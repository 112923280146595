import React from "react";
import ReactFusioncharts from "react-fusioncharts-fix";
import FusionCharts from "fusioncharts";
import { useTranslation } from "react-i18next";

FusionCharts.options.creditLabel = false;

const InvestTypeChart = ({ vcInfo }) => {
	const { t } = useTranslation();
	const CHART_CONFIG = (params, chartParams, annotationParams) => ({
		width: "100%",
		// height: "350",
		dataFormat: "JSON",
		dataSource: {
			chart: {
				baseFont: "Noto Sans KR",
				theme: "fusion",
				...chartParams,
			},
			annotations: {
				...annotationParams,
			},
		},
		...params,
	});
	const ASSET_PIE_CHART_CONFIG = CHART_CONFIG({ type: "pie2d" });
	return (
		<li className="invest-type-chart">
			<h3 className="basic-info-title">
				{t("VcInfo.VcDetail.investTypeChart.basic-info-title")}
			</h3>
			{vcInfo.vc_invest_ratio ? (
				<div className="chart-wrap">
					<ReactFusioncharts
						{...{
							...ASSET_PIE_CHART_CONFIG,
							dataSource: {
								chart: ASSET_PIE_CHART_CONFIG.dataSource.chart,
								data: JSON.parse(vcInfo.vc_invest_ratio),
							},
						}}
					/>
				</div>
			) : (
				<p className="no-content">{t("VcInfo.VcDetail.investTypeChart.no-content")}</p>
			)}
		</li>
	);
};

export default InvestTypeChart;
