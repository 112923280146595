import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { API_STATE } from "~/common/constants/state";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import useRequest from "~/common/hooks/useRequest";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const Validate = ({ setStep }) => {
	const { t } = useTranslation();
	const pwCheckForm = useForm();
	const checkRequest = useRequest("post", "user/pw/check");

	const request = (data) => {
		checkRequest.call(data);
	};

	useEffect(() => {
		switch (checkRequest.state) {
			case API_STATE.done:
				setStep("change");
				break;
			case API_STATE.error:
				pwCheckForm.setError("pw", {
					type: "custom",
					message: translation.t("MyPage.PasswordPage.validatePw"),
				});
				console.log("error", checkRequest.error);
				break;
			default:
				break;
		}
	}, [checkRequest.state, checkRequest.response, checkRequest.error, setStep]);

	return (
		<div className="c__card">
			<Form useForm={pwCheckForm}>
				<Input
					name={"pw"}
					label={t("MyPage.PasswordPage.currentPw")}
					type={"password"}
					validation={{
						required: translation.t("Validate.require"),
					}}
				/>
			</Form>
			<div className="btn-wrap">
				<button
					className="btn__solid"
					color="primary"
					onClick={pwCheckForm.handleSubmit(request)}
				>
					{t("button.check")}
				</button>
			</div>
		</div>
	);
};

export default Validate;
