import React, { useEffect } from "react";
import PropsProvider from "../PropsProvider";

const DefaultRenderer = ({ checked, onChange, option, labelName, valueName }) => {
	return (
		<div className="checkbox">
			<label className={`checkbox-custom`}>
				<input
					checked={checked}
					onChange={onChange}
					type="checkbox"
					value={option[valueName]}
				/>
				<span className="checkmark" />
				<span className="checkbox-label">{option[labelName]}</span>
			</label>
		</div>
	);
};

/**
 * @param { Props } props
 *
 * @typedef Props
 * @property {object} control - useForm control
 * @property {string} name - field name
 * @property {string} labelName - custom label property name
 * @property {string} valueName - custom value property name
 * @property {object} validation - validating object
 * @property {{ label: string, value: any}[]} options
 */
const Checkbox = (props) => {
	const {
		value,
		setValue,
		options,
		children,
		labelName = "label",
		valueName = "value",
		isAllChecked = null,
		setISAllChecked,
		required,
	} = props;

	useEffect(() => {
		if (isAllChecked === true) {
			const allValues = options.map((option) => option[valueName]?.toString());
			setValue(allValues);
		}
	}, [isAllChecked]);

	useEffect(() => {
		if (isAllChecked !== null && value !== undefined) {
			const newField = options.filter((v) => v.value !== "ALL").map((d) => d.value);
			if (
				JSON.stringify(value.sort()) === JSON.stringify(newField.sort()) ||
				value.find((v) => v === "ALL")
			) {
				setISAllChecked(true);
			} else {
				setISAllChecked(false);
			}
		}
	}, [value]);

	const SingleCheckbox = () =>
		options.map((option, index) => {
			const onChange = (e) => {
				if (value.find((v) => v === e.target.value)) {
					if (e.target.value === "ALL") {
						setValue([]);
					} else if (isAllChecked) {
						setValue(value.filter((v) => v !== "ALL" && v !== e.target.value));
					} else {
						setValue(value.filter((v) => v !== e.target.value));
					}
				} else {
					setValue([...value, e.target.value]);
					if (isAllChecked !== null) {
						e.target.value === "ALL" && setISAllChecked(true);
					}
				}
			};
			const checked = value.includes(option[valueName]?.toString());
			return children ? (
				<PropsProvider
					key={index}
					props={{ checked, onChange, option, labelName, valueName }}
				>
					{children}
				</PropsProvider>
			) : (
				<DefaultRenderer
					key={index}
					{...{ checked, onChange, option, labelName, valueName }}
				/>
			);
		});

	return (
		<div className={`f__control ${required && value.length === 0 ? "has-error" : ""} `}>
			<SingleCheckbox />
		</div>
	);
};

export default Checkbox;
