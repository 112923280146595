import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { ErrorIcon } from "~/assets/svg/Error";

const InternalServerError = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className="p__error">
			<ErrorIcon />
			<div className="phrase-wrap">
				<h3>예상하지 못한 오류가 발생했습니다. (500)</h3>
				<p className="pre">
					일시적인 현상이거나 네트워크 문제일 수도 있습니다.
					<br /> 잠시 후 다시 시도해 주세요.
				</p>
			</div>
			<div className="btn-wrap">
				<button
					type="button"
					className="btn__solid"
					color="primary"
					onClick={() => navigate(-2)}
				>
					{t("NotFound.preBtn")}
				</button>
				<button
					type="button"
					className="btn__solid"
					color="gray"
					onClick={() => navigate("/dashboard")}
				>
					{t("NotFound.nextBtn")}
				</button>
			</div>
		</div>
	);
};

export default InternalServerError;
