import React, { useEffect, useState } from "react";
import { useGlobalStore } from "~/store";
import { CollapseArrow } from "~/assets/svg/InterfacesIcon";
import { PAGE_STATE } from "~/common/constants/state";
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";

const Faq = () => {
	const { t } = useTranslation();
	const [
		{
			round: { faq },
		},
	] = useGlobalStore();

	const [page, setPage] = useState(PAGE_STATE.LOADING);
	const [faqList, setFaqList] = useState([]);

	useEffect(() => {
		if (!faq) return;
		if (faq.length === 0) {
			setPage(PAGE_STATE.EMPTY);
		} else {
			const toggleList = faq.map((d) => ({
				...d,
				toggle: false,
			}));

			setFaqList(toggleList);
			setPage(PAGE_STATE.VIEW);
		}
	}, [faq]);

	const toggleQuestion = (idx) => {
		const value = faqList.map((q) => (q.idx === idx ? { ...q, toggle: !q.toggle } : q));
		setFaqList(value);
	};

	return (
		<>
			<div className="faq-tab">
				<If condition={page}>
					<Case condition={PAGE_STATE.LOADING}>
						<Loading />
					</Case>
					<Case condition={PAGE_STATE.EMPTY}>
						<NoData
							main={t("Invest.Round.RoundDetailModal.View.component.faq.NoData")}
						/>
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						<div className="rounding-faq">
							{faqList.map((q, q_idx) => (
								<div
									className={`faq-card ${q.toggle ? "card-open" : ""}`}
									key={q_idx}
								>
									<div className="faq-card-header">
										<button
											type="button"
											className="faq-toggle-btn"
											onClick={() => toggleQuestion(q.idx)}
										>
											<CollapseArrow />
											{q.question}
										</button>
									</div>
									<div className="faq-card-body">
										<div className="card-answer">
											<li className="card-answer-line">{q.answer}</li>
										</div>
									</div>
								</div>
							))}
						</div>
					</Case>
				</If>
			</div>
		</>
	);
};

export default Faq;
