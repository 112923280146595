import React from "react";
import { CautionIcon } from "~/assets/svg/InterfacesIcon";

const NoData = ({ img, main, sub, Location = "top", className }) => {
	function IconControl() {
		return img ? <img src={img} alt="empty" /> : <CautionIcon />;
	}

	return (
		<div className={`${className ?? ""} c__noData`}>
			{Location === "top" && IconControl()}
			<p className="main">{main}</p>
			<p className="sub">{sub}</p>
			{Location === "bottom" && IconControl()}
		</div>
	);
};
export default NoData;
