import { SET_EVALUATE_LIST, SET_CURRENT_EVALUATE, GET_IR_LIST } from "./actions";

/**
 * @type {EVALUATE_STATE}
 */

export const INITIAL_STATE = {
	currentEvaluate: {},
	evaluateList: null,
	irList: [],
};

export const REDUCER = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_EVALUATE_LIST:
			return {
				...state,
				evaluateList: action.payload.evaluateList,
			};
		case SET_CURRENT_EVALUATE:
			return {
				...state,
				currentEvaluate: action.payload.evaluateDetail,
			};
		case GET_IR_LIST:
			return {
				...state,
				irList: action.payload.irList,
			};
		default:
			return state;
	}
};
