import React, { useEffect, useState } from "react";
import SingleImageUploader from "~/common/components/ImageUploader/SingleImageUploader";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import AlertTemplate from "~/common/components/AlertPortal/AlertTemplate";
import { useGlobalStore } from "~/store";
import { USER_INFO } from "~/store/user/repository";
import useRepository from "~/common/hooks/useRepository";
import { Trans, useTranslation } from "react-i18next";

const Edit = ({ setPage }) => {
	const { t } = useTranslation();
	const [{ company }] = useGlobalStore();
	const [getUserInfo] = useRepository(USER_INFO);
	const [logoRec, setLogoRec] = useState(company.info.logoRec ? company.info.logoRec : "");
	const [logoSqu, setLogoSqu] = useState(company.info.logoSqu ? company.info.logoSqu : "");
	const logoRequest = useRequest("post", "admin/logo");
	const [alertToggle, setAlertToggle] = useState(false);

	const handleSubmit = () => {
		logoRequest.call({
			logoSqu: logoSqu,
			logoRec: logoRec,
		});
	};

	useEffect(() => {
		switch (logoRequest.state) {
			case API_STATE.done:
				setAlertToggle(true);
				break;
			case API_STATE.error:
				alert(t("MyPage.admin.Logo.Edit.alert"));
				console.log("error", logoRequest.error);
				break;
			default:
				break;
		}
	}, [logoRequest.state, logoRequest.response, logoRequest.error]);

	const handleUpdate = () => {
		getUserInfo().then(() => {
			setPage("detail");
		});
	};

	return (
		<>
			<div className="c__card">
				<div className="logo-content">
					<h3 className="logo-content-title">
						{t("MyPage.admin.Logo.Edit.logo-content-title")}
					</h3>
					<p className="logo-content-limit">
						{t("MyPage.admin.Logo.Edit.logo-content-limit")}
					</p>
					<div className="logo-uploader-group">
						<div className="logo-uploader-container square">
							<p className="logo-type">
								<Trans
									i18nKey="MyPage.admin.Logo.Edit.logo-square"
									components={[<b />]}
								/>
							</p>
							<div className="logo-uploader">
								<SingleImageUploader
									uploaderConstant={UPLOAD_TYPE.COMPANY_LOGO}
									width={160}
									height={160}
									img={logoSqu}
									setImg={setLogoSqu}
								/>
							</div>
						</div>
						<div className="logo-uploader-container rectangle">
							<p className="logo-type">
								<Trans
									i18nKey="MyPage.admin.Logo.Edit.logo-rectangle"
									components={[<b />]}
								/>
							</p>
							<div className="logo-uploader">
								<SingleImageUploader
									uploaderConstant={UPLOAD_TYPE.COMPANY_LOGO}
									width={220}
									height={85}
									img={logoRec}
									setImg={setLogoRec}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="business-bottom">
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => setPage("detail")}
					>
						{t("button.cancel")}
					</button>
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={handleSubmit}
					>
						{t("button.save")}
					</button>
				</div>
			</div>
			<AlertTemplate alertModalState={alertToggle} setAlertModalToggle={setAlertToggle}>
				<p className="alert-content">{t("MyPage.admin.Logo.Edit.alert-content")}</p>
				<div className="alert-footer">
					<button className="btn__solid" color="primary" onClick={handleUpdate}>
						{t("button.check")}
					</button>
				</div>
			</AlertTemplate>
		</>
	);
};

export default Edit;
