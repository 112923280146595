import React from "react";
import { ErrorIcon } from "~/assets/svg/Error";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={"l__content p__NotFound"}>
			<ErrorIcon />
			<div className="phrase-wrap">
				<h3>{t("NotFound.phrase-wrap.h3")}</h3>
				<p className="pre">{t("NotFound.phrase-wrap.p")}</p>
			</div>
			<div className="btn-wrap">
				<button
					type="button"
					className="btn__solid"
					color="primary"
					onClick={() => navigate("/")}
				>
					{t("NotFound.nextBtn")}
				</button>
			</div>
		</div>
	);
};

export default NotFoundPage;
