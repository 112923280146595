import React from "react";
import { RectangleGreen, Global, GlobalResponsive } from "~/assets/svg/LandingIcon";
import { useTranslation } from "react-i18next";
import { bookDemoModalAtom } from "~/atoms/Landing";
import { useSetAtom } from "jotai";

export default function EmpowerGlobal() {
	const { t } = useTranslation();
	const setBookDemoModal = useSetAtom(bookDemoModalAtom);

	return (
		<section className="bg-[#042F2E] text-white pt-8 relative max-w-[1920px] m-auto">
			<div className="mx-auto max-w-[1920px] w-[95%] sm:w-[85%]">
				<div className="lg:w-[50%] w-full">
					<div className="mt-[-16px]">
						<RectangleGreen />
					</div>
					<h1 className="text-green-950 text-2xl text-white">
						{t("WhyUs.empower-global.title")}
					</h1>
					<div className="py-8">
						<span>{t("WhyUs.empower-global.sub-title")}</span>
					</div>
					<div>
						<button
							className="p-4 rounded-xl bg-[#DEEDF0] text-green-950 mb-16 font-bold"
							onClick={() => setBookDemoModal(true)}
						>
							{t("WhyUs.empower-global.sign-up-button")}
						</button>
					</div>
				</div>
			</div>
			<div className="absolute right-0 bottom-0 lg:block hidden">
				<Global />
			</div>
			<div className="relative lg:hidden block flex justify-center">
				<GlobalResponsive />
			</div>
		</section>
	);
}
