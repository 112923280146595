import {
	setExistMemberList,
	setMemberList,
	setOrgMemberData,
	setTreeData,
	setWorkTypeList,
} from "./actions";

/**
 * 조직 리스트
 */
export const HR_GET_ORG = {
	method: "post",
	url: "hr/org",
	type: "body",
	template: (param) => ({
		searchWord: param?.searchWord ?? "",
		employmentStatus: param?.employmentStatus ?? [0, 1, 2],
		usage: param?.usage ?? "team",
	}),
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data;
		dispatch(setOrgMemberData({ orgMemberData: responseData.member }));
		dispatch(setTreeData({ treeData: responseData.org }));
		return responseData;
	},
	onError: ({ error, navigate, dispatch }) => {
		switch (error.statusCode) {
			case 404:
				dispatch(setOrgMemberData({ orgMemberData: [] }));
				dispatch(setTreeData({ treeData: [] }));
				console.log("조직이 하나도 없음");
				break;
			default:
				console.log("error", error);
				navigate("/404");
				break;
		}
		return error;
	},
};

/**
 * 팀원 리스트 조회
 */
export const HR_GET_MEMBER = {
	method: "post",
	url: "hr/team",
	type: "body",
	template: (param) => ({
		searchWord: param?.searchWord ?? "",
		employmentStatus: param?.employmentStatus ?? [0, 1, 2],
		orgIdList: param?.orgIdList ?? [],
	}),
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data;
		const sortedData = responseData.sort((a, b) => a.name.localeCompare(b.name));
		dispatch(setMemberList({ memberList: sortedData }));
		return sortedData;
	},
	onError: ({ error, navigate }) => {
		console.log("error", error);
		navigate("/404");
		return error;
	},
};

/**
 * 추가된 팀원 리스트 조회
 */
export const HR_GET_EXIST_MEMBER = {
	method: "post",
	url: "hr/team",
	type: "body",
	template: () => ({
		searchWord: "",
		employmentStatus: [0, 1, 2, 3],
		orgIdList: [],
	}),
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data;
		const sortedData = responseData.sort((a, b) => a.name.localeCompare(b.name));
		dispatch(setExistMemberList({ existMemberList: sortedData }));
		return sortedData;
	},
	onError: ({ error, navigate }) => {
		console.log("error", error);
		navigate("/404");
		return error;
	},
};

/**
 * 근무 유형 조회
 */
export const HR_GET_WORK_TYPE = {
	method: "get",
	url: "hr/work",
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data.map((workType) => ({
			[workType.idx]: workType.title,
		}));

		let obj = new Object();
		Object.assign(obj, ...responseData);

		dispatch(
			setWorkTypeList({
				workTypeList: obj,
			})
		);
		return responseData;
	},
};
