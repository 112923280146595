import React from "react";
import { useGlobalStore } from "~/store";
import { ClipIcon, DownLoadIcon, FileLinkIcon } from "~/assets/svg/InterfacesIcon";
import { formatBytes } from "~/utils/formatter";

const Detail = () => {
	const [
		{
			round: { detail, files },
		},
	] = useGlobalStore();

	return (
		<>
			<div className="detail-info-tab" dangerouslySetInnerHTML={{ __html: detail }} />
			{files && files.length > 0 && (
				<div className="content-file">
					<div className="file-title">
						<ClipIcon />
						{"첨부 파일"}
					</div>
					<ul className="file-list">
						{files.map((file, index) => (
							<a href={file.path} download={file.name} key={index}>
								<li className="file-item" key={index}>
									<div className="file-icon">
										<FileLinkIcon />
									</div>
									<div className="file-contents">
										<p className="file-text">{file.name}</p>
										<p className="file-data">{formatBytes(file.size, 1)}</p>
									</div>
									<div className="file-download">
										<DownLoadIcon />
									</div>
								</li>
							</a>
						))}
					</ul>
				</div>
			)}
		</>
	);
};

export default Detail;
