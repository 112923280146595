import React, { useEffect } from "react";
import Input from "~/common/components/Input";
import Form from "~/common/components/Form";

const Short = ({ useForm, form, apply }) => {
	useEffect(() => {
		useForm.setValue(form.order.toString(), form.answer);
	}, []);

	return (
		<>
			<Form useForm={useForm}>
				<Input
					label={form.title}
					name={form.order.toString()}
					required={form.required}
					readonly={apply}
				/>
			</Form>
		</>
	);
};

export default Short;
