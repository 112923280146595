import React from "react";

//404 에러 아이콘
export const ErrorIcon = () => {
	return (
		<svg
			width="326"
			height="260"
			viewBox="0 0 326 260"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M223.029 247.543H154.742L119.499 185.905V239.741C119.499 248.16 125.645 254.895 133.093 254.895H223.029C224.479 254.895 225.78 253.881 225.78 252.432V250.021C225.78 248.572 224.479 247.543 223.029 247.543Z"
				fill="#0D9488"
			/>
			<path
				d="M129.514 205.271V235.801C129.514 241.99 134.424 246.932 140.618 246.932H199.415V205.271H129.514Z"
				fill="#F8F8F8"
			/>
			<path
				d="M200.029 247.543H140.618C134.047 247.543 128.901 242.385 128.901 235.799V204.658H200.029V247.543ZM130.128 205.882V235.799C130.128 241.698 134.736 246.317 140.618 246.317H198.802V205.882H130.128Z"
				fill="#1B191B"
			/>
			<path
				d="M314.531 208.086L27.8414 208.71C25.3358 208.71 23.22 206.846 22.9004 204.359L0.063131 26.0381C-0.493063 21.6993 2.67225 17.6024 7.04235 17.6024L290.023 17.8591L314.531 208.086Z"
				fill="#0D9488"
			/>
			<path
				d="M320.405 207.859H39.442C36.9382 207.859 34.8224 205.991 34.5009 203.495L11.8097 26.7785C11.1796 21.8692 14.9842 17.5212 19.9105 17.5212H297.25C299.754 17.5212 301.87 19.3897 302.191 21.8858L325.348 202.213C325.732 205.208 323.411 207.859 320.407 207.859H320.405Z"
				fill="#F8F8F8"
			/>
			<path
				d="M320.405 208.472H39.442C36.637 208.472 34.2534 206.366 33.8949 203.572L11.2018 26.8559C10.8766 24.3321 11.6545 21.7898 13.3342 19.8844C14.9991 17.9938 17.3957 16.9082 19.9106 16.9082H297.25C300.055 16.9082 302.439 19.0148 302.797 21.8082L325.954 202.136C326.161 203.744 325.666 205.361 324.596 206.576C323.535 207.782 322.007 208.472 320.405 208.472ZM19.9106 18.1341C17.7486 18.1341 15.6883 19.0665 14.2544 20.6949C12.8075 22.3381 12.1386 24.5259 12.4177 26.7027L35.1107 203.419C35.3916 205.603 37.2524 207.248 39.442 207.248H320.405C321.654 207.248 322.846 206.709 323.676 205.767C324.513 204.817 324.899 203.55 324.738 202.293L301.581 21.9652C301.3 19.781 299.44 18.136 297.25 18.136H19.9106V18.1341Z"
				fill="#1B191B"
			/>
			<path
				d="M33.9004 0.61293H279.969C285.4 0.61293 290.343 5.02738 291.006 10.4739L311.765 180.534C312.437 186.038 308.553 190.501 303.091 190.501H55.64C50.1779 190.501 45.2405 186.038 44.6122 180.534L25.1935 10.472C24.5708 5.02553 28.4697 0.611084 33.9023 0.611084L33.9004 0.61293Z"
				fill="#98D5FF"
			/>
			<path
				d="M291.786 16.4946L291.322 10.3852C290.662 4.98856 285.548 0.69043 280.117 0.69043H34.0482C28.6174 0.69043 24.6612 4.98856 25.2784 10.3852L25.8549 17.907L291.788 16.4946H291.786Z"
				fill="#0D9488"
			/>
			<path
				d="M40.7669 10.6381C40.9683 12.3921 39.7081 13.8137 37.9527 13.8137C36.1972 13.8137 34.6118 12.3921 34.4122 10.6381C34.2108 8.88416 35.471 7.46252 37.2265 7.46252C38.9819 7.46252 40.5673 8.88416 40.7669 10.6381Z"
				fill="#98D5FF"
			/>
			<path
				d="M50.9114 10.4258C51.1128 12.1798 49.8545 13.6014 48.0991 13.6014C46.3436 13.6014 44.7582 12.1798 44.5568 10.4258C44.3554 8.67188 45.6156 7.25024 47.3692 7.25024C49.1227 7.25024 50.71 8.67188 50.9114 10.4258Z"
				fill="#98D5FF"
			/>
			<path
				d="M61.9743 10.8505C62.1757 12.6045 60.9174 14.0261 59.1619 14.0261C57.4065 14.0261 55.8211 12.6045 55.6197 10.8505C55.4183 9.09656 56.6766 7.67493 58.432 7.67493C60.1875 7.67493 61.7729 9.09656 61.9743 10.8505Z"
				fill="#98D5FF"
			/>
			<path
				d="M259.924 191.112H55.7306C49.9765 191.112 44.7564 186.396 44.0949 180.603L24.6743 10.5422C24.3509 7.71374 25.1565 5.0551 26.9415 3.05743C28.7025 1.08561 31.2063 0 33.991 0H280.062C285.784 0 291.008 4.66554 291.708 10.3982L298.459 65.6997C298.499 66.0358 298.261 66.3404 297.925 66.3829C297.588 66.4216 297.283 66.1853 297.241 65.8493L290.491 10.5478C289.864 5.40774 285.186 1.22593 280.062 1.22593H33.991C31.5592 1.22593 29.3807 2.16568 27.8562 3.87349C26.3077 5.60714 25.6111 7.92606 25.8938 10.4038L45.3126 180.464C45.9057 185.66 50.5789 189.888 55.7306 189.888H259.926C260.264 189.888 260.539 190.163 260.539 190.501C260.539 190.838 260.264 191.114 259.926 191.114L259.924 191.112Z"
				fill="#0D9488"
			/>
			<path
				d="M222.604 255.303H136.261C135.922 255.303 135.647 255.028 135.647 254.69C135.647 254.352 135.922 254.077 136.261 254.077H222.604C223.698 254.077 224.555 253.182 224.555 252.041V249.629C224.555 248.479 223.679 247.543 222.604 247.543H197.577C197.239 247.543 196.963 247.268 196.963 246.93C196.963 246.592 197.239 246.317 197.577 246.317H222.604C224.355 246.317 225.782 247.804 225.782 249.629V252.041C225.782 253.839 224.355 255.303 222.604 255.303Z"
				fill="#1B191B"
			/>
			<path
				d="M108.255 248.769H33.8469L40.8889 234.922L47.0255 222.862L53.8828 209.386L60.0176 197.332L67.9965 181.662C68.5804 180.512 69.7611 179.792 71.0509 179.792C72.3407 179.792 73.5214 180.512 74.1053 181.661L82.0842 197.33L88.219 209.382L95.0763 222.855L101.213 234.922L108.255 248.769Z"
				fill="#98D5FF"
			/>
			<path
				d="M88.219 209.558H53.8846L60.0175 197.306H82.0842L88.219 209.558Z"
				fill="#0D9488"
			/>
			<path
				d="M101.213 234.881H40.8889L47.0273 223.037H95.0762L101.213 234.881Z"
				fill="#0D9488"
			/>
			<path
				d="M107.887 249.585H33.4791C33.2666 249.585 33.0689 249.474 32.9562 249.293C32.8453 249.112 32.8342 248.885 32.9303 248.697L39.9723 234.747L46.1108 222.635L59.1028 197.066L67.0817 181.389C67.7747 180.027 69.1531 179.181 70.6831 179.181C72.2131 179.181 73.5934 180.025 74.2827 181.384L95.2536 222.576L108.434 248.697C108.53 248.887 108.521 249.112 108.408 249.293C108.297 249.474 108.1 249.585 107.885 249.585H107.887ZM34.4751 248.359H106.891L94.1597 223.128L73.1906 181.938C72.7102 180.992 71.7493 180.405 70.6831 180.405C69.6169 180.405 68.6579 180.994 68.1756 181.943L54.062 209.685L47.2047 223.187L41.0663 235.297L34.4732 248.357L34.4751 248.359Z"
				fill="#1B191B"
			/>
			<path
				d="M29.0739 246.535H112.662C114.037 246.535 115.155 247.65 115.155 249.026V256.898C115.155 258.272 114.039 259.389 112.662 259.389H29.0739C27.6992 259.389 26.5812 258.274 26.5812 256.898V249.026C26.5812 247.652 27.6973 246.535 29.0739 246.535Z"
				fill="#0D9488"
			/>
			<path
				d="M112.662 260H29.0739C27.361 260 25.9677 258.608 25.9677 256.896V249.026C25.9677 247.314 27.361 245.922 29.0739 245.922H112.662C114.375 245.922 115.768 247.314 115.768 249.026V256.896C115.768 258.608 114.375 260 112.662 260ZM29.0739 247.148C28.0373 247.148 27.1947 247.99 27.1947 249.026V256.896C27.1947 257.932 28.0373 258.774 29.0739 258.774H112.662C113.699 258.774 114.541 257.932 114.541 256.896V249.026C114.541 247.99 113.699 247.148 112.662 247.148H29.0739Z"
				fill="#1B191B"
			/>
			<path
				d="M85.6709 84.0701L86.9182 95.2308H101.919L102.71 102.309H87.709L89.0265 114.109H105.942L106.755 121.38H80.9035L75.9236 76.7994H101.775L102.586 84.0701H85.6709Z"
				fill="#F8F8F8"
			/>
			<path
				d="M134.647 121.38L122.88 104.032H118.667L120.606 121.38H111.67L106.697 76.8641H123.42C126.866 76.8641 129.871 77.4697 132.432 78.6809C134.993 79.892 136.994 81.5297 138.437 83.592C139.88 85.6543 140.745 87.9603 141.03 90.5118C141.357 93.4455 140.801 96.0931 139.363 98.4526C137.924 100.812 135.573 102.439 132.312 103.332L144.988 121.382H134.647V121.38ZM117.919 97.3356H125.388C127.814 97.3356 129.557 96.7522 130.617 95.5817C131.678 94.413 132.092 92.7864 131.859 90.702C131.632 88.6618 130.857 87.0777 129.542 85.9515C128.224 84.8253 126.352 84.2622 123.928 84.2622H116.459L117.919 97.3356Z"
				fill="#F8F8F8"
			/>
			<path
				d="M172.369 121.38L160.602 104.032H156.389L158.327 121.38H149.391L144.419 76.8641H161.141C164.588 76.8641 167.592 77.4697 170.153 78.6809C172.714 79.892 174.716 81.5297 176.159 83.592C177.602 85.6543 178.467 87.9603 178.751 90.5118C179.078 93.4455 178.522 96.0931 177.084 98.4526C175.645 100.812 173.295 102.439 170.033 103.332L182.709 121.382H172.369V121.38ZM155.64 97.3356H163.109C165.536 97.3356 167.278 96.7522 168.339 95.5817C169.399 94.413 169.813 92.7864 169.58 90.702C169.353 88.6618 168.579 87.0777 167.263 85.9515C165.946 84.8253 164.074 84.2622 161.65 84.2622H154.181L155.64 97.3356Z"
				fill="#F8F8F8"
			/>
			<path
				d="M196.028 118.891C192.32 116.936 189.252 114.225 186.822 110.76C184.393 107.294 182.933 103.373 182.445 98.9935C181.961 94.6566 182.547 90.7554 184.202 87.29C185.858 83.8245 188.321 81.1142 191.592 79.159C194.862 77.2037 198.582 76.2252 202.754 76.2252C206.927 76.2252 210.916 77.2037 214.603 79.159C218.289 81.1142 221.336 83.8263 223.746 87.29C226.153 90.7554 227.6 94.6566 228.084 98.9935C228.574 103.373 228.001 107.296 226.368 110.76C224.732 114.225 222.28 116.936 219.01 118.891C215.739 120.848 212.018 121.825 207.847 121.825C203.676 121.825 199.737 120.848 196.03 118.891H196.028ZM213.838 112.036C215.745 110.824 217.147 109.093 218.045 106.838C218.943 104.586 219.224 101.97 218.891 98.9935C218.559 96.0173 217.694 93.4122 216.295 91.1801C214.896 88.9479 213.11 87.2364 210.935 86.0456C208.76 84.8566 206.332 84.2602 203.651 84.2602C200.969 84.2602 198.663 84.8566 196.732 86.0456C194.801 87.2364 193.386 88.9479 192.486 91.1801C191.586 93.4122 191.303 96.0173 191.636 98.9935C191.969 101.97 192.835 104.586 194.236 106.838C195.637 109.093 197.436 110.824 199.635 112.036C201.834 113.247 204.275 113.852 206.954 113.852C209.634 113.852 211.929 113.247 213.838 112.036Z"
				fill="#F8F8F8"
			/>
			<path
				d="M257.006 121.38L245.239 104.032H241.026L242.965 121.38H234.029L229.056 76.8641H245.779C249.225 76.8641 252.23 77.4697 254.791 78.6809C257.352 79.892 259.353 81.5297 260.796 83.592C262.239 85.6543 263.104 87.9603 263.389 90.5118C263.716 93.4455 263.16 96.0931 261.722 98.4526C260.283 100.812 257.932 102.439 254.671 103.332L267.347 121.382H257.006V121.38ZM240.278 97.3356H247.747C250.173 97.3356 251.916 96.7522 252.976 95.5817C254.037 94.413 254.451 92.7864 254.218 90.702C253.991 88.6618 253.216 87.0777 251.901 85.9515C250.583 84.8253 248.711 84.2622 246.287 84.2622H238.818L240.278 97.3356Z"
				fill="#F8F8F8"
			/>
		</svg>
	);
};
