import React, { useEffect, useState } from "react";
import { SearchIcon } from "~/assets/svg/InterfacesIcon";
import noImg from "~/assets/img/defaultImg/profile-large.png";
import { SolidCloseIcon } from "~/assets/svg/CloseIcon";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import "~/assets/sass/pages/community/boardPage/inviteModal.scss";
import useRequest from "~/common/hooks/useRequest";
import { useParams } from "react-router";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";

const InviteModal = ({ inviteModalToggle, setInviteModalToggle, getMember }) => {
	const { t } = useTranslation();
	const { idx } = useParams();
	const invitableMembersRequest = useRequest("post", `/community/member/invite/search/${idx}`);
	const inviteMembersRequest = useRequest("post", `/community/member/invite/${idx}`);

	const [invitableMembers, setInvitableMembers] = useState([]);
	const [selectedMembers, setSelectedMembers] = useState([]);

	const [selectableMembers, setSelectableMembers] = useState([]);

	useEffect(() => {
		if (inviteModalToggle) {
			setSelectedMembers([]);
			setSelectableMembers([]);
			setInvitableMembers([]);

			invitableMembersRequest
				.asyncCall(
					{
						page: 1,
						size: 50,
					},
					"body"
				)
				.then(({ data }) => {
					setInvitableMembers(data.users);
					setSelectableMembers(data.users);
				});
		}
	}, [inviteModalToggle]);

	const search = (event) => {
		setSelectableMembers(
			invitableMembers.filter(
				(member) =>
					member.name.includes(event.target.value) ||
					member.id.includes(event.target.value)
			)
		);
	};

	const checkMember = (member) => {
		if (selectedMembers.includes(member)) {
			setSelectedMembers((prevState) => prevState.filter((item) => item.idx !== member.idx));
		} else {
			setSelectedMembers((prevState) => [...prevState, member].sort((a, b) => a.idx - b.idx));
		}
	};

	const checkAll = () => {
		if (selectableMembers.every((m) => selectedMembers.includes(m)))
			setSelectedMembers((prevState) =>
				prevState.filter((m) => !selectableMembers.includes(m))
			);
		else
			setSelectedMembers((prevState) =>
				[
					...prevState.filter((m) => !selectableMembers.includes(m)),
					...selectableMembers,
				].sort((a, b) => a.idx - b.idx)
			);
	};

	const inviteMembers = () => {
		const selectedIds = selectedMembers.map((member) => member.idx);

		inviteMembersRequest
			.asyncCall(
				{
					userIndexes: selectedIds,
				},
				"body"
			)
			.then(() => {
				getMember();
				setInviteModalToggle(false);
			});
	};
	return (
		<ModalTemplate
			title={t("Community.BoardPage.BoardPost.List.Modal.modal-title")}
			modalState={inviteModalToggle}
			setModalToggle={setInviteModalToggle}
			width={680}
			height={700}
			className="invite-modal"
		>
			<div className="modal-content">
				<div className="team-checkbox">
					<div className="checkbox-header">
						<div className="checkbox">
							<label className={`checkbox-custom`}>
								<input
									type="checkbox"
									checked={
										selectableMembers.length > 0 &&
										selectableMembers.every((m) => selectedMembers.includes(m))
									}
									onChange={checkAll}
								/>
								<span className="checkmark" />
							</label>
						</div>

						<div className="c__search">
							<input
								className="c__input"
								placeholder={t(
									"Community.BoardPage.BoardPost.List.input-placeholder"
								)}
								onChange={search}
							/>
							<button type="button" className="btn-search">
								<SearchIcon />
							</button>
						</div>
					</div>
					<div className="checkbox-body">
						<ul className="member-list">
							{selectableMembers.length > 0 ? (
								selectableMembers.map((member, index) => (
									<li
										className="member-item"
										key={index}
										onClick={(e) => {
											e.preventDefault();
											checkMember(member);
										}}
									>
										<div className="checkbox">
											<label className={`checkbox-custom`}>
												<input
													type="checkbox"
													checked={selectedMembers.includes(member)}
													onChange={() => {}}
												/>
												<span className="checkmark" />
											</label>
										</div>
										<div className="profile-img">
											<img
												src={member.img ?? noImg}
												alt={t("Img.profile")}
												onError={(e) => {
													e.target.src = noImg;
												}}
											/>
										</div>

										<div className="profile-info">
											<span className="profile-name"> {member.name}</span>
											<div className="profile-email">{member.id}</div>
										</div>
									</li>
								))
							) : (
								<NoData
									main={t("Community.BoardPage.BoardPost.List.searchNoData.main")}
									sub={t("Community.BoardPage.BoardPost.List.searchNoData.sub")}
								/>
							)}
						</ul>
					</div>
				</div>
				<div className="team-select">
					<div className="select-header">
						<div className="select-member">
							<span>{selectedMembers?.length}</span>{" "}
							{t("Community.BoardPage.BoardPost.List.Modal.select-member")}
						</div>
						<button
							type="button"
							className="btn-delete"
							onClick={() => setSelectedMembers([])}
						>
							{t("Community.BoardPage.BoardPost.List.Modal.btn-delete")}
						</button>
					</div>
					<div className="select-body">
						<ul className="member-list">
							{selectedMembers?.map((member, index) => (
								<li className="member-item" key={index}>
									<div className="profile-img">
										<img
											src={member.img ?? noImg}
											alt={t("Img.profile")}
											onError={(e) => {
												e.target.src = noImg;
											}}
										/>
									</div>

									<div className="profile-info">
										<span className="profile-name">{member.name}</span>
										<div className="profile-email">{member.id}</div>
									</div>
									<button
										type="button"
										className="btn-close"
										onClick={() =>
											setSelectedMembers((prevState) =>
												prevState.filter((item) => item.idx !== member.idx)
											)
										}
									>
										<SolidCloseIcon />
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
			<div className="modal-footer">
				<button
					key="cancel"
					className="btn__solid"
					color="gray"
					onClick={() => setInviteModalToggle(false)}
				>
					{t("button.back")}
				</button>
				<button key="add" className="btn__solid" color="primary" onClick={inviteMembers}>
					{t("Community.BoardPage.BoardPost.List.button-invite")}
				</button>
			</div>
		</ModalTemplate>
	);
};

export default InviteModal;
