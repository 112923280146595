import React, { useEffect, useState } from "react";
import { ClipIcon, FileDeleteIcon, UploadIcon } from "~/assets/svg/InterfacesIcon";
import "~/assets/sass/component/fileUploader.scss";
import { useController } from "react-hook-form";
import useUpload from "~/common/hooks/useUpload";
import useAlert from "~/common/hooks/useAlert";
import { API_STATE } from "~/common/constants/state";
import { useTranslation } from "react-i18next";

const FileUploader = ({ type, name, control, required, format = "*" }) => {
	const { t } = useTranslation();
	const {
		field: { onChange, value },
	} = useController({
		control,
		name,
		rules: {
			required:
				required === true ? t("Validate.require") : required === null ? " " : required,
		},
	});

	const { url, upload, clear, state } = useUpload(type, value ?? null, format);

	const [showErrorAlert, ErrorAlert] = useAlert();
	const [fileName, setFileName] = useState("");

	useEffect(() => {
		switch (state) {
			case API_STATE.error:
				showErrorAlert();
				setFileName("");
				break;
			default:
				break;
		}
	}, [state]);

	useEffect(() => {
		onChange(url);
	}, [url]);

	return (
		<>
			<div className="c__fileUploader">
				<div className="file-preview">
					<ClipIcon />
					<p className="file-name">{fileName}</p>
					<button
						type="button"
						className="btn-delete"
						onClick={() => {
							clear();
							setFileName("");
						}}
					>
						<FileDeleteIcon />
					</button>
				</div>
				<label className="file-label" htmlFor={"file-input"}>
					<input
						type="file"
						accept={format}
						name="file-input"
						className="file-input"
						onChange={({ target }) => {
							target.files.length > 0 && upload(target.files);
							setFileName(target.files[0].name);
						}}
					/>
					<UploadIcon />
					{t("components.FileUploader.file-label")}
				</label>
			</div>
			<ErrorAlert
				message={t("components.FileUploader.error-alert")}
				type="error"
				confirm={t("button.confirm")}
			/>
		</>
	);
};

export default FileUploader;
