import React from "react";
import { useGlobalStore } from "~/store";
import { thousandFormatter } from "~/utils/formatter";
import { AREA_TYPE } from "~/common/constants/form";
import { useTranslation } from "react-i18next";

const MarketSection = () => {
	const { t } = useTranslation();
	const [
		{
			ir: {
				product: { marketResearch, positioning, IRProductMarket, IRProductPlayer },
			},
		},
	] = useGlobalStore();

	const marketList = () =>
		IRProductMarket.map(({ idx, area, content, value }) => (
			<ul key={idx} className="f__control">
				<li className="kind-select">{AREA_TYPE[area]}</li>
				<li className="content">{content}</li>
				<li className="number">{thousandFormatter(value)}</li>
			</ul>
		));

	const playerList = () =>
		IRProductPlayer.map(({ idx, area, name, sales, content }) => (
			<ul key={idx} className="f__control">
				<li className="kind-select">{AREA_TYPE[area]}</li>
				<li className="name">{name}</li>
				<li className="content">{content}</li>
				<li className="number">{thousandFormatter(sales)}</li>
			</ul>
		));

	return (
		<>
			<div className="section-title">
				{t("IR.IrDetail.Product.Edit.MarketSection.Index.market")}
			</div>
			<div className="c__form vertical sub-section">
				<div className="f__group">
					<label className="f__label">
						{t("IR.IrDetail.Product.Edit.MarketSection.Index.research")}
					</label>
					<div className="f__control">
						<textarea
							className="c__textarea f__item"
							defaultValue={marketResearch}
							readOnly
						/>
					</div>
				</div>
				<div className="f__group">
					<label className="f__label">
						{t("IR.IrDetail.Product.Edit.MarketSection.Index.positioning")}
					</label>
					<div className="f__control">
						<textarea
							className="c__textarea f__item"
							defaultValue={positioning}
							readOnly
						/>
					</div>
				</div>
			</div>
			<div className="c__form vertical sub-section">
				<div className="f__group">
					<div className="f__label unit-wrap">
						{t("IR.IrDetail.Product.Edit.MarketSection.Index.title1")}{" "}
						<span className="unit">
							{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
							{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
						</span>
					</div>
					{marketList()}
				</div>
			</div>
			<div className="c__form vertical sub-section">
				<div className="f__group">
					<div className="f__label unit-wrap">
						{t("IR.IrDetail.Product.Edit.MarketSection.Index.title2")}{" "}
						<span className="unit">
							{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
							{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
						</span>
					</div>
					{playerList()}
				</div>
			</div>
		</>
	);
};

export default MarketSection;
