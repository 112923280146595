import React, { useEffect, useState } from "react";
import { ALLOW_COMMENT_STATE, API_STATE } from "~/common/constants/state";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import { useForm } from "react-hook-form";
import PageHeader from "~/common/components/PageHeader";
import Form, { Group } from "~/common/components/Form";
import Input from "~/common/components/Input";
import FileUploaderAdd from "~/common/components/FileUploader/FileUploaderAdd";
import useRequest from "~/common/hooks/useRequest";
//assets
import "~/assets/sass/pages/community/communityRegister.scss";
import useAlert from "~/common/hooks/useAlert";
import { useNavigate, useParams } from "react-router";
import Toggle from "~/common/components/Toggle";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import { imgTagSrcExtraction } from "~/utils/formatter";
import { useAtomValue } from "jotai";
import { CurrentNoticeAtom } from "~/atoms/Community";
import Editor from "~/common/components/Editor";
import { useTranslation } from "react-i18next";

const NoticeEdit = ({ isEdit }) => {
	const { t } = useTranslation();
	const { idx } = useParams();
	const [fileList, setFileList] = useState([]);
	const [ImageList, setImageList] = useState([]);
	const [showWarningAlert, WarningAlert] = useAlert();
	const currentNotice = useAtomValue(CurrentNoticeAtom);
	const noticeEditForm = useForm(
		isEdit && {
			defaultValues: {
				noticeTitle: currentNotice.title,
				content: currentNotice.content,
				commentMode: currentNotice.settings[0].value === ALLOW_COMMENT_STATE.ALLOW,
			},
		}
	);
	const noticeEditRequest = useRequest(
		isEdit ? "patch" : "post",
		`community/notice${isEdit ? `/${idx}` : ""}`
	);
	const navigate = useNavigate();

	async function submit(formData) {
		await noticeEditRequest.call(
			{
				title: formData.noticeTitle,
				content: formData.content,
				settings: [
					{
						type: "ALLOW_COMMENT",
						value: formData.commentMode
							? ALLOW_COMMENT_STATE.ALLOW
							: ALLOW_COMMENT_STATE.NOT_ALLOW,
					},
				],
				contentFiles: ImageList.filter((file) =>
					imgTagSrcExtraction(formData.content).includes(file.path)
				),
				uploadFiles: fileList,
			},
			"body"
		);
	}

	useEffect(() => {
		if (isEdit) {
			setFileList(
				currentNotice.uploadFiles.map((file) => ({
					name: file.name,
					path: file.path,
					size: Number(file.size),
				}))
			);
			setImageList(
				currentNotice.contentFiles.map((file) => ({
					name: file.name,
					path: file.path,
					size: Number(file.size),
				}))
			);
		}
	}, []);

	useEffect(() => {
		switch (noticeEditRequest.state) {
			case API_STATE.done:
				if (noticeEditRequest.response.statusCode === 500) {
					console.log("error");
					navigate("/404");
				} else {
					navigate(isEdit ? `/community/notice/detail/${idx}` : "/community/notice");
				}

				break;
			case API_STATE.error:
				navigate("/404");
				break;
		}
	}, [noticeEditRequest.state]);

	const onCancel = () => {
		if (isEdit) {
			if (
				currentNotice.title !== noticeEditForm.watch("noticeTitle") ||
				currentNotice.content !== noticeEditForm.watch("content") ||
				currentNotice.uploadFiles.filter((item) =>
					fileList.find((file) => item.name !== file.name)
				).length !== 0 ||
				(currentNotice.settings[0].value === ALLOW_COMMENT_STATE.ALLOW) !==
					noticeEditForm.watch("commentMode")
			) {
				showWarningAlert()
					.then(() => {
						navigate(`/community/notice/detail/${idx}`);
					})
					.catch(() => {});
			} else {
				navigate(`/community/notice/detail/${idx}`);
			}
		} else {
			if (noticeEditForm.watch("noticeTitle") || noticeEditForm.watch("content")) {
				showWarningAlert()
					.then(() => {
						navigate(`/community/notice`);
					})
					.catch(() => {});
			} else {
				navigate("/community/notice");
			}
		}
	};

	const buttonList = [
		<button className="btn__solid" color="gray" onClick={onCancel}>
			{t("button.cancel")}
		</button>,
		<button
			className="btn__solid"
			color="primary"
			onClick={noticeEditForm.handleSubmit(submit)}
		>
			{t("button.save")}
		</button>,
	];

	return (
		<NavbarAndSidebarLayout menu={["community", "notice"]} type={"main"}>
			<PageHeader menu={["community", "notice"]} buttonList={buttonList} />
			<div className="c__card community-register-card">
				<Form useForm={noticeEditForm} className={`Community-PostForm`}>
					<Input
						name="noticeTitle"
						placeholder={t(
							"Community.BoardPage.BoardPost.Detail.Edit.notice-placeholder"
						)}
						required={t("Validate.require")}
					/>
					<Editor
						useForm={noticeEditForm}
						setImageList={setImageList}
						uploadType={UPLOAD_TYPE.NOTICE_IMAGE}
						placeholder={t(
							"Community.BoardPage.BoardPost.Detail.Edit.notice-editor-placeholder"
						)}
					/>
					<FileUploaderAdd
						label={t("Community.FileUploader-label")}
						name="file"
						type={UPLOAD_TYPE.NOTICE_FILE}
						className={"community-fileUpload"}
						format=".pdf, .hwp, .docx, .xlsx, .ppt, ,pptx, .jpg, .jpeg, .png, .keynote"
						fileList={fileList}
						setFileList={setFileList}
					/>
					<Group className="switch-group">
						<label className="switch-label">{t("Community.switch-label")}</label>
						<Toggle
							name="commentMode"
							defaultValue={isEdit ? noticeEditForm.watch("commentMode") : true}
						/>
					</Group>
				</Form>
			</div>
			<WarningAlert
				type="error"
				className="overtime-use-modal"
				layout="horiz"
				width={430}
				height={306}
				message={
					<>
						<h3>{t("Community.NoticePage.Edit.overtime-use-modal.h3")}</h3>
						<p>{t("Community.NoticePage.Edit.overtime-use-modal.p")}</p>
					</>
				}
				desc={
					<div className="desc-box error">{t("Community.NoticePage.Edit.desc-box")}</div>
				}
				reject={t("button.cancel")}
				confirm={t("button.check")}
			/>
		</NavbarAndSidebarLayout>
	);
};
export default NoticeEdit;
