import { useEffect, useState } from "react";

const useScrollTop = () => {
    const [ yScroll, setYScroll ] = useState(null);

    useEffect(() => {
		const { scrollTop } = document.documentElement;
		setYScroll(scrollTop);
	},[]);

    useEffect(() => {
		const handlePostion = () => {
			if((yScroll > 0 && yScroll !== 0)) {
				document.documentElement.scroll({
					top: 0,
					behavior: "auto"
				})
			}
		};
		
		handlePostion();

        return () => {
            handlePostion();
        }
	},[yScroll]);
}

export default useScrollTop;