import React, { useEffect, useRef, useState } from "react";

import { useGlobalStore } from "~/store";
import AlertTemplate from "../AlertPortal/AlertTemplate";

const AuthButton = ({ onClick = () => {}, children, ...others }) => {
	const [alert, setAlert] = useState(false);
	const confirmButton = useRef(null);
	const [
		{
			sidebar: { currentPath },
		},
	] = useGlobalStore();

	useEffect(() => {
		alert === true && confirmButton.current.focus();
	}, [alert]);

	const handleClick = (e) => {
		if (currentPath.auth === "edit") {
			onClick();
		} else {
			setAlert(true);
			e.stopPropagation();
		}
	};
	return (
		<>
			<button onClick={handleClick} {...others}>
				{children}
			</button>
			<AlertTemplate
				alertModalState={alert}
				setAlertModalToggle={setAlert}
				alertType="warning"
			>
				<p className="alert-content">
					권한이 없습니다. <br />
					관리자에게 문의하세요.
				</p>
				<div className="alert-footer">
					<button
						ref={confirmButton}
						className="btn__solid"
						color="primary"
						onClick={() => setAlert(false)}
					>
						확인
					</button>
				</div>
			</AlertTemplate>
		</>
	);
};

export default AuthButton;
