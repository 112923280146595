export const SET_WORK_TYPE = "hrSetting/SET_WORK_TYPE";
export const CLEAR_WORK_TYPE = "hrSetting/CLEAR_WORK_TYPE";
export const SET_SELECTED_VACATION = "hrSetting/SET_SELECTED_VACATION";
export const CLEAR_SELECTED_VACATION = "hrSetting/CLEAR_SELECTED_VACATION";
export const SET_VACATION_SETTING = "hrSetting/SET_VACATION_SETTING";

export const setWorkType = (workType) => {
	return {
		type: SET_WORK_TYPE,
		payload: {
			workType: workType,
		},
	};
};

export const clearWorkType = () => ({
	type: CLEAR_WORK_TYPE,
});

export const setSelectedVacation = (selectedVacation) => {
	return {
		type: SET_SELECTED_VACATION,
		payload: {
			selectedVacation,
		},
	};
};

export const clearSelectedVacation = () => ({
	type: CLEAR_SELECTED_VACATION,
});

export const setVacationSetting = (setting) => {
	return {
		type: SET_VACATION_SETTING,
		payload: {
			setting,
		},
	};
};
