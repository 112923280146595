import React, { useEffect } from "react";
import PageHeader from "~/common/components/PageHeader";
import { ALLOW_COMMENT_STATE, API_STATE } from "~/common/constants/state";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import { useForm } from "react-hook-form";
import Form, { Group } from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import CommentUpload from "~/common/components/FormImageUploader/CommentUpload";
import { useNavigate, useParams } from "react-router";
import useRequest from "~/common/hooks/useRequest";
import { dateToString, formatBytes } from "~/utils/formatter";
import useAlert from "~/common/hooks/useAlert";
import { useGlobalStore } from "~/store";
import Comment from "~/pages/community/NoticePage/NoticeView/component/comment";

//icon
import {
	ArrowIcon,
	ClipIcon,
	CommentIcon,
	DownLoadIcon,
	FileLinkIcon,
	ListIcon,
} from "~/assets/svg/InterfacesIcon";

//assets
import "~/assets/sass/pages/community/communityDetail.scss";
import "~/assets/sass/pages/community/noticePage/noticePage.scss";
import noImg from "~/assets/img/defaultImg/profile-large.png";
import { useAtomValue } from "jotai";
import { CurrentNoticeAtom } from "~/atoms/Community";
import { Trans, useTranslation } from "react-i18next";

const View = ({ menu, type, noticeMove, noticeComment, noticeCommentRequest }) => {
	const { t } = useTranslation();
	const communityForm = useForm();
	const { idx } = useParams();
	const navigate = useNavigate();
	const [
		{
			sidebar: { currentPath },
		},
	] = useGlobalStore();

	const currentNotice = useAtomValue(CurrentNoticeAtom);

	const noticeDeleteRequest = useRequest("delete", "community/notice");
	const addCommentRequest = useRequest("post", `community/notice/comment/${idx}`);

	const [showWarningAlert, WarningAlert] = useAlert();

	const handleCommentSubmit = (data) => {
		if (!data.comment && data.files.length === 0) {
			communityForm.setError("comment", {
				type: "custom",
				message: t("Validate.require"),
			});
			return;
		}
		addCommentRequest.call(data, "body");
	};

	const buttonList = [
		<button
			className="btn__solid"
			color="red"
			onClick={() => {
				showWarningAlert()
					.then(() => {
						noticeDeleteRequest.call(idx, "path");
					})
					.catch(() => {});
			}}
		>
			{t("button.Delete")}
		</button>,
		<button
			className="btn__solid"
			color="primary"
			onClick={() => navigate(`/community/notice/detail/${idx}/edit`)}
		>
			{t("button.Modify")}
		</button>,
	];

	useEffect(() => {
		switch (addCommentRequest.state) {
			case API_STATE.done:
				communityForm.setValue("comment", "");
				communityForm.setValue("files", []);
				noticeCommentRequest.call(idx, "path");

				if (addCommentRequest.response.statusCode === 500) {
					console.log("error");
					navigate("/404");
				}
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
		}
	}, [addCommentRequest.state]);

	useEffect(() => {
		switch (noticeDeleteRequest.state) {
			case API_STATE.done:
				navigate("/community/notice");
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
		}
	}, [noticeDeleteRequest.state]);

	return (
		<>
			<PageHeader
				{...{ menu, type }}
				buttonList={currentPath.auth === "edit" ? buttonList : []}
			/>
			<div className="c__card community-detail-card">
				{/* 글 section */}
				<section className="content-section">
					<h3 className="content-title">{currentNotice?.title}</h3>

					{/* 프로필*/}
					<div className="content-profile">
						<div className="profile-img">
							<img src={currentNotice?.user.img ?? noImg} alt={t("Img.profile")} />
						</div>
						<dl className="profile-info">
							<dt>
								<span className="profile-name"> {currentNotice?.user.name}</span>
								<span className="profile-position">({currentNotice?.user.id})</span>
							</dt>
							<dd>
								<span className="profile-date">
									{dateToString(currentNotice?.createdAt, "yyyy.MM.dd HH:mm")}
								</span>
								<span className="profile-state">
									<Trans
										i18nKey="Community.NoticePage.View.profile-state"
										components={[{ currentNotice }]}
									/>
								</span>
							</dd>
						</dl>
					</div>

					{/* 게시글 */}
					<div
						className="content-text ql-editor"
						dangerouslySetInnerHTML={{ __html: currentNotice?.content }}
					/>

					{/* 첨부파일 */}
					{currentNotice?.uploadFiles.length !== 0 && (
						<div className="content-file">
							<div className="file-title">
								<ClipIcon />
								{t("Community.file-title")}
							</div>
							<ul className="file-list">
								{currentNotice?.uploadFiles.map((file, index) => (
									<a href={file.path} download={file.name} key={index}>
										<li className="file-item">
											<div className="file-icon">
												<FileLinkIcon />
											</div>
											<div className="file-contents">
												<p className="file-text">{file.name}</p>
												<p className="file-data">
													{formatBytes(file.size, 1)}
												</p>
											</div>
											<div className="file-download">
												<DownLoadIcon />
											</div>
										</li>
									</a>
								))}
							</ul>
						</div>
					)}
				</section>

				{currentNotice?.settings[0].value === ALLOW_COMMENT_STATE.ALLOW && (
					<section className="comment-section">
						<div className="comment-title">
							<CommentIcon />
							<span> {t("Community.comment")} </span>
							<span className="num">{noticeComment.totalCount}</span>
						</div>

						{/* 댓글 box */}
						<div className="comment-box">
							<Form useForm={communityForm} className={"Community-ListForm"}>
								<Textarea
									name="comment"
									placeholder={t("Community.textarea-placeholder")}
								/>
								<Group>
									<CommentUpload
										name="files"
										type={UPLOAD_TYPE.NOTICE_COMMENT_IMAGE}
										height="60px"
										width="60px"
										className={"community-img"}
										maxLength={5}
									/>
									<button
										className="btn__solid"
										color="primary"
										onClick={communityForm.handleSubmit(handleCommentSubmit)}
									>
										{t("button.register")}
									</button>
								</Group>
							</Form>
						</div>
						<ul className="comment-list">
							{noticeComment.comments &&
								noticeComment.comments.map((comment, index) => (
									<Comment
										comment={comment}
										commentRequest={noticeCommentRequest}
										key={index}
									/>
								))}
						</ul>
					</section>
				)}
			</div>
			{(noticeMove.next || noticeMove.prev) && (
				<div className="c__card community-move-card">
					{noticeMove.next && (
						<div
							className="move-item"
							onClick={() => {
								navigate(`/community/notice/detail/${noticeMove.next.idx}`);
							}}
						>
							<div className="next-icon">
								<ArrowIcon />
							</div>
							<div className="move-title">{t("Community.move-next")}</div>
							<div className="move-text">{noticeMove.next.title}</div>
						</div>
					)}
					{noticeMove.prev && (
						<div
							className="move-item"
							onClick={() =>
								navigate(`/community/notice/detail/${noticeMove.prev.idx}`)
							}
						>
							<div className="prev-icon">
								<ArrowIcon />
							</div>
							<div className="move-title">{t("Community.move-prev")}</div>
							<div className="move-text">{noticeMove.prev.title}</div>
						</div>
					)}
				</div>
			)}
			<div className="community-list-btn">
				<button
					className="btn__solid "
					color="primary"
					onClick={() => navigate("/community/notice")}
				>
					<ListIcon />
					{t("button.list")}
				</button>
			</div>
			<WarningAlert
				type="error"
				className="overtime-use-modal"
				layout="horiz"
				width={430}
				height={306}
				message={
					<>
						<h3>{t("Community.WarningAlert.h3")}</h3>
						<p>{t("Community.WarningAlert.p")}</p>
					</>
				}
				desc={<div className="desc-box error">{t("Community.WarningAlert.desc-box")}</div>}
				reject={t("button.cancel")}
				confirm={t("button.check")}
			/>
		</>
	);
};

export default View;
