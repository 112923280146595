import React from "react";
import { PAGE_STATE } from "~/common/constants/state";
import { useGlobalStore } from "~/store";
import { useTranslation } from "react-i18next";

const List = ({ vacationTypeList, setPageState, setSelectedVacation }) => {
	const { t } = useTranslation();
	const [{ user }] = useGlobalStore();
	const handleClick = (vacation) => {
		if (vacation.type === "연차" || parseFloat(vacation.remainingTime) > 0) {
			setSelectedVacation(vacation);
			setPageState(PAGE_STATE.EDIT);
		}
	};
	const vacationType = () =>
		vacationTypeList.map((vacation, idx) => (
			<div
				className={`vacation-bar ${
					vacation.type !== "연차" &&
					parseFloat(vacation.remainingTime) === 0 &&
					"disabled"
				}`}
				key={idx}
				onClick={() => handleClick(vacation)}
			>
				<div className="vacation-item">
					<span className="vacation-name">
						<i className="color" style={{ background: vacation.color }} />
						{vacation.title}
					</span>
					{!vacation.paid && (
						<span className="unpaid-badge">
							{t("HR.Manage.vacationList.Modal.List.noPay")}
						</span>
					)}
					{(vacation.type === "연차" || vacation.type === "법정필수휴가") && (
						<span className="public-holiday pre-wrap">
							{t("HR.Manage.vacationList.Modal.List.public-holiday")}
						</span>
					)}
				</div>
				<span
					className={`day-status ${
						vacation?.remainingTime.toString().charAt(0) === "-" && "minus"
					}`}
				>
					{vacation?.remainingTime}
				</span>
			</div>
		));

	return (
		<>
			<div className="modal-content">
				{user.info.joinDate === null && (
					<div className={"warning-phrase"}>
						{user.info.name} 님의 인사정보가 등록되어 있지 않아 연차 정보가 정확하지
						않을 수 있습니다.
						<br /> 관리자에게 문의하여 인사정보를 등록 해주세요.
					</div>
				)}
				{vacationType()}
			</div>
		</>
	);
};

export default List;
