import React from "react";
import pluginFeed from "~/assets/img/sample/plugin-feed.jpg";
import pluginDoc from "~/assets/img/sample/plugin-doc.jpg";
import pluginNews from "~/assets/img/sample/plugin-news.jpg";
import pluginSupplyChain from "~/assets/img/sample/plugin-supply-chain.jpg";

export const PluginDescConstant = [
	{
		//기업
		idx: 1,
		desc: "혁신적인 아이디어를 실현하기 위한 팀원, 제품, 기업정보를 관리하세요. 다른 기업들과 투자자에게 정보를 공유하고 신규 비즈니스 파트너와 투자자에게 더욱 빠르게 연결해보세요",
		feature: [
			"스타트업 성장을 위한 투자정보, 팀원 관리, 제품 관리 기능을 제공합니다.",
			"단한번의 작성을 통해 깃발내 모든 투자/사업 신청시 자동으로 정보가 작성됩니다.",
			"사업자 등록번호 조회를 통한 정보 작성을 최소화 하였습니다.",
			"공개설정을 통해 더 상세한 기업정보, 투자정보, 제품정보는 다른 기업 또는 투자자에게 공유되어 스타트업의 홍보/마켓팅에 도움이 됩니다.",
			"핏이 맞는 투자저 추천을 위해서 기업정보와 투자정보를 함께 분석하여 다음라운드를 위한 투자자를 추천합니다",
		],
	},
	{
		//인사
		idx: 2,
		desc: "재직증명서, 경력증명서, 팀원관리, 급여관리 , 연차, 결제 등과 같은 반복적인 업무는 스타트업에게도 늘 고민거리였습니다. 지금 바로 팀원들을 초대하고 쉽고 간편하게 인사정보를 관리해보세요",
		feature: [],
		license: "Pro",
	},
	{
		//IR
		idx: 3,
		desc: "투자를 생각하는 스타트업이라면 한번쯤은 작성해야할 어렵고 복잡한 IR 정보!!! 이제는 단 한번의 작성으로 체계적인 성장 관리와 투자유치까지 한번에 진행해보세요.",
		feature: [
			"IR 정보에 꼭 들어가야할 항목들만 정리할수있도록 템플릿을 제공합니다.",
			"IR 정보입력을 통해 온라인투자 요청 투자자에게 빠르게 전달할수있습니다.",
			"잊혀지기 쉬운 IR 정보를 매년 작성하고 관리합니다.",
			"지분율, 재무현황에 대한 자동 계산 기능",
		],
	},
	{
		//투자
		idx: 4,
		desc: "여기저기 흩어져있는 투자정보 지원사업들.. 그리고 어려운 투자자 컨텍으로 많은 시간과 리소스를 투입하고있나요? 온/오프라인 투자심사와 투자관련 라운드 정보 그리고 정부 지원 사업까지 한번에 !!!",
		feature: [
			"기업정보와 IR 정보 입력이 완료되어야 서비스 이용이 가능합니다.",
			"투자자로부터 직접 온라인심사 요청을 받을수있습니다.",
			"심사요청에 대한 승인및 거부를 할수있습니다.",
			"심사요청자료로 작성된 IR 정보와 발표자료를 전달하고 투자자로 부터 피딩을 받을수있습니다.",
		],
	},
	{
		//주주
		idx: 5,
		desc: "스타트업을 시작하기 전에 가장 먼저해야 할 주주들의 권리 보장! 바로 주주명부 작성입니다. 투자를 받은 기업이 아니더라도 회사의 주주 설정은 향후의 기업 가치를 대비해서 매우 중요한 일입니다. 주주클럽을 통해서 함께 구슬땀 흘리는 핵심 코파운더와 투자자들 주식정보를 간편하게 관리해보세요.",
		feature: [
			"시즌별 주주 명부를 언재든 작성하고 관리합니다.",
			"주주명부는 투자자와 연동되어 그간 반복적인 주주명부 작성및 제출에서 해방될수있습니다. (1.1버전에서 지원예정)",
			"인증된 사용자일지라도 암호인증을 통해 주주명부 정보에 접근할수있습니다.",
			"하이퍼렛져 블록체인 인증을 통해 민감정보에 대한 접근 보안을 강화하였습니다.",
		],
	},
	{
		//VC정보
		idx: 6,
		desc: "기업정보를 어필할 국내 VC 정보를 모두 찾아보고, 핏이 맞는 투자사를 빠르게 선택하세요. 그리고 투자양식을 원하는 투자사에게 전달해보세요.",
		feature: [
			"투자자 유형과 집중투자 정보를 확인할 수 있습니다.",
			"투자사의 총 투자 집행 내역을 확인할 수 있습니다.",
			"현재 깃발을 통해 진행중인 라운딩이 있는지를 확인할 수 있습니다.",
			"투자를 진행하였던 투자 조합을 확인할 수 있습니다.",
			"바로 투자지원이 가능한 웹페이지를 제공하여 빠르게 투자 지원서를 보낼 수 있습니다.",
		],
	},
	{
		//세무
		idx: 7,
		desc: "비싸고 전화로만 하는 세무 상담은 이제그만 !!! 시중 대비 약 30% 저렴한 세무 대행 서비스를 받고 세무 히스토리 관리와 어려운 세무 관련 질의는 플랫폼을 통해 바로 해결하세요",
		feature: [],
		license: "Pro",
	},
	{
		//커뮤니티
		idx: 8,
		desc: "다양한 니즈에 맞는 오픈/비공개 커뮤니티를 만들고 참여자들과 정보를 공유하세요. 이제 팀별 공지사항및 각종 커뮤니티 공유정보는 이곳에서 모두 관리하세요.",
		feature: [
			"팀별 전체 공지사항을 관리할수 있습니다.",
			"다양한 커뮤니티방을 자유롭게 만들고 참여자를 초대할수있습니다.",
			"커뮤니티별 참여자 권한을 조정하여 원하는 참여자와 커뮤니티를 운영할수있습니다.",
			"각 공지의 댓글기능을 따로 관리할수있습니다.",
		],
	},
];

export const UnusedPlugin = [
	{
		name: "공급망분석",
		thumbImg: <img src={pluginSupplyChain} alt="서플라인 체인 플러그인" />,
		license: "Enterprise",
		description:
			"자사 제품의 생산, 배달, 기타 모든 공급 관련 활동을 수행하는 거래처를 빠르게 찾고 관리해 보세요.",
	},
	{
		name: "소식알리기",
		thumbImg: <img src={pluginFeed} alt="Feed 플러그인" />,
		license: "Free",
		description:
			"기업의 새로운 소식을 알리고 싶은 기업, 투자자를 골라 빠르게 전달할 수 있어요.",
	},
	{
		name: "스타트업 뉴스",
		thumbImg: <img src={pluginNews} alt="뉴스 플러그인" />,
		license: "Free",
		description:
			"기업의 창업과 성공, 투자, 기술 등 스타트업들의 새로운 소식만 골라 볼 수 있어요.",
	},
	{
		name: "문서관리",
		thumbImg: <img src={pluginDoc} alt="문서 플러그인" />,
		license: "Free",
		description:
			"자주 사용하는 문서, 관리가 필요한 문서, 접근 권한이 필요한 문서 등 우리 기업의 다양한 문서를 관리할 수 있어요.",
	},
];
