import React from "react";
import "~/assets/sass/component/loading.scss";

const Loading = () => {
	return (
		<div className={`c__loader`}>
			<div className={`loader`} />
		</div>
	);
};
export default Loading;
