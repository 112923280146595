import React from "react";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";

const NumberInput = (props) => {
	const { t } = useTranslation();
	const {
		control,
		placeholder,
		name,
		type,
		thousandSeparator,
		required,
		unit,
		string,
		decimalScale = 0,
		readonly,
		validation,
		max = 999999999999999,
	} = props;

	const defaultPlaceholderList = {
		social: "000000-0000000",
		company: "000-00-00000",
		phone: "010-0000-0000",
	};

	const socialFormat = (e) => {
		const $1 = e.substring(0, 6);
		const $2 = e.substring(6, 13);
		return $1 + ($2.length ? "-" + $2 : "");
	};

	const companyFormat = (e) => {
		const $1 = e.substring(0, 3);
		const $2 = e.substring(3, 5);
		const $3 = e.substring(5, 10);
		return $1 + ($2.length ? "-" + $2 : "") + ($3.length ? "-" + $3 : "");
	};

	const phoneFormat = (e) => {
		if (e.substring(0, 2) === "02") {
			if (e.length > 9) {
				const $1 = e.substring(0, 2);
				const $2 = e.substring(2, 6);
				const $3 = e.substring(6, 10);
				return $1 + ($2.length ? "-" + $2 : "") + ($3.length ? "-" + $3 : "");
			} else {
				const $1 = e.substring(0, 2);
				const $2 = e.substring(2, 5);
				const $3 = e.substring(5, 9);
				return $1 + ($2.length ? "-" + $2 : "") + ($3.length ? "-" + $3 : "");
			}
		} else if (e.length > 10) {
			const $1 = e.substring(0, 3);
			const $2 = e.substring(3, 7);
			const $3 = e.substring(7, 11);
			return $1 + "-" + $2 + ($3.length ? "-" + $3 : "");
		} else {
			const $1 = e.substring(0, 3);
			const $2 = e.substring(3, 6);
			const $3 = e.substring(6, 10);
			return $1 + ($2.length ? "-" + $2 : "") + ($3.length ? "-" + $3 : "");
		}
	};

	const phoneRule = {
		minLength: { value: 9, message: t("components.NumberInput.validate.0") },
		maxLength: { value: 11, message: t("components.NumberInput.validate.0") },
	};

	const companyRule = {
		minLength: { value: 10, message: t("components.NumberInput.validate.1") },
		maxLength: { value: 10, message: t("components.NumberInput.validate.1") },
	};

	const ssnRule = {
		minLength: { value: 13, message: t("components.NumberInput.validate.2") },
		maxLength: { value: 13, message: t("components.NumberInput.validate.2") },
	};

	return (
		<Controller
			control={control}
			name={name}
			rules={{
				required:
					required === true ? t("Validate.require") : required === null ? " " : required,
				maxLength: { value: 49, message: t("components.NumberInput.validate.3") },
				...(type === "phone"
					? phoneRule
					: type === "company"
					? companyRule
					: type === "social"
					? ssnRule
					: {}),
				...validation,
			}}
			render={({ field }) => {
				return (
					<>
						<NumberFormat
							className={`c__input f__item`}
							placeholder={
								placeholder ? placeholder : type && defaultPlaceholderList[type]
							}
							autoFocus
							format={
								type &&
								((e) => {
									switch (type) {
										case "social":
											return socialFormat(e);
										case "company":
											return companyFormat(e);
										case "phone":
											return phoneFormat(e);
										default:
											break;
									}
								})
							}
							allowLeadingZeros={type || string}
							defaultValue={field.value}
							value={string ? field.value?.toString() : field.value}
							onValueChange={({ value, floatValue }) => {
								field.onChange(
									type || string || floatValue === undefined ? value : floatValue
								);
							}}
							thousandSeparator={
								type === undefined && thousandSeparator !== false && true
							}
							readOnly={readonly}
							decimalScale={decimalScale}
							isAllowed={(value) =>
								value.floatValue <= max || value.floatValue === undefined
									? true
									: false
							}
							fixedDecimalScale
						/>
						{unit !== "" && <span className="unit">{unit}</span>}
					</>
				);
			}}
		/>
	);
};

export default NumberInput;
