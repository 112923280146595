import React, {useEffect, useState} from 'react';
import defaultAvatar from "~/assets/img/defaultImg/profile-large.png";
import {useTranslation} from "react-i18next";
import {useGlobalStore} from "~/store";
import useRequest from "~/common/hooks/useRequest";
import moment from "moment";

const MyInfo = (props) => {
    const {auth, setVacationTypeListModal} = props;
    const { t } = useTranslation();
    const [{ user }] = useGlobalStore();

    const object = {
        admin: "슈퍼관리자",
        manager: "관리자",
        user: "팀원"
    }

    const [progress, setProgress] = useState(null);

    const userDetailRequest = useRequest("get", "user/info");

    // percent bar calculate
    const AnnualLeavesProgress = (vacationData) => {
        const total = vacationData?.totalVacationTime;
        const rest = vacationData?.remainingVacationTime;
        if(!total && !rest) return 0;
        const used = total - rest;
        const percentage = (used / total) * 100;
        setProgress(percentage);
    }

    useEffect(() => {
        if (user) {
            AnnualLeavesProgress(user.info.hrRemainVacation);
        } else {
            userDetailRequest.asyncCall().then((res) => {
                AnnualLeavesProgress(res.data.user.hrRemainVacation);
            });
        }
    }, [user]);



    return(
        <>
            <div className={"my-info"}>
                <div className="basic-info">
                    <img
                        src={defaultAvatar}
                        onError={(e) => {
                            e.target.src = defaultAvatar;
                        }}
                        alt={"profile image"}
                    />
                    <div className="profile-content">
                        <p className="profile-authority">
                            { user?.info?.role && object[`${user?.info?.role}`] }
                        </p>
                        <p className={"profile-name"}>{user?.info?.name ?? ""}</p>
                        <p className={"profile-email"}>{user?.info?.id ?? ""}</p>
                    </div>
                </div>

                <div className={"vacation-info"}>
                    <div className="vacation-top">
                        <p className={"title"}>{t("Dashboard.Contents.userInfo.title")}<span>(입사일: {user?.info?.joinDate && moment(user?.info?.joinDate).format("YYYY.MM.DD")})</span></p>
                        <button className="btn__solid btn_apply_vacation" color="primary"
                                onClick={() => setVacationTypeListModal ? setVacationTypeListModal(true): {}}>
                            휴가신청
                        </button>
                    </div>
                    <div className="progress-wrap">
                        <div className="status-wrap">
                            <p className={"status-item use"}>사용 연차 <span className={"count"}>{user?.info?.hrRemainVacation?.totalVacationTime ?? 0}</span></p>
                            <p className={"status-item rest"}>잔여 연차 <span className={"count"}>{user?.info?.hrRemainVacation?.remainingVacationTime ?? 0}</span></p>
                        </div>
                        <div className="progress-bar">
                            {
                                progress  && <div className="progress-bar-active" style={{ width: `${progress}%` }} />
                            }
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default MyInfo
