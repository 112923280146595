import React, { useEffect, useState } from "react";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";

//assets
import "~/assets/sass/pages/community/communityList.scss";
import "~/assets/sass/pages/community/boardPage/boardPage.scss";
import { SearchIcon, TableListIcon, TableAlbumIcon } from "~/assets/svg/InterfacesIcon";

//components
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import Pagination from "~/common/components/Pagination";
import CustomSelect from "~/common/components/CustomSelect";
import { optionsGenerator } from "~/common/components/Select";
import ListView from "~/pages/community/BoardPage/BoardPost/List/ViewType/listView";
import CardView from "~/pages/community/BoardPage/BoardPost/List/ViewType/cardView";
import AlbumView from "~/pages/community/BoardPage/BoardPost/List/ViewType/albumView";
import Member from "~/pages/community/BoardPage/BoardPost/List/member";
import NoData from "~/common/components/NoData";
import { CommunityPaginationAtom, CurrentBoardAtom } from "~/atoms/Community";
import { useAtom, useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const List = () => {
	const { t } = useTranslation();
	const currentBoard = useAtomValue(CurrentBoardAtom);
	const navigate = useNavigate();
	const { idx } = useParams();

	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	const [viewType, setViewType] = useState("list");
	const [boardList, setBoardList] = useState([]);
	const [searchType, setSearchType] = useState("ALL");
	const [totalPage, setTotalPage] = useState(0);
	const [pageNumber, setPageNumber] = useAtom(CommunityPaginationAtom);
	const [isBoardEmpty, setIsBoardEmpty] = useState(true);

	const [managerList, setManagerList] = useState([]);
	const [participantList, setParticipantList] = useState([]);

	const [searchValue, setSearchValue] = useState("");
	const [searchedValue, setSearchedValue] = useState("");

	const boardDetailListRequest = useRequest(
		"post",
		`community/board/search?community=${idx}&type=${viewType}`
	);
	const memberRequest = useRequest("post", `community/member/search/${idx}`);

	const pageSize = 10;
	const searchOptions = {
		ALL: translation.t("Community.BoardPage.BoardPost.List.searchOptions.ALL"),
		TITLE: translation.t("Community.BoardPage.BoardPost.List.searchOptions.TITLE"),
		CONTENT: translation.t("Community.BoardPage.BoardPost.List.searchOptions.CONTENT"),
		NAME: translation.t("Community.BoardPage.BoardPost.List.searchOptions.NAME"),
		COMMENT: translation.t("Community.BoardPage.BoardPost.List.searchOptions.COMMENT"),
	};

	const searchBoardList = async () => {
		setSearchedValue(searchValue);
		setPageState(PAGE_STATE.LOADING);
		await boardDetailListRequest.asyncCall(
			{ size: pageSize, page: pageNumber, queryType: searchType, query: searchValue },
			"body"
		);
	};

	const getMember = (query) => {
		memberRequest.call(
			{
				page: 1,
				size: 1000,
				query: query,
			},
			"body"
		);
	};

	useEffect(() => {
		switch (boardDetailListRequest.state) {
			case API_STATE.done:
				const response = boardDetailListRequest.response.data;

				setBoardList(response.boards);
				setTotalPage(response.pagination.totalPage);
				setPageNumber(response.pagination.page);
				setIsBoardEmpty(response.boardEmpty);

				setPageState(response.boards.length > 0 ? PAGE_STATE.VIEW : PAGE_STATE.EMPTY);
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [boardDetailListRequest.state]);

	useEffect(() => {
		switch (memberRequest.state) {
			case API_STATE.done:
				const response = memberRequest.response.data;
				setManagerList(response.managers);
				setParticipantList(response.members);
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [memberRequest.state, memberRequest.response]);

	useEffect(() => {
		boardDetailListRequest.call(
			{
				size: pageSize,
				page: pageNumber,
				queryType: searchType,
				query: searchValue,
			},
			"body"
		);
	}, [pageNumber, viewType]);

	const headerButton = [
		<button className="btn__solid" color={"primary"} onClick={() => navigate(`add`)}>
			{t("button.registering")}
		</button>,
	];

	return (
		<NavbarAndSidebarLayout menu={["community", "community"]} type="main">
			<PageHeader buttonList={headerButton} />
			<div className="p__community-list">
				<section className="c__card community-list-section">
					<div className="card-title">{currentBoard?.name}</div>

					<div className="view-header">
						<ul className="view-list">
							<li
								className={`view-item ${viewType === "list" ? "active" : ""}`}
								onClick={() => {
									setPageState(PAGE_STATE.LOADING);
									setViewType("list");
								}}
							>
								<TableListIcon />
							</li>
							<li
								className={`view-item ${viewType === "card" ? "active" : ""}`}
								onClick={() => {
									setPageState(PAGE_STATE.LOADING);
									setPageNumber(1);
									setViewType("card");
								}}
							>
								<TableAlbumIcon />
							</li>
						</ul>
						<div className="search-box">
							<CustomSelect
								placeholder="선택"
								selectName="status-option"
								value={searchType}
								setValue={setSearchType}
								options={optionsGenerator(searchOptions)}
							/>
							<div className="c__search ">
								<input
									className="c__input"
									placeholder={t(
										"Community.BoardPage.BoardPost.List.searchPlaceholder"
									)}
									onChange={(e) => setSearchValue(e.target.value)}
									onKeyDown={async (e) => {
										if (e.key === "Enter") {
											await searchBoardList();
										}
									}}
								/>
								<button
									type="button"
									className="btn-search"
									onClick={searchBoardList}
								>
									<SearchIcon />
								</button>
							</div>
						</div>
					</div>
					<If condition={pageState}>
						<Case condition={PAGE_STATE.LOADING}>
							<Loading />
						</Case>
						<Case condition={PAGE_STATE.VIEW}>
							<If condition={viewType} {...{ boardList }}>
								<Case condition={"list"}>
									<ListView />
								</Case>
								<Case condition={"content"}>
									<CardView />
								</Case>
								<Case condition={"card"}>
									<AlbumView />
								</Case>
							</If>
							<Pagination
								setPageNumber={setPageNumber}
								currentPage={pageNumber}
								totalPage={totalPage}
							/>
						</Case>
						<Case condition={PAGE_STATE.EMPTY}>
							{searchedValue ? (
								<NoData
									main={t("Community.BoardPage.BoardPost.List.pageNoData1.main")}
									sub={t("Community.BoardPage.BoardPost.List.pageNoData1.sub")}
								/>
							) : (
								<NoData
									main={
										viewType === "card" && !isBoardEmpty
											? t(
													"Community.BoardPage.BoardPost.List.pageNoData2.main1"
											  )
											: t(
													"Community.BoardPage.BoardPost.List.pageNoData2.main2"
											  )
									}
									sub={t("Community.BoardPage.BoardPost.List.pageNoData2.sub")}
								/>
							)}
						</Case>
					</If>
				</section>
				<Member
					{...{
						managerList,
						participantList,
						getMember,
					}}
				/>
			</div>
		</NavbarAndSidebarLayout>
	);
};

export default List;
