import React from "react";
import bgImg from "~/assets/img/bg-okceo-dashboard.png";
import taxLogoImg from "~/assets/img/tax-logo.png";
import AuthButton from "~/common/components/AuthButton";
import dashboardImg from "~/assets/img/okceo-dashboard-img.png";
import enDashboardImg from "~/assets/img/EN/okceo-dashboard-img_en.png";

// Assets
import "~/assets/sass/pages/tax/taxDashboard.scss";
import "~/assets/sass/pages/tax/common/index.scss";
import { useNavigate } from "react-router";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Empty = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const langImg = i18next.language;

	return (
		<>
			<div className="bg-img-wrap">
				<img src={bgImg} alt={t("Tax.Dashboard.Empty.bg-img-wrap")} />
			</div>
			<div className="page-wrap">
				<section>
					<div className="tax-logo-wrap">
						<img src={taxLogoImg} alt={t("Tax.Dashboard.Empty.tax-logo-wrap")} />
					</div>
					<div className="phrase-wrap">
						<h3>{t("Tax.Dashboard.Empty.phrase-wrap.h3")}</h3>
						<p>{t("Tax.Dashboard.Empty.phrase-wrap.p")}</p>
					</div>
					<div className="btn-wrap">
						<AuthButton
							type="button"
							className="btn__solid"
							color="primary"
							onClick={() => navigate("/tax/setting")}
						>
							{t("Tax.btn-interlock")}
						</AuthButton>
						<button
							type="button"
							className="btn__solid"
							color="gray"
							onClick={() => window.open("https://okceo.co.kr/")}
						>
							{t("Tax.btn-okceo")}
						</button>
					</div>
				</section>
				<section className="img-wrap">
					<img
						src={langImg === "ko" ? dashboardImg : enDashboardImg}
						alt={t("Tax.Dashboard.Empty.img-wrap")}
					/>
				</section>
			</div>
		</>
	);
};

export default Empty;
