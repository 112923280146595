import React, { useEffect, useState } from "react";

import { useFieldArray, useForm } from "react-hook-form";
import useRequest from "~/common/hooks/useRequest";

import PageHeader from "~/common/components/PageHeader";

import EditCard from "./EditCard";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import Modal from "./Modal";

//Assets
import { LightPlusIcon, PlusIcon } from "~/assets/svg/MathIcon";
import useAlert from "~/common/hooks/useAlert";
import { API_STATE } from "~/common/constants/state";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Edit = ({ showViewPage, menu, type, data }) => {
	const { t } = useTranslation();
	const [getTeamModal, setGetTeamModal] = useState(false);
	const navigate = useNavigate();

	const memberForm = useForm({
		defaultValues: { member: data },
	});

	const { fields, append, remove } = useFieldArray({
		control: memberForm.control,
		name: "member",
	});

	const teamListMutation = useRequest("post", "company/member");

	const submit = ({ member }) => {
		const DedupedMembers = member.reduce((accumulator, current) => {
			if (
				accumulator.findIndex(
					(item) =>
						item.name === current.name &&
						item.department === current.department &&
						item.position === current.position
				) === -1
			) {
				accumulator.push(current);
			}
			return accumulator;
		}, []);
		teamListMutation.call(DedupedMembers);
	};

	const [showCancelAlert, CancelAlert] = useAlert();
	const [showWarningAlert, WarningAlert] = useAlert();

	const buttonList = [
		<button type="button" className="dashed-btn plus-btn" onClick={() => setGetTeamModal(true)}>
			<PlusIcon />
			{t("Company.Member.Edit.getTeamModal")}
		</button>,
		<button
			type="button"
			className="btn__solid"
			color="gray"
			onClick={() => {
				showCancelAlert().then(() => showViewPage());
			}}
		>
			{t("button.cancel")}
		</button>,
		<button
			type="button"
			onClick={() => showWarningAlert().then(memberForm.handleSubmit(submit))}
			className="btn__solid"
			color="primary"
		>
			{t("button.save")}
		</button>,
	];

	const CARD_INIT_DATA = {
		img: "",
		name: "",
		position: "",
		department: "",
		introduction: "",
	};

	useEffect(() => {
		switch (teamListMutation.state) {
			case API_STATE.done:
				showViewPage();
				break;
			case API_STATE.error:
				navigate("/404");
		}
	}, [teamListMutation.state]);

	return (
		<>
			<PageHeader {...{ menu, type, buttonList }} />
			<div className="c__cardRow company-member-edit">
				{fields.map((field, index) => {
					const getName = (name) => `member.${index}.${name}`;
					const removeItem = () => remove(index);
					return (
						<EditCard
							key={field.id}
							name={getName}
							append={append}
							remove={removeItem}
							useForm={memberForm}
						/>
					);
				})}
				<div onClick={() => append(CARD_INIT_DATA)} className="c__card member-add-card">
					<LightPlusIcon />
				</div>
			</div>
			<ModalTemplate
				modalState={getTeamModal}
				setModalToggle={setGetTeamModal}
				title={t("Company.Member.Edit.getTeamModal")}
				width={680}
				className="getTeamInfo-modal"
			>
				<Modal
					importUser={(user) => {
						append(user);
					}}
					{...{ fields }}
				/>
			</ModalTemplate>
			<CancelAlert
				message={t("Company.Member.Edit.CancelAlert")}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
			<WarningAlert
				message={t("Company.Member.Edit.WarningAlert")}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
		</>
	);
};

export default Edit;
