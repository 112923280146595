import { SET_CURRENT_MENU, SET_SIDEBAR_LAYOUT, SET_SIDEBAR_LIST } from "./actions";
import persistHelper from "~/utils/persist";

export const INITIAL_STATE = {
	authMap: {},
	sideBarList: null,
	currentPath: "",
	layout: "",
};

export const PERSISTED_STATE = persistHelper.initState("sidebar", INITIAL_STATE);

export const REDUCER = (state = PERSISTED_STATE, action) => {
	switch (action.type) {
		case SET_SIDEBAR_LIST:
			const menuList = action.payload.sideBarList;
			const menuTypes = Object.keys(menuList);

			const authMap = menuTypes.reduce((type_acc, type_cur) => {
				const mainMap = menuList[type_cur].reduce((main_acc, main_cur) => {
					const subMap = main_cur.subMenu.sort(function (a, b) {
						if (a.order > b.order) {
							return 1;
						}
						if (a.order < b.order) {
							return -1;
						}
						return 0;
					});

					const sortedSubMap = subMap.reduce((sub_acc, sub_cur) => {
						return {
							...sub_acc,
							[sub_cur.id]: sub_cur.auth,
						};
					}, {});
					return { ...main_acc, [main_cur.id]: sortedSubMap };
				}, {});
				return {
					...type_acc,
					[type_cur]: mainMap,
				};
			}, {});

			return persistHelper.setPersist("sidebar", {
				...state,
				authMap: authMap,
				sideBarList: menuList,
			});
		case SET_CURRENT_MENU:
			const mainMenuList = state.sideBarList?.[action.payload.type];
			const subMenuList = mainMenuList?.find(({ id }) => id === action.payload.main)?.subMenu;
			const auth = subMenuList?.find(({ id }) => id === action.payload.sub)?.auth;
			return {
				...state,
				currentPath: {
					type: action.payload.type,
					main: action.payload.main,
					sub: action.payload.sub,
					auth: auth,
				},
			};
		case SET_SIDEBAR_LAYOUT:
			return {
				...state,
				layout: action.payload.layout,
			};
		default:
			return state;
	}
};
