import React, { useEffect } from "react";

import "~/assets/sass/pages/shareholder/edit.scss";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import { useNavigate } from "react-router";
import useRequest from "~/common/hooks/useRequest";
import { COMPANY_INFO } from "~/store/company/repository";
import useRepository from "~/common/hooks/useRepository";
import Edit from "./Edit";

const ShareholderAdd = () => {
	const navigate = useNavigate();

	const [getCompanyInfo] = useRepository(COMPANY_INFO);

	useEffect(() => {
		getCompanyInfo();
	}, []);

	const shareholderDetail = {
		idx: undefined,
		title: "",
		sign: "",
		regDate: new Date().toString(),
		ShareholderMember: [],
	};

	const shareholderAddMutation = useRequest("post", "shareholder/add");

	const onSubmit = (data) => {
		shareholderAddMutation.asyncCall(data).then(() => {
			navigate("/shareholder/list");
		});
	};

	const onCancel = () => {
		navigate("/shareholder/list");
	};

	return (
		<NavbarAndSidebarLayout menu={["shareholder", "list"]} type="main">
			<Edit {...{ shareholderDetail, onSubmit, onCancel }} />
		</NavbarAndSidebarLayout>
	);
};

export default ShareholderAdd;
