import React from "react";
import { dateToString } from "~/utils/formatter";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";

const CardBack = ({ careerList, setMemberDetailModal, handleCallback }) => {
    const { t } = useTranslation();
    const careers = () => {
        const dateFormat = "yyyy-MM";
        if (careerList.length > 0) {
            return careerList.map((career, index) => {
                return (
                    <li key={index} className="career">
                        <p className="work">{career.companyName}</p>
                        <p className="date">
                            {dateToString(career.startDate, dateFormat)}~
                            {dateToString(career.endDate, dateFormat)}
                        </p>
                    </li>
                );
            });
        } else {
            return (
                <div className={`c__noData`}>
                    <button className={"add-button"} onClick={()=> setMemberDetailModal(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                            <path d="M29.1678 8.16665C29.1678 7.52233 28.6454 7 28.0011 7C27.3567 7 26.8344 7.52233 26.8344 8.16665V26.834H8.16644C7.5221 26.834 6.99976 27.3564 6.99976 28.0007C6.99976 28.645 7.5221 29.1673 8.16644 29.1673H26.8344V47.8347C26.8344 48.479 27.3567 49.0014 28.0011 49.0014C28.6454 49.0014 29.1678 48.479 29.1678 47.8347V29.1673H47.8357C48.4801 29.1673 49.0024 28.645 49.0024 28.0007C49.0024 27.3564 48.4801 26.834 47.8357 26.834H29.1678V8.16665Z" fill="#767676"/>
                        </svg>
                    </button>
                    <p className="main">{t("MyPage.AccountPage.career-Nodata")}</p>
                </div>
                // <NoData main={t("MyPage.AccountPage.career-Nodata")} />;
            )
        }
    };
    return (
        <div className="c__card back">
            <div className="content-wrap">
                <div className="content">
                    <p className="title">
                        {t("MyPage.AccountPage.career-title")}
                        <button className={"button-update"} onClick={()=> setMemberDetailModal(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M15.0335 5.35C15.5001 4.88333 16.3168 4.88333 16.7835 5.35L18.6501 7.21668C19.1168 7.68334 19.1168 8.50001 18.6501 8.96668L18.1835 9.43335C17.7168 9.90002 16.9001 9.90002 16.4335 9.43335L14.5668 7.56668C14.1001 7.10001 14.1001 6.28334 14.5668 5.81667L15.0335 5.35ZM12.5834 7.7999C12.5834 7.68324 12.7 7.68324 12.8167 7.7999L16.2 11.1832C16.3167 11.1832 16.3167 11.2999 16.2 11.4166L9.55002 18.0666H9.43335L5.23333 18.9999C5.11667 18.9999 5 18.8833 5 18.7666L5.93334 14.5666V14.4499L12.5834 7.7999Z" fill="#D7D7D7"/>
                            </svg>
                        </button>
                    </p>
                    <div className="scroll-wrap">
                        <ul className="career-list">{careers()}</ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardBack;
