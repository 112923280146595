import React from "react";
import { useNavigate } from "react-router-dom";
import { BIZ_TYPE } from "~/common/constants/form";
import { useTranslation } from "react-i18next";

// Assets
import preFounder from "~/assets/img/img-pre-founder.png";
import businessperson from "~/assets/img/img-businessperson.png";

const JoinType = ({ setCurrentStep, bizType, setBizType }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleCheckType = (type) => {
		setBizType(type);
		setCurrentStep("joinForm");
	};
	const BIZTYPE = BIZ_TYPE();

	return (
		<>
			<div className="type-wrap">
				<div
					className={`type-select ${bizType === BIZTYPE.PROSPECTIVE.value && "active"}`}
					onClick={() => handleCheckType(BIZTYPE.PROSPECTIVE.value)}
				>
					<div className="img-wrap">
						<img
							src={preFounder}
							alt={t("Auth.Join.Type.type-name.pre-cofounder.alt")}
						/>
					</div>
					<div className="type-name">
						{t("Auth.Join.Type.type-name.pre-cofounder.name")}
					</div>
				</div>
				<div
					className={`type-select ${bizType === "entrepreneur" && "active"}`}
					onClick={() => handleCheckType("entrepreneur")}
				>
					<div className="img-wrap">
						<img
							src={businessperson}
							alt={t("Auth.Join.Type.type-name.cofunder.alt")}
						/>
					</div>
					<div className="type-name active">
						{t("Auth.Join.Type.type-name.cofunder.name")}
					</div>
				</div>
			</div>
			<p className="phrase">{t("Auth.Join.Type.phrase")}</p>
			<p className="navigate-login">
				{t("Auth.Join.navigate-login.title")}
				<button onClick={() => navigate("/login")}>{t("button.login")}</button>
			</p>
		</>
	);
};

export default JoinType;
