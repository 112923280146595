import React from "react";
import DropdownTemplate from "~/common/components/DropdownPortal/DropdownTemplate";
import { PAGE_STATE } from "~/common/constants/state";
import EmptyCase from "~/common/components/EmptyCase";
import Loading from "~/common/components/Loading";
import { useTranslation } from "react-i18next";

const View = ({ lastElementRef, pageState, signatureList, setActiveSignIdx, setDeleteSignIdx }) => {
	const { t } = useTranslation();
	const handleActive = (s) => {
		setActiveSignIdx(s);
	};

	const handleDelete = (s) => {
		setDeleteSignIdx(s);
	};

	const signatureCard = () => {
		return signatureList.map((s, s_idx) => {
			return (
				<div
					className={`c__card ${s.activation && "active"}`}
					key={s_idx}
					ref={s_idx + 1 === signatureList.length ? lastElementRef : null}
				>
					{s.activation && (
						<div className="c__badge">
							{t("MyPage.admin.Signature.view.activation")}
						</div>
					)}
					<DropdownTemplate target={s.idx} vertical>
						<li className="c__dropdownItem" onClick={() => handleActive(s.idx)}>
							{s.activation
								? t("MyPage.admin.Signature.view.deactivation")
								: t("MyPage.admin.Signature.view.activation")}
						</li>
						<li className="c__dropdownItem" onClick={() => handleDelete(s.idx)}>
							{t("MyPage.admin.Signature.view.delete")}
						</li>
					</DropdownTemplate>
					<div className="img-wrap">
						<img src={s.img} alt={t("MyPage.admin.Signature.view.sample")} />
					</div>
					<p className="date">Updated: {s.updateDate}</p>
				</div>
			);
		});
	};

	return (
		<>
			{pageState === PAGE_STATE.EMPTY ? (
				<EmptyCase
					main={t("MyPage.admin.Signature.view.empty.main")}
					sub={t("MyPage.admin.Signature.view.empty.sub")}
				/>
			) : (
				<>
					<div className="c__cardRow vc-info-list">{signatureCard()}</div>
					{pageState === PAGE_STATE.LOADING && <Loading />}
				</>
			)}
		</>
	);
};
export default View;
