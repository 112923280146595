import React, { useEffect, useRef } from "react";
import OrgChart, { OrgChartConfig } from "react-balkangraph-orgchart";

import "./index.scss";
import { useNavigate } from "react-router";
import { useGlobalStore } from "~/store";

const View = ({ flatData, setModalToggle, setSelectedMember }) => {
	let orgRef = useRef(null);
	const navigate = useNavigate();
	const [
		{
			user: { info },
		},
	] = useGlobalStore();

	const handleClick = (e) => {
		const isGroupNode = e?.node?.tags?.indexOf("group") !== -1;
		const hasChildren = e?.node?.tags?.indexOf("hasChildren") !== -1;

		if (isGroupNode) {
			if (e.node.min) {
				orgRef.current.chart.maximize(e.node.id);
			} else {
				hasChildren && orgRef.current.chart.minimize(e.node.id);
			}
		} else {
			if (e.node.id === "company") {
				if (info.role === "admin") navigate("/myPage/admin/logo");
				return false;
			} else {
				setSelectedMember(e.node.id);
				setModalToggle(true);
			}
		}
		return false;
	};

	useEffect(() => {
		orgRef.current.chart.on("field", function (sender, args) {
			if (args.node.min) {
				if (args.name === "img") {
					const count =
						args.node.stChildrenIds.length > 6 ? 6 : args.node.stChildrenIds.length;
					const x = args.node.w / 2 - (count * 32) / 2;

					const reducer = args.node.stChildrenIds
						.slice(0, count)
						.reduce(function (acc, current, index) {
							const data = sender.get(current);

							acc[index] =
								'<image xlink:href="' +
								data.img +
								'" x="' +
								(x + index * 32) +
								'" y="45" width="32" height="32" preserveAspectRatio="xMidYMid slice" ></image>';
							return acc;
						}, []);

					args.value = reducer.join(" ");
					args.element = reducer.join(" ");
				}
			}
		});
	}, []);

	return (
		<OrgChart
			className="org-chart-container"
			nodes={flatData}
			defaultTemplate="olivia"
			ref={orgRef}
			config={{
				enableSearch: false,
				nodeBinding: {
					img_0: "img",
					field_0: "name",
					field_1: "position",
				},
				tags: {
					group: {
						template: "group",
						subTreeConfig: {
							layout: OrgChart.mixed,
							columns: 2,
						},
					},
					company: {
						template: "diva",
					},
				},
				mouseScrool: OrgChartConfig.action.zoom,
			}}
			onCardClick={handleClick}
		/>
	);
};

export default View;
