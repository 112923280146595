import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { API_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import { useForm } from "react-hook-form";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import useAlert from "~/common/hooks/useAlert";
import { useTranslation } from "react-i18next";

const MailCheck = ({ setCurrentStep }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const findPwForm = useForm();

	const findPwRequest = useRequest("post", `user/pw/find`);

	const request = (data) => {
		findPwRequest.call({ id: data.email }, "queryString");
	};

	useEffect(() => {
		switch (findPwRequest.state) {
			case API_STATE.done:
				setCurrentStep("mailSend");
				break;
			case API_STATE.error:
				switch (findPwRequest.error.statusCode) {
					case 404:
						showAlert();
						break;
					case 500:
						alert("잠시 후 다시 시도해주세요.");
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	}, [findPwRequest.state, findPwRequest.error]);

	const [showAlert, Alert] = useAlert();

	return (
		<>
			<div className="phrase-wrap">
				<h3>{t("Auth.FindPw.Find.MailCheck.phrase-wrap.h3")}</h3>
				<p>{t("Auth.FindPw.Find.MailCheck.phrase-wrap.p")}</p>
				<Form useForm={findPwForm} className="auth-form">
					<Input
						name="email"
						type="email"
						placeholder={t("Auth.FindPw.Find.MailCheck.auth-form.placeholder")}
						autoComplete="new-password"
						validation={{
							required: t("Auth.FindPw.Find.MailCheck.auth-form.required"),
							pattern: {
								value: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{1,10}$/i,
								message: t("Auth.FindPw.Find.MailCheck.auth-form.pattern"),
							},
						}}
					/>
				</Form>
			</div>
			<div className="btn-wrap">
				<button className="btn__solid" color="gray" onClick={() => navigate("/login")}>
					{t("button.prev")}
				</button>
				<button
					className="btn__solid"
					color="primary"
					onClick={findPwForm.handleSubmit(request)}
				>
					{t("button.next")}
				</button>
			</div>
			<Alert message={t("Auth.FindPw.Find.MailCheck.WarningAlert")} confirm={t("button.check")} type={"warning"} />
		</>
	);
};
export default MailCheck;
