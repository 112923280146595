import React, { useRef, useState } from 'react'
import * as XLSX from 'xlsx';
import { DownLoadIcon, UploadIcon } from '~/assets/svg/InterfacesIcon';

const ExcelLoader = ({format=[],name="",onAction}) => {
    const fileInputRef = useRef(null);
    const [data, setData] = useState([]);
    const [dataFormat, setDataFormat] = useState(format);
   
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        
        reader.onload = (event) => {
          const binaryStr = event.target.result;
          const workbook = XLSX.read(binaryStr, { type: 'binary' });
    
          // Assuming the data is in the first sheet
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
    
          setData(jsonData);

          console.log(jsonData)

          onAction(jsonData)
        };
    
        reader.readAsBinaryString(file);
      };
    
      const handleFileDownload = () => {
        const worksheet = XLSX.utils.json_to_sheet(dataFormat);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    
        // Create binary string from workbook
        const binaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    
        // Convert binary string to Blob
        const blob = new Blob([s2ab(binaryString)], { type: 'application/octet-stream' });
    
        // Create download link and trigger download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}_양식.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };

      const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      };

      const handleButtonClick = () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      };
    
    
    return (
         <div className="team-add-modal-header-btns-wrapper">
            <button type="button" className="btn__download angled" color="primary"
            onClick={handleFileDownload}
            >
              <DownLoadIcon />
            엑셀 양식 다운로드
					</button>
                    <div className='download_input_wrapper'>
                    <button type="button" className="btn__upload angled" color="primary"
                    onClick={handleButtonClick}
                    style={{zIndex:1,position:'relative'}}
                    >
                    <UploadIcon />
						엑셀업로드
					</button>
                    <input ref={fileInputRef} className='download_input' type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
                    </div>
                    
            </div>
    //     <div style={{ height: 200, background: 'yellow' }}>
    //         <button
    //             type="button"
    //             className="btn__solid"
    //             color="primary"
    //         >  <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
    //             업로도
    //         </button>
          
    //         <button
    //             type="button"
    //             className="btn__solid"
    //             color="primary"
    //             onClick={handleFileDownload}
    //         >
    //             다운로도
    //         </button>

    //         <table border="1">
    //     <thead>
    //       <tr>
    //         <th>이름</th>
    //         <th>입사일</th>
    //         <th>근무일</th>
    //         <th>남은 연차 수</th>
    //         <th>이메일</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       {data.map((item, index) => (
    //         <tr key={index}>
    //           <td>{item.email}</td>
    //           <td>{item.name}</td>
    //           <td>{item.joinDate}</td>
    //           <td>{item.numOfRemainAnnualLeave}</td>
    //           <td>{item.workType}</td>
    //         </tr>
    //       ))}
    //     </tbody>
    //   </table>
    //     </div>
    )
}

export default ExcelLoader
