import React, { useMemo } from "react";
import { useGlobalStore } from "~/store";
import { dateToString } from "~/utils/formatter";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const View = ({ Header, showEditPage }) => {
	const { t } = useTranslation();
	const [
		{
			ir: { history },
		},
	] = useGlobalStore();

	const buttonList = [
		<AuthButton type="button" className="btn__solid" color="primary" onClick={showEditPage}>
			{t("button.Edit")}
		</AuthButton>,
	];

	const historyList = useMemo(() => {
		const reducedHistory = history
			.sort((a, b) => b.date.localeCompare(a.date))
			.reduce((acc, { date, content }) => {
				const year = dateToString(date, "yyyy");
				const current = acc[year] || [];

				acc[year] = [...current, { date, content }];
				return acc;
			}, {});

		return Object.keys(reducedHistory)
			.reverse()
			.map((year) => (
				<div key={year} className="yearly-history">
					<h3 className="year">{year}</h3>
					<ol>
						{reducedHistory[year].map(({ date, content }, idx) => (
							<li key={idx} className="form-data-group">
								<span className="history-date">
									{dateToString(date, "yyyy-MM")}
								</span>
								<ul className="history-content">
									<li>{content}</li>
								</ul>
							</li>
						))}
					</ol>
				</div>
			));
	}, [history]);

	return (
		<>
			<Header buttonList={buttonList} />
			<div className="ir-view">
				<div className="c__card history-view">{historyList}</div>
			</div>
		</>
	);
};

export default View;
