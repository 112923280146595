// path: s3 폴더명
const S3_PATH = {
	// 사업자등록증
	COMPANY_BUSINESS: "company/business",
	// 법인인감
	COMPANY_SIGN: "company/sign",
	// 기업로고
	COMPANY_LOGO: "company/logo",
	// 제품이미지
	COMPANY_PRODUCT: "company/product",
	// 주요인력 사진
	COMPANY_MEMBER: "company/member",
	// 플러그인 정보
	PLUGIN_INFO: "plugin/info",
	// 개인서명
	USER_SIGN: "user/sign",
	// 사용자 사진
	USER_PROFILE: "user/profile",
	// IR 주요인력 사진
	IR_MEMBER: "ir/member",
	// 휴가신청 첨부파일
	HR_VACATION: "hr/vacation",
	// 온라인투자심사 사업계획서
	EVALUATE_BUSINESS: "evaluate/business",
	// 라운드 제출파일
	ROUND_SUBMIT: "round/submit",
	// 커뮤니티 공지사항 사진
	NOTICE_IMAGE: "community/notice/content",
	// 커뮤니티 공지사항 첨부파일
	NOTICE_FILE: "community/notice/upload",
	// 커뮤니티 공자사항 댓글 사진
	NOTICE_COMMENT_IMAGE: "community/notice/comment",
	// 커뮤니티 이미지
	COMMUNITY_IMAGE: "community/community/image",
	// 커뮤니티 게시물 에디터 이미지
	COMMUNITY_CONTENT: "community/community/content",
	// 커뮤니티 게시물 파일첨부
	COMMUNITY_UPLOAD: "community/community/upload",
	// 커뮤니티 댓글 이미지
	COMMUNITY_COMMENT: "community/community/comment",
	// 공통
	COMMON: "common",
};

//type: 파일 종류
const FILE_TYPE = {
	COMPANY_BUSINESS: "사업자등록증",
	COMPANY_SIGN: "법인인감",
	COMPANY_LOGO: "기업로고",
	COMPANY_PRODUCT: "제품이미지",
	COMPANY_MEMBER: "주요인력 사진",
	PLUGIN_INFO: "플러그인 정보",
	USER_SIGN: "개인서명",
	USER_PROFILE: "사용자 사진",
	IR_MEMBER: "IR 주요인력 사진",
	HR_VACATION: "휴가신청 첨부파일",
	EVALUATE_BUSINESS: "온라인투자심사 사업계획서",
	ROUND_SUBMIT: "라운드 제출파일",
	NOTICE_IMAGE: "공지사항 사진",
	NOTICE_FILE: "공지사항 첨부파일",
	NOTICE_COMMENT_IMAGE: "공지사항 댓글 사진",
	COMMUNITY_IMAGE: "커뮤니티 이미지",
	COMMUNITY_CONTENT: "커뮤니티 게시물 에디터 이미지",
	COMMUNITY_UPLOAD: "커뮤니티 게시물 파일첨부",
	COMMUNITY_COMMENT: "커뮤니티 댓글 이미지",
	COMMON: "공통",
};

export const UPLOAD_TYPE = {
	COMPANY_BUSINESS: { type: FILE_TYPE.COMPANY_BUSINESS, path: S3_PATH.COMPANY_BUSINESS },
	COMPANY_SIGN: { type: FILE_TYPE.COMPANY_SIGN, path: S3_PATH.COMPANY_SIGN },
	COMPANY_LOGO: { type: FILE_TYPE.COMPANY_LOGO, path: S3_PATH.COMPANY_LOGO },
	COMPANY_PRODUCT: { type: FILE_TYPE.COMPANY_PRODUCT, path: S3_PATH.COMPANY_PRODUCT },
	COMPANY_MEMBER: { type: FILE_TYPE.COMPANY_MEMBER, path: S3_PATH.COMPANY_MEMBER },
	PLUGIN_INFO: { type: FILE_TYPE.PLUGIN_INFO, path: S3_PATH.PLUGIN_INFO },
	USER_SIGN: { type: FILE_TYPE.USER_SIGN, path: S3_PATH.USER_SIGN },
	USER_PROFILE: { type: FILE_TYPE.USER_PROFILE, path: S3_PATH.USER_PROFILE },
	IR_MEMBER: { type: FILE_TYPE.IR_MEMBER, path: S3_PATH.IR_MEMBER },
	HR_VACATION: { type: FILE_TYPE.HR_VACATION, path: S3_PATH.HR_VACATION },
	EVALUATE_BUSINESS: { type: FILE_TYPE.EVALUATE_BUSINESS, path: S3_PATH.EVALUATE_BUSINESS },
	ROUND_SUBMIT: { type: FILE_TYPE.ROUND_SUBMIT, path: S3_PATH.ROUND_SUBMIT },
	NOTICE_IMAGE: { type: FILE_TYPE.NOTICE_IMAGE, path: S3_PATH.NOTICE_IMAGE },
	NOTICE_FILE: { type: FILE_TYPE.NOTICE_FILE, path: S3_PATH.NOTICE_FILE },
	NOTICE_COMMENT_IMAGE: {
		type: FILE_TYPE.NOTICE_COMMENT_IMAGE,
		path: S3_PATH.NOTICE_COMMENT_IMAGE,
	},
	COMMUNITY_IMAGE: { type: FILE_TYPE.COMMUNITY_IMAGE, path: S3_PATH.COMMUNITY_IMAGE },
	COMMUNITY_CONTENT: { type: FILE_TYPE.COMMUNITY_CONTENT, path: S3_PATH.COMMUNITY_CONTENT },
	COMMUNITY_UPLOAD: { type: FILE_TYPE.COMMUNITY_UPLOAD, path: S3_PATH.COMMUNITY_UPLOAD },
	COMMUNITY_COMMENT: { type: FILE_TYPE.COMMUNITY_COMMENT, path: S3_PATH.COMMUNITY_COMMENT },
	COMMON: { type: FILE_TYPE.COMMON, path: S3_PATH.COMMON },
};
