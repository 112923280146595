import { ALARM } from "./actions";

export const INITIAL_STATE = {
	alarmList: [],
};

export const REDUCER = (state, action) => {
	switch (action.type) {
		case ALARM:
			return { ...state, alarmList: action.payload.alarmList };
		default:
			return state;
	}
};
