import React from "react";
import { dateToString } from "~/utils/formatter";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";

const CardBack = ({ careerList }) => {
	const { t } = useTranslation();
	const careers = () => {
		const dateFormat = "yyyy-MM";
		if (careerList.length > 0) {
			return careerList.map((career, index) => {
				return (
					<li key={index} className="career">
						<p className="work">{career.companyName}</p>
						<p className="date">
							{dateToString(career.startDate, dateFormat)}~
							{dateToString(career.endDate, dateFormat)}
						</p>
					</li>
				);
			});
		} else {
			return <NoData main={t("MyPage.AccountPage.career-Nodata")} />;
		}
	};
	return (
		<div className="c__card back">
			<div className="content-wrap">
				<div className="content">
					<p className="title">{t("MyPage.AccountPage.career-title")}</p>
					<div className="scroll-wrap">
						<ul className="career-list">{careers()}</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CardBack;
