import React from "react";
import { useNavigate } from "react-router";
import initImg from "~/assets/img/init-org.png";

const Empty = () => {
	const navigate = useNavigate();

	return (
		<div className="page-init">
			<p className="main">조직도 생성을 위해 팀원을 추가 해주세요.</p>
			<p className="sub">팀원 추가 후 팀원 정보에서 조직을 설정해 주세요!</p>
			<button className="btn__solid" color="primary" onClick={() => navigate("/hr/list")}>
			팀원 추가하기
			</button>
			<img src={initImg} alt="생성된 조직 없음" />
		</div>
	);
};

export default Empty;
