import React, { useState } from "react";
import { useGlobalStore } from "~/store";
import defaultImg from "~/assets/img/defaultImg/default-img.png";
import defaultLogo from "~/assets/img/defaultImg/logo-square.png";
import faceboockIco from "~/assets/img/sns/ico-facebook.png";
import twitterIco from "~/assets/img/sns/ico-twitter.png";
import youtubeIco from "~/assets/img/sns/ico-youtube.png";
import instargramIco from "~/assets/img/sns/ico-instargram.png";
import linkedinIco from "~/assets/img/sns/ico-linkedin.png";
import { dateToString } from "~/utils/formatter";
import useAlert from "~/common/hooks/useAlert";
import { useNavigate } from "react-router";
import storageService from "~/utils/storage";

const Info = ({ roundStatus }) => {
	const [
		{
			round: { info },
		},
	] = useGlobalStore();
	const [showAlert, Alert] = useAlert();
	const [alertMsg, setAlertMsg] = useState("");
	const navigate = useNavigate();

	const token = storageService.get("auth", "accessToken", "local");

	const hasToken = token !== undefined;

	const handleClick = () => {
		if (hasToken) {
			setAlertMsg("온라인 지원 페이지로 이동하시겠습니까?");
			showAlert().then(() => navigate(`/invest/round?idx=${info.idx}`));
		} else {
			setAlertMsg("온라인 지원을 위해 로그인 페이지로 이동합니다.");
			showAlert().then(() => navigate(`/login`));
		}
	};

	return (
		<>
			<section className="rounding-main">
				<div className="rounding-summary-left">
					<div className="rounding-img">
						<img
							src={info.img || defaultImg}
							alt="라운딩썸네일"
							onError={(e) => {
								e.target.src = defaultImg;
							}}
						/>
					</div>
					<div className="rounding-host-wrap">
						<div className="rounding-host">
							<div className="rounding-host-logo">
								<img
									src={info.managerImg || defaultLogo}
									alt="로고이미지"
									onError={(e) => {
										e.target.src = defaultLogo;
									}}
								/>
							</div>
							<dl className="rounding-host-data">
								<dt className="host-name">{info.managerName}</dt>
								<dd className="host-email">{info.managerEmail}</dd>
							</dl>
						</div>
						<ul className="share-sns">
							{info.facebook && (
								<li>
									<button type="button">
										<a href={info.facebook} target="_blank" rel="noreferrer">
											<img src={faceboockIco} alt="페이스북아이콘" />
										</a>
									</button>
								</li>
							)}
							{info.twitter && (
								<li>
									<button type="button">
										<a href={info.twitter} target="_blank" rel="noreferrer">
											<img src={twitterIco} alt="트위터아이콘" />
										</a>
									</button>
								</li>
							)}
							{info.instagram && (
								<li>
									<button type="button">
										<a href={info.instagram} target="_blank" rel="noreferrer">
											<img src={instargramIco} alt="카카오스토리아이콘" />
										</a>
									</button>
								</li>
							)}
							{info.linkedin && (
								<li>
									<button type="button">
										<a href={info.linkedin} target="_blank" rel="noreferrer">
											<img src={linkedinIco} alt="카카오스토리아이콘" />
										</a>
									</button>
								</li>
							)}
							{info.youtube && (
								<li>
									<button type="button">
										<a href={info.youtube} target="_blank" rel="noreferrer">
											<img src={youtubeIco} alt="카카오스토리아이콘" />
										</a>
									</button>
								</li>
							)}
						</ul>
					</div>
				</div>
				<div className="rounding-summary-right">
					<div>
						<div className="rounding-main-header">
							<h3 className="rounding-title">{info.title}</h3>
							{/*<div className="info-wrap">*/}
							{/*	<p className="host-name">{info.VcCompany.name}</p>*/}
							{/*	<p className="host-site">*/}
							{/*		<a*/}
							{/*			href={info.VcCompany.homepage}*/}
							{/*			target="_blank"*/}
							{/*			rel="noreferrer"*/}
							{/*		>*/}
							{/*			{info.VcCompany.homepage}*/}
							{/*		</a>*/}
							{/*	</p>*/}
							{/*</div>*/}
							<div>
								<h4 className="rounding-status">
									{roundStatus(info.status, info.endDate)}
								</h4>
								<p className="rounding-update">
									등록일자 : {dateToString(info.regDate, "yyyy-MM-dd")}
								</p>
							</div>
						</div>
						<p className="rounding-summary">{info.description}</p>
						<dl className="rounding-instructions">
							<div className="period">
								<dt>기간</dt>
								<dd>
									{dateToString(info.startDate, "yyyy-MM-dd")} ~
									{dateToString(info.endDate, "yyyy-MM-dd")}
								</dd>
							</div>
							<div className="place">
								<dt>장소</dt>
								<dd>{info.place}</dd>
							</div>
						</dl>
					</div>
					{info.status === "진행중" && (
						<button
							type="button"
							className="btn__solid btn-status"
							color="primary"
							onClick={handleClick}
						>
							온라인 지원하기
						</button>
					)}
				</div>
			</section>
			<Alert type="info" message={alertMsg} confirm="확인" cancel="취소" />
		</>
	);
};

export default Info;
