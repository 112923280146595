import React, { useEffect, useState } from "react";
import { PlusIcon } from "~/assets/svg/MathIcon";
import VacationCard from "~/pages/vacation/SettingPage/Modal/vacationCard";
import { API_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import useAlert from "~/common/hooks/useAlert";
import { clearSelectedVacation } from "~/store/hrSetting/actions";
import { useGlobalStore } from "~/store";

const ManageVacationModal = (props) => {
	const {
		modalVacationTypeList,
		setVacationAddModal,
		getVacationTypeList,
		setModalToggle,
		getModalVacationTypeList,
	} = props;

	const [, dispatch] = useGlobalStore();

	const deleteVacationTypeRequest = useRequest("delete", "vacation/type");

	const handleDelete = ({ idx, type }) => {
		let requestData = {
			data: {
				idx: [idx],
			},
		};

		if (type === "법정필수휴가") {
			showAlert();
		} else {
			deleteAlert().then(() => deleteVacationTypeRequest.call(requestData, "body"));
		}
	};

	useEffect(() => {
		switch (deleteVacationTypeRequest.state) {
			case API_STATE.done:
				getModalVacationTypeList();
				break;
			case API_STATE.error:
				console.log("error", deleteVacationTypeRequest.error);
				break;
			default:
				break;
		}
	}, [deleteVacationTypeRequest.state]);

	const [deleteAlert, DeleteAlert] = useAlert();
	const [showAlert, Alert] = useAlert();

	const activateRequest = useRequest("put", "hr/vacation/type/activate");

	const [activateValue, setActivateValue] = useState([]);

	const onChange = (idx) => {
		if (activateValue.find((v) => v === idx)) {
			setActivateValue(activateValue.filter((v) => v !== idx));
		} else {
			setActivateValue([...activateValue, idx]);
		}
	};

	const handleSubmit = () => {
		activateRequest.asyncCall({ idx: activateValue }, "body");
	};

	useEffect(() => {
		switch (activateRequest.state) {
			case API_STATE.done:
				getVacationTypeList();
				setModalToggle(false);
				break;
			case API_STATE.error:
				console.log("error", activateRequest.error);
				break;
		}
	}, [activateRequest.state]);

	return (
		<>
			<div className="modal-content">
				<button
					className="btn__solid angled btn-vacation-policy"
					color="primary"
					onClick={() => {
						dispatch(clearSelectedVacation());
						setVacationAddModal(true);
					}}
				>
					<PlusIcon />
					휴가 정책 생성
				</button>
				<div className="policy-card-wrap">
					{VacationCard({
						modalVacationTypeList,
						handleDelete,
						setVacationAddModal,
						setActivateValue,
						activateValue,
						onChange,
						getVacationTypeList,
					})}
				</div>
			</div>
			<div className="modal-footer">
				<button className="btn__solid" color="gray" onClick={() => setModalToggle(false)}>
					취소
				</button>
				<button className="btn__solid" color="primary" onClick={handleSubmit}>
					저장하기
				</button>
			</div>
			<DeleteAlert
				message={
					<>
						<div className="alert-content">
							<h3>해당 휴가를 삭제하시겠습니까?</h3>
							<p>확인을 누르시면 휴가가 삭제됩니다.</p>
						</div>
						<div className="desc-box error">
							삭제 전 해당 휴가를 사용 했던 직원들의 데이터는 변경되지 않습니다.
							삭제를 하신 이후 해당 휴가 사용은 불가능 합니다.
						</div>
					</>
				}
				confirm={"확인"}
				reject={"취소"}
				type={"warning"}
				className={"vacation-delete-modal error"}
			/>
			<Alert
				message={
					<>
						해당 휴가는 법정 필수 휴가이기 때문에 <br />
						삭제하실 수 없습니다.
					</>
				}
				confirm={"확인"}
				type={"info"}
			/>
		</>
	);
};
export default ManageVacationModal;
