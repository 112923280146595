import React, { useEffect } from "react";
import Form from "~/common/components/Form";
import Select, { optionsGenerator } from "~/common/components/Select";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import { useForm } from "react-hook-form";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import { useTranslation } from "react-i18next";

const Modal = ({ modalState, setModalToggle, importShareholder, response }) => {
	const { t } = useTranslation();
	const closeModal = () => {
		setModalToggle(false);
	};

	const shareholderForm = useForm();

	const shareholderDetailRequest = useRequest("get", `shareholder/detail`);

	const handleClick = (data) => {
		shareholderDetailRequest.call(data.idx, "path");
	};

	useEffect(() => {
		switch (shareholderDetailRequest.state) {
			case API_STATE.done:
				const newShareholder = shareholderDetailRequest.response.data.ShareholderMember.map(
					(shareholder) => ({
						name: shareholder.name,
						total: shareholder.total,
						ratio: shareholder.ratio,
						etc: "",
					})
				);
				importShareholder(newShareholder);
				closeModal();
				break;
			default:
				break;
		}
	}, [shareholderDetailRequest.state]);

	return (
		<>
			<ModalTemplate
				modalState={modalState}
				setModalToggle={setModalToggle}
				title={t("IR.IrDetail.Shareholder.Edit.Index.import")}
				width={500}
				className="modal-shareholder-upload"
			>
				<div className="modal-content">
					<Form className="vertical" useForm={shareholderForm}>
						<Select
							label={t("IR.IrDetail.Shareholder.Edit.Modal.select")}
							name="idx"
							options={optionsGenerator(response)}
							placeholder={t("IR.IrDetail.Shareholder.Edit.Modal.placeholder")}
						/>
					</Form>
				</div>
				<div className="modal-footer">
					<button className="btn__solid" color="gray" onClick={closeModal}>
						{t("button.cancel")}
					</button>
					<button
						className="btn__solid"
						color="primary"
						onClick={shareholderForm.handleSubmit(handleClick)}
					>
						{t("button.bring")}
					</button>
				</div>
			</ModalTemplate>
		</>
	);
};
export default Modal;
