import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { API_STATE } from "~/common/constants/state";
import * as env from "~/common/constants/env";
import storageService from "~/utils/storage";
import useAlert from "~/common/hooks/useAlert";
import useRequest from "~/common/hooks/useRequest";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import { useTranslation } from "react-i18next";

// Store
import { useGlobalStore } from "~/store";
import { Okceologin, Okceologout } from "~/store/tax/actions";

// Components
import PageHeader from "~/common/components/PageHeader";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import { Case, If, OtherWise } from "~/common/components/If";

// Assets
import "~/assets/sass/pages/tax/taxSetting.scss";
import okceoLogo from "~/assets/img/okceo-logo.png";
import bgRegister from "~/assets/img/bg-tax-setting.png";
import bgConnect from "~/assets/img/bg-tax-setting02.png.png";
import AuthButton from "~/common/components/AuthButton";

const Setting = () => {
	const { t } = useTranslation();
	const [
		{
			tax: { logged },
		},
		dispatch,
	] = useGlobalStore();
	const MENU = ["tax", "setting"];
	const TYPE = "main";
	const okceoLoginForm = useForm();
	const [showAccountAlert, AccountAlert] = useAlert();
	const [showMatchAlert, MatchAlert] = useAlert();

	const headerButtons = [
		<>
			<button
				type="button"
				className="btn__solid"
				color="primary"
				onClick={() => {
					logged === true
						? window.open(`${env.OKCEO_API_URL}/ssolink?token=${okceoToken}`)
						: window.open(`https://okceo.co.kr`);
				}}
			>
				{t("Tax.btn-okceo")}
			</button>
		</>,
	];

	const okceoToken = storageService.get("okceo.auth", "accessToken", "local");

	const okceoConnectRequest = useRequest("get", "tax/check/is-connected");
	const okceoLoginRequest = useRequest("post", "tax/connect");
	const okceoLogoutRequest = useRequest("patch", "tax/disconnect");

	//okceo 연동 확인
	useEffect(() => {
		okceoConnectRequest.singleCall();
	});

	useEffect(() => {
		switch (okceoConnectRequest.state) {
			case API_STATE.done:
				const response = okceoConnectRequest.response.data.isTokenExist;
				if (response === true) {
					dispatch(
						Okceologin({ accessToken: okceoConnectRequest.response.data.okceoToken })
					);
				}
				break;
			case API_STATE.error:
				console.log("error");
				break;
			default:
				break;
		}
	}, [okceoConnectRequest.state, dispatch]);

	//okceo 연동
	useEffect(() => {
		switch (okceoLoginRequest.state) {
			case API_STATE.done:
				const response = okceoLoginRequest.response.data.okceoToken;
				dispatch(Okceologin({ accessToken: response }));
				break;
			case API_STATE.error:
				switch (okceoLoginRequest.error.statusCode) {
					case 400:
						showAccountAlert();
						break;
					case 500:
						showMatchAlert();
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	}, [okceoLoginRequest.state, dispatch]);

	const submit = (formData) => {
		const payload = {
			...formData,
			okceoId: formData.id,
			okceoPassword: formData.pw,
		};
		okceoLoginRequest.asyncCall(payload, "body");
	};

	const handleLogout = () => {
		dispatch(Okceologout());
		okceoLogoutRequest.singleCall();
	};

	return (
		<>
			<NavbarAndSidebarLayout menu={MENU} type={TYPE}>
				<PageHeader buttonList={headerButtons} />
				<If condition={logged}>
					<Case condition={true}>
						<div className="c__card after-connect">
							<div className="content-wrap">
								<div className="content">
									<h3 className="content-title">
										{t("Tax.Setting.content-title")}
									</h3>
									<p className="content-phrase">
										{t("Tax.Setting.content-phrase")}
									</p>
								</div>
								<div className="img-wrap">
									<img
										src={bgConnect}
										alt={t("Tax.Setting.after-connect.img-wrap")}
									/>
								</div>
							</div>
							<div className="login-wrap">
								<h1 className="logo-img">
									<img src={okceoLogo} alt={t("Tax.Setting.logo-img")} />
								</h1>
								<p className="main-desc">
									{t("Tax.Setting.after-connect.main-desc")}
								</p>
								<p className="sub-desc">
									{t("Tax.Setting.after-connect.sub-desc")}
								</p>
								<button
									type="button"
									className="btn__solid btn-connect"
									color="primary"
									onClick={handleLogout}
								>
									{t("Tax.Setting.after-connect.btn-connect")}
								</button>
							</div>
						</div>
					</Case>
					<OtherWise>
						<div className="c__card before-connect">
							<div className="content-wrap">
								<div className="content">
									<h3 className="content-title">
										{t("Tax.Setting.content-title")}
									</h3>
									<p className="content-phrase">
										{t("Tax.Setting.content-phrase")}
									</p>
								</div>
								<div className="img-wrap">
									<img
										src={bgRegister}
										alt={t("Tax.Setting.before-connect.img-wrap")}
									/>
								</div>
							</div>
							<div className="login-wrap">
								<h1 className="logo-img">
									<img src={okceoLogo} alt={t("Tax.Setting.logo-img")} />
								</h1>
								<Form useForm={okceoLoginForm} className="tax-login-form">
									<Input placeholder="ID" name="id" type="email" required />
									<Input placeholder="PW" name="pw" type="password" required />
								</Form>
								<div className="register-btn-wrap">
									<div>
										<button
											type="button"
											className="btn-pw"
											onClick={() =>
												window.open("https://okceo.co.kr/findpw")
											}
										>
											{t("Tax.Setting.before-connect.btn-pw")}
										</button>
									</div>
									<div>
										{t("Tax.Setting.before-connect.register-text")}
										<button
											type="button"
											className="btn-register"
											onClick={() =>
												window.open("https://okceo.co.kr/register")
											}
										>
											{t("Tax.Setting.before-connect.btn-register")}
										</button>
									</div>
								</div>
								<AuthButton
									type="button"
									className="btn__solid btn-connect angled"
									color="primary"
									onClick={okceoLoginForm.handleSubmit(submit)}
								>
									{t("Tax.Setting.before-connect.btn-connect")}
								</AuthButton>
							</div>
						</div>
					</OtherWise>
				</If>
			</NavbarAndSidebarLayout>

			{/*Alert Modal*/}
			<AccountAlert
				type="warning"
				message={t("Tax.Setting.before-connect.AccountAlert")}
				confirm={t("button.check")}
			/>
			<MatchAlert
				type="warning"
				message={t("Tax.Setting.before-connect.MatchAlert")}
				confirm={t("button.check")}
			/>
		</>
	);
};

export default Setting;
