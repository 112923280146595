import React from "react";
import PluginItem from "~/pages/mypage/PluginPage/PluginItem";

const PluginList = ({ getPluginList, pluginList }) => {
	return (
		<>
			<div className="c__cardRow">
				{pluginList.map((plugin, index) => {
					return (
						<PluginItem
							key={index}
							{...{
								plugin,
								getPluginList,
							}}
						/>
					);
				})}
			</div>
		</>
	);
};

export default PluginList;
