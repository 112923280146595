import React from "react";

import Form, { Group } from "~/common/components/Form";
import Select, { optionsGenerator } from "~/common/components/Select";
import Input from "~/common/components/Input";
import { RoundMinusIcon } from "~/assets/svg/InterfacesIcon";
import { PlusIcon } from "~/assets/svg/MathIcon";
import NumberInput from "~/common/components/NumberInput";
import DatePicker from "~/common/components/DatePicker";
import { RoundType } from "~/common/constants/type";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

const RoundTypeOptions = optionsGenerator(RoundType);

const InputFieldRow = ({ useForm, name, remove }) => {
	const { t } = useTranslation();
	return (
		<Form useForm={useForm} className={"invest-history-row"}>
			<Group>
				<DatePicker
					name={name("date")}
					month
					datePickerName={"date"}
					className="date-picker"
					required
				/>
				<Input placeholder={t("Company.Invest.investName")} name={name("name")} required />
				<NumberInput
					placeholder={t("Company.Invest.comValue")}
					name={name("comValue")}
					string
					required
					max={999999999}
				/>
				<Select name={name("step")} options={RoundTypeOptions} className="round" required />
				<NumberInput
					placeholder={t("Company.Invest.investAmount")}
					name={name("value")}
					string
					required
					validation={{
						maxLength: {
							value: 27,
							message: t("Company.Invest.Edit.maxLength"),
						},
					}}
					max={999999999}
				/>
				<button type="button" className="btn-round-delete" onClick={remove}>
					<RoundMinusIcon />
				</button>
			</Group>
		</Form>
	);
};

const InvestHistory = ({ useForm }) => {
	const { t } = useTranslation();
	const { control } = useForm;

	const { fields, append, remove } = useFieldArray({
		control,
		name: "investHistory",
	});

	const INVEST_INIT_DATA = {
		name: "",
		value: "",
		step: undefined,
		comValue: "",
		date: new Date().toISOString(),
	};

	return (
		<>
			<h3 className="section-title unit-wrap">
				{t("Company.Invest.Edit.investHistory")}{" "}
				<span className="unit">{t("Company.unit")}</span>
			</h3>
			<div className="data-form">
				<button type="button" className="plus-btn" onClick={() => append(INVEST_INIT_DATA)}>
					<PlusIcon />
					{t("Company.Invest.Edit.plus-btn")}
				</button>

				{fields.map((field, index) => {
					const getName = (name) => `investHistory.${index}.${name}`;
					const removeItem = () => remove(index);
					return (
						<InputFieldRow
							key={field.id}
							name={getName}
							remove={removeItem}
							useForm={useForm}
						/>
					);
				})}
			</div>
		</>
	);
};

export default InvestHistory;
