import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import translation from "~/translations";
import i18next from "i18next";

// Assets
import NewLandingLayout from "~/layouts/LandingLayout";
import "~/assets/sass/pages/landing/case/index.scss";
import { ArrowIcon } from "~/assets/svg/InterfacesIcon";
import ventureSquareLogo from "~/assets/img/landing/logo-ventureSquare-rac.png";
import sunboLogo from "~/assets/img/landing/logo-sunbo.png";
import caseBoomco from "~/assets/img/landing/startupImg-boomco.png";
import caseHaas from "~/assets/img/landing/startupImg-haas.jpg";
import caseTeamEverywhere from "~/assets/img/landing/startupImg-everywhere.png";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import TabMenu from "~/common/components/TabMenu";
import { If, Case } from "~/common/components/If";
import { StartupCase, VcCase } from "~/pages/LandingPage/LandingConstant";
import { Trans, useTranslation } from "react-i18next";

// ko img
import roundCase1 from "~/assets/img/landing/round-case1.jpg";
import roundCase2 from "~/assets/img/landing/round-case2.jpg";
import roundCase3 from "~/assets/img/landing/round-case3.jpg";

// en img
import enRoundCase1 from "~/assets/img/EN/round-case1_en.png";
import enRoundCase2 from "~/assets/img/EN/round-case2_en.png";
import enRoundCase3 from "~/assets/img/EN/round-case3_en.png";

const CasePage = () => {
	const { t } = useTranslation();
	const langImg = i18next.language;
	const [vcCaseModal, setVcCaseModal] = useState(false);
	const [startupCaseModal, setStartupCaseModal] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [currentTab, setCurrentTab] = useState("company");
	const [vcCaseDetail, setVcCaseDetail] = useState({});
	const [startupCaseDetail, setStartupCaseDetail] = useState({});

	const targetRef = useRef(null);

	const options = {
		root: null,
		rootMargin: "0px",
		threshold: 1.0,
	};

	const tabMenuList = [
		{ id: "company", title: translation.t("Landing.CasePage.tabMenuList.company") },
		{ id: "service", title: translation.t("Landing.CasePage.tabMenuList.service") },
	];

	const settings = {
		dots: true,
		arrows: false,
		dotsClass: "slick-dots slick-thumb",
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	const handleVcCaseModal = async (idx) => {
		setVcCaseDetail(VcCase[idx]);
		(await vcCaseDetail) && setVcCaseModal(true);
	};

	const handleStartupCaseModal = async (idx) => {
		setStartupCaseDetail(StartupCase[idx]);
		(await startupCaseDetail) && setStartupCaseModal(true);
	};

	const callback = (entries) => {
		const [entry] = entries;
		setIsVisible(entry.isIntersecting);
	};

	// options에 따라 인스턴스 생성
	const observer = new IntersectionObserver(callback, options);

	useEffect(() => {
		// 타겟 요소 관찰 시작
		const targetElement = targetRef.current;
		if (targetElement) {
			observer.observe(targetElement);
		}

		return () => {
			observer.unobserve(targetElement);
		};
	}, [targetRef]);

	return (
		<NewLandingLayout>
			<div className="p__case">
				<section className="top-case-section">
					<div className="bg">
						<p className="bg-text pre-wrap">
							<Trans
								i18nKey="Landing.CasePage.top-case-section.bg-text"
								components={[<b />]}
							/>
						</p>
					</div>
					<div className="bottom">
						<div className="l__layout">
							<p className="bottom-text">
								{t("Landing.CasePage.top-case-section.bottom-text")}
							</p>
							<button type="button" className="btn-company" disabled>
								{t("Landing.CasePage.top-case-section.button")}
							</button>
						</div>
					</div>
				</section>
				<section className="vc-section">
					<div className="l__layout">
						<div>
							<h2 className="section-title">
								{t("Landing.CasePage.vc-section.title")}
							</h2>
							<p className="section-desc">{t("Landing.CasePage.vc-section.desc")}</p>
						</div>
						<div className="c__cardRow">
							<div className="c__card" onClick={() => handleVcCaseModal(2)}>
								<div
									className="card-img"
									title={t("Landing.CasePage.vc-section.card-common.title")}
								>
									<img
										src={langImg === "ko" ? roundCase1 : enRoundCase1}
										alt={t("Landing.CasePage.vc-section.card-common.alt")}
									/>
								</div>
								<div className="card-logo">
									<img
										src={ventureSquareLogo}
										alt={t("Landing.CasePage.vc-section.card-common.logo")}
									/>
								</div>
								<div className="card-text">
									<p
										className="title"
										title={t("Landing.CasePage.vc-section.card-common.title")}
									>
										{t("Landing.CasePage.vc-section.card.1.title")}
									</p>
									<p className="name">
										{t("Landing.CasePage.vc-section.card.1.name")}
									</p>
								</div>
							</div>
							<div className="c__card" onClick={() => handleVcCaseModal(1)}>
								<div
									className="card-img"
									title={t("Landing.CasePage.vc-section.card-common.title")}
								>
									<img
										src={langImg === "ko" ? roundCase2 : enRoundCase2}
										alt={t("Landing.CasePage.vc-section.card-common.alt")}
									/>
								</div>
								<div className="card-logo">
									<img
										src={sunboLogo}
										alt={t("Landing.CasePage.vc-section.card-common.logo")}
									/>
								</div>
								<div className="card-text">
									<p
										className="title"
										title={t("Landing.CasePage.vc-section.card-common.title")}
									>
										{t("Landing.CasePage.vc-section.card.2.title")}
									</p>
									<p className="name">
										{t("Landing.CasePage.vc-section.card.2.name")}
									</p>
								</div>
							</div>
							<div className="c__card" onClick={() => handleVcCaseModal(0)}>
								<div
									className="card-img"
									title={t("Landing.CasePage.vc-section.card-common.title")}
								>
									<img
										src={langImg === "ko" ? roundCase3 : enRoundCase3}
										alt={t("Landing.CasePage.vc-section.card-common.alt")}
									/>
								</div>
								<div className="card-logo">
									<img
										src={ventureSquareLogo}
										alt={t("Landing.CasePage.vc-section.card-common.logo")}
									/>
								</div>
								<div className="card-text">
									<p
										className="title"
										title={t("Landing.CasePage.vc-section.card-common.title")}
									>
										{t("Landing.CasePage.vc-section.card.3.title")}
									</p>
									<p className="name">
										{t("Landing.CasePage.vc-section.card.3.name")}
									</p>
								</div>
							</div>
						</div>
						<button type="button" className="btn__more" disabled>
							<ArrowIcon /> {t("Landing.CasePage.vc-section.button")}
						</button>
					</div>
				</section>
				<section className={`startup-section ${isVisible ? "active" : ""}`}>
					<div className="l__layout">
						<div>
							<h2 className="section-title">
								{t("Landing.CasePage.st-section.title")}
							</h2>
							<p className="section-desc">{t("Landing.CasePage.st-section.desc")}</p>
						</div>
						<div className="c__cardRow">
							<div className="c__card" onClick={() => handleStartupCaseModal(1)}>
								<div
									className="card-img"
									title={t("Landing.CasePage.vc-section.card-common.title")}
									onClick={() => setStartupCaseModal(true)}
								>
									<img
										src={caseBoomco}
										alt={t("Landing.CasePage.vc-section.card-common.alt")}
									/>
								</div>
								<div className="card-text">
									<div className="card-top">
										<p
											className="title"
											title={t(
												"Landing.CasePage.vc-section.card-common.title"
											)}
										>
											{t("Landing.CasePage.st-section.card.1.company")}
										</p>
										<p className="explain">
											{t("Landing.CasePage.st-section.card.1.explain")}
										</p>
									</div>
									<ul className="card-bottom">
										<li className="tag">
											{t("Landing.CasePage.st-section.card.1.tagList.1")}
										</li>
										<li className="tag">
											{t("Landing.CasePage.st-section.card.1.tagList.2")}
										</li>
										<li className="tag">
											{t("Landing.CasePage.st-section.card.1.tagList.3")}
										</li>
									</ul>
								</div>
							</div>
							<div className="c__card" onClick={() => handleStartupCaseModal(2)}>
								<div
									className="card-img"
									title={t("Landing.CasePage.vc-section.card-common.title")}
									onClick={() => setStartupCaseModal(true)}
								>
									<img
										src={caseHaas}
										alt={t("Landing.CasePage.vc-section.card-common.alt")}
									/>
								</div>
								<div className="card-text">
									<div className="card-top">
										<p
											className="title"
											title={t(
												"Landing.CasePage.vc-section.card-common.title"
											)}
										>
											{t("Landing.CasePage.st-section.card.2.company")}
										</p>
										<p className="explain">
											{t("Landing.CasePage.st-section.card.2.explain")}
										</p>
									</div>
									<ul className="card-bottom">
										<li className="tag">
											{t("Landing.CasePage.st-section.card.2.tagList.1")}
										</li>
										<li className="tag">
											{t("Landing.CasePage.st-section.card.2.tagList.2")}
										</li>
										<li className="tag">
											{t("Landing.CasePage.st-section.card.2.tagList.3")}
										</li>
									</ul>
								</div>
							</div>
							<div className="c__card" onClick={() => handleStartupCaseModal(0)}>
								<div
									className="card-img"
									title={t("Landing.CasePage.vc-section.card-common.title")}
									onClick={() => setStartupCaseModal(true)}
								>
									<img
										src={caseTeamEverywhere}
										alt={t("Landing.CasePage.vc-section.card-common.alt")}
									/>
								</div>
								<div className="card-text">
									<div className="card-top">
										<p
											className="title"
											title={t(
												"Landing.CasePage.vc-section.card-common.title"
											)}
										>
											{t("Landing.CasePage.st-section.card.3.company")}
										</p>
										<p className="explain">
											{t("Landing.CasePage.st-section.card.3.explain")}
										</p>
									</div>
									<ul className="card-bottom">
										<li className="tag">
											{t("Landing.CasePage.st-section.card.3.tagList.1")}
										</li>
										<li className="tag">
											{t("Landing.CasePage.st-section.card.3.tagList.2")}
										</li>
									</ul>
								</div>
							</div>
						</div>
						<button type="button" className="btn__more" disabled ref={targetRef}>
							<ArrowIcon /> {t("Landing.CasePage.st-section.button")}
						</button>
					</div>
				</section>
			</div>

			{/*투자라운드 사례 모달*/}
			<ModalTemplate
				title={t("Landing.CasePage.vc-modal.title")}
				modalState={vcCaseModal}
				setModalToggle={setVcCaseModal}
				width={1100}
				className="vc-case-modal"
			>
				<div className="modal-content">
					<div className="img-wrap">{vcCaseDetail.img}</div>
					<div className="content">
						<h3 className="title">{vcCaseDetail.title}</h3>
						<div className="company">
							<div className="logo">{vcCaseDetail.companyLogo}</div>
							<dl>
								<dt className="name">{vcCaseDetail.companyName}</dt>
								<dd className="url">
									<a
										href={vcCaseDetail.url}
										target="_blank"
										rel="noopener noreferrer"
									>
										{vcCaseDetail.homepage}
									</a>
								</dd>
							</dl>
						</div>
						<p className="desc">{vcCaseDetail.desc}</p>
						<div className="apply">
							<dl>
								<dt>{t("Landing.CasePage.vc-modal.date")}</dt>
								<dd>{vcCaseDetail.period}</dd>
							</dl>
							<dl>
								<dt>{t("Landing.CasePage.vc-modal.place")}</dt>
								<dd className="place">{vcCaseDetail.place}</dd>
							</dl>
						</div>
					</div>
				</div>
			</ModalTemplate>

			{/*스타트업 이용 사례 모달*/}
			<ModalTemplate
				title={t("Landing.CasePage.st-modal.title")}
				modalState={startupCaseModal}
				setModalToggle={setStartupCaseModal}
				width={1100}
				className="startup-case-modal"
			>
				<div className="modal-content">
					<div className="top">
						<Slider {...settings} className="slide">
							<div className="slide-item">
								<div className="img-wrap">{startupCaseDetail.img}</div>
							</div>
						</Slider>
						<div className="company">
							<div className="logo">{startupCaseDetail.companyLogo}</div>
							<h3 className="title">{startupCaseDetail.title}</h3>
							<p className="desc">{startupCaseDetail.desc}</p>
						</div>
					</div>
					<div className="content">
						<TabMenu
							tabItem={tabMenuList}
							currentTab={currentTab}
							setCurrentTab={setCurrentTab}
						/>
						<If condition={currentTab}>
							<Case condition={"company"}>
								<p className="company-explain pre-wrap">
									{startupCaseDetail.companyInfo}
								</p>
							</Case>
							<Case condition={"service"}>
								<p className="service-explain pre-wrap">
									{startupCaseDetail.serviceInfo}
								</p>
							</Case>
						</If>
					</div>
				</div>
			</ModalTemplate>
		</NewLandingLayout>
	);
};

export default CasePage;
