import {
	SET_COMPANY_INFO,
	SET_CURRENT_PRODUCT,
	SET_PRODUCT_LIST,
	CLEAR_COMPANY,
	SET_MEMBER_LIST,
	SET_MEMBER_TREND,
} from "./actions";

export const INITIAL_STATE = {
	info: {
		address: "",
		addressDetail: "",
		bizCheck: false,
		bizStatus: "",
		bizType: "",
		ceoContact: "",
		ceoEmail: "",
		ceoName: "",
		certType: "",
		comCheck: false,
		country: "KR",
		description: "",
		employeeNum: null,
		estDate: new Date(),
		homepage: "",
		idx: null,
		industryField: "",
		introduction: "",
		irCheck: false,
		license: "",
		listed: false,
		logoRec: "",
		logoSqu: "",
		name: "",
		regNum: "",
		sales: "",
		salesYear: `${new Date().getFullYear()}`,
		type: "",
		viewMode: false,
		zipCode: "",
		CompanyField: [],
	},
	currentProduct: {},
	repProduct: { CompanyProductKeyword: [], CompanyProductImage: [] },
	productList: [],
	memberList: [],
	memberTrend: [],
};

export const REDUCER = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_COMPANY_INFO:
			return {
				...state,
				info: action.payload.info,
				repProduct: action.payload.repProduct,
			};
		case SET_PRODUCT_LIST:
			return {
				...state,
				productList: action.payload.productList,
			};
		case SET_CURRENT_PRODUCT:
			return {
				...state,
				currentProduct: action.payload.product,
			};
		case SET_MEMBER_LIST:
			return {
				...state,
				memberList: action.payload.memberList,
			};
		case SET_MEMBER_TREND:
			return {
				...state,
				memberTrend: action.payload.memberTrend,
			};
		case CLEAR_COMPANY:
			return {
				...INITIAL_STATE,
			};
		default:
			return state;
	}
};
