/**
 * @param {*} object - 객체
 * @param {any[]} properties - 제거할 프로퍼티의 키들의 배열
 * @returns {*}
 */
export const removeFromObject = (object, properties) => {
	const newObject = { ...object };
	properties.forEach((property) => {
		delete newObject[property];
	});
	return newObject;
};

/**
 * @param {*} object
 * @param {string} path
 * @returns {*}
 */
export const getNestedValue = (object, path) => {
	if (!path) {
		return object;
	} else {
		try {
			const properties = path.split(".");
			return getNestedValue(object[properties.shift()], properties.join("."));
		} catch {
			return undefined;
		}
	}
};

/**
 * @param {any[]} array
 * @param {string} keyword
 * @param {string[]} properties
 * @returns {*}
 */
export const findPropertyValue = (array, keyword, properties) => {
	return array.reduce((acc, cur) => {
		const isContained = (properties ?? Object.keys(cur)).some((e) => {
			const string = (cur[e] ?? "").toString();
			return string.includes(keyword);
		});
		if (isContained === true || keyword.length === 0) {
			acc = acc.concat(cur);
		}
		return acc;
	}, []);
};

export const getKeyByValue = (object, value) => {
	const key = Object.keys(object).find((key) => object[key] === value);
	if (key !== undefined) {
		return key;
	} else {
		return "undefined";
	}
};

export const ObjectIsNotEmpty = (object) => {
	return Object.keys(object).length !== 0;
};
