export const SET_SELECTED_IDX = "ir/SET_SELECTED_IDX";
export const CLEAR_SELECTED_IDX = "ir/CLEAR_SELECTED_IDX";
export const SET_BASIC_INFO = "ir/SET_BASIC_INFO";
export const SET_MEMBER = "ir/SET_MEMBER";
export const SET_HISTORY = "ir/SET_HISTORY";
export const SET_SHAREHOLDER = "ir/SET_SHAREHOLDER";
export const SET_FINANCE = "ir/SET_FINANCE";
export const SET_PRODUCT = "ir/SET_PRODUCT";
export const SET_OUTCOME = "ir/SET_OUTCOME";

export const selectIr = (idx) => {
	return {
		type: SET_SELECTED_IDX,
		payload: {
			idx,
		},
	};
};

export const clearSelectedIr = () => ({
	type: CLEAR_SELECTED_IDX,
});

export const setBasicInfo = (basicInfo) => {
	return {
		type: SET_BASIC_INFO,
		payload: {
			basicInfo,
		},
	};
};

export const setHistory = (history) => {
	return {
		type: SET_HISTORY,
		payload: {
			history,
		},
	};
};

export const setMember = (member) => {
	return {
		type: SET_MEMBER,
		payload: {
			member,
		},
	};
};

export const setShareholder = (shareholder) => {
	return {
		type: SET_SHAREHOLDER,
		payload: {
			shareholder,
		},
	};
};

export const setFinance = (finance) => {
	return {
		type: SET_FINANCE,
		payload: {
			finance,
		},
	};
};

export const setProduct = (product) => {
	return {
		type: SET_PRODUCT,
		payload: {
			product,
		},
	};
};

export const setOutcome = (outcome) => {
	return {
		type: SET_OUTCOME,
		payload: {
			outcome,
		},
	};
};
