import {
	differenceInCalendarYears,
	format,
	getDay,
	hoursToMinutes,
	parse,
	setMilliseconds,
	setSeconds,
} from "date-fns";
import { DAY_TYPE } from "~/common/constants/form";
import i18n from "i18next";

/**
 * @param {Date || string} date UTC date
 * @param {string} dateFormat
 * @returns {*} KST date
 */
export const dateToString = (date, dateFormat = "yyyyMMdd") => {
	try {
		return format(new Date(date), dateFormat);
	} catch {
		return "";
	}
};

export const UTCdateToString = (date) => {
	try {
		const parseDate = new Date(date);
		const parseMonth = parseDate.getUTCMonth() + 1;
		return `${parseDate.getUTCFullYear()}-${
			parseMonth.toString().length === 1 ? `0${parseMonth}` : parseMonth
		}-${
			parseDate.getUTCDate().toString().length === 1
				? `0${parseDate.getUTCDate()}`
				: parseDate.getUTCDate()
		} ${
			parseDate.getUTCHours().toString().length === 1
				? `0${parseDate.getUTCHours()}`
				: parseDate.getUTCHours()
		}:${
			parseDate.getUTCMinutes().toString().length === 1
				? `0${parseDate.getUTCMinutes()}`
				: parseDate.getUTCMinutes()
		}:${
			parseDate.getUTCSeconds().toString().length === 1
				? `0${parseDate.getUTCSeconds()}`
				: parseDate.getUTCSeconds()
		}`;
	} catch {
		return "";
	}
};

/**
 * @param {number} phoneNumber phone number
 * @example phoneNumberFormatter("01011112222")
 */
export const phoneNumberFormatter = (phoneNumber) => {
	let num = phoneNumber?.toString();
	try {
		if (num.length === 9) {
			return num.replace(/(\d{3})(\d{3})(\d{3})/, "$1-$2-$3");
		} else if (num.length === 10) {
			return num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
		} else if (num.length === 11) {
			return num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
		}
	} catch {
		return phoneNumber;
	}
};

/**
 * @param {number} companyNumber company number
 * @example companyNumberFormatter(1231212345)
 */
export const companyNumberFormatter = (companyNumber) => {
	try {
		return companyNumber.toString().replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
	} catch {
		return companyNumber;
	}
};

/**
 * @param {number} number number
 * @example thousandFormatter(1000000)
 */
export const thousandFormatter = (number) => {
	try {
		return parseInt(number || 0).toLocaleString();
	} catch {
		return number;
	}
};

/**
 * @param {number} ssn social security number
 * @example ssnFormatter(0000001111111)
 */
export const ssnFormatter = (ssn) => {
	try {
		return ssn.toString().replace(/(\d{6})(\d{7})/, "$1-$2");
	} catch {
		return ssn.toString();
	}
};

/**
 * @param {number} "hhmmss" time string
 * @example timeFormatter(121212,":")
 */
export const timeFormatter = (time, separator) => {
	try {
		return time.toString().replace(/(\d{2})(\d{2})(\d{2})/, `$1${separator}$2${separator}$3`);
	} catch {
		return time.toString();
	}
};

/**
 * @param {number} "hhmmss" time string
 * @example dateTimeFormatter(20210101121212)
 */
export const dateTimeFormatter = (dateTime) => {
	try {
		return dateTime
			.toString()
			.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, `$1.$2.$3 $4:$5:$6`);
	} catch {
		return dateTime.toString();
	}
};

/**
 * @param {number} number zeroLeadingFormatter
 * @example zeroLeadingFormatter(1)
 */
export const zeroLeadingFormatter = (number) => {
	try {
		return number.toString().padStart(2, "0");
	} catch {
		return number.toString();
	}
};

/**
 * @param {string|Date} date string(yyyyMMdd, yyyyMM) or date object
 * @param {string} [sep] string separator(option)
 * @example dateFormatter(new Date(), "-")
 */
export const dateFormatter = (date, separator) => {
	try {
		if (typeof date === "string" && date.length === 8) {
			return date
				.toString()
				.replace(
					/(\d{4})(\d{2})(\d{2})/,
					`$1${separator ? separator : "."}$2${separator ? separator : "."}$3`
				);
		} else if (typeof date === "string" && date.length === 6) {
			return date.toString().replace(/(\d{4})(\d{2})/, `$1${separator ? separator : "."}$2`);
		} else {
			const y = date.getFullYear();
			const m = zeroLeadingFormatter(date.getMonth() + 1);
			const d = zeroLeadingFormatter(date.getDate());
			return `${y}${separator ? separator : ""}${m}${separator ? separator : "."}${d}`;
		}
	} catch (e) {
		if (date === null || date === undefined) {
			return "";
		} else {
			return date.toString();
		}
	}
};

/**
 * @param {string} link
 * @example extLinkFormatter("instagram.com")
 */
export const extLinkFormatter = (link) => {
	if (link === undefined || link === null || link.length === 0) {
		return undefined;
	} else {
		return link.startsWith("http") ? link : `https://${link}`;
	}
};

/**
 * @param {string} time
 * @example timeToDateFormatter(14:30:00) = Fri May 06 2022 14:30:00 GMT+0900
 */
export const timeToDateFormatter = (time) => {
	try {
		const timeValue = time.split(":");
		const date = new Date();

		date.setHours(
			parseInt(timeValue[0]),
			parseInt(timeValue[1] ?? "00"),
			parseInt(timeValue[2] ?? "00")
		);
		return date;
	} catch {
		return time;
	}
};

/**
 * @param {string} dashDate
 * @example dashDateToDateFormatter(2022-06-18) = Sta jun 18 2022 02:40:00 GMT+0900
 */
export const dashDateToDateFormatter = (dashDate) => {
	try {
		const dateValue = dashDate.split("-");
		const date = new Date();

		date.setFullYear(
			parseInt(dateValue[0]),
			parseInt(dateValue[1]) - 1,
			parseInt(dateValue[2])
		);
		return date;
	} catch {
		return dashDate;
	}
};

/**
 * @param {number} number
 * @example hoursDurationFormatter(450) = 7시간 30분
 */
export const hoursDurationFormatter = (number) => {
	try {
		if (number !== 0) {
			const hour = parseInt(number / 60) !== 0 ? parseInt(number / 60) + "시간" : "";
			const min = number % 60 !== 0 ? (number % 60) + "분" : "";

			return hour + " " + min;
		} else {
			return number + "시간";
		}
	} catch {
		return number;
	}
};

/**
 * @param {number} number
 * @param {number} totalMinutes
 * @example dayFormatter(1110) = 2일 2시간 30분
 */
export const dayFormatter = (number, totalMinutes) => {
	try {
		if (number >= totalMinutes) {
			const day = parseInt(number / totalMinutes);
			const remainder = number % totalMinutes;
			const hourMinutes =
				hoursDurationFormatter(remainder) !== "0시간"
					? hoursDurationFormatter(remainder)
					: "";

			return day + "일 " + hourMinutes;
		} else {
			return hoursDurationFormatter(number);
		}
	} catch {
		return number;
	}
};

/**
 * @param {string} duration
 * @param {number} totalMinutes
 * @example durationToMinutesFormatter(6일 5시간 30분, 480) = 3210;
 * @example durationToMinutesFormatter(0.5일, 480);
 */
export const durationToMinutesFormatter = (duration, totalMinutes = 1440) => {
	try {
		const day =
			duration.indexOf("일") !== -1
				? Number(duration.substring(0, duration.indexOf("일")))
				: 0;
		const hour =
			duration.indexOf("시간") !== -1
				? Number(duration.substring(duration.indexOf("일") + 1, duration.indexOf("시간")))
				: 0;
		const min =
			duration.indexOf("분") !== -1
				? Number(duration.substring(duration.indexOf("시간") + 2, duration.indexOf("분")))
				: 0;
		const dayToMinutes = day * totalMinutes;

		return dayToMinutes + hoursToMinutes(hour) + min;
	} catch {
		return duration;
	}
};

/**
 * @param {Date} date
 * @param {String} format
 * @example DateWithDayName(Mon May 16 2022 00:00:00 GMT+0900) = "5월 16일 (월)"
 */
export const DateWithDayName = (date, format = i18n.language === "ko" ? "M월 dd일 " : "dd.M ") => {
	const newDate = dateToString(date, format);
	const dayName = DAY_TYPE[getDay(new Date(date))];
	return newDate + "(" + dayName + ")";
};

export const YearDateWithDayName = (
	date,
	format = i18n.language === "ko" ? "yyyy년 M월 dd일 " : "dd.M.yyyy "
) => {
	const newDate = dateToString(date, format);
	const dayName = DAY_TYPE[getDay(new Date(date))];
	return newDate + "(" + dayName + ")";
};

/**
 * @param {number} number
 * @example isInteger(0.5) = false
 */
export const isInteger = (number) => {
	return number % 1 === 0;
};

/**
 * @param {array} array
 * @example [2, 1, 3, 10] = [1, 2, 3, 10]
 */
export const ascSort = (array) => {
	const ascArray = [...array];
	ascArray.sort(function (a, b) {
		return a - b;
	});
	return ascArray;
};

/**
 * @param {array} array
 * @example [2, 1, 3, 10] = [10, 3, 2, 1]
 */
export const descSort = (array) => {
	const descArray = [...array];
	descArray.sort(function (a, b) {
		return b - a;
	});
	return descArray;
};

/**
 * @param {Date} date
 * @example timeToDateFormatter(14:30:00) = Fri May 06 2022 14:30:00 GMT+0900
 */
export const setExactDate = (date) => {
	try {
		return setMilliseconds(setSeconds(date, 0), 0).valueOf();
	} catch {
		return date;
	}
};

/**
 * @param {Date} UTC_DATE
 * @example oneDayPastCheck(16:48:00) =22분 전, 22시간 전, 2022.11.22
 */
export const oneDayPastCheck = (UTC_DATE) => {
	const today = new Date();
	const KST_DATE = new Date(UTC_DATE);
	const timeGap = (today - KST_DATE) / (60 * 60 * 1000);
	if (timeGap <= 24) {
		if (Math.floor(timeGap) === 0) {
			return `${Math.ceil(timeGap * 60)}분 전`;
		} else {
			return `${Math.floor(timeGap)}시간 전`;
		}
	} else {
		return dateFormatter(KST_DATE, ".");
	}
};

/**
 * @param {string} content
 * @example deleteTagRegex('<p>gitbal</p>') = gitbal
 */
export const deleteTagRegex = (content) => {
	return content.replace(/(<([^>]+)>)/gi, "");
};

/**
 * @param {string} content
 * @example imgTagExtraction('<img src="~~~~" /> gitbal <img src="~~~" />') = ['<img src="~~~" />', '<img src="~~~" />']
 * */
export const imgTagExtraction = (content) => {
	const regex = /<img[^>]*src=["']?([^>"']+)["']?[^>]*>/g;
	return content.match(regex);
};

/**
 * @param {string} imgTag
 * @example imgTagSrcExtraction('<img src="~~~~~">') = "~~~~~"
 */
export const imgTagSrcExtraction = (imgTag) => {
	const regex = /"[\"']?([^>\"']+)[\"']/g;
	return imgTag.match(regex)
		? imgTag.match(regex).map((src) => src.substring(1, src.length - 1))
		: [];
};

/**
 * @param {number} bytes
 * @param {number} decimals
 * @example formatBytes(1024, 2) = 1KB
 */
export const formatBytes = (bytes, decimals) => {
	if (bytes === 0) return "0 Bytes";
	const divide = 1024,
		decimal = decimals || 2,
		sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
		i = Math.floor(Math.log(bytes) / Math.log(divide));
	return parseFloat((bytes / Math.pow(divide, i)).toFixed(decimal)) + " " + sizes[i];
};

/**
 * @param {string} content
 * @example lineBreakFormatter("~~~\n~~~") = "~~~<br/>~~~"
 */
export const lineBreakFormatter = (content) => {
	const regex = /(?:\r\n|\r|\n)/g;
	return content.replace(regex, "<br />");
};
/**
 * @param {string} time
 * 꼭 아래와 같은 형태를 지켜야 함
 * @example timeFormatter(18:21:00) = "18시 21분"
 * */
export const hourAndMinuteFormatter = (time) => {
	return format(parse(time, "HH:mm:ss", new Date()), "HH시 mm분");
};

/**
 * @param letter string
 * @example ConvertFirstLetterToUpperCase("gitbal") = "Gitbal"
 */
export const ConvertFirstLetterToUpperCase = (letter) => {
	return letter.charAt(0).toUpperCase() + letter.slice(1);
};

/**
 * @param {string} cardNumber
 * @example CardNumberFormatter("4100********4100") = "4100-****-****-4100"
 */
export const CardNumberFormatter = (cardNumber) => {
	return cardNumber.match(/[0-9*]{1,4}/g).join("-");
};

export const YearMonthFormatter = (date) => {
	const YearMonthDate = new Date(date);
	return `${YearMonthDate.getFullYear()}년 ${YearMonthDate.getMonth() + 1}월`;
};

export const signFormatter = (number) => {
	const IntegerNumber = Math.abs(number);
	return Math.sign(number) === -1 ? `- ${IntegerNumber}` : `+ ${IntegerNumber}`;
};

export const sign = (number) => {
	return Math.sign(number) === -1 ? `-` : `+`;
};

/**
 * @param {string | Date} startDate
 * @returns {*[]}
 */
export const getBetweenYears = (startDate) => {
	const CurrentYear = new Date();
	const UserYear = new Date(startDate);
	const DifferYear = differenceInCalendarYears(CurrentYear, UserYear) + 1;
	let years = [];
	for (let i = 0; i < DifferYear; i++) {
		years.push({
			label: `${CurrentYear.getFullYear() - i}년`,
			value: CurrentYear.getFullYear() - i,
		});
	}
	return years;
};

/**
 * @param {number} number
 * @returns
 * @example getOnlyDemical(5.654) === 0.564
 */
export const getOnlyDemical = (number) => {
	const onlyDemical = number - Math.floor(number);
	if (onlyDemical === 0) return 0;
	return onlyDemical;
};
