import React, {useEffect} from "react";
import { VACATION_CLASSNAME, VACATION_STATUS, VACATION_STATUS_TEXT } from "~/common/constants/form";
import { DateWithDayName } from "~/utils/formatter";

const List = ({
	vacationList,
	setSelectedVacation,
	setVacationManageModal,
	titleText,
	timeText,
}) => {

	return vacationList.previousVacations.data.map((vacation, v_idx) => (
		<div
			className="row"
			onClick={() => {
				setSelectedVacation(vacation);
				setVacationManageModal(true);
			}}
			key={v_idx}
		>
			<div
				className={`vacation-name ${
					VACATION_CLASSNAME[vacation.HrVacation.title] ?? "others"
				}`}
			>
				{titleText(vacation)}
			</div>
			<div className="date">
				{vacation.startDate === vacation.endDate
					? DateWithDayName(vacation.startDate)
					: `${DateWithDayName(vacation.startDate)}
					   ~ 
					  ${DateWithDayName(vacation.endDate)}`}
			</div>
			<div className="time">{timeText(vacation)}</div>
			<div className="permission">
				<span className={VACATION_STATUS[vacation.status]}>
					{VACATION_STATUS_TEXT[VACATION_STATUS[vacation.status]]}
				</span>
			</div>
		</div>
	));
};
export default List;
