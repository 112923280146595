import { useState } from "react";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";

const useUpload = (type, defaultUrl) => {
	const uploadRequest = useRequest("post", "file/st/upload");

	const [url, setUrl] = useState(defaultUrl ?? null);
	const [fileName, setFileName] = useState("");
	const [fileSize, setFileSize] = useState(0);
	const [state, setState] = useState(API_STATE.ready);

	const upload = (file) => {
		return new Promise(async (resolve, reject) => {
			const formData = new FormData();
			formData.append("path", type.path);
			formData.append("type", type.type);
			formData.append("file", file[0], file[0].name);

			if (state === API_STATE.loading) {
				reject(API_STATE.loading);
			}

			setState(API_STATE.loading);

			uploadRequest
				.asyncCall(formData)
				.then(({ data }) => {
					setUrl(data);
					setFileName(file[0].name);
					setFileSize(file[0].size);
					setState(API_STATE.done);
					resolve(data);
				})
				.catch((e) => {
					setUrl(null);
					setFileName("");
					setFileSize(0);
					setState(API_STATE.error);
					reject(e);
				});
		});
	};

	const clear = () => {
		setUrl(null);
		setFileName("");
		setFileSize(0);
	};

	return {
		upload,
		url,
		fileName,
		fileSize,
		state,
		clear,
		result: uploadRequest.result,
		error: uploadRequest.error,
	};
};

export default useUpload;
