import {
	OKCEO_LOGIN,
	OKCEO_LOGOUT,
	SET_ACCOUNT_DATA,
	SET_COST_DATA,
	SET_SALES_DATA,
	SET_FLOW_DATA,
	SET_PROFIT_DATA,
	SET_DUTY_DATA,
} from "./actions";
import persistHelper from "~/utils/persist";

export const INITIAL_STATE = {
	logged: false,
	accessToken: "",
	account: null,
	cost: null,
	sales: null,
	flow: null,
	profit: null,
	duty: null,
};

export const PERSISTED_STATE = persistHelper.initState("okceo.auth", INITIAL_STATE, "local");

export const REDUCER = (state = PERSISTED_STATE, action) => {
	switch (action.type) {
		case OKCEO_LOGIN:
			return persistHelper.setPersist(
				"okceo.auth",
				{
					logged: true,
					accessToken: action.payload.accessToken,
				},
				"local"
			);
		case SET_ACCOUNT_DATA:
			const account = action.payload.account;
			return {
				...state,
				account: account,
			};
		case SET_COST_DATA:
			const cost = action.payload.cost;
			return {
				...state,
				cost: cost,
			};
		case SET_SALES_DATA:
			const sales = action.payload.sales;
			return {
				...state,
				sales: sales,
			};
		case SET_FLOW_DATA:
			const flow = action.payload.flow;
			return {
				...state,
				flow: flow,
			};
		case SET_PROFIT_DATA:
			const profit = action.payload.profit;
			return {
				...state,
				profit: profit,
			};
		case SET_DUTY_DATA:
			const duty = action.payload.duty;
			return {
				...state,
				duty: duty,
			};
		case OKCEO_LOGOUT:
			return {
				...INITIAL_STATE,
			};
		default:
			return state;
	}
};
