

import React, {useEffect} from "react";
import { PlusIcon } from "~/assets/svg/MathIcon";
import { subDays } from "date-fns";
import { dateFormatter, dateToString } from "~/utils/formatter";
import { PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import { Case, If } from "~/common/components/If";
import { useTranslation } from "react-i18next";
import {ArrowRight} from "~/assets/svg/InterfacesIcon";
import {Link} from "react-router-dom";
import moment from "moment";
import {useNavigate} from "react-router";


const RecentNotice = ({ data, pageState }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    // const AlertRow = ({ category, title, date }) => {
    //     return (
    //         <li className="row notice">
    //             <div className="board-title">
    //                 <p className={"board-title-ellipsis"}><b className={"category"}>[{category}]</b>{title}</p>
    //             </div>
    //             <div className="board-date">{date}</div>
    //         </li>
    //     );
    // };
    //
    // const AlertTable = () => {
    //     data.map(({ type, title, createdAt, content }, i) => {
    //         const formattedDate =
    //             dateFormatter(new Date()) === dateFormatter(createdAt)
    //                 ? t("Dashboard.Contents.RecentAlert.AlertRowDate.0")
    //                 : dateFormatter(subDays(new Date(), 1)) === dateFormatter(regDate)
    //                     ? t("Dashboard.Contents.RecentAlert.AlertRowDate.1")
    //                     : moment(regDate).format("YYYY-MM-DD")
    //         // : dateToString(regDate, "MM-dd");
    //         return <AlertRow key={i} category={category} title={title} date={formattedDate} />;
    //     });
    // }


    return (
        <article className="recent-board">
            <div className="recent-board-top">
                <div className="c__title">
                    {t("Dashboard.Contents.RecentNotice.notice-title")}
                </div>
                <Link to={"/community/list"}><ArrowRight/></Link>
            </div>
            <If condition={pageState}>
                <Case condition={PAGE_STATE.LOADING}>
                    <Loading />
                </Case>
                <Case condition={PAGE_STATE.VIEW}>
                    {data?.length > 0 ? (
                        <ul>
                            { data?.map((item) => (
                                <li
                                    className="row notice"
                                    key={item.idx}
                                    onClick={() => {
                                        if(item.type === "NOTICE") {
                                            navigate(`/community/notice/detail/${item?.idx}`);
                                        } else if(item.type === "COMMUNITY") {
                                            console.log("NOTICE url 1: ", `/community/list/board/${item?.Community?.idx}`);
                                            console.log("NOTICE url 2: ", `/community/list/board/${item?.idx}`);

                                            navigate(`/community/list/board/${item?.Community?.idx}/post/${item?.idx}`);
                                        }
                                    }}
                                >
                                    <div className="board-title">
                                        <p className={"board-title-ellipsis"}>
                                            <b className={"category"}>[{
                                                item.type === "NOTICE" ?
                                                    t("Dashboard.Contents.RecentNotice.notice") :
                                                    item.type === "COMMUNITY" ?
                                                        t("Dashboard.Contents.RecentNotice.community") :
                                                        ""
                                            }]</b>
                                            {item.title}
                                        </p>
                                    </div>
                                    <div className="board-date">{moment(item.createdAt).format("YYYY-MM-DD")}</div>
                                </li>
                            )) }
                            {/*<AlertTable />*/}
                        </ul>
                    ) : (
                        <div className="recent-board-initial">
                            <p>{t("Dashboard.Contents.RecentAlert.recent-alarm-initial")}</p>
                        </div>
                    )}
                </Case>
            </If>
        </article>
    );
};

export default RecentNotice;
