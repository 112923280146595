import React, { useState } from "react";
import TabMenu from "~/common/components/TabMenu";
import { Case, If } from "~/common/components/If";
import ProfileEdit from "../components/ProfileEdit";
import HrView from "../components/HrView";
import PersonalEdit from "../components/PersonalEdit";
import CareerEdit from "../components/CareerEdit";
import ScrollTop from "~/common/components/ScrollTop";
import translation from "~/translations";

const MineEdit = ({ useForm, userDetail, isHrTeamMember }) => {
	const [currentTab, setCurrentTab] = useState("basic");

	const tabMenuList = [
		{ id: "basic", title: translation.t("HR.TeamInfo.modal.MemberDetailModal.EditView.basic") },
		{
			id: "career",
			title: translation.t("HR.TeamInfo.modal.MemberDetailModal.EditView.career"),
		},
	];

	return (
		<>
			<div className="modal-content">
				<ProfileEdit useForm={useForm} />
				<div className="tabInfo-area">
					<TabMenu
						tabItem={tabMenuList}
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
					<ScrollTop className="scroll-wrap">
						<If condition={currentTab} useForm={useForm}>
							<Case condition={"basic"}>
								{isHrTeamMember && <HrView userDetail={userDetail} />}
								<PersonalEdit isHrTeamMember={isHrTeamMember} />
							</Case>
							<Case condition={"career"}>
								<CareerEdit />
							</Case>
						</If>
					</ScrollTop>
				</div>
			</div>
		</>
	);
};

export default MineEdit;
