import React, { useEffect } from "react";
import Form from "~/common/components/Form";
import Select, { optionsGenerator } from "~/common/components/Select";
import Input from "~/common/components/Input";
import { useForm } from "react-hook-form";
import useAlert from "~/common/hooks/useAlert";
import useRequest from "~/common/hooks/useRequest";
import FileUploader from "~/common/components/FileUploader";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import RequestMsg from "~/pages/invest/EvaluatePage/EvaluateDetail/View/components/requestMsg";
import { useNavigate } from "react-router";
import { useGlobalStore } from "~/store";
import { API_STATE } from "~/common/constants/state";
import AuthButton from "~/common/components/AuthButton";
import { Trans, useTranslation } from "react-i18next";

const Request = ({ setCardStep }) => {
	const { t } = useTranslation();
	const [
		{
			evaluate: {
				currentEvaluate: { idx },
				irList,
			},
		},
	] = useGlobalStore();
	const navigate = useNavigate();
	const irSendForm = useForm({
		defaultValues: { investIdx: idx, businessPlan: "", promotionUrl: "" },
		// defaultValues: { investIdx: idx },
	});

	const [showReject, Reject] = useAlert();
	const [showAccept, Accept] = useAlert();
	const [showAlert, Alert] = useAlert();

	const investConfirmRequest = useRequest("post", "/invest/confirm");
	const investRejectRequest = useRequest("post", "/invest/reject");

	const submit = (data) => {
		const requestData = {
			...data,
			irIdx: parseInt(data.irIdx),
		};

		showAccept()
			.then(async () => {
				await investConfirmRequest.call(requestData);
			})
			.catch(() => {});
	};

	useEffect(() => {
		switch (investConfirmRequest.state) {
			case API_STATE.done:
				showAlert().then(async () => {
					await navigate("/invest/review");
				});
				break;
			case API_STATE.error:
				if (investConfirmRequest.error.statusCode === 404) {
					alert(t("Alert.alert1"));
					console.log("error", investConfirmRequest.error);
				}
		}
	}, [investConfirmRequest.state]);

	const handleReject = () => {
		showReject()
			.then(async () => {
				await investRejectRequest.call(idx, "path");
				await navigate("/invest/review");
			})
			.catch(() => {});
	};

	const handleCancel = () => {
		irSendForm.reset();
		setCardStep(1);
	};

	return (
		<>
			<section className="c__card message">
				<RequestMsg />
				<div className="card-bottom">
					<p>{t("Invest.Evaluate.EvaluateDetail.Request.approval-message")}</p>
					<div className="card-btn-group">
						<AuthButton
							type="button"
							className="btn__solid"
							color="gray"
							onClick={handleReject}
						>
							{t("Invest.situation.reject")}
						</AuthButton>
						<AuthButton
							type="button"
							className="btn__solid"
							color="primary"
							onClick={() => setCardStep(2)}
						>
							{t("Invest.situation.approval")}
						</AuthButton>
					</div>
				</div>
			</section>
			<section className="c__card info">
				<div className="top">
					<p className="phrase pre">
						<Trans
							i18nKey="Invest.Evaluate.EvaluateDetail.View.components.acceptInfo.phrase.before"
							components={[<b />]}
						/>
					</p>
					<div className="form-ir-send">
						<Form useForm={irSendForm}>
							{irList.length === 0 ? (
								<div className="f__group required">
									<p className="f__label">
										{t(
											"Invest.Evaluate.EvaluateDetail.Request.card-info.form-ir-send.label"
										)}
									</p>
									<p className="f__control ir-none">
										{t(
											"Invest.Evaluate.EvaluateDetail.Request.card-info.form-ir-send.ir-none1"
										)}
										<button
											onClick={() => navigate("/ir/list")}
											className="empty"
										>
											{t(
												"Invest.Evaluate.EvaluateDetail.Request.card-info.form-ir-send.empty-button"
											)}
										</button>
										{t(
											"Invest.Evaluate.EvaluateDetail.Request.card-info.form-ir-send.ir-none2"
										)}
									</p>
								</div>
							) : (
								<Select
									required
									label={t(
										"Invest.Evaluate.EvaluateDetail.Request.card-info.form-ir-send.label"
									)}
									placeholder={t(
										"Invest.Evaluate.EvaluateDetail.Request.card-info.form-ir-send.select-placeholder"
									)}
									name="irIdx"
									options={optionsGenerator(irList)}
								/>
							)}
							<FileUploader
								label={t(
									"Invest.Evaluate.EvaluateDetail.Request.card-info.form-ir-send.fileUploader-label"
								)}
								name="businessPlan"
								type={UPLOAD_TYPE.EVALUATE_BUSINESS}
								format={".png, .jpg, .jpeg, .pdf, .doc, .hwp"}
							/>
							<Input
								label={t(
									"Invest.Evaluate.EvaluateDetail.Request.card-info.form-ir-send.input-label"
								)}
								name="promotionUrl"
								placeholder={t(
									"Invest.Evaluate.EvaluateDetail.Request.card-info.form-ir-send.input-placeholder"
								)}
							/>
						</Form>
					</div>
				</div>
				<div className="card-bottom">
					<p>{t("Invest.Evaluate.EvaluateDetail.Request.complete-message")}</p>
					<div className="card-btn-group">
						<button
							type="button"
							className="btn__solid"
							color="gray"
							onClick={handleCancel}
						>
							{t("button.cancel")}
						</button>
						<button
							type="button"
							className="btn__solid"
							color="primary"
							onClick={irSendForm.handleSubmit(submit)}
							disabled={irList.length === 0}
						>
							{t("button.confirm")}
						</button>
					</div>
				</div>
			</section>
			<Reject
				message={t("Invest.Evaluate.EvaluateDetail.Request.modal.Reject-message")}
				type="error"
				confirm={t("Invest.situation.reject")}
				reject={t("button.cancel")}
			/>
			<Accept
				message={t("Invest.Evaluate.EvaluateDetail.Request.modal.Accept-message")}
				type="warning"
				confirm={t("button.yes")}
				reject={t("button.No")}
			/>
			<Alert
				message={t("Invest.Evaluate.EvaluateDetail.Request.modal.Alert-message")}
				type="success"
				confirm={t("button.check")}
			/>
		</>
	);
};

export default Request;
