import React, { useEffect } from "react";
import { Case, If, OtherWise } from "~/common/components/If";
import AdminEdit from "./AdminEdit";
import MineEdit from "./MineEdit";
import { useForm } from "react-hook-form";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import { USER_INFO } from "~/store/user/repository";
import useRepository from "~/common/hooks/useRepository";
import useAlert from "~/common/hooks/useAlert";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Edit = ({
	userRole,
	userDetail,
	showViewPage,
	handleSearch = () => {},
	getInitialState = () => {},
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [getUserInfo] = useRepository(USER_INFO);
	const { user, career, memo } = userDetail;

	const editForm = useForm({
		defaultValues: {
			UserProfile: {
				...user,
				joinDate: user.joinDate,
				workingType: user.workingType?.toString(),
				workStartDate: user.workStartDate,
				workEndDate: user.workEndDate,
				resignDate: user.resignDate,
				employmentStatus: user.employmentStatus?.toString(),
			},
			UserCareer: career.map((d) => ({
				...d,
				content: d.content.replace(/<br\s*\/?>/gm, "\n"),
			})),
			UserMemo: memo,
		},
	});

	const queryString = `hr/team/info/edit/${userRole === "admin" ? "admin" : "user"}`;

	const userDetailMutation = useRequest("post", queryString);

	const [showMemoAlert, MemoAlert] = useAlert();
	const [showCareerAlert, CareerAlert] = useAlert();

	const submit = (data) => {
		const propertyProvider = (object, propList) => {
			return propList.reduce((acc, cur) => {
				acc[cur] = object[cur];
				return acc;
			}, {});
		};

		const commonProfileData = [
			"name",
			"phone",
			"introduction",
			"zipCode",
			"address",
			"addressDetail",
			"facebook",
			"twitter",
			"instagram",
			"linkedin",
			"youtube",
			"regNum",
			"bank",
			"account",
			"workStartDate",
			"workEndDate",
		];

		const adminProfileData = [
			...commonProfileData,
			"department",
			"orgId",
			"position",
			"authentication",
			"employmentStatus",
			"group",
			"salary",
			"joinDate",
			"resignDate",
			"workingType",
			"contractStatus",
		];

		const requestData = {
			idx: user.idx,
			img: data.UserProfile.img,
			UserProfile: propertyProvider(
				data.UserProfile,
				userRole === "admin" ? adminProfileData : commonProfileData
			),
			UserCareer: data.UserCareer.map((d) => ({
				...d,
				content: d.content.replace(/(?:\r\n|\r|\n)/g, "<br />"),
			})),
			UserMemo: userRole === "admin" ? data.UserMemo : undefined,
		};

		const isCareerValid =
			requestData.UserCareer.filter(
				({ content, companyName }) => content.length < 1 && companyName.length < 1
			).length === 0;
		const isMemoValid =
			requestData.UserMemo ?? [].filter(({ content }) => content.length < 1).length < 1;
		if (!isCareerValid) {
			showCareerAlert();
		} else if (!isMemoValid) {
			showMemoAlert();
		} else {
			userDetailMutation.call(requestData);
		}
	};

	useEffect(() => {
		switch (userDetailMutation.state) {
			case API_STATE.done:
				(async () => {
					await getUserInfo();
					handleSearch();
					getInitialState();
					await showViewPage();
				})();
				break;
			case API_STATE.error:
				console.error(userDetailMutation.error);
				navigate("/404");
				break;
			default:
				break;
		}
	}, [userDetailMutation.state]);

	return (
		<>
			<If condition={userRole} useForm={editForm} userDetail={userDetail}>
				<Case condition={"admin"}>
					<AdminEdit />
				</Case>
				<OtherWise>
					<MineEdit />
				</OtherWise>
			</If>
			<div className="modal-footer">
				<button
					type="button"
					key="cancel"
					className="btn__solid"
					color="gray"
					onClick={showViewPage}
				>
					{t("button.cancel")}
				</button>
				<button
					type="button"
					key="edit"
					className="btn__solid"
					color="primary"
					onClick={editForm.handleSubmit(submit)}
				>
					{t("button.confirm")}
				</button>
			</div>
			<MemoAlert
				type="warning"
				message={t("HR.TeamInfo.modal.MemberDetailModal.EditView.MemoAlert")}
			/>
			<CareerAlert
				type="warning"
				message={t("HR.TeamInfo.modal.MemberDetailModal.EditView.CareerAlert")}
			/>
		</>
	);
};

export default Edit;
