import React from "react";

import Form, { Group } from "~/common/components/Form";
import Input from "~/common/components/Input";
import { LightCloseIcon } from "~/assets/svg/CloseIcon";
import SingleImageUploader from "~/common/components/FormImageUploader/SingleImageUploader";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import Textarea from "~/common/components/Textarea";
import { useTranslation } from "react-i18next";

const Card = ({ useForm, name, remove }) => {
	const { t } = useTranslation();
	return (
		<div className="c__card">
			<span className="btn-close" onClick={remove}>
				<LightCloseIcon />
			</span>
			<div className="data-form">
				<Form useForm={useForm}>
					<SingleImageUploader
						name={name("img")}
						type={UPLOAD_TYPE.IR_MEMBER}
						className={"member-img"}
						height="150px"
						width="150px"
						round
					/>
					<Group className="group-wrap">
						<Input
							name={name("department")}
							placeholder={t("Company.Member.Edit.department")}
							required={false}
						/>
						<Input
							name={name("position")}
							placeholder={t("Company.Member.Edit.position")}
							required={false}
						/>
					</Group>
					<Input
						name={name("name")}
						placeholder={t("Company.Member.Edit.name")}
						required
					/>
					<Textarea
						name={name("introduction")}
						placeholder={t("Company.Member.Edit.introduction")}
						className="staff-info-textarea"
					/>
				</Form>
			</div>
		</div>
	);
};

export default Card;
