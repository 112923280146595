import React, {useState} from 'react';
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import {useTranslation} from "react-i18next";
import useAlert from "~/common/hooks/useAlert";
import {QuestionMarkIcon} from "~/assets/svg/InterfacesIcon";
import List from "~/pages/vacation/SettingPage/View/list.js";
import SettingPolicy from "~/pages/vacation/SettingPage/View/settingPolicy";
import {useGlobalStore} from "~/store";

const VacationSettingPage = (props) => {
    const { t } = useTranslation();
    const MENU = ["vacation", "setting"];
    const TYPE = "main";

    const [
        {
            sidebar: { currentPath },
        },
    ] = useGlobalStore();

    const [vacationTypeListModal, setVacationTypeListModal] = useState(false);
    const [showAuthAlert, AuthAlert] = useAlert();

    return(
        <NavbarAndSidebarLayout menu={MENU} type={TYPE} menuName={"vacation"}>
            <PageHeader
                title="설정"
                mainTitle="휴가"
                subTitle="설정"
            />
            <div className="c__card vacation-card">
                <SettingPolicy {...{ currentPath, showAuthAlert }} />
                <List {...{ currentPath, showAuthAlert }} />
            </div>
            <AuthAlert
                message={
                    <>
                        권한이 없습니다. <br />
                        관리자에게 문의하세요.
                    </>
                }
                type="warning"
                confirm="확인"
            />
        </NavbarAndSidebarLayout>
    )
}

export default VacationSettingPage
