import {
	SET_BOARD_LIST,
	SET_CURRENT_BOARD,
	SET_CURRENT_NOTICE,
	SET_COMMUNITY_AUTH,
	CLEAR_CURRENT_NOTICE,
} from "./actions";
import persistHelper from "~/utils/persist";

export const INITIAL_STATE = {
	boardList: [],
	currentBoard: {},
	myAuth: "",
	currentNotice: {},
};

export const PERSISTED_STATE = persistHelper.initState("community", INITIAL_STATE);

export const REDUCER = (state = PERSISTED_STATE, action) => {
	switch (action.type) {
		case SET_BOARD_LIST:
			return {
				...state,
				boardList: action.payload.boardList,
			};
		case SET_CURRENT_BOARD:
			return persistHelper.setPersist("community", {
				...state,
				currentBoard: action.payload.currentBoard,
			});

		case SET_COMMUNITY_AUTH:
			return persistHelper.setPersist("community", {
				...state,
				myAuth: action.payload.myAuth,
			});
		case SET_CURRENT_NOTICE:
			return {
				...state,
				currentNotice: action.payload.currentNotice,
			};
		case CLEAR_CURRENT_NOTICE:
			return {
				...state,
				currentNotice: action.payload.currentNotice,
			};
		default:
			return state;
	}
};
