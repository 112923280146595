import React from "react";
import { ClipIcon } from "~/assets/svg/InterfacesIcon";

import { deleteTagRegex, oneDayPastCheck } from "~/utils/formatter";
import { ALLOW_COMMENT_STATE } from "~/common/constants/state";
import defaultImg from "~/assets/img/defaultImg/default-img.png";
import { useTranslation } from "react-i18next";

const CardView = (props) => {
	const { t } = useTranslation();
	const { noticeList, handleNavigateView } = props;

	return (
		<>
			<div className="community-card-table">
				<div className="c__table">
					<div className="thead">
						<div className="tr">
							<div className="th title">{t("Community.board-table.title")}</div>
							<div className="th name">{t("Community.board-table.name")}</div>
							<div className="th date">{t("Community.board-table.date")}</div>
						</div>
					</div>
					{noticeList &&
						noticeList.map((notice, index) => (
							<div className="tbody" key={index}>
								<div
									className="tr"
									onClick={() => {
										handleNavigateView(notice.idx);
									}}
								>
									<div className="td title">
										{notice?.contentFiles.length !== 0 && (
											<div className="table-image">
												<img
													src={notice.contentFiles[0].path}
													alt={t("Community.board-img")}
													onError={(e) => (e.target.src = defaultImg)}
												/>
												{notice.contentFiles.length > 1 && (
													<span className="tag">
														{notice.contentFiles.length - 1 + " +"}
													</span>
												)}
											</div>
										)}
										<div className="table-box">
											<div className="title-box">
												<div className="text">{notice.title}</div>
												<div className="icon">
													{notice.uploadFiles.length !== 0 && (
														<span className="clip">
															<ClipIcon />
														</span>
													)}
													{notice.settings[0].value ===
														ALLOW_COMMENT_STATE.ALLOW &&
														notice.commentCount !== 0 && (
															<span className="num">
																[{notice.commentCount}]
															</span>
														)}
												</div>
											</div>
										</div>
									</div>
									<div className="td name">{notice.user.name} </div>
									<div className="td date">
										{oneDayPastCheck(notice.createdAt)}
									</div>
								</div>
							</div>
						))}
				</div>
			</div>
		</>
	);
};

export default CardView;
