import React from "react";
import PageHeader from "~/common/components/PageHeader";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import RoundList from "~/pages/invest/RoundPage/RoundList";

const RoundPage = () => {
	return (
		<NavbarAndSidebarLayout menu={["invest", "round"]} type="main">
			<PageHeader />
			<RoundList />
		</NavbarAndSidebarLayout>
	);
};
export default RoundPage;
