export const LOGIN = "sidebar/SET_CURRENT_PAGE";
export const SET_SIDEBAR_LIST = "sidebar/SET_SIDEBAR_LIST";
export const SET_CURRENT_MENU = "sidebar/SET_CURRENT_MENU";
export const SET_SIDEBAR_LAYOUT = "sidebar/SET_SIDEBAR_LAYOUT";

export const setSidebarList = (sideBarList) => ({
	type: SET_SIDEBAR_LIST,
	payload: {
		sideBarList: sideBarList,
	},
});

export const setCurrentMenu = ({ main, sub, type }) => ({
	type: SET_CURRENT_MENU,
	payload: {
		main,
		sub,
		type,
	},
});

export const setSidebarLayout = (layout) => ({
	type: SET_SIDEBAR_LAYOUT,
	payload: {
		layout: layout,
	},
});
