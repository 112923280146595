import React, { useEffect } from "react";
import { Case, If, OtherWise } from "~/common/components/If";
import Loading from "../Loading";
import { API_STATE } from "~/common/constants/state";

import "src/assets/sass/component/imageUploader.scss";
import SquareInitialImg from "~/assets/img/defaultImg/logo-square.png";
import { LightPlusIcon } from "~/assets/svg/MathIcon";

import { useController } from "react-hook-form";
import useUpload from "~/common/hooks/useUpload";
import useAlert from "~/common/hooks/useAlert";

const SingleImageUploader = ({
	type,
	name,
	control,
	round,
	height = "inhert",
	width = "200px",
}) => {
	const {
		field: { onChange, value },
	} = useController({
		control,
		name,
	});

	const { url, upload, clear, state } = useUpload(type, value ?? null);

	const [showErrorAlert, ErrorAlert] = useAlert();

	useEffect(() => {
		switch (state) {
			case API_STATE.error:
				showErrorAlert();
				break;
			default:
				break;
		}
	}, [state]);

	useEffect(() => {
		onChange(url);
	}, [url]);

	const WithUrl = () => (
		<div className="upload-preview">
			<img
				src={url}
				alt={url}
				onError={(e) => {
					e.target.src = SquareInitialImg;
				}}
			/>
			<span className="upload-preview-actions">
				<button onClick={clear}>
					<i className="simple-icon-trash" />
					{/*<TrashIcon />*/}
				</button>
				<button>
					<a href={url} target="_blank" rel="noreferrer">
						<i className="simple-icon-size-fullscreen" />
						{/*<ExpansionIcon />*/}
					</a>
				</button>
			</span>
		</div>
	);

	const WithOutUrl = ({ error }) => (
		<div className={`uploader-select ${error && "error"}`}>
			<input
				type="file"
				accept="image/*"
				name="file"
				onChange={({ target }) => target.files.length > 0 && upload(target.files)}
			/>
			<span className="uploader-actions">
				<LightPlusIcon />
				Upload
			</span>
		</div>
	);

	const Uploading = () => (
		<div className="upload-preview">
			<Loading size={40} />
		</div>
	);

	return (
		<>
			<div
				className={`single-uploader ${round && "round"}`}
				style={{ width: width, height: height }}
			>
				<If condition={state}>
					<Case condition={API_STATE.loading}>
						<Uploading />
					</Case>
					<Case condition={API_STATE.error}>
						<WithOutUrl error />
					</Case>
					<OtherWise>{url ? <WithUrl /> : <WithOutUrl />}</OtherWise>
				</If>
			</div>
			<ErrorAlert
				message="이미지 업로드 중 에러가 발생했습니다."
				type="error"
				confirm="확인"
			/>
		</>
	);
};

export default SingleImageUploader;
