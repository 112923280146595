import React, {useState} from "react";
import PageHeader from "~/common/components/PageHeader";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import {Case, If} from "~/common/components/If";
import Validate from "~/pages/management/PwPage/Validate";
import Change from "~/pages/management/PwPage/Change";


const PwPage = () => {
    const [step, setStep] = useState("validate");
    return(
        <NavbarAndSidebarLayout menu={["management", "pw"]} type="main">
            <PageHeader
                title="비밀번호 변경"
                mainTitle="계정관리"
                subTitle="비밀번호 변경"
            />
            <If condition={step}>
                <Case condition={"validate"}>
                    <Validate {...{ setStep }} />
                </Case>
                <Case condition={"change"}>
                    <Change {...{ setStep }} />
                </Case>
            </If>
        </NavbarAndSidebarLayout>
    )
}

export default PwPage
