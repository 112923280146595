import React from "react";

import { useForm } from "react-hook-form";

import PageHeader from "~/common/components/PageHeader";

import Form from "~/common/components/Form";
import Select, { optionsGenerator } from "~/common/components/Select";
import NumberInput from "~/common/components/NumberInput";
import InvestHistory from "./InvestHistory";
import { ProgressType, RoundType } from "~/common/constants/type";
import useRequest from "~/common/hooks/useRequest";
import useAlert from "~/common/hooks/useAlert";
import { useTranslation } from "react-i18next";

const Edit = ({ menu, type, showViewPage, data }) => {
	const { t } = useTranslation();
	const companyInvestForm = useForm({
		defaultValues: data,
	});

	const investInfoMutation = useRequest("post", "company/invest/info/edit");

	const handleRequest = async (data) => {
		const {
			investInfo: { round, status, value, target },
			investHistory,
		} = data;
		const requestData = {
			round,
			status,
			value,
			target,
			investHistory,
		};
		await investInfoMutation.asyncCall(requestData);
		showViewPage();
	};

	const [showCancelAlert, CancelAlert] = useAlert();

	const buttonList = [
		<button
			type="button"
			className="btn__solid"
			color="gray"
			onClick={() => {
				showCancelAlert().then(() => showViewPage());
			}}
		>
			{t("button.cancel")}
		</button>,
		<button
			type="button"
			className="btn__solid"
			color="primary"
			onClick={companyInvestForm.handleSubmit(handleRequest)}
		>
			{t("button.save")}
		</button>,
	];

	return (
		<>
			<PageHeader {...{ menu, type, buttonList }} />
			<div className="c__card invest-edit">
				<div className="data-form">
					<section className="input-group-section">
						<h3 className="section-title unit-wrap">
							{t("Company.Invest.Edit.investAttract")}
							<span className="unit">{t("Company.unit")}</span>
						</h3>
						<Form useForm={companyInvestForm} className="company-invest-form">
							<Select
								label={t("Company.Invest.Edit.investRound")}
								name="investInfo.round"
								options={optionsGenerator(RoundType)}
							/>
							<Select
								label={t("Company.Invest.investStatus")}
								name="investInfo.status"
								options={optionsGenerator(ProgressType)}
								string
								required
							/>
							<NumberInput
								label={t("Company.Invest.investValue")}
								name="investInfo.value"
								string
								required
								validation={{
									maxLength: {
										value: 27,
										message: t("Company.Invest.Edit.maxLength"),
									},
								}}
								max={999999999}
							/>
							<NumberInput
								label={t("Company.Invest.investTarget")}
								name="investInfo.target"
								string
								required
								validation={{
									maxLength: {
										value: 27,
										message: t("Company.Invest.Edit.maxLength"),
									},
								}}
								max={999999999}
							/>
						</Form>
					</section>
					<section className="input-group-section invest-history-section">
						<InvestHistory useForm={companyInvestForm} />
					</section>
				</div>
			</div>
			<CancelAlert
				message={t("Company.Invest.Edit.CancelAlert")}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
		</>
	);
};

export default Edit;
