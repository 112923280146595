import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	CircleArrowRight,
	CircleArrowLeft,
	DisableArrowLeft,
	DisableArrowRight,
} from "~/assets/svg/WhyUsIcon";
import Slider from "react-slick";

export default function SlideHorizontalFade(props) {
	const sliderRef = useRef(null);
	const { t } = useTranslation();

	const [currentSlide, setCurrentSlide] = useState(0);
	var expandSlides = 1;

	const next = () => {
		if (currentSlide < expandSlides) {
			sliderRef.current.slickNext();
			setCurrentSlide(currentSlide + 1);
		}
	};

	const previous = () => {
		if (currentSlide > 0) {
			sliderRef.current.slickPrev();
			setCurrentSlide(currentSlide - 1);
		}
	};
	const settings = {
		infinite: false,
		slidesToShow: 1.5,
		slidesToScroll: 0.5,
		accessibility: true,
		arrows: false,
  // 		responsive: [
		// 	{
		// 		breakpoint: 768,
		// 		settings: {
		// 			slidesToShow: 1,
		// 			slidesToScroll: 1,
		// 		},
		// 	},
		// ],
	};

	return (
		<div className="relative text-left">
			<div className="mt-4 mb-8 absolute top-0 left-[50px] z-[10]">
				<button onClick={previous} className="mr-4">
					{currentSlide > 0 ? <CircleArrowLeft /> : <DisableArrowLeft />}
				</button>
				<button onClick={next}>
					{currentSlide < expandSlides ? <CircleArrowRight /> : <DisableArrowRight />}
				</button>
			</div>
			<div className="slider-container">
				<Slider ref={sliderRef} {...settings}>
					{props.props.map((item, index) => (
						<div
							key={index}
							className="mt-16"
						>
       <div>
							 <img className="w-full animate-appear" alt="" src={`${ currentSlide === 1 ? item.imgFade : item.img}`}/>
       </div>
       <div className="w-[80%] md:ml-8 ml-4 xl:mt-[-100px] ">
        <div className="text-xl font-semibold">{item.title}</div>
        <div className="my-4">{item.description}</div>
							<div className="cursor-pointer underline underline-8 font-semibold">
								{t("WhyUs.solutions.learn-more")}
							</div>
       </div>
						</div>
					))}
				</Slider>
			</div>
		</div>
	);
}
