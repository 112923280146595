import React from "react";
import ReactFusioncharts from "react-fusioncharts-fix";
import charts from "fusioncharts/fusioncharts.charts";
import FusionCharts from "fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useTranslation } from "react-i18next";

charts(FusionCharts);
FusionTheme(FusionCharts);
FusionCharts.options.creditLabel = false;

const InvestmentExecution = () => {
	const { t } = useTranslation();
	const CHART_CONFIG = (params, chartParams, annotationParams) => ({
		width: "100%",
		// height: "350",
		dataFormat: "JSON",
		dataSource: {
			chart: {
				baseFont: "Noto Sans KR",
				theme: "fusion",
				...chartParams,
			},
			annotations: {
				...annotationParams,
			},
		},
		...params,
	});
	const ASSET_LINE_CHART_CONFIG = CHART_CONFIG(
		{ type: "line" },
		{},
		{
			origw: "400",
			origh: "300",
			autoscale: "1",
			groups: [
				{
					items: [
						{
							id: "zone",
							type: "text",
							text: t("VcInfo.VcDetail.investmentExecution.NoData"),
							fillcolor: "#0000000",
							fontSize: "18",
							bold: "0.5",
							x: "$canvasCenterX",
							y: "$canvasCenterY",
							color: "#6baa01",
							alpha: "20",
						},
					],
				},
			],
		}
	);
	const tempLineData = [
		{
			label: "Jan",
		},
		{
			label: "Feb",
		},
		{
			label: "Mar",
		},
		{
			label: "Apr",
		},
		{
			label: "May",
		},
		{
			label: "Jun",
		},
		{
			label: "Jul",
		},
		{
			label: "Aug",
		},
		{
			label: "Sep",
		},
		{
			label: "Oct",
		},
		{
			label: "Nov",
		},
		{
			label: "Dec",
		},
	];
	return (
		<li>
			<h4 className="detail-info-title">
				{t("VcInfo.VcDetail.investmentExecution.detail-info-title")}
			</h4>
			<div className="row">
				<div className="invest-execution-chart">
					<ReactFusioncharts
						{...{
							...ASSET_LINE_CHART_CONFIG,
							dataSource: {
								chart: ASSET_LINE_CHART_CONFIG.dataSource.chart,
								annotations: ASSET_LINE_CHART_CONFIG.dataSource.annotations,
								data: tempLineData,
							},
						}}
					/>
				</div>
				<ul className="invest-num">
					<li>
						{t("VcInfo.VcDetail.investmentExecution.invest-number")}
						<b>-</b>
					</li>
					<li>
						{t("VcInfo.VcDetail.investmentExecution.invest-execution")}
						<b>-</b>
					</li>
				</ul>
			</div>
		</li>
	);
};

export default InvestmentExecution;
