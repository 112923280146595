import React, { useEffect, useState } from "react";

import "~/assets/sass/pages/ir/common/index.scss";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import { Outlet, useNavigate, useParams } from "react-router";
import { COMPANY_INFO } from "~/store/company/repository";
import useRepository from "~/common/hooks/useRepository";
import { useGlobalStore } from "~/store";
import { PAGE_STATE } from "~/common/constants/state";
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import PageHeader from "~/common/components/PageHeader";
import translation from "~/translations";
import { useTranslation } from "react-i18next";

const IrDetail = () => {
	const { t } = useTranslation();
	const { idx } = useParams();
	const navigate = useNavigate();

	const [getCompanyInfo] = useRepository(COMPANY_INFO);

	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	const [
		{
			ir: { selectedIdx },
		},
	] = useGlobalStore();

	useEffect(() => {
		if (selectedIdx?.toString() !== idx) {
			navigate("/ir/list");
		} else {
			getCompanyInfo();
			setPageState(PAGE_STATE.VIEW);
		}
	}, []);

	const TAB_LIST = [
		{ id: "basicInfo", title: translation.t("IR.IrDetail.Index.basicInfo"), path: "../basic" },
		{ id: "history", title: translation.t("IR.IrDetail.Index.history"), path: "../history" },
		{ id: "member", title: translation.t("IR.IrDetail.Index.member"), path: "../member" },
		{
			id: "shareholder",
			title: translation.t("IR.IrDetail.Index.shareholder"),
			path: "../shareholder",
		},
		{ id: "finance", title: translation.t("IR.IrDetail.Index.finance"), path: "../finance" },
		{ id: "product", title: translation.t("IR.IrDetail.Index.product"), path: "../product" },
		{ id: "outcome", title: translation.t("IR.IrDetail.Index.outcome"), path: "../outcome" },
	];

	return (
		<NavbarAndSidebarLayout menu={["ir", "list"]} type="main" menuName={"ir-detail"}>
			<If condition={pageState}>
				<Case condition={PAGE_STATE.LOADING}>
					<PageHeader />
					<Loading />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<Outlet context={{ tabList: TAB_LIST, irIdx: selectedIdx }} />
				</Case>
			</If>
		</NavbarAndSidebarLayout>
	);
};

export default IrDetail;
