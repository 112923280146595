import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import AuthenticationLayout from "~/layouts/AuthenticationLayout";
import { Case, If } from "~/common/components/If";
import MailCheck from "~/pages/auth/FindPwPage/Find/MailCheck";
import MailSend from "~/pages/auth/FindPwPage/Find/MailSend";

// Assets
import "~/assets/sass/pages/auth/findPw.scss";
import logo from "~/assets/img/logo.svg";

const FindPwPage = () => {
	const { t } = useTranslation();
	const currentPath = "findPw";
	const [currentStep, setCurrentStep] = useState("mailCheck");

	return (
		<AuthenticationLayout contentSide={false} PATH={currentPath}>
			<section className={`section-` + currentStep}>
				<div className="card-header">
					<div className="logo">
						<img src={logo} alt={t("Auth.Login.image-alt")} />
					</div>
				</div>
				<If condition={currentStep}>
					<Case condition={"mailCheck"}>
						<MailCheck {...{ setCurrentStep }} />
					</Case>
					<Case condition={"mailSend"}>
						<MailSend />
					</Case>
				</If>
			</section>
		</AuthenticationLayout>
	);
};

export default FindPwPage;
