import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import "~/assets/sass/pages/ir/outcome.scss";
import { Case, If } from "~/common/components/If";
import PageHeader from "~/common/components/PageHeader";
import TabNav from "~/common/components/TabNav";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import { useGlobalStore } from "~/store";
import { setOutcome } from "~/store/ir/actions";
import View from "./View";
import Edit from "./Edit";
import Empty from "./Empty";
import Loading from "~/common/components/Loading";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const Outcome = ({}) => {
	const { t } = useTranslation();
	const { tabList, irIdx } = useOutletContext();

	const outcomeRequest = useRequest("get", `ir/outcome/detail`);

	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	const [, dispatch] = useGlobalStore();

	useEffect(() => {
		outcomeRequest.singleCall(irIdx, "path");
	}, []);

	useEffect(() => {
		switch (outcomeRequest.state) {
			case API_STATE.done:
				const response = outcomeRequest.response.data;
				dispatch(setOutcome(response));
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				if (outcomeRequest.error.statusCode === 404) {
					setPageState(PAGE_STATE.EMPTY);
				}
			default:
				break;
		}
	}, [outcomeRequest.state]);

	const showViewPage = () => outcomeRequest.asyncCall(irIdx, "path");

	const showEditPage = () => {
		setPageState(PAGE_STATE.EDIT);
	};

	const Header = ({ buttonList = [] }) => {
		return (
			<>
				<PageHeader
					title={t("IR.IrDetail.Index.IRDetail")}
					mainTitle="IR"
					subTitle={t("IR.IrDetail.Index.outcome")}
					buttonList={buttonList}
				/>
				<TabNav tabList={tabList} currentTab={"outcome"} />
			</>
		);
	};

	const buttonList = [
		<AuthButton type="button" className="btn__solid" color="primary">
			{t("button.Edit")}
		</AuthButton>,
	];

	return (
		<>
			<If condition={pageState}>
				<Case condition={PAGE_STATE.VIEW}>
					<View {...{ Header, showEditPage }} />
				</Case>
				<Case condition={PAGE_STATE.EDIT}>
					<Edit {...{ Header, showViewPage }} />
				</Case>
				<Case condition={PAGE_STATE.EMPTY}>
					<Empty {...{ Header, showEditPage }} />
				</Case>
				<Case condition={PAGE_STATE.LOADING}>
					<Header buttonList={buttonList} />
					<Loading />
				</Case>
			</If>
		</>
	);
};

export default Outcome;
