import React, { useState } from "react";
import CustomCheckbox from "~/common/components/CustomCheckbox";
import { ROUND_STATE } from "~/common/constants/state";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const Search = ({ setSearchTitle, setSearchStatus, setSearchApply, setPage }) => {
	const { t } = useTranslation();
	const [inputValue, setInputValue] = useState("");
	const [applyValue, setApplyValue] = useState(t("Invest.Round.RoundList.entire"));
	const [statusValue, setStatusValue] = useState([]);
	const [allChecked, setAllChecked] = useState(true);
	const [checkboxRequired, setCheckboxRequired] = useState(false);
	const searchOption = [
		{ label: t("Invest.Round.RoundList.entire"), value: "ALL" },
		{ label: t("Invest.situation.standby"), value: "WAITING" },
		{ label: t("Invest.situation.ongoing"), value: "PROCEEDING" },
		{ label: t("Invest.situation.deadline"), value: "END" },
	];

	const applyOption = [
		translation.t("Invest.Round.RoundList.applyOption.option1"),
		translation.t("Invest.Round.RoundList.applyOption.option2"),
		translation.t("Invest.Round.RoundList.applyOption.option3"),
	];

	const handleSearch = () => {
		if (statusValue.length !== 0) {
			setSearchTitle(inputValue);

			const newValue = [];
			statusValue
				.filter((s) => s !== "ALL")
				.forEach((d) => {
					if (d === "END") {
						newValue.push(
							ROUND_STATE.CLOSING,
							ROUND_STATE.REVIEW,
							ROUND_STATE.COMPLETE
						);
					} else {
						newValue.push(ROUND_STATE[d]);
					}
				});

			setSearchStatus(newValue);
			setSearchApply(applyValue);
			setPage(1);
		} else {
			setCheckboxRequired(true);
		}
	};

	return (
		<div className="c__card search-card">
			<div className="c__form horiz">
				<div className="f__group status-chk-wrap">
					<label className="f__label">
						{t("Invest.Round.RoundList.status-chk-wrap.f__label1")}
					</label>
					<CustomCheckbox
						options={searchOption}
						required={checkboxRequired}
						isAllChecked={allChecked}
						setISAllChecked={setAllChecked}
						value={statusValue}
						setValue={setStatusValue}
					/>
				</div>
				<div className="f__group status-chk-wrap">
					<label className="f__label">
						{t("Invest.Round.RoundList.status-chk-wrap.f__label2")}
					</label>
					<div className="c__radio-wrap">
						{applyOption.map((option, option_idx) => (
							<label className="c__radio" key={option_idx}>
								<input
									type="radio"
									onChange={() => setApplyValue(option)}
									checked={applyValue === option}
								/>
								<span className="checkmark" />
								<span className="radio-label">{option}</span>
							</label>
						))}
					</div>
				</div>
				<div className="f__group search-list-wrap">
					<label className="f__label">
						{t("Invest.Round.RoundList.status-chk-wrap.f__label3")}
					</label>
					<div className="f__control-wrap">
						<div className="f__control">
							<input
								className="c__input f__item"
								placeholder={t(
									"Invest.Round.RoundList.status-chk-wrap.input-placeholder"
								)}
								value={inputValue}
								onChange={(e) => setInputValue(e.target.value)}
								onKeyDown={(e) => e.key === "Enter" && handleSearch()}
							/>
						</div>
						<button
							type="button"
							className="btn__solid btn-search"
							color="primary"
							onClick={() => handleSearch()}
						>
							{t("button.search")}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Search;
