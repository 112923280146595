import { setCompanyVcKeyword } from "./actions";

/**
 * 조직 별 인원 조회
 */

export const VC_LIST = {
	method: "post",
	url: "company/vc/list",
	type: "body",
	template: (param) => ({
		name: param?.name ? param.name : "",
		type: param?.type ? param.type : "",
		field: param?.field ? param.field : [],
		page: param?.page ? param.page : 1,
		size: 21,
	}),
	onSuccess: ({ response }) => {
		const responseData = response.data.foundCompanies;
		const pageable = response.data.pageable;

		return { vcList: responseData, pageData: pageable };
	},
};

export const COMPANY_VC_KEYWORD = {
	method: "get",
	url: "company/vc/keywords",
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data;
		let newFields = [];
		responseData.fields.forEach((d) => {
			newFields.push({ idx: d.idx, field: d.name });
		});

		let typesValue = [{ label: "All", value: "ALL" }];
		response.data.types.forEach((d) => {
			typesValue.push({ label: d.invest_association, value: d.invest_association });
		});

		dispatch(setCompanyVcKeyword({ fields: newFields, types: typesValue }));
		return responseData;
	},
};
