import React from "react";

// Assets
import "~/assets/sass/pages/shareholder/empty.scss";
import initImg from "~/assets/img/init-org.png";
import { useTranslation } from "react-i18next";

const Empty = () => {
	const { t } = useTranslation();
	return (
		<div className="page-init">
			<p className="main">{t("Invest.Evaluate.EvaluateList.Empty.main")}</p>
			<p className="sub">{t("Invest.Evaluate.EvaluateList.Empty.sub")}</p>
			<img src={initImg} alt={t("Invest.Evaluate.EvaluateList.Empty.img")} />
		</div>
	);
};
export default Empty;
