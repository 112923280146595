import React, { useState } from "react";

import defaultAvatar from "~/assets/img/defaultImg/profile-large.png";
import { Case, If } from "~/common/components/If";
import { dateToString } from "~/utils/formatter";
import TooltipTemplate from "~/common/components/TooltipPortal/TooltipTemplate";
import { CautionTooltipIcon } from "~/assets/svg/TeamInfoIcon";
import { useTranslation } from "react-i18next";
import { useGlobalStore } from "~/store";
import DropdownTemplate, { DropDownItem } from "~/common/components/DropdownPortal/DropdownTemplate";
import translation from "~/translations";

const UserRow = ({
	user,
	handleClick,
	img,
	idx,
	name,
	department,
	position,
	joinDate,
	usersRole,
	email,
	status,
	employmentStatus,
	userRequirements,
	handleActive,
	handleWithdraw,
	setCurrentUser,
	setAlertState,
	setResendMember,
	setResendMailModal,
	handleClickTeamOrOutside
}) => {

	const [
		{
			user: {
				info: { role },
			}
		},
	] = useGlobalStore();
	const { t } = useTranslation();
	const ALERT_MODAL_STATE = {
		TEAM_EMENBER_CHANGE: '팀원전환',//`${value}으로 전환`,//translation.t("MyPage.admin.Team.index.state.change"),
		EXTERNAL_CHANGE: '외부전환',//`${value}으로 전환`,//translation.t("MyPage.admin.Team.index.state.change"),
		RESEND: translation.t("MyPage.admin.Team.index.state.resend"),
		ACTIVATE: translation.t("MyPage.admin.Team.index.state.activate"),
		DEACTIVATE: translation.t("MyPage.admin.Team.index.state.deactivate"),
		IS_WITHDRAW: translation.t("MyPage.admin.Team.index.state.is-withdraw"),
		WITHDRAW_DONE: translation.t("MyPage.admin.Team.index.state.withdraw-done"),
		RESIGN_DONE: translation.t("MyPage.admin.Team.index.state.resign-done"),
	};

	const object = {
        admin: "슈퍼관리자",
        manager: "관리자",
        user: "팀원",
		external:'외부'
    }

	return (
		<>
			<div
				className={`profile-row ${employmentStatus === 3 ? "resign-row" : null}`}
				onClick={handleClick}
			>
				<div className="profile-img">
					<div className="img-wrap">
						{userRequirements === false && (
							<button type="button" className="c__tooltip-btn">
								<CautionTooltipIcon />
								<TooltipTemplate className={`error`}>
									{t("HR.TeamInfo.UserList.TooltipError")}
								</TooltipTemplate>
							</button>
						)}
						<img
							src={img ? img : defaultAvatar}
							alt={t("Img.profile")}
							onError={(e) => {
								e.target.src = defaultAvatar;
							}}
						/>
					</div>
				</div>
				<div className="profile-name">
					<p>
						<span className="name">{name}</span>
						{/* <If condition={status}>
							<Case condition="대기">
								<span className="standby"> ({t("HR.TeamInfo.standby")})</span>
							</Case>
							<Case condition="탈퇴">
								<span className="withdraw"> ({t("HR.TeamInfo.withdraw")})</span>
							</Case>
						</If> */}
					</p>
					{/* TODO: 생일 아이콘 */}
					{/* <div className="birth-img-wrap">*/}
					{/*	<img src={BirthIcon} alt="생일아이콘" />*/}
					{/*</div>*/}
				</div>
				<div className="profile-team">{department ? department : "-"}</div>
				<div className="profile-position">{position ? position : "-"}</div>
				<div className="profile-position">{usersRole ? object[usersRole] : "-"}</div>
				<div className="profile-joinDate">
					{joinDate ? dateToString(joinDate, "yyyy.MM.dd ") : "-"}
				</div>
				<div className="profile-email">{email}</div>
				<div className="profile-status">
				
				</div>
				<div className={`team-active`}
				>
						<If condition={employmentStatus}>
						<Case condition={0}>
						<span className={`status-box team-active ${status === "정상" ? "on":'waiting'}`}>
						{status === "정상"?'근무중':status}
						</span>
						</Case>
						<Case condition={2}>
						{/* 휴직 */}
							<span className="status-box leave">{t("HR.TeamInfo.leave")}</span>
						</Case>
						<Case condition={3}>
							<span className="status-box resign">{t("HR.TeamInfo.resign")}</span>
						</Case>

						
					</If>
					
					{role === "admin" && (
						<div
							onClick={(e) => e.stopPropagation()} // 이벤트 전파 중지
						>
							<DropdownTemplate target={`dropdown_${user.idx}`} vertical className="right"
							>
								<DropDownItem
									onClick={() => {
										handleClickTeamOrOutside(user.idx)
									}}
								>
									{user.role ==='external' ? ALERT_MODAL_STATE.TEAM_EMENBER_CHANGE:ALERT_MODAL_STATE.EXTERNAL_CHANGE}
								</DropDownItem>
								<DropDownItem
									onClick={() => {
										setResendMailModal(true);
										setResendMember(user);
									}}
								>
									{ALERT_MODAL_STATE.RESEND}
								</DropDownItem>
								{status === "정상" && (
									<DropDownItem
										onClick={() => {
											setAlertState(ALERT_MODAL_STATE.DEACTIVATE);
											handleActive(user.idx);
										}}
									>
										{ALERT_MODAL_STATE.DEACTIVATE}
									</DropDownItem>
								)}
								{status === "대기" && (
									<>
										<DropDownItem
											onClick={() => {
												setAlertState(ALERT_MODAL_STATE.ACTIVATE);
												handleActive(user.idx);
											}}
										>
											{ALERT_MODAL_STATE.ACTIVATE}
										</DropDownItem>
									</>
								)}
								{employmentStatus===3 &&<DropDownItem
									onClick={() => {
										handleWithdraw(user);
									}}
								>
									{ALERT_MODAL_STATE.IS_WITHDRAW}
								</DropDownItem>}
							</DropdownTemplate>
						</div>
					)}
				</div>
			</div>

		</>
	);
};

export default UserRow;
