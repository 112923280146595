import React from "react";
import { useNavigate } from "react-router";
import "~/assets/sass/component/tabMenu.scss";

const TabNav = ({ tabList, currentTab, tabClick=null }) => {
	const navigate = useNavigate();

	return (
		<ul className="c__tabMenu">
			{tabList.map((t, index) => {
				return (
					<li
						key={index}
						className={`c__tabMenuItem ${currentTab === t.id && "active"} ${
							t.disable ? "disabled" : ""
						}`}
						onClick={() => {
							tabClick ? tabClick(t) :
							navigate(t.path);
						}}
					>
						{t.title}
					</li>
				);
			})}
		</ul>
	);
};

export default TabNav;
