export const SET_USER = "user/SET_USER";
export const CLEAR_USER = "user/CLEAR_USER";

export const setUser = (user) => {
	const check = {
		bizCheck: user.bizCheck,
		comCheck: user.comCheck,
		irCheck: user.irCheck,
		userRequirements: user.userRequirements,
	};
	const company = {
		idx: user.companyIdx,
		name: user.companyName,
		type: user.companyType,
		certType: user.certType,
		regNum: user.regNum,
		logo: user.logo,
		license: user.license,
	};
	const info = {
		department: user.department,
		id: user.id,
		idx: user.idx,
		img: user.img,
		lastLogin: user.lastLogin,
		license: user.license,
		name: user.name,
		phone: user.phone,
		position: user.position,
		regDate: user.regDate,
		role: user.role,
		status: user.status,
		updateDate: user.updateDate,
		workingType: user.workingType,
		joinDate: user.joinDate,
		isHrTeamMember: user.hrTeamMember,
		hrRemainVacation: user.hrRemainVacation,
	};
	return {
		type: SET_USER,
		payload: {
			check: check,
			company: company,
			info: info,
		},
	};
};

export const clearUser = () => ({
	type: CLEAR_USER,
});
