import React, {useEffect, useState} from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

export const optionsGenerator = (type, property) => {
	const option = Object.keys(type).map((key) => ({
		label: property ? type[key][property] : type[key],
		value: key === "true" ? true : key === "false" ? false : key,
	}));
	return option;
};

export const DownArrowIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 8 5"
			width="8"
			height="5"
			className="ico_select-arrow"
		>
			<path
				d="M7.7,0.4C7.3,0,6.8,0,6.5,0.4L4,2.8L1.5,0.4C1.2,0,0.7,0,0.3,0.4S0,1.2,0.3,1.6l3.1,3.1C3.7,5,4.3,5,4.6,4.6
	l3.1-3.1C8,1.2,8,0.7,7.7,0.4z"
			/>
		</svg>
	);
};

const Select = (props) => {
	const {
		control,
		name,
		validation,
		options,
		defaultValue,
		placeholder,
		required,
		disabled,
		selectName,
	} = props;
	const { t } = useTranslation();
	const [selectToggle, setSelectToggle] = useState(false);

	const { field } = useController({
		control,
		name,
		rules: { required: required && t("Validate.require"), ...validation },
		defaultValue: defaultValue,
	});

	return (
		<div
			className={`c__select f__item ${selectName ? selectName : ""} ${
				selectToggle ? "open" : ""
			} ${disabled ? "disabled" : ""}`}
			tabIndex={0}
			onKeyDown={(e) => e.key === "Tab" && setSelectToggle(false)}
			onFocus={() => {
				!(disabled === true || options.length === 0) && setSelectToggle(true);
			}}
			onBlur={() => {
				setSelectToggle(false);
			}}
		>
			<p
				className={`c__selection-item`}
				onMouseDown={() => {
					!(disabled === true || options.length === 0) && setSelectToggle(!selectToggle);
				}}
			>
				{field.value === undefined || field.value === null || field.value === "" ? (
					<span className="placeholder">{placeholder}</span>
				) : (
					<span>{(options.find((v) => v.value === field.value) || {}).label}</span>
				)}
				<DownArrowIcon />
			</p>
			<ul className="c__select-dropdown">
				{options.map((option, index) => (
					<li
						key={index}
						className={`c__select-dropdown-item ${
							field.value === option.value && "selected"
						}`}
						onClick={() => {
							field.onChange(option.value);
							setSelectToggle(false);
						}}
					>
						{option.label}
					</li>
				))}
			</ul>
		</div>
	);
};

export default Select;

Select.defaultProps = {
	placeholder: translation.t("Select.no-select"),
};
