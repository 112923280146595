import React, { useEffect } from "react";

import PageHeader from "~/common/components/PageHeader";

import "~/assets/sass/pages/company/product.scss";
import useRequest from "~/common/hooks/useRequest";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import {
	FacebookIcon,
	InstagramIcon,
	LinkedInIcon,
	TwitterIcon,
	YoutubeIcon,
} from "~/assets/svg/SnsIcon";
import ImageSlider from "~/common/components/ImageSlider";
import { useNavigate, useParams } from "react-router";
import { API_STATE } from "~/common/constants/state";
import { extLinkFormatter } from "~/utils/formatter";
import { setCurrentProduct } from "~/store/company/actions";
import { useGlobalStore } from "~/store";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

/**
 * Product list view component
 *
 * @param { Props } props
 *
 * @typedef Props
 * @property {string[]} menu
 * @property {"main"|"myPage"} type
 */
const CompanyProductDetailPage = () => {
	const { t } = useTranslation();
	const { idx } = useParams();
	const navigate = useNavigate();
	const [
		{
			company: { currentProduct },
		},
		dispatch,
	] = useGlobalStore();

	const productRequest = useRequest("get", `company/product/info`);

	useEffect(() => {
		productRequest.singleCall(idx, "path");
	}, []);

	useEffect(() => {
		switch (productRequest.state) {
			case API_STATE.done:
				const product = productRequest.response.data;
				dispatch(setCurrentProduct({ product }));
				break;
			case API_STATE.error:
				alert(t("Company.Product.View.alert"));
				navigate("/company/product");
			default:
				break;
		}
	}, [productRequest.state]);

	const buttonList = [
		<AuthButton
			onClick={() => {
				navigate(`/company/product/edit/${idx}`);
			}}
			type="button"
			className="btn__solid btn-edit"
			color="primary"
		>
			{t("button.Modify")}
		</AuthButton>,
	];

	const tags = () => {
		return currentProduct?.CompanyProductKeyword?.map((keyword, idx) => {
			return (
				<span key={idx} className="product-tag">
					{keyword.content}
				</span>
			);
		});
	};
	return (
		<NavbarAndSidebarLayout menu={["company", "product"]} type="main">
			<PageHeader {...{ buttonList }} />
			<div className="company-product-view">
				<div className="c__card company-product">
					<div className="product-detail-content">
						<div className="carousel-wrap">
							<ImageSlider list={currentProduct?.CompanyProductImage} />
						</div>
						<div className="product-basic-info">
							<div className="product-info-top">
								<h3 className="product-name">
									<span className="product-name-title">
										{currentProduct?.name}
									</span>
									{currentProduct?.representation && (
										<p className="product-site">
											{t("Company.Product.mainProduct")}
										</p>
									)}
								</h3>
								{currentProduct?.viewMode ? (
									<div className="product-view-mode open">
										{t("Company.Product.View.open")}
									</div>
								) : (
									<div className="product-view-mode private">
										{t("Company.Product.View.private")}
									</div>
								)}
							</div>
							<p
								className="product-desc"
								style={{ whiteSpace: "pre-wrap", lineBreak: "anywhere" }}
							>
								{currentProduct?.description}
							</p>
							<div className="product-tag-group">{tags()}</div>
							<div className="product-info-bottom">
								<ul className="product-sns-group">
									<li>
										<a
											target="_blank"
											rel="noreferrer"
											href={extLinkFormatter(currentProduct?.youtube)}
											className={!currentProduct?.youtube ? "disabled" : ""}
										>
											<YoutubeIcon />
										</a>
									</li>
									<li>
										<a
											href={extLinkFormatter(currentProduct?.instagram)}
											target="_blank"
											rel="noreferrer"
											className={!currentProduct?.instagram ? "disabled" : ""}
										>
											<InstagramIcon />
										</a>
									</li>
									<li>
										<a
											target="_blank"
											rel="noreferrer"
											href={extLinkFormatter(currentProduct?.facebook)}
											className={!currentProduct?.facebook ? "disabled" : ""}
										>
											<FacebookIcon />
										</a>
									</li>
									<li>
										<a
											target="_blank"
											rel="noreferrer"
											href={extLinkFormatter(currentProduct?.twitter)}
											className={!currentProduct?.twitter ? "disabled" : ""}
										>
											<TwitterIcon />
										</a>
									</li>
									<li>
										<a
											target="_blank"
											rel="noreferrer"
											href={extLinkFormatter(currentProduct?.linkedin)}
											className={!currentProduct?.linkedin ? "disabled" : ""}
										>
											<LinkedInIcon />
										</a>
									</li>
								</ul>
								<div className="product-link">
									<a href={`mailto:${currentProduct?.email}`} target="_blank">
										<span>{currentProduct?.email}</span>
									</a>
									<a
										href={extLinkFormatter(currentProduct?.webSite)}
										target="_blank"
									>
										<span>{currentProduct?.webSite}</span>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="product-detail-info c__form vertical">
						<div className="f__group">
							<div className="f__label">{t("Company.Product.differentiation")}</div>
							<p
								className="f__control"
								style={{ whiteSpace: "pre-wrap", lineBreak: "anywhere" }}
							>
								{currentProduct?.differentiation}
							</p>
						</div>
						<div className="f__group">
							<div className="f__label">{t("Company.Product.View.step")}</div>
							<div className="f__control">{currentProduct?.step}</div>
						</div>
					</div>
				</div>
			</div>
		</NavbarAndSidebarLayout>
	);
};

export default CompanyProductDetailPage;
