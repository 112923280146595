import React from "react";

// Sections
import NewLandingLayout from "~/layouts/LandingLayout";
import TopSection from "~/pages/LandingPage/Sections/TopSection";
import TitleSection from "~/pages/LandingPage/Sections/TitleSection";
import AccordionSection from "~/pages/LandingPage/Sections/AccordionSection";
import CommunitySection from "~/pages/LandingPage/Sections/CommunitySection";
import RoadmapSection from "~/pages/LandingPage/Sections/RoadmapSection";

// Assets
import "~/assets/sass/pages/landing/serviceInfo/index.scss";
import "~/assets/sass/pages/landing/serviceInfo/landingKeyframes.scss";
import StartupService from "~/pages/LandingPage/Sections/StartupService";

const LandingPage = () => {
	return (
		<NewLandingLayout>
			<div className="curtain-effect" />
			<TopSection />
			<TitleSection />
			<StartupService/>
			<AccordionSection />
			{/*<CommunitySection />*/}
			{/*<RoadmapSection />*/}
		</NewLandingLayout>
	);
};

export default LandingPage;
