import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Funds = () => {
	const { t } = useTranslation();
	const [fundsList] = useState([]);

	const FoundRow = () => {
		return (
			<ul className="table-body">
				<li className="fund-num">1</li>
				<li className="fund-name">
					오픈이노베이션 제 1호 펀드
					<span>중소/벤처-지방</span>
				</li>
				<li className="fund-size">40.9억</li>
				<li className="fund-lp">-</li>
				<li className="fund-start-date">
					2019-11-14
					<span>0.8년차</span>
				</li>
				<li className="fund-end-date">
					2027-11-14
					<span>남은기한: 7.2년</span>
				</li>
				<li className="fund-manager">-</li>
			</ul>
		);
	};

	return (
		<li>
			<h4 className="detail-info-title">
				{t("VcInfo.VcDetail.funds.detail-info-title")}({fundsList.length ?? 0})
			</h4>
			<div className="fund-table">
				{fundsList.length === 0 ? (
					<p className="no-content">{t("VcInfo.VcDetail.funds.no-content")}</p>
				) : (
					<>
						<ul className="table-head">
							<li className="fund-num">{t("VcInfo.VcDetail.funds.fund-num")}</li>
							<li className="fund-name">{t("VcInfo.VcDetail.funds.fund-name")}</li>
							<li className="fund-size">{t("VcInfo.VcDetail.funds.fund-size")}</li>
							<li className="fund-lp">{t("VcInfo.VcDetail.funds.fund-lp")}(LP)</li>
							<li className="fund-start-date">
								{t("VcInfo.VcDetail.funds.fund-start-date")}
							</li>
							<li className="fund-end-date">
								{t("VcInfo.VcDetail.funds.fund-end-date")}
							</li>
							<li className="fund-manager">
								{t("VcInfo.VcDetail.funds.fund-manager")}
							</li>
						</ul>
						{FoundRow()}
					</>
				)}
			</div>
		</li>
	);
};

export default Funds;
