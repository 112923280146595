import React, { useState } from "react";
import TabMenu from "~/common/components/TabMenu";
import { Case, If, OtherWise } from "~/common/components/If";
import translation from "~/translations";

import ProfileView from "../components/ProfileView";
import CareerView from "../components/CareerView";
import HrView from "../components/HrView";
import PersonalView from "../components/PersonalView";
import ScrollTop from "~/common/components/ScrollTop";

const OtherView = ({ userDetail }) => {
	const [currentTab, setCurrentTab] = useState("basic");

	const tabMenuList = [
		{ id: "basic", title: translation.t("HR.TeamInfo.modal.MemberDetailModal.EditView.basic") },
		{
			id: "career",
			title: translation.t("HR.TeamInfo.modal.MemberDetailModal.EditView.career"),
		},
	];

	return (
		<>
			<div className="modal-content">
				<ProfileView {...{ userDetail }} />
				<div className="tabInfo-area">
					<TabMenu
						tabItem={tabMenuList}
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
					<ScrollTop className="scroll-wrap">
						<If condition={currentTab} userDetail={userDetail}>
							<Case condition={"basic"}>
								<HrView hide />
								<PersonalView hide />
							</Case>
							<OtherWise>
								<CareerView />
							</OtherWise>
						</If>
					</ScrollTop>
				</div>
			</div>
		</>
	);
};

export default OtherView;
