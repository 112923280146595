import React, { useState } from "react";

// Components
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import { Case, If, OtherWise } from "~/common/components/If";
import Detail from "~/pages/mypage/admin/LogoPage/Detail";
import Edit from "~/pages/mypage/admin/LogoPage/Edit";

// Assets
import "~/assets/sass/pages/mypage/admin/logo.scss";

const LogoPage = () => {
	const MENU = ["admin", "logo"];
	const TYPE = "myPage";
	const [page, setPage] = useState("detail");

	return (
		<NavbarAndSidebarLayout menu={MENU} type={TYPE}>
			<PageHeader hasBreadCrumb={true} />
			<If condition={page} {...{ setPage }}>
				<Case condition="detail">
					<Detail />
				</Case>
				<OtherWise>
					<Edit />
				</OtherWise>
			</If>
		</NavbarAndSidebarLayout>
	);
};
export default LogoPage;
