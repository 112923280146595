import React from "react";

//Assets
import "~/assets/sass/pages/hr/modal/common/detail/career.scss";
import { Case, If, OtherWise } from "~/common/components/If";
import { dateToString } from "~/utils/formatter";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";

const CareerItem = ({ start, end, title, content }) => {
	return (
		<div className="history-row">
			<div className="date">
				{dateToString(new Date(start), "yyyy. MM")} ~{" "}
				{dateToString(new Date(end), "yyyy. MM")}
			</div>
			<div className="history-wrap">
				{/* TODO: title */}
				<div className="name">{title}</div>
				<div className="content" dangerouslySetInnerHTML={{ __html: content }} />
			</div>
		</div>
	);
};
const CareerView = ({ userDetail }) => {
	const { t } = useTranslation();
	const { career } = userDetail;
	const careers = () => {
		return career.map((item) => {
			return (
				<CareerItem
					key={item.idx}
					start={item.startDate}
					end={item.endDate}
					title={item.companyName}
					content={item.content}
				/>
			);
		});
	};
	return (
		<section className="teamInfo-career detail scroll-wrap">
			<p className="section-title">
				{t("HR.TeamInfo.modal.MemberDetailModal.components.Career.section-title")}
			</p>
			<div className="career-history">
				<If condition={career.length}>
					<Case condition={0}>
						<NoData
							main={t(
								"HR.TeamInfo.modal.MemberDetailModal.components.Career.NoData2"
							)}
						/>
					</Case>
					<OtherWise>{careers()}</OtherWise>
				</If>
			</div>
		</section>
	);
};

export default CareerView;
