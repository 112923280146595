import React, { useState } from "react";
import TabMenu from "~/common/components/TabMenu";
import { Case, If } from "~/common/components/If";
import Detail from "~/pages/invest/RoundPage/modal/RoundDetailModal/View/component/detail";
import Faq from "~/pages/invest/RoundPage/modal/RoundDetailModal/View/component/faq";
import Inquiry from "~/pages/invest/RoundPage/modal/RoundDetailModal/View/component/inquiry";
import { differenceInDays } from "date-fns";
import Info from "~/pages/ExternalRoundPage/View/component/info";
import { useTranslation } from "react-i18next";

const View = () => {
	const { t } = useTranslation();
	const tabMenuList = [
		{ id: "detail", title: "상세정보" },
		{ id: "faq", title: "FAQ" },
		{ id: "inquiry", title: "문의", disable: true },
	];

	const [currentTab, setCurrentTab] = useState("detail");
	// const [isAgree, setIsAgree] = useState(false);
	// const [, setPage] = useState("info");

	//FIXME 중복 코드
	function getDiffDays(endDate) {
		const count = differenceInDays(new Date(endDate), new Date());

		return `D-${count === 0 ? "day" : count}`;
	}

	//FIXME 중복 코드
	function roundStatus(status, endDate) {
		let newStatus;
		switch (status) {
			case t("Invest.situation.ongoing"):
				newStatus = getDiffDays(endDate);
				break;
			case t("Invest.Round.RoundList.round-deadline"):
			case t("Invest.Round.RoundList.round-audit"):
			case t("Invest.situation.termination"):
				newStatus = t("Invest.situation.deadline");
				break;
			default:
				newStatus = status;
				break;
		}

		return newStatus;
	}

	return (
		<div className="c__card">
			<Info {...{ roundStatus }} />
			<section className="rounding-tabs-wrap">
				<TabMenu
					tabItem={tabMenuList}
					currentTab={currentTab}
					setCurrentTab={setCurrentTab}
				/>
				<If condition={currentTab}>
					<Case condition={"detail"}>
						<Detail />
					</Case>
					<Case condition={"faq"}>
						<Faq />
					</Case>
					<Case condition={"inquiry"}>
						<Inquiry />
					</Case>
				</If>
			</section>
		</div>
	);
};

export default View;
