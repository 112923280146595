import React, { useEffect, useState } from "react";
import Loading from "~/common/components/Loading";
import { API_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import { CautionIcon } from "~/assets/svg/InterfacesIcon";
import { useTranslation } from "react-i18next";
import { SolidCloseIcon } from "~/assets/svg/CloseIcon";

const SignatureSelectModal = ({
	selectedSignature,
	setSelectedSignature,
	setModalToggle,
	setDigitalDataModalModal,
	signatureListRequest,
}) => {
	const { t } = useTranslation();
	const deleteSignRequest = useRequest("delete", "/admin/signature/delete");

	const [tempSelect, setTempSelect] = useState(selectedSignature);

	const [signatureList, setSignatureList] = useState();

	useEffect(() => {
		signatureListRequest.singleCall();
	}, []);

	useEffect(() => {
		switch (signatureListRequest.state) {
			case API_STATE.done:
				setSignatureList(
					signatureListRequest.response.data.list.filter(
						(signature) => signature.activation === true
					)
				);
				break;
			case API_STATE.error:
				console.log("error > ", signatureListRequest.error);
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signatureListRequest.state, signatureListRequest.error, signatureListRequest.response]);

	const signatures = () => {
		if (signatureListRequest.state === API_STATE.error) return <Loading />;
		if (signatureList?.length > 0) {
			return signatureList.map((signature) => (
				<div
					className={`c__card ${
						signature.idx === tempSelect?.idx || signature.img === tempSelect
							? "active"
							: ""
					}`}
					key={signature.idx}
					onClick={() =>
						tempSelect === signature.img || tempSelect.img === signature.img
							? setTempSelect("")
							: setTempSelect(signature)
					}
				>
					<div className="signature">
						<button className="btn-remove" onClick={() => handleDelete(signature.idx)}>
							<SolidCloseIcon />
						</button>
						<div className="img-wrap">
							<img
								src={signature.img}
								alt={t("common.components.SignatureSelectModal.signImg")}
							/>
						</div>
					</div>
				</div>
			));
		} else {
			return (
				<div className="no-data">
					<CautionIcon />
					{t("components.SignatureSelectModal.modal-seal-notice.noData")}
				</div>
			);
		}
	};

	const handleDelete = (idx) => {
		deleteSignRequest.call(idx, "path");
	};

	useEffect(() => {
		switch (deleteSignRequest.state) {
			case API_STATE.done:
				signatureListRequest.call();
				break;
			case API_STATE.error:
				console.log("error", deleteSignRequest.error);
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deleteSignRequest.state, deleteSignRequest.error, deleteSignRequest.response]);

	return (
		<>
			<div className="modal-content">
				<div className="btn-wrap">
					<button
						type="button"
						className="btn__outline angled btn-add"
						color="primary"
						onClick={() => {
							setDigitalDataModalModal(true);
						}}
						disabled={signatureList?.length >= 6}
					>
						<SolidCloseIcon />
						인감 추가
					</button>
				</div>
				<div className="c__cardRow sign-wrap">{signatures()}</div>
				<p className="modal-seal-notice">* 법인 인감은 최대 6개까지 등록할 수 있습니다.</p>
			</div>
			<div className="modal-footer">
				<button
					type="button"
					className="btn__solid"
					color="gray"
					onClick={() => {
						setModalToggle(false);
					}}
				>
					{t("button.cancel")}
				</button>
				<button
					type="button"
					className="btn__solid"
					color="primary"
					onClick={() => {
						setSelectedSignature(tempSelect?.img || "");
						setModalToggle(false);
					}}
				>
					{t("button.register")}
				</button>
			</div>
		</>
	);
};
export default SignatureSelectModal;
