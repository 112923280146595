import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import translation from "~/translations";
import useAlert from "~/common/hooks/useAlert";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";

// Components
import { Case, If } from "~/common/components/If";
import { dateToString } from "~/utils/formatter";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import Loading from "~/common/components/Loading";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import InviteModal from "~/pages/mypage/admin/TeamPage/InviteModal";
import View from "./View";

// Assets
import "~/assets/sass/pages/mypage/admin/team.scss";
import { TeamPageIcon } from "~/svg/PageIntroIcon";
import { Trans, useTranslation } from "react-i18next";

const AdminTeamPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const MENU = ["admin", "team"];
	const TYPE = "myPage";
	const [resendMember, setResendMember] = useState();
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [inviteList, setInviteList] = useState([]);
	const [withdrawUser, setWithdrawUser] = useState(null);

	//api
	const teamInviteRequest = useRequest("get", `admin/invite?size=100&page=1`);
	const teamActiveRequest = useRequest("post", `admin/activate`);
	const teamResendMailRequest = useRequest("post", `admin/invite/resend/${resendMember?.idx}`);
	const teamWithdrawRequest = useRequest("post", `admin/withdraw`);
	const memberResignRequest = useRequest("post", `user/resign`);

	//modal
	const [inviteModal, setInviteModal] = useState(false);
	const [modalWidth, setModalWidth] = useState(700);
	const [alertState, setAlertState] = useState("");
	const [alertModalMsg, setAlertModalMsg] = useState("");
	const [errMsg, setErrMsg] = useState("");

	const [showInfoAlert, InfoAlert] = useAlert();
	const [showErrorAlert, ErrorAlert] = useAlert();
	const [showWithdrawAlert, WithdrawAlert] = useAlert();
	const [showResignAlert, ResignAlert] = useAlert();
	const [showSuccessAlert, SuccessAlert] = useAlert();

	const ALERT_MODAL_STATE = {
		RESEND: translation.t("MyPage.admin.Team.index.state.resend"),
		ACTIVATE: translation.t("MyPage.admin.Team.index.state.activate"),
		DEACTIVATE: translation.t("MyPage.admin.Team.index.state.deactivate"),
		IS_WITHDRAW: translation.t("MyPage.admin.Team.index.state.is-withdraw"),
		WITHDRAW_DONE: translation.t("MyPage.admin.Team.index.state.withdraw-done"),
		RESIGN_DONE: translation.t("MyPage.admin.Team.index.state.resign-done"),
	};

	const informAndGetList = () => {
		showInfoAlert().then(() => getInviteList());
	};

	const getInviteList = () => {
		teamInviteRequest.call();
	};

	//활성, 비활성화 기능
	const handleActive = (idx) => {
		teamActiveRequest.call(idx, "path");
	};

	//메일 재전송 기능
	const handleResend = (password) => {
		showSuccessAlert().then(() => teamResendMailRequest.call({ password: password }, "body"));
	};

	//팀원 삭제
	const handleWithdraw = async (user) => {
		await setWithdrawUser({ idx: user.idx, isTeamMember: user.isTeamMember });
		showWithdrawAlert()
			.then(() => teamWithdrawRequest.call(user.idx, "path"))
			.catch(() => setWithdrawUser(null));
	};

	//팀원 퇴직 처리
	const handleResign = (idx) => {
		showResignAlert()
			.then(() => memberResignRequest.call(idx, "path"))
			.catch(() => informAndGetList());
	};

	//Alert Modal 상태메세지 변경
	useEffect(() => {
		switch (alertState) {
			case ALERT_MODAL_STATE.ACTIVATE:
				setAlertModalMsg(t("MyPage.admin.Team.index.alert.activate"));
				break;
			case ALERT_MODAL_STATE.DEACTIVATE:
				setAlertModalMsg(t("MyPage.admin.Team.index.alert.deactivate"));
				break;
			case ALERT_MODAL_STATE.WITHDRAW_DONE:
				setAlertModalMsg(t("MyPage.admin.Team.index.alert.withdraw-done"));
				break;
			case ALERT_MODAL_STATE.RESIGN_DONE:
				setAlertModalMsg(t("MyPage.admin.Team.index.alert.resign-done"));
				break;
			default:
				break;
		}
	}, [alertState]);

	useEffect(() => {
		getInviteList();
	}, []);

	useEffect(() => {
		switch (teamInviteRequest.state) {
			case API_STATE.done:
				const responseData = teamInviteRequest.response.data;

				const newData = responseData.result.map((d) => ({
					...d,
					regDate: dateToString(d.regDate, "yyyy-MM-dd"),
					role:
						d.role === "admin"
							? "관리자"
							: d.role === "user"
							? "일반 사용자"
							: "담당자",
				}));

				setInviteList(newData);
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error", teamInviteRequest.error);
				navigate("/404");
				break;
			default:
				break;
		}
	}, [teamInviteRequest.state, teamInviteRequest.error, teamInviteRequest.response]);

	useEffect(() => {
		switch (teamActiveRequest.state) {
			case API_STATE.done:
				informAndGetList();
				break;
			case API_STATE.error:
				console.log("error", teamActiveRequest.error);
				switch (teamActiveRequest.error.statusCode) {
					case 404:
						(async () => {
							await setErrMsg("존재하지 않는 사용자입니다. 관리자에게 문의해주세요.");
							showErrorAlert();
						})();
						break;
					case 403:
						(async () => {
							await setErrMsg("이미 탈퇴한 회원입니다. 관리자에게 문의해주세요.");
							showErrorAlert();
						})();
						break;
					case 500:
						navigate("/404");
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	}, [teamActiveRequest.state, teamActiveRequest.error, teamActiveRequest.response]);

	useEffect(() => {
		switch (teamWithdrawRequest.state) {
			case API_STATE.done:
				setAlertState(ALERT_MODAL_STATE.WITHDRAW_DONE);
				if (withdrawUser.isTeamMember) {
					handleResign(withdrawUser.idx);
				} else {
					setWithdrawUser(null);
					informAndGetList();
				}
				break;
			case API_STATE.error:
				console.log("error", teamWithdrawRequest.error);
				navigate("/404");
				break;
			default:
				break;
		}
	}, [teamWithdrawRequest.state, teamWithdrawRequest.error, teamWithdrawRequest.response]);

	useEffect(() => {
		switch (memberResignRequest.state) {
			case API_STATE.done:
				setAlertState(ALERT_MODAL_STATE.RESIGN_DONE);
				setWithdrawUser(null);
				informAndGetList();
				break;
			case API_STATE.error:
				console.log("error", memberResignRequest.error);
				navigate("/404");
				break;
			default:
				break;
		}
	}, [memberResignRequest.state, memberResignRequest.error, memberResignRequest.response]);

	const headerButtons = [
		<button
			type="button"
			className="btn__solid"
			color="primary"
			onClick={() => setInviteModal(true)}
		>
			{t("Community.BoardPage.BoardPost.List.button-invite")}
		</button>,
	];

	return (
		<>
			<NavbarAndSidebarLayout menu={MENU} type={TYPE} backTop={true}>
				<PageHeader buttonList={headerButtons} />
				<div className="pageIntro">
					<TeamPageIcon />
					<div className="content">
						<h3>
							<Trans
								i18nKey="MyPage.admin.Team.index.content.h3"
								components={[<b />]}
							/>
						</h3>
						<p className="pre">{t("MyPage.admin.Team.index.content.p")}</p>
					</div>
				</div>
				<If condition={pageState}>
					<Case condition={PAGE_STATE.LOADING}>
						<Loading />
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						<View
							{...{
								inviteList,
								pageState,
								handleActive,
								handleWithdraw,
								setAlertState,
								ALERT_MODAL_STATE,
								handleResend,
								resendMember,
								setResendMember,
							}}
						/>
					</Case>
				</If>
				<ModalTemplate
					modalState={inviteModal}
					setModalToggle={setInviteModal}
					title={t("MyPage.admin.Team.index.content.modal-title")}
					width={modalWidth}
					height={490}
					className={"modal-team-invite"}
				>
					<InviteModal
						teamInviteRequest={teamInviteRequest}
						setModalWidth={setModalWidth}
					/>
				</ModalTemplate>
			</NavbarAndSidebarLayout>
			<InfoAlert type="info" message={alertModalMsg} confirm={t("button.check")} />
			<ErrorAlert type="error" message={errMsg} confirm={t("button.check")} />
			<SuccessAlert
				message={
					<>
						<h3 className="main-desc">팀원에게 초대 메일을 재전송합니다.</h3>
						<p className="sub-desc">
							비밀번호 변경 후 초대 메일을 재전송할 경우
							<br /> 기존 비밀번호로는 로그인이 불가합니다.
						</p>
					</>
				}
				className="modal-resend-success"
				confirm="확인"
				reject="취소"
			/>
			<WithdrawAlert
				type="warning"
				message={t("MyPage.admin.Team.index.content.withdrawAlert")}
				confirm={t("button.yes")}
				reject={t("button.No")}
			/>
			<ResignAlert
				type="warning"
				message={t("MyPage.admin.Team.index.content.resignAlert")}
				confirm={t("button.yes")}
				reject={t("button.No")}
			/>
		</>
	);
};

export default AdminTeamPage;
