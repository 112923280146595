import React, { Children } from "react";
import PropsProvider from "~/common/components/PropsProvider";

export const If = ({ condition, children, ...props }) => {
	let caseElements = [];
	let otherWiseElements = [];
	Children.forEach(children, (child, idx) => {
		switch (child.type.displayName) {
			case "Case":
				if (child.props.condition === condition) {
					caseElements.push(
						<PropsProvider key={idx} props={props}>
							{child.props.children}
						</PropsProvider>
					);
				}
				break;
			case "MultiCases":
				if (child.props.condition.includes(condition)) {
					caseElements.push(
						<PropsProvider key={idx} props={props}>
							{child.props.children}
						</PropsProvider>
					);
				}
				break;
			case "OtherWise":
			default:
				otherWiseElements.push(
					<PropsProvider key={idx} props={props}>
						{child.props.children}
					</PropsProvider>
				);
				break;
		}
	});

	if (caseElements.length !== 0) {
		return caseElements;
	} else if (otherWiseElements.length !== 0) {
		return otherWiseElements;
	} else {
		return <></>;
	}
};

export const Case = ({ children }) => {
	return children;
};
Case.displayName = "Case";

export const MultiCases = ({ children }) => {
	return children;
};
MultiCases.displayName = "MultiCases";

export const OtherWise = ({ children }) => {
	return children;
};
OtherWise.displayName = "OtherWise";
