import { ascSort, DateWithDayName, descSort, setExactDate } from "~/utils/formatter";
import React, { useEffect, useState } from "react";
import { addHours, areIntervalsOverlapping, isEqual, subMinutes } from "date-fns";
import Form from "~/common/components/Form";
import Select, { optionsGenerator } from "~/common/components/Select";
import { VACATION_UNIT } from "~/common/constants/form";
import DatePicker from "~/common/components/DatePicker";
import useAlert from "~/common/hooks/useAlert";
import { PAGE_STATE } from "~/common/constants/state";

const SelectedDate = (props) => {
	const {
		useForm,
		name,
		workTime,
		setValue,
		restTime,
		vacationUnit,
		selectedVacation,
		setPageState,
		defaultWorkTime,
	} = props;
	const selectedDate = DateWithDayName(useForm.watch(name("startDate")));
	const selectedTimeUnit = useForm.watch(name("timeUnit"));

	const [readonly, setReadonly] = useState({ start: false, end: false });

	const [includeTimes, setIncludeTimes] = useState({ startTimeList: [], endTimeList: [] });

	const [startTimeRange, setStartTimeRange] = useState({
		min: null,
		max: null,
	});
	const [endTimeRange, setEndTimeRange] = useState({
		min: null,
		max: null,
	});

	const [isRestTimeIncluded, setIsRestTimeIncluded] = useState(false);

	const [showWarningAlert, WarningAlert] = useAlert();
	useEffect(() => {
		setReadonly({ start: false, end: false });
		setIsRestTimeIncluded(false);
		switch (selectedTimeUnit) {
			case "1":
				setValue(name("startTime"), defaultWorkTime.morningStart);
				setValue(name("endTime"), defaultWorkTime.morningEnd);
				setReadonly({ start: workTime.morningVStartTime.length === 1, end: true });
				setIncludeTimes({
					startTimeList: workTime.morningVStartTime,
					endTimeList: workTime.morningVEndTime,
				});
				break;
			case "2":
				setValue(name("startTime"), defaultWorkTime.afternoonStart);
				setValue(name("endTime"), defaultWorkTime.afternoonEnd);
				setReadonly({ start: true, end: workTime.afternoonVEndTime.length === 1 });
				setIncludeTimes({
					startTimeList: workTime.afternoonVStartTime,
					endTimeList: workTime.afternoonVEndTime,
				});

				break;
			case "3":
				setValue(name("startTime"), defaultWorkTime.morningStart);
				setValue(name("endTime"), addHours(defaultWorkTime.morningStart, 1));
				setStartTimeRange({
					min: ascSort(workTime.morningVStartTime)[0],
					max: descSort(workTime.afternoonVEndTime)[0],
				});
				setEndTimeRange({
					min: useForm.watch(name("startTime")),
					max: subMinutes(workTime.afternoonVEndTime[0], 30),
				});
				break;
		}
	}, [selectedTimeUnit]);

	const onChangeStartTime = (value) => {
		if (selectedTimeUnit === "3") {
			let endTime = addHours(value, 1);
			setValue(name("endTime"), endTime);
			if (endTime > descSort(workTime.afternoonVEndTime)[0]) {
				setValue(name("endTime"), descSort(workTime.afternoonVEndTime)[0]);
			}

			checkRestTimeIncluded();

			let index;
			index = workTime.morningVStartTime.findIndex((e) => isEqual(e, value));
			if (index > -1) {
				setEndTimeRange({
					min: value,
					max: subMinutes(workTime.afternoonVEndTime[index], 30),
				});
			} else {
				setEndTimeRange({ min: value, max: descSort(workTime.afternoonVEndTime)[0] });
			}
		} else {
			autoSetTime(value);
		}
	};

	const onChangeEndTime = (value) => {
		if (selectedTimeUnit === "3") {
			checkRestTimeIncluded();
		} else {
			autoSetTime(value);
		}
	};

	//반차 시간 변경 시 자동 시간 부여
	const autoSetTime = (value) => {
		let index;
		switch (selectedTimeUnit) {
			case "1":
				index = includeTimes.startTimeList.findIndex((e) => isEqual(e, value));
				if (includeTimes.endTimeList[index]) {
					setValue(name("endTime"), includeTimes.endTimeList[index]);
				} else {
					showWarningAlert().then(() => setPageState(PAGE_STATE.VIEW));
				}
				break;
			case "2":
				index = includeTimes.endTimeList.findIndex((e) => isEqual(e, value));
				if (includeTimes.endTimeList[index]) {
					setValue(name("startTime"), includeTimes.startTimeList[index]);
				} else {
					showWarningAlert().then(() => setPageState(PAGE_STATE.VIEW));
				}
				break;
		}
	};

	let handleTimeClassName = (time) => {
		const minDate = ascSort(workTime.morningVStartTime)[0];
		const maxDate = descSort(workTime.afternoonVEndTime)[0];

		return setExactDate(minDate) > time.getTime() || time.getTime() > setExactDate(maxDate)
			? "exclude-time"
			: "include-time";
	};

	const checkRestTimeIncluded = () => {
		setIsRestTimeIncluded(
			areIntervalsOverlapping(
				{
					start: setExactDate(restTime.start),
					end: setExactDate(restTime.end),
				},
				{
					start: setExactDate(useForm.watch(name("startTime"))),
					end: setExactDate(useForm.watch(name("endTime"))),
				}
			)
		);
	};

	function reduceObj(obj, delKey) {
		return Object.keys(obj).reduce((result, key) => {
			if (!delKey.includes(key)) {
				result[key] = obj[key];
			}
			return result;
		}, {});
	}

	const [unitOption, setUnitOption] = useState({});

	useEffect(() => {
		if (vacationUnit === "day") {
			setUnitOption(reduceObj(VACATION_UNIT, ["1", "2", "3"]));
		} else if (vacationUnit === "half") {
			setUnitOption(reduceObj(VACATION_UNIT, ["3"]));
		} else if (vacationUnit === "only-half") {
			setUnitOption(reduceObj(VACATION_UNIT, ["0", "3"]));
		} else {
			setUnitOption(VACATION_UNIT);
		}
	}, []);

	return (
		<>
			<Form useForm={useForm} className="annual-vacation-form">
				<Select
					label={
						<>
							<i className="color" style={{ background: selectedVacation.color }} />
							{selectedDate}
						</>
					}
					name={name("timeUnit")}
					className="vacation-name"
					options={optionsGenerator(unitOption)}
				/>
			</Form>
			{useForm.watch(name("timeUnit")) !== "0" && (
				<>
					<Form
						useForm={useForm}
						className="annual-vacation-form timePicker-wrap vertical"
					>
						<DatePicker
							onChange={onChangeStartTime}
							label="시작 시간"
							name={name("startTime")}
							className={"datepicker"}
							time
							readOnly={readonly.start}
							timeClassName={handleTimeClassName}
							includeTimes={selectedTimeUnit !== "3" && includeTimes.startTimeList}
							minTime={selectedTimeUnit === "3" && startTimeRange.min}
							maxTime={
								selectedTimeUnit === "3" && descSort(workTime.afternoonVEndTime)[0]
							}
							calendarClassName={
								selectedTimeUnit === "3" ? "time-vacation" : "half-day-vacation"
							}
						/>
						<span className="dash"> - </span>
						<DatePicker
							onChange={onChangeEndTime}
							label="종료 시간"
							name={name("endTime")}
							className={"datepicker"}
							time
							readOnly={readonly.end}
							timeClassName={handleTimeClassName}
							includeTimes={selectedTimeUnit !== "3" && includeTimes.endTimeList}
							minTime={selectedTimeUnit === "3" && useForm.watch(name("startTime"))}
							maxTime={selectedTimeUnit === "3" && endTimeRange.max}
							calendarClassName={
								selectedTimeUnit === "3" ? "time-vacation" : "half-day-vacation"
							}
						/>
					</Form>
					{isRestTimeIncluded && (
						<p className="warning-phrase">※ 휴게시간을 포함하여 연차에서 차감됩니다.</p>
					)}
				</>
			)}
			<WarningAlert
				message={"문제가 발생하였습니다. 다시 시도해주세요."}
				confirm="확인"
				type="warning"
			/>
		</>
	);
};

export default SelectedDate;
