
import React, {useEffect, useState} from "react";

// Assets
import { Trans, useTranslation } from "react-i18next";
import translation from "~/translations";
import stInvest from "~/assets/img/newLanding/st-invest.png";
import enStInvest from "~/assets/img/newLanding/EN/st-invest_en.png";
import i18next from "i18next";

// img
import vacation from "~/assets/img/newLanding/st-vacation.png";
import hr from "~/assets/img/newLanding/st-hr.png";
import community from "~/assets/img/newLanding/st-community.png";

// img en
import enVacation from "~/assets/img/newLanding/EN/st-vacation_en.png";
import enHr from "~/assets/img/newLanding/EN/st-hr_en.png";
import enCommunity from "~/assets/img/newLanding/EN/st-community_en.png";
import {AccordionArrowIcon} from "~/assets/svg/LandingIcon";
import vcApply2 from "~/assets/img/newLanding/vc-apply2.png";
import enVcApply2 from "~/assets/img/newLanding/EN/vc-apply2_en.png";

const StartupService = () => {
    const { t } = useTranslation();
    const langImg = i18next.language;
    const stMenuItem = [
        {
            key: "vacation",
            title: translation.t("Landing.Section.startupService.vacation.title"),
            desc: translation.t("Landing.Section.startupService.vacation.desc"),
            src: vacation,
            enSrc: enVacation,
            alt: translation.t("Landing.Section.startupService.vacation.alt"),
        },
        {
            key: "hr",
            title: translation.t("Landing.Section.startupService.hr.title"),
            desc: translation.t("Landing.Section.startupService.hr.desc"),
            src: hr,
            enSrc: enHr,
            alt: translation.t("Landing.Section.startupService.hr.alt"),
        },
        {
            key: "community",
            title: translation.t("Landing.Section.startupService.community.title"),
            desc: translation.t("Landing.Section.startupService.community.desc"),
            src: community,
            enSrc: enCommunity,
            alt: translation.t("Landing.Section.startupService.community.alt"),
        },
    ]


    return (
        <section className="accordion-section">
            <div className="wrap">
                {stMenuItem.map((item, i) => (
                    <div className={`accordion-wrap ${(i%2 > 0) ? "": "odd"}`} key={i}>
                        <div className="accordion-menu">
                            <h4 className="title">
                                {t("Landing.Section.startupService.title")}
                            </h4>
                            <ul className="accordion">
                                <li className={`accordion-item active`}>
                                    <div className="accordion-head">
                                        {item.title}
                                    </div>
                                    <p className={`accordion-content active`}>
                                        {item.desc}
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className={`img-wrap`}>
                            {langImg === "ko" ?
                                <img src={item.src} alt={item.alt} /> :
                                <img src={item.enSrc} alt={item.alt} />}
                        </div>
                    </div>
                ))}
            </div>


        </section>
    );
};

export default StartupService;
