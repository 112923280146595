import storageService from "~/utils/storage";
// import { CLEAR_STATES } from "~/store/reducers";

export const OKCEO_LOGIN = "tax/OKCEO_LOGIN";
export const OKCEO_LOGOUT = "tax/OKCEO_LOGOUT";
export const SET_ACCOUNT_DATA = "tax/SET_ACCOUNT_DATA";
export const SET_COST_DATA = "tax/SET_COST_DATA";
export const SET_SALES_DATA = "tax/SET_SALES_DATA";
export const SET_FLOW_DATA = "tax/SET_FLOW_DATA";
export const SET_PROFIT_DATA = "tax/SET_PROFIT_DATA";
export const SET_DUTY_DATA = "tax/SET_DUTY_DATA";

export const Okceologin = ({ accessToken }) => ({
	type: OKCEO_LOGIN,
	payload: {
		accessToken: accessToken,
	},
});

export const setAccountData = ({ account }) => ({
	type: SET_ACCOUNT_DATA,
	payload: {
		account: account,
	},
});

export const setCostData = ({ cost }) => ({
	type: SET_COST_DATA,
	payload: {
		cost: cost,
	},
});

export const setSalesData = ({ sales }) => ({
	type: SET_SALES_DATA,
	payload: {
		sales: sales,
	},
});

export const setFlowData = ({ flow }) => ({
	type: SET_FLOW_DATA,
	payload: {
		flow: flow,
	},
});

export const setProfitData = ({ profit }) => ({
	type: SET_PROFIT_DATA,
	payload: {
		profit: profit,
	},
});

export const setDutyData = ({ duty }) => ({
	type: SET_DUTY_DATA,
	payload: {
		duty: duty,
	},
});

export const Okceologout = () => {
	// type: OKCEO_LOGOUT,
	storageService.clear("okceo.auth", "local");
	return {
		type: OKCEO_LOGOUT,
	};
};
