import React from "react";
import SortableTree from "react-sortable-tree";
import CustomRenderer from "./CustomRenderer";

const TreeView = ({
	tree,
	setTree,
	handleClick = () => {},
	style,
	canMove = true,
	className,
	buttonList = () => [],
	selected,
}) => {
	let willRerange = false;
	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				overflowX: "hidden",
				overflowY: "auto",
				...style,
			}}
			className={className}
		>
			<SortableTree
				generateNodeProps={(row) => {
					const buttons = buttonList({ tree: tree, setTree: setTree, row: row });
					return {
						title: row.node.title,
						willRerange: (e) => (willRerange = e),
						handleClick: () => handleClick(row),
						buttons: buttons,
						className: className,
						isSelected: selected === row.node.id,
					};
				}}
				canDrag={() => {
					return canMove;
				}}
				treeData={tree}
				isVirtualized={false}
				onChange={(e) => setTree(e)}
				canDrop={() => {
					if (!willRerange) return true;
				}}
				theme={CustomRenderer}
			/>
		</div>
	);
};

export default TreeView;
