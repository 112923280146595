import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Store
import { useGlobalStore } from "~/store";

// Components
import { Case, If } from "~/common/components/If";
import { PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import AuthButton from "~/common/components/AuthButton";
import TreeView from "~/common/components/TreeView";
import TooltipTemplate from "~/common/components/TooltipPortal/TooltipTemplate";

// Assets
import { WheelIcon } from "~/assets/svg/InterfacesIcon";
import NoData from "~/common/components/NoData";
import {PlusIcon} from "~/assets/svg/MathIcon";

const OrgViewer = ({
	setModalToggle,
	totalMemberCount,
	selectedOrg,
	handleRowClick,
	clearSelectedOrg,
}) => {
	const { t } = useTranslation();
	const [{ user, organization }] = useGlobalStore();

	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	useEffect(() => {
		if (organization.treeData === null) {
			setPageState(PAGE_STATE.LOADING);
		} else if (organization.treeData.length > 0) {
			setPageState(PAGE_STATE.VIEW);
		} else if (organization.treeData.length === 0) {
			setPageState(PAGE_STATE.EMPTY);
		}
	}, [organization.treeData]);

	const viewerButtonList = ({ row }) => [
		<div
			className="org-number"
			style={{
				backgroundColor: "#bebbc9",
				height: "22px",
				width: "22px",
				borderRadius: "4px",
				color: "#f8f8f8",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				fontWeight: "600",
			}}
			key="count"
		>
			{row.node.reducedCount}
		</div>,
	];

	return (
		<div className="c__card-fit org-card">
			<div className="card-top">
				<div className="company" style={{ cursor: "pointer" }} onClick={clearSelectedOrg}>
					{user.company.name}
				</div>
				{/*<AuthButton*/}
				{/*	type="button"*/}
				{/*	className="btn__setting c__tooltip-btn"*/}
				{/*	onClick={() => setModalToggle(true)}*/}
				{/*>*/}
				{/*	<WheelIcon />*/}
				{/*	<TooltipTemplate>{t("HR.TeamInfo.orgSettingModal")}</TooltipTemplate>*/}
				{/*</AuthButton>*/}
				<AuthButton
					type="button"
					className="btn__setting c__tooltip-btn"
					onClick={() => setModalToggle(true)}
				>
					<PlusIcon/>
					조직 생성
					<TooltipTemplate>{t("HR.TeamInfo.orgSettingModal")}</TooltipTemplate>
				</AuthButton>
			</div>
			<If condition={pageState}>
				<Case condition={PAGE_STATE.LOADING}>
					<Loading />
				</Case>
				<Case condition={PAGE_STATE.EMPTY}>
					<NoData main={t("HR.TeamInfo.OrgViewer.NoData")} />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<div className="scroll-wrap">
						<div className="org-wrap">
							<div
								className={`org-overall-bar ${
									selectedOrg.length === 0 && "selected"
								}`}
								onClick={clearSelectedOrg}
							>
								<p className="org-name">{t("HR.TeamInfo.UserList.entire")}</p>
								<p className="org-number">{totalMemberCount}</p>
							</div>
							<TreeView
								tree={organization.treeData ?? []}
								handleClick={handleRowClick}
								canMove={false}
								selected={selectedOrg[0]}
								buttonList={viewerButtonList}
							/>
						</div>
					</div>
				</Case>
			</If>
		</div>
	);
};

export default OrgViewer;
