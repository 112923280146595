import React, {cloneElement, useEffect} from "react";
import { useGlobalStore } from "~/store";
import i18next from "i18next";
import { useAtomValue } from "jotai";
import { languageAtom } from "~/atoms/translation";
import { useTranslation } from "react-i18next";

/**
 * Page header component
 *
 * @param { Props } props
 *
 * @typedef Props
 * @property {[string, string]} menu - 메뉴 목록 (0: 메인메뉴, 1: 서브메뉴)
 * @property {"main"|"myPage"} type - 메뉴 타입
 * @property {boolean} hasBreadCrumb - default: true
 * @property {React.ReactElement[]} buttonList - 버튼 목록
 * @property {string} title -
 * @property {string} mainTitle -
 * @property {string} subTitle -
 */
const PageHeader = ({ menu = [], type, hasBreadCrumb, buttonList, title, mainTitle, subTitle }) => {
	const { i18n } = useTranslation();
	const [{ sidebar }] = useGlobalStore();
	const [mainMenuId, subMenuId] = menu;

	const buttons = () => {
		return buttonList.map((child, index) => {
			return cloneElement(child, { key: `${type}_${index}` });
		});
	};

	const getMenuById = (list, id) => {
		if (!list) return {};
		return list.find((menu) => menu.id === id);
	};

	const mainMenuList = sidebar.sideBarList?.[type];
	const mainMenu = getMenuById(mainMenuList, mainMenuId);
	const subMenuList = mainMenu?.subMenu;
	const subMenu = getMenuById(subMenuList, subMenuId);

	return (
		<>
			<div className="l__pageHeader">
				<h2 className="page-name">
					{/*{langChange ? }*/}
					{title
						? title
						: subMenu
						? i18n.language === "ko"
							? subMenu.title
							: subMenu.engTitle
						: i18n.language === "ko"
						? mainMenu.title
						: mainMenu.engTitle}
				</h2>
				{hasBreadCrumb === false ? null : (
					<ol className="c__breadcrumb">
						<li>Home</li>
						{mainTitle ? (
							<li>{mainTitle}</li>
						) : (
							mainMenu && (
								<li>
									{i18n.language === "ko" ? mainMenu.title : mainMenu.engTitle}
								</li>
							)
						)}
						{subTitle ? (
							<li>{subTitle}</li>
						) : (
							subMenu && (
								<li>{i18n.language === "ko" ? subMenu.title : subMenu.engTitle}</li>
							)
						)}
					</ol>
				)}
				<div className="divider" />
			</div>
			<div className="pageHeader-btn-group">{buttonList && buttons()}</div>
		</>
	);
};

export default PageHeader;
