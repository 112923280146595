import React from "react";
import { useForm } from "react-hook-form";

import "~/assets/sass/pages/ir/finance.scss";
import { useGlobalStore } from "~/store";
import DynamicFields from "./DynamicFields";
import useRequest from "~/common/hooks/useRequest";
import Positions from "./Positions";
import IncomeStatus from "./IncomeStatus";
import useRepository from "~/common/hooks/useRepository";
import { USER_INFO } from "~/store/user/repository";
import useAlert from "~/common/hooks/useAlert";
import { useTranslation } from "react-i18next";

const Edit = ({ showViewPage, Header }) => {
	const { t } = useTranslation();
	const [
		{
			ir: {
				selectedIdx: irIdx,
				finance: { IRFinanceDept, ...Finance },
			},
		},
	] = useGlobalStore();
	const [getUserInfo] = useRepository(USER_INFO);

	const financeForm = useForm({ defaultValues: { irIdx, Finance, Dept: IRFinanceDept } });

	const irMutation = useRequest("post", "ir/finance/edit");

	const submit = async (data) => {
		irMutation
			.asyncCall(data)
			.then(() => {
				getUserInfo().then(showViewPage);
			})
			.catch((msg) => console.log(msg));
	};

	const [showCancelAlert, CancelAlert] = useAlert();

	const buttonList = [
		<button
			type="button"
			className="btn__solid"
			color="gray"
			onClick={() => {
				showCancelAlert().then(() => showViewPage());
			}}
		>
			{t("button.cancel")}
		</button>,
		<button
			type="button"
			className="btn__solid"
			color="primary"
			onClick={financeForm.handleSubmit(submit)}
		>
			{t("button.check")}
		</button>,
	];

	return (
		<>
			<Header buttonList={buttonList} />
			<div className="ir-view">
				<div className="c__card finance-edit">
					<div className="data-form">
						<Positions useForm={financeForm} />
						<IncomeStatus useForm={financeForm} />
						<DynamicFields useForm={financeForm} />
					</div>
				</div>
			</div>
			<CancelAlert
				message={t("IR.IrDetail.Finance.Edit.Index")}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
		</>
	);
};

export default Edit;
