import React, { useEffect, useState } from "react";
import { Case, If } from "~/common/components/If";

import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";

import View from "./View";
import Edit from "./Edit";

import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";

import "~/assets/sass/pages/company/member.scss";
import LoadingPage from "~/pages/LoadingPage";

const CompanyMemberPage = () => {
	const [page, setPage] = useState(PAGE_STATE.LOADING);

	const [memberList, setMemberList] = useState([]);

	const teamListRequest = useRequest("get", "company/member");

	useEffect(() => {
		teamListRequest.singleCall();
	}, []);

	useEffect(() => {
		switch (teamListRequest.state) {
			case API_STATE.done:
				setMemberList(teamListRequest.response?.data);
				setPage(PAGE_STATE.VIEW);
			case API_STATE.loading:
			default:
				break;
		}
	}, [teamListRequest.state]);

	const showViewPage = () => {
		teamListRequest.call();
	};

	const showEditPage = () => {
		setPage(PAGE_STATE.EDIT);
	};

	return (
		<>
			<NavbarAndSidebarLayout menu={["company", "member"]} type="main">
				<If condition={page} data={memberList} {...{ showViewPage, showEditPage }}>
					<Case condition={PAGE_STATE.VIEW}>
						<View />
					</Case>
					<Case condition={PAGE_STATE.EDIT}>
						<Edit />
					</Case>
					<Case condition={PAGE_STATE.LOADING}>
						<LoadingPage />
					</Case>
				</If>
			</NavbarAndSidebarLayout>
		</>
	);
};

export default CompanyMemberPage;
