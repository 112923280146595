import React from "react";
import translation from "~/translations";

// Components
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import { Outlet } from "react-router";

// Assets
import "~/assets/sass/pages/hr/setting/common.scss";

const HrSettingPage = () => {
	const MENU = ["hr", "setting"];
	const TYPE = "main";
	const HR_SETTING_TAB_LIST = [
		{ id: "payday", title: translation.t("HR.HrSetting.TAB_LIST.payday"), path: "../payday" },
		{
			id: "worktype",
			title: translation.t("HR.HrSetting.TAB_LIST.worktype"),
			path: "../worktype",
		},
		{
			id: "allowance",
			title: translation.t("HR.HrSetting.TAB_LIST.allowance"),
			path: "",
			disable: true,
		},
	];

	return (
		<>
			<NavbarAndSidebarLayout menu={MENU} type={TYPE}>
				<Outlet
					context={{ hrSettingTabList: HR_SETTING_TAB_LIST, menu: MENU, type: TYPE }}
				/>
			</NavbarAndSidebarLayout>
		</>
	);
};
export default HrSettingPage;
