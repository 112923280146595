import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
	const { t } = useTranslation();
	return (
		<footer className="l__footer">
			<ul className="footer-info">
				<li>
					<Trans i18nKey="components.Footer.CompanyInfo.7" components={[<b />]} />
				</li>
				<li>{t("components.Footer.CompanyInfo.4")}</li>
				<li>Tel: {t("components.Footer.CompanyInfo.5")}</li>
				<li>E-Mail: {t("components.Footer.CompanyInfo.6")}</li> <br />
				<li>{t("components.Footer.CompanyInfo.1")}</li>
				<li>{t("components.Footer.CompanyInfo.2")}</li>
				<li>{t("components.Footer.CompanyInfo.3")}</li>
			</ul>
			<div className="footer-policy">
				<Link to={"/policy/terms"} target="_blank">
					{t("components.Footer.terms")}
				</Link>
				<Link to={"/policy/privacy"} target="_blank">
					{t("components.Footer.privacy")}
				</Link>
			</div>
		</footer>
	);
};

export default Footer;
