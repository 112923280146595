import React from "react";
import {
	AccountIcon,
	AdminIcon,
	AlarmIcon,
	CompanyIcon,
	VacationIcon,
	HrIcon,
	InvestIcon,
	IrIcon,
	PluginIcon,
	ShareholderIcon,
	TaxIcon,
	VcIcon,
	CommunityIcon,
	ManagementIcon
} from "~/assets/svg/SidebarMenuIcon";
import { Case, If } from "~/common/components/If";
import SquareInitialImg from "~/assets/img/defaultImg/logo-square.png";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";
import { useGlobalStore } from "~/store";

const MainMenuIcon = ({ id }) => {
	return (
		<If condition={id}>
			<Case condition={"company"}>
				<CompanyIcon />
			</Case>
			<Case condition={"vacation"}>
				<VacationIcon />
			</Case>
			<Case condition={"hr"}>
				<HrIcon />
			</Case>
			<Case condition={"ir"}>
				<IrIcon />
			</Case>
			<Case condition={"invest"}>
				<InvestIcon />
			</Case>
			<Case condition={"shareholder"}>
				<ShareholderIcon />
			</Case>
			<Case condition={"vc"}>
				<VcIcon />
			</Case>
			<Case condition={"tax"}>
				<TaxIcon />
			</Case>
			<Case condition={"account"}>
				<AccountIcon />
			</Case>
			<Case condition={"alarm"}>
				<AlarmIcon />
			</Case>
			<Case condition={"plugin"}>
				<PluginIcon />
			</Case>
			<Case condition={"admin"}>
				<AdminIcon />
			</Case>
			<Case condition={"community"}>
				<CommunityIcon />
			</Case>
			<Case condition={"management"}>
				<ManagementIcon />
			</Case>
		</If>
	);
};

const MainMenuItem = ({ active, disabled, title, engTitle, id, handleClick }) => {
	const { i18n } = useTranslation();
	const classString = `${active} ${disabled}`;
	return (
		<li className={classString}>
			<button onClick={handleClick}>
				<MainMenuIcon id={id} />
				{i18n.language === "ko" ? title : engTitle}
			</button>
		</li>
	);
};

const MainMenu = ({ list, selectedMenu, handleClick, currentType, user }) => {
	const [
		{
			user: { info },
			payment: { isFailedPayment },
		},
	] = useGlobalStore();

	const mainMenuList = () => {
		return list.map((menu, i) => {
			if (menu === null) return null;
			const disabled = !menu.active && "disabled";
			const active = menu === selectedMenu && "active";
			const title = menu.title;
			const engTitle = menu.engTitle;
			const id = menu.id;
			return (
				<MainMenuItem
					key={i}
					handleClick={() => handleClick(id)}
					{...{ disabled, active, title, engTitle, id }}
				/>
			);
		});
	};

	const failBannerHeight = isFailedPayment && info.role === "admin" ? "60px" : "0px";


	return (
		<>
			<ul className="l__mainMenu">
		
				<PerfectScrollbar
					// style={
					// 	currentType === "myPage"
					// 		? { height: `calc(100% - 120px - ${failBannerHeight} )` }
					// 		: { height: `calc(100% - ${failBannerHeight})` }
					// }
				>
					{mainMenuList()}
				</PerfectScrollbar>
			</ul>
		</>
	);
};

export default MainMenu;
