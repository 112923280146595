import React from "react";
import { useTranslation } from "react-i18next";

// Assets
import "~/assets/sass/pages/hr/modal/common/edit/memberDelete.scss";

const MemberDelete = () => {
	const { t } = useTranslation();
	return (
		<section className="teamInfo-delete edit">
			<p className="section-title">
				{t("HR.TeamInfo.modal.MemberDetailModal.components.MemberDelete.section-title")}
			</p>
			<p className="phrase">
				{t("HR.TeamInfo.modal.MemberDetailModal.components.MemberDelete.phrase")}
			</p>
			<div className="desc-wrap">
				<p>{t("HR.TeamInfo.modal.MemberDetailModal.components.MemberDelete.desc-wrap1")}</p>
				<p>{t("HR.TeamInfo.modal.MemberDetailModal.components.MemberDelete.desc-wrap2")}</p>
			</div>
		</section>
	);
};

export default MemberDelete;
