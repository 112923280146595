import React from "react";
import EmptyCase from "~/common/components/EmptyCase";
import { useTranslation } from "react-i18next";

const Empty = () => {
	const { t } = useTranslation();
	return (
		<EmptyCase main={t("IR.IrList.Empty.Index.main")} sub={t("IR.IrList.Empty.Index.sub")} />
	);
};

export default Empty;
