import React, {useEffect} from "react";
import { PlusIcon } from "~/assets/svg/MathIcon";
import { subDays } from "date-fns";
import { dateFormatter, dateToString } from "~/utils/formatter";
import { PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import { Case, If } from "~/common/components/If";
import { useTranslation } from "react-i18next";
import {ArrowRight} from "~/assets/svg/InterfacesIcon";
import {Link} from "react-router-dom";
import moment from "moment";

const RecentAlert = ({ data, pageState }) => {

	const { t } = useTranslation();
	const AlertRow = ({ title, date }) => {
		return (
			<li className="row">
				<div className="alarm-title">
					<p className={"alarm-title-ellipsis"}>{title}</p>
				</div>
				<div className="alarm-date">{date}</div>
			</li>
		);
	};

	const AlertTable = () =>
		data.map(({ title, regDate }, i) => {
			const formattedDate =
				dateFormatter(new Date()) === dateFormatter(regDate)
					? t("Dashboard.Contents.RecentAlert.AlertRowDate.0")
					: dateFormatter(subDays(new Date(), 1)) === dateFormatter(regDate)
					? t("Dashboard.Contents.RecentAlert.AlertRowDate.1")
					: moment(regDate).format("YYYY-MM-DD")
					// : dateToString(regDate, "MM-dd");
			return <AlertRow key={i} title={title} date={formattedDate} />;
		});


	useEffect(() => {
		pageState && console.log('pageState -> ', pageState);
	}, [pageState])

	return (
		<article className="recent-alarm">
			<div className="recent-alarm-top">
				<div className="c__title">
					{t("Dashboard.Contents.RecentAlert.article-title")}({data.length})
				</div>
				{/*<a href="/mypage/alarm/list" className="btn-alarm-plus">*/}
				{/*	<PlusIcon />*/}
				{/*</a>*/}
				<Link to={"/mypage/alarm/list"}><ArrowRight/></Link>
			</div>
			<If condition={pageState}>
				<Case condition={PAGE_STATE.LOADING}>
					<Loading />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					{data.length > 0 ? (
						<ul>
							<AlertTable />
						</ul>
					) : (
						<div className="recent-alarm-initial">
							<p>{t("Dashboard.Contents.RecentAlert.recent-alarm-initial")}</p>
						</div>
					)}
				</Case>
			</If>
		</article>
	);
};

export default RecentAlert;
