import React, { useEffect, useState } from "react";
import { CautionIcon, QuestionMarkIcon } from "~/assets/svg/InterfacesIcon";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import useAlert from "~/common/hooks/useAlert";
import Loading from "~/common/components/Loading";
import { Case, If } from "~/common/components/If";
import VacationExplanationModal from "../Modal/VacationExplanationModal";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const SettingPolicy = ({ currentPath, showAuthAlert }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	const [setting, setSetting] = useState({});

	const vacationSettingRequest = useRequest("get", `vacation/setting`);
	const modifyHrVacationSettingRequest = useRequest("post", `vacation/setting`);

	useEffect(() => {
		vacationSettingRequest.call();
	}, []);

	useEffect(() => {
		switch (vacationSettingRequest.state) {
			case API_STATE.done:
				setSetting(vacationSettingRequest.response.data);
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log(vacationSettingRequest.error);
				// navigate("/404");
				break;
		}
	}, [vacationSettingRequest.state]);

	const [annualLeaveModal, setAnnualLeaveModal] = useState(false);
	const [showUnitWarningAlert, UnitWarningAlert] = useAlert();
	const [showPolicyWarningAlert, PolicyWarningAlert] = useAlert();

	const handleChangePolicy = (value) => {
		if (currentPath.auth === "view") {
			showAuthAlert();
		} else {
			const requestData = {
				option: "policy",
				value: value,
			};
			showPolicyWarningAlert().then(() =>
				modifyHrVacationSettingRequest.call(requestData, "body")
			);
		}
	};

	const handleChangeUnit = (value) => {
		if (currentPath.auth === "view") {
			showAuthAlert();
		} else {
			const requestData = {
				option: "unit",
				value: value,
			};
			showUnitWarningAlert().then(() =>
				modifyHrVacationSettingRequest.call(requestData, "body")
			);
		}
	};

	const handleChangeAutoConfirm = (value) => {
		if (currentPath.auth === "view") {
			showAuthAlert();
		} else {
			const requestData = {
				option: "autoConfirm",
				value: value,
			};
			modifyHrVacationSettingRequest.call(requestData, "body");
		}
	};

	useEffect(() => {
		switch (modifyHrVacationSettingRequest.state) {
			case API_STATE.done:
				vacationSettingRequest.call();
				break;
			case API_STATE.error:
				break;
		}
	}, [modifyHrVacationSettingRequest.state]);

	return (
		<>
			<h3 className="title">
				{t("HR.Vacation.VacationSetting.SettingPolicy.title")}
				<button type="button" className="c__tooltip-btn">
					<QuestionMarkIcon />
					<div className="c__tooltip">
						{t("HR.Vacation.VacationSetting.SettingPolicy.c__tooltip")}
					</div>
				</button>
			</h3>
			<If condition={pageState}>
				<Case condition={PAGE_STATE.LOADING}>
					<Loading />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<section className="vacation-setting-section">
						<h4 className="sub-title">전체 연차 휴가 설정</h4>
						<div className="content-wrap">
							<dl>
								<dt className="content-title">연차 부여일 기준</dt>
								<dd className="content">
									<div>
										<CautionIcon />
										직원들의 연·월차 부여일 기준을 설정 할 수 있습니다. 해당
										설정은 연·월차에만 적용됩니다.
									</div>
									<button
										type="button"
										className="btn-more"
										onClick={() => setAnnualLeaveModal(true)}
									>
										자세히 알아보기
									</button>
								</dd>
							</dl>
							<ul className="radio-wrap vacationPolicy">
								<li
									className={`${
										setting.vacationPolicy === "회계년도기준" ? "active" : ""
									} radio`}
									onClick={() => handleChangePolicy("회계년도")}
								>
									회계년도 기준
								</li>
								<li
									className={`${
										setting.vacationPolicy === "입사일기준" ? "active" : ""
									} radio`}
									onClick={() => handleChangePolicy("입사일")}
								>
									입사일 기준
								</li>
							</ul>
						</div>
						<div className="content-wrap">
							<dl>
								<dt className="content-title">연차 사용 단위</dt>
								<dd className="content">
									<div>
										<CautionIcon />
										직원들의 연,월차에 대한 정책 설정을 할 수 있습니다. 해당
										설정은 연,월차에만 적용됩니다.
									</div>
								</dd>
							</dl>
							<ul className="radio-wrap unit">
								<li
									className={`${setting.unit === "일" ? "active" : ""} radio`}
									onClick={() => handleChangeUnit("FullDay")}
								>
									일
								</li>
								<li
									className={`${setting.unit === "반차" ? "active" : ""} radio`}
									onClick={() => handleChangeUnit("HalfDay")}
								>
									반차
								</li>
								<li
									className={`${setting.unit === "시간" ? "active" : ""} radio`}
									onClick={() => handleChangeUnit("Time")}
								>
									시간
								</li>
							</ul>
						</div>
						<div className="content-wrap">
							<dl>
								<dt className="content-title">관리자 알림 및 자동승인 설정</dt>
								<dd className="content">
									<div>
										<CautionIcon />
										직원들의 연,월차에 대한 알림을 On/Off 할 수 있으며, 관리자
										자동 승인을 허용할 경우 직원들의 연,월차가 자동으로 승인
										됩니다.
									</div>
								</dd>
							</dl>
							<div className="switch-group">
								<div className="row">
									{/* TODO: 관리자 알림 설정 추후 개발 예정 */}
									<div className="switch-label">관리자 알림 신청</div>
									<div className="switch-wrap alarm">
										<label className="switch alarm">
											<input type="checkbox alarm" defaultChecked={false} />
											<span className="slider round alarm" />
										</label>
									</div>
								</div>
								<div className="row">
									<div className="switch-label">관리자 자동 승인</div>
									<div className="switch-wrap">
										<label className="switch">
											<input
												type="checkbox"
												checked={setting.autoConfirm}
												onChange={() =>
													handleChangeAutoConfirm(!setting.autoConfirm)
												}
											/>
											<span className="slider round" />
										</label>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Case>
			</If>

			{/*연차 부여일 기준 모달*/}
			<ModalTemplate
				modalState={annualLeaveModal}
				setModalToggle={setAnnualLeaveModal}
				title="연차 부여일 기준"
				width={574}
				className="annual-leave-modal"
			>
				<VacationExplanationModal setModalToggle={setAnnualLeaveModal} />
			</ModalTemplate>
			<PolicyWarningAlert
				type="error"
				className="annual-excess-modal"
				layout="horiz"
				width={430}
				height={306}
				message={
					<>
						<h3>휴가부여일 기준을 변경합니다.</h3>
						<p>팀원들의 연차데이터에 오차가 발생할 수 있습니다.</p>
					</>
				}
				desc={
					<div className="desc-box error">
						관리자는 휴가관리 메뉴의 연차부여기능을 이용하여 팀원들의 휴가를 재조정 할
						수 있습니다.
					</div>
				}
				reject="취소"
				confirm="확인"
			/>
			<UnitWarningAlert
				type="error"
				className="annual-excess-modal"
				layout="horiz"
				width={430}
				height={306}
				message={
					<>
						<h3>연차 사용 단위를 변경합니다.</h3>
						<p>팀원들의 연차 데이터에 오차가 발생할 수 있습니다.</p>
					</>
				}
				desc={
					<div className="desc-box error">
						휴가 관리 메뉴에서 팀원들의 연차를 조정할 수 있습니다.
					</div>
				}
				reject="취소"
				confirm="확인"
			/>
		</>
	);
};

export default SettingPolicy;
