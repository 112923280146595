import React from "react";
import { handleWindow } from "~/common/components/Confirm";
import defaultImg from "~/assets/img/defaultImg/logo-square.png";
import { dateToString } from "~/utils/formatter";
import { Case, If } from "~/common/components/If";
import { PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import { CautionIcon } from "~/assets/svg/InterfacesIcon";
import { useTranslation } from "react-i18next";

const News = ({ newsList, newsState }) => {
	const { t } = useTranslation();
	const EMPTY_MSG = t("VcInfo.VcDetail.basic.Unknown");

	const newsRow = () => {
		return newsList.map((news, n_idx) => (
			<li key={n_idx} className="news-item" onClick={() => handleWindow(news.url)}>
				<div className="img-wrap">
					<img
						src={news.image_url ?? defaultImg}
						onError={(e) => {
							e.target.src = defaultImg;
						}}
						alt={t("VcInfo.VcDetail.basic.logo-wrap")}
					/>
				</div>
				<div className="column">
					<h5 className="news-title">{news.title}</h5>
					<p className="news-content">
						{news.content ?? t("VcInfo.VcDetail.news.news-content")}
					</p>
				</div>
				<div className="news-info">
					<span>{news.media ?? EMPTY_MSG}</span>
					<div className="divider" />
					<span>
						{news.create_date
							? dateToString(news.create_date, "yyyy-MM-dd")
							: EMPTY_MSG}
					</span>
				</div>
			</li>
		));
	};

	return (
		<li className="relation-news">
			<h4 className="detail-info-title">{t("VcInfo.VcDetail.news.detail-info-title")}</h4>
			<ul className="news-list">
				<If condition={newsState}>
					<Case condition={PAGE_STATE.VIEW}>
						{newsList?.length !== 0 ? (
							newsRow()
						) : (
							<p className="no-content">{t("VcInfo.VcDetail.news.no-content")}</p>
						)}
					</Case>
					<Case condition={PAGE_STATE.LOADING}>
						<div className="no-content">
							<Loading />
						</div>
					</Case>
					<Case condition={"error"}>
						<div className="no-content">
							<CautionIcon />
							{t("VcInfo.VcDetail.news.error")}
						</div>
					</Case>
				</If>
			</ul>
		</li>
	);
};

export default News;
