import React from "react";

// Components
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import Contents from "./Contents";

// Assets
import "~/assets/sass/pages/dashboard/admin.scss";

const DashboardPage = () => {
	return (
		<NavbarAndSidebarLayout menu={["dashboard"]} type="main">
			<Contents />
		</NavbarAndSidebarLayout>
	);
};

export default DashboardPage;
