import {
	SET_WORK_TYPE,
	CLEAR_WORK_TYPE,
	SET_SELECTED_VACATION,
	CLEAR_SELECTED_VACATION,
	SET_VACATION_SETTING,
} from "./actions";

export const INITIAL_STATE = {
	workType: {
		title: "",
		HrWorkEmployeeType: "1",
		restStartTime: new Date(new Date().setHours(12, 0, 0)),
		restEndTime: new Date(new Date().setHours(13, 0, 0)),
		workDays: [],
		paidDayOff: [],
		HrWorkTimes: [
			{
				idx: "",
				workStartTime: new Date(new Date().setHours(9, 0, 0)),
				workEndTime: new Date(new Date().setHours(18, 0, 0)),
			},
		],
	},
	selectedVacation: {
		idx: "",
		type: "",
		title: "",
		days: "",
		employeeType: ["정규직"],
		weekend: false,
		refresh: "year",
		continuous: true,
		paid: true,
		gender: "A",
		documents: "none",
		alarm: true,
		autoConfirm: true,
	},
};

export const REDUCER = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_WORK_TYPE:
			return {
				...state,
				workType: action.payload.workType,
			};
		case CLEAR_WORK_TYPE:
			return {
				...INITIAL_STATE,
			};
		case SET_SELECTED_VACATION:
			return {
				...state,
				selectedVacation: action.payload.selectedVacation,
			};
		case CLEAR_SELECTED_VACATION:
			return {
				...INITIAL_STATE,
			};
		case SET_VACATION_SETTING:
			return {
				...state,
				setting: action.payload.setting,
			};
		default:
			return state;
	}
};
