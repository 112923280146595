export const SET_PLUGIN_LIST = "plugin/SET_PLUGIN_LIST";

export const setPluginList = ({ pluginList }) => {
	return {
		type: SET_PLUGIN_LIST,
		payload: {
			pluginList,
		},
	};
};
