import React from "react";

import PageHeader from "~/common/components/PageHeader";
import { useGlobalStore } from "~/store";

import { v4 as uuid } from "uuid";
import DropdownTemplate, {
	DropDownItem,
} from "~/common/components/DropdownPortal/DropdownTemplate";
import { dateToString } from "~/utils/formatter";
import noImg from "~/assets/img/defaultImg/default-img.png";
import useRequest from "~/common/hooks/useRequest";
import { useNavigate } from "react-router";
import { setCurrentProduct } from "~/store/company/actions";
import { useTranslation } from "react-i18next";
import AuthButton from "~/common/components/AuthButton";

/**
 * Product list view component
 * @param { Props } props
 * @typedef Props
 * @property {string[]} menu
 * @property {"main"|"myPage"} type
 */
const View = ({ menu, type, companyProductRequest }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [
		{
			company: { productList },
			sidebar: { currentPath },
		},
		dispatch,
	] = useGlobalStore();

	const buttonList = [
		<AuthButton
			type="button"
			className="btn__solid btn-edit"
			color="primary"
			onClick={() => navigate("/company/product/add")}
		>
			{t("button.registering")}
		</AuthButton>,
	];

	const setRepresentationRequest = useRequest("post", `company/product/rep`);
	const removeRequest = useRequest("delete", `company/product/delete`);

	const handleSetRepresentation = (id) => {
		setRepresentationRequest.asyncCall(id, "path").then(companyProductRequest.call).catch();
	};

	const handleRemove = (id) => {
		removeRequest.asyncCall(id, "path").then(companyProductRequest.call).catch();
	};

	const productRequest = useRequest("get", `company/product/info`);

	const cardList = () => {
		return productList.map((product, index) => {
			const productImg = product.CompanyProductImage[0]?.img;
			const handleNavigateView = () => {
				navigate(`view/${product.idx}`);
			};
			const handleNavigateEdit = () => {
				productRequest
					.asyncCall(product.idx, "path")
					.then(({ data }) => {
						dispatch(setCurrentProduct({ product: data }));
					})
					.finally(() => {
						navigate(`edit/${product.idx}`);
					});
			};
			return (
				<div className="c__card" key={uuid()}>
					{product.representation && (
						<div className="custom-badge">{t("Company.Product.mainProduct")}</div>
					)}
					<div className="product-img" onClick={handleNavigateView}>
						<img
							src={productImg ? productImg : noImg}
							alt={t("Company.Product.List.view.productImg")}
						/>
					</div>
					<div className="product-container">
						<dl className="product-content" onClick={handleNavigateView}>
							<dt>{product.name}</dt>
							<dd>{product.description}</dd>
						</dl>
						<div className="product-bottom">
							<span className="date">
								{t("Company.Product.List.view.date")}{" "}
								{dateToString(product.updateDate, "yyyy. MM. dd.")}
							</span>
							{currentPath.auth === "edit" && (
								<DropdownTemplate target={index} vertical>
									{product.representation === false && (
										<DropDownItem
											onClick={() => {
												handleSetRepresentation(product.idx);
											}}
										>
											{t("Company.Product.List.view.productSetting")}
										</DropDownItem>
									)}
									<DropDownItem
										onClick={() => {
											handleNavigateEdit();
										}}
									>
										{t("Company.Product.List.view.modify")}
									</DropDownItem>
									<DropDownItem
										onClick={() => {
											handleRemove(product.idx);
										}}
									>
										{t("Company.Product.List.view.delete")}
									</DropDownItem>
								</DropdownTemplate>
							)}
						</div>
					</div>
				</div>
			);
		});
	};

	return (
		<>
			<PageHeader {...{ menu, type, buttonList }} />
			<div className="c__cardRow product-list-card">{cardList()}</div>
		</>
	);
};

export default View;
