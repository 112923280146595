import React, { useEffect, useRef, useState } from "react";

import { useGlobalStore } from "~/store";
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import UserRow from "./UserRow";

import { EMPLOYMENT_STATUS } from "~/common/constants/form";
import CustomSelect from "~/common/components/CustomSelect";
import { optionsGenerator } from "~/common/components/Select";

import { SearchIcon } from "~/assets/svg/InterfacesIcon";
import { removeFromObject } from "~/utils/object";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";
import AlertTemplate from "~/common/components/AlertPortal/AlertTemplate";
import useAlert from "~/common/hooks/useAlert";
import useRequest from "~/common/hooks/useRequest";

const UserList = (props) => {
  const {
    setModalToggle,
    setSelectedMember,
    searchWord,
    setSearchWord,
    employmentStatusFilter,
    setEmploymentStatusFilter,
    handleSearch,
    handleFilter,
    setTotalMemberCount,
    handleActive,
    handleWithdraw,
    setAlertState,
    setResendMember,
    setResendMailModal,
    getInviteList,
    showExternalAlert
  } = props;

  const { t } = useTranslation();
  const [
    {
      user: {
        info: { role },
      },
      organization: { memberList },
    },
  ] = useGlobalStore();
  
  const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
  const [alert, setAlert] = useState(false);
  
  
  const [idx, setIdx] = useState();

  const userChangeRequest = useRequest("post", `admin/auth/user/${idx}`);
  const externalChangeRequest = useRequest("post", `admin/auth/external/${idx}`);
  
  const handleChangeExternal = () => {
    alert === "external"? userChangeRequest.asyncCall().then(()=> {
      // 재요청
      setAlert(false);
      showExternalAlert().then(() => getInviteList())
			
    })
    :
     externalChangeRequest.asyncCall().then(()=> {
      // 재요청
       setAlert(false);
       showExternalAlert().then(() => getInviteList())
     })
  }

  useEffect(() => {
    handleFilter();
  }, [employmentStatusFilter]);
  

  useEffect(() => {
    if (memberList === null) {
      setPageState(PAGE_STATE.LOADING);
    } else if (memberList.length === 0) {
      setPageState(PAGE_STATE.EMPTY);
    } else if (memberList.length > 0) {
      setPageState(PAGE_STATE.VIEW);
    }

    setTotalMemberCount(memberList?.filter((ele)=> ele.status!=='탈퇴').length);
  }, [memberList]);

  const handleClickTeamOrOutside = (idx) => {
    setIdx(idx);
    setAlert(memberList.find((ele) => ele.idx === idx).role); // 외부인지 팀원인지
  };

  const Users = () => {
    return memberList?.filter((ele)=> ele.status!=='탈퇴').map((user) => {
      const {
        idx,
        id,
        img,
        name,
        joinDate,
        status,
		role,
        department,
        position,
        workingType,
        userRequirements,
        employmentStatus,
      } = user;
      return (
        <UserRow
          key={idx+id}
          user={user}
          img={img}
          idx={idx}
          name={name}
          email={id}
		  usersRole={role}
          joinDate={joinDate}
          status={status}
          department={department}
          position={position}
          workingType={workingType}
          userRequirements={userRequirements}
          employmentStatus={employmentStatus}
          handleClick={() => {
            setSelectedMember(idx);
            setModalToggle(true);
          }}
          pageState={pageState}
          handleActive={handleActive}
          handleWithdraw={handleWithdraw}
		  setAlertState={setAlertState}
          setResendMember={setResendMember}
          setResendMailModal={setResendMailModal}
          handleClickTeamOrOutside={handleClickTeamOrOutside}
        />
      );
    });
  };

  return (
    <>
      <div className="c__card-fit filter-card">
        <div className="card-top">
          <div className="search-area">
            <button type="button" className="btn-search" onClick={() => handleSearch()}>
              <SearchIcon />
            </button>
            <input
              type="text"
              value={searchWord}
              onChange={({ target }) => setSearchWord(target.value)}
              placeholder={t("HR.TeamInfo.UserList.inputPlaceholder")}
              onKeyDown={(e) => e.key === "Enter" && handleSearch()}
            />
          </div>
          <CustomSelect
            placeholder={t("HR.TeamInfo.UserList.selectPlaceholder")}
            selectName="status-option"
            value={employmentStatusFilter.toString()}
            setValue={setEmploymentStatusFilter}
            options={[
              { label: t("HR.TeamInfo.UserList.entire"), value: "-1" },
              ...optionsGenerator(
                role === "user"
                  ? removeFromObject(EMPLOYMENT_STATUS, [3])
                  : EMPLOYMENT_STATUS
              ),
            ]}
          />
        </div>
        <div className="filter-info">
          <If condition={pageState}>
            <Case condition={PAGE_STATE.LOADING}>
              <Loading />
            </Case>
            <Case condition={PAGE_STATE.EMPTY}>
              <NoData
                main={t("HR.TeamInfo.UserList.NoDataMain")}
                sub={t("HR.TeamInfo.UserList.NoDataSub")}
              />
            </Case>
            <Case condition={PAGE_STATE.VIEW}>
              <Users />
            </Case>
          </If>
        </div>
      </div>
      <AlertTemplate
        alertModalState={typeof alert === "string"}
        setAlertModalToggle={setAlert}
        alertType={"warning"}
      >
        <p className="alert-content">
          {alert === "external" ? "팀원으" : "외부"}로 전환 시 권한이 변경됩니다. <br />
          전환 하시겠습니까?
        </p>
        <div className="alert-footer">
          <button
            className="btn__solid"
            color={"gray"}
            onClick={() => setAlert(false)}
          >
            {t("button.cancel")}
          </button>
          <button
            className="btn__solid"
            color={"primary"}
            onClick={handleChangeExternal}
          >
            {t("button.check")}
          </button>
        </div>
      </AlertTemplate>
    </>
  );
};

export default UserList;
