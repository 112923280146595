import React, { useEffect, useState } from "react";
import { useSetAtom } from "jotai";
import { motion } from "framer-motion";

// Components
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";

// Assets
import topDashboard from "~/assets/img/newLanding/gitbal-dashboard.png";
import enTopDashboard from "~/assets/img/EN/myPage_plugin_list2.png";
import { promotionModalAtom } from "~/atoms/Landing";
import personImg from "~/assets/img/newLanding/lading-person.gif";
import airplane from "~/assets/img/newLanding/airplane.png";
import { useTranslation } from "react-i18next";
import ButtonWave from "~/common/components/ButtonWave";

const TopSection = () => {
	const { t, i18n } = useTranslation();
	const [introModal, setIntroModal] = useState(false);

	const setPromotionModal = useSetAtom(promotionModalAtom);

	return (
		<>
			<div className="wrap">
				<section id="top-section">
					{/*<motion.div*/}
					{/*	initial={{ opacity: 0, y: 0 }}*/}
					{/*	animate={{ opacity: 1, y: 0 }}*/}
					{/*	transition={{*/}
					{/*		duration: 0.4,*/}
					{/*		delay: 0,*/}
					{/*	}}*/}
					{/*>*/}
					{/*	<div className="img-wrap">*/}
					{/*		<img*/}
					{/*			src={airplane}*/}
					{/*			alt={t("Landing.Section.TopSection.img-alt.airplane")}*/}
					{/*		/>*/}
					{/*	</div>*/}
					{/*</motion.div>*/}
					<motion.div
						initial={{ opacity: 0, y: 0 }}
						animate={{ opacity: 1, y: 0 }}
						transition={{
							duration: 0.4,
							delay: 0,
						}}
					>
						<div className="gif-wrap">
							<img
								src={personImg}
								alt={t("Landing.Section.TopSection.img-alt.person")}
							/>
						</div>
					</motion.div>
					<div className="scroll-section">
						<div className="content-wrap">
							<motion.div
								initial={{ opacity: 0, y: 40 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{
									duration: 0.8,
									delay: 1,
								}}
							>
								<div className="content">
									<h3 className="title">
										{t(
											"Landing.Section.TopSection.top-section.content.title.1"
										)}
									</h3>
									<h3 className="title pre-wrap">
										{t(
											"Landing.Section.TopSection.top-section.content.title.2"
										)}
									</h3>
									<div className="btn-wrap">
										<ButtonWave
											className={"btn__solid angled"}
											onClick={() => setPromotionModal(true)}
											text={t("Landing.Section.TopSection.top-section.content.button.start_2")}
										/>
										<ButtonWave
											className={"btn__outline angled"}
											onClick={() => setIntroModal(true)}
											text={t("Landing.Section.TopSection.top-section.content.button.intro_2")}
										/>
										{/*<button*/}
										{/*	type="button"*/}
										{/*	className="btn__solid angled text-up"*/}
										{/*	onClick={() => setPromotionModal(true)}*/}
										{/*>*/}
										{/*	<span>*/}
										{/*		{t(*/}
										{/*			"Landing.Section.TopSection.top-section.content.button.start.1"*/}
										{/*		)}*/}
										{/*	</span>*/}
										{/*	<span>*/}
										{/*		{t(*/}
										{/*			"Landing.Section.TopSection.top-section.content.button.start.2"*/}
										{/*		)}*/}
										{/*	</span>*/}
										{/*	<span>*/}
										{/*		{t(*/}
										{/*			"Landing.Section.TopSection.top-section.content.button.start.3"*/}
										{/*		)}*/}
										{/*	</span>*/}
										{/*	<span>*/}
										{/*		{t(*/}
										{/*			"Landing.Section.TopSection.top-section.content.button.start.4"*/}
										{/*		)}*/}
										{/*	</span>*/}
										{/*	<span>*/}
										{/*		{t(*/}
										{/*			"Landing.Section.TopSection.top-section.content.button.start.5"*/}
										{/*		)}*/}
										{/*	</span>*/}
										{/*	<span />*/}
										{/*</button>*/}
										{/*<button*/}
										{/*	type="button"*/}
										{/*	className="btn__outline angled text-up"*/}
										{/*	onClick={() => setIntroModal(true)}*/}
										{/*>*/}
										{/*	<span>*/}
										{/*		{t(*/}
										{/*			"Landing.Section.TopSection.top-section.content.button.intro.1"*/}
										{/*		)}*/}
										{/*	</span>*/}
										{/*	<span>*/}
										{/*		{t(*/}
										{/*			"Landing.Section.TopSection.top-section.content.button.intro.2"*/}
										{/*		)}*/}
										{/*	</span>*/}
										{/*	<span>*/}
										{/*		{t(*/}
										{/*			"Landing.Section.TopSection.top-section.content.button.intro.3"*/}
										{/*		)}*/}
										{/*	</span>*/}
										{/*	<span>*/}
										{/*		{t(*/}
										{/*			"Landing.Section.TopSection.top-section.content.button.intro.4"*/}
										{/*		)}*/}
										{/*	</span>*/}
										{/*	<span>*/}
										{/*		{t(*/}
										{/*			"Landing.Section.TopSection.top-section.content.button.intro.5"*/}
										{/*		)}*/}
										{/*	</span>*/}
										{/*	<span />*/}
										{/*</button>*/}
									</div>
								</div>
							</motion.div>
						</div>
						<motion.div
							initial={{ opacity: 0, y: 35 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{
								duration: 0.6,
								delay: 0.8,
							}}
						>
							<div className="dashboard-img">
								<img
									src={i18n.language === "ko" ? topDashboard : enTopDashboard}
									alt={t("Landing.Section.TopSection.img-alt.dashboard")}
								/>
							</div>
						</motion.div>
					</div>
				</section>
			</div>

			{/*서비스 데모 모달*/}
			<ModalTemplate
				modalState={introModal}
				setModalToggle={setIntroModal}
				title={t("Landing.Section.TopSection.top-section.modal.title")}
				className="video-intro-modal"
			>
				<div className="modal-content">
					<div className="video-item">
						<iframe
							width="560"
							height="315"
							src="https://www.youtube.com/embed/NQuZCkeb7bM"
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
					</div>
				</div>
			</ModalTemplate>
		</>
	);
};

export default TopSection;
