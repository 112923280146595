import React from "react";
import NoData from "~/common/components/NoData";
import PostRow from "./PostRow";
import { useTranslation } from "react-i18next";

const MyPost = ({ myPostList, myCommunityList, showInfoAlert }) => {
	const { t } = useTranslation();
	return myPostList.length !== 0 ? (
		<>
			<div className="c__table">
				<div className="thead">
					<div className="tr">
						<div className="th name">{t("Community.MyPostPage.table.name")}</div>
						<div className="th title">{t("Community.MyPostPage.table.write")}</div>
						<div className="th num">{t("Community.MyPostPage.table.num")}</div>
						<div className="th date">{t("Community.MyPostPage.table.date1")}</div>
					</div>
				</div>
				<div className="tbody">
					{myPostList.map((myPost, index) => (
						<PostRow
							myPost={myPost}
							myCommunityList={myCommunityList}
							showInfoAlert={showInfoAlert}
							key={index}
						/>
					))}
				</div>
			</div>
		</>
	) : (
		<NoData main={"내가 쓴 글 내역이 존재하지 않습니다."} />
	);
};

export default React.memo(MyPost);
