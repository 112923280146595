import React from "react";
import { useFieldArray } from "react-hook-form";
import { RoundMinusIcon } from "~/assets/svg/InterfacesIcon";
import { PlusIcon } from "~/assets/svg/MathIcon";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import { useTranslation } from "react-i18next";

const InputRow = ({ useForm, name, remove }) => {
	const { t } = useTranslation();
	return (
		<Form useForm={useForm} className="tr">
			<Input
				placeholder={`ex ${t("Tax.Dashboard.View.ModalTemplate.deposit")}`}
				className="td plan-indicator"
				name={name("indicator")}
				type="text"
				required={null}
			/>
			<Input
				placeholder="ex 3000"
				className="td"
				name={name("now")}
				type="text"
				required={null}
			/>
			<Input
				placeholder="ex 5000"
				className="td"
				name={name("three")}
				type="text"
				required={null}
			/>
			<Input
				placeholder="ex 10000"
				className="td"
				name={name("six")}
				type="text"
				required={null}
			/>
			<Input
				placeholder="ex 100000"
				className="td"
				name={name("twelve")}
				type="text"
				required={null}
			/>
			<div className="td">
				<button type="button" className="btn-round-delete" onClick={remove}>
					<RoundMinusIcon />
				</button>
			</div>
		</Form>
	);
};

const MainPlan = ({ name, useForm }) => {
	const { t } = useTranslation();
	const { control } = useForm;

	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const ROW_INIT_DATA = {
		indicator: "",
		now: "",
		three: "",
		six: "",
		twelve: "",
	};

	return (
		<>
			<section className="outcome-plan">
				<h3 className="section-title">
					{t("IR.IrDetail.Outcome.Edit.MainPlan.title")}
					<span className="notice">{t("IR.IrDetail.Outcome.Edit.MainPlan.notice")}</span>
				</h3>
				<div className="scroll-wrap">
					<div className="outcome-plan-table">
						<div className="thead">
							<div className="tr">
								<div className="th">
									{t("IR.IrDetail.Outcome.Edit.MainPlan.th1")}
								</div>
								<div className="th">
									{t("IR.IrDetail.Outcome.Edit.MainPlan.th2")}
								</div>
								<div className="th">+3M</div>
								<div className="th">+6M</div>
								<div className="th">+12M</div>
							</div>
						</div>
						<div className="tbody">
							{fields.map((field, index) => {
								const getName = (propName) => `${name}.${index}.${propName}`;
								const removeItem = () => remove(index);
								return (
									<InputRow
										key={field.id}
										name={getName}
										remove={removeItem}
										useForm={useForm}
									/>
								);
							})}
						</div>
					</div>
				</div>
				<div className="unit-wrap">
					<button className="dashed-btn plus-btn" onClick={() => append(ROW_INIT_DATA)}>
						<PlusIcon />
						{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.addInput")}
					</button>
				</div>
			</section>
		</>
	);
};

export default MainPlan;
