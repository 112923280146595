import React from "react";

//Assets
import "~/assets/sass/pages/hr/modal/common/edit/personal.scss";
import Address from "~/common/components/Address";
import Form, { Group } from "~/common/components/Form";
import Input from "~/common/components/Input";
import NumberInput from "~/common/components/NumberInput";
import Select, { optionsGenerator } from "~/common/components/Select";
import { BANK_METADATA } from "~/common/constants/bank";
import { useTranslation } from "react-i18next";

const PersonalEdit = ({ useForm, isHrTeamMember = true }) => {
	const { t } = useTranslation();
	return (
		<section className="teamInfo-personal edit">
			<p className="section-title">
				{t("HR.TeamInfo.modal.MemberDetailModal.components.Personal.section-title")}
			</p>
			<Form useForm={useForm}>
				<Input
					label={t("HR.TeamInfo.modal.MemberDetailModal.components.Personal.email")}
					name="UserProfile.id"
					disabled
				/>
				<Input
					label={t("HR.TeamInfo.modal.MemberDetailModal.components.Personal.name")}
					name="UserProfile.name"
					required
				/>
				<NumberInput
					label={t("HR.TeamInfo.modal.MemberDetailModal.components.Personal.phone")}
					name="UserProfile.phone"
					type="phone"
				/>
				{isHrTeamMember && (
					<>
						<Address
							label={t(
								"HR.TeamInfo.modal.MemberDetailModal.components.Personal.address1"
							)}
							name={{
								postCode: "UserProfile.zipCode",
								address1: "UserProfile.address",
								address2: "UserProfile.addressDetail",
							}}
						/>
						<Group
							label={t(
								"HR.TeamInfo.modal.MemberDetailModal.components.Personal.account"
							)}
							className="account-input-group"
						>
							<Select
								className="bank-list"
								name="UserProfile.bank"
								options={optionsGenerator(BANK_METADATA, "name")}
							/>
							<Input
								name="UserProfile.account"
								placeholder={t(
									"HR.TeamInfo.modal.MemberDetailModal.components.Personal.accountNum"
								)}
							/>
							{/* TODO: 구현되지 않은 기능 */}
							{/*<button className="btn__solid btn__verification" color="primary">
						계좌 실명인증
					</button>*/}
						</Group>
						<NumberInput
							label={t(
								"HR.TeamInfo.modal.MemberDetailModal.components.Personal.regNum"
							)}
							name="UserProfile.regNum"
							type="social"
							className="personal-num"
							// required={user.info.idx === useForm.watch("UserProfile.idx")}
						/>
					</>
				)}
			</Form>
		</section>
	);
};

export default PersonalEdit;
