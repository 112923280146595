import React, { useEffect, useState } from "react";
import { LightPlusIcon, PlusIcon } from "~/assets/svg/MathIcon";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import { useGlobalStore } from "~/store";
import { useFieldArray, useForm } from "react-hook-form";
import Card from "~/pages/ir/IrDetail/Member/Edit/Card";
import "~/assets/sass/pages/ir/member.scss";
import useRequest from "~/common/hooks/useRequest";
import Modal from "~/pages/ir/IrDetail/Member/Edit/Modal";
import useAlert from "~/common/hooks/useAlert";
import { API_STATE } from "~/common/constants/state";
import { useTranslation } from "react-i18next";

const Edit = ({ Header, showViewPage }) => {
	const { t } = useTranslation();
	const [
		{
			ir: { selectedIdx: irIdx, member: Member },
		},
	] = useGlobalStore();

	const [getTeamModal, setGetTeamModal] = useState(false);

	const memberForm = useForm({
		defaultValues: { irIdx, Member },
	});

	const { fields, append, remove } = useFieldArray({
		control: memberForm.control,
		name: "Member",
	});

	const irMutation = useRequest("post", "ir/member/edit");

	const submit = async (data) => {
		const DedupedData = {
			...data,
			Member: data.Member.reduce((accumulator, current) => {
				if (
					accumulator.findIndex(
						(item) =>
							item.name === current.name &&
							item.department === current.department &&
							item.position === current.position
					) === -1
				) {
					accumulator.push(current);
				}
				return accumulator;
			}, []),
		};
		irMutation.call(DedupedData);
	};

	const CARD_INIT_DATA = {
		img: "",
		name: "",
		position: "",
		department: "",
		introduction: "",
	};

	const [showCancelAlert, CancelAlert] = useAlert();
	const [showWarningAlert, WarningAlert] = useAlert();

	useEffect(() => {
		switch (irMutation.state) {
			case API_STATE.done:
				showViewPage();
				break;
			case API_STATE.error:
				console.error("error");
		}
	}, [irMutation.state]);

	const buttonList = [
		<button type="button" className="dashed-btn plus-btn" onClick={() => setGetTeamModal(true)}>
			<PlusIcon />
			{t("Company.Member.Edit.getTeamModal")}
		</button>,
		<button
			type="button"
			className="btn__solid"
			color="gray"
			onClick={() => {
				showCancelAlert().then(() => showViewPage());
			}}
		>
			{t("button.cancel")}
		</button>,
		<button
			type="button"
			className="btn__solid"
			color="primary"
			onClick={() => showWarningAlert().then(memberForm.handleSubmit(submit))}
		>
			{t("button.save")}
		</button>,
	];
	return (
		<>
			<Header buttonList={buttonList} />
			<div className="member-edit-head">
				<div className="section-title">{t("IR.IrDetail.Member.Index.member")}</div>
			</div>
			<div className="c__cardRow member-edit">
				{fields.map((field, index) => {
					const getName = (name) => `Member.${index}.${name}`;
					const removeItem = () => remove(index);
					return (
						<Card
							key={field.id}
							name={getName}
							append={append}
							remove={removeItem}
							useForm={memberForm}
						/>
					);
				})}
				<div onClick={() => append(CARD_INIT_DATA)} className="c__card member-add-card">
					<LightPlusIcon />
				</div>
			</div>
			<ModalTemplate
				modalState={getTeamModal}
				setModalToggle={setGetTeamModal}
				title={t("Company.Member.Edit.getTeamModal")}
				width={680}
				className="getTeamInfo-modal"
			>
				<Modal
					importUser={(member) => {
						append(member);
					}}
					{...{ fields }}
				/>
			</ModalTemplate>
			<CancelAlert
				message={t("Company.Member.Edit.CancelAlert")}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
			<WarningAlert
				type={"warning"}
				confirm={t("button.yes")}
				reject={t("button.No")}
				message={<h3>{t("Company.Member.Edit.WarningAlert")}</h3>}
			/>
		</>
	);
};
export default Edit;
