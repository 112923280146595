import React from "react";
import logo from "~/assets/img/logo.svg";
import { NavDotIcon } from "~/assets/svg/InterfacesIcon";
import { useNavigate } from "react-router-dom";

const JoinPageHead = ({ currentStep }) => {
	const navigate = useNavigate();
	const navDots = ["policy", "bizType", "joinForm", "mailSend", "confirm"];

	return (
		<div className="card-header">
			<div className="logo" onClick={() => navigate("/login")}>
				<img src={logo} alt="로고이미지" />
			</div>
			<ol className="nav-dots">
				{navDots.map((nav, i) => {
					const isActive = currentStep === nav ? "active" : null;
					return (
						<li key={i} className={isActive}>
							<NavDotIcon />
						</li>
					);
				})}
			</ol>
		</div>
	);
};

export default JoinPageHead;
