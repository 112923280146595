import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useRequest from "~/common/hooks/useRequest";
import AuthenticationLayout from "~/layouts/AuthenticationLayout";
import "~/assets/sass/pages/auth/join.scss";
import JoinPageHead from "~/pages/auth/JoinPage/components/JoinPageHead";
import { API_STATE } from "~/common/constants/state";
import * as qs from "query-string";
import { useTranslation } from "react-i18next";

const JoinConfirm = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const currentPath = "join";
	const currentStep = "confirm";
	const registerConfirmRequest = useRequest("post", "user/register/confirm");
	const [token] = useState(qs.parse(location.search).token);

	useEffect(() => {
		if (!token) return;
		registerConfirmRequest.singleCall({ Authorization: `Bearer ${token}` }, "header");
	}, [token, registerConfirmRequest]);

	useEffect(() => {
		switch (registerConfirmRequest.state) {
			case API_STATE.done:
				setLoading(true);
				break;
			case API_STATE.error:
				alert(t("Auth.Join.Confirm.alert"));
				break;
			default:
				break;
		}
	}, [registerConfirmRequest.state, setLoading]);

	return (
		<AuthenticationLayout contentSide={false} PATH={currentPath}>
			{loading && (
				<section className="section-complete">
					<JoinPageHead currentStep={currentStep} />
					<div className="phrase-wrap">
						<h3>Congratulations!</h3>
						<p>{t("Auth.Join.Confirm.phrase-wrap.p")}</p>
					</div>
					<div className="btn-wrap">
						<button
							className="btn__solid"
							color="primary"
							onClick={() => navigate("/login")}
						>
							{t("button.login")}
						</button>
					</div>
				</section>
			)}
		</AuthenticationLayout>
	);
};

export default JoinConfirm;
