import React, { useEffect, useState } from "react";
import Form, { Group } from "~/common/components/Form";
import { useForm } from "react-hook-form";
import DatePicker from "~/common/components/DatePicker";
import Radio from "~/common/components/Radio";
import translation from "~/translations";

//assets
import "~/assets/sass/pages/mypage/admin/paymentPage/list.scss";
import Pagination from "~/common/components/Pagination";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import { useNavigate } from "react-router";
import { dateToString } from "~/utils/formatter";
import List from "~/pages/mypage/admin/PaymentPage/historyList/List";
import { useTranslation } from "react-i18next";

const HistoryList = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const paymentForm = useForm({
		defaultValues: {
			startDate: new Date().setFullYear(new Date().getFullYear() - 1),
			endDate: new Date(),
		},
	});

	const [historyList, setHistoryList] = useState([]);
	const [totalPage, setTotalPage] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);

	const formQuarter = paymentForm.watch("quarter");
	const currentStartDate = paymentForm.watch("startDate");
	const currentEndDate = paymentForm.watch("endDate");

	const purchaseHistoryListRequest = useRequest("get", `/admin/purchase/history`);
	const pageSize = 10;
	const quarterOptions = [
		{ label: translation.t("MyPage.admin.Payment.historyList.Q1"), value: "Q1" },
		{ label: translation.t("MyPage.admin.Payment.historyList.Q2"), value: "Q2" },
		{ label: translation.t("MyPage.admin.Payment.historyList.Q3"), value: "Q3" },
		{ label: translation.t("MyPage.admin.Payment.historyList.Q4"), value: "Q4" },
		{ label: translation.t("MyPage.admin.Payment.historyList.firstHalf"), value: "firstHalf" },
		{
			label: translation.t("MyPage.admin.Payment.historyList.secondHalf"),
			value: "secondHalf",
		},
	];

	useEffect(() => {
		switch (purchaseHistoryListRequest.state) {
			case API_STATE.done:
				const response = purchaseHistoryListRequest.response.data;

				setHistoryList(response.histories[0]);
				setTotalPage(Number(response.pagination.totalPage));
				setPageNumber(Number(response.pagination.page));
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [purchaseHistoryListRequest.state]);

	const getHistoryList = () => {
		purchaseHistoryListRequest.call(
			{
				startDate: dateToString(currentStartDate, "yy-MM-dd"),
				endDate: dateToString(currentEndDate, "yy-MM-dd 23:59:59"),
				pageSize: pageSize,
				page: pageNumber,
			},
			"queryString"
		);
	};

	useEffect(() => {
		getHistoryList();
	}, [pageNumber]);

	const submit = () => {
		getHistoryList();
	};

	const clickRadio = (e) => {
		const selectedQuarter = e.target.value;

		if (formQuarter === selectedQuarter) {
			paymentForm.setValue("quarter", "");
		} else {
			const quarters = ["Q1", "Q2", "Q3", "Q4"];
			const halfQuarters = ["firstHalf", "secondHalf"];
			let startDate = "";
			let endDate = "";

			const currentYear = new Date().getFullYear();
			if (quarters.includes(selectedQuarter)) {
				startDate = new Date(currentYear, quarters.indexOf(selectedQuarter) * 3, 1);
				endDate = new Date(currentYear, (quarters.indexOf(selectedQuarter) + 1) * 3, 0);
			} else if (halfQuarters.includes(selectedQuarter)) {
				startDate = new Date(currentYear, halfQuarters.indexOf(selectedQuarter) * 6, 1);
				endDate = new Date(currentYear, (halfQuarters.indexOf(selectedQuarter) + 1) * 6, 0);
			}
			paymentForm.setValue("startDate", startDate);
			paymentForm.setValue("endDate", endDate);
		}
	};

	const resetQuarter = () => {
		paymentForm.setValue("quarter", "");
	};

	return (
		<>
			<div className="c__card payment-search-card">
				<Form useForm={paymentForm} className="payment-form">
					<Group
						className="datePicker-group"
						label={t("MyPage.admin.Payment.historyList.label")}
					>
						<DatePicker
							name="startDate"
							onChange={resetQuarter}
							maxDate={currentEndDate}
						/>
						<span className="dash"> - </span>
						<DatePicker
							name="endDate"
							onChange={resetQuarter}
							minDate={currentStartDate}
						/>
					</Group>
					<Radio
						name="quarter"
						options={quarterOptions}
						handleClick={clickRadio}
						boolean
					/>
				</Form>
				<button
					type="button"
					className="btn__solid btn-search"
					color="primary"
					onClick={paymentForm.handleSubmit(submit)}
				>
					{t("MyPage.admin.Payment.historyList.search")}
				</button>
			</div>
			<div className="c__card payment-list-card">
				<div className="payment-list-table">
					<div className="c__table">
						<div className="thead">
							<div className="tr">
								<div className="th title">
									{t("MyPage.admin.Payment.historyList.title")}
								</div>
								<div className="th sum">
									{t("MyPage.admin.Payment.historyList.sum")}
								</div>
								<div className="th state">
									{t("MyPage.admin.Payment.historyList.state")}
								</div>
								<div className="th date">
									{t("MyPage.admin.Payment.historyList.date")}
								</div>
							</div>
						</div>
						<List {...{ historyList }} />
					</div>
				</div>
				<Pagination
					setPageNumber={setPageNumber}
					currentPage={pageNumber}
					totalPage={totalPage}
				/>
			</div>
		</>
	);
};

export default HistoryList;
