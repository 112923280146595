import React from "react";

//얇은 플러스 아이콘
export const LightPlusIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
			<path
				d="M29.512 15.5h-14v14a.5.5 0 0 1-1 0v-14h-14a.5.5 0 0 1 0-1h14V.5a.5.5 0 0 1 1 0v14h14a.5.5 0 0 1 0 1z"
				transform="translate(-.016 -.002)"
			/>
		</svg>
	);
};

//기본 플러스 아이콘
export const PlusIcon = () => {
	return (
		<svg width="11px" height="11px" viewBox={" 0 0 11 11"}>
			<path d="M10.450,6.049 L6.051,6.049 L6.051,10.448 C6.051,10.752 5.804,10.999 5.501,10.999 C5.197,10.999 4.950,10.752 4.950,10.448 L4.950,6.049 L0.551,6.049 C0.246,6.049 0.000,5.803 0.000,5.499 C0.000,5.196 0.246,4.949 0.551,4.949 L4.950,4.949 L4.950,0.549 C4.950,0.245 5.197,-0.001 5.501,-0.001 C5.804,-0.001 6.051,0.245 6.051,0.549 L6.051,4.949 L10.450,4.949 C10.754,4.949 11.001,5.196 11.001,5.499 C11.001,5.803 10.754,6.049 10.450,6.049 Z" />
		</svg>
	);
};

// 연결 아이콘
export const ConnectIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="17"
			viewBox="0 0 32 17"
			className="ico__connect undefined"
		>
			<path
				id="prefix__패스_393"
				transform="translate(-313.937 -435.263)"
				d="M337.61 452.263a8.222 8.222 0 0 1-5.5-2.113.677.677 0 0 1-.059-.942.645.645 0 0 1 .923-.061 6.929 6.929 0 0 0 4.632 1.781 7.166 7.166 0 0 0 0-14.329c-4.025 0-6.388 2.441-7.025 7.254v.01a10.882 10.882 0 0 1-2.7 6.112 7.6 7.6 0 0 1-5.623 2.289 8.5 8.5 0 0 1 0-17 8.168 8.168 0 0 1 4.656 1.452.676.676 0 0 1 .176.927.646.646 0 0 1-.908.179 6.882 6.882 0 0 0-3.924-1.223 7.166 7.166 0 0 0 0 14.329c3.839 0 6.333-2.578 7.025-7.259a10.6 10.6 0 0 1 2.7-6.28 7.643 7.643 0 0 1 5.621-2.125 8.5 8.5 0 0 1 0 17z"
			/>
		</svg>
	);
};
