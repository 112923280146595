import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import AuthenticationLayout from "~/layouts/AuthenticationLayout";

// Assets
import "~/assets/sass/pages/auth/findPw.scss";
import logo from "~/assets/img/logo.svg";
import { Case, If } from "~/common/components/If";
import PwChangeForm from "~/pages/auth/FindPwPage/Change/PwChangeForm";
import PwChangeComplete from "~/pages/auth/FindPwPage/Change/PwChangeComplete";

const FindPwPage = () => {
	const { t } = useTranslation();
	const currentPath = "findPw";
	const [currentStep, setCurrentStep] = useState("change");

	return (
		<AuthenticationLayout contentSide={false} PATH={currentPath}>
			<section className={`section-` + currentStep}>
				<div className="card-header">
					<div className="logo">
						<img src={logo} alt={t("Auth.Login.image-alt")} />
					</div>
				</div>
				<If condition={currentStep}>
					<Case condition={"change"}>
						<PwChangeForm {...{ setCurrentStep }} />
					</Case>
					<Case condition={"complete"}>
						<PwChangeComplete />
					</Case>
				</If>
			</section>
		</AuthenticationLayout>
	);
};

export default FindPwPage;
