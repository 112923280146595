/**
 * @param callback
 * @param delay
 * @returns {*}
 */
const UseDebounce = (callback, delay) => {
	let timer;

	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => callback(...args), delay);
	};
};

export default UseDebounce;
