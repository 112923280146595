import React from "react";
import { useTranslation } from "react-i18next";
import NewLandingLayout from "~/layouts/LandingLayout/index";
import { DocIcon, PeopleStarIcon, BagIcon, DollarIcon } from "~/assets/svg/WhyUsIcon";
import { ManHoldCup, RectangleGreen, RectangleGreenRight, GlobalDarkIcon, BagDarkIcon, NoteDarkIcon, ScaleDarkIcon, MoneyNoteDarkIcon } from "~/assets/svg/EorIcon";
import { ArrowRightFull } from "~/assets/svg/LandingIcon";
import { TickIcon } from "~/assets/svg/LandingIcon";
import EmpowerGlobal from "~/common/components/EmpowerGlobal";
import SlideHorizontal from "~/common/components/SlideHorizontal";
import dashboard from "~/assets/img/persona/eorPage/Dashboard.png";
import dashboardResponsive from "~/assets/img/persona/eorPage/Dashboard-responsive.png";
import notification from "~/assets/img/persona/eorPage/Notification.png";
import notification2 from "~/assets/img/persona/eorPage/Notification2.png";
import shakehand from "~/assets/img/persona/eorPage/Shakehand.png";
import shakehandResponsive from "~/assets/img/persona/eorPage/Shakehand-responsive.png";

export default function EORPage() {
	const { t } = useTranslation();
	const HrTasks = [
		t("Eor.hr-tasks.1"),
		t("Eor.hr-tasks.2"),
		t("Eor.hr-tasks.3"),
		t("Eor.hr-tasks.4"),
		t("Eor.hr-tasks.5"),
		t("Eor.hr-tasks.6"),
	];
	const benefits = [
		{
   icon: <GlobalDarkIcon/>,
			title: t("Eor.benefits.items.1.title"),
			description: t("Eor.benefits.items.1.description"),
			color: "bg-green-50",
		},
		{
   icon: <BagDarkIcon/>,
			title: t("Eor.benefits.items.2.title"),
			description: t("Eor.benefits.items.2.description"),
			color: "bg-violet-50",
		},
		{
   icon: <NoteDarkIcon/>,
			title: t("Eor.benefits.items.3.title"),
			description: t("Eor.benefits.items.3.description"),
			color: "bg-orange-50",
		},
	];
	const benefits2 = [
		{
   icon: <ScaleDarkIcon/>,
			title: t("Eor.benefits.items.4.title"),
			description: t("Eor.benefits.items.4.description"),
			color: "bg-red-50",
		},
		{
   icon: <MoneyNoteDarkIcon/>,
			title: t("Eor.benefits.items.5.title"),
			description: t("Eor.benefits.items.5.description"),
			color: "bg-emerald-50",
		},
	];
	return (
		<NewLandingLayout>
			<section>
				<main className="h-full w-full max-w-[1920px] mx-auto">
					<section className="bg-[#DEEDF0]">
						<div className="w-[95%] sm:w-[85%] m-auto pt-48 md:flex block justify-between">
							<div className="lg:flex block justify-between w-full">
								<div className="mt-[-50px] md:mb-0 mb-16">
									<div className="text-[#0D9488] font-semibold">
										{t("Eor.header-green")}
									</div>
									<div className="sm:text-4xl text-3xl font-semibold w-full">
										{t("Eor.header")}
									</div>
									<div className="pt-4 m lg:mb-8 sm:mb-0">
										{t("Eor.sub-header")}
									</div>
								</div>
								<div className="flex justify-center">
									<div className="">
										<ManHoldCup />
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="w-[95%] sm:w-[85%] m-auto">
						<div className="md:py-32 py-16">
							<div className="text-[#0D9488] font-semibold text-center">
								{t("Eor.global-hiring.header-green")}
							</div>
							<div className="sm:text-4xl text-3xl font-semibold text-center">
								{t("Eor.global-hiring.header")}
							</div>
							<SlideHorizontal
								props={[
									{
										icon: <DocIcon />,
										title: t("Eor.global-hiring.1.title"),
										description: t("Eor.global-hiring.1.description"),
									},
									{
										icon: <PeopleStarIcon />,
										title: t("Eor.global-hiring.2.title"),
										description: t("Eor.global-hiring.2.description"),
									},
									{
										icon: <BagIcon />,
										title: t("Eor.global-hiring.3.title"),
										description: t("Eor.global-hiring.3.description"),
									},
									{
										icon: <DollarIcon />,
										title: t("Eor.global-hiring.4.title"),
										description: t("Eor.global-hiring.4.description"),
									},
									{
										icon: <DocIcon />,
										title: t("Eor.global-hiring.5.title"),
										description: t("Eor.global-hiring.5.description"),
									},
								]}
							/>
						</div>
					</section>
					<section className="bg-[#042F2E] text-white pt-8 relative max-w-[1920px] m-auto text-center z-[-20]">
						<div className="mx-auto max-w-[1920px] w-[95%] sm:w-[85%]">
							<div className="relative mt-24 pb-24">
								<div className="absolute top-0 left-0 z-[-10]">
									<RectangleGreen />
								</div>
								<div className="font-semibold">{t("Eor.payroll.header")}</div>
								<div className="sm:text-4xl text-3xl font-semibold">
									{t("Eor.payroll.title")}
								</div>
								<div className="md:w-[50%] w-full m-auto pt-4 pb-8">
									{t("Eor.payroll.description")}
								</div>
								<div className="mx-auto block flex justify-center w-full">
									<div className="relative">
										<div>
											<img
												src={dashboard}
												alt=""
												className="md:block hidden"
											/>
										</div>
										<div className="absolute top-[330px] left-[-120px] md:block hidden">
											<img src={notification} alt="" />
										</div>
										<div className="absolute top-[140px] right-[-102px] md:block hidden">
											<img src={notification2} alt="" />
										</div>
										<div className="md:hidden block">
											<img src={dashboardResponsive} alt="" />
										</div>
									</div>
								</div>
								<div className="absolute bottom-0 right-0 z-[-10]">
									<RectangleGreenRight />
								</div>
							</div>
						</div>
					</section>
					<section className="w-[95%] 2xl:w-[60%] lg:w-[85%] m-auto">
						<div className="flex lg:flex-row flex-col-reverse lg:items-center lg:justify-between py-24">
							<div>
								<div className="sm:text-4xl text-3xl font-semibold lg:text-left text-center">
									{t("Eor.hr-tasks.header")}
								</div>
								<div>
									{HrTasks.map((item, index) => (
										<div key={index} className="flex items-center gap-2 py-2">
											<TickIcon />
											{item}
										</div>
									))}
								</div>
								<div className="mt-4 lg:block flex justify-center">
									<div className="cursor-pointer text-green-950 flex gap-2 underline underline-offset-8 font-semibold">
										{t("WhyUs.solutions.learn-more")} <ArrowRightFull />
									</div>
								</div>
							</div>
							<div>
								<img
									src={shakehand}
									className="md:block md:mx-auto mx-0 hidden"
									alt=""
								/>
								<img
									src={shakehandResponsive}
									className="md:hidden md:mx-0 mx-auto block"
									alt=""
								/>
							</div>
						</div>
					</section>
					<section className="w-[95%] lg:w-[85%] m-auto">
      <div className="text-center">
       <div className="text-[#0D9488] font-semibold">{t('Eor.benefits.header-green')}</div>
       <div className="sm:text-4xl text-3xl font-semibold w-full">{t('Eor.benefits.title')}</div>
      </div>
						<div className="md:flex block justify-between md:my-12 mt-8">
							{benefits.map((item, index) => (
								<div className={`${item.color} p-8 rounded-bl-2xl rounded-tr-2xl md:w-[31%] w-full md:mb-0 mb-4`} key={index}>
         <div className="mb-2">{item.icon}</div>
									<div className="text-lg font-semibold">{item.title}</div>
									<div>{item.description}</div>
								</div>
							))}
						</div>
						<div className="md:flex block justify-between md:w-[70%] w-full m-auto mb-16">
							{benefits2.map((item, index) => (
								<div className={`${item.color} p-8 rounded-bl-2xl rounded-tr-2xl md:w-[45%] w-full md:mb-0 mb-4`} key={index}>
         <div className="mb-2">{item.icon}</div>
         <div className="text-lg font-semibold">{item.title}</div>
									<div>{item.description}</div>
								</div>
							))}
						</div>
					</section>
					<EmpowerGlobal />
				</main>
			</section>
		</NewLandingLayout>
	);
}
