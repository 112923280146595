import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Case, If } from "~/common/components/If";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import PageHeader from "~/common/components/PageHeader";
import TabNav from "~/common/components/TabNav";
import View from "~/pages/hr/HrSettingPage/PayDay/View";
import Edit from "~/pages/hr/HrSettingPage/PayDay/Edit";
import useRuquest from "~/common/hooks/useRequest";
import { useNavigate } from "react-router";

const PayDay = () => {
	const navigate = useNavigate();
	const { hrSettingTabList, menu, type } = useOutletContext();
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [payday, setPayday] = useState();
	const paydayRequest = useRuquest("get", "admin/payday");

	useEffect(() => {
		paydayRequest.singleCall();
	}, []);

	useEffect(() => {
		switch (paydayRequest.state) {
			case API_STATE.done:
				if (!paydayRequest.response) return;
				const responseData = paydayRequest.response.data;

				setPayday({
					lastDay: responseData.lastDay,
					payCycle: responseData.payCycle,
					payDay: responseData.lastDay ? "말" : responseData.payDay,
				});

				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error", paydayRequest.error);
				navigate("/404");
				break;
		}
	}, [paydayRequest.state]);

	const Header = ({ buttonList = [] }) => {
		return (
			<>
				<PageHeader menu={menu} type={type} buttonList={buttonList} />
				<TabNav tabList={hrSettingTabList} currentTab={"payday"} />
			</>
		);
	};

	const showViewPage = () => {
		paydayRequest.call();
	};

	const showEditPage = () => {
		setPageState(PAGE_STATE.EDIT);
	};

	return (
		<>
			<If condition={pageState} {...{ payday }}>
				<Case condition={PAGE_STATE.VIEW}>
					<View {...{ Header, showEditPage }} />
				</Case>
				<Case condition={PAGE_STATE.EDIT}>
					<Edit {...{ Header, showViewPage }} />
				</Case>
				<Case condition={PAGE_STATE.LOADING}>
					<Loading />
				</Case>
			</If>
		</>
	);
};

export default PayDay;
