import React, { useMemo } from "react";
import { useGlobalStore } from "~/store";
import { thousandFormatter } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const FinancialPosition = ({}) => {
	const { t } = useTranslation();
	const [
		{
			ir: {
				finance: {
					quickAsset,
					inventories,
					otherCurrentAsset,

					investment,
					tangibleAsset,
					inTangibleAsset,
					otherNonCurrentAsset,
				},
			},
		},
	] = useGlobalStore();

	const CurrentAssets = useMemo(
		() => +quickAsset + +inventories + +otherCurrentAsset,
		[quickAsset, inventories, otherCurrentAsset]
	);
	const NonCurrentAssets = useMemo(
		() => +investment + +tangibleAsset + +inTangibleAsset + +otherNonCurrentAsset,
		[investment, tangibleAsset, inTangibleAsset, otherNonCurrentAsset]
	);
	const TotalAssets = useMemo(
		() => CurrentAssets + NonCurrentAssets,
		[CurrentAssets, NonCurrentAssets]
	);

	return (
		<div className="f__group sub-section-table">
			<div className="unit-wrap sub-title">
				{t("IR.IrDetail.Finance.Edit.Positions.subTitle1")}
				<span className="unit">
					{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
					{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
				</span>
			</div>
			<div className="f__row">
				<div className="f__column">
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section1.title")}
						</div>
						<div className="f__control">{thousandFormatter(CurrentAssets)}</div>
					</div>
				</div>
				<div className="f__field">
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section1.label1")}
						</div>
						<div className="f__control">{thousandFormatter(quickAsset)}</div>
					</div>
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section1.label2")}
						</div>
						<div className="f__control">{thousandFormatter(inventories)}</div>
					</div>
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section1.label3")}
						</div>
						<div className="f__control">{thousandFormatter(otherCurrentAsset)}</div>
					</div>
				</div>
			</div>
			<div className="f__row">
				<div className="f__column">
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section2.title")}
						</div>
						<div className="f__control">{thousandFormatter(NonCurrentAssets)}</div>
					</div>
				</div>
				<div className="f__field">
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section2.label1")}
						</div>
						<div className="f__control">{thousandFormatter(investment)}</div>
					</div>
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section2.label2")}
						</div>
						<div className="f__control">{thousandFormatter(tangibleAsset)}</div>
					</div>
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section2.label3")}
						</div>
						<div className="f__control">{thousandFormatter(inTangibleAsset)}</div>
					</div>
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section2.label4")}
						</div>
						<div className="f__control">{thousandFormatter(otherNonCurrentAsset)}</div>
					</div>
				</div>
			</div>
			<div className="total">
				<div className="total-label">{t("IR.IrDetail.Finance.Edit.Positions.total1")}</div>
				<div className="f__control">{thousandFormatter(TotalAssets)}</div>
			</div>
			<dl className="alert-content">
				<div>
					<dt>{t("IR.IrDetail.Finance.Edit.Positions.total1")}</dt>
					<dd>
						{t("IR.IrDetail.Finance.Edit.Positions.section1.title")} +{" "}
						{t("IR.IrDetail.Finance.Edit.Positions.section2.title")}
					</dd>
				</div>
				<div>
					<dt>{t("IR.IrDetail.Finance.Edit.Positions.section1.title")}</dt>
					<dd>
						{t("IR.IrDetail.Finance.Edit.Positions.section1.label1")} +{" "}
						{t("IR.IrDetail.Finance.Edit.Positions.section1.label2")} +{" "}
						{t("IR.IrDetail.Finance.Edit.Positions.section1.label3")}
					</dd>
				</div>
				<div>
					<dt>{t("IR.IrDetail.Finance.Edit.Positions.section2.title")}</dt>
					<dd>
						{t("IR.IrDetail.Finance.Edit.Positions.section2.label1")} +{" "}
						{t("IR.IrDetail.Finance.Edit.Positions.section2.label2")} +{" "}
						{t("IR.IrDetail.Finance.Edit.Positions.section2.label3")} +{" "}
						{t("IR.IrDetail.Finance.Edit.Positions.section2.label4")}
					</dd>
				</div>
			</dl>
		</div>
	);
};

export default FinancialPosition;
