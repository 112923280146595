import React from "react";

// Assets
import "~/assets/sass/pages/shareholder/empty.scss";
import noImg from "~/assets/img/defaultImg/default-img.png";
import { CautionIcon } from "~/assets/svg/InterfacesIcon";
import { Trans, useTranslation } from "react-i18next";

const Empty = () => {
	const { t } = useTranslation();
	return (
		<>
			<div className="pageIntro">
				<CautionIcon />
				<div className="content">
					<h3>
						<Trans
							i18nKey="Invest.Evaluate.EvaluateDetail.Empty.content.h3"
							components={[<b />]}
						/>
					</h3>
					<p>{t("Invest.Evaluate.EvaluateDetail.Empty.content.p")}</p>
				</div>
			</div>
			<div className="c__card no-content">
				<div className="no-img">
					<img src={noImg} alt={t("Invest.Evaluate.EvaluateDetail.Empty.no-img")} />
				</div>
				<p className="card-content">
					<Trans
						i18nKey="Invest.Evaluate.EvaluateDetail.Empty.card-content"
						components={[<b />]}
					/>
				</p>
			</div>
		</>
	);
};
export default Empty;
