import React from "react";
import logoImg from "~/assets/img/defaultImg/logo-square.png";
import staffImg from "~/assets/img/defaultImg/profile-large.png";
import DropdownTemplate from "~/common/components/DropdownPortal/DropdownTemplate";
import { Trans, useTranslation } from "react-i18next";

const Inquiry = () => {
	const { t } = useTranslation();
	// const [roundInquiryDropdown, setRoundInquiryDropdown] = useState(false);
	return (
		<>
			<div className="inquiry-tab">
				<div className="qna-register-header">
					<h5>
						{t(
							"Invest.Round.RoundDetailModal.View.component.inquiry.qna.register-header"
						)}
					</h5>
					<span className="qna-register-number">
						<Trans
							i18nKey="Invest.Round.RoundDetailModal.View.component.inquiry.qna.register-number"
							components={[<strong />]}
						/>
					</span>
				</div>
				<div className="qna-register-content">
					<div className="qna-upload">
						<div className="admin-picture">
							<img src={logoImg} alt="" />
						</div>
						<div className="qna-upload-box">
							<input
								className="f__input"
								placeholder={t(
									"Invest.Round.RoundDetailModal.View.component.inquiry.qna.input-placeholder"
								)}
							/>
							<button type="button" className="btn__solid" color="primary">
								{t("button.register")}
							</button>
						</div>
					</div>
					<div className="register-user">
						<div className="user-box">
							<div className="user-picture">
								<img src={staffImg} alt="" />
							</div>
							<div className="register-question">
								<div className="register-question-header">
									<div className="left-box">
										<div className="register-user-name">
											{t(
												"Invest.Round.RoundDetailModal.View.component.inquiry.qna.register-user-name"
											)}
										</div>
										<div className="answer-complete">
											{t(
												"Invest.Round.RoundDetailModal.View.component.inquiry.qna.answer-complete"
											)}
										</div>
									</div>
									<div className="right-box">
										<span className="qna-register-date">2020-08-25</span>
										<DropdownTemplate target={1}>
											<li className="c__dropdownItem">
												{t("button.answer")}
											</li>
											<li className="c__dropdownItem">
												{t("button.delete")}
											</li>
										</DropdownTemplate>
									</div>
								</div>
								<p className="user-question-content">
									{t(
										"Invest.Round.RoundDetailModal.View.component.inquiry.qna.user-question-content"
									)}
								</p>
							</div>
						</div>
						<div className="admin-box">
							<div className="admin-picture">
								<img src={logoImg} alt="" />
							</div>
							<div className="register-answer">
								<div className="admin-answer-header">
									<div className="register-admin">
										{t(
											"Invest.Round.RoundDetailModal.View.component.inquiry.qna.register-admin"
										)}
									</div>
									<DropdownTemplate target={2} className={"dropdown-btn"}>
										<li className="c__dropdownItem">{t("button.answer")}</li>
										<li className="c__dropdownItem">{t("button.delete")}</li>
									</DropdownTemplate>
								</div>
								<p className="admin-answer-content">
									{t(
										"Invest.Round.RoundDetailModal.View.component.inquiry.qna.admin-answer-content"
									)}
								</p>
							</div>
						</div>
						<div className="user-box">
							<div className="user-picture">
								<img src={staffImg} alt="" />
							</div>
							<div className="register-question">
								<div className="register-question-header">
									<div className="left-box">
										<div className="register-user-name">
											{t(
												"Invest.Round.RoundDetailModal.View.component.inquiry.qna.register-user-name"
											)}
										</div>
										<div className="answer-ready">
											{t(
												"Invest.Round.RoundDetailModal.View.component.inquiry.qna.answer-ready"
											)}
										</div>
									</div>
									<div className="right-box">
										<span className="qna-register-date">2020-07-25</span>
										<DropdownTemplate target={3}>
											<li className="c__dropdownItem">
												{t("button.answer")}
											</li>
											<li className="c__dropdownItem">
												{t("button.delete")}
											</li>
										</DropdownTemplate>
									</div>
								</div>
								<p className="user-question-content">
									{t(
										"Invest.Round.RoundDetailModal.View.component.inquiry.qna.user-question-content"
									)}
								</p>
							</div>
						</div>
						<button type="button" className="read-more-btn">
							{t(
								"Invest.Round.RoundDetailModal.View.component.inquiry.qna.read-more-btn"
							)}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Inquiry;
