import React, { useState } from "react";
import { Case, If } from "~/common/components/If";
import { PAGE_STATE } from "~/common/constants/state";
import PageHeader from "~/common/components/PageHeader";
import TabNav from "~/common/components/TabNav";
import Loading from "~/common/components/Loading";
import { useOutletContext } from "react-router-dom";
import View from "~/pages/hr/HrSettingPage/Allowance/View";
import { useTranslation } from "react-i18next";

const Allowance = () => {
	const { t } = useTranslation();
	const { hrSettingTabList, menu, type } = useOutletContext();
	const [pageState] = useState(PAGE_STATE.VIEW);

	const buttonList = [
		<button type="button" className="btn__solid" color="primary" disabled>
			{t("button.Add")}
		</button>,
	];
	return (
		<If condition={pageState}>
			<Case condition={PAGE_STATE.VIEW}>
				<PageHeader menu={menu} type={type} buttonList={buttonList} />
				<TabNav tabList={hrSettingTabList} currentTab={"allowance"} />
				<View />
			</Case>
			<Case condition={PAGE_STATE.EMPTY}>
				<div>Empty Page</div>
			</Case>
			<Case condition={PAGE_STATE.LOADING}>
				{/*<Header buttonList={buttonList} />*/}
				<Loading />
			</Case>
		</If>
	);
};

export default Allowance;
