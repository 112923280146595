import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";

//Assets
import "~/assets/sass/pages/hr/modal/common/edit/memo.scss";
import { SolidCloseIcon } from "~/assets/svg/CloseIcon";
import { PlusIcon } from "~/assets/svg/MathIcon";
import DatePicker from "~/common/components/DatePicker";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";

const Memo = ({ index, name, remove, useForm }) => {
	const { t } = useTranslation();
	return (
		<Form useForm={useForm} className="row">
			<DatePicker name={name("date")} className={"date-picker"} />
			<Input
				name={name("content")}
				placeholder={t(
					"HR.TeamInfo.modal.MemberDetailModal.components.Memo.InputPlaceholder"
				)}
				className="content"
				required={null}
			/>
			<button className="btn__close" onClick={() => remove(index)}>
				<SolidCloseIcon />
			</button>
		</Form>
	);
};

const MemoEdit = ({ useForm }) => {
	const { t } = useTranslation();
	const { control, getValues } = useForm;

	const { fields, append, remove } = useFieldArray({
		control,
		name: "UserMemo",
	});

	const [userId] = useState(getValues("UserProfile.idx"));

	const MEMO_INIT_DATA = {
		userIdx: userId,
		date: new Date().toISOString(),
		content: "",
	};

	return (
		<section className="teamInfo-memo edit">
			<div className="title-wrap">
				<p className="section-title">
					{t("HR.TeamInfo.modal.MemberDetailModal.components.Memo.section-title")}
				</p>
				<button
					className="btn__outline btn__add"
					color="primary"
					onClick={() => append(MEMO_INIT_DATA)}
				>
					<PlusIcon />
					{t("HR.TeamInfo.modal.MemberDetailModal.components.Memo.btn__add")}
				</button>
			</div>
			<div className="memo-list">
				{fields.length !== 0 ? (
					fields.map((field, index) => {
						const getName = (name) => `UserMemo.${index}.${name}`;
						const removeItem = () => remove(index);
						return (
							<Memo
								key={field.id}
								name={getName}
								remove={removeItem}
								useForm={useForm}
							/>
						);
					})
				) : (
					<NoData
						main={t("HR.TeamInfo.modal.MemberDetailModal.components.Memo.NoData")}
					/>
				)}
			</div>
		</section>
	);
};

export default MemoEdit;
