import React, { useEffect } from "react";
import Form from "~/common/components/Form";
import DatePicker from "~/common/components/DatePicker";
import { useTranslation } from "react-i18next";

const Date = ({ useForm, form, apply }) => {
	const { t } = useTranslation();
	useEffect(() => {
		if (apply) {
			useForm.setValue(form.order.toString(), form.answer);
		} else {
			useForm.setValue(form.order.toString(), null);
		}
	}, []);

	return (
		<>
			<Form useForm={useForm}>
				<DatePicker
					label={form.title}
					name={form.order.toString()}
					required={form.required}
					readOnly={apply}
					placeholderText={t(
						"Invest.Round.RoundDetailModal.Edit.component.date.DatePlaceholder"
					)}
				/>
			</Form>
		</>
	);
};

export default Date;
