import React, { useEffect } from "react";
import { useController } from "react-hook-form";

import "~/assets/sass/component/imageUploader.scss";
import { API_STATE } from "~/common/constants/state";
import useUpload from "~/common/hooks/useUpload";
import useAlert from "~/common/hooks/useAlert";
import { CameraIcon } from "~/assets/svg/InterfacesIcon";
import { BoldCloseIcon } from "~/assets/svg/CloseIcon";

const CommentUpload = ({
	type,
	name,
	control,
	height = "inherit",
	width = "200px",
	files,
	maxLength = 5,
}) => {
	const {
		field: { onChange, value },
	} = useController({
		control,
		name,
		defaultValue: files ?? [],
		rules: {
			maxLength,
		},
	});

	const { url, fileName, fileSize, upload, state } = useUpload(type);

	const [showErrorAlert, ErrorAlert] = useAlert();

	useEffect(() => {
		switch (state) {
			case API_STATE.error:
				showErrorAlert().then(() => {});
				break;
			case API_STATE.done:
				onChange(
					value.length < maxLength
						? value.concat({ name: fileName, path: url, size: fileSize })
						: value
				);
				break;
			default:
				break;
		}
	}, [state]);

	const ImageList = () => {
		if (value?.length > 0) {
			return value.map((image, idx) => {
				const handleRemove = () => onChange(value.filter((_, i) => i !== idx));
				return (
					<div
						key={idx}
						className="upload-preview"
						style={{ height: height, width: width }}
					>
						<img src={image.path} alt="제품" />
						<span className="upload-preview-actions">
							<button onClick={handleRemove}>
								<BoldCloseIcon />
							</button>
						</span>
					</div>
				);
			});
		} else {
			return <></>;
		}
	};

	const Uploader = () => {
		return (
			<>
				<span className="uploader-actions">
					<input
						type="file"
						accept="image/*"
						name="file"
						onChange={({ target }) =>
							target.files.length > 0 &&
							value.length < maxLength &&
							upload(target.files)
						}
					/>
					<CameraIcon /> Upload Image [{value.length} / {maxLength}]
				</span>
			</>
		);
	};

	/*
	const Uploading = () => {
		return <Loading size={40} />;
	};
*/

	return (
		<>
			{/* 커뮤니티 추가 컴포넌트 */}
			<div className="comment-uploader">
				<div className="uploader-list">
					<ImageList />
				</div>
				<div className="upload-button">
					<Uploader />
				</div>
				{/*<Uploading />*/}
			</div>
			<ErrorAlert
				message="이미지 업로드 중 에러가 발생했습니다."
				type="error"
				confirm="확인"
			/>
		</>
	);
};

export default CommentUpload;
