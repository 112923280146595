import React, { useEffect, useState } from "react";

// Components
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import Form, { Group } from "~/common/components/Form";
import Input from "~/common/components/Input";

// Assets
import "~/assets/sass/pages/mypage/admin/paymentPage/management.scss";
import { HideIcon, ShowIcon } from "~/svg/EyeIcon";
import { API_STATE } from "~/common/constants/state";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useRequest from "~/common/hooks/useRequest";
import useAlert from "~/common/hooks/useAlert";
import { useTranslation } from "react-i18next";

const AddPaymentModal = ({ addPaymentModal, setAddPaymentModal, getPaymentCards }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [showSecondNumber, setShowSecondNumber] = useState(false);
	const [showThirdNumber, setShowThirdNumber] = useState(false);

	const addPaymentCardRequest = useRequest("post", "/admin/purchase/option");

	const paymentModalForm = useForm();

	const [showAlert, Alert] = useAlert();
	const [addPaymentMessage, setAddPaymentMessage] = useState("");

	useEffect(() => {
		if (!addPaymentModal) {
			paymentModalForm.reset();
		}
	}, [addPaymentModal]);

	useEffect(() => {
		switch (addPaymentCardRequest.state) {
			case API_STATE.done:
				const responseMessage = addPaymentCardRequest.response.message;
				if (responseMessage === "success") {
					setAddPaymentModal(false);
					getPaymentCards();
				} else {
					setAddPaymentMessage(responseMessage);
					showAlert();
				}
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [addPaymentCardRequest.state]);

	const submit = (data) => {
		addPaymentCardRequest.call(
			{
				cardNumber:
					data.cardNumber1 + data.cardNumber2 + data.cardNumber3 + data.cardNumber4,
				cardExpirationMonth: data.expMonth,
				cardExpirationYear: data.expYear,
				cardPassword: data.password,
			},
			"body"
		);
	};

	const changeAddCardInfo = (e) => {
		const inputName = e.target.name;
		const inputValue = e.target.value;
		paymentModalForm.setValue(inputName, inputValue.replace(/[^0-9]/g, ""));
		switch (inputName) {
			case "cardNumber1":
				inputValue.length === 4 && paymentModalForm.setFocus("cardNumber2");
				break;
			case "cardNumber2":
				inputValue.length === 4 && paymentModalForm.setFocus("cardNumber3");
				break;
			case "cardNumber3":
				inputValue.length === 4 && paymentModalForm.setFocus("cardNumber4");
				break;
			case "expMonth":
				inputValue.length === 2 && paymentModalForm.setFocus("expYear");
				break;
			default:
				break;
		}
	};

	return (
		<>
			<ModalTemplate
				title={t("MyPage.admin.Payment.management.title")}
				modalState={addPaymentModal}
				setModalToggle={setAddPaymentModal}
				width={500}
				className="payment-modal"
				closeable={false}
			>
				<div className="modal-content">
					<Form useForm={paymentModalForm} className="payment-modal-form">
						<Group label={t("MyPage.admin.Payment.management.cardNumber")}>
							<Input
								name="cardNumber1"
								required={null}
								maxLength={4}
								onInput={changeAddCardInfo}
								validation={{
									minLength: { value: 4, message: " " },
								}}
							/>
							<Input
								name="cardNumber2"
								required={null}
								type={showSecondNumber ? "text" : "password"}
								maxLength={4}
								onInput={changeAddCardInfo}
								validation={{
									minLength: { value: 4, message: " " },
								}}
							/>
							<Input
								name="cardNumber3"
								required={null}
								type={showThirdNumber ? "text" : "password"}
								maxLength={4}
								onInput={changeAddCardInfo}
								validation={{
									minLength: { value: 4, message: " " },
								}}
							/>
							<Input
								name="cardNumber4"
								required={null}
								maxLength={4}
								onInput={changeAddCardInfo}
								validation={{
									minLength: { value: 4, message: " " },
								}}
							/>
						</Group>
						<div
							className="card-toggle2"
							onMouseDown={() => setShowSecondNumber(true)}
							onMouseUp={() => setShowSecondNumber(false)}
							onMouseLeave={() => setShowSecondNumber(false)}
						>
							{showSecondNumber ? <ShowIcon /> : <HideIcon />}
						</div>
						<div
							className="card-toggle3"
							onMouseDown={() => setShowThirdNumber(true)}
							onMouseUp={() => setShowThirdNumber(false)}
							onMouseLeave={() => setShowThirdNumber(false)}
						>
							{showThirdNumber ? <ShowIcon /> : <HideIcon />}
						</div>
						<Group label={t("MyPage.admin.Payment.management.cardExp")}>
							<Input
								name="expMonth"
								required={null}
								placeholder={"MM"}
								className={"date"}
								onInput={changeAddCardInfo}
								maxLength={2}
								validation={{
									minLength: { value: 2, message: " " },
								}}
							/>
							<Input
								name="expYear"
								required={null}
								placeholder={"YY"}
								className={"date"}
								onInput={changeAddCardInfo}
								maxLength={2}
								validation={{
									minLength: { value: 2, message: " " },
								}}
							/>
						</Group>
						<Input
							name="password"
							label={t("MyPage.admin.Payment.management.password")}
							type="password"
							required={null}
							className={"password"}
							onInput={changeAddCardInfo}
							autoComplete="new-password"
							maxLength={2}
							validation={{
								minLength: { value: 2, message: " " },
							}}
						/>
					</Form>
				</div>
				<div className="modal-footer">
					<button
						className="btn__solid"
						color="gray"
						onClick={() => setAddPaymentModal(false)}
					>
						{t("button.cancel")}
					</button>
					<button
						className="btn__solid"
						color="primary"
						onClick={paymentModalForm.handleSubmit(submit)}
					>
						{t("MyPage.admin.Payment.management.submit")}
					</button>
				</div>
			</ModalTemplate>
			<Alert message={addPaymentMessage} type="info" confirm="확인" />
		</>
	);
};

export default AddPaymentModal;
