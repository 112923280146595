import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const CurrentNoticeAtom = atom({});

export const CurrentBoardAtom = atomWithStorage("gitbal.currentBoard", {});

export const MyAuthAtom = atomWithStorage("gitbal.myauth", "");

export const BoardListAtom = atom([]);

export const NoticePaginationAtom = atom(1);
export const CommunityPaginationAtom = atom(1);
