import React, { useEffect, useState } from "react";
import useRepository from "~/common/hooks/useRepository";
import { PAGE_STATE } from "~/common/constants/state";
import { PERIOD_TYPE } from "~/common/constants/form";
import { thousandFormatter } from "~/utils/formatter";
import { Trans, useTranslation } from "react-i18next";

// Store
import { useGlobalStore } from "~/store";
import { OKCEO_FLOW_DATA } from "~/store/tax/repository";

// Components
import { optionsGenerator } from "~/common/components/Select";
import { Case, If } from "~/common/components/If";
import CustomSelect, { optionGeneratorByObjectValues } from "~/common/components/CustomSelect";
import Loading from "~/common/components/Loading";
import { OkceoNoContent } from "~/pages/Tax/Dashboard/View";

const FlowCard = ({ okceoToken, selectedCompany, flowSelectedYear, setFlowSelectedYear }) => {
	const { t } = useTranslation();
	const [
		{
			tax: { flow },
		},
	] = useGlobalStore();
	const [cardState, setCardState] = useState(PAGE_STATE.LOADING);
	const [getFlowData] = useRepository(OKCEO_FLOW_DATA);
	const PeriodType = PERIOD_TYPE();

	useEffect(() => {
		getFlowData({ selectedCompany: selectedCompany, selectedYear: flowSelectedYear });
	}, [flowSelectedYear]);

	useEffect(() => {
		if (flow) {
			setCardState(PAGE_STATE.VIEW);
		} else {
			setCardState(PAGE_STATE.EMPTY);
		}
	}, [flow]);

	return (
		<div className="c__card profit-card">
			<div className="card-head">
				<h2 className="card-title">
					<Trans
						i18nKey="Tax.Dashboard.View.component.ProfitCard.FlowCard.card-title"
						components={[<span />]}
					/>
				</h2>
				<div className="extra-condition">
					<CustomSelect
						value={flowSelectedYear}
						setValue={setFlowSelectedYear}
						options={optionGeneratorByObjectValues(PeriodType)}
					/>
				</div>
			</div>
			<div className="card-content">
				<If condition={cardState}>
					<Case condition={PAGE_STATE.LOADING}>
						<Loading />
					</Case>
					<Case condition={PAGE_STATE.EMPTY}>
						<OkceoNoContent {...{ okceoToken, selectedCompany }} />
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						<div className="content-wrap">
							<div className="plus-money">
								<div className="bar-chart-wrap">
									<p className="label">
										{t(
											"Tax.Dashboard.View.component.ProfitCard.FlowCard.plus-money-label"
										)}
									</p>
									<dl className="bar-chart" color="plus">
										<dt className="amount">
											<div className="amount-num">
												{thousandFormatter(flow?.depositAmount)}
												&nbsp;
												<i>{t("Tax.Dashboard.View.component.won")}</i>
											</div>
											<i className="unit">
												({thousandFormatter(flow?.depositCount)}
												{t("Tax.Dashboard.View.component.case")})
											</i>
										</dt>
										<dd className="bar">
											<span
												className="bar-state"
												style={{ width: `${flow?.depositPercent}%` }}
											/>
										</dd>
										<dd
											className="bar-percent"
											style={{ width: `${flow?.depositPercent}%` }}
										>
											{flow?.depositPercent}%
										</dd>
									</dl>
								</div>
							</div>
							<div className="minus-money">
								<div className="bar-chart-wrap">
									<p className="label">
										{t(
											"Tax.Dashboard.View.component.ProfitCard.FlowCard.minus-money-label"
										)}
									</p>
									<dl className="bar-chart" color="minus">
										<dt className="amount">
											<div className="amount-num">
												{thousandFormatter(flow?.withdrawalAmount)}
												&nbsp;
												<i>{t("Tax.Dashboard.View.component.won")}</i>
											</div>
											<i className="unit">
												({thousandFormatter(flow?.withdrawalCount)}
												{t("Tax.Dashboard.View.component.case")})
											</i>
										</dt>
										<dd className="bar">
											<span
												className="bar-state"
												style={{
													width: `${flow?.withdrawalPercent}%`,
												}}
											/>
										</dd>
										<dd
											className="bar-percent"
											style={{
												width: `${flow?.withdrawalPercent}%`,
											}}
										>
											{flow?.withdrawalPercent}%
										</dd>
									</dl>
								</div>
							</div>
						</div>
					</Case>
				</If>
			</div>
		</div>
	);
};

export default FlowCard;
