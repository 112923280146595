import { useCallback, useRef } from "react";
import { PAGE_STATE } from "~/common/constants/state";

const UseInfiniteScroll = (pageState, page, setPage, hasMore) => {
	const observer = useRef();
	return useCallback(
		(node) => {
			if (pageState === PAGE_STATE.LOADING) return;
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasMore) {
					setPage(page + 1);
				}
			});
			if (node) observer.current.observe(node);
		},
		[pageState, hasMore]
	);
};

export default UseInfiniteScroll;
