import React, { useState } from "react";
import ProcessStep from "~/pages/invest/EvaluatePage/EvaluateDetail/View/components/processStep";
import Footer from "~/pages/invest/EvaluatePage/EvaluateDetail/View/components/Footer";
import { Case, If } from "~/common/components/If";
import { INVEST_STATE } from "~/common/constants/state";
import { useGlobalStore } from "~/store";
import Request from "~/pages/invest/EvaluatePage/EvaluateDetail/View/Request";
import Confirm from "~/pages/invest/EvaluatePage/EvaluateDetail/View/Confirm";
import Complete from "~/pages/invest/EvaluatePage/EvaluateDetail/View/Complete";
import Reject from "~/pages/invest/EvaluatePage/EvaluateDetail/View/Reject";

const View = () => {
	const [
		{
			evaluate: {
				currentEvaluate: { status, statusType },
			},
		},
	] = useGlobalStore();
	const [totalCardStep] = useState(
		statusType === "waiting" ? 1 : statusType === "reject" ? 2 : 3
	);
	const [cardStep, setCardStep] = useState(totalCardStep);

	return (
		<>
			<div className="process-total-wrap">
				<ProcessStep />
				<div className={`c__cardRow ${statusType}-step card-step${cardStep}`}>
					<If condition={status} {...{ cardStep, setCardStep }}>
						<Case condition={INVEST_STATE.WAITING}>
							<Request />
						</Case>
						<Case condition={INVEST_STATE.CONFIRM}>
							<Confirm />
						</Case>
						<Case condition={INVEST_STATE.COMPLETE}>
							<Complete />
						</Case>
						<Case condition={INVEST_STATE.REJECT}>
							<Reject />
						</Case>
					</If>
				</div>
				<Footer {...{ totalCardStep, cardStep, setCardStep, statusType }} />
			</div>
		</>
	);
};

export default View;
