import React, { useState } from "react";
import { useForm } from "react-hook-form";

import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import DatePicker from "~/common/components/DatePicker";

import "~/assets/sass/pages/shareholder/edit.scss";
import { PlusIcon } from "~/assets/svg/MathIcon";
import PageHeader from "~/common/components/PageHeader";
import { useGlobalStore } from "~/store";
import DetailInfo from "./DetailInfo";
import SignatureSelectModal from "~/common/components/SignatureSelectModal";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import SingleImageUploader from "~/common/components/ImageUploader/SingleImageUploader";
import useAlert from "~/common/hooks/useAlert";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import useRequest from "~/common/hooks/useRequest";
import SignatureUploadModal from "~/pages/mypage/admin/SignaturePage/SignatureUploadModal";

const Edit = ({ shareholderDetail, menu, type, onSubmit, onCancel }) => {
	const { t } = useTranslation();
	const langStatus = i18next.language;
	const { idx, title, sign, regDate, ShareholderMember } = shareholderDetail;
	const [
		{
			company: {
				info: {
					ceoName: companyCeo,
					address: companyAddress,
					addressDetail: companyAddressDetail,
					name: companyName,
				},
			},
		},
	] = useGlobalStore();

	const shareholderForm = useForm({
		defaultValues: { idx, title, sign, regDate, shareholderMember: ShareholderMember },
	});

	const signatureListRequest = useRequest("get", `admin/signature?size=6&page=1`);

	const [showAlert, Alert] = useAlert();
	const [showCancelAlert, CancelAlert] = useAlert();

	const headerButtons = [
		<button
			type="button"
			className="btn__solid"
			color="gray"
			onClick={() => {
				showCancelAlert().then(() => onCancel());
			}}
		>
			{t("button.cancel")}
		</button>,
		<button
			type="button"
			className="btn__solid"
			color="primary"
			onClick={shareholderForm.handleSubmit((data) => {
				const { sign } = data;
				if (sign.length > 0) {
					onSubmit(data);
				} else {
					showAlert();
				}
			})}
			// onClick={shareholderForm.handleSubmit((data) => {
			// 	onSubmit(data);
			// })}
		>
			{t("button.save")}
		</button>,
	];
	const [signRegisterModal, setSignRegisterModal] = useState(false);
	const [digitalDataModal, setDigitalDataModalModal] = useState(false);

	const selectedSignature = shareholderForm.watch("sign");

	const setSelectedSignature = (img) => {
		shareholderForm.setValue("sign", img);
	};

	return (
		<>
			<PageHeader buttonList={headerButtons} menu={menu} type={type} />
			<div className="c__card shareholder-edit-card">
				<h3 className="section-title">
					{t("Shareholder.ShareholderDetail.Edit.DetailInfo.thead.info")}
				</h3>
				<section className="shareholder-card-info">
					<div className="form-label">{t("VcInfo.VcDetail.basic.basic-info-title")}</div>
					<Form useForm={shareholderForm}>
						<Input
							label={t("Community.board-table.title")}
							name="title"
							required={null}
							validation={{
								maxLength: {
									value: 100,
									message: t(
										"Shareholder.ShareholderDetail.Edit.Index.validationMsg"
									),
								},
							}}
						/>
						<DatePicker
							label={t("Shareholder.ShareholderDetail.Edit.Index.issueDate")}
							name="regDate"
							datePickerName="date-picker"
							required={null}
						/>
					</Form>
				</section>
				<section className="shareholder-form-table">
					<DetailInfo useForm={shareholderForm} />
				</section>
				<section className="shareholder-footer">
					<div className="info-area">
						<h2 className="company-name">{companyName}</h2>
						<p className="company-address">
							{companyAddress} {companyAddressDetail}
						</p>
					</div>
					<div className="seal-area">
						<dl>
							<dd className="signatory-position">
								{t("Shareholder.ShareholderDetail.Edit.Index.position")}
							</dd>
							<dt className="signatory-name">{companyCeo}</dt>
						</dl>
						<div className="seal-uploader">
							{selectedSignature.length > 0 ? (
								<SingleImageUploader
									uploaderConstant={UPLOAD_TYPE.USER_SIGN}
									width={236}
									height={122}
									round={false}
									img={selectedSignature}
									setImg={() => setSelectedSignature("")}
									deletable={false}
									editable={true}
									handleEdit={(e) => {
										e.preventDefault();
										setSignRegisterModal(true);
									}}
								/>
							) : (
								<button
									className="dashed-btn plus-btn"
									onClick={() => setSignRegisterModal(true)}
								>
									<PlusIcon />
									{t("Shareholder.ShareholderDetail.Edit.Index.button")}
								</button>
							)}
						</div>
					</div>
				</section>
			</div>
			{/*서명설정하기 모달*/}
			<ModalTemplate
				modalState={signRegisterModal}
				setModalToggle={setSignRegisterModal}
				title={t("Shareholder.ShareholderDetail.Edit.Index.signRegister")}
				width={700}
				className={"modal-sign"}
				closeable={false}
			>
				<SignatureSelectModal
					{...{
						selectedSignature,
						setSelectedSignature,
						setDigitalDataModalModal,
						signatureListRequest,
					}}
				/>
			</ModalTemplate>

			{/* 법인인감 Policy 모달 */}
			<ModalTemplate
				title={t("MyPage.admin.Signature.index.modalTitle")}
				modalState={digitalDataModal}
				setModalToggle={setDigitalDataModalModal}
				width={500}
				height={380}
				className="modal-signatureUpload"
			>
				<SignatureUploadModal {...{ getSignatureList: () => {}, signatureListRequest }} />
			</ModalTemplate>

			<Alert
				message={t("Shareholder.ShareholderDetail.Edit.Index.alertMsg")}
				confirm={t("button.check")}
				type="error"
			/>
			<CancelAlert
				message={
					<>
						{t("Shareholder.ShareholderDetail.Edit.Index.cancelAlert.main")}{" "}
						{idx !== undefined
							? t("button.edit").toLowerCase()
							: t("button.register").toLowerCase()}
						{langStatus === "ko" ? "" : "?"}
						{t("Shareholder.ShareholderDetail.Edit.Index.cancelAlert.sub")}
					</>
				}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
		</>
	);
};

export default Edit;
