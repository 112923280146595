import React, { useEffect } from "react";

import Form, { Group } from "~/common/components/Form";
import Input from "~/common/components/Input";
import { RoundMinusIcon } from "~/assets/svg/InterfacesIcon";
import { PlusIcon } from "~/assets/svg/MathIcon";
import NumberInput from "~/common/components/NumberInput";
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

const DetailInfoRow = ({ useForm, name, remove, reducedTotal }) => {
	const { t } = useTranslation();
	const price = useForm.watch(name("price"));
	const stock = useForm.watch(name("stock"));
	useEffect(() => {
		const total = price * stock;
		useForm.setValue(name("total"), total.toString());
	}, [price, stock]);

	const total = useForm.watch(name("total"));

	useEffect(() => {
		const ratio = Math.round((total / reducedTotal) * 10000) / 100;
		if (ratio > 0) {
			useForm.setValue(name("ratio"), ratio.toString());
		} else {
			useForm.setValue(name("ratio"), 0);
		}
	}, [total, reducedTotal]);

	return (
		<>
			<Form useForm={useForm} className={"tr"}>
				<Group className="td shareholder-info">
					<Input
						className="shareholder-name"
						placeholder={t(
							"Shareholder.ShareholderDetail.Edit.DetailInfo.placeholder.name"
						)}
						name={name("name")}
						required={" "}
					/>
					<Input
						className="shareholder-number"
						placeholder={t(
							"Shareholder.ShareholderDetail.Edit.DetailInfo.placeholder.number"
						)}
						name={name("regNum")}
						type="text"
						required={null}
					/>
				</Group>
				<Input className="td shareholder-address" name={name("address")} required={null} />
				<NumberInput
					className="td shareholder-price"
					name={name("price")}
					required={null}
					string
				/>
				<NumberInput
					className="td shareholder-stock"
					name={name("stock")}
					required={null}
					string
				/>
				<NumberInput
					className="td shareholder-total"
					name={name("total")}
					readonly
					string
				/>
				<Input className="td equity-ratio" name={name("ratio")} readonly />
				<button type="button" className="btn-round-delete" onClick={remove}>
					<RoundMinusIcon />
				</button>
			</Form>
		</>
	);
};

const DetailInfo = ({ useForm }) => {
	const { t } = useTranslation();
	const { control } = useForm;

	const { fields, append, remove } = useFieldArray({
		control,
		name: "shareholderMember",
	});

	const SHAREHOLDER_INIT_DATA = {
		name: "",
		regNum: "",
		address: "",
		price: "",
		stock: "",
	};

	const reducedTotal = useForm
		.watch("shareholderMember")
		.reduce((acc, cur) => acc + cur.stock * cur.price, 0);

	return (
		<>
			<div className="form-label">
				{t("Invest.Round.RoundDetailModal.View.tabMenuList.detail")}
				<button
					className="dashed-btn plus-btn"
					onClick={() => append(SHAREHOLDER_INIT_DATA)}
				>
					<PlusIcon />
					{t("Shareholder.ShareholderDetail.Edit.DetailInfo.button")}
				</button>
			</div>
			<div className="shareholder-table">
				<div className="tr thead">
					<div className="th shareholder-info">
						{t("Shareholder.ShareholderDetail.Edit.DetailInfo.thead.info")}
					</div>
					<div className="th shareholder-address">
						{t("Shareholder.ShareholderDetail.Edit.DetailInfo.thead.address")}
					</div>
					<div className="th shareholder-price">
						{t("Shareholder.ShareholderDetail.Edit.DetailInfo.thead.price")}
					</div>
					<div className="th shareholder-stock">
						{t("Shareholder.ShareholderDetail.Edit.DetailInfo.thead.stock")}
					</div>
					<div className="th shareholder-total">
						{t("Shareholder.ShareholderDetail.Edit.DetailInfo.thead.total")}
					</div>
					<div className="th equity-ratio">
						{t("Shareholder.ShareholderDetail.Edit.DetailInfo.thead.ratio")}
					</div>
				</div>
				<div className="tbody">
					{fields.map((field, index) => {
						const getName = (name) => `shareholderMember.${index}.${name}`;
						const removeItem = () => remove(index);
						return (
							<DetailInfoRow
								key={field.id}
								name={getName}
								remove={removeItem}
								useForm={useForm}
								reducedTotal={reducedTotal}
							/>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default DetailInfo;
