import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const SignaturePolicy = ({ setModalToggle, setStep }) => {
	const { t } = useTranslation();
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [policyList, setPolicyList] = useState([
		{
			id: 1,
			text: (
				<Trans
					i18nKey="MyPage.SignaturePage.SignatureUploadModal.content.idx1"
					components={[<b />]}
				/>
			),
			checked: false,
		},
		{
			id: 2,
			text: (
				<Trans
					i18nKey="MyPage.SignaturePage.SignatureUploadModal.content.idx2"
					components={[<b />]}
				/>
			),
			checked: false,
		},
		{
			id: 3,
			text: (
				<Trans
					i18nKey="MyPage.SignaturePage.SignatureUploadModal.content.idx3"
					components={[<b />]}
				/>
			),
			checked: false,
		},
		{
			id: 4,
			text: (
				<Trans
					i18nKey="MyPage.SignaturePage.SignatureUploadModal.content.idx4"
					components={[<b />]}
				/>
			),
			checked: false,
		},
	]);

	useEffect(() => {
		if (policyList.find((p) => p.checked === false)) {
			setIsCheckAll(false);
		} else {
			setIsCheckAll(true);
		}
	}, [policyList]);

	const handleCheckAll = () => {
		const value = policyList.map((v) => ({
			...v,
			checked: !isCheckAll,
		}));

		setIsCheckAll(!isCheckAll);
		setPolicyList(value);
	};

	return (
		<>
			<div className="modal-content signature-policy">
				<div className="checkbox check-all">
					<label className={`checkbox-custom`}>
						<input type="checkbox" checked={isCheckAll} onChange={handleCheckAll} />
						<span className="checkmark" />
						<span className="checkbox-label">
							{t("MyPage.SignaturePage.SignatureUploadModal.content.checkbox-label")}
						</span>
					</label>
				</div>
				{policyList.map((p, p_idx) => (
					<div className="checkbox" key={p_idx}>
						<label className={`checkbox-custom`}>
							<input
								type="checkbox"
								checked={p.checked}
								onChange={() => {
									const value = policyList.map((policy, idx) =>
										p_idx === idx ? { ...policy, checked: !p.checked } : policy
									);
									setPolicyList(value);
								}}
							/>
							<span className="checkmark" />
							<span className="checkbox-label join-chk">{p.text}</span>
						</label>
					</div>
				))}
			</div>
			<div className="modal-footer">
				<button
					className="btn__solid"
					color="gray"
					onClick={() => {
						setStep("init");
						setModalToggle(false);
					}}
				>
					{t("button.cancel")}
				</button>
				<button
					className="btn__solid"
					color="primary"
					onClick={() => setStep("upload")}
					disabled={!isCheckAll}
				>
					{t("button.next")}
				</button>
			</div>
		</>
	);
};
export default SignaturePolicy;
