import React from "react";

const List = ({ vacationStatusList }) => {
	const vacationType = () =>
		vacationStatusList.map((vacation, idx) => (
			<div
				className={`vacation-bar ${
					vacation.type !== "연차" &&
					parseFloat(vacation.remainingTime) === 0 &&
					"disabled"
				}`}
				key={idx}
			>
				<div className="vacation-item">
					<span className="vacation-name">
						<i className="color" style={{ background: vacation.color }} />
						{vacation.title}
					</span>
					{!vacation.paid && <span className="unpaid-badge">무급</span>}
					{(vacation.type === "연차" || vacation.type === "법정필수휴가") && (
						<span className="public-holiday">법정필수휴가</span>
					)}
				</div>
				<span
					className={`day-status ${vacation?.remainingTime.charAt(0) === "-" && "minus"}`}
				>
					{vacation?.remainingTime}
				</span>
			</div>
		));

	return (
		<>
			<div className="modal-content">{vacationType()}</div>
		</>
	);
};
export default List;
