import React from "react";
import { useGlobalStore } from "~/store";
import { dateToString, thousandFormatter } from "~/utils/formatter";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const View = ({ showEditPage, Header }) => {
	const { t } = useTranslation();
	const [
		{
			ir: {
				outcome: {
					IROutcomeInvest,
					IROutcomeAward,
					IROutcomeExport,
					IROutcomeFund,
					IROutcomePlan,
				},
			},
		},
	] = useGlobalStore();

	const investList = () =>
		IROutcomeInvest.map(({ idx, name, value, date }) => (
			<ul key={idx} className="f__control">
				<li className="content">{name}</li>
				<li className="value">{thousandFormatter(value)}</li>
				<li className="date">{dateToString(date, "yyyy-MM-dd")}</li>
			</ul>
		));

	const awardList = () =>
		IROutcomeAward.map(({ idx, name, content, date }) => (
			<ul key={idx} className="f__control">
				<li className="content">{name}</li>
				<li className="value award-value">{content}</li>
				<li className="date">{dateToString(date, "yyyy-MM-dd")}</li>
			</ul>
		));

	const exportList = () =>
		IROutcomeExport.map(({ idx, name, value, date }) => (
			<ul key={idx} className="f__control">
				<li className="content">{name}</li>
				<li className="value">{thousandFormatter(value)}</li>
				<li className="date">{dateToString(date, "yyyy-MM-dd")}</li>
			</ul>
		));

	const fundList = () =>
		IROutcomeFund.map(({ idx, name, value, date }) => (
			<ul key={idx} className="f__control">
				<li className="content">{name}</li>
				<li className="value">{thousandFormatter(value)}</li>
				<li className="date">{dateToString(date, "yyyy-MM-dd")}</li>
			</ul>
		));

	const planList = () =>
		IROutcomePlan.map(({ idx, indicator, now, three, six, twelve }) => (
			<div key={idx} className="tr">
				<div className="td plan-indicator">{indicator}</div>
				<div className="td">{now}</div>
				<div className="td">{three}</div>
				<div className="td">{six}</div>
				<div className="td">{twelve}</div>
			</div>
		));

	const buttonList = [
		<AuthButton
			type={"button"}
			className={"btn__solid"}
			color={"primary"}
			onClick={showEditPage}
		>
			{t("button.Edit")}
		</AuthButton>,
	];

	return (
		<>
			<Header buttonList={buttonList} />
			<div className="ir-view">
				<div className="c__card outcome-view">
					<div className="data-form">
						<section>
							<h3 className="section-title">
								{t("IR.IrDetail.Outcome.Edit.Index.title")}
							</h3>
							<div className="c__form vertical sub-section">
								<div className="f__group">
									<div className="f__label unit-wrap">
										{t("IR.IrDetail.Outcome.Edit.Index.field1.title")}
										<span className="unit">
											{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
											{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
										</span>
									</div>
									{investList()}
								</div>
								<div className="f__group">
									<div className="f__label">
										{t("IR.IrDetail.Outcome.Edit.Index.award")}
									</div>
									{awardList()}
								</div>
							</div>
							<div className="c__form vertical sub-section">
								<div className="f__group">
									<div className="f__label unit-wrap">
										{t("IR.IrDetail.Outcome.Edit.Index.field2.title")}
										<span className="unit">
											{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
											{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
										</span>
									</div>
									{exportList()}
								</div>
								<div className="f__group">
									<div className="f__label unit-wrap">
										{t("IR.IrDetail.Outcome.Edit.Index.field3.title")}
										<span className="unit">
											{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
											{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
										</span>
									</div>
									{fundList()}
								</div>
							</div>
						</section>
						<section className="outcome-plan">
							<h3 className="section-title">
								{t("IR.IrDetail.Outcome.Edit.MainPlan.title")}
							</h3>
							<div className="outcome-plan-table">
								<div className="thead">
									<div className="tr">
										<div className="th plan-indicator">
											{t("IR.IrDetail.Outcome.Edit.MainPlan.th1")}
										</div>
										<div className="th">
											{t("IR.IrDetail.Outcome.Edit.MainPlan.th2")}
										</div>
										<div className="th">+3M</div>
										<div className="th">+6M</div>
										<div className="th">+12M</div>
									</div>
								</div>
								<div className="tbody">{planList()}</div>
							</div>
						</section>
					</div>
				</div>
			</div>
		</>
	);
};

export default View;
