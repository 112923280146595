import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import "~/assets/sass/pages/ir/shareholder.scss";
import { useGlobalStore } from "~/store";
import { thousandFormatter } from "~/utils/formatter";
import { PlusIcon } from "~/assets/svg/MathIcon";
import useRequest from "~/common/hooks/useRequest";
import Modal from "~/pages/ir/IrDetail/Shareholder/Edit/Modal";
import InputRow from "./InputRow";
import { API_STATE } from "~/common/constants/state";
import useAlert from "~/common/hooks/useAlert";
import { useNavigate } from "react-router";
import useRepository from "~/common/hooks/useRepository";
import { USER_INFO } from "~/store/user/repository";
import { useTranslation } from "react-i18next";

const Edit = ({ showViewPage, Header }) => {
	const { t } = useTranslation();
	const [
		{
			ir: { selectedIdx: irIdx, shareholder: Shareholder },
		},
	] = useGlobalStore();
	const [getUserInfo] = useRepository(USER_INFO);
	const navigate = useNavigate();
	const [importModal, setImportModal] = useState(false);
	const [showAlert, Alert] = useAlert();

	const shareholderForm = useForm({ defaultValues: { irIdx, Shareholder } });

	const { fields, append, remove, replace } = useFieldArray({
		control: shareholderForm.control,
		name: "Shareholder",
	});

	const irMutation = useRequest("post", "ir/shareholder/edit");

	const submit = async (data) => {
		irMutation
			.asyncCall(data)
			.then(() => {
				getUserInfo().then(showViewPage);
			})
			.catch((msg) => console.log(msg));
	};

	const shareholderListRequest = useRequest("get", "shareholder");

	const handleCheckImport = () => {
		shareholderListRequest.call();
	};

	const [response, setResponse] = useState([]);

	useEffect(() => {
		switch (shareholderListRequest.state) {
			case API_STATE.done:
				if (shareholderListRequest.response.data.length === 0) {
					showAlert()
						.then(async () => {
							await navigate("/shareholder/list");
						})
						.catch(() => {});
				} else {
					const newData = shareholderListRequest.response.data.reduce(
						(data, shareholder) => {
							data[shareholder.idx] = shareholder.title;
							return data;
						},
						{}
					);
					setResponse(newData);
					setImportModal(true);
				}
				break;
			default:
				break;
		}
	}, [shareholderListRequest.state]);

	const ROW_INIT_DATA = {
		name: "",
		total: "0",
		ratio: 0,
		etc: "",
	};

	const [showCancelAlert, CancelAlert] = useAlert();

	const buttonList = [
		<button
			type="button"
			className="btn__solid"
			color="gray"
			onClick={() => {
				showCancelAlert().then(() => showViewPage());
			}}
		>
			{t("button.cancel")}
		</button>,
		<button
			type="button"
			className="btn__solid"
			color="primary"
			onClick={shareholderForm.handleSubmit(submit)}
		>
			{t("button.save")}
		</button>,
	];

	const reducedAmount = shareholderForm
		.watch("Shareholder")
		.reduce((acc, cur) => acc + parseInt(cur.total), 0);

	return (
		<>
			<Header buttonList={buttonList} />
			<div className="ir-view">
				<div className="c__card shareholder-edit">
					<h3 className="section-title">
						{t("IR.IrDetail.Shareholder.Edit.Index.title")}
						<div className="btn-group-wrap">
							<button className="dashed-btn plus-btn" onClick={handleCheckImport}>
								{t("IR.IrDetail.Shareholder.Edit.Index.import")}
							</button>
							<button
								className="dashed-btn plus-btn"
								onClick={() => {
									append(ROW_INIT_DATA);
								}}
							>
								<PlusIcon />
								{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.addInput")}
							</button>
						</div>
					</h3>
					<div className="scroll-wrap">
						<div className="shareholder-table">
							<div className="tr thead">
								<div className="th shareholder-name">
									{t("IR.IrDetail.Shareholder.Edit.Index.th1")}
								</div>
								<div className="th shareholder-amount">
									{t("IR.IrDetail.Shareholder.Edit.Index.th2")}
								</div>
								<div className="th shareholder-ratio">
									{t("IR.IrDetail.Shareholder.Edit.Index.th3")}
								</div>
								<div className="th shareholder-remark">
									{t("IR.IrDetail.Shareholder.Edit.Index.th4")}
								</div>
							</div>
							<div className="tbody">
								{fields.map((field, index) => {
									const getName = (name) => `Shareholder.${index}.${name}`;
									const removeItem = () => remove(index);
									return (
										<InputRow
											key={field.id}
											name={getName}
											remove={removeItem}
											useForm={shareholderForm}
											reducedAmount={reducedAmount}
										/>
									);
								})}
							</div>
							<div className="tfoot tr">
								<div className="td">
									{t("IR.IrDetail.Shareholder.Edit.Index.total")}
								</div>
								<div className="td shareholder-amount">
									{thousandFormatter(reducedAmount)}
								</div>
								<div className="td shareholder-ratio">
									{reducedAmount !== 0 ? "100%" : "0"}
								</div>
								<div className="td" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				response={response}
				modalState={importModal}
				setModalToggle={setImportModal}
				importShareholder={(shareholder) => {
					replace(shareholder);
				}}
			/>
			<Alert
				message={
					<>
						<div>{t("IR.IrDetail.Shareholder.Edit.Index.noContent1")}</div>
						<div>{t("IR.IrDetail.Shareholder.Edit.Index.noContent2")}</div>
					</>
				}
				confirm={t("button.check")}
				reject={t("button.cancel")}
				type="info"
			/>
			<CancelAlert
				message={t("IR.IrDetail.Shareholder.Edit.Index.cancelMsg")}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
		</>
	);
};

export default Edit;
