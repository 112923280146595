import React, { useState } from "react";
import CustomCheckbox from "~/common/components/CustomCheckbox";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const Search = ({ setSearchName, setSearchStatus, setPage }) => {
	const { t } = useTranslation();
	const [inputValue, setInputValue] = useState("");
	const [statusValue, setStatusValue] = useState([]);
	const [allChecked, setAllChecked] = useState(true);
	const [checkboxRequired, setCheckboxRequired] = useState(false);
	const searchOption = [
		{ label: translation.t("Invest.Round.RoundList.entire"), value: "ALL" },
		{ label: translation.t("Invest.situation.request"), value: "승인대기" },
		{ label: translation.t("Invest.situation.evaluate"), value: "승인" },
		{ label: translation.t("Invest.situation.reject"), value: "반려" },
		{ label: translation.t("Invest.situation.complete"), value: "완료" },
	];

	const handleSearch = () => {
		if (statusValue.length !== 0) {
			setSearchName(inputValue);
			setSearchStatus(statusValue.filter((s) => s !== "ALL"));
			setPage(1);
		} else {
			setCheckboxRequired(true);
		}
	};

	return (
		<div className="c__card search-card">
			<div className="c__form horiz">
				<div className="f__group status-chk-wrap">
					<label className="f__label">
						{t("Invest.Evaluate.EvaluateList.View.status-chk-wrap.f__label")}
					</label>
					<CustomCheckbox
						options={searchOption}
						required={checkboxRequired}
						isAllChecked={allChecked}
						setISAllChecked={setAllChecked}
						value={statusValue}
						setValue={setStatusValue}
					/>
				</div>
				<div className="f__group search-list-wrap">
					<label className="f__label">
						{t("Invest.Evaluate.EvaluateList.View.search-list-wrap.f__label")}
					</label>
					<div className="f__control-wrap">
						<div className="f__control">
							<input
								className="c__input f__item"
								placeholder={t(
									"Invest.Evaluate.EvaluateList.View.search-list-wrap.input-placeholder"
								)}
								value={inputValue}
								onChange={(e) => setInputValue(e.target.value)}
								onKeyDown={(e) => e.key === "Enter" && handleSearch()}
							/>
						</div>
						<button
							type="button"
							className="btn__solid btn-search"
							color="primary"
							onClick={() => handleSearch()}
						>
							{t("button.search")}
						</button>
					</div>
				</div>
			</div>
			<p className="warning-phrase pre-wrap">
				{t("Invest.Evaluate.EvaluateList.View.warning-phrase")}
			</p>
		</div>
	);
};

export default Search;
