import React, { useEffect, useState } from "react";
import PageHeader from "~/common/components/PageHeader";
import { Case, If } from "~/common/components/If";
import { API_STATE, INVEST_STATE, PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import View from "./View/index";
import Empty from "./Empty";
import "~/assets/sass/pages/invest/evaluateDetail.scss";
import { useNavigate, useParams } from "react-router";
import useRequest from "~/common/hooks/useRequest";
import { useGlobalStore } from "~/store";
import { getIrList, setCurrentEvaluate } from "~/store/evaluate/actions";
import { getKeyByValue } from "~/utils/object";
import { dateToString } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const EvaluateDetail = () => {
	const { t } = useTranslation();
	const { idx } = useParams();
	const navigate = useNavigate();
	const [, dispatch] = useGlobalStore();

	const [page, setPage] = useState(PAGE_STATE.LOADING);

	const evaluateDetailRequest = useRequest("get", "invest/detail");
	const investIrRequest = useRequest("get", "/invest/ir");

	useEffect(() => {
		evaluateDetailRequest.singleCall(idx, "path");
		investIrRequest.singleCall();
	}, []);

	useEffect(() => {
		switch (evaluateDetailRequest.state) {
			case API_STATE.done:
				const response = evaluateDetailRequest.response.data;
				const newData = {
					...response,
					statusType: getKeyByValue(INVEST_STATE, response.status).toLowerCase(),
					regDate: dateToString(response?.regDate, "yyyy-MM-dd"),
					confirmDate: dateToString(response?.confirmDate, "yyyy-MM-dd"),
					completeDate: dateToString(response?.completeDate, "yyyy-MM-dd"),
					score: Math.round(response?.score * 100) / 100,
				};

				dispatch(setCurrentEvaluate({ evaluateDetail: newData }));
				setPage(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				navigate("/invest/review");
		}
	}, [evaluateDetailRequest.state]);

	useEffect(() => {
		switch (investIrRequest.state) {
			case API_STATE.done:
				const newObj = investIrRequest.response.data.reduce(function (acc, value) {
					return { ...acc, [value.idx]: value.title };
				}, {});
				dispatch(getIrList({ irList: newObj }));
				break;
			case API_STATE.error:
				if (investIrRequest.error.statusCode === 404) {
					alert(t("Alert.alert1"));
					console.log("error", investIrRequest.error);
				}
		}
	}, [investIrRequest.state]);

	return (
		<NavbarAndSidebarLayout menu={["invest", "review"]} type="main">
			<PageHeader />
			<If condition={page} {...{ setPage }}>
				<Case condition={PAGE_STATE.EMPTY}>
					<Empty />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<View />
				</Case>
				<Case condition={PAGE_STATE.LOADING}>
					<Loading />
				</Case>
			</If>
		</NavbarAndSidebarLayout>
	);
};

export default EvaluateDetail;
