import React, { useState } from "react";
import { Case, MultiCases, If, OtherWise } from "~/common/components/If";
import AdminView from "./AdminView";
import MineView from "./MineView";
import OtherView from "./OtherView";
import { useTranslation } from "react-i18next";

const View = ({ userRole, showEditPage, handleClose, handleDelete, userDetail }) => {
	const { t } = useTranslation();
	const [isDeleteTab, setIsDeleteTab] = useState(false);
	return (
		<>
			<If condition={userRole} {...{ userDetail }}>
				<Case condition={"admin"}>
					<AdminView {...{ setIsDeleteTab }} />
				</Case>
				<Case condition={"user"}>
					<OtherView />
				</Case>
				<Case condition={"mine"}>
					<MineView />
				</Case>
			</If>
			<div className="modal-footer">
				<If condition={userRole}>
					<MultiCases condition={["admin", "mine"]}>
						<button
							type="button"
							key="cancel"
							className="btn__solid"
							color="gray"
							onClick={handleClose}
						>
							{t("button.cancel")}
						</button>
						{isDeleteTab ? (
							<button
								type="button"
								className="btn__solid"
								color="red"
								onClick={handleDelete}
							>
								{t("button.delete")}
							</button>
						) : (
							<button
								type="button"
								key="edit"
								className="btn__solid"
								color="primary"
								onClick={showEditPage}
							>
								{t("button.modify")}
							</button>
						)}
					</MultiCases>
					<OtherWise>
						<button
							type="button"
							key="cancel"
							className="btn__solid"
							color="gray"
							onClick={handleClose}
						>
							{t("button.close")}
						</button>
					</OtherWise>
				</If>
			</div>
		</>
	);
};

export default View;
