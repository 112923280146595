import React from "react";
import { useTranslation } from "react-i18next";

const Desc = ({ selectedPlugin }) => {
	const { i18n } = useTranslation();
	return (
		<div
			className="desc-wrap"
			dangerouslySetInnerHTML={{
				__html:
					i18n.language === "ko"
						? selectedPlugin.description
						: selectedPlugin.engDescription,
			}}
		/>
	);
};

export default Desc;
