import React from "react";
import defaultImg from "~/assets/img/defaultImg/default-img.png";
import { BookMarkIcon, ProfileIcon } from "~/assets/svg/InterfacesIcon";
import { useTranslation } from "react-i18next";

const MyCommunity = ({ myCommunityList }) => {
	const { t } = useTranslation();
	return myCommunityList.map((community, index) => (
		<li className="modal-item" key={index}>
			<div className="modal-title">
				<div className="modal-img">
					<img
						src={community.img ?? defaultImg}
						alt={t("Img.profile")}
						onError={(e) => {
							e.target.src = defaultImg;
						}}
					/>
				</div>
				<div className="title">{community.name}</div>
				<div className="member">
					<ProfileIcon />
					{community.memberCount}
				</div>
			</div>
			{community.me.isFavorite && (
				<div className="custom-badge">
					<button type="button" className={`badge-btn active`}>
						<BookMarkIcon />
					</button>
				</div>
			)}
		</li>
	));
};

export default MyCommunity;
