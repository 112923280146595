import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Assets
import { AuthEmailIcon } from "~/assets/svg/InterfacesIcon";
import useRequest from "~/common/hooks/useRequest";

const JoinAuthMailSend = ({ registerForm }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const registerRetryRequest = useRequest(
		"post",
		`/user/register/resend/${registerForm.watch().id}`
	);

	const retryRequest = () => {
		registerRetryRequest.call(registerForm.watch());
	};

	return (
		<>
			<div className="phrase-wrap">
				<AuthEmailIcon />
				<h3>{t("Auth.Join.AutoMailSend.phrase-wrap.h3")}</h3>
				<p>{t("Auth.Join.AutoMailSend.phrase-wrap.p")}</p>
			</div>
			<div className="btn-wrap">
				<button
					type="button"
					className="btn__solid"
					color="primary"
					onClick={() => navigate("/login")}
				>
					{t("button.check")}
				</button>
			</div>
			<div className="mail-resend">
				이메일을 찾을 수 없으신가요?
				<button className="btn-mail-resend" onClick={retryRequest}>
					인증메일 재전송
				</button>
			</div>
		</>
	);
};

export default JoinAuthMailSend;
