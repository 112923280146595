import React, { useEffect, useState } from "react";
import noImg from "~/assets/img/defaultImg/profile-large.png";
import { dateToString } from "~/utils/formatter";
import DropdownTemplate, {
	DropDownItem,
} from "~/common/components/DropdownPortal/DropdownTemplate";
import Form, { Group } from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import CommentUpload from "~/common/components/FormImageUploader/CommentUpload";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import useRequest from "~/common/hooks/useRequest";
import { useGlobalStore } from "~/store";
import { API_STATE } from "~/common/constants/state";
import { useNavigate } from "react-router-dom";
import { useAtomValue } from "jotai";
import { MyAuthAtom } from "~/atoms/Community";
import { useTranslation } from "react-i18next";

const Comment = ({ comment, commentRequest }) => {
	const { t } = useTranslation();
	const [
		{
			user: { info },
		},
	] = useGlobalStore();
	const myAuth = useAtomValue(MyAuthAtom);
	const { idx, postIdx } = useParams();
	const navigate = useNavigate();
	const replyForm = useForm();
	const replyEditForm = useForm({
		defaultValues: {
			comment: comment.comment,
			ImageList: comment.files.map((file) => {
				return { name: file.name, path: file.path, size: Number(file.size) };
			}),
		},
	});
	const [isReplyOn, setIsReplyOn] = useState(false);
	const deleteCommentRequest = useRequest("delete", `/community/board/comment`);
	const [isEdit, setIsEdit] = useState(false);
	const updateCommentRequest = useRequest(
		isEdit ? "patch" : "post",
		`community/board/comment/${isEdit ? comment.idx : postIdx}`
	);

	const closeReply = () => {
		replyForm.reset();
		setIsReplyOn(false);
	};

	const submit = (data) => {
		if (isEdit) {
			if (!data.comment && data.ImageList.length === 0) {
				replyEditForm.setError("comment", {
					type: "custom",
					message: t("Validate.require"),
				});
				return;
			}
			updateCommentRequest.call(
				{
					comment: data.comment,
					files: data.ImageList?.map((image) => {
						return { ...image, size: Number(image.size) };
					}),
				},
				"body"
			);
		} else {
			if (!data.comment && data.ImageList.length === 0) {
				replyForm.setError("comment", {
					type: "custom",
					message: t("Validate.require"),
				});
				return;
			}
			updateCommentRequest.call(
				{
					communityIdx: Number(idx),
					comment: data.comment,
					parentIdx: Number(comment.idx),
					files: data.ImageList,
				},
				"body"
			);
		}
	};

	const deleteComment = () => {
		closeReply();
		deleteCommentRequest.singleCall(comment.idx, "path");
	};

	useEffect(() => {
		replyEditForm.setValue("comment", comment.comment);
		replyEditForm.setValue(
			"ImageList",
			comment.files.map((file) => {
				return { name: file.name, path: file.path, size: Number(file.size) };
			})
		);
		replyEditForm.trigger();
	}, [comment, replyEditForm]);

	useEffect(() => {
		switch (deleteCommentRequest.state) {
			case API_STATE.done:
				commentRequest.call();
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [deleteCommentRequest.state]);

	useEffect(() => {
		switch (updateCommentRequest.state) {
			case API_STATE.done:
				if (updateCommentRequest.response.statusCode === 500) {
					console.log("error");
					navigate("/404");
				}

				commentRequest.call();
				if (isEdit) {
					setIsEdit(false);
				} else {
					closeReply();
				}

				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [updateCommentRequest.state]);

	return (
		Object.keys(comment).length > 0 && (
			<li
				className={`${comment?.parent ? "reply-item" : "comment-item"} ${
					comment.user.idx === info.idx && !comment.isBlinded && !isEdit ? "active" : ""
				}`}
			>
				{!isEdit ? (
					/*댓글 혹은 대댓글*/
					<div className="comment-view">
						{comment.isBlinded ? (
							<div className="comment-info">
								<div className="comment-text">
									{t(
										"Community.BoardPage.BoardPost.Detail.component.comment-text"
									)}
								</div>
							</div>
						) : (
							<>
								<div className="profile-img">
									<img
										src={comment?.user?.img ?? noImg}
										alt={t("Img.profile")}
										onError={(e) => {
											e.target.src = noImg;
										}}
									/>
								</div>

								<div className="comment-info">
									<div className="comment-name">{comment?.user?.name}</div>
									<div className="comment-text">
										{comment.parent && (
											<span className="comment-tag">
												@{comment.parent.user.name}
											</span>
										)}
										<span
											dangerouslySetInnerHTML={{
												__html: comment?.comment,
											}}
										/>
									</div>
									<ul className="comment-img">
										{comment.files.map((image, index) => (
											<li className="img-item" key={index}>
												<img
													src={image.path}
													alt={t(
														"Community.BoardPage.BoardPost.Detail.component.img-item"
													)}
												/>
											</li>
										))}
									</ul>
									<div className="comment-date">
										<span className="date">
											{dateToString(comment?.createdAt, "yyyy. MM. dd HH:mm")}
										</span>
										<button className="reply-button">
											{isReplyOn ? (
												<span
													className="reply-cancel"
													onClick={() => {
														closeReply();
													}}
												>
													{t(
														"Community.BoardPage.BoardPost.Detail.component.reply-cancel"
													)}
												</span>
											) : (
												<span
													className="reply-write"
													onClick={() => setIsReplyOn(true)}
												>
													{t(
														"Community.BoardPage.BoardPost.Detail.component.reply-write"
													)}
												</span>
											)}
										</button>
									</div>
								</div>
								{(myAuth === "MANAGER" || comment.user.idx === info.idx) && (
									<DropdownTemplate target={comment.idx} vertical>
										<DropDownItem
											className={
												comment.user.idx === info.idx ? "" : "disabled"
											}
											onClick={() => {
												closeReply();
												if (comment.user.idx === info.idx) setIsEdit(true);
											}}
										>
											{t("button.modify")}
										</DropDownItem>
										<DropDownItem onClick={deleteComment}>
											{t("button.delete")}
										</DropDownItem>
									</DropdownTemplate>
								)}
							</>
						)}
					</div>
				) : (
					/*댓글 혹은 대댓글 수정*/
					<div className="comment-view">
						<div className="profile-img">
							<img
								src={comment?.user?.img ?? noImg}
								alt={t("Img.profile")}
								onError={(e) => {
									e.target.src = noImg;
								}}
							/>
						</div>

						<div className="comment-info">
							<div className="comment-name">
								<span>{comment?.user?.name}</span>
							</div>
							<button
								className="edit-button"
								onClick={() => {
									replyEditForm.reset();
									setIsEdit(false);
								}}
							>
								{t("button.cancel")}
							</button>

							<div className="comment-edit">
								<div className="comment-box">
									<Form useForm={replyEditForm} className={"Community-ListForm"}>
										<Group>
											<span className="comment-tag">
												{comment.parent && `@${comment.parent.user.name}`}
											</span>
											<Textarea
												name="comment"
												placeholder={t("Community.textarea-placeholder")}
											/>
										</Group>
										<Group>
											<CommentUpload
												name="ImageList"
												type={UPLOAD_TYPE.COMMUNITY_COMMENT}
												files={comment.files}
												height="60px"
												width="60px"
												className={"community-img"}
												maxCount={5}
											/>
											<button
												className="btn__solid"
												color="primary"
												onClick={replyEditForm.handleSubmit(submit)}
											>
												{t("button.register")}
											</button>
										</Group>
									</Form>
								</div>
							</div>
						</div>
					</div>
				)}

				{/*대댓글 작성 폼*/}
				{isReplyOn && (
					<div className="comment-edit">
						<div className="comment-box">
							<Form useForm={replyForm} className={"Community-ListForm"}>
								<Group>
									<span className="comment-tag">@{comment.user.name}</span>
									<Textarea
										name="comment"
										placeholder={t("Community.textarea-placeholder")}
									/>
								</Group>
								<Group>
									<CommentUpload
										name="ImageList"
										type={UPLOAD_TYPE.COMMUNITY_COMMENT}
										height="60px"
										width="60px"
										className={"community-img"}
										maxCount={5}
									/>
									<button
										className="btn__solid"
										color="primary"
										onClick={replyForm.handleSubmit(submit)}
									>
										{t("button.register")}
									</button>
								</Group>
							</Form>
						</div>
					</div>
				)}

				{comment.children?.length > 0 && (
					<ul className="reply-list">
						{comment.children?.map((child, index) => (
							<Comment comment={child} key={index} commentRequest={commentRequest} />
						))}
					</ul>
				)}
			</li>
		)
	);
};
export default Comment;
