import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import useRequest from "~/common/hooks/useRequest";
import InviteModal from "./InviteModal";
import ExcelLoader from "~/common/components/Excel";
import { API_STATE } from "~/common/constants/state";


const AddMemberModal = ({
    inviteModal,
    setInviteModal,
    authMemberListRequest,
    teamInviteRequest
}) => {
    const { t } = useTranslation();
    const [step, setStep] = useState("form");
    const [excelData, setExcelData] = useState([]);
    const [modalWidth, setModalWidth] = useState(700);
    // const teamInviteRequest = useRequest("get", `admin/invite?size=100&page=1`);
    // const teamInviteRequest = useRequest("get", "admin/auth/member");
    const uploadFileRequest = useRequest("post", "/admin/inviteMember/uploadFile");


    const handleUpload = (data) => {
        setExcelData(data)
    }

    useEffect(() => {
        switch (uploadFileRequest.state) {
            case API_STATE.done:
                const responseData = uploadFileRequest.response.data;
                console.log('responseData', responseData)
                // setImg(responseData);
                // setStatus("done");
                break;
            case API_STATE.loading:
                // setStatus("loading");
                break;
            case API_STATE.error:
                // setStatus("error");
                console.log("error", uploadFileRequest.error);
                break;
            default:
                break;
        }
    }, [uploadFileRequest.state, uploadFileRequest.response, uploadFileRequest.error]);

    const inviteModalHeader = (
        <div //className="team-add-modal-header-wrapper"
        >
            <div className="team-add-modal-header-wrapper">
                <h3>팀원 정보입력</h3>
                <ExcelLoader
                    format={[
                        {
                            email: "",
                            name: "",
                            joinDate: 'YYYY-MM-DD',
                            workStartDay: 'YYYY-MM-DD',
                            numOfRemainAnnualLeave: ""
                        }
                    ]}
                    name={'업로드'}
                    onAction={handleUpload}
                />
            </div>
            <div className="team-add-modal-sub-wrapper">
                <h4>초대할 팀원의 이름, 이메일을 입력해 주세요.</h4>
                <p>비밀번호는 자동으로 부여됩니다. 메일 재전송이 필요할 경우 팀원 목록 우측에 있는 버튼을 클릭해 주세요.</p>
            </div>
        </div>
    )

    return (
        <ModalTemplate
            modalState={inviteModal}
            setModalToggle={setInviteModal}
            width={modalWidth}
            height={490}
            className={"modal-team-invite"}
            title={'팀원 초대'}
        //headerComponent={step==='form' &&inviteModalHeader}
        //title={step!=='form' && '팀원초대'}
        >
            {inviteModalHeader}
            <InviteModal
                step={step}
                setStep={setStep}
                teamInviteRequest={teamInviteRequest}
                setModalWidth={setModalWidth}
                authMemberListRequest={authMemberListRequest}
                excelData={excelData}
                setExcelData={setExcelData}
            />
        </ModalTemplate>
    );
};
export default AddMemberModal;
