import React, { useEffect, useRef, useState } from "react";
import DropdownPortal from "~/common/components/DropdownPortal/index";

//Assets
import "~/assets/sass/component/dropdown.scss";

/**
 *
 * @param { Props } props
 *
 * @typedef Props
 * @property {*} children - dropdown 메뉴에 표시될 내용
 * @property {string} className - "c__dropdown" 같은 레벨의 className
 * @property {string} target - 특정하기 위한 id , 필수
 * @property {boolean} vertical - 기본 세로형(케밥) / false 일 때 가로형 (미트볼)
 * @property {string} wrapperClassName - "c__dropdown-wrap" 같은 레벨의 className
 */
const DropdownTemplate = (props) => {
	const [dropdownState, setDropdownState] = useState(false);
	const { children, className, target, vertical,wrapperClassName } = props;

	let ref = useRef(null);

	function handleClickOutside(e) {
		if (ref.current && !ref.current.contains(e.target)) {
			setDropdownState(false);
		}
	}

	useEffect(() => {
		if (dropdownState) {
			document.addEventListener("click", handleClickOutside, false);
			return () => {
				document.removeEventListener("click", handleClickOutside, true);
			};
		}
	}, [dropdownState]);

	return (
		<div id={`dropdown_${target}`} className={`c__dropdown-wrap ${wrapperClassName}`} ref={ref}>
			<button
				className={`btn-dropdown ${vertical === true ? "vertical" : "horiz"} ${
					dropdownState ? "visible" : ""
				}`}
				onClick={() => setDropdownState(!dropdownState)}
			>
				<span />
				<span />
				<span />
			</button>
			<DropdownPortal target={`dropdown_${target}`}>
				<ul
					className={`c__dropdown ${dropdownState ? "visible" : ""} ${className}`}
					onClick={() => setDropdownState(false)}
				>
					{children}
				</ul>
			</DropdownPortal>
		</div>
	);
};

export default DropdownTemplate;
DropdownTemplate.displayName = "DropdownTemplate";

DropdownTemplate.defaultProps = {
	width: 430,
	height: 245,
	className: "",
};

export const DropDownItem = ({ children, className, ...props }) => {
	return (
		<li
			className={className ? `${className + " c__dropdownItem"}` : "c__dropdownItem"}
			{...props}
		>
			{children}
		</li>
	);
};
