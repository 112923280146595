import React, {useEffect, useState} from "react";
import "src/assets/sass/component/imageUploader.scss";
import AlertTemplate from "../components/AlertPortal/AlertTemplate";
import { useTranslation } from "react-i18next";

const useAlert = () => {
	const { t } = useTranslation();
	const [modalState, setModalState] = useState(false);

	const [promiseInfo, setPromiseInfo] = useState({});

	const showAlert = () => {
		return new Promise(async (resolve, reject) => {
			setPromiseInfo({ resolve, reject });
			setModalState(true);
		});
	};


	/**
	 * @param { Props } props
	 *
	 * @typedef Props
	 * @property {string} message - 알림 메시지
	 * @property {string=} confirm - 확인 버튼 메시지
	 * @property {string=} reject - 취소 버튼 메시지
	 * @property {"info"|"warning"|"error"|"success"} type - 알림 종류
	 * @property {"string"} className
	 * @property {"vertical"|"horiz"} layout
	 * @property {number} width
	 * @property {number} height
	 * @property {string} desc - 부가 내용 추가 시
	 * @property {boolean} mask - 배경 유무
	 */
	const AlertComponent = ({
		message,
		confirm = t("button.check"),
		reject,
		type,
		className,
		layout,
		width,
		height = 230,
		desc,
		mask,
								handleConfirm
	}) => {
		return (
			<AlertTemplate
				alertModalState={modalState}
				setAlertModalToggle={setModalState}
				alertType={type}
				{...{ className, layout, width, height, mask }}
			>
				<div className="alert-content">
					<div className="modal-text pre-wrap">{message}</div>
				</div>
				{desc && desc}
				<div className="alert-footer">
					{reject && (
						<button
							type="button"
							className="btn__solid"
							color="gray"
							onClick={() => {
								promiseInfo.reject();
								setModalState(false);
							}}
						>
							{reject}
						</button>
					)}
					<button
						type="button"
						className="btn__solid"
						color={type === "error" ? "red" : "primary"}
						onClick={() => {
							if(handleConfirm) {
								handleConfirm()
							} else {
								promiseInfo.resolve();
								setModalState(false);
							}

						}}

						autoFocus
					>
						{confirm}
					</button>
				</div>
			</AlertTemplate>
		);
	};

	return [showAlert, React.memo(AlertComponent)];
};

export default useAlert;
