import React, { useEffect, useState } from "react";
import { ClipIcon, FileDeleteIcon, UploadIcon } from "~/assets/svg/InterfacesIcon";
import "~/assets/sass/component/fileUploader.scss";
import { useController } from "react-hook-form";
import useAlert from "~/common/hooks/useAlert";
import { API_STATE } from "~/common/constants/state";
import useFileUpload from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/file/useFileUpload";
import { useTranslation } from "react-i18next";

const Uploader = ({ type, name, control, required, format = "*" }) => {
	const { t } = useTranslation();
	const {
		field: { onChange },
	} = useController({
		control,
		name,
		rules: {
			required:
				required === true
					? t("Invest.Round.RoundDetailModal.Edit.componentFile.required")
					: required === null
					? " "
					: required,
		},
	});

	const { url, upload, clear, state } = useFileUpload(type);
	const [showErrorAlert, ErrorAlert] = useAlert();
	const [fileName, setFileName] = useState("");

	useEffect(() => {
		switch (state) {
			case API_STATE.error:
				showErrorAlert();
				setFileName("");
				break;
			default:
				break;
		}
	}, [state]);

	useEffect(() => {
		onChange(url);
	}, [url]);

	return (
		<>
			<div className="c__fileUploader">
				<div className="file-preview">
					<ClipIcon />
					<p className="file-name">{fileName}</p>
					<button
						type="button"
						className="btn-delete"
						onClick={() => {
							clear();
							setFileName("");
						}}
					>
						<FileDeleteIcon />
					</button>
				</div>
				<label className="file-label" htmlFor={"file-input"}>
					<input
						type="file"
						accept={format}
						name="file-input"
						className="file-input"
						onChange={({ target }) => {
							const newTarget = [...target.files];
							newTarget.length > 0 && upload(newTarget);
							setFileName(newTarget[0].name);
							target.value = "";
						}}
					/>
					<UploadIcon />
					{t("Invest.Round.RoundDetailModal.Edit.componentFile.file-input")}
				</label>
			</div>
			<ErrorAlert
				message={t("Invest.Round.RoundDetailModal.Edit.componentFile.file-alert")}
				type="error"
				confirm={t("button.check")}
			/>
		</>
	);
};

export default Uploader;
