import React, { useEffect } from "react";
import { useGlobalStore } from "~/store";
// import useChannelBoot from "~/common/hooks/useChannelBoot";
import { useLocation } from "react-router";

const BaseRouter = () => {
	const location = useLocation();
	const [
		{
			user: { info },
		},
	] = useGlobalStore();
	// const channelBoot = useChannelBoot(info);

	// useEffect(() => {
	// 	channelBoot();
	// }, [info, location]);

	return <></>;
};

export default BaseRouter;
