import React, { useEffect, useState } from "react";

import { Case, If } from "~/common/components/If";

import "~/assets/sass/pages/externalRound/index.scss";
import { useSearchParams } from "react-router-dom";
import { useGlobalStore } from "~/store";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import { setCurrentRound } from "~/store/round/actions";
import Loading from "~/common/components/Loading";
import logo from "~/assets/img/logo.svg";
import View from "~/pages/ExternalRoundPage/View";
import NotFound from "~/pages/ExternalRoundPage/NotFound";
import cryptoData from "~/utils/cryptoData";

const ExternalRoundPage = () => {
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [, dispatch] = useGlobalStore();
	const [searchParams] = useSearchParams();
	const params = searchParams.get("idx");
	const [roundIdx, setRoundIdx] = useState(null);

	useEffect(() => {
		if (params) {
			setRoundIdx(cryptoData.dectypt(params)?.idx);
		}
	}, [params]);

	const roundDetailRequest = useRequest("get", "round/external/detail");

	useEffect(() => {
		if (roundIdx) {
			roundDetailRequest.singleCall(roundIdx, "path");
		}
	}, [roundDetailRequest, roundIdx]);

	useEffect(() => {
		switch (roundDetailRequest.state) {
			case API_STATE.done:
				const responseData = roundDetailRequest.response.data;
				dispatch(
					setCurrentRound({
						round: { ...responseData.round, managerImg: responseData.managerImg },
						content: responseData.round.content,
						faq: responseData.round.Qna,
						files: responseData.files,
					})
				);
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("Error: ", roundDetailRequest.error);
				setPageState(PAGE_STATE.EMPTY);
				break;
		}
	}, [roundDetailRequest.state]);

	return (
		<div className="p__round-external">
			<nav className="l__nav">
				<div className="logo-wrap">
					<h1 className="logo">
						<img src={logo} alt="로고" />
					</h1>
				</div>
			</nav>
			<div className="l__mainContainer">
				<main className="l__main" id="l__main">
					<div className="l__content">
						<If condition={pageState}>
							<Case condition={PAGE_STATE.LOADING}>
								<Loading />
							</Case>
							<Case condition={PAGE_STATE.EMPTY}>
								<NotFound />
							</Case>
							<Case condition={PAGE_STATE.VIEW}>
								<View />
							</Case>
						</If>
					</div>
				</main>
			</div>
		</div>
	);
};

export default ExternalRoundPage;
