import React from "react";
import defaultAvatar from "~/assets/img/defaultImg/profile-large.png";
import { differenceInMinutes } from "date-fns";
import { timeToDateFormatter } from "~/utils/formatter";

const Detail = ({ daySchedule, selectedDate, selectedDateType }) => {
	function diffTimes(start, end) {
		const diffMinutes = differenceInMinutes(
			timeToDateFormatter(end),
			timeToDateFormatter(start)
		);
		const hour = parseInt(diffMinutes / 60) !== 0 ? parseInt(diffMinutes / 60) : 0;

		return diffMinutes % 60 === 0 ? hour + "H" : hour + ".5H";
	}

	const scheduleRow = (type) => {
		return daySchedule.map((schedule, s_idx) => (
			<li key={s_idx}>
				<span className="img-wrap">
					<img
						src={schedule.User.img ?? defaultAvatar}
						onError={(e) => {
							e.target.src = defaultAvatar;
						}}
						alt="프로필 이미지"
					/>
				</span>
				<p className="name">{schedule.User.name}</p>
				{type === "vacations" ? (
					<>
						<p className="desc">
							{schedule.type === "연차"
								? schedule.timeUnit !== "하루종일"
									? `${schedule.timeUnit}(${schedule.startTime.slice(
											0,
											5
									  )} ~ ${schedule.endTime.slice(0, 5)})`
									: `${schedule.HrVacation.title}(${schedule.timeUnit})`
								: `${schedule.type}(${schedule.startTime.slice(
										0,
										5
								  )} ~ ${schedule.endTime.slice(0, 5)})`}
						</p>
						<div className="badge-wrap">
							{schedule.type === "연차" && schedule.timeUnit === "시간반차" && (
								<span className="badge vacation-hour">
									{diffTimes(schedule.startTime, schedule.endTime)}
								</span>
							)}
							{schedule.status && (
								<span
									className={`badge confirm ${
										schedule.status === "완료" ? "done" : "waiting"
									}`}
								>
									{schedule.status}
								</span>
							)}
						</div>
					</>
				) : (
					<p className="desc">
						{schedule.startTime.slice(0, 5)} ~ {schedule.endTime.slice(0, 5)}
					</p>
				)}
			</li>
		));
	};

	return (
		<>
			<div className="detail-header">
				<span className={`type-color ${selectedDateType?.type}`} />
				<dl>
					<dt>{selectedDateType?.typeName}</dt>
					<dd>{selectedDate}</dd>
				</dl>
			</div>
			<ul className="detail-list">{scheduleRow(selectedDateType?.type)}</ul>
		</>
	);
};

export default Detail;
