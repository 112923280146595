import React from "react";
import { VACATION_CLASSNAME, VACATION_STATUS, VACATION_STATUS_TEXT } from "~/common/constants/form";
import { DateWithDayName } from "~/utils/formatter";

const List = ({
	vacationList,
	setSelectedVacation,
	setVacationManageModal,
	titleText,
	timeText,
	currentPath,
	showAuthAlert,
}) => {
	/*const fileClassName = (vacation) => {
		let result;
		if (vacation.HrVacationType.documents === "pre") {
			if (vacation.HrVacationFiles.length > 0) {
				result = "done";
			} else {
				result = "no-submit";
			}
		} else if (vacation.HrVacationType.documents === "post") {
			if (vacation.HrVacationFiles.length > 0) {
				result = "done";
			} else {
				result = "later";
			}
		} else {
			result = "";
		}
		return result;
	};

	const fileText = (vacation) => {
		switch (fileClassName(vacation)) {
			case "no-submit":
				return "증명자료 미제출";
			case "done":
				return "증명자료 제출 완료";
			case "later":
				return "증명자료 사후 제출";
			case "":
				return "-";
		}
	};*/

	const handleClick = (v) => {
		if (currentPath.auth === "view") {
			showAuthAlert();
		} else {
			setSelectedVacation(v);
			setVacationManageModal(true);
		}
	};

	return vacationList.upcoming.data.map((v, v_idx) => (
		<div className="row" onClick={() => handleClick(v)} key={v_idx}>
			<div className={`vacation-name ${VACATION_CLASSNAME[v.HrVacation.title] ?? "others"}`}>
				{titleText(v)}
			</div>
			<div className="date">
				{v.startDate === v.endDate
					? DateWithDayName(v.startDate)
					: `${DateWithDayName(v.startDate)}
							   ~ 
							  ${DateWithDayName(v.endDate)}`}
			</div>
			<div className="time">{timeText(v)}</div>
			{/*<div className="evidence">*/}
			{/*	/!*<span className={`${fileClassName(v.HrVacation)}`}>{fileText(v.HrVacation)}</span>*!/*/}
			{/*	<span >{v.HrVacation.memo}</span>*/}
			{/*</div>*/}
			<div className="permission">
				<span className={VACATION_STATUS[v.status]}>
					{VACATION_STATUS_TEXT[VACATION_STATUS[v.status]]}
				</span>
			</div>
		</div>
	));
};
export default List;
