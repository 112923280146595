import React, { useState } from "react";
import DropdownTemplate from "~/common/components/DropdownPortal/DropdownTemplate";
import defaultAvatar from "~/assets/img/defaultImg/profile-large.png";
import { useGlobalStore } from "~/store";
import { useTranslation } from "react-i18next";

const RequestMsg = () => {
	const { t } = useTranslation();
	const [
		{
			evaluate: {
				currentEvaluate: { title, Judge, content, VcCompany },
			},
		},
	] = useGlobalStore();
	const [evaluateListDropdown, setEvaluateListDropdown] = useState(false);

	return (
		<div className="content">
			<div className="top">
				<h3 className="title">{title}</h3>
				<div className="dropdown-wrap">
					<div id={"dropdown"}>
						<DropdownTemplate
							target={`dropdown_1`}
							className={"judge-list-wrap"}
							layout={"horiz"}
						>
							<div className="top">
								<span>
									{t(
										"Invest.Evaluate.EvaluateDetail.View.components.requestMsg.topText"
									)}
								</span>
								<span className="num">{Judge.length}</span>
							</div>
							{Judge.map((judge, j_idx) => {
								return (
									<li className="c__dropdownItem" key={j_idx}>
										<span className="img-wrap">
											<img
												src={judge.img ? judge.img : defaultAvatar}
												onError={(e) => {
													e.target.src = defaultAvatar;
												}}
												alt={judge.name}
											/>
										</span>
										<dl>
											<dt>{judge.name}</dt>
											<dd>{VcCompany.name}</dd>
										</dl>
									</li>
								);
							})}
						</DropdownTemplate>
					</div>
					<ul
						className="judge-summary-list"
						onClick={() => setEvaluateListDropdown(!evaluateListDropdown)}
					>
						{Judge.map((judge, j_idx) => {
							return (
								<li key={j_idx} className="img-wrap">
									<img
										src={judge.img ? judge.img : defaultAvatar}
										onError={(e) => {
											e.target.src = defaultAvatar;
										}}
										alt={judge.name}
									/>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
			<div className="text">
				<p>{content}</p>
			</div>
			<dl className="vc">
				<dt>{VcCompany.name}</dt>
				<dd>{Judge[0].name}</dd>
			</dl>
		</div>
	);
};

export default RequestMsg;
