import React from "react";
import Form from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import MarketDynamicFields from "~/pages/ir/IrDetail/Product/Edit/MarketSection/MarketDynamicFields";
import PlayerDynamicFields from "~/pages/ir/IrDetail/Product/Edit/MarketSection/PlayerDynamicFields";
import { useTranslation } from "react-i18next";

const MarketSection = ({ useForm }) => {
	const { t } = useTranslation();
	return (
		<>
			<div className="section-title">
				{t("IR.IrDetail.Product.Edit.MarketSection.Index.market")}
			</div>
			<Form useForm={useForm} className="sub-section vertical">
				<Textarea
					label={t("IR.IrDetail.Product.Edit.MarketSection.Index.research")}
					name="Product.marketResearch"
				/>
				<Textarea
					label={t("IR.IrDetail.Product.Edit.MarketSection.Index.positioning")}
					name="Product.positioning"
				/>
			</Form>
			<MarketDynamicFields
				useForm={useForm}
				name="Market"
				title={t("IR.IrDetail.Product.Edit.MarketSection.Index.title1")}
			/>
			<PlayerDynamicFields
				useForm={useForm}
				name="Player"
				title={t("IR.IrDetail.Product.Edit.MarketSection.Index.title2")}
			/>
		</>
	);
};

export default MarketSection;
