import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import translation from "~/translations";

// Components
import useAlert from "~/common/hooks/useAlert";

// Assets
import { AccordionArrowIcon } from "~/assets/svg/LandingIcon";

const CommunitySection = () => {
	const { t } = useTranslation();
	const langImg = i18next.language;
	const [collapse, setCollapse] = useState(false);
	const collapseToggle = () => {
		setCollapse(!collapse);
	};

	const benefitCardList = [
		{
			num: "1",
			desc: translation.t("Landing.Section.CommunitySection.benefitCardList.1"),
		},
		{
			num: "2",
			desc: translation.t("Landing.Section.CommunitySection.benefitCardList.2"),
		},
		{
			num: "3",
			desc: translation.t("Landing.Section.CommunitySection.benefitCardList.3"),
		},
		{
			num: "4",
			desc: translation.t("Landing.Section.CommunitySection.benefitCardList.4"),
		},
		{
			num: "5",
			desc: translation.t("Landing.Section.CommunitySection.benefitCardList.5"),
		},
		{
			num: "6",
			desc: translation.t("Landing.Section.CommunitySection.benefitCardList.6"),
		},
		{
			num: "7",
			desc: translation.t("Landing.Section.CommunitySection.benefitCardList.7"),
		},
		{
			num: "8",
			desc: translation.t("Landing.Section.CommunitySection.benefitCardList.8"),
		},
		{
			num: "9",
			desc: translation.t("Landing.Section.CommunitySection.benefitCardList.9"),
		},
		{
			num: "10",
			desc: translation.t("Landing.Section.CommunitySection.benefitCardList.10"),
		},
		{
			num: "11",
			desc: translation.t("Landing.Section.CommunitySection.benefitCardList.11"),
		},
		{
			num: "12",
			desc: translation.t("Landing.Section.CommunitySection.benefitCardList.12"),
		},
	];

	const [showAlert, Alert] = useAlert();

	const [animatedStyle, setAnimatedStyle] = useState(0);

	function handleScroll() {
		const topSection = document.getElementById("community-section");
		if (
			typeof topSection !== "object" ||
			topSection === null ||
			!("getBoundingClientRect" in topSection)
		)
			return;
		const topSectionSize = topSection?.getBoundingClientRect();
		const topSectionBottom = topSectionSize.bottom;

		if (topSectionBottom < window.innerHeight) {
			const size = (window.innerHeight - topSectionBottom) / 4;
			if (size > 50) {
				setAnimatedStyle(50);
			} else {
				setAnimatedStyle(size);
			}
		} else {
			setAnimatedStyle(0);
		}
	}

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<>
			<section
				id={`community-section`}
				style={
					window.innerWidth > 950
						? {
								width: `calc(100% - ${animatedStyle}px)`,
								borderRadius: animatedStyle,
						  }
						: null
				}
			>
				<div className="community-wrap">
					<div className="top">
						<p className="main-desc pre-wrap">
							{t("Landing.Section.CommunitySection.main-desc")}
						</p>
						<p className="sub-desc pre-wrap">
							{t("Landing.Section.CommunitySection.sub-desc")}
						</p>
						<button
							type="button"
							className="btn__solid text-up angled"
							color="primary"
							onClick={showAlert}
						>
							<span className={langImg === "ko" ? "" : "lang"}>백</span>
							<span className={langImg === "ko" ? "" : "lang"}>서</span>
							&nbsp;
							<span>W</span>
							<span>h</span>
							<span>i</span>
							<span>t</span>
							<span>e</span>
							&nbsp;
							<span>P</span>
							<span>a</span>
							<span>p</span>
							<span>e</span>
							<span>r</span>
						</button>
					</div>
					<div className="bottom">
						<h2>{t("Landing.Section.CommunitySection.bottom.title")}</h2>
						<div className={`card-wrap ${langImg === "ko" ? "" : "lang"}`}>
							{benefitCardList.map((benefit, i) => {
								return (
									<div
										className={`c__card benefit-item ${
											collapse || (benefit.num < 7 && "active")
												? "active"
												: "inactive"
										}`}
										key={i}
									>
										<div className="benefit-num">{benefit.num}</div>
										<div className="benefit-desc pre-wrap">{benefit.desc}</div>
									</div>
								);
							})}
						</div>
					</div>
					<button
						type="button"
						className={`btn-collapse ${collapse ? "close" : ""}`}
						onClick={collapseToggle}
					>
						{collapse
							? t("Landing.Section.CommunitySection.bottom.button.close")
							: t("Landing.Section.CommunitySection.bottom.button.open")}
						<AccordionArrowIcon />
					</button>
				</div>
			</section>

			{/*준비중 alert 모달*/}
			<Alert
				message={t("Landing.Section.CommunitySection.Alert")}
				confirm={t("button.check")}
				type="info"
			/>
		</>
	);
};

export default CommunitySection;
