import { setUser } from "~/store/user/actions";
import { setSidebarList } from "~/store/sidebar/actions";
import { logout } from "../auth/actions";

export const USER_INFO = {
	method: "get",
	url: "user/info",
	onSuccess: async ({ response, dispatch }) => {
		const responseData = response.data;
		const mainSidebar = responseData.menu.main;

		const myPageSidebar =
			responseData.user.role !== "admin"
				? responseData.menu.myPage.filter((m) => m.id !== "plugin")
				: responseData.menu.myPage;

		await dispatch(setUser(responseData.user));
		await dispatch(setSidebarList({ main: mainSidebar, myPage: myPageSidebar }));
		return responseData;
	},
	onError: ({ error, dispatch, navigate }) => {
		switch (error.statusCode) {
			case 401:
				console.log("세션이 만료되었습니다.");
				navigate("/login");
				dispatch(logout());
				break;
			default:
				console.log("error", error);
				break;
		}
		return error;
	},
};
