import React from "react";
import { useNavigate } from "react-router";
import EmptyCase from "~/common/components/EmptyCase";
import { useTranslation } from "react-i18next";
import AuthButton from "~/common/components/AuthButton";

const Empty = ({ Header }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const buttonList = [
		<AuthButton
			type="button"
			className="btn__solid btn-edit"
			color="primary"
			onClick={() => navigate("/company/product/add")}
		>
			등록하기
		</AuthButton>,
	];

	return (
		<>
			<Header buttonList={buttonList} />
			<EmptyCase
				main={t("Company.Product.List.empty.main")}
				sub={t("Company.Product.List.empty.sub")}
			/>
		</>
	);
};

export default Empty;
