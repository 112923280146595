import React from "react";
import { VACATION_CLASSNAME, VACATION_STATUS, VACATION_STATUS_TEXT } from "~/common/constants/form";
import { DateWithDayName } from "~/utils/formatter";

const List = ({
	vacationList,
	setVacationManageModal,
	setSelectedVacation,
	titleText,
	timeText,
	currentPath,
	showAuthAlert,
}) => {
	const handleClick = (v) => {
		if (currentPath.auth === "view") {
			showAuthAlert();
			return;
		}
		setVacationManageModal(true);
		setSelectedVacation(v);
	};

	return vacationList.previous.data.map((v, v_idx) => (
		<div className="row" onClick={() => handleClick(v)} key={v_idx}>
			<div className={`vacation-name ${VACATION_CLASSNAME[v.HrVacation.title] ?? "others"}`}>
				{titleText(v)}
			</div>
			<div className="date">
				{v.startDate === v.endDate
					? DateWithDayName(v.startDate)
					: `${DateWithDayName(v.startDate)} ~ ${DateWithDayName(v.endDate)}`}
			</div>
			<div className="time">{timeText(v)}</div>
			{/*<div className="evidence">*/}
			{/*	/!*<span className={`${fileClassName(v.HrVacation)}`}>{fileText(v.HrVacation)}</span>*!/*/}
			{/*	<span>{v.HrVacation.memo}</span>*/}
			{/*</div>*/}
			<div className="permission">
				<span className={VACATION_STATUS[v.status]}>
					{VACATION_STATUS_TEXT[VACATION_STATUS[v.status]]}
				</span>
			</div>
		</div>
	));
};
export default List;
