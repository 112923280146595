import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAtomValue } from "jotai";
import { languageAtom } from "~/atoms/translation";

/**
 * Input component for useForm
 *
 * @param { Props } props
 *
 * @typedef Props
 * @property {import"react-hook-form".UseFormRegister} register
 * @property {string=} placeholder
 * @property {string} name
 * @property {string=} type
 * @property {Validation} validation
 * @property {boolean} required
 * @property {function} onEnter
 * @property {number} maxLength
 * @property {function} onInput
 *
 * @typedef Validation
 * @property {string|boolean} required
 * @property {string|number} min
 * @property {string|number} max
 * @property {number | {value: number, message: string}} maxLength
 * @property {number | {value: number, message: string}} minLength
 * @property {RegExp} pattern
 */
const Input = (props) => {
	const {
		register,
		placeholder,
		name,
		type,
		validation,
		required,
		key,
		disabled,
		readonly,
		onEnter = () => {},
		unit,
		autoComplete,
		maxLength,
		onInput,
	} = props;
	const { t } = useTranslation();
	return (
		<>
			<input
				key={key}
				name={name}
				className={`c__input f__item`}
				placeholder={placeholder}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						onEnter();
					}
				}}
				type={type}
				{...register(name, {
					required:
						required === true
							? t("Validate.require")
							: required === null
							? " "
							: required,
					...validation,
				})}
				disabled={disabled}
				readOnly={readonly}
				autoComplete={autoComplete}
				maxLength={maxLength}
				onInput={onInput}
			/>
			{unit !== "" && <span className="unit">{unit}</span>}
		</>
	);
};

export default Input;

Input.defaultProps = {
	type: "text",
};
