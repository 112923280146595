export const NODE_ENV =
	process.env.REACT_APP_ENV === undefined ? "development" : process.env.REACT_APP_ENV;

export const BUILD_TIME =
	process.env.REACT_APP_BUILD_TIME === undefined
		? "REALTIME"
		: process.env.REACT_APP_BUILD_TIME.replace(
				/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/,
				`$1.$2.$3 $4:$5`
		  );

export const API_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_URL}`;

export const EM_NEWS_API_URL = `${process.env.REACT_APP_EM_API_PROTOCOL}://${process.env.REACT_APP_EM_NEWS_API_URL}`;
export const EM_COMPANY_API_URL = `${process.env.REACT_APP_EM_API_PROTOCOL}://${process.env.REACT_APP_EM_COMPANY_API_URL}`;

export const OKCEO_API_URL = `${process.env.REACT_APP_OKCEO_API_PROTOCOL}://${process.env.REACT_APP_OKCEO_API_URL}`;

export const REQUEST_CREDENTIALS = process.env.REACT_APP_API_REQUEST_CREDENTIALS;

export const WEB_URL = `${NODE_ENV === "production" ? "https" : "http"}://${
	NODE_ENV === "production"
		? "startup.gitbal.io"
		: NODE_ENV === "development"
		? "220.118.147.58:20070"
		: "localhost:3000"
}/`;
