import React, { useEffect } from "react";

// Components
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import useRequest from "~/common/hooks/useRequest";
import PageHeader from "~/common/components/PageHeader";
import { API_STATE } from "~/common/constants/state";

// Assets
import "~/assets/sass/pages/mypage/alarm/alarm.scss";
import View from "~/pages/mypage/AlarmPage/View";
import { useTranslation } from "react-i18next";
import useRepository from "~/common/hooks/useRepository";
import { ALARM_LIST } from "~/store/alarm/repository";

const AlarmPage = () => {
	const { t } = useTranslation();

	const readAllAlarmRequest = useRequest("patch", "user/alarm/readAll");
	const [getAlarmList] = useRepository(ALARM_LIST);

	useEffect(() => {
		switch (readAllAlarmRequest.state) {
			case API_STATE.done:
				getAlarmList();
				break;
			case API_STATE.error:
				console.error(readAllAlarmRequest.error);
				break;
			default:
				break;
		}
	}, [readAllAlarmRequest.state]);

	const buttonList = [
		<button className="btn__solid" color="primary" onClick={() => readAllAlarmRequest.call()}>
			{t("MyPage.AlarmPage.all")}
		</button>,
	];

	return (
		<NavbarAndSidebarLayout menu={["alarm", "list"]} type="main">
			<PageHeader buttonList={buttonList} mainTitle="알람" subTitle="전체알람" />
			<View />
		</NavbarAndSidebarLayout>
	);
};

export default AlarmPage;
