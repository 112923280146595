import React, { useEffect, useState } from "react";
import { Case, If } from "~/common/components/If";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import View from "~/pages/invest/RoundPage/modal/RoundDetailModal/View";
import { useGlobalStore } from "~/store";
import { setCurrentRound, setRoundSurvey } from "~/store/round/actions";
import { useTranslation } from "react-i18next";

const RoundDetailModal = (props) => {
	const { t } = useTranslation();
	const { selectedIdx, setModalToggle, getRoundList, roundStatus, ROUND_STATUS } = props;
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [, dispatch] = useGlobalStore();
	const roundDetailRequest = useRequest("get", "round/detail");
	const companyProductRequest = useRequest("get", "company/product");
	const [isProductExist, setIsProductExist] = useState(false);
	useEffect(() => {
		roundDetailRequest.singleCall(selectedIdx, "path");
	}, [roundDetailRequest]);

	useEffect(() => {
		companyProductRequest.singleCall();
	}, []);

	useEffect(() => {
		switch (companyProductRequest.state) {
			case API_STATE.done:
				const productList = companyProductRequest.response.data;
				setIsProductExist(productList && productList?.length > 0);
				break;
			default:
				break;
		}
	}, [companyProductRequest.state, companyProductRequest.response, dispatch]);

	useEffect(() => {
		switch (roundDetailRequest.state) {
			case API_STATE.done:
				const responseData = roundDetailRequest.response.data;
				dispatch(
					setCurrentRound({
						round: { ...responseData.round, apply: responseData.apply, managerImg: responseData.managerImg },
						content: responseData.round.content,
						faq: responseData.round.Qna,
						files: responseData.files,
					})
				);
				dispatch(
					setRoundSurvey({
						survey: responseData.apply
							? responseData.answer?.result
							: responseData.query,
					})
				);
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				alert(t("Alert.alert2"));
				break;
		}
	}, [roundDetailRequest.state]);

	return (
		<>
			<If condition={pageState}>
				<Case condition={PAGE_STATE.LOADING}>
					<Loading />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<View
						{...{
							setModalToggle,
							getRoundList,
							roundStatus,
							ROUND_STATUS,
							isProductExist,
						}}
					/>
				</Case>
			</If>
		</>
	);
};

export default RoundDetailModal;
