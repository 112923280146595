import React from "react";
import useAlert from "~/common/hooks/useAlert";

// Asset
import { PrintIcon, SingleArrowIcon } from "~/assets/svg/InterfacesIcon";

const Print = () => {
	const [showPrintAlert, PrintAlert] = useAlert();

	const checkPrintAlert = () => {
		const hideUntil = localStorage.getItem("hideUntil");

		if (!hideUntil)
			showPrintAlert().then(() => {
				window.print();
			});
		else if (hideUntil) {
			const hideUntilDate = new Date(hideUntil);
			const currentDate = new Date();

			if (currentDate < hideUntilDate) {
				window.print();
			} else {
				showPrintAlert().then(() => {
					window.print();
				});
			}
		}
	};

	// 인쇄 안내창
	const hidePrintAlertHandler = (e) => {
		if (!e.target.checked) {
			localStorage.removeItem("hideUntil");
		} else {
			const currentDate = new Date();
			const hideUntilDate = new Date();
			hideUntilDate.setDate(currentDate.getDate() + 7);
			localStorage.setItem("hideUntil", hideUntilDate);
		}
	};

	return (
		<>
			<button className="btn-circle" onClick={checkPrintAlert}>
				<PrintIcon />
			</button>
			<PrintAlert
				type="info"
				className="print-alert-modal"
				layout="horiz"
				width={420}
				height={330}
				message={
					<>
						<h3 className="print-title-wrap">
							아래와 같은 설정을 하셔야
							<br />
							정상적으로 인쇄할 수 있습니다.
						</h3>
					</>
				}
				desc={
					<>
						<div className="desc-box">
							<div className="desc-title">깃발 인쇄 설정 방법</div>
							<p className="desc-content">
								설정 더보기 <SingleArrowIcon /> 배율 : 맞춤 설정 클릭 후 50 입력
							</p>
						</div>
						<div className="checkbox">
							<label className={`checkbox-custom`}>
								<input onChange={hidePrintAlertHandler} type="checkbox" />
								<span className="checkmark" />
								<span className="checkbox-label">일주일 동안 보지 않기</span>
							</label>
						</div>
					</>
				}
				confirm="확인"
			/>
		</>
	);
};

export default Print;
