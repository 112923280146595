import React from "react";

//둥근 X 아이콘
export const RoundXIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14px"
			height="14px"
			viewBox="0 0 14 14"
			fill="#ff8787"
		>
			<path d="M10.218,9.763 L7.611,7.181 L10.192,4.577 L9.388,3.780 L6.808,6.386 L4.203,3.807 L3.405,4.604 L6.13,7.188 L3.431,9.795 L4.229,10.593 L6.815,7.984 L9.421,10.567 L10.218,9.763 ZM6.812,0.374 C3.49,0.374 0.0,3.424 0.0,7.187 C0.0,10.949 3.49,13.999 6.812,13.999 C10.574,13.999 13.624,10.949 13.624,7.187 C13.624,3.424 10.574,0.374 6.812,0.374 ZM6.812,1.509 C9.942,1.509 12.489,4.56 12.489,7.187 C12.489,10.317 9.942,12.864 6.812,12.864 C3.681,12.864 1.134,10.317 1.134,7.187 C1.134,4.56 3.681,1.509 6.812,1.509 Z" />
		</svg>
	);
};

//둥근 체크 아이콘
export const RoundChkIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14px"
			height="14px"
			viewBox="0 0 14 14"
			fill="#369303"
		>
			<path d="M9.680,4.632 L6.477,7.915 L4.976,6.493 L3.922,7.548 L6.477,10.25 L10.735,5.687 L9.680,4.632 ZM7.187,0.374 C3.424,0.374 0.374,3.424 0.374,7.186 C0.374,10.949 3.424,13.999 7.187,13.999 C10.949,13.999 13.999,10.949 13.999,7.186 C13.999,3.424 10.949,0.374 7.187,0.374 ZM7.187,1.510 C10.317,1.510 12.863,4.56 12.863,7.186 C12.863,10.317 10.317,12.864 7.187,12.864 C4.56,12.864 1.509,10.317 1.509,7.186 C1.509,4.56 4.56,1.510 7.187,1.510 Z" />
		</svg>
	);
};

//전화 아이콘
export const PhoneIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="13px" height="18px" viewBox="0 0 18 18">
			<path d="M12.901,16.853 L10.279,11.791 C10.274,11.794 8.808,12.514 8.741,12.544 C7.72,13.353 3.675,6.718 5.306,5.843 L6.858,5.78 L4.256,0.0 C4.255,0.0 2.713,0.761 2.687,0.773 C-2.678,3.571 5.841,20.132 11.330,17.623 C11.420,17.582 12.896,16.856 12.901,16.853 Z" />
		</svg>
	);
};

//수정 아이콘
export const ModifyIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="13px" height="13px" viewBox="0 0 13 13">
			<path d="M12.364,4.353 L11.942,4.776 C11.529,5.189 10.862,5.189 10.449,4.776 L8.857,3.185 C8.445,2.773 8.445,2.105 8.857,1.692 L9.280,1.269 C9.693,0.858 10.360,0.858 10.773,1.269 L12.364,2.861 C12.777,3.273 12.777,3.941 12.364,4.353 ZM10.314,6.403 L4.670,12.47 C4.648,12.70 4.620,12.85 4.588,12.92 L0.978,12.851 C0.861,12.875 0.759,12.772 0.783,12.656 L1.542,9.46 C1.548,9.15 1.565,8.986 1.587,8.963 L7.230,3.320 C7.294,3.255 7.399,3.255 7.463,3.320 L10.314,6.170 C10.378,6.235 10.378,6.339 10.314,6.403 Z" />
		</svg>
	);
};

//구성원 추가 아이콘
export const MemberAddIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="14px" viewBox="0 0 16 14">
			<path d="M14.730,4.129 L12.924,4.129 L12.924,5.874 C12.924,6.145 12.697,6.364 12.416,6.364 C12.136,6.364 11.909,6.145 11.909,5.874 L11.909,4.129 L10.103,4.129 C9.823,4.129 9.596,3.910 9.596,3.639 C9.596,3.369 9.823,3.150 10.103,3.150 L11.909,3.150 L11.909,1.404 C11.909,1.134 12.136,0.915 12.416,0.915 C12.697,0.915 12.924,1.134 12.924,1.404 L12.924,3.150 L14.730,3.150 C15.010,3.150 15.237,3.369 15.237,3.639 C15.237,3.910 15.010,4.129 14.730,4.129 ZM6.593,6.296 C7.302,6.296 7.975,6.036 8.488,5.564 C8.691,5.379 9.011,5.385 9.205,5.581 C9.399,5.778 9.391,6.087 9.188,6.274 C8.991,6.455 8.774,6.607 8.547,6.740 C11.084,7.538 12.924,9.841 12.924,12.554 C12.924,12.825 12.697,13.044 12.416,13.044 C12.136,13.044 11.909,12.825 11.909,12.554 C11.909,9.723 9.525,7.419 6.593,7.419 C3.662,7.419 1.279,9.723 1.279,12.554 C1.279,12.825 1.052,13.044 0.772,13.044 C0.492,13.044 0.265,12.825 0.265,12.554 C0.265,9.837 2.109,7.532 4.650,6.737 C3.564,6.099 2.830,4.955 2.830,3.639 C2.830,1.634 4.518,0.004 6.593,0.004 C7.537,0.004 8.441,0.343 9.137,0.959 C9.343,1.141 9.358,1.451 9.169,1.650 C8.980,1.851 8.659,1.863 8.452,1.681 C7.943,1.231 7.283,0.983 6.593,0.983 C5.078,0.983 3.844,2.175 3.844,3.639 C3.844,5.104 5.078,6.296 6.593,6.296 Z" />
		</svg>
	);
};

// 자물쇠 열림 아이콘
export const UnLockIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path d="M13,9c0-1.7-1.3-3-3-3S7,7.3,7,9v1h1V9c0-1.1,0.9-2,2-2s2,0.9,2,2v2h-2v6h8v-6h-5V9z" />
			<path
				d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z M12,22C6.5,22,2,17.5,2,12
	C2,6.5,6.5,2,12,2s10,4.5,10,10C22,17.5,17.5,22,12,22z"
			/>
		</svg>
	);
};

// 자물쇠 닫힘 아이콘
export const LockIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<path
				d="M16.8,11.4h-1.2V9c0-2-1.6-3.6-3.6-3.6S8.4,7,8.4,9v2.4H7.2v7.2h9.6V11.4z M14.4,11.4H9.6V9
	c0-1.3,1.1-2.4,2.4-2.4s2.4,1.1,2.4,2.4V11.4z"
			/>
			<path
				d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z M12,22C6.5,22,2,17.5,2,12
	C2,6.5,6.5,2,12,2s10,4.5,10,10C22,17.5,17.5,22,12,22z"
			/>
		</svg>
	);
};

// 하트 아이콘
export const LikeIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
			<path d="M11,20c-0.1,0-0.2,0-0.3-0.1L10,19.5C6.5,17.3,0,13.2,0,6.6C0,3,2.8,0,6.3,0C8.1,0,9.8,0.8,11,2.2 C12.2,0.8,13.9,0,15.7,0C19.2,0,22,3,22,6.6c0,6.6-6.5,10.7-10,12.9l-0.7,0.4C11.2,20,11.1,20,11,20z M6.3,1C3.4,1,1,3.5,1,6.6 c0,6.1,6.2,9.9,9.6,12l0.4,0.3l0.4-0.3c3.3-2.1,9.6-5.9,9.6-12C21,3.5,18.6,1,15.7,1c-1.7,0-3.3,0.8-4.3,2.2c-0.2,0.3-0.6,0.3-0.8,0 C9.6,1.8,8,1,6.3,1z"></path>
		</svg>
	);
};

// 그래프 아이콘
export const GraphIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 31.8">
			<g>
				<polygon
					className="st0"
					points="31.8,28.8 3,28.8 3,0 2,0 2,28.8 0,28.8 0,29.8 2,29.8 2,31.8 3,31.8 3,29.8 31.8,29.8"
				></polygon>
				<rect
					x="4.6"
					y="19.9"
					transform="matrix(0.7071 -0.7071 0.7071 0.7071 -12.2395 11.2149)"
					className="st0"
					width="5.6"
					height="1"
				></rect>
				<rect x="4" y="26.8" className="st0" width="1" height="1"></rect>
				<rect x="6" y="26.8" className="st0" width="1" height="1"></rect>
				<rect x="7.9" y="26.8" className="st0" width="1" height="1"></rect>
				<rect x="9.9" y="26.8" className="st0" width="1" height="1"></rect>
				<rect x="11.9" y="26.8" className="st0" width="1" height="1"></rect>
				<rect x="13.9" y="26.8" className="st0" width="1" height="1"></rect>
				<rect x="15.9" y="26.8" className="st0" width="1" height="1"></rect>
				<rect x="17.9" y="26.8" className="st0" width="1" height="1"></rect>
				<rect x="19.9" y="26.8" className="st0" width="1" height="1"></rect>
				<rect x="21.9" y="26.8" className="st0" width="1" height="1"></rect>
				<rect x="23.9" y="26.8" className="st0" width="1" height="1"></rect>
				<rect x="25.8" y="26.8" className="st0" width="1" height="1"></rect>
				<rect x="27.8" y="26.8" className="st0" width="1" height="1"></rect>
				<path
					className="st0"
					d="M20.7,13.4h-6.8c-0.1,0-0.2,0-0.3,0.1l-2,2l0.7,0.7l1.8-1.8h6.8c0.1,0,0.2,0,0.3-0.1l6.6-6.6v1.3h1V6.5 c0-0.3-0.2-0.5-0.5-0.5h-2.5v1h1.3L20.7,13.4z"
				></path>
				<path
					className="st0"
					d="M14.1,9.8c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1L17.6,7h3.1L23,9.3l0.7-0.7l-2.5-2.5C21.1,6,21,6,20.9,6h-3.5 c-0.1,0-0.2,0-0.3,0.1l-2.6,2.6l-2.6-2.6C11.7,6,11.6,6,11.4,6H5v1h6.3L14.1,9.8z"
				></path>
				<path
					className="st0"
					d="M28.8,11.4h-1v1.3l-1.6-1.6l-0.7,0.7l1.6,1.6h-1.3v1h2.5c0.3,0,0.5-0.2,0.5-0.5V11.4z"
				></path>
				<path
					className="st0"
					d="M28.7,20.5l-2-2L26,19.2l1.1,1.1h-5.6L18.2,17c-0.2-0.2-0.5-0.2-0.7,0l-3.1,3.1l-8.6-8.6l-0.7,0.7l8.9,8.9 c0.2,0.2,0.5,0.2,0.7,0l3.1-3.1l3.1,3.1c0.1,0.1,0.2,0.1,0.3,0.1h5.8L26,22.5l0.7,0.7l2-2C28.9,21,28.9,20.7,28.7,20.5z"
				></path>
			</g>
		</svg>
	);
};

// 다트 아이콘
export const DartIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="32" height="31.8" viewBox="0 0 32 31.8">
			<path
				className="st0"
				d="M32,3.7c-0.1-0.2-0.3-0.3-0.5-0.3h-2.1l2.5-2.5l-0.8-0.8l-2.5,2.5V0.5c0-0.2-0.1-0.4-0.3-0.5 C28,0,27.8,0,27.6,0.2l-3.4,3.4C24.1,3.7,24,3.8,24,4v3.2L13.2,17.9l0.8,0.8l1.5-1.5c0.2,0.3,0.3,0.7,0.3,1.1c0,1.2-1,2.2-2.2,2.2 c-1.2,0-2.2-1-2.2-2.2c0-1.1,0.9-2.1,2.1-2.2L13.4,15c-1.7,0.1-3.1,1.5-3.1,3.3c0,1.8,1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3 c0-0.7-0.2-1.3-0.6-1.9l1.7-1.7c0.8,1,1.3,2.2,1.3,3.5c0,3.1-2.5,5.6-5.6,5.6c-3.1,0-5.6-2.5-5.6-5.6s2.5-5.6,5.6-5.6 c0.8,0,1.7,0.2,2.4,0.5l0.5-1c-0.9-0.4-1.9-0.7-2.9-0.7c-3.7,0-6.7,3-6.7,6.7s3,6.7,6.7,6.7c3.7,0,6.7-3,6.7-6.7 c0-1.6-0.6-3.1-1.6-4.3l1.7-1.6c1.5,1.7,2.3,3.7,2.3,6c0,5-4.1,9-9.1,9c-5,0-9.1-4-9.1-9s4.1-9,9.1-9c1.7,0,3.4,0.5,4.9,1.4l0.6-0.9 c-1.6-1-3.5-1.6-5.5-1.6C8,8.2,3.4,12.7,3.4,18.3S8,28.3,13.6,28.3c5.6,0,10.2-4.5,10.2-10.1c0-2.5-0.9-4.9-2.6-6.7l1.6-1.6 c2.1,2.3,3.3,5.2,3.3,8.4c0,6.8-5.6,12.4-12.5,12.4c-6.9,0-12.5-5.6-12.5-12.4c0-6.8,5.6-12.4,12.5-12.4c2.7,0,5.2,0.8,7.3,2.4 l0.6-0.9c-2.3-1.7-5.1-2.6-8-2.6C6.1,4.8,0,10.8,0,18.3s6.1,13.5,13.6,13.5c7.5,0,13.6-6.1,13.6-13.5c0-3.4-1.3-6.6-3.6-9.1l1.2-1.2 H28c0.1,0,0.3-0.1,0.4-0.2l3.4-3.4C32,4.2,32,3.9,32,3.7z M25.1,4.2l2.3-2.3v1.9L25.1,6V4.2z M27.8,6.8h-1.9l2.3-2.3h1.9L27.8,6.8z"
			></path>
		</svg>
	);
};

// 연필 아이콘
export const PencilIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="11px" height="11px">
			<path d="M10.266,3.790 L9.914,4.142 C9.570,4.486 9.13,4.486 8.670,4.142 L7.344,2.815 C7.0,2.472 7.0,1.915 7.344,1.571 L7.696,1.219 C8.39,0.875 8.597,0.875 8.940,1.219 L10.266,2.546 C10.610,2.889 10.610,3.446 10.266,3.790 ZM8.558,5.498 L3.853,10.203 C3.835,10.221 3.811,10.235 3.785,10.240 L0.776,10.873 C0.679,10.893 0.593,10.807 0.613,10.710 L1.245,7.701 C1.251,7.675 1.264,7.651 1.283,7.632 L5.987,2.927 C6.41,2.874 6.128,2.874 6.181,2.927 L8.558,5.304 C8.611,5.358 8.611,5.445 8.558,5.498 Z" />
		</svg>
	);
};

// 벨 알람 아이콘
export const BellIcon = () => {
	return (
		<svg width="14" height="18" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.9654 11.4641C12.0666 10.0068 12.109 8.99109 12.1599 7.71043C12.1683 7.4013 12.1853 7.08334 12.1853 6.75655C12.1853 5.01661 11.1084 2.59659 8.82733 1.73104C8.6747 0.821321 7.91152 0.114746 6.99572 0.114746C6.07991 0.114746 5.31673 0.812489 5.1641 1.73104C2.88306 2.59659 1.80613 5.00778 1.80613 6.75655C1.80613 7.08334 1.82309 7.4013 1.83157 7.71043C1.88245 8.99993 1.92485 10.0156 1.026 11.4641C-0.237477 13.522 -0.0678828 14.4141 0.15259 14.7673C0.271306 14.9528 0.474819 15.0676 0.686811 15.0676H13.3131C13.5251 15.0676 13.7286 14.9528 13.8473 14.7673C14.0593 14.4141 14.2374 13.522 12.9654 11.4641ZM6.99572 0.997965C7.34338 0.997965 7.63169 1.19227 7.81825 1.46607C7.55538 1.43074 7.28403 1.40425 6.99572 1.40425C6.70741 1.40425 6.43605 1.43074 6.17318 1.46607C6.35974 1.19227 6.64805 0.997965 6.99572 0.997965ZM1.33975 13.7428C1.43303 13.4337 1.63654 12.9303 2.09444 12.1795C3.20529 10.3689 3.15441 8.99109 3.09505 7.6486C3.08657 7.35714 3.07809 7.06568 3.07809 6.75655C3.07809 5.49355 4.06174 2.72907 6.99572 2.72907C9.9297 2.72907 10.9133 5.49355 10.9133 6.75655C10.9133 7.06568 10.9049 7.35714 10.8879 7.6486C10.837 8.98226 10.7777 10.3689 11.8885 12.1795C12.3464 12.9303 12.5499 13.4337 12.6432 13.7428H1.33975Z" />
			<path d="M8.98849 15.5975C8.67474 15.4385 8.29315 15.5798 8.14052 15.9154C7.92852 16.3747 7.4791 16.675 6.99576 16.675C6.51241 16.675 6.05451 16.3747 5.851 15.9154C5.69836 15.5886 5.31677 15.4385 5.00302 15.5975C4.6808 15.7564 4.54512 16.1451 4.69776 16.4807C5.11326 17.4081 6.02059 17.9998 6.99576 17.9998C7.97092 17.9998 8.87825 17.3992 9.29376 16.4807C9.44639 16.1451 9.31072 15.7564 8.98849 15.5975Z" />
		</svg>
	);
};

//필터 아이콘
export const FilterIcon = () => {
	return (
		<svg width="19" height="15" viewBox="0 0 19 15" xmlns="http://www.w3.org/2000/svg">
			<g>
				<path
					d="M18.7402 9.11002L16.6602 7.03003C17.0179 6.43522 17.2112 5.7561 17.2204 5.0621C17.2296 4.36811 17.0544 3.68412 16.7127 3.08002C16.371 2.47591 15.875 1.97335 15.2755 1.62372C14.6759 1.27409 13.9942 1.08993 13.3002 1.09003C12.2961 1.14079 11.3499 1.57541 10.6572 2.30405C9.96443 3.03269 9.57812 3.99962 9.57812 5.005C9.57812 6.01039 9.96443 6.97735 10.6572 7.70599C11.3499 8.43463 12.2961 8.86925 13.3002 8.92001C14.0054 8.91907 14.697 8.72543 15.3002 8.36002L17.3902 10.44C17.4732 10.5259 17.5726 10.5942 17.6826 10.6409C17.7925 10.6875 17.9108 10.7115 18.0302 10.7115C18.1497 10.7115 18.2679 10.6875 18.3778 10.6409C18.4878 10.5942 18.5872 10.5259 18.6702 10.44H18.7202C18.8126 10.3562 18.8869 10.2544 18.9383 10.1407C18.9897 10.0271 19.0172 9.90408 19.019 9.77936C19.0209 9.65463 18.9971 9.53086 18.9492 9.41571C18.9012 9.30057 18.83 9.19652 18.7402 9.11002ZM12.5902 6.99002C12.2996 6.88146 12.0341 6.71473 11.8102 6.50003C11.613 6.30314 11.4567 6.0693 11.35 5.81192C11.2433 5.55454 11.1884 5.27866 11.1884 5.00003C11.1884 4.72141 11.2433 4.44549 11.35 4.18811C11.4567 3.93073 11.613 3.69693 11.8102 3.50003C12.1762 3.11292 12.6756 2.87939 13.2074 2.84674C13.7391 2.81409 14.2635 2.98476 14.6741 3.32419C15.0847 3.66362 15.351 4.14644 15.4189 4.67484C15.4869 5.20323 15.3516 5.7377 15.0402 6.17001V6.25003L14.8702 6.43002C14.5809 6.72788 14.2142 6.93903 13.8114 7.03973C13.4086 7.14044 12.9856 7.12671 12.5902 7.00003V6.99002Z"
					fill="white"
				/>
				<path
					d="M8.09977 0H0.919766C0.417187 0 0.00976562 0.407421 0.00976562 0.91V0.92C0.00976562 1.42258 0.417187 1.83 0.919766 1.83H8.09977C8.60234 1.83 9.00977 1.42258 9.00977 0.92V0.91C9.00977 0.407421 8.60234 0 8.09977 0Z"
					fill="white"
				/>
				<path
					d="M7.09977 6.59003H0.919766C0.417187 6.59003 0.00976562 6.99745 0.00976562 7.50003V7.51003C0.00976562 8.01261 0.417187 8.42003 0.919766 8.42003H7.09977C7.60234 8.42003 8.00977 8.01261 8.00977 7.51003V7.50003C8.00977 6.99745 7.60234 6.59003 7.09977 6.59003Z"
					fill="white"
				/>
				<path
					d="M18.09 13.17H0.91C0.407421 13.17 0 13.5774 0 14.08V14.09C0 14.5926 0.407421 15 0.91 15H18.09C18.5926 15 19 14.5926 19 14.09V14.08C19 13.5774 18.5926 13.17 18.09 13.17Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_467_778">
					<rect width="19" height="15" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

// 디테일 검색 아이콘
export const DetailSearchIcon = () => {
	return (
		<svg width="21" height="19" viewBox="0 0 21 19" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.875 3.46253H2.45219C2.62808 3.91512 2.93568 4.30396 3.33487 4.57833C3.73406 4.8527 4.2063 4.99986 4.69 5.00061C5.17407 5.00031 5.64679 4.85336 6.04641 4.57896C6.44604 4.30456 6.75397 3.91547 6.93 3.46253H20.125C20.3571 3.46253 20.5796 3.36993 20.7437 3.2051C20.9078 3.04028 21 2.81672 21 2.58362C21 2.35052 20.9078 2.12697 20.7437 1.96214C20.5796 1.79731 20.3571 1.70472 20.125 1.70472H6.93C6.75397 1.25177 6.44604 0.862681 6.04641 0.588283C5.64679 0.313884 5.17407 0.166933 4.69 0.166626C4.2063 0.167375 3.73406 0.314533 3.33487 0.588903C2.93568 0.863273 2.62808 1.25212 2.45219 1.70472H0.875C0.642936 1.70472 0.420375 1.79731 0.256281 1.96214C0.0921866 2.12697 0 2.35052 0 2.58362C0 2.81672 0.0921866 3.04028 0.256281 3.2051C0.420375 3.36993 0.642936 3.46253 0.875 3.46253ZM4.03375 2.58362C4.03375 2.4088 4.10289 2.24113 4.22596 2.11751C4.34903 1.99389 4.51595 1.92444 4.69 1.92444C4.86405 1.92444 5.03097 1.99389 5.15404 2.11751C5.27711 2.24113 5.34625 2.4088 5.34625 2.58362C5.34625 2.75845 5.27711 2.92611 5.15404 3.04973C5.03097 3.17335 4.86405 3.2428 4.69 3.2428C4.60382 3.2428 4.51848 3.22575 4.43886 3.19262C4.35924 3.1595 4.2869 3.11094 4.22596 3.04973C4.16502 2.98852 4.11669 2.91585 4.08371 2.83588C4.05073 2.7559 4.03375 2.67019 4.03375 2.58362Z" />
			<path d="M20.125 8.28552H18.4013C18.2252 7.83258 17.9173 7.44349 17.5177 7.16909C17.118 6.89469 16.6453 6.74774 16.1613 6.74744C15.6772 6.74774 15.2045 6.89469 14.8048 7.16909C14.4052 7.44349 14.0973 7.83258 13.9213 8.28552H0.875C0.642936 8.28552 0.420375 8.37812 0.256281 8.54295C0.0921866 8.70777 0 8.93133 0 9.16443C0 9.39753 0.0921866 9.62108 0.256281 9.78591C0.420375 9.95074 0.642936 10.0433 0.875 10.0433H13.9213C14.0973 10.4963 14.4052 10.8854 14.8048 11.1598C15.2045 11.4342 15.6772 11.5811 16.1613 11.5814C16.6453 11.5811 17.118 11.4342 17.5177 11.1598C17.9173 10.8854 18.2252 10.4963 18.4013 10.0433H20.125C20.3571 10.0433 20.5796 9.95074 20.7437 9.78591C20.9078 9.62108 21 9.39753 21 9.16443C21 8.93133 20.9078 8.70777 20.7437 8.54295C20.5796 8.37812 20.3571 8.28552 20.125 8.28552ZM16.8175 9.16443C16.8175 9.33925 16.7484 9.50692 16.6253 9.63054C16.5022 9.75416 16.3353 9.82361 16.1613 9.82361C15.9872 9.82361 15.8203 9.75416 15.6972 9.63054C15.5741 9.50692 15.505 9.33925 15.505 9.16443C15.505 8.9896 15.5741 8.82194 15.6972 8.69832C15.8203 8.5747 15.9872 8.50525 16.1613 8.50525C16.3353 8.50525 16.5022 8.5747 16.6253 8.69832C16.7484 8.82194 16.8175 8.9896 16.8175 9.16443Z" />
			<path d="M20.125 14.8685H10.2594C10.0834 14.4156 9.77542 14.0265 9.3758 13.7521C8.97618 13.4777 8.50345 13.3308 8.01938 13.3304C7.53531 13.3308 7.06258 13.4777 6.66296 13.7521C6.26334 14.0265 5.9554 14.4156 5.77937 14.8685H0.875C0.642936 14.8685 0.420375 14.9611 0.256281 15.126C0.0921866 15.2908 0 15.5143 0 15.7474C0 15.9805 0.0921866 16.2041 0.256281 16.3689C0.420375 16.5337 0.642936 16.6263 0.875 16.6263H5.77937C5.9554 17.0793 6.26334 17.4684 6.66296 17.7428C7.06258 18.0172 7.53531 18.1641 8.01938 18.1644C8.50345 18.1641 8.97618 18.0172 9.3758 17.7428C9.77542 17.4684 10.0834 17.0793 10.2594 16.6263H20.125C20.3571 16.6263 20.5796 16.5337 20.7437 16.3689C20.9078 16.2041 21 15.9805 21 15.7474C21 15.5143 20.9078 15.2908 20.7437 15.126C20.5796 14.9611 20.3571 14.8685 20.125 14.8685ZM8.67563 15.7474C8.67563 15.9223 8.60648 16.0899 8.48341 16.2135C8.36034 16.3372 8.19343 16.4066 8.01938 16.4066C7.84534 16.4066 7.67841 16.3372 7.55534 16.2135C7.43227 16.0899 7.36313 15.9223 7.36313 15.7474C7.36313 15.5726 7.43227 15.4049 7.55534 15.2813C7.67841 15.1577 7.84534 15.0883 8.01938 15.0883C8.19343 15.0883 8.36034 15.1577 8.48341 15.2813C8.60648 15.4049 8.67563 15.5726 8.67563 15.7474Z" />
		</svg>
	);
};

// 세일 말풍선 아이콘
export const BalloonSaleIcon = () => {
	return (
		<svg
			width="51"
			height="56"
			viewBox="0 0 51 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="balloon-sale"
		>
			<path d="M44.8814 42.0721C48.696 37.6151 51 31.8266 51 25.5C51 11.4167 39.5833 0 25.5 0C11.4167 0 0 11.4167 0 25.5C0 36.6029 7.09586 46.0484 17 49.549V54.9737C17 55.5361 17.4682 55.989 18.0299 55.9605C29.8304 55.3605 39.1769 49.7943 44.8814 42.0721Z" />
		</svg>
	);
};

// 디테일 검색 아이콘
export const ArrowSaleIcon = () => {
	return (
		<svg
			viewBox="0 0 225 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="arrow-sale"
		>
			<path
				d="M224 1.5L224.685 2.22898C224.984 1.94819 225.081 1.51327 224.93 1.13188C224.779 0.750498 224.41 0.5 224 0.5V1.5ZM182 39.9686C181.983 40.5206 182.417 40.9821 182.969 40.9995L191.964 41.2824C192.516 41.2997 192.978 40.8663 192.995 40.3143C193.012 39.7623 192.579 39.3007 192.027 39.2834L184.031 39.0319L184.282 31.0359C184.3 30.4839 183.866 30.0223 183.314 30.0049C182.762 29.9876 182.301 30.421 182.283 30.973L182 39.9686ZM223.315 0.771018L182.315 39.271L183.685 40.729L224.685 2.22898L223.315 0.771018ZM0 2.5H122.022V0.5H0V2.5ZM122.022 2.5H224V0.5H122.022V2.5Z"
				fill="#B2B2B2"
			/>
		</svg>
	);
};

// 느낌표 툴팁 아이콘
export const CautionTooltipIcon = () => {
	return (
		<svg
			width="15"
			height="15"
			viewBox="0 0 15 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5Z"
				fill="#FF4444"
			/>
			<rect x="7" y="11" width="1" height="1" fill="white" />
			<rect x="7" y="3" width="1" height="7" fill="white" />
		</svg>
	);
};
