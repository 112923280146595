import React, { useState } from "react";
import { useForm } from "react-hook-form";

import DropdownTemplate from "~/common/components/DropdownPortal/DropdownTemplate";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";

import "~/assets/sass/pages/shareholder/list.scss";
import { UnLockIcon, LockIcon } from "~/assets/svg/TeamInfoIcon";
import { dateFormatter } from "~/utils/formatter";
import useRequest from "~/common/hooks/useRequest";
import useAlert from "~/common/hooks/useAlert";
import { useNavigate } from "react-router";
import { useGlobalStore } from "~/store";
import { selectShareholder } from "~/store/shareholder/actions";
import { CautionIcon } from "~/assets/svg/InterfacesIcon";
import { Trans, useTranslation } from "react-i18next";

const ShareholderCard = ({
	idx,
	title,
	date,
	lock,
	onDuplicate,
	onDelete,
	onPwSetup,
	onPwChange,
	onPwReset,
	onClick,
	onSelect,
	currentPath,
}) => {
	const { t } = useTranslation();
	return (
		<div className="c__card shareholder-list-card" onClick={onSelect}>
			{currentPath.auth !== "view" && (
				<DropdownTemplate target={`dropdown_${idx}`} vertical>
					{lock ? (
						<>
							<li className="c__dropdownItem" onClick={onPwChange}>
								{t("Shareholder.ShareholderList.View.Index.pwChange")}
							</li>
							<li className="c__dropdownItem" onClick={onPwReset}>
								{t("Shareholder.ShareholderList.View.Index.pwClear")}
							</li>
						</>
					) : (
						<li className="c__dropdownItem" onClick={onPwSetup}>
							{t("Shareholder.ShareholderList.View.Index.pwSet")}
						</li>
					)}
					<li className="c__dropdownItem" onClick={onDelete}>
						{t("Shareholder.ShareholderList.View.Index.holderDelete")}
					</li>
					<li className="c__dropdownItem" onClick={onDuplicate}>
						{t("Shareholder.ShareholderList.View.Index.holderCopy")}
					</li>
				</DropdownTemplate>
			)}
			<div className="shareholder-card-wrap" onClick={onClick}>
				<h3 className="card-title">{title}</h3>
				<div className="card-status">
					<span className="date">{dateFormatter(date, "-")}</span>
					{lock ? (
						<span className="lock-status locked">
							<LockIcon />
						</span>
					) : (
						<span className="lock-status unlocked">
							<UnLockIcon />
						</span>
					)}
				</div>
			</div>
		</div>
	);
};

const View = ({ shareholderRequest, shareholderList }) => {
	const { t } = useTranslation();
	const [
		{
			sidebar: { currentPath },
		},
	] = useGlobalStore();
	const navigate = useNavigate();

	const [, dispatch] = useGlobalStore();

	const [pwModal, setPwModal] = useState(false);
	const [pwSetupModal, setPwSetupModal] = useState(false);
	const [pwChangeModal, setPwChangeModal] = useState(false);
	const [pwResetModal, setPwResetModal] = useState(false);

	const pwRequest = useRequest("post", "shareholder/pw/check");

	const pwSetRequest = useRequest("post", "shareholder/pw/set");

	const [showWrongAlert, WrongAlert] = useAlert();
	const pwChangeRequest = useRequest("post", "shareholder/pw/edit");

	const pwResetRequest = useRequest("post", "shareholder/pw/reset");

	const [showDuplicateAlert, DuplicateAlert] = useAlert();
	const duplicateRequest = useRequest("post", "shareholder/copy");

	const [showDeleteAlert, DeleteAlert] = useAlert();
	const deleteRequest = useRequest("delete", "shareholder/delete");

	const passwordForm = useForm();

	const shareholderCardList = () => {
		return shareholderList.map(({ idx, title, date, lock }) => {
			return (
				<ShareholderCard
					key={idx}
					idx={idx}
					title={title}
					date={date}
					lock={lock}
					onClick={() => {
						dispatch(selectShareholder(idx));
						if (lock === false) {
							navigate(`/shareholder/detail/${idx}`);
						} else {
							setPwModal(true);
						}
					}}
					onSelect={() => {
						passwordForm.reset();
						passwordForm.setValue("shareholderIdx", idx);
					}}
					onPwSetup={() => setPwSetupModal(true)}
					onPwChange={() => setPwChangeModal(true)}
					onPwReset={() => setPwResetModal(true)}
					onDuplicate={() => {
						showDuplicateAlert()
							.then(async () => {
								await duplicateRequest.asyncCall(idx, "path");
								await shareholderRequest.asyncCall();
							})
							.catch(() => {});
					}}
					onDelete={() => {
						showDeleteAlert()
							.then(async () => {
								await deleteRequest.asyncCall(idx, "path");
								await shareholderRequest.asyncCall();
							})
							.catch(() => {});
					}}
					currentPath={currentPath}
				/>
			);
		});
	};

	const submitPassword = () => {
		passwordForm.handleSubmit((data) => {
			pwRequest
				.asyncCall(data)
				.then(() => {
					navigate(`/shareholder/detail/${data.shareholderIdx}`);
				})
				.catch(() => {
					showWrongAlert();
				});
		})();
	};

	return (
		<>
			<div className="pageIntro">
				<CautionIcon />
				<div className="content">
					<h3>
						<Trans
							i18nKey="Shareholder.ShareholderList.View.Index.content.title"
							components={[<b />]}
						/>
					</h3>
					<p>{t("Shareholder.ShareholderList.View.Index.content.text")}</p>
				</div>
			</div>
			<div className="c__cardRow">{shareholderCardList()}</div>

			{/*드롭다운 관리자메뉴 모달*/}
			{/*----- 암호입력*/}
			<ModalTemplate
				modalState={pwModal}
				setModalToggle={setPwModal}
				title={t("Shareholder.ShareholderList.View.Index.pwInsert")}
				width={500}
				className="password-form"
			>
				<div className="modal-content">
					<Form useForm={passwordForm}>
						<Input
							name={"pw"}
							label={"password"}
							type={"password"}
							validation={{
								required: t("Shareholder.ShareholderList.View.Index.pwInsertMsg"),
							}}
							onEnter={submitPassword}
						/>
					</Form>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => setPwModal(false)}
					>
						{t("button.cancel")}
					</button>
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={submitPassword}
					>
						{t("button.check")}
					</button>
				</div>
			</ModalTemplate>
			{/*----- 암호설정*/}
			<ModalTemplate
				modalState={pwSetupModal}
				setModalToggle={setPwSetupModal}
				title={t("Shareholder.ShareholderList.View.Index.pwSet")}
				width={500}
				className="password-form"
			>
				<div className="modal-content">
					<Form useForm={passwordForm}>
						<Input
							name={"pw"}
							label={"password"}
							type={"password"}
							validation={{
								required: t("Shareholder.ShareholderList.View.Index.pwInsertMsg"),
							}}
						/>
					</Form>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => setPwSetupModal(false)}
					>
						{t("button.cancel")}
					</button>
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={passwordForm.handleSubmit(async (data) => {
							await pwSetRequest.asyncCall(data);
							await shareholderRequest.asyncCall();
							setPwSetupModal(false);
						})}
					>
						{t("button.check")}
					</button>
				</div>
			</ModalTemplate>

			{/*----- 암호변경*/}
			<ModalTemplate
				modalState={pwChangeModal}
				setModalToggle={setPwChangeModal}
				title={t("Shareholder.ShareholderList.View.Index.pwChange")}
				width={500}
				className="password-form"
			>
				<div className="modal-content">
					<Form useForm={passwordForm}>
						<Input
							name={"pw"}
							label={"password"}
							type={"password"}
							validation={{
								required: t("Shareholder.ShareholderList.View.Index.pwInsertMsg"),
							}}
						/>
						<Input
							name={"newPw"}
							label={"new password"}
							type={"password"}
							validation={{
								required: t(
									"Shareholder.ShareholderList.View.Index.newPwInsertMsg"
								),
							}}
						/>
					</Form>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => setPwChangeModal(false)}
					>
						{t("button.cancel")}
					</button>
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={passwordForm.handleSubmit((data) => {
							pwChangeRequest
								.asyncCall(data)
								.then(async () => {
									await shareholderRequest.asyncCall();
									setPwChangeModal(false);
								})
								.catch(() => {
									showWrongAlert();
								});
						})}
					>
						{t("button.check")}
					</button>
				</div>
			</ModalTemplate>
			{/*----- 암호 초기화*/}
			<ModalTemplate
				modalState={pwResetModal}
				setModalToggle={setPwResetModal}
				title={t("Shareholder.ShareholderList.View.Index.pwClear")}
				width={500}
				className="password-form"
			>
				<div className="modal-content">
					<p className="modal-content-text">
						{t("Shareholder.ShareholderList.View.Index.pwClearMsg")}
					</p>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={passwordForm.handleSubmit(async (data) => {
							await pwResetRequest.asyncCall(data.shareholderIdx, "path");
							await shareholderRequest.asyncCall();
							setPwResetModal(false);
						})}
					>
						{t("button.check")}
					</button>
				</div>
			</ModalTemplate>

			{/* TODO: merge alerts (get message params on 'show' function) */}
			<WrongAlert
				type="warning"
				message={t("Shareholder.ShareholderList.View.Index.wrongPwMsg")}
				confirm={t("button.close")}
			/>
			<DeleteAlert
				message={t("Shareholder.ShareholderList.View.Index.holderDeleteMsg")}
				confirm={t("button.check")}
				reject={t("button.cancel")}
			/>
			<DuplicateAlert
				message={t("Shareholder.ShareholderList.View.Index.holderCopyMsg")}
				confirm={t("button.check")}
				reject={t("button.cancel")}
			/>
		</>
	);
};
export default View;
