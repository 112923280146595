import React from "react";
import { MyPostType } from "~/common/constants/type";
import { dateToString } from "~/utils/formatter";
import { useNavigate } from "react-router";
import NoData from "~/common/components/NoData";
import CommentRow from "./CommentRow";
import { useTranslation } from "react-i18next";

const MyComment = ({ myPostList: myCommentList, myCommunityList, showInfoAlert }) => {
	const { t } = useTranslation();
	return myCommentList.length !== 0 ? (
		<div className="c__table">
			<div className="thead">
				<div className="tr">
					<div className="th content">{t("Community.MyPostPage.table.content")}</div>
					<div className="th name">{t("Community.MyPostPage.table.name")}</div>
					<div className="th write">{t("Community.MyPostPage.table.write")}</div>
					<div className="th date">{t("Community.MyPostPage.table.date2")}</div>
				</div>
			</div>
			<div className="tbody">
				{myCommentList.map((myComment, index) => (
					<CommentRow
						key={index}
						{...{ myComment, myCommunityList, showInfoAlert, index }}
					/>
				))}
			</div>
		</div>
	) : (
		<NoData main={t("Community.MyPostPage.commentNoData")} />
	);
};
export default MyComment;
