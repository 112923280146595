import React, { useEffect, useState } from "react";
import noImg from "~/assets/img/defaultImg/profile-large.png";
import { dateToString } from "~/utils/formatter";
import DropdownTemplate, {
	DropDownItem,
} from "~/common/components/DropdownPortal/DropdownTemplate";
import Form, { Group } from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import CommentUpload from "~/common/components/FormImageUploader/CommentUpload";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import useRequest from "~/common/hooks/useRequest";
import { useGlobalStore } from "~/store";
import { API_STATE } from "~/common/constants/state";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Comment = ({ comment, commentRequest }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { idx } = useParams();
	const replyForm = useForm();
	const replyEditForm = useForm({
		defaultValues: {
			comment: comment.comment,
			ImageList: comment.files.map((file) => {
				return { name: file.name, path: file.path, size: Number(file.size) };
			}),
		},
	});
	const [isReplyOn, setIsReplyOn] = useState(false);
	const addCommentRequest = useRequest("post", `community/notice/comment/${idx}`);
	const deleteCommentRequest = useRequest("delete", `community/notice/comment`);
	const patchCommentRequest = useRequest("patch", `community/notice/comment/${comment.idx}`);
	const [
		{
			sidebar: { currentPath },
			user: { info },
		},
	] = useGlobalStore();

	const [isEdit, setIsEdit] = useState(false);

	const closeReply = () => {
		replyForm.reset();
		setIsReplyOn(false);
	};

	const submit = (data) => {
		if (isEdit) {
			if (!data.comment && data.ImageList.length === 0) {
				replyEditForm.setError("comment", {
					type: "custom",
					message: t("Validate.require"),
				});
				return;
			}
			patchCommentRequest.call(
				{
					comment: data.comment,
					files: data.ImageList,
				},
				"body"
			);
		} else {
			if (!data.comment && data.ImageList.length === 0) {
				replyForm.setError("comment", {
					type: "custom",
					message: t("Validate.require"),
				});
				return;
			}
			addCommentRequest.call(
				{
					comment: data.comment,
					parentIdx: Number(comment.idx),
					files: data.ImageList,
				},
				"body"
			);
		}
	};

	const deleteComment = async () => {
		closeReply();
		await deleteCommentRequest.call(comment.idx, "path");
	};

	useEffect(() => {
		replyEditForm.setValue("comment", comment.comment);
		replyEditForm.setValue(
			"ImageList",
			comment.files.map((file) => {
				return { name: file.name, path: file.path, size: Number(file.size) };
			})
		);
		replyEditForm.trigger();
	}, [comment, replyEditForm]);

	useEffect(() => {
		switch (addCommentRequest.state) {
			case API_STATE.done:
				if (addCommentRequest.response.statusCode === 500) {
					console.log("error");
					navigate("/404");
				}

				commentRequest.call(idx, "path");
				closeReply();
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
		}
	}, [addCommentRequest.state]);

	useEffect(() => {
		switch (deleteCommentRequest.state) {
			case API_STATE.done:
				commentRequest.call(idx, "path");
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
		}
	}, [deleteCommentRequest.state]);

	useEffect(() => {
		switch (patchCommentRequest.state) {
			case API_STATE.done:
				if (patchCommentRequest.response.statusCode === 500) {
					console.log("error");
					navigate("/404");
				}

				commentRequest.call(idx, "path");
				setIsEdit(false);
				break;
			case API_STATE.error:
				console.log(patchCommentRequest.error);
				navigate("/404");
		}
	}, [patchCommentRequest.state]);

	return (
		Object.keys(comment).length > 0 && (
			<li
				className={`${comment?.parent ? "reply-item" : "comment-item"} ${
					comment.user.idx === info.idx && !comment.isBlinded && !isEdit ? "active" : ""
				}`}
			>
				{!isEdit ? (
					<div className="comment-view">
						{comment.isBlinded ? (
							<div className="comment-info">
								<div className="comment-text">
									{t(
										"Community.BoardPage.BoardPost.Detail.component.comment-text"
									)}
								</div>
							</div>
						) : (
							<>
								<div className="profile-img">
									<img src={comment?.user?.img ?? noImg} alt={t("Img.profile")} />
								</div>

								<div className="comment-info">
									<div className="comment-name">{comment?.user?.name}</div>
									<div className="comment-text">
										{comment.parent && (
											<span className="comment-tag">
												@{comment.parent.user.name}
											</span>
										)}
										{comment?.comment}
									</div>
									<ul className="comment-img">
										{comment.files.map((image, index) => (
											<li className="img-item" key={index}>
												<img
													src={image.path}
													alt={t("Community.MyPostPage.notice")}
												/>
											</li>
										))}
									</ul>

									<div className="comment-date">
										<span className="date">
											{dateToString(comment?.createdAt, "yyyy. MM. dd HH:mm")}
										</span>
										<button className="reply-button">
											{isReplyOn ? (
												<span
													className="reply-cancel"
													onClick={() => {
														closeReply();
													}}
												>
													{t(
														"Community.BoardPage.BoardPost.Detail.component.reply-cancel"
													)}
												</span>
											) : (
												<span
													className="reply-write"
													onClick={() => {
														setIsReplyOn(true);
													}}
												>
													{t(
														"Community.BoardPage.BoardPost.Detail.component.reply-write"
													)}
												</span>
											)}
										</button>
									</div>
								</div>
								{(currentPath.auth === "edit" || comment.user.idx === info.idx) && (
									<DropdownTemplate target={comment.idx} vertical>
										<DropDownItem
											onClick={() => {
												closeReply();
												setIsEdit(true);
											}}
										>
											{t("button.modify")}
										</DropDownItem>
										<DropDownItem onClick={deleteComment}>
											{t("button.delete")}
										</DropDownItem>
									</DropdownTemplate>
								)}
							</>
						)}
					</div>
				) : (
					<div className="comment-view">
						<div className="profile-img">
							<img src={comment?.user?.img ?? noImg} alt={t("Img.profile")} />
						</div>

						<div className="comment-info">
							<div className="comment-name">
								<span>{comment?.user?.name}</span>
							</div>
							<button
								className="edit-button"
								onClick={() => {
									replyEditForm.reset();
									setIsEdit(false);
								}}
							>
								{t("button.cancel")}
							</button>

							<div className="comment-edit">
								<div className="comment-box">
									<Form useForm={replyEditForm} className={"Community-ListForm"}>
										<Group>
											<span className="comment-tag">
												@{comment.user.name}
											</span>
											<Textarea
												name="comment"
												placeholder={t("Community.textarea-placeholder")}
												required
											/>
										</Group>
										<Group>
											<CommentUpload
												name="ImageList"
												type={UPLOAD_TYPE.NOTICE_COMMENT_IMAGE}
												files={comment.files}
												height="60px"
												width="60px"
												className={"community-img"}
												maxLength={5}
											/>
											<button
												className="btn__solid"
												color="primary"
												onClick={replyEditForm.handleSubmit(submit)}
											>
												{t("button.register")}
											</button>
										</Group>
									</Form>
								</div>
							</div>
						</div>
					</div>
				)}

				{isReplyOn && (
					<div className="comment-edit">
						<div className="comment-box">
							<Form useForm={replyForm} className={"Community-ListForm"}>
								<Group>
									<span className="comment-tag">@{comment.user.name}</span>
									<Textarea
										name="comment"
										placeholder={t("Community.textarea-placeholder")}
									/>
								</Group>
								<Group>
									<CommentUpload
										name="ImageList"
										type={UPLOAD_TYPE.COMPANY_PRODUCT}
										height="60px"
										width="60px"
										className={"community-img"}
										maxLength={5}
									/>
									<button
										className="btn__solid"
										color="primary"
										onClick={replyForm.handleSubmit(submit)}
									>
										{t("button.register")}
									</button>
								</Group>
							</Form>
						</div>
					</div>
				)}

				{comment.children?.length > 0 && (
					<ul className="reply-list">
						{comment.children?.map((child, index) => (
							<Comment comment={child} key={index} commentRequest={commentRequest} />
						))}
					</ul>
				)}
			</li>
		)
	);
};
export default Comment;
