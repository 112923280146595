import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

import "~/assets/sass/pages/ir/history.scss";
import { Case, If } from "~/common/components/If";
import PageHeader from "~/common/components/PageHeader";
import TabNav from "~/common/components/TabNav";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import { useGlobalStore } from "~/store";
import { setHistory } from "~/store/ir/actions";
import Edit from "./Edit";
import View from "./View";
import Empty from "~/pages/ir/IrDetail/History/Empty";
import Loading from "~/common/components/Loading";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const History = ({}) => {
	const { t } = useTranslation();
	const { tabList, irIdx } = useOutletContext();

	const historyRequest = useRequest("get", `ir/history/detail`);

	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	const [, dispatch] = useGlobalStore();

	useEffect(() => {
		historyRequest.singleCall(irIdx, "path");
	}, []);

	useEffect(() => {
		switch (historyRequest.state) {
			case API_STATE.done:
				const response = historyRequest.response.data;
				dispatch(setHistory(response));
				if (response.length === 0) {
					setPageState(PAGE_STATE.EMPTY);
				} else {
					setPageState(PAGE_STATE.VIEW);
				}
				break;
			case API_STATE.error:
				if (historyRequest.error.statusCode === 404) {
					setPageState(PAGE_STATE.EMPTY);
				}
			default:
				break;
		}
	}, [historyRequest.state]);

	const showViewPage = () => historyRequest.asyncCall(irIdx, "path");

	const showEditPage = () => {
		setPageState(PAGE_STATE.EDIT);
	};

	const Header = ({ buttonList = [] }) => {
		return (
			<>
				<PageHeader
					title={t("IR.IrDetail.Index.IRDetail")}
					mainTitle="IR"
					subTitle={t("IR.IrDetail.Index.history")}
					buttonList={buttonList}
				/>
				<TabNav tabList={tabList} currentTab={"history"} />
			</>
		);
	};

	const buttonList = [
		<AuthButton type="button" className="btn__solid" color="primary">
			{t("button.Edit")}
		</AuthButton>,
	];

	return (
		<If condition={pageState}>
			<Case condition={PAGE_STATE.VIEW}>
				<View {...{ Header, showEditPage }} />
			</Case>
			<Case condition={PAGE_STATE.EDIT}>
				<Edit {...{ Header, showViewPage }} />
			</Case>
			<Case condition={PAGE_STATE.EMPTY}>
				<Empty {...{ Header, showEditPage }} />
			</Case>
			<Case condition={PAGE_STATE.LOADING}>
				<Header buttonList={buttonList} />
				<Loading />
			</Case>
		</If>
	);
};

export default History;
