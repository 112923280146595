import React from "react";
import { AuthEmailIcon } from "~/assets/svg/InterfacesIcon";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MailSend = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<>
			<div className="phrase-wrap">
				<AuthEmailIcon />
				<p>
					{t("Auth.FindPw.Find.MailSend.phrase-wrap")}
				</p>
			</div>
			<div className="btn-wrap">
				<button className="btn__solid" color="primary" onClick={() => navigate("/login")}>
					{t("button.check")}
				</button>
			</div>
			{/*<div className="card-bottom-alarm">*/}
			{/*    이메일을 찾을 수 없으신가요?*/}
			{/*    <a href="/"> 인증메일 재전송</a>*/}
			{/*</div>*/}
		</>
	);
};
export default MailSend;
