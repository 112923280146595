import React, { useEffect, useState } from "react";
import PageHeader from "~/common/components/PageHeader";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import { Outlet, useNavigate } from "react-router";
import ApplyVacationModal from "~/pages/vacation/VacationListPage/ApplyVacationModal";
// import ApplyVacationModal from "~/pages/vacation/";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import { useGlobalStore } from "~/store";
import { Case, If, MultiCases } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import { hourAndMinuteFormatter } from "~/utils/formatter";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const VacationListPage = () => {
    const { t } = useTranslation();
    const MENU = ["vacation", "request"];
    const TYPE = "main";
    const TAB_LIST = [
        {
            id: "used",
            title: translation.t("HR.Vacation.VacationPage.TAB_LIST.used"),
            path: "../used",
        },
        {
            id: "expected",
            title: translation.t("HR.Vacation.VacationPage.TAB_LIST.expected"),
            path: "../expected",
        },
        {
            id: "all",
            title: translation.t("HR.Vacation.VacationPage.TAB_LIST.all"),
            path: "../all",
        },
    ];
    const navigate = useNavigate();
    const [
        {
            user: { info },
        },
    ] = useGlobalStore();

    const [vacationList, setVacationList] = useState({});
    const [pagingObject, setPagingObject] = useState({});
    const [pageState, setPageState] = useState({
        histories: PAGE_STATE.LOADING,
        upcomingVacation: PAGE_STATE.LOADING,
        previousVacations: PAGE_STATE.LOADING,
    });
    const [page, setPage] = useState("upcomingVacation");
    const [pageNumber, setPageNumber] = useState(1);
    const [vacationTypeListModal, setVacationTypeListModal] = useState(false);
    const [year, setYear] = useState(new Date().getFullYear());
    const [vacationCount, setVacationCount] = useState();

    const vacationStatusRequest = useRequest("post", "vacation/status/user");
    const previousVacationsRequest = useRequest("get", "vacation/previous");
    const historyRequest = useRequest("get", "vacation/history");
    const hrUpcomingVacationRequest = useRequest("get", "vacation/upcoming");
    const vacationTimeCountRequest = useRequest("get", "vacation/time/count");

    const handlePageState = {
        expected: "upcomingVacation",
        used: "previousVacations",
        all: "histories",
    };

    const handleTab = (tab) => {
        navigate(`/vacation/request/${tab}`);
        setPageNumber(1);
        setYear(new Date().getFullYear());
        setPage(handlePageState[tab]);
    };

    const titleText = (v) => {
        if (!v?.HrVacation) return "연차";
        if (
            v.HrVacation.type === "연차" ||
            (v.HrVacation.HrVacationType && v.HrVacation.HrVacationType.days === 0.5)
        ) {
            if (v.timeUnit === "하루종일") return v.HrVacation.type;
            return v.timeUnit;
        }
        return v.HrVacation.title;
    };

    const timeText = (v) => {
        if (!v?.HrVacation) return " - ";
        if (
            titleText(v) === "오전반차" ||
            titleText(v) === "오후반차" ||
            titleText(v) === "시간반차" ||
            (v.HrVacation.HrvactionType && v.HrVacation.HrVacationType.days === 0.5)
        ) {
            return `${hourAndMinuteFormatter(v.startTime)}
				 ~
				${hourAndMinuteFormatter(v.endTime)}`;
        }
        return t("contants.form.VACATION_UNIT.0");
    };

    const getAllRequest = async () => {
        const MethodAndUrls = [
            {
                request: previousVacationsRequest,
                type: "queryString",
                params: { page: pageNumber, size: 10, userIdx: info.idx, year },
            },
            {
                request: historyRequest,
                type: "queryString",
                params: { page: pageNumber, size: 10, userIdx: info.idx, year },
            },
            {
                request: hrUpcomingVacationRequest,
                type: "queryString",
                params: { page: pageNumber, size: 10, userIdx: info.idx },
            },
        ];

        const Requests = MethodAndUrls.map((data) => {
            data.request.call(data.params, data.type);
        });

        await Promise.all(Requests);
    };

    useEffect(() => {
        switch (vacationTimeCountRequest.state) {
            case API_STATE.done:
                setVacationCount({
                    totalVacationTime: `${vacationTimeCountRequest.response.data.totalVacationTime}일`,
                    remainingVacationTime: `${vacationTimeCountRequest.response.data.remainingVacationTime}일`,
                });
                break;
            case API_STATE.error:
                console.log(vacationTimeCountRequest.error);
                // navigate("/404");
                break;
        }
    }, [vacationTimeCountRequest.state]);

    useEffect(() => {
        if (!info.idx) return;
        vacationTimeCountRequest.call(info.idx, "path");
    }, [info.idx]);

    useEffect(() => {
        if (Object.keys(vacationList).length !== 3) {
            return;
        }
        setPageState((prevState) => {
            return {
                ...prevState,
                histories: vacationList.histories.length !== 0 ? PAGE_STATE.VIEW : PAGE_STATE.EMPTY,
                previousVacations:
                    vacationList.previousVacations.data.length !== 0
                        ? PAGE_STATE.VIEW
                        : PAGE_STATE.EMPTY,
                upcomingVacation:
                    vacationList.upcomingVacation.data.length !== 0
                        ? PAGE_STATE.VIEW
                        : PAGE_STATE.EMPTY,
            };
        });
    }, [vacationList]);

    useEffect(() => {
        if (!info.idx) return;
        getAllRequest();
    }, [info.idx]);

    useEffect(() => {
        switch (previousVacationsRequest.state) {
            case API_STATE.done:
                const response = previousVacationsRequest.response.data;

                setVacationList((prevState) => {
                    return {
                        ...prevState,
                        previousVacations: {
                            data: response.previousVacations,
                            totalTime: response.totalTime,
                        },
                    };
                });
                setPagingObject((prevState) => {
                    return { ...prevState, previousVacations: response.pageable };
                });

                break;
            case API_STATE.error:
                console.error(previousVacationsRequest.error);
                // navigate("/404");
                break;
        }
    }, [previousVacationsRequest.state]);

    useEffect(() => {
        switch (historyRequest.state) {
            case API_STATE.done:
                const response = historyRequest.response.data;

                setVacationList((prevState) => {
                    return { ...prevState, histories: response.histories };
                });
                setPagingObject((prevState) => {
                    return { ...prevState, histories: response.pageable };
                });

                break;
            case API_STATE.error:
                console.error(historyRequest.error);
                // navigate("/404");
        }
    }, [historyRequest.state]);

    useEffect(() => {
        switch (hrUpcomingVacationRequest.state) {
            case API_STATE.done:
                const response = hrUpcomingVacationRequest.response.data;

                setVacationList((prevState) => {
                    return {
                        ...prevState,
                        upcomingVacation: {
                            data: response.upcomingVacation,
                            totalTime: response.totalTime,
                        },
                    };
                });
                setPagingObject((prevState) => {
                    return { ...prevState, upcomingVacation: response.pageable };
                });

                break;
            case API_STATE.error:
                console.error(hrUpcomingVacationRequest.error);
                // navigate("/404");
        }
    }, [hrUpcomingVacationRequest.state]);

    const headerButtons = [
        <button
            type="button"
            className="btn__solid"
            color="primary"
            onClick={() => setVacationTypeListModal(true)}
        >
            {t("HR.Vacation.application")}
        </button>,
    ];

    return (
        <>
            <NavbarAndSidebarLayout menu={MENU} type={TYPE} menuName={"vacation"}>
                {/*<PageHeader buttonList={headerButtons} />*/}
                <PageHeader
                    title="휴가신청/내역"
                    mainTitle="휴가"
                    subTitle="휴가신청/내역"
                    buttonList={headerButtons}
                />
                <If condition={pageState[page]}>
                    <MultiCases condition={[PAGE_STATE.VIEW, PAGE_STATE.EMPTY]}>
                        <Outlet
                            context={{
                                vacationTabList: TAB_LIST,
                                MENU,
                                TYPE,
                                info,
                                vacationList,
                                pageNumber,
                                pagingObject,
                                pageState,
                                year,
                                setPageNumber,
                                setYear,
                                setVacationList,
                                setPagingObject,
                                setPageState,
                                navigate,
                                vacationStatusRequest,
                                previousVacationsRequest,
                                historyRequest,
                                hrUpcomingVacationRequest,
                                handleTab,
                                titleText,
                                timeText,
                                historyState: pageState.histories,
                                upcomingState: pageState.upcomingVacation,
                                previousState: pageState.previousVacations,
                                vacationCount,
                            }}
                        />
                    </MultiCases>
                    <Case condition={PAGE_STATE.LOADING}>
                        <Loading />
                    </Case>
                </If>
            </NavbarAndSidebarLayout>

            {vacationTypeListModal && (
                <ApplyVacationModal
                    {...{
                        vacationTypeListModal,
                        setVacationTypeListModal,
                        getUpcomingVacation: getAllRequest,
                        vacationTimeCountRequest,
                        info,
                    }}
                />
            )}
        </>
    );
};

export default VacationListPage;


