import React, { useState } from "react";
import translation from "~/translations";

// Components
import AuthenticationLayout from "~/layouts/AuthenticationLayout";
import TabMenu from "~/common/components/TabMenu";
import { Case, If } from "~/common/components/If";

// Assets
import "~/assets/sass/pages/auth/terms.scss";
import { useTranslation } from "react-i18next";

const PrivacyPage = () => {
	const { t } = useTranslation();
	const currentPath = "policy";

	const [currentTab, setCurrentTab] = useState("privacy");
	const tabMenuList = [
		{ id: "terms", title: translation.t("components.Footer.terms") },
		{ id: "privacy", title: translation.t("components.Footer.privacy") },
	];

	return (
		<AuthenticationLayout contentSide={false} PATH={currentPath}>
			<div className="terms-card-head">
				<h3 className="head-title">{t("Auth.Join.TermsOfUseAndPrivacyPolicy")}</h3>
			</div>

			<div className="tabMenu-wrap">
				<TabMenu
					tabItem={tabMenuList}
					currentTab={currentTab}
					setCurrentTab={setCurrentTab}
				/>
				<If condition={currentTab}>
					<Case condition={"terms"}>
						<div className="article">
							<b style={{ fontSize: "1rem" }}>
								{t("Auth.Join.TermsOfUse.terms.wrap1.mainTitle1")}
								<br />
							</b>
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap1.subTitle1")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap1.content1")}
							<br />
							<b>
								<br />
								{t("Auth.Join.TermsOfUse.terms.wrap1.subTitle2")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap1.content2")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap1.subTitle3")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap1.content3")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap1.subTitle4")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap1.content4")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap1.subTitle5")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap1.content5")}
							<br />
							<br />
							<b style={{ fontSize: "1rem" }}>
								{t("Auth.Join.TermsOfUse.terms.wrap2.mainTitle1")}
								<br />
							</b>
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap2.subTitle1")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap2.content1")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap2.subTitle2")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap2.content2")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap2.subTitle3")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap2.content3")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap2.subTitle4")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap2.content4")}
							<br />
							<br />
							<b style={{ fontSize: "1rem" }}>
								{t("Auth.Join.TermsOfUse.terms.wrap3.mainTitle1")}
								<br />
							</b>
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap3.subTitle1")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap3.content1")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap3.subTitle2")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap3.content2")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap3.subTitle3")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap3.content3")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap3.subTitle4")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap3.content4")}
							<br />
							<br />
							<b style={{ fontSize: "1rem" }}>
								{t("Auth.Join.TermsOfUse.terms.wrap4.mainTitle1")}
								<br />
							</b>
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap4.subTitle1")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap4.content1")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap4.subTitle2")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap4.content2")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap4.subTitle3")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap4.content3")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap4.subTitle4")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap4.content4")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap4.subTitle5")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap4.content5")}
							<br />
							<br />
							<b style={{ fontSize: "1rem" }}>
								{t("Auth.Join.TermsOfUse.terms.wrap5.mainTitle1")}
								<br />
							</b>
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap5.subTitle1")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap5.content1")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap5.subTitle2")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap5.content2")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap5.subTitle3")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap5.content3")}
							<br />
							<br />
							<b>
								{t("Auth.Join.TermsOfUse.terms.wrap5.subTitle4")}
								<br />
							</b>
							{t("Auth.Join.TermsOfUse.terms.wrap5.content4")}
							<br />
							<br />
							{t("Auth.Join.TermsOfUse.terms.date")}
						</div>
					</Case>
					<Case condition={"privacy"}>
						<div className="article">
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap1.subTitle1")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap1.content1")}
							<br />
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap1.subTitle2")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap1.content2")}
							<br />
							<br />
							<b>{t("Auth.Join.PrivacyPolicy.terms.wrap1.subTitle2-1")}</b>
							<br />
							{t("Auth.Join.PrivacyPolicy.terms.wrap1.content2-1")}
							<br />
							<br />
							<b>{t("Auth.Join.PrivacyPolicy.terms.wrap1.subTitle2-2")}</b>
							<br />
							{t("Auth.Join.PrivacyPolicy.terms.wrap1.content2-2")}
							<br />
							<br />
							<b>{t("Auth.Join.PrivacyPolicy.terms.wrap1.subTitle2-3")}</b>
							<br />
							{t("Auth.Join.PrivacyPolicy.terms.wrap1.content2-3")}
							<br />
							<br />
							<b>{t("Auth.Join.PrivacyPolicy.terms.wrap1.subTitle2-4")}</b>
							<br />
							{t("Auth.Join.PrivacyPolicy.terms.wrap1.content2-4")}
							<br />
							<br />
							<b>{t("Auth.Join.PrivacyPolicy.terms.wrap1.subTitle2-5")}</b>
							<br />
							{t("Auth.Join.PrivacyPolicy.terms.wrap1.content2-5")}
							<br />
							<br />
							<table>
								<thead>
									<tr>
										<th style={{ width: "50%" }}>
											{t("Auth.Join.PrivacyPolicy.terms.table1.th1")}
										</th>
										<th style={{ width: "50%" }}>
											{t("Auth.Join.PrivacyPolicy.terms.table1.th2")}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td1")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td2")}</td>
									</tr>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td3")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td4")}</td>
									</tr>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td5")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td6")}</td>
									</tr>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td7")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td8")}</td>
									</tr>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td9")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td10")}</td>
									</tr>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td11")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td12")}</td>
									</tr>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td13")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table1.td14")}</td>
									</tr>
								</tbody>
							</table>
							{t("Auth.Join.PrivacyPolicy.terms.wrap2.desc1")}
							<br />
							<br />
							<b>{t("Auth.Join.PrivacyPolicy.terms.wrap2.subTitle1")}</b>
							<br />
							{t("Auth.Join.PrivacyPolicy.terms.wrap2.content1")}
							<br />
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap2.subTitle2")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap2.content2")}
							<br />
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap2.subTitle3")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap2.content3")}
							<br />
							<br />
							<table>
								<thead>
									<tr>
										<th style={{ width: "33.3%" }}>
											{t("Auth.Join.PrivacyPolicy.terms.table2.th1")}
										</th>
										<th style={{ width: "33.3%" }}>
											{t("Auth.Join.PrivacyPolicy.terms.table2.th2")}
										</th>
										<th style={{ width: "33.3%" }}>
											{t("Auth.Join.PrivacyPolicy.terms.table2.th3")}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table2.td1")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table2.td2")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table2.td3")}</td>
									</tr>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table2.td4")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table2.td5")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table2.td6")}</td>
									</tr>
								</tbody>
							</table>
							<br />
							<b>{t("Auth.Join.PrivacyPolicy.terms.wrap3.subTitle1")}</b>
							<br />
							{t("Auth.Join.PrivacyPolicy.terms.wrap3.content1")}
							<br />
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap3.subTitle2")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap3.content2")}
							<br />
							<br />
							<table>
								<thead>
									<tr>
										<th style={{ width: "33.3%" }}>
											{t("Auth.Join.PrivacyPolicy.terms.table3.th1")}
										</th>
										<th style={{ width: "33.3%" }}>
											{t("Auth.Join.PrivacyPolicy.terms.table3.th2")}
										</th>
										<th style={{ width: "33.3%" }}>
											{t("Auth.Join.PrivacyPolicy.terms.table3.th3")}
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td1")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td2")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td3")}</td>
									</tr>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td4")}</td>
										<td />
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td5")}</td>
									</tr>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td6")}</td>
										<td />
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td7")}</td>
									</tr>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td8")}</td>
										<td />
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td9")}</td>
									</tr>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td10")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td11")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td12")}</td>
									</tr>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td13")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td14")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td15")}</td>
									</tr>
									<tr>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td16")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td17")}</td>
										<td>{t("Auth.Join.PrivacyPolicy.terms.table3.td18")}</td>
									</tr>
								</tbody>
							</table>
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap4.subTitle1")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap4.content1")}
							<br />
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap4.subTitle2")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap4.content2")}
							<br />
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap4.subTitle3")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap4.content3")}
							<br />
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap4.subTitle4")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap4.content4")}
							<br />
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap4.subTitle5")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap4.content5")}
							<br />
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap4.subTitle6")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap4.content6")}
							<br />
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap4.subTitle7")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap4.content7")}
							<br />
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap4.subTitle8")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap4.content8")}
							<br />
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap4.subTitle9")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap4.content9")}
							<br />
							<br />
							<b>
								{t("Auth.Join.PrivacyPolicy.terms.wrap4.subTitle10")}
								<br />
							</b>
							{t("Auth.Join.PrivacyPolicy.terms.wrap4.content10")}
							<br />
						</div>
					</Case>
				</If>
			</div>
		</AuthenticationLayout>
	);
};

export default PrivacyPage;
