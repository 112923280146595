import { CollapseArrow } from "~/assets/svg/InterfacesIcon";
import React, { useState } from "react";
import AnnualAdjustDropdown from "~/pages/hr/ManagePage/vacationList/Modal/Detail/MonthRow/AnnualAdjustDropdown";
import { useTranslation } from "react-i18next";

const MonthRow = ({
	vacation,
	selectedUser,
	vacationDetailRequest,
	vacationSetting,
	vacationTimeCountRequest,
	openedAdjustmentDropdownDate,
	setOpenedAdjustmentDropdownDate,
}) => {
	const { t } = useTranslation();

	return (
		<div className="tr">
			<div className="td date">
				{vacation.date}
				{vacation.isJoinMonth && (
					<span className="join-month">
						{t("HR.Manage.vacationList.Modal.Detail.MonthRow.join-month")}
					</span>
				)}
				{vacation.isCurrentMonth && (
					<span className="this-month">
						{t("HR.Manage.vacationList.Modal.Detail.MonthRow.this-month")}
					</span>
				)}
			</div>
			<div className="td grant">{vacation.autoAddDaySign} {parseFloat(parseFloat(vacation.autoAddDay).toFixed(3))}</div>
			<div className="td forward">{vacation.escalationSign} {parseFloat(parseFloat(vacation.escalationDay).toFixed(3))}</div>
			<div className="td disappear">{vacation.destroyDay}</div>
			<div className="td adjustment">
				{/* 조정기록 없을 때 */}
				<button
					type="button"
					onClick={() => {
						setOpenedAdjustmentDropdownDate((prevState) => {
							return prevState === vacation.date ? null : vacation.date;
						});
					}}
					className={`btn-adjustment ${
						openedAdjustmentDropdownDate === vacation.date && "active"
					}`}
				>
					<CollapseArrow />
				</button>
				{openedAdjustmentDropdownDate === vacation.date && (
					<AnnualAdjustDropdown
						{...{
							date: vacation.date,
							selectedUser,
							vacationDetailRequest,
							vacationSetting,
							vacationTimeCountRequest,
						}}
					/>
				)}
			</div>
			<div className="td use">{vacation.usedDay}</div>
			<div className="td remain">{vacation.remainDay}</div>
		</div>
	);
};

export default MonthRow;
