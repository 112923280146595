import React, { useEffect, useState } from "react";
import { Case, If } from "~/common/components/If";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import LoadingPage from "~/pages/LoadingPage";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import View from "~/pages/community/BoardPage/BoardPost/Detail/View";
import Edit from "~/pages/community/BoardPage/BoardPost/Detail/Edit";
import { useParams } from "react-router";
import useRequest from "~/common/hooks/useRequest";
import { useNavigate } from "react-router-dom";

const Detail = () => {
	const navigate = useNavigate();
	const [pageState, setPageState] = useState(PAGE_STATE.VIEW);

	const [board, setBoard] = useState();
	const [prev, setPrev] = useState();
	const [next, setNext] = useState();

	const { idx, postIdx } = useParams();
	const boardDetailRequest = useRequest("get", `/community/board/${postIdx}?community=${idx}`);

	const onCancel = () => {
		setPageState(PAGE_STATE.VIEW);
	};

	const getBoardDetail = () => {
		boardDetailRequest.call();
	};

	useEffect(() => {
		switch (boardDetailRequest.state) {
			case API_STATE.done:
				const response = boardDetailRequest.response.data;
				setBoard(response.board);
				setPrev(response.prev);
				setNext(response.next);
				// increaseHitRequest.singleCall(postIdx, "path");

				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [boardDetailRequest.state]);

	return (
		<NavbarAndSidebarLayout menu={["community", "community"]} type="main">
			<If condition={pageState} {...{ setPageState }}>
				<Case condition={PAGE_STATE.LOADING}>
					<LoadingPage />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<View {...{ board, prev, next, getBoardDetail, setPageState }} />
				</Case>
				<Case condition={PAGE_STATE.EDIT}>
					<Edit {...{ onCancel }} defaultData={board} />
				</Case>
			</If>
		</NavbarAndSidebarLayout>
	);
};

export default Detail;
