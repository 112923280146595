import React from "react";
import { TopBtnIcon } from "~/assets/svg/InterfacesIcon";

const BackToTopButton = ({ mainRef }) => {
	const scrollToTop = () => {
		mainRef.current.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<button className="top-btn-wrap" onClick={scrollToTop}>
			<TopBtnIcon />
		</button>
	);
};

export default BackToTopButton;
