import React from "react";
import { useGlobalStore } from "~/store";
import { dateToString } from "~/utils/formatter";
import { IP_TYPE } from "~/common/constants/form";
import { Trans, useTranslation } from "react-i18next";

const TechSection = () => {
	const { t } = useTranslation();
	const [
		{
			ir: {
				product: { mainTech, advantages, IRProductIp },
			},
		},
	] = useGlobalStore();

	const patentList = () =>
		IRProductIp.map(({ idx, type, content, applyNum, date }) => (
			<ul key={idx} className="f__control">
				<li className="kind-select">{IP_TYPE[type]}</li>
				<li className="content">{content}</li>
				<li className="number">
					<Trans
						i18nKey="IR.IrDetail.Product.View.TechSection.number"
						components={[{ applyNum }]}
					/>
				</li>
				<li className="ipr-date">{dateToString(date, "yyyy-MM-dd")}</li>
			</ul>
		));

	return (
		<>
			<div className="section-title">
				{t("IR.IrDetail.Product.Edit.TechSection.Index.tech")}
			</div>
			<div className="c__form vertical sub-section">
				<div className="f__group">
					<label className="f__label">
						{t("IR.IrDetail.Product.Edit.TechSection.Index.mainTech")}
					</label>
					<div className="f__control">
						<textarea
							className="c__textarea f__item"
							defaultValue={mainTech}
							readOnly
						/>
					</div>
				</div>
				<div className="f__group">
					<label className="f__label">
						{t("IR.IrDetail.Product.Edit.TechSection.Index.advantage")}
					</label>
					<div className="f__control">
						<textarea
							className="c__textarea f__item"
							defaultValue={advantages}
							readOnly
						/>
					</div>
				</div>
			</div>
			<div className="c__form vertical sub-section">
				<div className="f__group">
					<div className="f__label">
						{t("IR.IrDetail.Product.Edit.TechSection.Index.property")}
					</div>
					{patentList()}
				</div>
			</div>
		</>
	);
};

export default TechSection;
