import React, {useState} from "react";
import PageHeader from "~/common/components/PageHeader";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";

import TabMenu from "~/common/components/TabMenu";
import { Case, If } from "~/common/components/If";
import HistoryList from "~/pages/management/PaymentPage/historyList";
import Management from "~/pages/management/PaymentPage/management";
import translation from "~/translations";

//asset
import "~/assets/sass/pages/mypage/admin/paymentPage/common.scss";


const PaymentPage = () => {

    const [currentTab, setCurrentTab] = useState("history");

    const tabMenuList = [
        { id: "history", title: translation.t("MyPage.admin.Payment.tabMenuList.history") },
        { id: "management", title: translation.t("MyPage.admin.Payment.tabMenuList.management") },
    ];

    return(
        <NavbarAndSidebarLayout menu={["management", "payment"]} type="main">
            <PageHeader
                title="청구"
                mainTitle="계정관리"
                subTitle="청구"
            />
            <TabMenu tabItem={tabMenuList} {...{ currentTab, setCurrentTab }} />
            <If condition={currentTab}>
                <Case condition={"history"}>
                    <HistoryList />
                </Case>
                <Case condition={"management"}>
                    <Management />
                </Case>
            </If>
        </NavbarAndSidebarLayout>
    )
}

export default PaymentPage
