import React, { useState } from "react";
import { PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import DropdownTemplate, {
	DropDownItem,
} from "~/common/components/DropdownPortal/DropdownTemplate";
import { ConnectIcon } from "~/assets/svg/MathIcon";
import { useTranslation } from "react-i18next";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import { useForm } from "react-hook-form";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";

const View = (props) => {
	const { t } = useTranslation();
	const {
		inviteList,
		pageState,
		handleActive,
		handleWithdraw,
		setAlertState,
		ALERT_MODAL_STATE,
		handleResend,
		resendMember,
		setResendMember,
	} = props;

	const [resendMailModal, setResendMailModal] = useState(false);
	const resendMailForm = useForm();

	const submit = ({ password }) => {
		handleResend(password);
	};

	const inviteTeamRow = () => {
		return inviteList.map((team, index) => {
			return (
				<ul className="tr" key={index}>
					<li className="td team-num">{index + 1}</li>
					<li className="td team-name">{team.name}</li>
					<li className="td team-mail">{team.id}</li>
					<li className="td team-role">{team.role}</li>
					<li className="td connect-chk">{team.status === "정상" && <ConnectIcon />}</li>
					<li className="td team-date">{team.regDate}</li>
					<li className={`td team-active ${team.status === "정상" && "active"}`}>
						{team.status}
						{team.role !== "관리자" && (
							<DropdownTemplate target={`dropdown_${team.idx}`} vertical>
								<DropDownItem
									onClick={() => {
										setResendMailModal(true);
										setResendMember(team);
									}}
								>
									{ALERT_MODAL_STATE.RESEND}
								</DropDownItem>
								{team.status === "정상" && (
									<DropDownItem
										onClick={() => {
											setAlertState(ALERT_MODAL_STATE.DEACTIVATE);
											handleActive(team.idx);
										}}
									>
										{ALERT_MODAL_STATE.DEACTIVATE}
									</DropDownItem>
								)}
								{team.status === "대기" && (
									<>
										<DropDownItem
											onClick={() => {
												setAlertState(ALERT_MODAL_STATE.ACTIVATE);
												handleActive(team.idx);
											}}
										>
											{ALERT_MODAL_STATE.ACTIVATE}
										</DropDownItem>
									</>
								)}
								<DropDownItem
									onClick={() => {
										handleWithdraw(team);
									}}
								>
									{ALERT_MODAL_STATE.IS_WITHDRAW}
								</DropDownItem>
							</DropdownTemplate>
						)}
					</li>
				</ul>
			);
		});
	};

	return (
		<>
			<div className="c__card">
				<div className="invite-table">
					<ul className="thead">
						<li className="team-num">No</li>
						<li className="team-name">{t("MyPage.admin.Team.View.team-name")}</li>
						<li className="team-mail">{t("MyPage.admin.Team.View.team-mail")}</li>
						<li className="team-role">{t("MyPage.admin.Team.View.team-role")}</li>
						<li className="connect-chk" />
						<li className="team-date">{t("MyPage.admin.Team.View.team-date")}</li>
						<li className="team-active">{t("MyPage.admin.Team.View.team-active")}</li>
						<li className="dropdown-wrap" />
					</ul>
					{inviteTeamRow()}
					{pageState === PAGE_STATE.LOADING && <Loading />}
				</div>
			</div>
			{/*초대 메일 재전송 */}
			<ModalTemplate
				title="초대 메일 재전송"
				width={700}
				height={343}
				modalState={resendMailModal}
				setModalToggle={setResendMailModal}
				className="modal-resend-mail"
			>
				<div className="modal-content">
					<p className="main-desc">초대할 팀원의 초기 비밀번호를 입력해 주세요.</p>
					<p className="sub-desc">
						팀원이 비밀번호를 잊은 경우 관리자가 직접 비밀번호를 입력해 초대 메일을
						재전송할 수 있습니다.
					</p>
					<div className="f__group-row">
						<Form useForm={resendMailForm}>
							<Input
								name="name"
								className="team-invite-name"
								placeholder={resendMember?.name}
								readonly
							/>
							<Input
								name="email"
								type="email"
								className="team-invite-mail"
								placeholder={resendMember?.id}
								readonly
							/>
							<Input
								name="password"
								type="password"
								className="team-invite-password"
								placeholder={t("Auth.Join.Form.auth-form.password.label")}
								validation={{
									required: "필수 입력 사항 입니다.",
									pattern: {
										value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/,
										message:
											"8~16자 영문 대소문자, 숫자, 특수문자를 사용해주세요.",
									},
								}}
							/>
						</Form>
					</div>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => setResendMailModal(false)}
					>
						취소
					</button>
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={resendMailForm.handleSubmit(submit)}
					>
						초대하기
					</button>
				</div>
			</ModalTemplate>
		</>
	);
};

export default View;
