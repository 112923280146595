import React from "react";
import { Trans, useTranslation } from "react-i18next";

// Components
import AuthFooter from "~/common/components/AuthFooter";
import channelBot from "~/assets/img/channel-bot.png";

const ContentSide = () => {
	return (
		<div className="content-side">
			<div className="main-phrase">
				<Trans i18nKey="Layout.AuthenticationLayout.main-phrase" components={[<p />]} />
			</div>
			<div className="sub-phrase">
				<Trans i18nKey="Layout.AuthenticationLayout.sub-phrase" components={[<p />]} />
			</div>
		</div>
	);
};

const AuthenticationLayout = ({ contentSide, children, PATH }) => {
	return (
		<main className={`l__onePage p__` + PATH}>
			<div className="l__onePageContainer">
				<div className="c__card">
					{contentSide && <ContentSide />}
					<div className="form-side">{children}</div>
				</div>
			</div>
			<AuthFooter />
		</main>
	);
};

export default AuthenticationLayout;
