import React from "react";
import { useNavigate } from "react-router";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";

const SubMenuItem = ({ active, disabled, title, engTitle, id, handleClick }) => {
	const { i18n } = useTranslation();
	const classString = `${active}${disabled ? " disabled" : ""}`;

	return (
		<li
			name={id}
			className={classString}
			onClick={!disabled ? handleClick : null}
			style={id === "vcconnect" ? { display: "none" } : {}}
		>
			{i18n.language === "ko" ? title : engTitle}
		</li>
	);
};

const SubMenu = ({ list, currentMenu, currentType, user }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const subMenuList = () => {
		return list.reduce((acc, menu) => {
			if (menu.viewMode === true) {
				const title = menu.title;
				const engTitle = menu.engTitle;
				const id = menu.id;
				const active = id === currentMenu && "active";
				const element = (
					<SubMenuItem
						key={acc.length}
						handleClick={() => {
							navigate(menu.to);
						}}
						disabled={menu.auth === "none"}
						{...{
							active,
							title,
							engTitle,
							id,
						}}
					/>
				);
				return acc.concat(element);
			} else {
				return acc;
			}
		}, []);
	};

	if (!list || list.length === 0) return <></>;
	
	return (
		<ul className="l__subMenu">
			
			<PerfectScrollbar
				style={
					currentType === "myPage" ? { height: "calc(100% - 120px)" } : { height: "100%" }
				}
			>
				{subMenuList()}
			</PerfectScrollbar>
		</ul>
	);
};

export default SubMenu;
