import React, { useEffect, useState } from "react";
import ModalPortal from "~/common/components/ModalPortal/index";
import PropsProvider from "~/common/components/PropsProvider";

//Assets
import "~/assets/sass/component/modal.scss";
import { SolidCloseIcon } from "~/assets/svg/CloseIcon";
import OutsideAlerter from "~/common/hooks/OutsideAlerter";

/**
 * @param { Props } props
 *
 * @typedef Props
 * @property {boolean} modalState - modal toggle state
 * @property {function} setModalToggle - modal toggle setter
 * @property {string} title - modal header title
 * @property {*} children - modal contents
 * @property {boolean} mask - modal background mask
 * @property {number} width - width
 * @property {number} height - height
 * @property {string} className - className
 * @property {boolean} closeable - close when click modal mask
 */
const ModalTemplate = (props) => {
	const {
		modalState,
		setModalToggle,
		title,
		children,
		mask = true,
		width = 1000,
		height = 500,
		className,
		closeable = true,
		headerComponent,
	} = props;

	const [modalTitle, setModalTitle] = useState("");

	useEffect(() => {
		setModalTitle(title);
	}, [title]);

	if (!modalState) {
		return <></>;
	} else {
		return (
			<ModalPortal>
				<div className={`c__modal toggleOpen ${mask && "show-mask"} ${className}`}>
					<OutsideAlerter
						callback={() => {
							closeable && setModalToggle(false);
						}}
					>
						<div
							className="modal-container"
							style={{ width: width, minHeight: height }}
						>
							{title && (
								<div className="modal-header">
									<p className="modal-title">{modalTitle || ""}</p>
									<button
										className="modal-close"
										onClick={() => setModalToggle(false)}
									>
										<SolidCloseIcon />
									</button>
								</div>
							)}
							{headerComponent && headerComponent}
							<div className="modal-body">
								<PropsProvider
									props={{ modalState, setModalToggle, setModalTitle }}
								>
									{children}
								</PropsProvider>
							</div>
						</div>
					</OutsideAlerter>
				</div>
			</ModalPortal>
		);
	}
};

export default ModalTemplate;
