import React, { useEffect } from "react";
import Form, { Group } from "~/common/components/Form";
import Radio from "~/common/components/Radio";
import DatePicker from "~/common/components/DatePicker";
import { useFieldArray, useForm } from "react-hook-form";
import Input from "~/common/components/Input";
import Checkbox from "~/common/components/Checkbox";
import { optionsGenerator } from "~/common/components/Select";
import { DAY_TYPE, EMPLOYEE_TYPE } from "~/common/constants/form";
import useRequest from "~/common/hooks/useRequest";
import { useGlobalStore } from "~/store";
import { RoundMinusIcon } from "~/assets/svg/InterfacesIcon";
import { PlusIcon } from "~/assets/svg/MathIcon";
import { differenceInMinutes, format } from "date-fns";
import useAlert from "~/common/hooks/useAlert";
import { hoursDurationFormatter } from "~/utils/formatter";

const InputRow = ({ useForm, name, remove }) => {
	return (
		<Form useForm={useForm} className="row">
			<DatePicker name={name("workStartTime")} time />
			<span className="dash"> - </span>
			<DatePicker name={name("workEndTime")} time />
			<button type="button" className="btn-round-delete" onClick={remove}>
				<RoundMinusIcon />
			</button>
		</Form>
	);
};

const EditModal = (props) => {
	const {
		closeEditModal,
		workTypeRequest,
		selectIdx,
		workTimeTotal,
		setWorkTimeTotal,
		weekTimeTotal,
		setWeekTimeTotal,
	} = props;
	const [
		{
			hrSetting: { workType },
		},
	] = useGlobalStore();

	const workTypeForm = useForm({
		defaultValues: {
			idx: selectIdx ? selectIdx : "",
			title: workType.title,
			employeeTypeIdx: workType.HrWorkEmployeeType,
			restStartTime: workType.restStartTime,
			restEndTime: workType.restEndTime,
			workDays: workType.workDays,
			paidDayOff: workType.paidDayOff,
			HrWorkTimes: workType.HrWorkTimes,
		},
	});

	const { fields, append, remove } = useFieldArray({
		control: workTypeForm.control,
		name: "HrWorkTimes",
	});

	const ROW_INIT_DATA = {
		workStartTime: new Date(),
		workEndTime: new Date(),
	};

	const workTypeMutation = useRequest(selectIdx ? "put" : "post", "hr/work");
	const [showWarningAlert, WarningAlert] = useAlert();
	const [showMutateAlert, MutateAlert] = useAlert();

	const submit = async (data) => {
		let calWorkTimes = [];

		data.HrWorkTimes.forEach((d) => {
			calWorkTimes.push(differenceInMinutes(d.workStartTime, d.workEndTime));
		});

		if (calWorkTimes.every((t) => t === calWorkTimes[0])) {
			let newWorkTime = [];
			data.HrWorkTimes.forEach((d) => {
				newWorkTime.push({
					...d,
					workStartTime: format(d.workStartTime, "HH:mm"),
					workEndTime: format(d.workEndTime, "HH:mm"),
				});
			});

			const requestData = {
				...data,
				employeeTypeIdx: parseInt(data.employeeTypeIdx),
				HrWorkTimes: newWorkTime,
				restStartTime: format(data.restStartTime, "HH:mm"),
				restEndTime: format(data.restEndTime, "HH:mm"),
			};

			if (selectIdx) {
				onMutate(requestData);
			} else {
				onSubmit(requestData);
			}
		} else {
			showWarningAlert();
		}
	};

	const onSubmit = (requestData) => {
		workTypeMutation
			.asyncCall(requestData)
			.then(() => {
				closeEditModal();
				workTypeRequest.call();
			})
			.catch((msg) => console.log(msg));
	};

	const onMutate = (requestData) => {
		showMutateAlert()
			.then(async () => {
				await onSubmit(requestData);
			})
			.catch(() => {});
	};

	const workStartTime = workTypeForm.watch("HrWorkTimes.0.workStartTime");
	const workEndTime = workTypeForm.watch("HrWorkTimes.0.workEndTime");
	const restStartTime = workTypeForm.watch("restStartTime");
	const restEndTime = workTypeForm.watch("restEndTime");
	const workDays = workTypeForm.watch("workDays");
	const paidDayOff = workTypeForm.watch("paidDayOff");

	useEffect(() => {
		const commutingHours = differenceInMinutes(workEndTime, workStartTime);

		const restHours = differenceInMinutes(restEndTime, restStartTime);
		const workHours = commutingHours - restHours;

		setWorkTimeTotal(hoursDurationFormatter(workHours * workDays?.length));
		setWeekTimeTotal(
			hoursDurationFormatter(workHours * (workDays?.length + paidDayOff?.length))
		);
	}, [workStartTime, workEndTime, restStartTime, restEndTime, workDays, paidDayOff]);

	const isDuplicate = (value) => {
		return value.filter((v) => v !== "" && workDays.includes(v)).length === 0;
	};

	return (
		<>
			<div className="modal-content">
				<Form useForm={workTypeForm}>
					<Input name={"title"} label={"유형 이름"} required />
					<Radio
						label="근무유형 설정"
						name="employeeTypeIdx"
						options={optionsGenerator(EMPLOYEE_TYPE)}
						boolean
					/>
				</Form>
				<div className="f__group commuting-time-group">
					<label className="f__label">출 퇴근 시간</label>
					<div>
						<button
							className="plus-btn"
							type="button"
							onClick={() => append(ROW_INIT_DATA)}
						>
							<PlusIcon />
						</button>
						{fields.map((field, index) => {
							const getName = (propName) => `HrWorkTimes.${index}.${propName}`;
							const removeItem = () => remove(index);
							return (
								<InputRow
									key={field.id}
									name={getName}
									remove={removeItem}
									useForm={workTypeForm}
								/>
							);
						})}
					</div>
				</div>
				<Form useForm={workTypeForm}>
					<Group label="일 기본 휴게시간" className="rest-time-group">
						<DatePicker name="restStartTime" time />
						<span className="dash"> - </span>
						<DatePicker name="restEndTime" time />
					</Group>
					<Checkbox
						label="일하는날"
						name="workDays"
						className={"dateCheckbox"}
						groupName="checkbox-group"
						options={optionsGenerator(DAY_TYPE)}
					/>
					<Checkbox
						label="유급휴일(주휴일)"
						name="paidDayOff"
						className={"dateCheckbox"}
						options={optionsGenerator(DAY_TYPE)}
						groupName="checkbox-group"
						validation={{
							validate: (value) =>
								isDuplicate(value) || "유급휴일과 일하는 날은 중복 될 수 없습니다.",
						}}
					/>
				</Form>
				<div className="phrase-wrap">
					<p className="week-time">주 {workTimeTotal}</p>
					<p className="total-time">(주휴일 포함시 주 {weekTimeTotal})</p>
				</div>
			</div>
			<div className="modal-footer">
				<button className="btn__solid" color="gray" onClick={closeEditModal}>
					취소
				</button>
				<button
					className="btn__solid"
					color="primary"
					onClick={workTypeForm.handleSubmit(submit)}
				>
					{selectIdx ? "수정하기" : "추가하기"}
				</button>
			</div>
			<MutateAlert
				type="info"
				message={
					<div className="alert-workType">
						<p>해당 근무 유형을 수정하시겠습니까?</p>
						<span>수정된 근무유형은 익일 00시 이후 반영됩니다.</span>
					</div>
				}
				confirm="확인"
				reject="취소"
			/>
			<WarningAlert
				type="info"
				message={
					<div className="alert-workType">
						<p>총 근무 시간이 일치하지 않습니다.</p>
						<span>출·퇴근 시간 및 일 기본 휴게시간을 확인해주세요.</span>
					</div>
				}
				confirm="확인"
			/>
		</>
	);
};

export default EditModal;
