import React, { useEffect } from "react";
import Form from "~/common/components/Form";
import Radio from "~/common/components/Radio";

const RadioType = ({ useForm, form, setOptions, apply }) => {
	useEffect(() => {
		useForm.setValue(form.order.toString(), form.answer);
	}, []);
	console.log(apply);

	return (
		<>
			<Form useForm={useForm} className="radio-form-wrap horiz">
				<Radio
					label={form.title}
					name={form.order.toString()}
					options={setOptions(form.selectOption)}
					required={form.required}
					readOnly={apply}
				/>
			</Form>
		</>
	);
};

export default RadioType;
