import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

// Components
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import { Case, If } from "~/common/components/If";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import { useGlobalStore } from "~/store";
import List from "~/pages/mypage/admin/AuthPage/list";
import View from "~/pages/mypage/admin/AuthPage/View";
import Edit from "~/pages/mypage/admin/AuthPage/Edit";

// Assets
import "~/assets/sass/pages/mypage/admin/auth.scss";
import { CautionIcon } from "~/assets/svg/InterfacesIcon";

export const AuthPageIntro = () => {
	const { t } = useTranslation();
	return (
		<div className="pageIntro">
			<CautionIcon />
			<div className="content">
				<h3>
					<Trans i18nKey="MyPage.admin.Auth.content.h3" components={[<b />]} />
				</h3>
				<p className="pre">{t("MyPage.admin.Auth.content.p")}</p>
			</div>
		</div>
	);
};

const AdminAuthPage = () => {
	const { t } = useTranslation();
	const [
		{
			user: { info },
		},
	] = useGlobalStore();
	const [page, setPage] = useState(PAGE_STATE.LOADING);
	//팀원 리스트
	const [authMemberList, setAuthMemberList] = useState([]);
	//메뉴 리스트
	const [pluginList, setPluginList] = useState([]);
	//선택된 유저
	const [selectUser, setSelectUser] = useState();
	//권한 리스트
	const [authorityList, setAuthorityList] = useState([]);
	//팀원 리스트 조회
	const authMemberListRequest = useRequest("get", "admin/auth/member");
	//플러그인 리스트 조히
	const pluginListRequest = useRequest("get", "admin/auth/plugin");
	//상세 권한 조회
	const getAuthorityRequest = useRequest("get", "admin/auth/detail");
	//Edit Page Alert Modal
	const [authEditAlertModal, setAuthEditAlertModal] = useState(false);
	const [authEditAlertContent, setAuthEditAlertContent] = useState(false);
	const [alertState, setAlertState] = useState("");

	//플러그인 메뉴 리스트 조회 Request
	useEffect(() => {
		pluginListRequest.singleCall();
	}, [pluginListRequest]);

	//플러그인 메뉴 리스트 조회 Response
	useEffect(() => {
		switch (pluginListRequest.state) {
			case API_STATE.done:
				setPluginList(pluginListRequest.response.data);
				break;
			case API_STATE.error:
				console.log("error", pluginListRequest.error);
				alert(t("MyPage.Alert"));
				break;
			default:
				break;
		}
	}, [pluginListRequest.state, pluginListRequest.done, pluginListRequest.error]);

	//팀원 리스트 조회 Request
	useEffect(() => {
		authMemberListRequest.singleCall();
	}, [authMemberListRequest]);

	//팀원 리스트 조회 Response
	useEffect(() => {
		switch (authMemberListRequest.state) {
			case API_STATE.done:
				const newData = authMemberListRequest.response.data.map((d) => ({
					...d,
					roleName:
						d.role === "admin"
							? t("MyPage.Admin")
							: d.role === "user"
							? t("MyPage.User")
							: t("MyPage.Manager"),
				}));
				setAuthMemberList(newData);
				if (selectUser === undefined) {
					setSelectUser(newData[0]);
				} else {
					setSelectUser(newData.find((d) => d.idx === selectUser.idx));
				}
				setPage(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error", authMemberListRequest.error);
				alert(t("MyPage.Alert"));
				break;
			default:
				break;
		}
	}, [authMemberListRequest.state, authMemberListRequest.done, authMemberListRequest.error]);

	//선택한 유저 권한 조회 Request
	useEffect(() => {
		if (authMemberList.length > 0 && selectUser !== undefined) {
			getAuthorityRequest.call(selectUser.idx, "path");
		}
	}, [selectUser]);

	//선택한 유저 권한 조회 Response
	useEffect(() => {
		switch (getAuthorityRequest.state) {
			case API_STATE.done:
				let newData = [];

				if (getAuthorityRequest.response.data === "admin") {
					pluginList.forEach((p) => {
						const menuAuth = p.subMenu.map((s) => ({
							...s,
							viewAuth: true,
							editAuth: true,
						}));
						newData.push({ ...p, subMenu: menuAuth });
					});
				} else {
					const viewAuth = getAuthorityRequest.response.data.viewAuth;
					const editAuth = getAuthorityRequest.response.data.editAuth;

					pluginList.forEach((p) => {
						const menuAuth = p.subMenu.map((s) => ({
							...s,
							viewAuth:
								viewAuth.length > 0 &&
								viewAuth.find((v) => v.pluginMenuIdx === s.idx)
									? viewAuth.find((v) => v.pluginMenuIdx === s.idx).auth
									: false,
							editAuth:
								editAuth.length > 0 &&
								editAuth.find((v) => v.pluginMenuIdx === s.idx)
									? editAuth.find((v) => v.pluginMenuIdx === s.idx).auth
									: false,
						}));
						newData.push({ ...p, subMenu: menuAuth });
					});
				}

				setAuthorityList(newData);
				break;
			case API_STATE.error:
				console.log("error", getAuthorityRequest.error);
				alert(t("MyPage.Alert"));
				break;
			default:
				break;
		}
	}, [getAuthorityRequest.state, getAuthorityRequest.done, getAuthorityRequest.error]);

	//페이지 상단 버튼 그룹
	const buttonList =
		page === PAGE_STATE.VIEW
			? [
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={() => setPage(PAGE_STATE.EDIT)}
					>
						{t("button.Modify")}
					</button>,
			  ]
			: selectUser?.role === "admin"
			? [
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => {
							setPage(PAGE_STATE.VIEW);
						}}
					>
						{t("button.back")}
					</button>,
			  ]
			: [
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => {
							setAuthEditAlertModal(true);
							setAuthEditAlertContent(
								<>
									{selectUser.name}
									{t("MyPage.admin.Auth.btn-cancel")}
								</>
							);
							setAlertState("cancel");
						}}
					>
						{t("button.cancel")}
					</button>,
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={() => {
							setAuthEditAlertModal(true);
							setAuthEditAlertContent(
								selectUser.name + t("MyPage.admin.Auth.btn-submit")
							);
							setAlertState("submit");
						}}
					>
						{t("button.application")}
					</button>,
			  ];

	return (
		<NavbarAndSidebarLayout menu={["admin", "auth"]} type="myPage">
			<PageHeader buttonList={buttonList} />
			<AuthPageIntro />
			<div className="admin-auth-cardRow">
				<List
					{...{
						page,
						setPage,
						authMemberList,
						selectUser,
						setSelectUser,
						authMemberListRequest,
						info,
					}}
				/>
				<If
					condition={page}
					{...{
						setPage,
						selectUser,
						authorityList,
						authMemberListRequest,
						getAuthorityRequest,
						authEditAlertModal,
						setAuthEditAlertModal,
						authEditAlertContent,
						alertState,
					}}
				>
					<Case condition={PAGE_STATE.VIEW}>
						<View />
					</Case>
					<Case condition={PAGE_STATE.EDIT}>
						<Edit {...{ info }} />
					</Case>
				</If>
			</div>
		</NavbarAndSidebarLayout>
	);
};

export default AdminAuthPage;
