import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { PrivacyPolicy, TermsOfUse } from "~/pages/auth/JoinPage/components/policy";
import { Terms } from "~/pages/auth/JoinPage";

const JoinPolicy = ({ setCurrentStep, setCheckPolicy }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isRequiredChecked, setIsRequiredChecked] = useState(false);
	const [policyList, setPolicyList] = useState([
		{
			text: Terms.SERVICE,
			content: <TermsOfUse />,
			checked: false,
			required: true,
		},
		{
			text: Terms.PRIVACY,
			content: <PrivacyPolicy />,
			checked: false,
			required: true,
		},
		{
			text: Terms.MARKETING,
			content: null,
			checked: false,
			required: false,
		},
	]);

	const handleCheckAll = () => {
		const value = policyList.map((v) => ({
			...v,
			checked: !isCheckAll,
		}));

		setIsCheckAll(!isCheckAll);
		setPolicyList(value);
	};

	useEffect(() => {
		//체크박스 모두 선택 유무
		if (policyList.find((p) => p.checked === false)) {
			setIsCheckAll(false);
		} else {
			setIsCheckAll(true);
		}

		let newList = [];

		//필수 항목 체크 완료여부
		policyList.forEach((d) => {
			if (d.required) return newList.push(d);
		});

		//회원가입 Form에 담아 보낼 배열로 변경
		const newData = policyList.map((d) => ({
			term: d.text,
			status: d.checked,
		}));

		//필수 항목 체크 완료에 따라 버튼 disable 토글
		if (newList.find((d) => d.checked !== true)) {
			setIsRequiredChecked(false);
		} else {
			setCheckPolicy(newData);
			setIsRequiredChecked(true);
		}
	}, [policyList]);

	return (
		<>
			<div className="join-auth-all">
				<div className="checkbox">
					<label className={`checkbox-custom`}>
						<input type="checkbox" checked={isCheckAll} onChange={handleCheckAll} />
						<span className="checkmark" />
						<span className="checkbox-label join-chk">
							{t("Auth.Join.Policy.join-chk")}
						</span>
					</label>
				</div>
			</div>
			{policyList.map((p, p_idx) => (
				<div className="join-auth-wrap" key={p_idx}>
					<div className="checkbox">
						<label className={`checkbox-custom`}>
							<input
								type="checkbox"
								checked={p.checked}
								onChange={() => {
									const value = policyList.map((policy, idx) =>
										p_idx === idx ? { ...policy, checked: !p.checked } : policy
									);
									setPolicyList(value);
								}}
							/>
							<span className="checkmark" />
							<span className="checkbox-label join-chk">
								{p.text}{" "}
								<b>
									(
									{p.required
										? t("Auth.Join.Policy.b.0")
										: t("Auth.Join.Policy.b.1")}
									)
								</b>
							</span>
						</label>
					</div>
					{p.content !== null && <div className="article">{p.content}</div>}
				</div>
			))}
			<div className="btn-wrap">
				<button className="btn__solid" color="gray" onClick={() => navigate("/login")}>
					{t("button.prev")}
				</button>
				<button
					className="btn__solid"
					color="primary"
					disabled={!isRequiredChecked}
					onClick={() => setCurrentStep("bizType")}
				>
					{t("button.next")}
				</button>
			</div>
		</>
	);
};

export default JoinPolicy;
