import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { PlusIcon } from "~/assets/svg/MathIcon";
import { RoundMinusIcon } from "~/assets/svg/InterfacesIcon";
import Input from "~/common/components/Input";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import { useTranslation } from "react-i18next";
import translation from "~/translations";
import { makeRandomPassword } from "~/utils/password";

const InviteInputGroup = ({ index, remove, errors, inviteForm }) => {
	const { t } = useTranslation();
	return (
		<div className="f__group-row">
			<div className="f__group team-invite-name">
				<div
					className={`f__control  ${errors.inviteFormField?.[index]?.name ? "has-error" : ""
						}`}
				>
					<Input
						{...inviteForm}
						name={`inviteFormField.${index}.name`}
						required
						placeholder={t("Auth.Join.Form.auth-form.name")}
					/>
					{errors.inviteFormField?.[index] && (
						<p className="f__feedback">
							{errors.inviteFormField[index]?.name?.message}
						</p>
					)}
				</div>
			</div>
			<div className="f__group team-invite-mail">
				<div
					className={`f__control  ${errors.inviteFormField?.[index]?.id ? "has-error" : ""
						}`}
				>
					<Input
						{...inviteForm}
						name={`inviteFormField.${index}.id`}
						required
						placeholder={t("Auth.Join.Form.auth-form.email.label")}
						type={"email"}
						autoComplete={"new-password"}
						validation={{
							maxLength: {
								value: 250,
								message: translation.t(
									"MyPage.admin.Team.InviteModal.email.maxLength"
								),
							},
							pattern: {
								value: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i,
								message: translation.t(
									"MyPage.admin.Team.InviteModal.email.pattern"
								),
							},
						}}
					/>
					{errors.inviteFormField?.[index] && (
						<p className="f__feedback">{errors.inviteFormField[index]?.id?.message}</p>
					)}
				</div>
			</div>
			<div className="f__group team-invite-password">
				<div
					className={`f__control  ${errors.inviteFormField?.[index]?.pw ? "has-error" : ""
						}`}
					style={{
						marginBottom: errors.inviteFormField?.[index]?.pw?.type === "pattern" && 33,
					}}
				>
					<Input
						{...inviteForm}
						name={`inviteFormField.${index}.pw`}
						readonly
						placeholder={t("Auth.Join.Form.auth-form.password.label")}
						// type={"password"}
						autoComplete={"new-password"}
					/>
					{errors.inviteFormField?.[index] && (
						<p
							className="f__feedback"
							style={{
								lineHeight:
									errors.inviteFormField?.[index]?.pw?.type === "pattern" &&
									"1.3em",
							}}
						>
							{errors.inviteFormField[index]?.pw?.message}
						</p>
					)}
				</div>
			</div>
			<button type="button" className="btn-round-delete" onClick={() => remove(index)}>
				<RoundMinusIcon />
			</button>
		</div>
	);
};

const InviteForm = ({ setStep, setModalToggle, setInviteResponseData, excelData,setExcelData }) => {
	const { t } = useTranslation();
	const inviteForm = useForm();
	const {
		setError,
		control,
		handleSubmit,
		formState: { errors },
	} = inviteForm;
	const [loading, setLoading] = useState(false);

	const { fields, append, remove } = useFieldArray({
		control,
		name: "inviteFormField",
		keyName: "key",
		defaultValue: {},
	});

	const inviteRequest = useRequest("post", "admin/inviteMember");

	const request = (data) => {
		// if(!excelValidation(data)){
		// 	alert('엑셀 양식을 확인하세요.')
		// }
		let duplicatedList = [];
		data.inviteFormField.forEach((d, index) => {
			const length = data.inviteFormField.filter(({ id }) => id === d.id).length;
			if (length > 1) {
				duplicatedList.push(index);
			}
		});
		if (duplicatedList.length > 0) {
			duplicatedList.forEach((i) => {
				setError(`inviteFormField.${i}.id`, {
					type: "duplicated",
					message: translation.t("MyPage.admin.Team.InviteModal.duplicated"),
				});
			});
		} else {
			inviteRequest.call({ inviteList: data.inviteFormField });
			setLoading(true);
		}
	};

	const excelValidation = (dataArray) => {
		// 이메일 형식 검증을 위한 정규표현식
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		// 날짜 형식 검증을 위한 정규표현식 (YYYY-MM-DD)
		const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

		return dataArray.every(item => {
			const { email, joinDate, workStartDay } = item;
			// 이메일 형식 검증
			if (!emailRegex.test(email)) {
				return false;
			}
			// joinDate 형식 검증
			if (joinDate && !dateRegex.test(joinDate)) {
				return false;
			}

			// workStartDay 형식 검증
			if (workStartDay && !dateRegex.test(workStartDay)) {
				return false;
			}
			return true;
		});
	}


	useEffect(() => {
		switch (inviteRequest.state) {
			case API_STATE.done:
				const responseData = inviteRequest.response.data;
				setInviteResponseData(responseData);
				setLoading(false);
				setStep("result");
				fields.forEach((_, index) => remove(index));
				break;
			case API_STATE.error:
				switch (inviteRequest.error.statusCode) {
					case 401:
						alert(t("MyPage.admin.Team.InviteModal.alert.401"));
						break;
					case 409:
						alert(t("MyPage.admin.Team.InviteModal.alert.409"));
						break;
					case 500:
					default:
						console.log("error", inviteRequest.error);
						alert(t("Alert.alert1"));
						break;
				}
				break;
			default:
				break;
		}
	}, [
		inviteRequest.state,
		inviteRequest.response,
		inviteRequest.error,
		setInviteResponseData,
		setStep,
	]);

	useEffect(() => {
		if (excelData.length > 0) {
			append(excelData
				.map(ele => ({ ...ele, id: ele.email, pw: makeRandomPassword() })).map(({ email, ...rest }) => rest));
		}
	}, [excelData])

	return (
		<>
			<div className="modal-content invite-form">
				{loading ? (
					<Loading />
				) : (

					<>
						<button
							className="dashed-btn plus-btn"
							onClick={() => append({ name: "", id: "", pw: makeRandomPassword() })}
						>
							<PlusIcon />
							{t("MyPage.admin.Team.InviteModal.btn-plus")}
						</button>
						{fields.map((item, index) => {
							return (
								<InviteInputGroup
									key={item.key}
									index={index}
									control={control}
									remove={remove}
									inviteForm={inviteForm}
									errors={errors}
								/>
							);
						})}
					</>
				)}
			</div>
			<div className="modal-footer">
				<button className="btn__solid" color={"gray"} onClick={() => {
					fields.forEach((_, index) => remove(index));
					setExcelData([])
					setModalToggle(false);
				}}>
					{t("button.cancel")}
				</button>
				<button className="btn__solid" color={"primary"} onClick={handleSubmit(request)}>
					{t("Community.BoardPage.BoardPost.List.button-invite")}
				</button>
			</div>
		</>
	);
};

export default InviteForm;
