import React from "react";

// Assets
import "~/assets/sass/pages/hr/setting/workType.scss";
import { SolidCloseIcon } from "~/assets/svg/CloseIcon";

const AllowanceView = () => {
	return (
		<>
			<h3 className="title">수당지급</h3>
			<div className="c__cardRow card-wrap">
				<h4 className="type-title">수당 항목</h4>
				<div className="c__card type-card">
					<span className="close-icon">
						<SolidCloseIcon />
					</span>
					<div className="content">
						<div className="type">식비</div>
						<div className="time-wrap">
							<span className="week-time">주 40시간</span>
							<span className="month-time">월 173.8시간</span>
						</div>
						<p className="total-time">주휴일 포함시 주 48시간, 월 208.6시간</p>
					</div>
				</div>
				<div className="c__card type-card">
					<span className="close-icon">
						<SolidCloseIcon />
					</span>
					<div className="content">
						<div className="type">차량유지비</div>
						<div className="time-wrap">
							<span className="week-time">주 40시간</span>
							<span className="month-time">월 173.8시간</span>
						</div>
						<p className="total-time">주휴일 포함시 주 48시간, 월 208.6시간</p>
					</div>
				</div>
				<div className="c__card type-card">
					<span className="close-icon">
						<SolidCloseIcon />
					</span>
					<div className="content">
						<div className="type">자녀보육비</div>
						<div className="time-wrap">
							<span className="week-time">주 40시간</span>
							<span className="month-time">월 173.8시간</span>
						</div>
						<p className="total-time">주휴일 포함시 주 48시간, 월 208.6시간</p>
					</div>
				</div>
				<div className="c__card type-card">
					<span className="close-icon">
						<SolidCloseIcon />
					</span>
					<div className="content">
						<div className="type">연구보조비</div>
						<div className="time-wrap">
							<span className="week-time">주 40시간</span>
							<span className="month-time">월 173.8시간</span>
						</div>
						<p className="total-time">주휴일 포함시 주 48시간, 월 208.6시간</p>
					</div>
				</div>
				<div className="c__card type-card">
					<span className="close-icon">
						<SolidCloseIcon />
					</span>
					<div className="content">
						<div className="type">주휴수당</div>
						<div className="time-wrap">
							<span className="week-time">주 40시간</span>
							<span className="month-time">월 173.8시간</span>
						</div>
						<p className="total-time">주휴일 포함시 주 48시간, 월 208.6시간</p>
					</div>
				</div>
				<div className="c__card type-card">
					<span className="close-icon">
						<SolidCloseIcon />
					</span>
					<div className="content">
						<div className="type">초과근무 수당</div>
						<div className="time-wrap">
							<span className="week-time">주 40시간</span>
							<span className="month-time">월 173.8시간</span>
						</div>
						<p className="total-time">주휴일 포함시 주 48시간, 월 208.6시간</p>
					</div>
				</div>
				<div className="c__card type-card">
					<span className="close-icon">
						<SolidCloseIcon />
					</span>
					<div className="content">
						<div className="type">연차 미사용 수당</div>
						<div className="time-wrap">
							<span className="week-time">주 40시간</span>
							<span className="month-time">월 173.8시간</span>
						</div>
						<p className="total-time">주휴일 포함시 주 48시간, 월 208.6시간</p>
					</div>
				</div>
			</div>
			<div className="c__cardRow card-wrap bonus-card-wrap">
				<h4 className="type-title">보너스 항목</h4>
				<div className="c__card type-card">
					<span className="close-icon">
						<SolidCloseIcon />
					</span>
					<div className="content">
						<div className="type">
							P.I <i>(Productivity Incentive)</i>
						</div>
						<div className="time-wrap">
							<span className="week-time">주 40시간</span>
							<span className="month-time">월 173.8시간</span>
						</div>
						<p className="total-time">주휴일 포함시 주 48시간, 월 208.6시간</p>
					</div>
				</div>
				<div className="c__card type-card">
					<span className="close-icon">
						<SolidCloseIcon />
					</span>
					<div className="content">
						<div className="type">
							P.S <i>(Profit Sharing)</i>
						</div>
						<div className="time-wrap">
							<span className="week-time">주 40시간</span>
							<span className="month-time">월 173.8시간</span>
						</div>
						<p className="total-time">주휴일 포함시 주 48시간, 월 208.6시간</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default AllowanceView;
