import React from "react";

import "~/assets/sass/pages/ir/finance.scss";
import NumberInput from "~/common/components/NumberInput";
import { thousandFormatter } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const IncomeStatus = ({ useForm: { control, watch } }) => {
	const { t } = useTranslation();
	const GrossProfit = watch("Finance.sales") - watch("Finance.cost");

	const OperatingProfit = GrossProfit - watch("Finance.sga");

	const OrdinaryProfit =
		OperatingProfit + watch("Finance.otherIncome") - watch("Finance.nonOperatingExpenses");
	const NetIncome = OrdinaryProfit - watch("Finance.tax");

	return (
		<section>
			<div className="c__form vertical">
				<div className="unit-wrap sub-section sub-title">
					{t("IR.IrDetail.Finance.Edit.IncomeStatus.title")}
					<span className="unit">
						{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
						{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
					</span>
				</div>
				<div className="f__group income-status-table">
					<ul className="f__control">
						<li>
							<div className="title">
								{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title1")}
							</div>
							<div className="num">
								<NumberInput
									placeholder="0"
									control={control}
									name="Finance.sales"
								/>
							</div>
						</li>
						<li>
							<div className="title">
								{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title2")}
							</div>
							<div className="num">
								<NumberInput
									placeholder="0"
									control={control}
									name="Finance.cost"
								/>
							</div>
						</li>
						<li>
							<div className="title">
								{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title3")}
							</div>
							<div className="num">
								<input
									className="c__input"
									value={thousandFormatter(GrossProfit)}
									readOnly
								/>
							</div>
						</li>
						<li>
							<div className="title">
								{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title4")}
							</div>
							<div className="num">
								<NumberInput placeholder="0" control={control} name="Finance.sga" />
							</div>
						</li>
						<li>
							<div className="title">
								{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title5")}
							</div>
							<div className="num">
								<input
									className="c__input"
									value={thousandFormatter(OperatingProfit)}
									readOnly
								/>
							</div>
						</li>
						<li>
							<div className="title">
								{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title6")}
							</div>
							<div className="num">
								<NumberInput
									placeholder="0"
									control={control}
									name="Finance.otherIncome"
								/>
							</div>
						</li>
						<li>
							<div className="title">
								{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title7")}
							</div>
							<div className="num">
								<NumberInput
									placeholder="0"
									control={control}
									name="Finance.nonOperatingExpenses"
								/>
							</div>
						</li>
						<li>
							<div className="title">
								{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title8")}
							</div>
							<div className="num">
								<input
									className="c__input"
									value={thousandFormatter(OrdinaryProfit)}
									readOnly
								/>
							</div>
						</li>
						<li>
							<div className="title">
								{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title9")}
							</div>
							<div className="num">
								<NumberInput placeholder="0" control={control} name="Finance.tax" />
							</div>
						</li>
						<li>
							<div className="title">
								{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title10")}
							</div>
							<div className="num">
								<input
									className="c__input"
									value={thousandFormatter(NetIncome)}
									readOnly
								/>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default IncomeStatus;
