import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import List from "~/pages/vacation/VacationListPage/Expected/List";
import Modal from "~/pages/vacation/VacationListPage/Expected/Modal/Modal";
import { Case, If } from "~/common/components/If";
import { PAGE_STATE } from "~/common/constants/state";
import Empty from "~/pages/vacation/VacationListPage/Empty";
import TabMenu from "~/common/components/TabMenu";
import Pagination from "~/common/components/Pagination";
import { VACATION_STATUS, VACATION_STATUS_TEXT } from "~/common/constants/form";
import { AirplaneIcon, HourglassIcon } from "~/assets/svg/InterfacesIcon";
import { useTranslation } from "react-i18next";

const Expected = () => {
	const {
		vacationTabList,
		info,
		vacationList,
		pageNumber,
		pagingObject,
		upcomingState,
		setPageNumber,
		hrUpcomingVacationRequest,
		vacationStatusRequest,
		handleTab,
		titleText,
		timeText,
		vacationCount,
	} = useOutletContext();
	const { t } = useTranslation();
	const [vacationManageModal, setVacationManageModal] = useState(false);
	const [selectedVacation, setSelectedVacation] = useState({});

	useEffect(() => {
		if (!info.idx) return;
		hrUpcomingVacationRequest.call(
			{ page: pageNumber, size: 10, userIdx: info.idx },
			"queryString"
		);
	}, [pageNumber, info.idx]);

	return (
		<>
			<TabMenu tabItem={vacationTabList} currentTab={"expected"} setCurrentTab={handleTab} />
			<div className="c__card">
				<div className="vacation-list-wrap">
					<div className="vacation-chk-header">
						<div className="vacation-header">
							<h3 className="title">
								{t("HR.Manage.vacationList.scheduleVacation")}
							</h3>
							{upcomingState === PAGE_STATE.VIEW && (
								<span className="total-tag">
									{`${t("HR.Manage.vacationList.Used.total-tag")} ${
										vacationList.upcomingVacation.totalTime ??
										`0${t("HR.Manage.day")}`
									}`}
								</span>
							)}
						</div>
						<div className="vacation-info">
							<div className="sum-wrap">
								<div className="sum-info">
									<AirplaneIcon />
									{t("HR.Manage.totalAnnual")}:
									<span>{vacationCount?.totalVacationTime}</span>
								</div>
								<div className="sum-info">
									<HourglassIcon />
									{t("HR.Manage.remainAnnual")}:
									<span
										className={
											vacationCount?.remainingVacationTime?.charAt(0) === "-"
												? "withdraw"
												: ""
										}
									>
										{vacationCount?.remainingVacationTime}
									</span>
								</div>
							</div>
						</div>
					</div>
					<If condition={upcomingState}>
						<Case condition={PAGE_STATE.VIEW}>
							<div className="vacation-chk-list">
								<List
									{...{
										vacationList,
										setSelectedVacation,
										setVacationManageModal,
										titleText,
										timeText,
									}}
								/>
							</div>
							<Pagination
								currentPage={pageNumber}
								totalPage={pagingObject.upcomingVacation.totalPage}
								setPageNumber={setPageNumber}
							/>
						</Case>
						<Case condition={PAGE_STATE.EMPTY}>
							<Empty currentTab={"expected"} info={info} />
						</Case>
					</If>
				</div>
			</div>
			{Object.keys(selectedVacation).length > 0 && (
				<ModalTemplate
					modalState={vacationManageModal}
					setModalToggle={setVacationManageModal}
					title={
						<>
							{titleText(selectedVacation)}
							{selectedVacation.HrVacation.HrVacationType.autoConfirm ? (
								<span className="permission auto-approval">
									{t("HR.Manage.vacationList.auto-approval")}
								</span>
							) : (
								<span
									className={`permission ${
										VACATION_STATUS[selectedVacation.status]
									}`}
								>
									{VACATION_STATUS_TEXT[VACATION_STATUS[selectedVacation.status]]}
								</span>
							)}
						</>
					}
					width={460}
					className="vacation-manage-modal"
					closeable={false}
				>
					<Modal
						{...{
							selectedVacation,
							setVacationManageModal,
							timeText,
							hrUpcomingVacationRequest,
							vacationStatusRequest,
							info,
							pageNumber,
						}}
					/>
				</ModalTemplate>
			)}
			{/*휴가 삭제 버튼 클릭시 AlertModal*/}
		</>
	);
};
export default Expected;
