import {
	map,
	walk,
	removeNodeAtPath as remove,
	changeNodeAtPath as change,
	addNodeUnderParent as add,
} from "react-sortable-tree";

/**
 * Expanded 속성 추가
 *
 * 쿼리 데이터를 받았을때 렌더러가 작동할 수 있도록 expanded:true를 각 노드에 추가해줘야함
 * @param {Object} treeData 트리 데이터
 * @returns
 */
export const updateAll = (treeData, callBack) =>
	map({
		treeData,
		callback: ({ node }) => ({ ...node, ...callBack(node) }),
		getNodeKey: ({ treeIndex }) => treeIndex,
		ignoreCollapsed: false,
	});

/**
 * Expanded 속성 제거
 *
 * 데이터를 올릴때는 expanded를 삭제하고 올려야함
 * @param {v} treeData 트리 데이터
 * @returns
 */
export const removeExpanded = (treeData) =>
	map({
		treeData,
		callback: ({ node }) => ({ ...node, expanded: true }),
		getNodeKey: ({ treeIndex }) => treeIndex,
		ignoreCollapsed: false,
	});

/**
 * 노드 추가
 * @param {Array} treeData 트리 데이터
 * @param {Object} property 새로운 노드 프로퍼티
 * @returns
 */
export const addNode = (treeData, property, isInit) =>
	add({
		treeData,
		newNode: {
			id: "",
			children: [],
			expanded: true,
			...property,
		},
		parentKey: isInit !== true ? 0 : undefined,
		getNodeKey: ({ treeIndex }) => treeIndex,
	}).treeData;

/**
 * 노드 업데이트
 * @param {Array} treeData 트리 데이터
 * @param {Array} path 노드 경로
 * @param {Object} newNode 새로운 노드
 * @returns
 */
export const updateNode = (treeData, path, newNode) =>
	change({
		treeData,
		path,
		newNode,
		getNodeKey: ({ treeIndex }) => treeIndex,
		ignoreCollapsed: false,
	});

/**
 * 노드 제거
 * @param {Object} treeData 트리 데이터
 * @param {Array} path 노드 경로
 * @returns
 */
export const removeNode = (treeData, path) =>
	remove({
		treeData,
		path,
		getNodeKey: ({ treeIndex }) => treeIndex,
		ignoreCollapsed: false,
	});

/**
 * 자식노드 검색
 * @param {Object} node 트리 노드
 * @param {string} property property
 * @returns
 */
export const getChildren = (node, property) => {
	let list = [];
	walk({
		treeData: [node],
		callback: ({ node }) => (property ? list.push(node[property]) : list.push(node)),
		getNodeKey: ({ treeIndex }) => treeIndex,
		ignoreCollapsed: false,
	});
	return list.filter((e) => e);
};

/**
 * OrgChart를 위한 flat data provider
 *
 * @param {Object} treeData 트리 데이터
 * @returns
 */
export const getFlatChart = (treeData) => {
	let groupList = [];
	walk({
		treeData,
		callback: ({ node, parentNode }) => {
			const parentHasChild = parentNode?.count === 0;
			groupList.push({
				id: node.id,
				name: node.title,
				[parentHasChild ? "stpid" : "pid"]: parentNode?.id ? parentNode?.id : "company",
				tags: [
					node.title,
					"group",
					parentHasChild && "nested-group",
					node.count !== 0 && "hasChildren",
				],
				// hasChildren: node.count !== 0,
			});
		},
		getNodeKey: ({ treeIndex }) => treeIndex,
		ignoreCollapsed: false,
	});
	return groupList;
};
