import React from "react";
import { useForm } from "react-hook-form";

import "~/assets/sass/pages/ir/history.scss";
import { useGlobalStore } from "~/store";
import { dateToString } from "~/utils/formatter";
import DynamicFields from "./DynamicFields";
import useRequest from "~/common/hooks/useRequest";
import useRepository from "~/common/hooks/useRepository";
import { USER_INFO } from "~/store/user/repository";
import useAlert from "~/common/hooks/useAlert";
import { useTranslation } from "react-i18next";

const Edit = ({ showViewPage, Header }) => {
	const { t } = useTranslation();
	const [
		{
			ir: { selectedIdx: irIdx, history: History },
		},
	] = useGlobalStore();
	const [getUserInfo] = useRepository(USER_INFO);

	const historyForm = useForm({ defaultValues: { irIdx, History } });

	const irMutation = useRequest("post", "ir/history/edit");

	const submit = async (data) => {
		irMutation
			.asyncCall(data)
			.then(() => {
				getUserInfo().then(showViewPage);
			})
			.catch((msg) => console.log(msg));
	};

	const [showCancelAlert, CancelAlert] = useAlert();

	const buttonList = [
		<button
			type="button"
			className="btn__solid"
			color="gray"
			onClick={() => {
				showCancelAlert().then(() => showViewPage());
			}}
		>
			{t("button.cancel")}
		</button>,
		<button
			type="button"
			className="btn__solid"
			color="primary"
			onClick={historyForm.handleSubmit(submit)}
		>
			{t("button.save")}
		</button>,
	];

	const watchingHistory = historyForm.watch("History");

	const historyList = () => {
		const reducedHistory = watchingHistory.reduce((acc, { date, content }) => {
			const year = dateToString(date, "yyyy");
			const current = acc[year] || [];
			acc[year] = [...current, { date, content }];
			return acc;
		}, {});

		return Object.keys(reducedHistory)
			.reverse()
			.map((year) => (
				<div key={year}>
					<h3 className="year">{year}</h3>
					<ol>
						{reducedHistory[year].map(({ date, content }, idx) => (
							<li key={idx} className="form-data-group">
								<span className="history-date">
									{dateToString(date, "yyyy-MM")}
								</span>
								<ul className="history-content">
									<li>{content}</li>
								</ul>
							</li>
						))}
					</ol>
				</div>
			));
	};

	return (
		<>
			<Header buttonList={buttonList} />
			<div className="ir-view">
				<div className="c__card history-edit">
					<div className="title">{t("IR.IrDetail.History.Edit.Index.title1")}</div>
					<div className="history-register-wrap">
						<DynamicFields name="History" useForm={historyForm} />
						<section className="yearly-history">{historyList()}</section>
					</div>
				</div>
			</div>
			<CancelAlert
				message={t("IR.IrDetail.History.Edit.Index.msg")}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
		</>
	);
};

export default Edit;
