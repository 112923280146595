import React from "react";

// Components
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";

// Assets
import "~/assets/sass/pages/invest/setting.scss";

const SettingPage = () => {
	return (
		<NavbarAndSidebarLayout menu={["invest", "setting"]} type="main">
			{/*<PageHeader/>*/}
			<div className="l__pageHeader">
				<h2 className="page-name">설정</h2>
				<ol className="c__breadcrumb">
					<li>Home</li>
					<li>투자</li>
					<li>설정</li>
				</ol>
				<div className="divider" />
				<div className="pageHeader-btn-group">
					<button type="button" className="btn__solid btn-edit" color="primary">
						저장하기
					</button>
				</div>
			</div>
			<div className="c__card">
				<section className="set-invest-offer">
					<div className="section-title">투자제의</div>
					<div className="section-content">
						<div className="checkbox-btn-group">
							<label className={`checkbox-custom checked`}>
								<input type="checkbox" checked />
								<span className="checkmark" />
								<span className="checkbox-label">VC / 전문투자사</span>
							</label>
							<label className={`checkbox-custom checked`}>
								<input type="checkbox" checked />
								<span className="checkmark" />
								<span className="checkbox-label">개인투자자</span>
							</label>
						</div>
						<p className="certified-phrase">
							※ 전문 투자사와 개인투자사의 투자제의를 설정합니다.
						</p>
					</div>
				</section>
				<section className="set-invest-event">
					<div className="section-title">투자이벤트</div>
					<div className="section-content">
						<div className="checkbox-btn-group">
							<label className={`checkbox-custom`}>
								<input type="checkbox" />
								<span className="checkmark" />
								<span className="checkbox-label">VC / 전문투자사</span>
							</label>
							<label className={`checkbox-custom`}>
								<input type="checkbox" />
								<span className="checkmark" />
								<span className="checkbox-label">정부/기관/기업</span>
							</label>
						</div>
						<p className="certified-phrase">
							※ 전문 투자사와 정부 / 기관 / 기업의 투자이벤트를 설정합니다.
						</p>
					</div>
				</section>
			</div>
		</NavbarAndSidebarLayout>
	);
};

export default SettingPage;
