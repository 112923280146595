import React from "react";
import { dateToString, thousandFormatter } from "~/utils/formatter";
import { useNavigate } from "react-router-dom";
import NoData from "~/common/components/NoData";

const List = ({ historyList }) => {
	const navigate = useNavigate();

	return (
		<div className="tbody">
			{historyList.length > 0 ? (
				historyList.map(
					(history, index) =>
						!history.promotion && (
							<div
								className="tr"
								key={index}
								onClick={() =>
									navigate(`/myPage/admin/payment/detail/${history.idx}`)
								}
							>
								<div className="td title">{`${dateToString(
									history.createdDate,
									"yyyy"
								)}년 ${dateToString(
									history.createdDate,
									"MM"
								)}월 정기결제(월간)`}</div>
								<div className="td sum">
									{thousandFormatter(history.price * history.members)}원
								</div>
								<div
									className={`td ${history.purchaseResult ? "complete" : "fail"}`}
								>
									{history.purchaseResult ? "결제 성공" : "결제 실패"}
								</div>
								<div className="td date">
									{dateToString(history.createdDate, "yyyy.MM.dd")}
								</div>
							</div>
						)
				)
			) : (
				<NoData main={"결제 이력이 없습니다."} />
			)}
		</div>
	);
};
export default List;
