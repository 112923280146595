import React, { useState } from "react";
import "~/assets/sass/pages/hr/modal/common/edit/profile.scss";
import Form from "~/common/components/Form";
import SingleImageUploader from "~/common/components/FormImageUploader/SingleImageUploader";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import { useTranslation } from "react-i18next";

const ProfileEdit = ({ useForm }) => {
	const { t } = useTranslation();
	const { register, getValues } = useForm;
	const [name] = useState(getValues("UserProfile.name"));
	const [position] = useState(getValues("UserProfile.position"));

	return (
		<div className="teamInfo-profile edit">
			<Form useForm={useForm}>
				<SingleImageUploader
					name="UserProfile.img"
					type={UPLOAD_TYPE.USER_PROFILE}
					height="100px"
					width="100px"
					className={"profile-img"}
					round
				/>
			</Form>
			<div className="name">
				{name}
				<span className="position">{position}</span>
			</div>
			<textarea
				className="c__textarea"
				placeholder={t(
					"HR.TeamInfo.modal.MemberDetailModal.components.Profile.textareaPlaceholder"
				)}
				{...register("UserProfile.introduction")}
			/>
		</div>
	);
};

export default ProfileEdit;
