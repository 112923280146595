import React, { Fragment } from "react";

export const ShowIcon = () => {
	return (
		<Fragment>
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M16 12.9779C14.3626 12.9779 13.0221 14.3175 13.0221 15.9557C13.0221 17.594 14.3617 18.9336 16 18.9336C17.6383 18.9336 18.9779 17.594 18.9779 15.9557C18.9779 14.3175 17.6383 12.9779 16 12.9779ZM16 14.7145C15.3052 14.7145 14.7597 15.26 14.7597 15.9548H14.2634C14.2634 14.9868 15.0329 14.2182 16 14.2182V14.7145Z"
					fill="black"
				/>
				<path
					d="M16 10C11.9557 10 8.18377 12.1838 6.14844 15.7071L6 15.9548L6.14844 16.2025C8.18286 19.7259 11.9548 21.9097 16 21.9097C20.0452 21.9097 23.8162 19.7259 25.8516 16.2025L26 15.9548L25.8516 15.7071C23.8171 12.1838 20.0452 10 16 10ZM7.14106 15.9557C8.45606 13.7966 10.516 12.2339 12.8737 11.4889C11.4594 12.4816 10.5415 14.0943 10.5415 15.9557C10.5415 17.8171 11.4594 19.4299 12.8737 20.4225C10.516 19.6785 8.48156 18.1149 7.14106 15.9557ZM11.5332 15.9557C11.5332 13.4988 13.543 11.4889 16 11.4889C18.457 11.4889 20.4668 13.4988 20.4668 15.9557C20.4668 18.4127 18.457 20.4225 16 20.4225C13.543 20.4225 11.5332 18.4127 11.5332 15.9557ZM19.1263 20.4225C20.5406 19.4299 21.4585 17.7926 21.4585 15.9557C21.4585 14.1189 20.5406 12.4816 19.1263 11.4889C21.484 12.2329 23.5184 13.7966 24.8589 15.9557C23.5194 18.1149 21.484 19.6776 19.1263 20.4225Z"
					fill="black"
				/>
			</svg>
		</Fragment>
	);
};

export const HideIcon = () => {
	return (
		<Fragment>
			<svg
				width="32"
				height="32"
				viewBox="0 0 32 32"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M15.9995 18.77C17.637 18.77 18.9776 17.4304 18.9776 15.792C18.9776 15.5443 18.953 15.2957 18.8783 15.0479L15.2555 18.6707C15.5032 18.7454 15.7518 18.77 15.9995 18.77Z"
					fill="black"
				/>
				<path
					d="M25.8516 15.5443C24.9582 13.9806 23.6923 12.6655 22.2288 11.6975L21.5093 12.4169C22.849 13.2602 23.991 14.4023 24.8598 15.792C23.5447 17.9513 21.4847 19.514 19.1269 20.259C20.5412 19.2663 21.4592 17.6289 21.4592 15.792C21.4592 14.8494 21.2115 13.9305 20.7889 13.1619L20.0449 13.9059C20.3181 14.4769 20.4666 15.1217 20.4666 15.792C20.4666 18.2491 18.4566 20.259 15.9995 20.259C15.3293 20.259 14.6845 20.1106 14.1135 19.8373L12.6991 21.2517C13.7665 21.5741 14.8584 21.748 15.9995 21.748C20.0449 21.748 23.8161 19.5641 25.8516 16.0406L26 15.7929L25.8516 15.5452V15.5443Z"
					fill="black"
				/>
				<path
					d="M8.20755 22.8891L8.90242 23.584L23.7925 8.69487L23.0976 8L20.3682 10.7294C19.0031 10.1338 17.514 9.83598 16.0005 9.83598C11.9551 9.83598 8.18387 12.0199 6.14844 15.5434L6 15.7911L6.14844 16.0388C7.21579 17.9249 8.82865 19.4139 10.6901 20.4065L8.20755 22.8891ZM15.9995 14.5507C15.3047 14.5507 14.7592 15.0962 14.7592 15.7911H14.2628C14.2628 14.823 15.0324 14.0544 15.9995 14.0544V14.5507ZM18.779 12.3177L17.7117 13.385C17.2399 13.0125 16.6443 12.814 15.9995 12.814C14.3621 12.814 13.0215 14.1536 13.0215 15.792C13.0215 16.4368 13.2201 17.0324 13.5671 17.5041L12.4997 18.5715C11.8795 17.8019 11.507 16.8348 11.507 15.792C11.507 13.3349 13.517 11.325 15.974 11.325C17.0414 11.325 18.0086 11.6975 18.7781 12.3177H18.779ZM12.8731 11.325C11.4588 12.3177 10.5408 13.9305 10.5408 15.792C10.5408 17.1071 11.0125 18.3483 11.8067 19.2909L11.4096 19.688C9.67288 18.8192 8.18387 17.5041 7.14111 15.792C8.48076 13.6327 10.5162 12.0699 12.874 11.325H12.8731Z"
					fill="black"
				/>
			</svg>
		</Fragment>
	);
};
