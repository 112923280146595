import React, { useState } from "react";
import TabMenu from "~/common/components/TabMenu";
import { Case, If } from "~/common/components/If";

const VacationExplanationModal = (props) => {
	const { setModalToggle } = props;
	const [currentTab, setCurrentTab] = useState("fiscal");
	const tabMenuList = [
		{ id: "fiscal", title: "회계년도 기준" },
		{ id: "join", title: "입사일 기준" },
	];

	return (
		<>
			<div className="modal-content">
				<TabMenu
					tabItem={tabMenuList}
					currentTab={currentTab}
					setCurrentTab={setCurrentTab}
				/>
				<If condition={currentTab}>
					<Case condition={"fiscal"}>
						<p className="desc">
							<b>회계년도 기준</b>은 통상 1월 1일 기준 모든 근로자의 연차 유급휴가가
							부여되며, 1년 미만 근로자의 경우 입사일을 기준으로 1개월마다 1일의
							유급휴가가 부여됩니다.
						</p>
						<div className="standard-wrap">
							1. 근로기간 입사 1년 미만
							<br />
							- 만 1년이 되기 전까지 한달에 1일의 연차휴가 부여
							<br />
							- 년도가 넘어가는 1월 1일에 전년도 근로에 대한 연차 휴가 부여
							<br />
							→ 15일 * 전년도 재직 일수 / 365 (소수점일 경우 올림하여 반영)
							<br />
							- 입사 1년미만자는 부여받은 연차 유급휴가를 입사 만 1년 내에 사용 가능
							<br />
							<br />
							2. 근로기간 입사 1년 ~ 3년
							<br />
							- 매년 회계일자에 15일의 연차 유급휴가 부여
							<br />
							<br />
							3. 근로기간 입사 3년 이상
							<br />
							- 매년 회계일자에 15일의 연차 유급휴가 부여
							<br />
							- 최초 1년을 초과하는 2년마다 1일을 가산한 유급휴가 추가 부여 (최대
							25일)
							<br />
							ex) 3년차는 16일 5년차는 17일
						</div>
					</Case>
					<Case condition={"join"}>
						<p className="desc">
							<b>입사일 기준</b>은 근로자 개인의 입사일을 기준으로 연차 유급휴가가
							부여됩니다.
						</p>
						<div className="standard-wrap">
							1. 근로기간 입사 1년 미만
							<br />
							- 만 1년이 되기 전까지 한달에 1일의 연차휴가 부여
							<br />
							- 입사 1년 미만자는 부여받은 연차 유급 휴가를 입사 만 1년 내에 사용 가능
							<br />
							<br />
							2. 근로기간 입사 1년~3년
							<br />
							- 근로자의 입사일 기준 15일의 연차 유급휴가 부여
							<br />
							<br />
							3. 근로기간 입사 3년 이상
							<br />
							- 근로자의 입사일 기준 15일의 연차 유급휴가 부여
							<br />
							- 최초 1년을 초과하는 2년마다 1일을 가산한 유급휴가 추가 부여 (최대
							25일)
							<br />
							ex) 3년차는 16일 5년차는 17일
						</div>
					</Case>
				</If>
			</div>
			<div className="modal-footer">
				<button
					className="btn__solid"
					color="primary"
					onClick={() => setModalToggle(false)}
				>
					확인
				</button>
			</div>
		</>
	);
};
export default VacationExplanationModal;
