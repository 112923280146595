import React, { useEffect, useState } from "react";

import "~/assets/sass/pages/shareholder/view.scss";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import { useNavigate, useParams } from "react-router";
import useRequest from "~/common/hooks/useRequest";
import { Case, If } from "~/common/components/If";
import View from "./View";
import { COMPANY_INFO } from "~/store/company/repository";
import useRepository from "~/common/hooks/useRepository";
import LoadingPage from "~/pages/LoadingPage";
import Edit from "./Edit";
import { useGlobalStore } from "~/store";
import { useTranslation } from "react-i18next";

const ShareholderDetail = () => {
	const { t } = useTranslation();
	const { idx } = useParams();
	const navigate = useNavigate();

	const [getCompanyInfo] = useRepository(COMPANY_INFO);
	const shareholderDetailRequest = useRequest("get", `shareholder/detail`);

	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [shareholderDetail, setShareholderDetail] = useState({});

	const [
		{
			shareholder: { selectedIdx },
		},
	] = useGlobalStore();

	useEffect(() => {
		if (selectedIdx === null) {
			alert(t("Shareholder.ShareholderDetail.Index.alert.badApproach"));
			navigate("/shareholder/list");
		} else {
			shareholderDetailRequest.singleCall(idx, "path");
			getCompanyInfo();
		}
	}, []);

	useEffect(() => {
		switch (shareholderDetailRequest.state) {
			case API_STATE.done:
				const response = shareholderDetailRequest.response.data;
				setShareholderDetail(response);
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				alert(t("Shareholder.ShareholderDetail.Index.alert.notExist"));
				navigate("/shareholder/list");
			default:
				break;
		}
	}, [shareholderDetailRequest.state]);

	const shareholderUpdateMutation = useRequest("post", "shareholder/edit");

	const onSubmit = async (data) => {
		await shareholderUpdateMutation.asyncCall(data);
		shareholderDetailRequest.call(idx, "path");
	};

	const onCancel = () => {
		setPageState(PAGE_STATE.VIEW);
	};

	return (
		<NavbarAndSidebarLayout menu={["shareholder", "list"]} type="main">
			<If condition={pageState} {...{ setPageState, shareholderDetail }}>
				<Case condition={PAGE_STATE.LOADING}>
					<LoadingPage />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<View />
				</Case>
				<Case condition={PAGE_STATE.EDIT}>
					<Edit {...{ onSubmit, onCancel }} />
				</Case>
			</If>
		</NavbarAndSidebarLayout>
	);
};

export default ShareholderDetail;
