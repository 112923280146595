import React, { useEffect, useState } from "react";
import { BUSINESS_PAGE_STATE } from "~/pages/mypage/admin/BusinessPage";
import Form, { Group } from "~/common/components/Form";
import Input from "~/common/components/Input";
import { API_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import AlertTemplate from "~/common/components/AlertPortal/AlertTemplate";
import { BIZ_TYPE } from "~/common/constants/form";
import Radio from "~/common/components/Radio";
import translation from "~/translations";
import { useTranslation } from "react-i18next";

//Assets
import "~/assets/sass/pages/mypage/admin/business/bizNumForm.scss";

const BizNumForm = ({ user, setPage, changeBizForm }) => {
	const { t } = useTranslation();
	const [bizNum, setBizNum] = useState("");
	const [checkBizNum, setCheckBizNum] = useState(false);
	const [validateModal, setValidateModal] = useState(false);
	const [validateModalType, setValidateModalType] = useState("");
	const [validateModalMsg, setValidateModalMsg] = useState("");
	const checkBizNumDuplicateRequest = useRequest("get", `user/check/biznum?biznum=${bizNum}`);

	//사업자등록번호 validation
	const handleBizNumCheck = (regNum) => {
		let sum = 0;
		const key = [1, 3, 7, 1, 3, 7, 1, 3, 5];

		for (let i = 0; i < 9; i++) {
			sum += key[i] * regNum[i];
		}

		const numCheck = 10 - ((sum + Math.floor((regNum[8] * key[8]) / 10)) % 10);

		const bizFirst = regNum.slice(0, 3);
		const bizSecond = regNum.slice(3, 5);
		const bizThird = regNum.slice(5, 9);

		if (regNum.match(/[^0-9]/g) || regNum.length !== 10) {
			//숫자 외 형식 및 자리수 체크
			changeBizForm.setError("regNum", {
				type: "checkNum",
				message: translation.t("MyPage.admin.Business.BizNumForm.checkNum"),
			});
		} else if (
			//사업자등록번호가 맞는지 체크
			numCheck % 10 === Number(regNum[9]) &&
			101 <= bizFirst &&
			bizFirst <= 999 &&
			Number(bizSecond) !== 0 &&
			Number(bizThird) !== 0
		) {
			changeBizForm.clearErrors("regNum");
			setBizNum(regNum);
		} else {
			changeBizForm.setError("regNum", {
				type: "checkFormat",
				message: translation.t("MyPage.admin.Business.BizNumForm.checkFormat"),
			});
		}
	};

	//사업자등록번호 중복체크 Request
	useEffect(() => {
		if (bizNum !== "") {
			checkBizNumDuplicateRequest.call();
		}
	}, [bizNum]);

	//사업자등록번호 중복체크 Response
	useEffect(() => {
		switch (checkBizNumDuplicateRequest.state) {
			case API_STATE.done:
				setValidateModalType("success");
				setValidateModal(true);
				setValidateModalMsg(t("MyPage.admin.Business.BizNumForm.success-check"));
				setCheckBizNum(true);
				break;
			case API_STATE.error:
				switch (checkBizNumDuplicateRequest.error.statusCode) {
					case 409:
						setValidateModalType("error");
						setValidateModal(true);
						setValidateModalMsg(t("MyPage.admin.Business.BizNumForm.error-check"));
						break;
					case 500:
						alert(t("Alert.alert1"));
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	}, [checkBizNumDuplicateRequest.state, checkBizNumDuplicateRequest.error]);

	const handleClick = () => {
		if (checkBizNum) {
			setPage(BUSINESS_PAGE_STATE.UNCERTIFIED);
		} else {
			setValidateModalType("warning");
			setValidateModal(true);
			setValidateModalMsg(t("MyPage.admin.Business.BizNumForm.warning-check"));
		}
	};

	return (
		<>
			<div className="c__card bizNumForm">
				<div className="card-top">
					<dl className="content">
						<dt>{t("MyPage.admin.Business.BizNumForm.content.dt")}</dt>
						<dd>{t("MyPage.admin.Business.BizNumForm.content.dd")}</dd>
					</dl>
					<Form useForm={changeBizForm}>
						<Radio
							name="type"
							defaultValue={BIZ_TYPE.PERSONAL}
							options={[
								{
									label: translation.t(
										"Auth.Join.Form.auth-form.cofound-number.radio.options.0"
									),
									value: BIZ_TYPE.PERSONAL,
								},
								{
									label: translation.t(
										"Auth.Join.Form.auth-form.cofound-number.radio.options.1"
									),
									value: BIZ_TYPE.CORPORATION,
								},
							]}
						/>
						<Group>
							<Input
								placeholder={t(
									"MyPage.admin.Business.BizNumForm.regNum-placeholder"
								)}
								name="regNum"
								autoComplete="new-password"
								required
							/>
							<button
								className="btn__solid"
								color={"primary"}
								onClick={() => handleBizNumCheck(changeBizForm.watch("regNum"))}
							>
								{t("button.duplicate")}
							</button>
						</Group>
					</Form>
				</div>
				<div className="card-bottom">
					{(user.company.certType === BIZ_TYPE.PROSPECTIVE ||
						user.company.certType === null) && (
						<button
							className="btn__solid"
							color="gray"
							onClick={() => setPage(BUSINESS_PAGE_STATE.PROSPECTIVE)}
						>
							{t("button.cancel")}
						</button>
					)}
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={handleClick}
					>
						{t("button.next")}
					</button>
				</div>
			</div>
			<AlertTemplate
				alertModalState={validateModal}
				setAlertModalToggle={setValidateModal}
				alertType={validateModalType}
			>
				<p className="alert-content">{validateModalMsg}</p>
				<div className="alert-footer">
					<button
						className="btn__solid"
						color={"primary"}
						onClick={() => {
							setValidateModal(false);
							setBizNum("");
						}}
					>
						{t("button.check")}
					</button>
				</div>
			</AlertTemplate>
		</>
	);
};
export default BizNumForm;
