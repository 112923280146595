import React, {useEffect, useState} from "react";
import PageHeader from "~/common/components/PageHeader";
import { useTranslation } from "react-i18next";

// Assets
import "~/assets/sass/pages/company/info/view.scss";
import RectangleInitialImg from "~/assets/img/defaultImg/logo-rac.png";
import { useGlobalStore } from "~/store";
import {
	dateToString,
	phoneNumberFormatter,
	thousandFormatter,
	companyNumberFormatter,
} from "~/utils/formatter";
import { COUNTRY_TYPE, LISTED_TYPE } from "~/common/constants/form";
import useRepository from "~/common/hooks/useRepository";
import { COMPANY_INFO } from "~/store/company/repository";
import AuthButton from "~/common/components/AuthButton";
import Print from "~/common/components/Print";

import noImg from "~/assets/img/defaultImg/default-img.png";
import useRequest from "~/common/hooks/useRequest";

const View = ({ PAGE_STATE, setPage, menu, type, certTypeSelect, companyInfoData }) => {
	const { t } = useTranslation();


	const buttonList = [
		<Print />,
		<AuthButton
			type="button"
			className="btn__solid btn-edit"
			color="primary"
			onClick={() => setPage(PAGE_STATE.EDIT)}
		>
			{t("button.Edit")}
		</AuthButton>,
	];
	return (
		<>
			<PageHeader {...{ menu, type, buttonList }} />
			<div className="c__card company-info-view">

				<div className="head-wrap">
					<div className="top-wrap">
						<div className="date">
							Updated : {companyInfoData?.company?.updateDate && dateToString(companyInfoData?.company?.updateDate, "yyyy. MM. dd.")}
						</div>
						{companyInfoData?.company?.viewMode && (
							<div className="tag-open">{t("Company.Info.View.tag-open")}</div>
						)}
					</div>
					<h2 className="company-name">{companyInfoData?.company?.name}</h2>
					<div className="company-logo">
						<div className="logo-wrap after">
							<img
								src={companyInfoData?.company?.logoRec || RectangleInitialImg}
								alt={t("Company.Info.logo")}
								onError={(e) => {
									e.target.src = RectangleInitialImg;
								}}
							/>
						</div>
					</div>
				</div>
				<div className="data-form">
					<section className="input-group-section">
						<h3 className="section-title">{t("Company.Info.basicInfo")}</h3>
						<div className="c__form horiz data-view">
							<div className="f__group">
								<p className="f__label registration-num">
									{t("Company.Info.registration-num")}
								</p>
								<p className="f__control">{companyNumberFormatter(companyInfoData?.company?.regNum)}</p>
							</div>
							<div className="f__group">
								<p className="f__label">{t("Company.Info.companyName")}</p>
								<p className="f__control">{companyInfoData?.company?.name}</p>
							</div>
							<div className="f__group">
								<p className="f__label">{t("Company.Info.ceo")}</p>
								<p className="f__control">{companyInfoData?.company?.ceoName}</p>
							</div>
							<div className="f__group">
								<p className="f__label">
									{t("Company.Info.sales")}
									<span>{t("Company.unit")}</span>
								</p>
								<p className="f__control">
									{thousandFormatter(companyInfoData?.company?.sales)}
								</p>
							</div>
							<div className="f__group">
								<p className="f__label">{t("Company.Info.bizType")}</p>
								<p className="f__control">{companyInfoData?.company?.bizType}</p>
							</div>
							<div className="f__group">
								<p className="f__label">{t("Company.Info.bizStatus")}</p>
								<p className="f__control">{companyInfoData?.company?.bizStatus}</p>
							</div>
							<div className="f__group">
								<p className="f__label">{t("Company.Info.estDate")}</p>
								<p className="f__control">
									{companyInfoData?.company?.estDate && dateToString(companyInfoData?.company?.estDate, "yyyy. MM. dd.")}
								</p>
							</div>
							<div className="f__group">
								<p className="f__label">{t("Company.Info.employeeNum")}</p>
								<p className="f__control">
									{companyInfoData?.company?.employeeNum && thousandFormatter(companyInfoData?.company?.employeeNum)}
									{t("Company.Info.View.num")}
								</p>
							</div>
							<div className="f__group">
								<p className="f__label">{t("Company.Info.type")}</p>
								<p className="f__control">
									{
										certTypeSelect.filter(item => item.value === companyInfoData?.company?.type)[0]?.label
									}
								</p>
							</div>
							<div className="f__group">
								<p className="f__label">{t("Company.Info.country")}</p>
								<p className="f__control">{companyInfoData?.company?.country}</p>
								{/*<p className="f__control">{COUNTRY_TYPE[companyInfoData?.company?.country]}</p>*/}
							</div>
							<div className="f__group">
								<p className="f__label">{t("Company.Info.listed")}</p>
								<p className="f__control">{LISTED_TYPE[companyInfoData?.company?.listed]}</p>
							</div>
							<div className="f__group">
								<p className="f__label">{t("Company.Info.homepage")}</p>
								<p className="f__control">{companyInfoData?.company?.homepage}</p>
							</div>
							<div className="f__group">
								<p className="f__label">{t("Company.Info.ceoEmail")}</p>
								<p className="f__control">{companyInfoData?.company?.ceoEmail}</p>
							</div>
							<div className="f__group">
								<p className="f__label">{t("Company.Info.ceoContact")}</p>
								<p className="f__control">
									{phoneNumberFormatter(companyInfoData?.company?.ceoContact)}
								</p>
							</div>
							<div className="f__group c__addressGroup">
								<p className="f__label">{t("Company.Info.address")}</p>
								<p className="f__control">
									{companyInfoData?.company?.zipCode ? "(" + companyInfoData?.company?.zipCode + ")" : ""} {companyInfoData?.company?.address}{" "}
									{companyInfoData?.company?.addressDetail}
								</p>
							</div>
							<div className="f__group keyword-wrap c__tag-wrap">
								<p className="f__label">{t("Company.Info.field")}</p>
								<p className="f__control">
									{companyInfoData?.company?.CompanyField.map((f, f_idx) => (
										<span className="c__tag-view" key={f_idx}>
											{f.field}
										</span>
									))}
								</p>
							</div>
						</div>
					</section>
					{
						companyInfoData?.repProduct?.introduction &&
						<section className="company-desc">
							<h3 className="section-title">{t("Company.Info.introduction")}</h3>
							<div
								className="f__control"
								style={{ whiteSpace: "pre-wrap", lineBreak: "anywhere" }}
							>
								{companyInfoData?.repProduct?.introduction}
							</div>
						</section>
					}
					{
						companyInfoData?.repProduct &&
						<section className={"info-product"}>
							<h3 className="section-title">{t("Company.Info.View.product")}</h3>
							<div className="c__form horiz data-view">
								<div className="f__group">
									<p className="f__label">{t("Company.Info.View.productName")}</p>
									<p className="f__control">{companyInfoData?.repProduct?.name}</p>
								</div>
								<div className="f__group">
									<p className="f__label">{t("Company.Info.View.description")}</p>
									<p
										className="f__control"
										style={{ whiteSpace: "pre-wrap", lineBreak: "anywhere" }}
									>
										{companyInfoData?.repProduct?.description}
									</p>
								</div>
								<div className="f__group">
									<p className="f__label">
										{t("Company.Info.View.differentiation")}
									</p>
									<p
										className="f__control"
										style={{ whiteSpace: "pre-wrap", lineBreak: "anywhere" }}
									>
										{companyInfoData?.repProduct?.differentiation}
									</p>
								</div>
								<div className="f__group c__tag-wrap">
									<p className="f__label">{t("Company.Info.View.keyWord")}</p>
									<div className="f__control">
										{companyInfoData?.repProduct?.CompanyProductKeyword?.map(
											({ content, i }) => (
												<span
													key={`${i}_${content}`}
													className="c__tag-view"
												>
													{content}
												</span>
											)
										)}
									</div>
								</div>
							</div>
						</section>
					}


					<div className="data-form">
						{/*사업자인증 추가*/}
						<section className="private-setting">
							<h3 className="section-title">사업자인증</h3>
							<div className={"c__form horiz data-view"}>
								{/*미인증 start*/}
								{
									(companyInfoData?.certificate?.imgUrl) ?
										<div className={"certification-wrap certified"}>
											<div className="img_wrap">
												<div className="no-img">
													<img
														src={companyInfoData?.certificate?.imgUrl}
														onError={(e) => {
															e.target.src = noImg;
														}}
													/>
												</div>
											</div>
											<div className="result_wrap">
												<div className="status-wrap">
													<p className="status">인증됨</p>
													<p className="description">
														사업자 인증이 완료되었습니다. <br/>
														추후 개편되는 서비스 및 기능을 추가 인증 없이 사용 가능합니다.
													</p>
												</div>
												{
													(companyInfoData?.company?.regNum) &&
													<div className="biz-number">
														<span className={"label"}>
															사업자 등록번호:
														</span> {companyNumberFormatter(companyInfoData?.company?.regNum)}
													</div>
												}
											</div>
										</div> :
										<div className={"certification-wrap"}>
											<div className="img_wrap">
												<div className="no-img">
													<img
														src={noImg}
														onError={(e) => {
															e.target.src = noImg;
														}}
													/>
												</div>
											</div>
											<div className="result_wrap">
												<div className="status-wrap">
													<p className="status">미인증</p>
													<p className="description">
														사업자 인증을 완료해 주세요. <br/>
														사업자 인증을 완료하시면 개편 중인 서비스의 주요 기능을 편리하게 이용할 수 있습니다.
													</p>
												</div>
											</div>
										</div>
								}
							</div>
						</section>
						{/*인감 start*/}
						{
							companyInfoData?.signature &&
							<section className="private-setting">
								<div className="signature-wrap">
									<div className="signature-part">
										<span className="label">signature</span>
										<div className="img-wrap">
											<img src={companyInfoData?.signature?.img} title={`Company Signature`}/>
										</div>
									</div>
								</div>
							</section>
						}
						{/*인감 end*/}
					</div>
				</div>

			</div>

		</>
	);
};

export default View;
