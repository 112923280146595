import React, { useEffect, useState } from "react";
import useRepository from "~/common/hooks/useRepository";
import * as env from "~/common/constants/env";
import { PAGE_STATE } from "~/common/constants/state";
import { PERIOD_TYPE } from "~/common/constants/form";
import { thousandFormatter } from "~/utils/formatter";
import { Trans, useTranslation } from "react-i18next";

// Store
import { useGlobalStore } from "~/store";
import { OKCEO_SALES_DATA } from "~/store/tax/repository";

// Components
import { optionsGenerator } from "~/common/components/Select";
import { Case, If } from "~/common/components/If";
import CustomSelect, {
	optionGenerator2,
	optionGeneratorByObjectValues,
} from "~/common/components/CustomSelect";
import Loading from "~/common/components/Loading";
import { OkceoNoContent } from "~/pages/Tax/Dashboard/View";

// Chart
import ReactFusioncharts from "react-fusioncharts-fix";
import charts from "fusioncharts/fusioncharts.charts";
import FusionCharts from "fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import i18next from "i18next";

// Assets
// import { ChartIcon } from "~/assets/svg/InterfacesIcon";

charts(FusionCharts);
FusionTheme(FusionCharts);

const SalesCard = ({ okceoToken, selectedCompany, salesSelectedYear, setSalesSelectedYear }) => {
	const { t } = useTranslation();
	const [
		{
			tax: { sales },
		},
	] = useGlobalStore();
	const [cardState, setCardState] = useState(PAGE_STATE.LOADING);
	const [getSalesData] = useRepository(OKCEO_SALES_DATA);
	const [salesChartData, setSalesChartData] = useState([]);
	const PeriodType = PERIOD_TYPE();

	useEffect(() => {
		getSalesData({ selectedCompany: selectedCompany, selectedYear: salesSelectedYear });
	}, [salesSelectedYear]);

	useEffect(() => {
		if (sales) {
			const chartData = sales.customers.map((d) => ({
				label: d.name,
				value: d.rate,
			}));
			setSalesChartData(chartData);
			setCardState(PAGE_STATE.VIEW);
		} else {
			setCardState(PAGE_STATE.EMPTY);
		}
	}, [sales]);

	const CHART_CONFIG = (params, chartParams, annotationParams) => ({
		width: "100%",
		dataFormat: "JSON",
		dataSource: {
			chart: {
				baseFont: "Noto Sans KR",
				theme: "fusion",
				decimals: "1",
				valueFontSize: "1rem",
				legendItemFontSize: "1rem",
				...chartParams,
			},
			annotations: {
				...annotationParams,
			},
		},
		...params,
	});
	const ASSET_PIE_CHART_CONFIG = CHART_CONFIG({ type: "pie2d" });

	// console.log(PERIOD_TYPE);

	return (
		<div className="c__card sales-card">
			<div className="card-head">
				<h2 className="card-title">
					<Trans
						i18nKey="Tax.Dashboard.View.component.ChartCard.SalesCard.card-title"
						components={[<span />]}
					/>
				</h2>
				<div className="extra-condition">
					<CustomSelect
						value={salesSelectedYear}
						setValue={setSalesSelectedYear}
						options={optionGeneratorByObjectValues(PeriodType)}
					/>
					<button
						type="button"
						className="btn__outline angled"
						color="gray"
						onClick={() =>
							window.open(
								`${env.OKCEO_API_URL}/ssolink?token=${okceoToken}&cid=${selectedCompany}`
							)
						}
					>
						{t("Tax.Dashboard.View.component.ChartCard.SalesCard.btn-detail")}
					</button>
					{/*<button type="button" className="btn__outline btn-detail" color="gray">*/}
					{/*	<ChartIcon />*/}
					{/*</button>*/}
				</div>
			</div>
			<div className="card-content">
				<If condition={cardState}>
					<Case condition={PAGE_STATE.LOADING}>
						<Loading />
					</Case>
					<Case condition={PAGE_STATE.EMPTY}>
						<OkceoNoContent {...{ okceoToken, selectedCompany }} />
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						<div className="total-wrap">
							<h3 className="total-wrap-title">
								{t(
									"Tax.Dashboard.View.component.ChartCard.SalesCard.total-wrap-title"
								)}
							</h3>
							<div className="amount-wrap">
								<span className="vat">{t("Tax.Dashboard.View.component.vat")}</span>
								<p className="amount">
									{thousandFormatter(sales?.totalAmount)}{" "}
									<i>{t("Tax.Dashboard.View.component.won")}</i>
								</p>
							</div>
						</div>
						<div className="chart-area">
							<ReactFusioncharts
								{...{
									...ASSET_PIE_CHART_CONFIG,
									dataSource: {
										chart: ASSET_PIE_CHART_CONFIG.dataSource.chart,
										data: salesChartData,
									},
								}}
							/>
						</div>
					</Case>
				</If>
			</div>
		</div>
	);
};

export default SalesCard;
