import React from "react";
import { motion } from "framer-motion";
import { Trans, useTranslation } from "react-i18next";
import translation from "~/translations";

const RoadmapSection = ({ scrollRef }) => {
	const { t } = useTranslation();
	const roadmapInfo = [
		{
			year: "2022.12",
			destination: translation.t("Landing.Section.RoadmapSection.roadmapInfo.1"),
			order: "odd",
		},
		{
			year: "2023.08",
			destination: translation.t("Landing.Section.RoadmapSection.roadmapInfo.2"),
			order: "even",
		},
		{
			year: "2024.01",
			destination: translation.t("Landing.Section.RoadmapSection.roadmapInfo.3"),
			order: "odd",
		},
		{
			year: "2024.03",
			destination: translation.t("Landing.Section.RoadmapSection.roadmapInfo.4"),
			order: "even",
		},
		{
			year: "2024.06",
			destination: translation.t("Landing.Section.RoadmapSection.roadmapInfo.5"),
			order: "odd",
		},
		{
			year: "2025.03",
			destination: translation.t("Landing.Section.RoadmapSection.roadmapInfo.6"),
			order: "even",
		},
	];

	// framer 속성
	const list = {
		hidden: {
			opacity: 0,
		},
		visible: {
			opacity: 1,
			transition: {
				when: "beforeChildren",
				staggerChildren: 0.4,
			},
		},
	};

	const item = {
		hidden: { opacity: 0, y: 0 },
		visible: { opacity: 1, y: 0 },
	};

	return (
		<section className="roadmap-section">
			<div className="wrap">
				<h2 className="roadmap-title">{t("Landing.Section.RoadmapSection.title")}</h2>
				{/*수평*/}
				<div className="roadmap-box">
					<motion.ol
						variants={list}
						initial="hidden"
						whileInView="visible"
						viewport={{ root: scrollRef, once: true, amount: 0.3 }}
						className="roadmap-line horiz"
					>
						{roadmapInfo.map((info, i) => {
							return (
								<motion.li variants={item} className="bar-item">
									<div
										className={`container ${
											info.order === "odd" ? "odd" : "even"
										}`}
									>
										<div className="bar-year">{info.year}</div>
										<div className="bar-wrap">
											<div className="bar" />
											<div className="destination pre">
												{info.destination}
											</div>
										</div>
									</div>
								</motion.li>
							);
						})}
					</motion.ol>
				</div>
				{/*수직(반응형)*/}
				<ol className="roadmap-line vertical">
					{roadmapInfo.map((info, idx) => {
						return (
							<li className="bar-item" key={idx}>
								<div
									className={`container ${info.order === "odd" ? "odd" : "even"}`}
								>
									<div className="bar-year">{info.year}</div>
									<div className="destination pre-wrap">{info.destination}</div>
								</div>
							</li>
						);
					})}
				</ol>
				<p className="roadmap-desc pre-wrap">
					<Trans
						i18nKey="Landing.Section.RoadmapSection.roadmap-desc"
						components={[<b />]}
					/>
				</p>
			</div>
		</section>
	);
};

export default RoadmapSection;
