import React from "react";
import { QuestionMarkIcon, ThickChkIcon } from "~/assets/svg/InterfacesIcon";
import defaultAvatar from "~/assets/img/defaultImg/profile-large.png";
import TooltipTemplate from "~/common/components/TooltipPortal/TooltipTemplate";
import { useTranslation } from "react-i18next";

const View = ({ selectUser, authorityList }) => {
	const { t, i18n } = useTranslation();
	return (
		<div className="c__card role-table-card">
			<div className="role-table">
				<div className="thead">
					<div className="th selected-member">
						<div className="member-img">
							<img
								src={selectUser.img ? selectUser.img : defaultAvatar}
								alt={selectUser.name}
							/>
						</div>
						<dl className="member-info">
							<dt>
								{selectUser.name} {selectUser.position}
							</dt>
							<dd>{selectUser.id}</dd>
						</dl>
					</div>
					<div className="th chk-area">
						<div className="auth-type">
							<p>{t("MyPage.accessAuthority")}</p>
							<div className="c__tooltip-btn">
								<QuestionMarkIcon />
								<TooltipTemplate>{t("MyPage.readingAuthority")}</TooltipTemplate>
							</div>
						</div>
					</div>
					<div className="th chk-area">
						<div className="auth-type">
							<p>{t("MyPage.chargeAuthority")}</p>
							<div className="c__tooltip-btn">
								<QuestionMarkIcon />
								<TooltipTemplate>{t("MyPage.editAuthority")}</TooltipTemplate>
							</div>
						</div>
					</div>
				</div>
				<div className="tbody">
					{authorityList.map((menu, menu_idx) => {
						return (
							<div className="tr" key={menu_idx}>
								<div className="td role-colgroup-title">
									{i18n.language === "ko" ? menu.title : menu.engTitle}
								</div>
								<div className="role-colgroup-wrap">
									{menu.subMenu.map((sub, s_idx) => {
										return (
											<div className="role-colgroup" key={s_idx}>
												<div className="role-info">
													<p className="td page-name">
														{i18n.language === "ko"
															? sub.title
															: sub.engTitle}
													</p>
													<p className="td page-desc">
														{i18n.language === "ko"
															? sub.description
															: sub.engDescription}
													</p>
												</div>
												<div
													className={`td chk-area ${
														sub.viewAuth ? "active" : ""
													}`}
												>
													<ThickChkIcon />
												</div>
												{sub.idx === 7 ||
												sub.idx === 28 ||
												sub.idx === 19 ||
												sub.idx === 20 ||
												sub.idx === 26 ||
												sub.idx === 27 ? (
													<div className={`td chk-area`}>-</div>
												) : (
													<div
														className={`td chk-area ${
															sub.editAuth ? "active" : ""
														}`}
													>
														<ThickChkIcon />
													</div>
												)}
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
export default View;
