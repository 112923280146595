import i18next from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { ThinChkIcon } from "~/assets/svg/InterfacesIcon";
import { TranslationIcon } from "~/assets/svg/HeaderIcon";
import { useTranslation } from "react-i18next";

const TransLanguageSelect = () => {
 const { t } = useTranslation();
	const [toggleTranslationDropdown, setToggleTranslationDropdown] = useState(false);
	const [currentLang, setCurrentLang] = useState(
		i18next.language === "ko" ? "한국어" : "English"
	);

	const langList = [
		{ lang: "ko", langItem: "한국어" },
		{ lang: "en", langItem: "English" },
	];

	const dropDownRef = useRef(null);

	function handleClickOutSide(e) {
		if (dropDownRef.current && !dropDownRef.current.contains(e.target)) {
			setToggleTranslationDropdown(false);
		}
	}

	useEffect(() => {
		if (toggleTranslationDropdown) {
			document.addEventListener("click", handleClickOutSide, false);
			return () => {
				document.addEventListener("click", handleClickOutSide, true);
			};
		}
	});

	return (
		<div className="translation-wrap" ref={dropDownRef}>
			<div
				className="translation-text"
				onClick={() => setToggleTranslationDropdown((prev) => !prev)}
			>
				<TranslationIcon />
    &nbsp;
    <div className="font-bold">
     {t('Landing.LandingHeader.nav.button.change-languague')}
    </div>
			</div>
			{toggleTranslationDropdown && (
				<ul className="translation-list">
					{langList.map((item, index) => (
						<li
							key={index}
							className={`${currentLang === item.langItem && "active"}`}
							onClick={() => {
								setCurrentLang(item.langItem);
								localStorage.setItem("language", item.lang);
								i18next.changeLanguage(`${item.lang}`);
								setToggleTranslationDropdown(false);
							}}
						>
							<ThinChkIcon />
							{item.langItem}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default TransLanguageSelect;
