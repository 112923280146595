import React from "react";
import { useController } from "react-hook-form";

const Radio = (props) => {
	const {
		control,
		name,
		validation,
		options,
		defaultValue,
		required,
		number,
		boolean,
		handleClick,
		readOnly = false,
	} = props;

	const booleanValidation =
		required && boolean
			? {
					required: undefined,
					validate: (value) =>
						typeof value === "boolean"
							? true
							: required !== true
							? required
							: "필수 입력 사항 입니다.",
			  }
			: {};
	const { field } = useController({
		control,
		name,
		defaultValue: defaultValue,
		rules: {
			required: required && "필수 입력 사항 입니다.",
			...booleanValidation,
			...validation,
		},
	});

	return (
		<div className={`f__item c__radio-wrap`}>
			{options.map((option, index) => {
				const optionValue = number
					? parseInt(option.value)
					: !boolean
					? option.value.toString()
					: option.value;
				return (
					<label className={`c__radio  ${readOnly ? "readOnly" : null}`} key={index}>
						<input
							checked={optionValue === field.value}
							onChange={() => field.onChange(optionValue)}
							type="radio"
							value={option.value}
							onClick={handleClick}
							disabled={readOnly}
						/>
						<span className="checkmark" />
						<span className="radio-label">{option.label}</span>
					</label>
				);
			})}
		</div>
	);
};
export default Radio;
