import React from "react";
import { DeleteThickIcon } from "~/assets/svg/InterfacesIcon";
import { BellIcon } from "~/assets/svg/TeamInfoIcon";
import { useGlobalStore } from "~/store";
import { setSelectedVacation } from "~/store/hrSetting/actions";
import { Case, If, OtherWise } from "~/common/components/If";
import NoData from "~/common/components/NoData";

const VacationCard = (props) => {
	const { modalVacationTypeList, handleDelete, setVacationAddModal, activateValue, onChange } =
		props;
	const [, dispatch] = useGlobalStore();

	const handleClick = (vacation) => {
		let employeeType = [];
		vacation.vacationAuthorization.forEach((d) => {
			employeeType.push(d.HrEmployeeType.name);
		});

		dispatch(
			setSelectedVacation({
				idx: vacation.idx,
				type: vacation.type,
				title: vacation.title,
				days: vacation.days,
				employeeType: employeeType,
				weekend: vacation.weekend,
				refresh: vacation.refresh,
				continuous: vacation.continuous,
				paid: vacation.paid,
				gender: vacation.gender,
				documents: vacation.documents,
				alarm: vacation.alarm,
				autoConfirm: vacation.autoConfirm,
			})
		);

		setVacationAddModal(true);
	};

	return (
		<If condition={modalVacationTypeList.length}>
			<Case condition={0}>
				<NoData main={"회사의 약정휴가가 존재하지 않습니다."} />
			</Case>
			<OtherWise>
				{modalVacationTypeList.map((vacation, v_idx) => (
					<div
						className="c__card policy-card"
						key={v_idx}
						onClick={() => {
							handleClick(vacation);
						}}
					>
						<div className="card-top">
							<div className="switch-label">
								{vacation.title}
								{vacation.paid ? "" : <span className="unpaid-badge">무급</span>}
							</div>
							<div className="control-wrap" onClick={(e) => e.stopPropagation()}>
								<div className="switch-wrap">
									<label className="switch">
										<input
											type="checkbox"
											value={activateValue.find(
												(e) => e.idx === vacation.idx
											)}
											checked={
												activateValue.includes(vacation.idx)
													? !vacation.activate
													: vacation.activate
											}
											onClick={() => {
												onChange(vacation.idx);
											}}
										/>
										<span className="slider round" />
									</label>
								</div>
								<button
									type="button"
									className="btn-delete"
									onClick={() => {
										handleDelete({ idx: vacation.idx, type: vacation.type });
									}}
								>
									<DeleteThickIcon />
								</button>
							</div>
						</div>
						<div className="card-bottom detail-status-wrap">
							{/* TODO: 관리자 알림 설정 추후 개발 예정 */}
							{/*<span className={`alarm-status ${vacation.alarm && "active"}`}>*/}
							<span className={`alarm-status`}>
								<BellIcon />
							</span>
							<span className="day-status">{vacation.days}일</span>
							<span
								className={`${
									vacation.type === "법정필수휴가" ? "public" : "contract"
								}-holiday`}
							>
								{vacation.type}
							</span>
							<span
								className={`auto-approval-${vacation.autoConfirm ? "on" : "off"}`}
							>
								자동승인{vacation.autoConfirm ? "ON" : "OFF"}
							</span>
						</div>
					</div>
				))}
			</OtherWise>
		</If>
	);
};

export default VacationCard;
