import React, { useEffect, useState } from "react";
import { Case, If } from "~/common/components/If";

import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";

import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";

import View from "~/pages/community/BoardPage/BoardList/View";
import Empty from "~/pages/community/BoardPage/BoardList/Empty";
import Loading from "~/common/components/Loading";
import { useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { BoardListAtom, CurrentBoardAtom } from "~/atoms/Community";

const BoardList = () => {
	const navigate = useNavigate();
	const setCurrentBoard = useSetAtom(CurrentBoardAtom);
	const setBoardList = useSetAtom(BoardListAtom);
	const [page, setPage] = useState(PAGE_STATE.LOADING);

	const boardListRequest = useRequest("post", "community/search");

	useEffect(() => {
		boardListRequest.singleCall();
		setCurrentBoard({});
	}, []);

	useEffect(() => {
		switch (boardListRequest.state) {
			case API_STATE.done:
				const response = boardListRequest.response.data.communities;
				setBoardList(response);
				setPage(response.length > 0 ? PAGE_STATE.VIEW : PAGE_STATE.EMPTY);
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [boardListRequest.state, boardListRequest.response]);

	return (
		<NavbarAndSidebarLayout menu={["community", "community"]} type="main">
			<If condition={page} {...{ setPage, boardListRequest }}>
				<Case condition={PAGE_STATE.LOADING}>
					<Loading />
				</Case>
				<Case condition={PAGE_STATE.EMPTY}>
					<Empty />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<View />
				</Case>
			</If>
		</NavbarAndSidebarLayout>
	);
};

export default BoardList;
