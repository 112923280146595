import React from "react";

const Category = ({ form }) => {
	return (
		<>
			<h4 className="category-title">{form.title}</h4>
		</>
	);
};

export default Category;
