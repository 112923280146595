import React from "react";
import translation from "~/translations";

// Assets
import "~/assets/sass/pages/mypage/admin/license/fee.scss";
import { ArrowSaleIcon, BalloonSaleIcon } from "~/assets/svg/TeamInfoIcon";
import { DetailCompare } from "~/pages/management/LicensePage/component/DetailCompare";
import { LICENSE_PERIOD } from "~/common/constants/form";
import { DateWithDayName, thousandFormatter } from "~/utils/formatter";
import useAlert from "~/common/hooks/useAlert";
import { Trans, useTranslation } from "react-i18next";

const compareList = [
	{ free: true, pro: true, name: translation.t("MyPage.admin.License.fee.compareList.company") },
	{ free: true, pro: true, name: translation.t("MyPage.admin.License.fee.compareList.ir") },
	{
		free: true,
		pro: true,
		name: translation.t("MyPage.admin.License.fee.compareList.event"),
		tooltip: translation.t("MyPage.admin.License.fee.compareList.event-tooltip"),
	},
	{
		free: true,
		pro: true,
		name: translation.t("MyPage.admin.License.fee.compareList.shareholder"),
	},
	{ free: true, pro: true, name: translation.t("MyPage.admin.License.fee.compareList.vc") },
	{
		free: false,
		pro: true,
		name: translation.t("MyPage.admin.License.fee.compareList.tax"),
		tooltip: translation.t("MyPage.admin.License.fee.compareList.tax-tooltip"),
	},
	{
		free: false,
		pro: true,
		name: translation.t("MyPage.admin.License.fee.compareList.hr"),
		tooltip: translation.t("MyPage.admin.License.fee.compareList.hr-tooltip"),
	},
	{
		free: false,
		pro: true,
		name: translation.t("MyPage.admin.License.fee.compareList.invest"),
		tooltip: translation.t("MyPage.admin.License.fee.compareList.invest-tooltip"),
	},
	{
		free: false,
		pro: false,
		name: translation.t("MyPage.admin.License.fee.compareList.service"),
	},
	{ free: true, pro: true, name: translation.t("MyPage.admin.License.fee.compareList.feed") },
	{ free: true, pro: true, name: translation.t("MyPage.admin.License.fee.compareList.news") },
	{ free: true, pro: true, name: translation.t("MyPage.admin.License.fee.compareList.document") },
	{ free: false, pro: false, name: translation.t("MyPage.admin.License.fee.compareList.supply") },
];

const Fee = ({
	purchaseInfo,
	handleApplyButton,
	period,
	setPeriod,
	showApplyAlert,
	ApplyAlert,
	UnsubscriptionAlert,
	showUnsubscriptionAlert,
	unsubscriptionRequest,
	showExpiredLicenseAlert,
	ExpiredLicenseAlert,
}) => {
	const { t } = useTranslation();
	const isPromotion = purchaseInfo.promotion ?? true;

	const [showAlert, InfoAlert] = useAlert();

	const promotionDate = () => {
		if (!purchaseInfo.remainder && !isPromotion) {
			return 60;
		}
		const date = new Date(purchaseInfo.expDate);

		if (purchaseInfo.remainder === 1) {
			const endYear = date.getMonth() + 1 >= 11 ? date.getFullYear() + 1 : date.getFullYear();
			const endMonth = date.getMonth() + 1;
			const endDay = date.getDate();
			const endDate = new Date(endYear, endMonth, endDay);

			return Math.ceil((endDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
		}
		return Math.ceil((date.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
	};

	const unsubscriptionLicense = () => {
		if (purchaseInfo.deactivatedDate) {
			showExpiredLicenseAlert();
		} else {
			showUnsubscriptionAlert().then(() => unsubscriptionRequest.call());
		}
	};

	const renderButton = {
		free() {
			return purchaseInfo.licenseStatus === this.free.name ? (
				<button type="button" className="btn__solid" color="primary" disabled>
					{t("MyPage.admin.License.fee.btn-using")}
				</button>
			) : (
				<button
					type="button"
					className="btn__solid"
					color="primary"
					onClick={() => {
						purchaseInfo.members > 6
							? showAlert()
							: purchaseInfo.deactivatedDate
							? showApplyAlert()
							: handleApplyButton(this.free.name);
					}}
				>
					{t("MyPage.admin.License.fee.btn-save")}
				</button>
			);
		},
		pro() {
			return purchaseInfo.licenseStatus === this.pro.name ? (
				<button type="button" className="btn__solid" color="primary" disabled={true}>
					{t("MyPage.admin.License.fee.btn-using")}
				</button>
			) : (
				<button
					type="button"
					className="btn__solid"
					color="primary"
					onClick={() => {
						handleApplyButton(this.pro.name);
					}}
				>
					{t("MyPage.admin.License.fee.btn-save")}
				</button>
			);
		},
		enterprise() {
			return purchaseInfo.licenseStatus === this.enterprise.name ? (
				<button type="button" className="btn__solid" color="primary" disabled={true}>
					{t("MyPage.admin.License.fee.btn-using")}
				</button>
			) : (
				<button type="button" className="btn__solid" color="primary" disabled>
					{t("MyPage.admin.License.fee.btn-save")}
				</button>
			);
		},
	};
	return (
		<>
			<div className="c__card payment-guide-wrap">
				<div className="title-wrap">
					<h3>{t("MyPage.admin.License.fee.h3")}</h3>
					<div className="c__radio-wrap">
						<label className="c__radio">
							<input
								type="radio"
								name={"period"}
								value={"month"}
								defaultChecked
								onChange={(e) => setPeriod(e.target.value)}
							/>
							<span className="checkmark" />
							<span className="radio-label">
								{t("MyPage.admin.License.fee.monthly")}
							</span>
						</label>
						<label className="c__radio">
							<input
								type="radio"
								value={"year"}
								name={"period"}
								onChange={(e) => setPeriod(e.target.value)}
								disabled={true}
							/>
							<span className="checkmark" />
							<span className="radio-label">
								{t("MyPage.admin.License.fee.yearly")}
							</span>
						</label>
					</div>
				</div>
				<div className="payment-option">
					<div
						className={`c__card free ${
							purchaseInfo.licenseStatus === "free" && "using"
						}`}
					>
						<div className="top-wrap">
							<h4 className="payment-type">Free</h4>
							<p className="payment-limit">
								{t("MyPage.admin.License.fee.free.payment-limit")}
							</p>
							<div className="price-wrap">
								<p className="payment-price">
									{t("MyPage.admin.License.fee.payment-price.member")}
									<span>
										0{t("MyPage.admin.License.fee.payment-price.standard")}
									</span>
									/ {LICENSE_PERIOD[period].date}
								</p>
							</div>
							{isPromotion && (
								<>
									<p className="payment-intro pre">
										{t("MyPage.admin.License.fee.free.payment-intro")}
									</p>
									<span className="divider" />
								</>
							)}
							<p className="payment-desc pre">
								{t("MyPage.admin.License.fee.free.payment-desc")}
							</p>
						</div>
						<div className="bottom-wrap">
							<div className="price-wrap">
								<p className="payment-price">
									{t("MyPage.admin.License.fee.payment-price.member")}
									<span>
										0{t("MyPage.admin.License.fee.payment-price.standard")}
									</span>
									/ {LICENSE_PERIOD[period].date}
								</p>
							</div>
							{renderButton["free"]()}
						</div>
					</div>
					<div
						className={`c__card pro ${purchaseInfo.licenseStatus === "pro" && "using"}`}
					>
						<div className="top-wrap">
							{!!purchaseInfo.remainder && isPromotion && (
								<div
									className={`pro-motion ${
										purchaseInfo.licenseStatus === "pro" && "using"
									}`}
								>
									<Trans
										i18nKey="MyPage.admin.License.fee.free.pro-motion"
										components={[{ promotionDate: promotionDate() }]}
									/>
								</div>
							)}
							<h4 className="payment-type">Pro</h4>
							<p className="payment-limit">
								{t("MyPage.admin.License.fee.pro.payment-limit")}
							</p>
							<div className="price-wrap">
								{isPromotion ? (
									<>
										<div className="discount-wrap">
											<BalloonSaleIcon />
											<p className="sale-phrase">SALE</p>
											<p className="payment-price">
												{t("MyPage.admin.License.fee.payment-price.member")}
												<span className="disabled">
													{thousandFormatter(
														LICENSE_PERIOD[period].money
													)}
													{t(
														"MyPage.admin.License.fee.payment-price.standard"
													)}
												</span>
												/ {LICENSE_PERIOD[period].date}
											</p>
											<ArrowSaleIcon />
										</div>
										<p className="payment-price">
											{t("MyPage.admin.License.fee.payment-price.member")}
											<span>
												0
												{t(
													"MyPage.admin.License.fee.payment-price.standard"
												)}
											</span>
											/ {LICENSE_PERIOD[period].date}
										</p>{" "}
									</>
								) : (
									<p className="payment-price">
										{t("MyPage.admin.License.fee.payment-price.member")}
										<span>
											{thousandFormatter(LICENSE_PERIOD[period].money)}
											{t("MyPage.admin.License.fee.payment-price.standard")}
										</span>
										/ {LICENSE_PERIOD[period].date}
									</p>
								)}
							</div>
							{isPromotion && (
								<>
									<p className="payment-intro pre-wrap">
										{t("MyPage.admin.License.fee.pro.payment-intro")}
									</p>
									<span className="divider" />
								</>
							)}
							<p className="payment-desc pre-wrap">
								{t("MyPage.admin.License.fee.pro.payment-desc")}
							</p>
						</div>
						<div className="bottom-wrap">
							<div className="price-wrap">
								{isPromotion ? (
									<>
										<div className="discount-wrap">
											<BalloonSaleIcon />
											<p className="sale-phrase">SALE</p>
											<p className="payment-price">
												{t("MyPage.admin.License.fee.payment-price.member")}
												<span className="disabled">
													{thousandFormatter(
														LICENSE_PERIOD[period].money
													)}
													{t(
														"MyPage.admin.License.fee.payment-price.standard"
													)}
												</span>
												/ {LICENSE_PERIOD[period].date}
											</p>
											<ArrowSaleIcon />
										</div>
										<p className="payment-price">
											{t("MyPage.admin.License.fee.payment-price.member")}
											<span>
												0
												{t(
													"MyPage.admin.License.fee.payment-price.standard"
												)}
											</span>
											/ {LICENSE_PERIOD[period].date}
										</p>{" "}
									</>
								) : (
									<p className="payment-price">
										{t("MyPage.admin.License.fee.payment-price.member")}
										<span>
											{thousandFormatter(LICENSE_PERIOD[period].money)}
											{t("MyPage.admin.License.fee.payment-price.standard")}
										</span>
										/ {LICENSE_PERIOD[period].date}
									</p>
								)}
								{/*<p className="payment-price option-year">*/}
								{/*	멤버당<span>9,000원</span>/ 연*/}
								{/*</p>*/}
							</div>
							{renderButton["pro"]()}
						</div>
					</div>
					<div className="c__card enterprise">
						<div className="top-wrap">
							<h4 className="payment-type">Enterprise</h4>
							<p className="payment-limit">
								{t("MyPage.admin.License.fee.enterprise.payment-limit")}
							</p>
							<div className="price-wrap">
								<p className="payment-price">
									{t("MyPage.admin.License.fee.payment-price.member")}
									<span>
										{t("MyPage.admin.License.fee.payment-price.confer")}
									</span>
									/ {LICENSE_PERIOD[period].date}
								</p>
								{/*<p className="payment-price option-year">*/}
								{/*	멤버당<span>협의</span>/ 연*/}
								{/*</p>*/}
							</div>
							{isPromotion && (
								<>
									<p className="payment-intro pre-wrap">
										{t("MyPage.admin.License.fee.enterprise.payment-intro")}
									</p>
									<span className="divider" />
								</>
							)}
							<p className="payment-desc pre-wrap">
								{t("MyPage.admin.License.fee.enterprise.payment-desc")}
							</p>
						</div>
						<div className="bottom-wrap">
							<div className="price-wrap">
								<p className="payment-price">
									{t("MyPage.admin.License.fee.payment-price.member")}
									<span>
										{t("MyPage.admin.License.fee.payment-price.confer")}
									</span>
									/ {LICENSE_PERIOD[period].date}
								</p>
								{/*<p className="payment-price option-year">*/}
								{/*	멤버당<span>협의</span>/ 연*/}
								{/*</p>*/}
							</div>
							{renderButton["enterprise"]()}
							{/*<button type="button" className="btn__solid" color="primary">*/}
							{/*	이용중*/}
							{/*</button>*/}
						</div>
					</div>
				</div>
				<div className="c__table payment-detail-table">
					<div className="thead">
						<div className="tr">
							<div className="th item-detail">
								{t("MyPage.admin.License.fee.item-detail")}
							</div>
							<div className="th item-free">Free</div>
							<div className="th item-pro">Pro</div>
							<div className="th item-enterprise">Enterprise</div>
						</div>
					</div>
					<div className="tbody">
						{compareList.map((item, index) => (
							<DetailCompare key={index} {...item} />
						))}
					</div>
				</div>
			</div>
			{purchaseInfo.licenseStatus === "pro" && (
				<div onClick={unsubscriptionLicense} className="license-reply-button">
					<button className="reply-button">
						{t("MyPage.admin.License.fee.btn-reply")}
					</button>
				</div>
			)}
			<ApplyAlert
				type="info"
				confirm={t("button.check")}
				width={480}
				height={306}
				message={
					<>
						<h3>{t("MyPage.admin.License.fee.ApplyAlert.main")}</h3>
						<p>
							{DateWithDayName(
								new Date(purchaseInfo.expDate).setDate(
									new Date(purchaseInfo.expDate).getDate() + 1
								)
							)}{" "}
							{t("MyPage.admin.License.fee.ApplyAlert.sub")}
						</p>
					</>
				}
			/>
			<InfoAlert
				type="info"
				confirm={t("button.check")}
				width={430}
				height={306}
				message={
					<>
						<h3>{t("MyPage.admin.License.fee.InfoAlert.main")}</h3>
						<p>{t("MyPage.admin.License.fee.InfoAlert.sub")}</p>
					</>
				}
			/>
			<UnsubscriptionAlert
				type="warning"
				confirm={t("button.yes")}
				reject={t("button.No")}
				width={430}
				height={310}
				layout="horiz"
				message={
					<>
						<h3>{t("MyPage.admin.License.fee.UnsubscriptionAlert.main")}</h3>
					</>
				}
				desc={
					<div className="desc-box error">
						{purchaseInfo.licenseStatus === "pro" ? (
							<Trans i18nKey="MyPage.admin.License.fee.UnsubscriptionAlert.desc.pro" />
						) : (
							// 		`현재 이용중인 pro 라이센스의 이용기간은
							// ${DateWithDayName(purchaseInfo.expDate)} 까지 입니다.`
							t("MyPage.admin.License.fee.UnsubscriptionAlert.desc.free")
						)}
						<br />
						{t("MyPage.admin.License.fee.UnsubscriptionAlert.desc.sub1")}{" "}
						{DateWithDayName(
							new Date(purchaseInfo.expDate).setDate(
								new Date(purchaseInfo.expDate).getDate() + 1
							)
						)}{" "}
						{t("MyPage.admin.License.fee.UnsubscriptionAlert.desc.sub2")}
					</div>
				}
			/>
			<ExpiredLicenseAlert
				type="info"
				confirm={t("button.check")}
				width={500}
				message={
					<>
						<h3>{t("MyPage.admin.License.fee.ExpiredLicenseAlert.main")}</h3>
						<p>
							{DateWithDayName(
								new Date(purchaseInfo.expDate).setDate(
									new Date(purchaseInfo.expDate).getDate() + 1
								)
							)}{" "}
							{t("MyPage.admin.License.fee.ExpiredLicenseAlert.sub")}
						</p>
					</>
				}
			/>
		</>
	);
};
export default Fee;
