import noImg from "~/assets/img/defaultImg/profile-large.png";
import Form, { Group } from "~/common/components/Form";
import DatePicker from "~/common/components/DatePicker";
import NumberInput from "~/common/components/NumberInput";
import Textarea from "~/common/components/Textarea";
import React, { useEffect } from "react";
import { endOfMonth, startOfMonth } from "date-fns";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import SwitchRadio from "~/common/components/SwitchRadio";
import Input from "~/common/components/Input";
import { getOnlyDemical } from "~/utils/formatter";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const AdjustEdit = ({
	CurrentDate,
	setAnnualAdjustModal,
	selectedUser,
	hrFixHistoryRequest,
	vacationDetailRequest,
	isEdit,
	selectedHistory,
	vacationSetting,
	vacationTimeCountRequest,
}) => {
	const { t } = useTranslation();
	const date = new Date(CurrentDate.year, CurrentDate.month - 1);
	const DateRange = { minDate: startOfMonth(date), maxDate: endOfMonth(date) };
	const navigate = useNavigate();
	const workTime = selectedHistory.currentWorkTime / 60;

	const AdjustForm = useForm({
		defaultValues: isEdit && {
			applyDate: new Date(selectedHistory.applyDate),
			memo: selectedHistory.memo,
			days:
				vacationSetting.unit === "시간"
					? Math.floor(selectedHistory.fixedDay)
					: selectedHistory.fixedDay,
			hours:
				vacationSetting.unit === "시간"
					? getOnlyDemical(selectedHistory.fixedDay) * workTime
					: 0,
			type: Math.sign(selectedHistory.fixedDay),
		},
	});

	const remainTimeRequest = useRequest("post", "vacation/remain/time");
	const editRemainTimeRequest = useRequest(
		"patch",
		`vacation/fix/history/${selectedHistory.idx}`
	);
	const deleteRemainTimeRequest = useRequest(
		"delete",
		`vacation/fix/history/${selectedHistory.idx}`
	);

	const handleAdjust = (data) => {
		const days = Number(data.days) * data.type;
		const hours = Number(data.hours) * data.type;
		const AdjustData = {
			days: days,
			hours: hours,
			memo: data.memo,
			userIdx: selectedUser.User.idx,
			applyDate: isEdit
				? data.applyDate
				: new Date(data.applyDate.getTime() + 9 * 60 * 60 * 1000),
		};
		isEdit
			? editRemainTimeRequest.call(AdjustData, "body")
			: remainTimeRequest.call(AdjustData, "body");
	};

	const afterAdjust = () => {
		setAnnualAdjustModal(false);
		hrFixHistoryRequest.call(CurrentDate, "queryString");
		vacationDetailRequest.call();
		vacationTimeCountRequest.call(selectedUser.User.idx, "path");
	};

	useEffect(() => {
		switch (remainTimeRequest.state) {
			case API_STATE.done:
				afterAdjust();
				break;
			case API_STATE.error:
				console.error(remainTimeRequest.error);
				// navigate("/404");
		}
	}, [remainTimeRequest.state]);

	useEffect(() => {
		switch (editRemainTimeRequest.state) {
			case API_STATE.done:
				afterAdjust();
				break;
			case API_STATE.error:
				console.error(editRemainTimeRequest.error);
				// navigate("/404");
		}
	}, [editRemainTimeRequest.state]);

	useEffect(() => {
		switch (deleteRemainTimeRequest.state) {
			case API_STATE.done:
				afterAdjust();
				break;
			case API_STATE.error:
				console.error(deleteRemainTimeRequest.error);
				// navigate("/404");
		}
	}, [deleteRemainTimeRequest.state]);

	const DESCRIPTION = {
		반차: translation.t("HR.Manage.vacationList.Modal.ManageAnnual.pText1"),
		일: translation.t("HR.Manage.vacationList.Modal.ManageAnnual.pText2"),
	};

	return (
		<>
			<div className="modal-content">
				<div className="top">
					<div className="img-wrap">
						<img src={selectedUser.User.img || noImg} alt="프로필 이미지" />
					</div>
					<div className="profile-wrap">
						<span className="name">{selectedUser.User.UserProfile.name}</span>
						<span className="team">
							{selectedUser.User.UserProfile.department}/
							{selectedUser.User.UserProfile.position}
						</span>
						<div className="email">{selectedUser.User.id}</div>
					</div>
				</div>
				<div className="bottom">
					<Form useForm={AdjustForm} className="vacation-manage-form vertical">
						<DatePicker
							label={t(
								"HR.Manage.vacationList.Modal.Detail.MonthRow.AdjustEdit.applyDateLabel"
							)}
							name="applyDate"
							datePickerName="f__datePicker"
							minDate={DateRange.minDate}
							maxDate={DateRange.maxDate}
							defaultValue={DateRange.minDate}
						/>
						<Group
							label={t(
								"HR.Manage.vacationList.Modal.Detail.MonthRow.AdjustEdit.GroupLabel"
							)}
						>
							{/* <Select
								name="type"
								defaultValue="추가"
								options={[
									{ label: "추가", value: "추가" },
									{ label: "차감", value: "차감" },
								]}
							/> */}
							<SwitchRadio
								className="angled"
								name="type"
								defaultValue={1}
								options={[
									{
										label: translation.t(
											"HR.Manage.vacationList.Modal.Detail.MonthRow.AdjustEdit.add"
										),
										value: 1,
									},
									{
										label: translation.t(
											"HR.Manage.vacationList.Modal.Detail.MonthRow.AdjustEdit.deduct"
										),
										value: -1,
									},
								]}
							/>
						</Group>
						{vacationSetting.unit === "시간" ? (
							<Group label={t("HR.Manage.payday-wrap")} className="payday-wrap">
								<NumberInput name="days" unit={t("HR.Manage.day")} />
								<NumberInput name="hours" unit={t("HR.Manage.hours")} />
							</Group>
						) : (
							<>
								<Input
									label={t("HR.Manage.payday-wrap")}
									name="days"
									unit="일"
									type={"number"}
									className="payday-wrap"
									required={null}
									validation={
										vacationSetting.unit === "반차"
											? {
													pattern: {
														value: /^[0-9]{1,3}$|.[5]/,
														message: " ",
													},
											  }
											: {
													pattern: {
														value: /^[0-9]{1,3}$/,
														message: "",
													},
											  }
									}
								/>
							</>
						)}
						<p
							className={`desc ${
								AdjustForm.formState.errors.days !== undefined ? "error" : ""
							}`}
						>
							{DESCRIPTION[vacationSetting.unit] ?? ""}
						</p>
						<Textarea
							label={t(
								"HR.Manage.vacationList.Modal.Detail.MonthRow.AdjustEdit.TextareaLabel"
							)}
							name="memo"
							placeholder={t(
								"HR.Manage.vacationList.Modal.Detail.MonthRow.AdjustEdit.TextareaPlaceholder"
							)}
						/>
					</Form>
				</div>
			</div>
			<div className="modal-footer">
				{/* 수정할때 버튼 */}
				{isEdit ? (
					<>
						<button
							className="btn__solid"
							color="red"
							onClick={() => deleteRemainTimeRequest.call()}
						>
							{t("button.delete")}
						</button>
						<button
							className="btn__solid"
							color="primary"
							onClick={AdjustForm.handleSubmit(handleAdjust)}
						>
							{t("button.modify")}
						</button>
					</>
				) : (
					<button
						className="btn__solid"
						color="primary"
						onClick={AdjustForm.handleSubmit(handleAdjust)}
					>
						{t("button.application")}
					</button>
				)}
				{/*조정할때 버튼*/}
			</div>
		</>
	);
};

export default AdjustEdit;
