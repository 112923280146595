import React from "react";
import { useGlobalStore } from "~/store";
//Assets
import "~/assets/sass/pages/ir/member.scss";
import defaultAvatar from "~/assets/img/defaultImg/profile-large.png";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const View = ({ Header, showEditPage }) => {
	const { t } = useTranslation();
	const [
		{
			ir: { member },
		},
	] = useGlobalStore();

	const buttonList = [
		<AuthButton type="button" className="btn__solid" color="primary" onClick={showEditPage}>
			{t("button.Edit")}
		</AuthButton>,
	];

	const memberList = () =>
		member.map(({ img, name, department, position, introduction }, idx) => (
			<div className="c__card" key={idx}>
				<div className="member-img">
					<img
						src={img || defaultAvatar}
						alt={name}
						onError={(e) => {
							e.target.src = defaultAvatar;
						}}
					/>
				</div>
				<dl className="card-content">
					<dt className="member-name">{name}</dt>
					<dd className="member-position">
						<span>{department}</span>
						<span>{position}</span>
					</dd>
					<dd className="member-career">{introduction}</dd>
					{/*<dd className="member-career">관리자가 직원 소개를 입력하지 않았습니다.</dd>*/}
				</dl>
			</div>
		));

	return (
		<>
			<Header buttonList={buttonList} />
			<div className="c__cardRow member-view">{memberList()}</div>
		</>
	);
};

export default View;
