import { LOGIN, MAIL } from "./actions";
import persistHelper from "~/utils/persist";

export const INITIAL_STATE = {
	logged: false,
	accessToken: "",
};

export const PERSISTED_STATE = persistHelper.initState("auth", INITIAL_STATE, "local");

export const REDUCER = (state = PERSISTED_STATE, action) => {
	switch (action.type) {
		case LOGIN:
			return persistHelper.setPersist(
				"auth",
				{
					logged: true,
					accessToken: action.payload.accessToken,
				},
				"local"
			);
		case MAIL:
			return persistHelper.setPersist(
				"auth",
				{
					accessToken: action.payload.accessToken,
				},
				"local"
			);
		default:
			return state;
	}
};
