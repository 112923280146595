import React from "react";
import { AuthEmailIcon } from "~/assets/svg/InterfacesIcon";
import { useTranslation } from "react-i18next";

const InviteResult = ({ setModalToggle, teamInviteRequest, inviteResponseData }) => {
	const { t } = useTranslation();
	const handleToggle = () => {
		setModalToggle(false);
		teamInviteRequest.call();
	};

	return (
		<>
			<div className="modal-content invite-result">
				<AuthEmailIcon />
				<p className="title">
					{t("MyPage.admin.Team.InviteModal.invite-success")} (
					{inviteResponseData.success.length}/
					{inviteResponseData.success.length + inviteResponseData.conflict.length})
				</p>
				{inviteResponseData.conflict.length !== 0 && (
					<p className="notice-wrap">
						{t("MyPage.admin.Team.InviteModal.invite-conflict")} <br />"
						{inviteResponseData.conflict.join('", "')}"
					</p>
				)}
			</div>
			<div className="modal-footer">
				<button type="button" className="btn__solid" color="primary" onClick={handleToggle}>
					{t("button.check")}
				</button>
			</div>
		</>
	);
};

export default InviteResult;
