import { PlusIcon } from "~/assets/svg/MathIcon";
import React, { useEffect, useState } from "react";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import { Case, If } from "~/common/components/If";
import { dateToString, signFormatter } from "~/utils/formatter";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import AdjustEdit from "~/pages/vacation/ManagePage/vacationList/Modal/Detail/MonthRow/AdjustEdit";
import { useTranslation } from "react-i18next";

const AnnualAdjustDropdown = ({
	date,
	selectedUser,
	vacationDetailRequest,
	vacationSetting,
	vacationTimeCountRequest,
}) => {
	const { t } = useTranslation();
	const CurrentDate = { year: date.slice(0, 4), month: date.slice(-3, -1) };

	const hrFixHistoryRequest = useRequest(
		"get",
		`vacation/fix/history/${selectedUser.User.idx}`
	);

	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [annualAdjustModal, setAnnualAdjustModal] = useState(false);
	const [fixedHistories, setFixedHistories] = useState([]);
	const [selectedHistory, setSelectedHistory] = useState({});
	const [isEdit, setIsEdit] = useState(false);

	// let outsideRef = useRef(null);
	//
	// function handleClickOutside(e) {
	// 	if (outsideRef.current && !outsideRef.current.contains(e.target)) {
	// 		setToggleUserDropdown(false);
	// 	}
	// }
	//
	// useEffect(() => {
	// 	if (toggleUserDropdown) {
	// 		document.addEventListener("click", handleClickOutside, false);
	// 		return () => {
	// 			document.removeEventListener("click", handleClickOutside, true);
	// 		};
	// 	}
	// }, [toggleUserDropdown]);

	useEffect(() => {
		hrFixHistoryRequest.call(CurrentDate, "queryString");
	}, []);

	useEffect(() => {
		switch (hrFixHistoryRequest.state) {
			case API_STATE.done:
				const response = hrFixHistoryRequest.response.data.fixedHistories;
				setFixedHistories(
					response.map((item) => {
						return { ...item, fixedDay: item.amount / item.currentWorkTime };
					})
				);
				setPageState(response.length === 0 ? PAGE_STATE.EMPTY : PAGE_STATE.VIEW);
		}
	}, [hrFixHistoryRequest.state]);

	return (
		<>
			<ul className="adjustment-list">
				<li className="adjustment-item">
					<If condition={pageState}>
						<Case condition={PAGE_STATE.VIEW}>
							<p className="title">
								{date}{" "}
								{t(
									"HR.Manage.vacationList.Modal.Detail.MonthRow.AnnualAdjustDropdown.title1"
								)}{" "}
								{fixedHistories.length}
							</p>
							{fixedHistories.map((history, index) => (
								<div
									className="date-info"
									key={index}
									onClick={() => {
										setSelectedHistory((prevState) => {
											return { ...prevState, ...history };
										});
										setIsEdit(true);
										setAnnualAdjustModal((prevState) => !prevState);
									}}
								>
									<span className="tag">
										{dateToString(history.applyDate, "M월 dd일")}
									</span>
									<span className="num">{signFormatter(history.fixedDay)}</span>
								</div>
							))}
						</Case>
						<Case condition={PAGE_STATE.EMPTY}>
							<p className="title">
								{date}{" "}
								{t(
									"HR.Manage.vacationList.Modal.Detail.MonthRow.AnnualAdjustDropdown.title2"
								)}
							</p>
						</Case>
					</If>
				</li>
				<li className="adjustment-item">
					<button
						type="button"
						className="btn-add"
						onClick={() => {
							setAnnualAdjustModal(true);
							setIsEdit(false);
						}}
					>
						<PlusIcon />
						{t(
							"HR.Manage.vacationList.Modal.Detail.MonthRow.AnnualAdjustDropdown.btn-add"
						)}
					</button>
				</li>
			</ul>
			{/* 연차 조정하기 모달 */}
			<ModalTemplate
				title={t(
					"HR.Manage.vacationList.Modal.Detail.MonthRow.AnnualAdjustDropdown.annualAdjustModal"
				)}
				modalState={annualAdjustModal}
				setModalToggle={setAnnualAdjustModal}
				width={350}
				height={403}
				className="vacation-modify-modal"
				closeable={false}
			>
				<AdjustEdit
					{...{
						CurrentDate,
						setAnnualAdjustModal,
						selectedUser,
						hrFixHistoryRequest,
						vacationDetailRequest,
						isEdit,
						selectedHistory,
						vacationSetting,
						vacationTimeCountRequest,
					}}
				/>
			</ModalTemplate>
		</>
	);
};

export default AnnualAdjustDropdown;
