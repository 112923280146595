export const SET_COMPANY_INFO = "company/SET_COMPANY_INFO";
export const SET_PRODUCT_LIST = "company/SET_PRODUCT_LIST";
export const SET_CURRENT_PRODUCT = "company/SET_CURRENT_PRODUCT";
export const SET_MEMBER_LIST = "company/SET_MEMBER_LIST";
export const SET_MEMBER_TREND = "company/SET_MEMBER_TREND";
export const CLEAR_COMPANY = "company/CLEAR_COMPANY";

export const setCompanyInfo = ({ info, repProduct }) => {
	return {
		type: SET_COMPANY_INFO,
		payload: {
			info: info,
			repProduct: repProduct,
		},
	};
};

export const setProductList = ({ productList }) => {
	return {
		type: SET_PRODUCT_LIST,
		payload: {
			productList: productList,
		},
	};
};

export const setCurrentProduct = ({ product }) => {
	return {
		type: SET_CURRENT_PRODUCT,
		payload: {
			product: product,
		},
	};
};

export const setMemberList = ({ memberList }) => {
	return {
		type: SET_MEMBER_LIST,
		payload: {
			memberList: memberList,
		},
	};
};

export const setMemberTrend = ({ memberTrend }) => {
	return {
		type: SET_MEMBER_TREND,
		payload: {
			memberList: memberTrend,
		},
	};
};

export const clearCompany = () => ({
	type: CLEAR_COMPANY,
});
