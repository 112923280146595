import React, { useEffect, useState } from "react";
import { BUSINESS_PAGE_STATE } from "~/pages/mypage/admin/BusinessPage";
import SingleImageUploader from "~/common/components/ImageUploader/SingleImageUploader";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import AlertTemplate from "~/common/components/AlertPortal/AlertTemplate";
import { useTranslation } from "react-i18next";

//Assets
import "~/assets/sass/pages/mypage/admin/business/certified.scss";

const Certified = ({ user, setPage, bizCertification, getBizImgRequest, getUserInfo }) => {
	const { t } = useTranslation();
	const [removeModal, setRemoveModal] = useState(false);

	const removeCertificationRequest = useRequest("post", `admin/business/cancel`);

	const handleCancel = () => {
		removeCertificationRequest.call(user.company.idx, "path");
	};

	useEffect(() => {
		switch (removeCertificationRequest.state) {
			case API_STATE.done:
				getUserInfo().then(() => {
					getBizImgRequest.call();
				});
				break;
			case API_STATE.error:
				console.log("error", API_STATE.error);
				alert(t("Alert.alert2"));
				break;
			default:
				break;
		}
	}, [
		removeCertificationRequest.state,
		removeCertificationRequest.response,
		removeCertificationRequest.error,
	]);

	return (
		<>
			<div className="c__card certified">
				<div className="card-top">
					<dl className="content">
						<dt>{t("MyPage.admin.Business.Certified.content.dt")}</dt>
						<dd className="pre">{t("MyPage.admin.Business.Certified.content.dd")}</dd>
						<dd className="biz-num">
							<b>{t("MyPage.admin.Business.biz-num")}</b>
							<span>{user.company.regNum}</span>
						</dd>
					</dl>
					<SingleImageUploader
						img={bizCertification.imgUrl}
						width={168}
						height={200}
						deletable={false}
					/>
				</div>
				<div className="card-bottom">
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => setRemoveModal(true)}
					>
						{t("MyPage.admin.Business.btn-cancel")}
					</button>
				</div>
			</div>
			<AlertTemplate
				alertModalState={removeModal}
				setAlertModalToggle={setRemoveModal}
				alertType="warning"
				width={470}
				height={280}
			>
				<p className="alert-content">
					{t("MyPage.admin.Business.Certified.alert-content")}
				</p>
				<div className="alert-footer">
					<button className="btn__solid" color="primary" onClick={handleCancel}>
						{t("button.yes")}
					</button>
					<button
						className="btn__solid"
						color="gray"
						onClick={() => setRemoveModal(false)}
					>
						{t("button.No")}
					</button>
				</div>
			</AlertTemplate>
		</>
	);
};
export default Certified;
