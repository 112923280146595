import React from "react";

import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import { RoundMinusIcon } from "~/assets/svg/InterfacesIcon";
import { PlusIcon } from "~/assets/svg/MathIcon";
import { useFieldArray } from "react-hook-form";
import DatePicker from "~/common/components/DatePicker";
import { dateToString } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const InputRow = ({ useForm, name, remove, onChange }) => {
	const { t } = useTranslation();
	return (
		<Form useForm={useForm} className="sub-section-wrap">
			<DatePicker
				onChange={onChange}
				name={name("date")}
				className="datepicker"
				month
				required
			/>
			<Input
				placeholder={t("HR.TeamInfo.modal.MemberDetailModal.components.Career.content")}
				className="content"
				name={name("content")}
				type="text"
				required
			/>
			<button type="button" className="btn-round-delete" onClick={remove}>
				<RoundMinusIcon />
			</button>
		</Form>
	);
};

const DynamicFields = ({ name, useForm }) => {
	const { t } = useTranslation();
	const { control, getValues, setValue } = useForm;

	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const ROW_INIT_DATA = {
		content: "",
		date: new Date().toISOString(),
	};

	const sortByDate = () => {
		const parseDate = (d) => dateToString(d, "yyyyMM");
		setValue(
			name,
			getValues(name).sort((a, b) => parseDate(b.date).localeCompare(parseDate(a.date)))
		);
	};
	return (
		<>
			<div className="left-wrap">
				<button
					className="dashed-btn plus-btn"
					type="button"
					onClick={() => {
						append(ROW_INIT_DATA);
						sortByDate();
					}}
				>
					<PlusIcon />
					{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.addInput")}
				</button>
				<section className="history-input">
					{fields.map((field, index) => {
						const getName = (propName) => `${name}.${index}.${propName}`;
						const removeItem = () => remove(index);
						return (
							<InputRow
								key={field.id}
								name={getName}
								remove={removeItem}
								useForm={useForm}
								onChange={sortByDate}
							/>
						);
					})}
				</section>
			</div>
		</>
	);
};

export default DynamicFields;
