export const SET_EVALUATE_LIST = "evaluate/SET_EVALUATE_LIST";
export const SET_CURRENT_EVALUATE = "evaluate/SET_CURRENT_EVALUATE";
export const GET_IR_LIST = "evaluate/GET_IR_LIST";

export const setEvaluateList = ({ evaluateList }) => {
	return {
		type: SET_EVALUATE_LIST,
		payload: {
			evaluateList: evaluateList,
		},
	};
};

export const setCurrentEvaluate = ({ evaluateDetail }) => {
	return {
		type: SET_CURRENT_EVALUATE,
		payload: {
			evaluateDetail: evaluateDetail,
		},
	};
};

export const getIrList = ({ irList }) => {
	return {
		type: GET_IR_LIST,
		payload: {
			irList: irList,
		},
	};
};
