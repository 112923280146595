export const SET_VACATION_TYPE = "attendance/SET_VACATION_TYPE";
export const SET_TEAM_SCHEDULE = "attendance/SET_TEAM_SCHEDULE";

export const setVacationType = (vacationType) => {
	return {
		type: SET_VACATION_TYPE,
		payload: {
			vacationType: vacationType,
		},
	};
};

export const setTeamSchedule = ({
	teamVacation,
	teamBusinessTrip,
	teamRemoteWork,
	calendarEvents,
}) => {
	return {
		type: SET_TEAM_SCHEDULE,
		payload: {
			teamVacation: teamVacation,
			teamBusinessTrip: teamBusinessTrip,
			teamRemoteWork: teamRemoteWork,
			calendarEvents: calendarEvents,
		},
	};
};
