import React from "react";
import { PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import { Case, If } from "~/common/components/If";
import Slider from "react-slick";
import { differenceInDays } from "date-fns";
import { range } from "~/utils/array";
import noImg from "~/assets/img/defaultImg/default-img.png";
import { useNavigate } from "react-router";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";

const InvestRound = ({ data = [], pageState }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const sliderSettings = {
		dots: true,
		arrows: true,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		responsive: [
			{
				breakpoint: 1247,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	};

	const RoundCard = ({ idx, title, date, loading, empty, img }) => {
		if (loading) {
			return (
				<div className="round-card empty">
					<div className="round-container-prepare">
						<Loading />
					</div>
				</div>
			);
		} else if (empty) {
			return (
				<div className="round-card empty">
					<div className="round-container-prepare">
						<p>{t("Dashboard.Contents.InvestRound.round-container-prepare")}</p>
					</div>
				</div>
			);
		} else {
			const dDay = differenceInDays(new Date(date), new Date());
			return (
				<div className="round-card" onClick={() => navigate(`/invest/round?idx=${idx}`)}>
					<div className="round-img">
						<img
							src={img ? img : noImg}
							alt={t("Dashboard.Contents.InvestRound.round-img-alt")}
							onError={(e) => {
								e.target.src = noImg;
							}}
						/>
					</div>
					<div className="round-container">
						<p>{title}</p>
						{dDay < 0 ? (
							<span className="deadline">
								{t("Dashboard.Contents.InvestRound.deadline")}
							</span>
						) : (
							<span className="d-day">D-{dDay === 0 ? "day" : dDay}</span>
						)}
					</div>
				</div>
			);
		}
	};

	const EmptyCard = () => {
		return <NoData main={t("Dashboard.Contents.InvestRound.no-data")} />;
	};

	const CardList = () => {
		const Cards = data.map(({ idx, title, endDate, img }, i) => {
			return <RoundCard idx={idx} title={title} date={endDate} key={i} img={img} />;
		});

		const dataLength = data.length;

		if (dataLength === 0) {
			return <EmptyCard />;
		} else {
			return (
				<Slider {...sliderSettings} className={dataLength > 4 ? "hasArrows" : null}>
					{Cards}
				</Slider>
			);
		}
	};

	const LoadingCardList = () => (
		<Slider {...sliderSettings}>
			{range(0, 3).map((i) => (
				<RoundCard key={i} loading />
			))}
		</Slider>
	);

	return (
		<article className="invest-round">
			<div className="article-title">{t("Dashboard.Contents.InvestRound.article-title")}</div>
			<If condition={pageState}>
				<Case condition={PAGE_STATE.LOADING}>
					<LoadingCardList />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<CardList />
				</Case>
			</If>
		</article>
	);
};

export default InvestRound;
