import React, { useState } from "react";
import { useGlobalStore } from "~/store";
import { SolidCloseIcon } from "~/assets/svg/CloseIcon";
import { Multiselect } from "multiselect-react-dropdown";
import CustomSelect from "~/common/components/CustomSelect";
import { Trans, useTranslation } from "react-i18next";

const Search = ({ allVcCount, allPortfolioCount, setPage, setSearchName, setSearchType }) => {
	const { t } = useTranslation();
	const [
		{
			vc: { fields, types },
		},
	] = useGlobalStore();
	const [inputValue, setInputValue] = useState("");
	const [selectValue, setSelectValue] = useState("");

	const handleSearch = () => {
		setSearchName(inputValue);
		setSearchType(selectValue === "ALL" ? "" : selectValue);
		setPage(1);
	};

	return (
		<div className="c__card search-card">
			<h3 className="info-title">
				<Trans
					i18nKey="VcInfo.VcList.View.search.info-title1"
					components={[<b />, { allVcCount }]}
				/>
				<div className="divider" />
				<Trans
					i18nKey={"VcInfo.VcList.View.search.info-title2"}
					components={[<b />, { allPortfolioCount }]}
				/>
			</h3>
			<div className="search-from">
				<Multiselect
					showCheckbox
					displayValue="name"
					options={fields}
					customCloseIcon={<SolidCloseIcon />}
					placeholder={t("VcInfo.VcList.View.search.vc-partner-select")}
					disable={true}
					className={"vc-partner-select"}
				/>
				<div className="row">
					<CustomSelect
						value={selectValue}
						setValue={setSelectValue}
						options={types}
						selectName="vc-investor-select"
						placeholder={t("VcInfo.VcList.View.search.vc-investor-select")}
					/>
					<input
						className="c__input f__item search-input"
						placeholder={t("VcInfo.VcList.View.search.search-input")}
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						onKeyDown={(e) => e.key === "Enter" && handleSearch()}
					/>
					<button
						type="button"
						className="btn__solid btn-search"
						color="primary"
						onClickCapture={() => handleSearch()}
					>
						{t("button.search")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Search;

// FixMe autocomplete
// <div className="auto-complete-wrap">
// 	<input
// 		className="c__input search-input"
// 		placeholder="투자사를 입력해주세요."
// 	/>
// </div>
