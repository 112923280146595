import React from "react";

export const LightCloseIcon = (props) => {
	const { clickEvent } = props;
	return (
		<svg
			onClick={clickEvent}
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
		>
			<path
				id="prefix__패스_184"
				d="M10.655 10l9.206 9.2a.465.465 0 0 1-.658.658L10 10.655.791 19.861a.465.465 0 0 1-.658-.661L9.34 10 .133.791A.465.465 0 0 1 .791.133L10 9.34 19.2.133a.465.465 0 0 1 .658.658z"
				transform="translate(.003 .003)"
			/>
		</svg>
	);
};

export const SolidCloseIcon = (props) => {
	const { style } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="10"
			viewBox="0 0 10 10"
			className="solid-close-icon"
			style={style}
		>
			<path
				id="prefix__패스_181"
				d="M6.179 4.99l3.565 3.565a.841.841 0 0 1-1.189 1.189L4.989 6.179 1.424 9.744A.841.841 0 0 1 .236 8.555L3.8 4.99.236 1.424A.84.84 0 0 1 1.424.236L4.989 3.8 8.555.236a.841.841 0 0 1 1.189 1.188z"
				transform="translate(.01 .01)"
			/>
		</svg>
	);
};

export const BoldCloseIcon = (props) => {
	const { style } = props;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="10"
			height="10"
			viewBox="0 0 10 10"
			className="solid-close-icon"
			style={style}
		>
			<path
				id="prefix__패스_181"
				d="M6.179 4.99l3.565 3.565a.841.841 0 0 1-1.189 1.189L4.989 6.179 1.424 9.744A.841.841 0 0 1 .236 8.555L3.8 4.99.236 1.424A.84.84 0 0 1 1.424.236L4.989 3.8 8.555.236a.841.841 0 0 1 1.189 1.188z"
				transform="translate(.01 .01)"
			/>
		</svg>
	);
};
