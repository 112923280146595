import { useEffect, useState } from "react";
import useDebounce from "~/common/hooks/useDebounce";

const useResize = () => {
	const [windowInnerSize, setWindowInnerSize] = useState(window.innerWidth);
	const [windowOuterSize, setWindowOuterSize] = useState(window.outerWidth);

	const handleResize = useDebounce(() => {
		setWindowOuterSize(window.outerWidth);
		setWindowInnerSize(window.innerWidth);
	}, 1000);

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return { windowOuterSize: windowOuterSize, windowInnerSize: windowInnerSize };
};

export default useResize;
