import React from "react";
import { useTranslation } from "react-i18next";
import NewLandingLayout from "~/layouts/LandingLayout/index";
import { PersonBurst, BlinkStar, TickIcon } from "~/assets/svg/PricingIcon";
import { useSetAtom } from "jotai";
import { bookDemoModalAtom } from "~/atoms/Landing";

const PricingPage = () => {
	const { t } = useTranslation();
	const setBookDemoModal = useSetAtom(bookDemoModalAtom);

	const basicPricing = [
		{
			header: t("Pricing.content.basic-pricing.1.header"),
			subHeader: t("Pricing.content.basic-pricing.1.subHeader"),
			price: t("Pricing.content.basic-pricing.1.price"),
			benefit: [
				t("Pricing.content.basic-pricing.1.benefit1"),
				t("Pricing.content.basic-pricing.1.benefit2"),
				t("Pricing.content.basic-pricing.1.benefit3"),
				t("Pricing.content.basic-pricing.1.benefit4"),
			],
		},
		{
			header: t("Pricing.content.basic-pricing.2.header"),
			subHeader: t("Pricing.content.basic-pricing.2.subHeader"),
			price: t("Pricing.content.basic-pricing.2.price"),
			benefit: [
				t("Pricing.content.basic-pricing.2.benefit1")
			],
		},
		{
			header: t("Pricing.content.basic-pricing.3.header"),
			subHeader: t("Pricing.content.basic-pricing.3.subHeader"),
			price: t("Pricing.content.basic-pricing.3.price"),
			benefit: [
				t("Pricing.content.basic-pricing.3.benefit1"),
				t("Pricing.content.basic-pricing.3.benefit2")
			],
		},
	];
	const bonusPricing = [
		// {
		// 	header: t("Pricing.content.plus-pricing.1.header"),
		// 	subHeader: t("Pricing.content.plus-pricing.1.subHeader"),
		// 	price: t("Pricing.content.plus-pricing.1.price"),
		// 	benefit: [
		// 		t("Pricing.content.plus-pricing.1.benefit1"),
		// 		t("Pricing.content.plus-pricing.1.benefit2"),
		// 	],
		// },
		{
			header: t("Pricing.content.plus-pricing.2.header"),
			subHeader: t("Pricing.content.plus-pricing.2.subHeader"),
			price: t("Pricing.content.plus-pricing.2.price"),
			benefit: [
				t("Pricing.content.plus-pricing.2.benefit1"),
				t("Pricing.content.plus-pricing.2.benefit2"),
			],
		},
		{
			header: t("Pricing.content.plus-pricing.3.header"),
			subHeader: t("Pricing.content.plus-pricing.3.subHeader"),
			price: t("Pricing.content.plus-pricing.3.price"),
			benefit: [
				t("Pricing.content.plus-pricing.3.benefit1"),
				t("Pricing.content.plus-pricing.3.benefit2"),
				t("Pricing.content.plus-pricing.3.benefit3"),
			],
		},
	];

	return (
		<NewLandingLayout>
			<main className="h-full w-full max-w-[1920px] mx-auto">
				<div className="bg-[#DEEDF0]">
					<div className="w-[95%] sm:w-[85%] m-auto pt-48 md:flex block justify-between">
						<div>
							<div className="relative sm:text-5xl text-3xl font-semibold md:w-[70%] w-full flex">
								{t("Pricing.header")}
								<div className="mt-36 ml-[-350px] xl:block hidden">
									<BlinkStar />
								</div>
							</div>
							<div className="pt-4 md:pb-8">{t("Pricing.sub-header")}</div>
						</div>
						<div className="flex justify-center">
							<PersonBurst />
						</div>
					</div>
				</div>
				<section className="w-[95%] sm:w-[85%] text-center m-auto pt-24 max-w-[1920px]">
					<div>
						<div className="text-xl font-bold">
							{t("Pricing.content.basic-pricing.header")}
						</div>
						<div>{t("Pricing.content.basic-pricing.sub-header")}</div>
						<div className="lg:flex block gap-4 justify-between text-left mt-8">
							{basicPricing.map((item, index) => (
								<div
									key={index}
									className="lg:w-[32%] w-full lg:h-[645px] h-auto pt-4"
								>
									<div className="border-8 border-white hover:border-[#DEEDF0] border-solid rounded-3xl lg:my-0 my-4 flex items-center">
										<div className="border border-slate-300 hover:border-green-600 border-solid rounded-2xl p-6 xl:h-[520px] lg:h-[650px] h-auto w-full">
											<div className="text-base font-bold text-green-950 md:h-[60px] h-auto xl:h-auto">
												{item.header}
											</div>
											<div className="mt-2 mb-8 md:h-[40px] h-[45px] xl:h-[30px] h-auto">
												{item.subHeader}
											</div>
											<div className="text-xl text-green-950 font-bold lg:h-[120px] 2xl:h-auto h-auto">
												{item.price}
											</div>
											<button
												className="bg-green-950 rounded-xl w-full py-2 text-white font-bold cursor-pointer my-8"
												onClick={() => setBookDemoModal(true)}
											>
												{t("button.get-started")}
											</button>
											<div>
												{item.benefit.map((item, index) => (
													<div key={index} className="flex gap-2 my-2">
														<div>
															<TickIcon />
														</div>
														{item}
													</div>
												))}
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="xl:pt-[0px] lg:pt-[120px] pt-8 pb-32">
						<div className="text-xl font-bold">
							{t("Pricing.content.plus-pricing.header")}
						</div>
						<div>{t("Pricing.content.plus-pricing.sub-header")}</div>
						<div className="lg:flex block justify-center gap-8 text-left mt-8">
							{bonusPricing.map((item, index) => (
								<div key={index} className="lg:w-[32%] w-full lg:h-[410px] h-auto">
									<div className="border-8 border-white hover:border-[#DEEDF0] border-solid rounded-3xl lg:my-0 my-4 flex items-center">
										<div className="border border-slate-300 hover:border-green-600 border-solid rounded-2xl p-6 lg:h-[440px] h-auto w-full">
											<div className="text-base font-bold text-green-950">
												{item.header}
											</div>
											<div className="mt-2 md:mb-8 mb-0 xl:h-[20px] lg:h-[40px]">
												{item.subHeader}
											</div>
											<button
												className="bg-green-950 rounded-xl w-full py-2 text-white font-bold cursor-pointer my-8"
												onClick={() => setBookDemoModal(true)}
											>
												{t("button.get-a-quote")}
											</button>
											<div>
												{item.benefit.map((item, index) => (
													<div key={index} className="flex gap-2 my-2">
														<div>
															<TickIcon />
														</div>
														{item}
													</div>
												))}
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</section>
			</main>
		</NewLandingLayout>
	);
};

export default PricingPage;
