import Form from "~/common/components/Form";
import Radio from "~/common/components/Radio";
import Textarea from "~/common/components/Textarea";
import React from "react";
import { useForm } from "react-hook-form";
import { VACATION_CLASSNAME } from "~/common/constants/form";
import { DateWithDayName } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const Modal = ({ setVacationManageModal, selectedVacation, timeText, onSubmit }) => {
	const { t } = useTranslation();
	const vacationManageForm = useForm({
		defaultValues: {
			status: selectedVacation.status,
			memo: selectedVacation.adminMemo,
		},
	});

	const fileClassNameForModal = (vacation) => {
		let result;
		if (vacation.HrVacationType.documents === "pre") {
			if (vacation.HrVacationFiles.length > 0) {
				result = "complete";
			} else {
				result = "no-submit";
			}
		} else if (vacation.HrVacationType.documents === "post") {
			if (vacation.HrVacationFiles.length > 0) {
				result = "complete";
			} else {
				result = "later";
			}
		} else {
			result = "";
		}
		return result;
	};

	return (
		<>
			<div className="modal-content">
				<div className="vacation-state">
					<div
						className={`vacation-name ${
							VACATION_CLASSNAME[selectedVacation.HrVacation.title]
						}`}
					>
						{selectedVacation.startDate === selectedVacation.endDate
							? DateWithDayName(selectedVacation.startDate)
							: `${DateWithDayName(selectedVacation.startDate)}
							   ~ 
							  ${DateWithDayName(selectedVacation.endDate)}`}
					</div>
					<div className="time">{timeText(selectedVacation)}</div>
				</div>
				{/*----증명자료제출*/}
				{selectedVacation.HrVacation.HrVacationType.documents !== "none" && (
					<div className="c__form vacation-manage-form vertical c__file-upload-form">
						{/*제출완료*/}
						{selectedVacation.HrVacation.HrVacationFiles.length > 0 ? (
							<div
								className={`f__group complete ${fileClassNameForModal(
									selectedVacation.HrVacation
								)}`}
							>
								<>
									<div className="f__label">
										{t("HR.Manage.vacationList.ProofDataCheck")}
										<span className="status-badge">
											{t("HR.Manage.vacationList.submit")}
										</span>
									</div>
									{selectedVacation.HrVacation.HrVacationFiles &&
										selectedVacation.HrVacation.HrVacationFiles.map((f) => (
											<div className="f__control">
												<span>
													<a
														href={f.File?.location}
														target={"_blank"}
														rel="noopener noreferrer"
													>
														{f.File?.name}
													</a>
												</span>
											</div>
										))}
								</>
							</div>
						) : (
							<div className="f__group later">
								<div className="f__label">
									{t("HR.Manage.vacationList.ProofDataCheck")}
									<span className="status-badge">
										{t("HR.Manage.vacationList.afterSubmit")}
									</span>
								</div>
							</div>
						)}
					</div>
				)}
				{/*----승인상태----*/}
				<Form useForm={vacationManageForm} className="vacation-manage-form vertical">
					{selectedVacation.HrVacation.HrVacationType.autoConfirm ? (
						<div className="f__group">
							<div className="f__label later">{t("HR.Manage.later")}</div>
							<div className="f__control auto-approval">
								{t("HR.Manage.vacationList.Expected.auto-approval")}
							</div>
						</div>
					) : (
						<>
							{/*{*/}
							{/*    defaultValues.status !== '' &&*/}
							<Radio
								label={t("HR.Manage.later")}
								name="status"
								className="divided3"
								options={[
									{ label: "대기", value: "pending" },
									{ label: "완료", value: "confirm" },
									{ label: "반려", value: "rejected" },
								]}
								boolean
							/>
							{/*}*/}
						</>
					)}
				</Form>
				<Form useForm={vacationManageForm} className="vacation-manage-form vertical">
					<Textarea
						label={t("HR.Manage.user")}
						name="user"
						placeholder={selectedVacation.HrVacation.memo}
						readOnly
					/>
				</Form>
				<Form useForm={vacationManageForm} className="vacation-manage-form vertical">
					<Textarea label={t("HR.Manage.admin-memo")} name="memo" />
				</Form>
			</div>
			{/*}*/}
			<div className="modal-footer">
				<button
					type="button"
					className="btn__solid btn-delete"
					color="gray"
					onClick={() => setVacationManageModal(false)}
				>
					{t("button.cancel")}
				</button>
				<button
					className="btn__solid"
					color="primary"
					onClick={vacationManageForm.handleSubmit(onSubmit)}
				>
					{t("button.check")}
				</button>
			</div>
		</>
	);
};
export default Modal;
