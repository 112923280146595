import React, { useEffect, useState } from "react";
import { PlusIcon } from "~/assets/svg/MathIcon";
import { PencilIcon } from "~/assets/svg/TeamInfoIcon";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import AddVacationModal from "~/pages/vacation/SettingPage/Modal/AddVacationModal";
import ManageVacationModal from "~/pages/vacation/SettingPage/Modal/ManageVacationModal";
import VacationRow from "~/pages/vacation/SettingPage/View/vacationRow";
import { useGlobalStore } from "~/store";
import { clearSelectedVacation } from "~/store/hrSetting/actions";
import { Case, If } from "~/common/components/If";
import AuthButton from "~/common/components/AuthButton";

const List = ({ currentPath, showAuthAlert }) => {
	const [
		{
			hrSetting: {
				selectedVacation: { idx },
			},
		},
		dispatch,
	] = useGlobalStore();
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [vacationTypeList, setVacationTypeList] = useState([]);
	const [modalVacationTypeList, setModalVacationTypeList] = useState([]);
	const [type, setType] = useState("all");
	const vacationTypeListRequest = useRequest(
		"get",
		`vacation/type?size=100&usage=list&page=1&type=${type}`
	);
	const modalVacationTypeListRequest = useRequest(
		"get",
		`vacation/type?size=100&usage=modal&type=custom&page=1`
	);

	const [vacationAddModal, setVacationAddModal] = useState(false);
	const [vacationBlanketModal, setVacationBlanketModal] = useState(false);

	const getVacationTypeList = () => {
		vacationTypeListRequest.call();
	};

	const getModalVacationTypeList = () => {
		modalVacationTypeListRequest.call();
	};

	useEffect(() => {
		getVacationTypeList();
	}, [type]);

	useEffect(() => {
		switch (vacationTypeListRequest.state) {
			case API_STATE.done:
				const responseData = vacationTypeListRequest.response.data;
				setVacationTypeList(responseData.foundVacations);
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error", vacationTypeListRequest.error);
				break;
			default:
				break;
		}
	}, [vacationTypeListRequest.state]);

	useEffect(() => {
		switch (modalVacationTypeListRequest.state) {
			case API_STATE.done:
				const responseData = modalVacationTypeListRequest.response.data;
				setModalVacationTypeList(responseData.foundVacations);
				getVacationTypeList();
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error", modalVacationTypeListRequest.error);
				break;
			default:
				break;
		}
	}, [modalVacationTypeListRequest.state]);

	const filters = [
		{ idx: "all", name: "ALL" },
		{ idx: "mandatory", name: "법정필수휴가" },
		{ idx: "custom", name: "약정휴가" },
	];

	return (
		<>
			<section>
				<div className="list-header">
					<div className="list-title">
						<h4 className="sub-title">회사 휴가 목록</h4>
						<ol className="filter-route">
							{filters.map((filter, index) => {
								return (
									<li
										key={index}
										className={type === filter.idx ? "active" : ""}
										onClick={() => {
											setType(filter.idx);
										}}
									>
										{filter.name}
									</li>
								);
							})}
						</ol>
					</div>
					<div className="btn-group">
						<AuthButton
							type="button"
							className="btn__solid angled"
							color="primary"
							onClick={() => {
								setVacationAddModal(true);
								dispatch(clearSelectedVacation());
							}}
						>
							<PlusIcon />
							추가하기
						</AuthButton>
						<AuthButton
							type="button"
							className="btn__outline angled"
							color="primary"
							onClick={async () => {
								getModalVacationTypeList();
								await setVacationBlanketModal(true);
							}}
						>
							<PencilIcon />
							일괄설정
						</AuthButton>
					</div>
				</div>
				<If condition={pageState}>
					<Case condition={PAGE_STATE.LOADING}>
						<Loading />
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						<div className="vacation-list">
							<VacationRow
								{...{
									vacationTypeList,
									pageState,
									setVacationAddModal,
									currentPath,
									showAuthAlert,
								}}
							/>
						</div>
					</Case>
				</If>
			</section>
			{/*추가하기 모달*/}
			<ModalTemplate
				modalState={vacationAddModal}
				setModalToggle={setVacationAddModal}
				title={`회사 휴가정책 ${idx !== "" ? "수정 " : "생성"}`}
				width={550}
				className="vacation-add-modal"
				closeable={false}
			>
				<AddVacationModal
					{...{ setVacationAddModal, getVacationTypeList, getModalVacationTypeList }}
				/>
			</ModalTemplate>
			{/*일괄설정 모달*/}
			<ModalTemplate
				modalState={vacationBlanketModal}
				setModalToggle={setVacationBlanketModal}
				title="휴가 정책 관리"
				width={580}
				className="vacation-blanket-modal"
				closeable={false}
			>
				<ManageVacationModal
					{...{
						modalVacationTypeList,
						setVacationAddModal,
						getVacationTypeList,
						getModalVacationTypeList,
					}}
				/>
			</ModalTemplate>
		</>
	);
};

export default List;
