import React from "react";
import { HelmetProvider } from "react-helmet-async";
import "react-quill/dist/quill.snow.css";
import "../src/assets/sass/reset.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import "../src/assets/sass/index.scss";
import "animate.css/animate.min.css";
// import "react-custom-scroll/dist/customScroll.css";
import "react-perfect-scrollbar/dist/css/styles.css";

import Router from "~/router/";
import { GlobalStoreProvider } from "./store";
import { I18nextProvider } from "react-i18next";
import translations from "~/translations";
import Inspection from "~/pages/inspection";

const App = () => {
	return (
		<>
			<HelmetProvider>
				<GlobalStoreProvider>
					<I18nextProvider i18n={translations}>
						{/*<Error />*/}
						<Router />
						{/* <Inspection /> */}
					</I18nextProvider>
				</GlobalStoreProvider>
			</HelmetProvider>
		</>
	);
};

export default App;
