import Form from "~/common/components/Form";
import FileUploader from "~/common/components/FileUploader";
import Textarea from "~/common/components/Textarea";
import React from "react";
import { useForm } from "react-hook-form";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import { VACATION_CLASSNAME } from "~/common/constants/form";
import { DateWithDayName } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const Modal = ({
	selectedVacation,
	setVacationManageModal,
	timeText,
	previousVacationsRequest,
	vacationStatusRequest,
	info,
	pageNumber,
	year,
}) => {
	const { t } = useTranslation();
	const HrVacation = selectedVacation.HrVacation;

	const submit = (payload) => {
		vacationStatusRequest
			.asyncCall(payload, "body")
			.then(() =>
				previousVacationsRequest.call(
					{
						page: pageNumber,
						size: 10,
						userIdx: info.idx,
						year: year,
					},
					"queryString"
				)
			)
			.catch((e) => console.log(e));
		setVacationManageModal(false);
	};

	const ClickSubmit = (data) => {
		const payload = {
			file: data.file || "",
			dateAndTimeIdx: selectedVacation.idx,
			memo: data.memo,
		};
		submit(payload);
	};

	const modalForm = useForm({
		defaultValues: {
			memo: HrVacation.memo,
			admin: selectedVacation.adminMemo,
		},
	});
	return (
		<>
			<div className="modal-content">
				<div className="vacation-state">
					<div className={`vacation-name ${VACATION_CLASSNAME[HrVacation.title]}`}>
						{selectedVacation.startDate === selectedVacation.endDate
							? DateWithDayName(selectedVacation.startDate)
							: `${DateWithDayName(selectedVacation.startDate)}
							   ~ 
							  ${DateWithDayName(selectedVacation.endDate)}`}
					</div>
					<div className="time">{timeText(selectedVacation)}</div>
				</div>
				{HrVacation.HrVacationType.documents !== "none" && (
					<Form
						useForm={modalForm}
						className="vacation-manage-form vertical c__file-upload-form"
					>
						{HrVacation.HrVacationFiles.length > 0 ? (
							<div className="f__group complete">
								<label className="f__label">
									{t("HR.Manage.vacationList.ProofDataSubmit")}{" "}
									<span className="status-badge">
										{t("HR.Manage.vacationList.submit")}
									</span>
								</label>
								{HrVacation.HrVacationFiles &&
									HrVacation.HrVacationFiles.map((f) => (
										<div className="f__control">
											<span>
												<a
													href={f.File?.location}
													target={"_blank"}
													rel="noopener noreferrer"
												>
													{f.File?.name}
												</a>
											</span>
										</div>
									))}
							</div>
						) : (
							<FileUploader
								label={
									<>
										{t("HR.Manage.vacationList.ProofDataSubmit")}
										<span className="status-badge">
											{t("HR.Manage.vacationList.notSubmit")}
										</span>
									</>
								}
								name="file"
								className="no-submit"
								type={UPLOAD_TYPE.HR_VACATION}
							/>
						)}
					</Form>
				)}
				<Form useForm={modalForm} className="vacation-manage-form vertical">
					<Textarea label={t("HR.Manage.admin")} name="admin" readOnly />
				</Form>
				<Form useForm={modalForm} className="vacation-manage-form vertical">
					<Textarea label={t("HR.Manage.user")} name="memo" />
				</Form>
			</div>
			<div className="modal-footer">
				<button
					className="btn__solid"
					color="primary"
					onClick={modalForm.handleSubmit(ClickSubmit)}
				>
					{t("button.check")}
				</button>
			</div>
		</>
	);
};
export default Modal;
