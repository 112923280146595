import { useForm } from "react-hook-form";
import Form from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import React from "react";
import { VACATION_CLASSNAME } from "~/common/constants/form";
import { useTranslation } from "react-i18next";

const Modal = ({
	selectedHistory,
	setVacationAllManageModal,
	eventTextMap,
	titleText,
	timeText,
}) => {
	const { t } = useTranslation();
	const vacationAllManageForm = useForm({ defaultValues: { memo: selectedHistory.memo } });
	const eventsWithoutDateAndTime = ["Vplus", "Vminus", "autoVplus", "reset", "policyChange"];

	return (
		<>
			<div className="modal-content">
				{eventsWithoutDateAndTime.includes(selectedHistory.event) ? (
					<div className="vacation-state">
						<div
							className={`history ${
								selectedHistory.event === "Vminus" ? "minus" : "plus"
							}`}
						>
							{eventTextMap(selectedHistory)[selectedHistory.event]()}
						</div>
					</div>
				) : (
					<div className="vacation-state">
						<div
							className={`vacation-name ${
								VACATION_CLASSNAME[
									titleText(selectedHistory.HrVacationDateAndTime)
								] ?? "others"
							}`}
						>
							{titleText(selectedHistory)}
						</div>
						<div className="time">
							{timeText(selectedHistory.HrVacationDateAndTime)}
						</div>
					</div>
				)}

				<Form useForm={vacationAllManageForm} className="vacation-manage-form vertical">
					<Textarea label={t("HR.Manage.admin")} name="memo" readOnly />
				</Form>
			</div>
			<div className="modal-footer">
				<button
					className="btn__solid"
					color="primary"
					onClick={() => setVacationAllManageModal(false)}
				>
					{t("button.check")}
				</button>
			</div>
		</>
	);
};
export default Modal;
