import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import NewLandingLayout from "~/layouts/LandingLayout/index";
import {
	PersonBeard,
	PersonWhiteHair,
	DocIcon,
	PeopleStarIcon,
	BagIcon,
	DollarIcon,
} from "~/assets/svg/WhyUsIcon";
import EmpowerGlobal from "~/common/components/EmpowerGlobal";
import NumbersProved from "~/common/components/NumbersProved";
import testimonial1 from "~/assets/img/persona/landingPage/testimonials1.png";
import testimonial2 from "~/assets/img/persona/landingPage/testimonials2.png";
import { FiveStar } from "~/assets/svg/LandingIcon";
import SlideHorizontal from "~/common/components/SlideHorizontal";

const WhyUsPage = () => {
	const { t } = useTranslation();
	const testimonialItems = [
		{
			name: t("WhyUs.testimonials.1.name"),
			position: t("WhyUs.testimonials.1.position"),
			image: testimonial2,
			testimonial: t("WhyUs.testimonials.1.text"),
		},
		{
			name: t("WhyUs.testimonials.2.name"),
			position: t("WhyUs.testimonials.2.position"),
			image: testimonial1,
			testimonial: t("WhyUs.testimonials.2.text"),
		},
	];

	return (
		<NewLandingLayout>
			<main className="h-full w-full max-w-[1920px] mx-auto">
				<section className="bg-[#DEEDF0]">
					<div className="w-[95%] sm:w-[85%] m-auto pt-48 md:flex block justify-between">
						<div className="lg:flex block justify-between w-full">
							<div className="lg:block hidden">
								<PersonBeard />
							</div>
							<div className="text-center mt-[-50px] md:mb-0 mb-16">
								<div className="text-[#0D9488] font-semibold">
									{t("WhyUs.header-green")}
								</div>
								<div className="sm:text-4xl text-3xl font-semibold w-full">
									{t("WhyUs.header")}
								</div>
								<div className="pt-4 m lg:mb-8 sm:mb-0">
									{t("WhyUs.sub-header")}
								</div>
							</div>
							<div className="flex justify-center">
								<div className="lg:hidden block">
									<PersonBeard />
								</div>
								<div className="">
									<PersonWhiteHair />
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="w-[95%] sm:w-[85%] m-auto">
					<div className="md:py-32 py-16">
						<div className="text-[#0D9488] font-semibold text-center">
							{t("WhyUs.solutions.header-green")}
						</div>
						<div className="sm:text-4xl text-3xl font-semibold text-center">
							{t("WhyUs.solutions.header")}
						</div>
						<SlideHorizontal
							props={[
								{
									icon: <DocIcon />,
									title: t("WhyUs.solutions.1.title"),
									description: t("WhyUs.solutions.1.description"),
								},
								{
									icon: <PeopleStarIcon />,
									title: t("WhyUs.solutions.2.title"),
									description: t("WhyUs.solutions.2.description"),
								},
								{
									icon: <BagIcon />,
									title: t("WhyUs.solutions.3.title"),
									description: t("WhyUs.solutions.3.description"),
								},
								{
									icon: <DollarIcon />,
									title: t("WhyUs.solutions.4.title"),
									description: t("WhyUs.solutions.4.description"),
								},
								{
									icon: <DocIcon />,
									title: t("WhyUs.solutions.5.title"),
									description: t("WhyUs.solutions.5.description"),
								},
							]}
						/>
					</div>
				</section>
				<section className="mx-auto max-w-[1920px] bg-[#042F2E] text-white">
					<div className="mx-auto max-w-[1920px] w-[85%] sm:w-[95%]">
						<div>
							<span className="flex justify-center pb-8 pt-24 font-semibold">
								{t("WhyUs.testimonials.header-small")}
							</span>
							<h1 className="text-white text-2xl pb-16 text-center">
								{t("WhyUs.testimonials.header")}
							</h1>
						</div>
						<div className="xl:flex block gap-16 pb-16 md:w-[70%] w-[95%] m-auto">
							{testimonialItems.map((item, index) => (
								<div
									key={index}
									className="bg-[#DEEDF0] mb-8 xl:mb-0 p-6 rounded-tl-2xl rounded-br-2xl w-full text-[#090909]"
								>
									<div className="text-justify text-sm 2xl:h-[110px] xl:h-[150px]">
										{item.testimonial}
									</div>
									<div className="xl:flex block justify-between items-end">
										<div className="flex gap-2 xl:mt-0 mt-2">
											<img src={item.image} className="h-[40px] w-[40px]" />
											<div>
												<div>{item.name}</div>
												<div>{item.position}</div>
											</div>
										</div>
										<div className="2xl:mt-0 mt-4">
											<FiveStar />
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</section>
				{/* <NumbersProved /> */}
				<EmpowerGlobal />
			</main>
		</NewLandingLayout>
	);
};

export default WhyUsPage;
