import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import translation from "~/translations";
import i18next from "i18next";

// Components
import useAlert from "~/common/hooks/useAlert";

// Assets
import { AccordionArrowIcon } from "~/assets/svg/LandingIcon";

// ko img
import stInvest from "~/assets/img/newLanding/st-invest.png";
import stRound from "~/assets/img/newLanding/st-round.png";
import stHr from "~/assets/img/newLanding/st-hr.png";
import stOkceo from "~/assets/img/newLanding/st-okceo.png";
import stIr from "~/assets/img/newLanding/st-ir.png";
import stShareholder from "~/assets/img/newLanding/st-shareholder.png";
import vcDealSourcing from "~/assets/img/newLanding/vc-dealSourcing.png";
import vcPortfolio from "~/assets/img/newLanding/vc-portfolio.png";
import vcRound from "~/assets/img/newLanding/vc-round.png";
import vcApply from "~/assets/img/newLanding/vc-apply.png";
import vcApply2 from "~/assets/img/newLanding/vc-apply2.png";
import vcConnect from "~/assets/img/newLanding/vc-connect.png";

// en img
import enStInvest from "~/assets/img/newLanding/EN/st-invest_en.png";
import enStRound from "~/assets/img/newLanding/EN/st-round_en.png";
import enStHr from "~/assets/img/newLanding/EN/st-hr_en.png";
import enStOkceo from "~/assets/img/newLanding/EN/st-okceo_en.png";
import enStIr from "~/assets/img/newLanding/EN/st-ir_en.png";
import enStShareholder from "~/assets/img/newLanding/EN/st-shareholder_en.png";
import enVcDealSourcing from "~/assets/img/newLanding/EN/vc-dealSourcing_en.png";
import enVcPortfolio from "~/assets/img/newLanding/EN/vc-portfolio_en.png";
import enVcRound from "~/assets/img/newLanding/EN/vc-round_en.png";
import enVcApply from "~/assets/img/newLanding/EN/vc-apply_en.png";
import enVcApply2 from "~/assets/img/newLanding/EN/vc-apply2_en.png";
import enVcConnect from "~/assets/img/newLanding/EN/vc-connect_en.png";

const AccordionSection = () => {
	const { t } = useTranslation();
	const langImg = i18next.language;
	const [stAccordionImg, setStAccordionImg] = useState(langImg === "ko" ? stInvest : enStInvest);
	const [stAccordionAlt, setStAccordionAlt] = useState(
		translation.t("Landing.Section.AccordionSection.stMenuItem.invest.img")
	);
	const [vcAccordionImg, setVcAccordionImg] = useState(
		langImg === "ko" ? vcDealSourcing : enVcDealSourcing
	);
	const [vcAccordionAlt, setVcAccordionAlt] = useState(
		translation.t("Landing.Section.AccordionSection.stMenuItem.invest.img")
	);

	const [stActiveIdx, setStActiveIdx] = useState(0);
	const [vcActiveIdx, setVcActiveIdx] = useState(0);

	useEffect(() => {
		setStAccordionImg(
			langImg === "ko" ? stMenuItem[stActiveIdx].src : stMenuItem[stActiveIdx].enSrc
		);
		setStAccordionAlt(stMenuItem[stActiveIdx].alt);
	}, [langImg]);

	useEffect(() => {
		setVcAccordionImg(
			langImg === "ko" ? vcMenuItem[vcActiveIdx].src : vcMenuItem[vcActiveIdx].enSrc
		);
		setVcAccordionAlt(vcMenuItem[vcActiveIdx].alt);
	}, [langImg]);

	const [isApplyMenu, setIsApplyMenu] = useState(false);

	const accordionToggle = (i, type) => {
		switch (type) {
			case "st":
				if (stAccordionImg !== i && stAccordionAlt) {
					setStAccordionImg(langImg === "ko" ? stMenuItem[i].src : stMenuItem[i].enSrc);
					setStAccordionAlt(stMenuItem[i].alt);
				}
				break;
			case "vc":
				if (vcAccordionImg !== i && vcAccordionAlt) {
					setVcAccordionImg(langImg === "ko" ? vcMenuItem[i].src : vcMenuItem[i].enSrc);
					setVcAccordionAlt(vcMenuItem[i].alt);
				}
				break;
		}
	};

	const stMenuItem = [
		{
			key: "invest",
			title: translation.t("Landing.Section.AccordionSection.stMenuItem.invest.title"),
			desc: translation.t("Landing.Section.AccordionSection.stMenuItem.invest.desc"),
			disabled: false,
			src: stInvest,
			enSrc: enStInvest,
			alt: translation.t("Landing.Section.AccordionSection.stMenuItem.invest.img"),
		},
		{
			key: "stround",
			title: translation.t("Landing.Section.AccordionSection.stMenuItem.stround.title"),
			desc: translation.t("Landing.Section.AccordionSection.stMenuItem.stround.desc"),
			disabled: false,
			src: stRound,
			enSrc: enStRound,
			alt: translation.t("Landing.Section.AccordionSection.stMenuItem.stround.img"),
		},
		{
			key: "hr",
			title: translation.t("Landing.Section.AccordionSection.stMenuItem.hr.title"),
			desc: translation.t("Landing.Section.AccordionSection.stMenuItem.hr.desc"),
			disabled: false,
			src: stHr,
			enSrc: enStHr,
			alt: translation.t("Landing.Section.AccordionSection.stMenuItem.hr.img"),
		},
		{
			key: "okceo",
			title: translation.t("Landing.Section.AccordionSection.stMenuItem.okceo.title"),
			desc: translation.t("Landing.Section.AccordionSection.stMenuItem.okceo.desc"),
			disabled: false,
			src: stOkceo,
			enSrc: enStOkceo,
			alt: translation.t("Landing.Section.AccordionSection.stMenuItem.okceo.img"),
		},
		{
			key: "ir",
			title: translation.t("Landing.Section.AccordionSection.stMenuItem.ir.title"),
			desc: translation.t("Landing.Section.AccordionSection.stMenuItem.ir.desc"),
			disabled: false,
			src: stIr,
			enSrc: enStIr,
			alt: translation.t("Landing.Section.AccordionSection.stMenuItem.ir.img"),
		},
		{
			key: "shareholder",
			title: translation.t("Landing.Section.AccordionSection.stMenuItem.shareholder.title"),
			desc: translation.t("Landing.Section.AccordionSection.stMenuItem.shareholder.desc"),
			disabled: false,
			src: stShareholder,
			enSrc: enStShareholder,
			alt: translation.t("Landing.Section.AccordionSection.stMenuItem.shareholder.img"),
		},
	];

	const vcMenuItem = [
		{
			key: "dealsourcing",
			title: translation.t("Landing.Section.AccordionSection.vcMenuItem.dealsourcing.title"),
			desc: translation.t("Landing.Section.AccordionSection.vcMenuItem.dealsourcing.desc"),
			disabled: false,
			src: vcDealSourcing,
			enSrc: enVcDealSourcing,
			alt: translation.t("Landing.Section.AccordionSection.vcMenuItem.dealsourcing.img"),
		},
		{
			key: "portfolio",
			title: translation.t("Landing.Section.AccordionSection.vcMenuItem.portfolio.title"),
			desc: translation.t("Landing.Section.AccordionSection.vcMenuItem.portfolio.desc"),
			disabled: false,
			src: vcPortfolio,
			enSrc: enVcPortfolio,
			alt: translation.t("Landing.Section.AccordionSection.vcMenuItem.portfolio.img"),
		},
		{
			key: "vcround",
			title: translation.t("Landing.Section.AccordionSection.vcMenuItem.vcround.title"),
			desc: translation.t("Landing.Section.AccordionSection.vcMenuItem.vcround.desc"),
			disabled: false,
			src: vcRound,
			enSrc: enVcRound,
			alt: translation.t("Landing.Section.AccordionSection.vcMenuItem.vcround.img"),
		},
		{
			key: "apply",
			title: translation.t("Landing.Section.AccordionSection.vcMenuItem.apply.title"),
			desc: translation.t("Landing.Section.AccordionSection.vcMenuItem.apply.desc"),
			disabled: false,
			src: vcApply,
			enSrc: enVcApply,
			alt: translation.t("Landing.Section.AccordionSection.vcMenuItem.apply.img"),
		},
		{
			key: "connect",
			title: translation.t("Landing.Section.AccordionSection.vcMenuItem.connect.title"),
			desc: translation.t("Landing.Section.AccordionSection.vcMenuItem.connect.desc"),
			disabled: false,
			src: vcConnect,
			enSrc: enVcConnect,
			alt: translation.t("Landing.Section.AccordionSection.vcMenuItem.connect.img"),
		},
	];

	const [showAlert, Alert] = useAlert();

	return (
		<>
			<section className="accordion-section mb-160">
				<div className="wrap">
					{/*<div className="accordion-wrap">*/}
					{/*	<div className="img-wrap">*/}
					{/*		{stAccordionImg && <img src={stAccordionImg} alt={stAccordionAlt} />}*/}
					{/*	</div>*/}
					{/*	<div className="accordion-menu">*/}
					{/*		<h4 className="title">*/}
					{/*			{t("Landing.Section.AccordionSection.accordion.st.title")}*/}
					{/*		</h4>*/}
					{/*		<ul className="accordion">*/}
					{/*			{stMenuItem.map((item, i) => {*/}
					{/*				return (*/}
					{/*					<li*/}
					{/*						key={i}*/}
					{/*						className={`accordion-item ${*/}
					{/*							stActiveIdx === i ? "active" : ""*/}
					{/*						} ${item.disabled ? "disabled" : ""}`}*/}
					{/*						onClick={() => {*/}
					{/*							!item.disabled && accordionToggle(i, "st");*/}
					{/*							setStActiveIdx(i);*/}
					{/*						}}*/}
					{/*					>*/}
					{/*						<div className="accordion-head">*/}
					{/*							{item.title}*/}
					{/*							<AccordionArrowIcon />*/}
					{/*						</div>*/}
					{/*						<p*/}
					{/*							className={`accordion-content ${*/}
					{/*								stActiveIdx === i ? "active" : "inactive"*/}
					{/*							}`}*/}
					{/*						>*/}
					{/*							{item.desc}*/}
					{/*						</p>*/}
					{/*					</li>*/}
					{/*				);*/}
					{/*			})}*/}
					{/*		</ul>*/}
					{/*		<button*/}
					{/*			type="button"*/}
					{/*			className="btn-accordion-more"*/}
					{/*			onClick={showAlert}*/}
					{/*		>*/}
					{/*			{t("Landing.Section.AccordionSection.accordion.st.button")}*/}
					{/*		</button>*/}
					{/*	</div>*/}
					{/*</div>*/}
					<div className="accordion-wrap">
						<div className="accordion-menu">
							<h4 className="title">
								{t("Landing.Section.AccordionSection.accordion.vc.title")}
							</h4>
							<ul className="accordion">
								{vcMenuItem.map((item, i) => {
									return (
										<li
											className={`accordion-item ${
												vcActiveIdx === i ? "active" : ""
											} ${item.disabled ? "disabled" : ""}`}
											onClick={() => {
												!item.disabled && accordionToggle(i, "vc");
												setVcActiveIdx(i);
												setIsApplyMenu(item.key === "apply");
											}}
											key={i}
										>
											<div className="accordion-head">
												{item.title}
												<AccordionArrowIcon />
											</div>
											<p
												className={`accordion-content ${
													vcActiveIdx === i ? "active" : "inactive"
												}`}
											>
												{item.desc}
											</p>
										</li>
									);
								})}
							</ul>
							<button
								type="button"
								className="btn-accordion-more"
								onClick={showAlert}
							>
								{t("Landing.Section.AccordionSection.accordion.vc.button")}
							</button>
						</div>
						<div className={`img-wrap ${isApplyMenu ? "apply" : ""}`}>
							{vcAccordionImg && <img src={vcAccordionImg} alt={vcAccordionAlt} />}
							{isApplyMenu && (
								<div className="sub-img">
									<img
										src={langImg === "ko" ? vcApply2 : enVcApply2}
										alt={t("Landing.Section.AccordionSection.accordion.vc.alt")}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</section>

			{/*준비중 alert 모달*/}
			<Alert
				message={t("Landing.Section.AccordionSection.accordion.Alert")}
				confirm={t("button.check")}
				type="info"
			/>
		</>
	);
};

export default AccordionSection;
