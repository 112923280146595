import React from "react";
import { useTranslation } from "react-i18next";
import {
 BlinkStar,
 TickIcon,
} from "~/assets/svg/LandingIcon";

export default function NumbersProved () {
	const { t } = useTranslation();

 const numbersProved = [
		{
			number: t("Landing.Section.numbers-proved.1.number"),
			description: t("Landing.Section.numbers-proved.1.description"),
		},
		{
			number: t("Landing.Section.numbers-proved.2.number"),
			description: t("Landing.Section.numbers-proved.2.description"),
		},
		{
			number: t("Landing.Section.numbers-proved.3.number"),
			description: t("Landing.Section.numbers-proved.3.description"),
		},
	];
 return (
  <section className="bg-[#DEEDF0] mx-auto max-w-[1920px]">
  <div className="mx-auto max-w-[1920px] w-[95%] sm:w-[85%] text-center md:py-32 py-16">
   <h1 className="text-green-950 text-2xl pb-8">
    {t("Landing.Section.numbers-proved.heading")}
   </h1>
   <div className="md:flex block justify-center gap-16">
    <div className="m-auto md:block hidden">
     <BlinkStar />
    </div>
    {numbersProved.map((item, index) => (
     <div className="mt-8" key={index}>
      <div className="text-[#0D9488] text-4xl font-bold">
       {item.number}
      </div>
      <div>{item.description}</div>
     </div>
    ))}
    <div className="m-auto md:block hidden">
     <BlinkStar />
    </div>
   </div>
  </div>
 </section>
 )
}