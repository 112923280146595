import { useState } from "react";
import gitbalApiService from "~/utils/gitbalApiService";
import emApiService from "~/utils/emApiService";
import okceoApiService from "~/utils/okceoApiService";
import storageService from "~/utils/storage";
import { API_STATE } from "../constants/state";
import * as Sentry from "@sentry/react";

/**
 * @param {"get"|"post"|"put"|"patch"|"delete"} method HTTP methods
 * @param {string} url
 * @param {object} body
 * @param {object} endpoint
 */
const useRequest = (method, url, endpoint = "gitbal") => {
	const [state, setState] = useState(API_STATE.ready);
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(null);

	const token = storageService.get("auth", "accessToken", "local");
	const okceoToken = storageService.get("okceo.auth", "accessToken", "local");

	const getUrl = (url, param, type) => {
		switch (type) {
			case "queryString":
				const paramList = Object.keys(param).reduce((acc, key) => {
					const value = param[key];
					return acc.concat(`${key}=${value}`);
				}, []);
				return `${url}/?${paramList.join("&")}`;
			case "path":
				return `${url}/${param}`;
			default:
				return url;
		}
	};

	const emGetUrl = (url, param, type) => {
		switch (type) {
			case "queryString":
				const paramList = Object.keys(param).reduce((acc, key) => {
					const value = param[key];
					return acc.concat(`${key}=${value}`);
				}, []);
				return `${url}?${paramList.join("&")}`;
			case "path":
				return `${url}${param}`;
			default:
				return url;
		}
	};

	const request = (param = {}, type = "body") => {
		const apiService =
			endpoint === "em"
				? emApiService
				: endpoint === "okceo"
				? okceoApiService
				: gitbalApiService;
		apiService.setBaererToken(endpoint === "okceo" ? okceoToken : token);
		const requestUrl =
			endpoint === "em" ? emGetUrl(url, param, type) : getUrl(url, param, type);
		const req = apiService[method](
			requestUrl,
			type === "body" && param,
			type === "header" && { headers: param }
		);
		return req;
	};

	/**
	 * @param {Object} param
	 * @param {"queryString"|"path"|"body"} type
	 */
	const call = (param, type) => {
		if (state === API_STATE.loading) return;
		setState(API_STATE.loading);
		request(param, type)
			.then((res) => {
				setResponse(res.data);
				setState(API_STATE.done);
			})
			.catch((e) => {
				const errorObject = e.response ? e.response.data : e;
				Sentry.captureException(e);
				setError(errorObject);
				setState(API_STATE.error);
			});
	};

	/**
	 * @param {Object} param
	 * @param {"queryString"|"path"|"body"} type
	 */
	const asyncCall = async (param, type) => {
		return new Promise(async (resolve, reject) => {
			if (state === API_STATE.loading) return;
			setState(API_STATE.loading);
			request(param, type)
				.then((res) => {
					setResponse(res.data);
					setState(API_STATE.done);
					resolve(res.data);
				})
				.catch((e) => {
					const errorObject = e.response ? e.response.data : e;
					Sentry.captureException(e);
					setError(errorObject);
					setState(API_STATE.error);
					reject(errorObject);
				});
		});
	};

	/**
	 * @param {Object} param
	 * @param {"queryString"|"path"|"body"} type
	 */
	const singleCall = (param, type) => {
		if (state !== API_STATE.ready) return;
		setState(API_STATE.loading);
		request(param, type)
			.then((res) => {
				setResponse(res.data);
				setState(API_STATE.done);
			})
			.catch((e) => {
				const errorObject = e.response ? e.response.data : e;
				Sentry.captureException(e);
				setError(errorObject);
				setState(API_STATE.error);
			});
	};

	/**
	 * @param {Object[]} listOfParam
	 * @param {"queryString"|"path"|"body"} type
	 */
	const multiCall = (listOfParam, type) => {
		return new Promise(async (resolve, reject) => {
			if (state === API_STATE.loading) return;
			setState(API_STATE.loading);
			const promiseList = listOfParam.map((param) => {
				return new Promise(async (resolve, reject) => {
					request(param, type)
						.then((res) => {
							resolve(res.data);
						})
						.catch((e) => {
							const errorObject = e.response ? e.response.data : e;
							reject(errorObject);
						});
				});
			});
			Promise.all(promiseList)
				.then((d) => {
					setResponse(d);
					setState(API_STATE.done);
					resolve(d);
				})
				.catch((e) => {
					setError(e);
					Sentry.captureException(e);
					setState(API_STATE.error);
					reject(e);
				});
		});
	};

	return { call, asyncCall, singleCall, multiCall, state, response, error };
};

export default useRequest;
