import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import AlertTemplate from "~/common/components/AlertPortal/AlertTemplate";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const Change = ({ setStep }) => {
	const { t } = useTranslation();
	const [successToggle, setSuccessToggle] = useState(false);

	const changePwForm = useForm();
	const currentPassword = changePwForm.watch("pw");

	const changeRequest = useRequest("post", "user/pw/change");

	const request = (data) => {
		changeRequest.call({ pw: data.pw });
	};

	useEffect(() => {
		switch (changeRequest.state) {
			case API_STATE.done:
				setSuccessToggle(true);
				break;
			case API_STATE.error:
				console.log("error", changeRequest.error);
				alert(t("Alert.alert1"));
				break;
			default:
				break;
		}
	}, [changeRequest.state, changeRequest.response, changeRequest.error]);

	return (
		<>
			<div className="c__card">
				<Form useForm={changePwForm}>
					<Input
						label={t("MyPage.PasswordPage.newPw")}
						type={"password"}
						name={"pw"}
						placeholder={t("MyPage.PasswordPage.pwPlaceholder")}
						description={t("MyPage.PasswordPage.pwDescription")}
						autoComplete="new-password"
						validation={{
							required: translation.t("MyPage.PasswordPage.pwRequired"),
							pattern: {
								value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/,
								message: translation.t("MyPage.PasswordPage.pwMessage"),
							},
						}}
					/>
					<Input
						label={t("MyPage.PasswordPage.newPwCheck")}
						placeholder={t("MyPage.PasswordPage.newPwPlaceholder")}
						name="rePw"
						type="password"
						description={t("MyPage.PasswordPage.newPwdDescription")}
						autoComplete="new-password"
						validation={{
							required: translation.t("Validate.require"),
							validate: (value) =>
								value === currentPassword ||
								translation.t("MyPage.PasswordPage.validatePw"),
						}}
					/>
				</Form>
				<div className="btn-wrap">
					<button
						className="btn__solid"
						color="primary"
						onClick={changePwForm.handleSubmit(request)}
					>
						{t("button.Save")}
					</button>
				</div>
			</div>
			<AlertTemplate
				alertModalState={successToggle}
				setAlertModalToggle={setSuccessToggle}
				className="pw-alert"
			>
				<p className="alert-content">{t("MyPage.PasswordPage.pwAlert")}</p>
				<div className="alert-footer">
					<button
						className="btn__solid"
						color="primary"
						onClick={() => {
							setSuccessToggle(false);
							setStep("validate");
						}}
					>
						{t("button.check")}
					</button>
				</div>
			</AlertTemplate>
		</>
	);
};

export default Change;
