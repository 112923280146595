import React from "react";

const PropsProvider = ({ children, props }) => {
	if (typeof children?.type === "string" || typeof children !== "object") {
		return <>{children}</>;
	} else {
		return React.Children.map(children, (child) => {
			if (child && typeof child.type !== "string") {
				return <>{React.cloneElement(child, props)}</>;
			} else if (child !== null) {
				return <>{child}</>;
			} else {
				return <></>;
			}
		});
	}
};

export default PropsProvider;
// FIXME: provided props보다 선언시 설정한 props를 우선으로
