import React, { useState } from "react";
import { useTranslation } from "react-i18next";

//Assets
import "~/assets/sass/pages/hr/modal/orgAdd.scss";
import { ModifyIcon } from "~/assets/svg/TeamInfoIcon";

export const OrgInput = ({ setModalToggle, handleSubmit, children, defaultValue }) => {
	const { t } = useTranslation();
	const [input, setInput] = useState(defaultValue ? defaultValue : "");
	const isEmpty = input === "";

	const submitAndClose = () => {
		handleSubmit(input);
		setModalToggle(false);
	};
	return (
		<>
			<div className="modal-content">
				<div className="f__group">
					<div className="f__control">
						<ModifyIcon />
						<input
							className="c__input f__item"
							placeholder={t("HR.TeamInfo.modal.orgInput.inputPlaceholder2")}
							value={input}
							onChange={(e) => setInput(e.target.value)}
							onKeyDown={(e) => e.key === "Enter" && !isEmpty && submitAndClose()}
						/>
					</div>
				</div>
				{children}
			</div>
			<div className="modal-footer">
				<button
					disabled={isEmpty}
					key="submit"
					className="btn__solid"
					color="primary"
					onClick={() => submitAndClose()}
				>
					{t("button.check")}
				</button>
				<button
					key="cancel"
					className="btn__solid"
					color="gray"
					onClick={() => setModalToggle(false)}
				>
					{t("button.cancel")}
				</button>
			</div>
		</>
	);
};

export default OrgInput;
