import React from "react";
import EmptyCase from "~/common/components/EmptyCase";
import { useTranslation } from "react-i18next";
import AuthButton from "~/common/components/AuthButton";

const Empty = ({ Header, showEditPage }) => {
	const { t } = useTranslation();
	const buttonList = [
		<AuthButton
			type="button"
			className="btn__solid btn-edit"
			color="primary"
			onClick={showEditPage}
		>
			{t("button.registering")}
		</AuthButton>,
	];

	return (
		<>
			<Header buttonList={buttonList} />
			<EmptyCase main={t("Company.Invest.Empty.main")} sub={t("Company.Invest.Empty.sub")} />
		</>
	);
};

export default Empty;
