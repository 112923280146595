import React, { useEffect, useState } from "react";
import DropdownTemplate, {
	DropDownItem,
} from "~/common/components/DropdownPortal/DropdownTemplate";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import { findPropertyValue } from "~/utils/object";
import AlertTemplate from "~/common/components/AlertPortal/AlertTemplate";

//Assets
import { RoundArrowIcon, SearchIcon } from "~/assets/svg/InterfacesIcon";
import defaultAvatar from "~/assets/img/defaultImg/profile-large.png";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const List = ({
	page,
	setPage,
	authMemberList,
	selectUser,
	setSelectUser,
	authMemberListRequest,
	info,
}) => {
	const { t } = useTranslation();
	//선택된 유저 외 클릭 된 유저
	const [clickedUser, setClickedUser] = useState("");
	//관리자 권한 부여 Request
	const setAdminAuthRequest = useRequest("post", "admin/auth/admin");
	//관리자 권한 삭제 Request
	const removeAdminAuthRequest = useRequest("post", "admin/auth/admin/remove");
	//멤버 리스트
	const [searchList, setSearchList] = useState(authMemberList);
	//검색어
	const [searchWord, setSearchWord] = useState("");
	//검색 카테고리
	const [searchCategory, setSearchCategory] = useState("");
	//페이지 최대 명수
	const listPageSize = 7;
	//페이지네이션
	const [listPage, setListPage] = useState(1);
	//단어 검색 리스트
	const searchWordList = findPropertyValue(authMemberList, searchWord, ["name", "id"]);
	//카테고리 검색 리스트
	const searchCategoryList = findPropertyValue(searchWordList, searchCategory, ["role"]);
	//관리자 권한 변경 Alert Modal
	const [adminSelectUser, setAdminSelectUser] = useState({});
	const [adminAuthAlertModal, setAdminAuthAlertModal] = useState(false);
	const [adminAuthAlertMsg, setAdminAuthAlertMsg] = useState(false);
	const [adminAlertState, setAdminAlertState] = useState("");

	//멤버 리스트 페이지네이션
	useEffect(() => {
		setSearchList(
			searchCategoryList.slice(listPageSize * (listPage - 1), listPageSize * listPage)
		);
	}, [searchWord, searchCategory, listPage, authMemberList]);

	//검색 변경 시 페이지 1로 되돌리기
	useEffect(() => {
		setListPage(1);
	}, [searchWord, searchCategory]);

	//이전 페이지
	const clickPrevPage = () => {
		setListPage(listPage - 1);
	};

	//다음 페이지
	const clickNextPage = () => {
		setListPage(listPage + 1);
	};

	//관리자 권한 부여 Request
	const handleSetAdmin = () => {
		setAdminAuthRequest.call(adminSelectUser.idx, "path");
	};

	//관리자 권한 부여 Response
	useEffect(() => {
		switch (setAdminAuthRequest.state) {
			case API_STATE.done:
				setAdminAuthAlertModal(false);
				authMemberListRequest.call();
				setSelectUser(adminSelectUser);
				setPage(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error", setAdminAuthRequest.error);
				alert(t("MyPage.Alert"));
				break;
			default:
				break;
		}
	}, [setAdminAuthRequest.state, setAdminAuthRequest.done, setAdminAuthRequest.error]);

	//관리자 권한 삭제 Request
	const handleRemoveAdmin = () => {
		removeAdminAuthRequest.call(adminSelectUser.idx, "path");
	};

	//관리자 권한 삭제 Response
	useEffect(() => {
		switch (removeAdminAuthRequest.state) {
			case API_STATE.done:
				setAdminAuthAlertModal(false);
				authMemberListRequest.call();
				setSelectUser(adminSelectUser);
				setPage(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error", removeAdminAuthRequest.error);
				alert(t("MyPage.Alert"));
				break;
			default:
				break;
		}
	}, [removeAdminAuthRequest.state, removeAdminAuthRequest.done, removeAdminAuthRequest.error]);

	//user 선택 시
	const handleClick = (user) => {
		if (page === PAGE_STATE.EDIT) {
			if (selectUser.role !== "admin") {
				setClickedUser(user);
				setAdminAuthAlertModal(true);
				setAdminAuthAlertMsg(
					<>
						{selectUser.name}
						{t("MyPage.admin.Auth.btn-cancel")}
					</>
				);
				setAdminAlertState("cancel");
			} else {
				setPage(PAGE_STATE.VIEW);
				setSelectUser(user);
			}
		} else {
			setSelectUser(user);
		}
	};

	return (
		<>
			<div className="c__card role-member-card">
				<div className="top">
					<div className="search-area">
						<input
							className="c__input"
							placeholder="Search"
							onChange={(e) => setSearchWord(e.target.value)}
						/>
						<button type="button" className="btn-search">
							<SearchIcon />
						</button>
					</div>
					<ul className="keyword-list">
						<li
							className={`keyword ${searchCategory === "" ? "active" : ""}`}
							onClick={() => setSearchCategory("")}
						>
							All
						</li>
						<li
							className={`keyword ${searchCategory === "admin" ? "active" : ""}`}
							onClick={() => setSearchCategory("admin")}
						>
							{t("MyPage.Admin")}
						</li>
						<li
							className={`keyword ${searchCategory === "manager" ? "active" : ""}`}
							onClick={() => setSearchCategory("manager")}
						>
							{t("MyPage.Manager")}
						</li>
						<li
							className={`keyword ${searchCategory === "user" ? "active" : ""}`}
							onClick={() => setSearchCategory("user")}
						>
							{t("MyPage.User")}
						</li>
					</ul>
				</div>
				<ul className="role-member-list">
					{searchCategoryList.length > 0 ? (
						searchList.map((d, d_idx) => {
							return (
								<li
									className={`role-member ${
										selectUser.idx === d.idx ? "active" : ""
									}`}
									key={d_idx}
								>
									<div
										className="role-member-content"
										onClick={() => handleClick(d)}
									>
										<div className="role-member-img">
											<img
												src={d.img ? d.img : defaultAvatar}
												onError={(e) => {
													e.target.src = defaultAvatar;
												}}
												alt={d.name}
											/>
										</div>
										<dl className="role-member-info">
											<dt>
												<span className="name">
													{d.name} {d.position}
												</span>
												<span className={"role " + d.role}>
													{d.roleName}
												</span>
											</dt>
											<dd className="email">{d.id}</dd>
										</dl>
									</div>
									{info.idx !== d.idx && (
										<DropdownTemplate target={d.idx} vertical>
											<DropDownItem
												className={d.role === "admin" && "disabled"}
												onClick={() => {
													setAdminSelectUser(d);
													setAdminAuthAlertModal(true);
													setAdminAuthAlertMsg(
														<>
															{d.name}
															{t(
																"MyPage.admin.Auth.List.SetAlertMsg"
															)}
														</>
													);
													setAdminAlertState("set");
												}}
											>
												{t("MyPage.grant")}
											</DropDownItem>
											<DropDownItem
												className={d.role !== "admin" && "disabled"}
												onClick={() => {
													setAdminSelectUser(d);
													setAdminAuthAlertModal(true);
													setAdminAuthAlertMsg(
														<>
															{d.name}
															{t(
																"MyPage.admin.Auth.List.RemoveAlertMsg"
															)}
														</>
													);
													setAdminAlertState("remove");
												}}
											>
												{t("MyPage.remove")}
											</DropDownItem>
										</DropdownTemplate>
									)}
								</li>
							);
						})
					) : (
						<NoData
							className={"role-member-list"}
							main={t("MyPage.admin.Auth.List.NoData")}
						/>
					)}
				</ul>
				<div className="tab-footer">
					<button className="prev-btn" onClick={clickPrevPage} disabled={listPage === 1}>
						<RoundArrowIcon />
						<span>{t("button.prev")}</span>
					</button>
					<button
						className="next-btn"
						onClick={clickNextPage}
						disabled={
							listPage === parseInt(searchCategoryList.length / listPageSize) + 1
						}
					>
						<span>{t("button.next")}</span>
						<RoundArrowIcon />
					</button>
				</div>
			</div>
			<AlertTemplate
				alertModalState={adminAuthAlertModal}
				setAlertModalToggle={setAdminAuthAlertModal}
				alertType={adminAlertState !== "set" && "warning"}
			>
				<p className="alert-content">{adminAuthAlertMsg}</p>
				<div className="alert-footer">
					<button
						className="btn__solid"
						color="gray"
						onClick={() => {
							setAdminAuthAlertModal(false);
							setAdminSelectUser({});
						}}
					>
						{t("button.cancel")}
					</button>
					{adminAlertState === "cancel" ? (
						<button
							className="btn__solid"
							color="primary"
							onClick={() => {
								setAdminAuthAlertModal(false);
								setPage(PAGE_STATE.VIEW);
								setSelectUser(clickedUser !== "" ? clickedUser : selectUser);
							}}
						>
							{t("button.check")}
						</button>
					) : adminAlertState === "set" ? (
						<button className="btn__solid" color="primary" onClick={handleSetAdmin}>
							{t("button.check")}
						</button>
					) : (
						<button className="btn__solid" color="primary" onClick={handleRemoveAdmin}>
							{t("button.check")}
						</button>
					)}
				</div>
			</AlertTemplate>
		</>
	);
};
export default List;
