import React from "react";
import Basic from "~/pages/vc/VcDetail/View/components/basic";
import InvestTypeChart from "~/pages/vc/VcDetail/View/components/investTypeChart";
import InvestmentExecution from "~/pages/vc/VcDetail/View/components/investmentExecution";
import Rounds from "~/pages/vc/VcDetail/View/components/rounds";
import Funds from "~/pages/vc/VcDetail/View/components/funds";
import Portfolio from "~/pages/vc/VcDetail/View/components/portfolio";
import News from "~/pages/vc/VcDetail/View/components/news";
import useAlert from "~/common/hooks/useAlert";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const View = ({ vcInfo, portfolioList, newsList, newsState }) => {
	const { t } = useTranslation();
	const [showAlert, Alert] = useAlert();
	return (
		<div className="c__card info-detail-card">
			<section className="basic-info-section">
				<ul className="basic-info-wrap">
					<Basic {...{ vcInfo }} />
					<InvestTypeChart {...{ vcInfo }} />
					<li className="btn-apply">
						{/*<button type="button" className="btn__solid btn-apply" color="primary">*/}
						{/*	APPLY*/}
						{/*</button>*/}
						{/*apply 신청 대기시 버튼*/}
						<AuthButton
							type="button"
							className="btn__solid"
							color="primary"
							onClick={() => showAlert()}
						>
							{t("VcInfo.VcDetail.btn-apply")}
						</AuthButton>
					</li>
				</ul>
			</section>
			<section className="detail-info-section">
				<ul className="detail-info-wrap">
					<InvestmentExecution />
					<Rounds />
					<Funds />
					<Portfolio {...{ portfolioList }} />
					<News {...{ newsList, newsState }} />
				</ul>
			</section>
			<Alert message={t("VcInfo.VcDetail.Alert")} confirm={t("button.check")} type="info" />
		</div>
	);
};

export default View;
