import React from "react";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import { Outlet } from "react-router";
import translation from "~/translations";

const AttendancePage = () => {
	const MENU = ["hr", "attendance"];
	const TYPE = "main";

	const TAB_LIST = [
		{ id: "my", title: translation.t("HR.Attendance.TAB_LIST.my"), path: "../my" },
		{ id: "team", title: translation.t("HR.Attendance.TAB_LIST.team"), path: "../team" },
	];

	return (
		<>
			<NavbarAndSidebarLayout menu={MENU} type={TYPE} menuName={"schedule"}>
				<Outlet
					context={{
						scheduleTabList: TAB_LIST,
						MENU,
						TYPE,
					}}
				/>
			</NavbarAndSidebarLayout>
		</>
	);
};

export default AttendancePage;
