import React, { useEffect, useState } from "react";
//components
import CustomSelect from "~/common/components/CustomSelect";
//assets
import "~/assets/sass/pages/hr/manage/vacationList.scss";
import { PencilIcon } from "~/assets/svg/TeamInfoIcon";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import { useNavigate } from "react-router-dom";
import MonthRow from "~/pages/vacation/ManagePage/vacationList/Modal/Detail/MonthRow";
import { dateToString, getBetweenYears, signFormatter } from "~/utils/formatter";
import { Trans, useTranslation } from "react-i18next";

const Detail = ({ selectedUser, setVacationDetailModal, vacationTimeCountRequest }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [yearType, setYearType] = useState(new Date().getFullYear());
	const [vacationDetail, setVacationDetail] = useState({});
	const [vacationSetting, setVacationSetting] = useState({});

	const vacationDetailRequest = useRequest(
		"get",
		`vacation/detail/${selectedUser.User.idx}?year=${yearType}`
	);

	const annualVacationSettingsRequest = useRequest(
		"get",
		`vacation/individual/setting/${selectedUser.User.idx}`
	);

	useEffect(() => {
		vacationDetailRequest.call();
	}, [yearType]);

	useEffect(() => {
		annualVacationSettingsRequest.call();
	}, []);

	useEffect(() => {
		switch (annualVacationSettingsRequest.state) {
			case API_STATE.done:
				setVacationSetting(annualVacationSettingsRequest.response.data);
				break;
			default:
				break;
		}
	}, [annualVacationSettingsRequest.state]);

	useEffect(() => {
		switch (vacationDetailRequest.state) {
			case API_STATE.done:
				const response = vacationDetailRequest.response.data;
				setVacationDetail({
					...response,
					annualVacations: response.annualVacations.map((vacation) => {
						return {
							...vacation,
							date: dateToString(vacation.date, "yyyy년 MM월"),
							autoAddDay: signFormatter(vacation.autoAddDay),
							escalationDay: signFormatter(vacation.escalationDay),
						};
					}),
				});
				break;
			case API_STATE.error:
				// navigate("/404");
		}
	}, [vacationDetailRequest.state]);

	return (
		<>
			{/* 연차 상세 현황 모달 */}
			<div className="modal-header">
				<p className="modal-title">
					{t("HR.Manage.vacationList.Modal.Detail.modal-title")}
					<span className="year-tag">
						{vacationDetail.annualVacationPolicy === "입사일기준" ? (
							<>
								<Trans
									i18nKey="HR.Manage.vacationList.Modal.Detail.join"
									components={[<b className="join" />]}
								/>
							</>
						) : (
							<>
								<Trans
									i18nKey="HR.Manage.vacationList.Modal.Detail.accounting"
									components={[<b className="accounting" />]}
								/>
							</>
						)}
					</span>
				</p>
				<div className="year-select">
					<CustomSelect
						value={yearType}
						setValue={setYearType}
						options={getBetweenYears(selectedUser.User.UserProfile.joinDate)}
					/>
				</div>
			</div>
			<div className="modal-content">
				<div className="top">
					<ul className="vacation-list">
						<li className="vacation-item">
							<p className="title">
								{t("HR.Manage.vacationList.Modal.Detail.grant")}
							</p>
							<p className="day grant">
								<b>{vacationDetail.totalAutoAddDay} </b>
								{t("HR.Manage.day")}
							</p>
						</li>
						<li className="vacation-item">
							<p className="title">
								{t("HR.Manage.vacationList.Modal.Detail.forward")}
							</p>
							<p className="day forward">
								<b>{vacationDetail.totalEscalationDay} </b>
								{t("HR.Manage.day")}
							</p>
						</li>
						<li className="vacation-item">
							<p className="title">
								{t("HR.Manage.vacationList.Modal.Detail.disappear")}
							</p>
							<p className="day disappear">
								<b>{vacationDetail.totalDestroyDay} </b>
								{t("HR.Manage.day")}
							</p>
						</li>
						<li className="vacation-item">
							<p className="title">
								{t("HR.Manage.vacationList.Modal.Detail.adjustment")}
							</p>
							<p className="day">
								<b>{vacationDetail.totalFixedDay} </b>
								{t("HR.Manage.day")}
							</p>
						</li>
						<li className="vacation-item">
							<p className="title">{t("HR.Manage.vacationList.Modal.Detail.use")}</p>
							<p className="day">
								<b>{vacationDetail.totalUsedDay} </b>
								{t("HR.Manage.day")}
							</p>
						</li>
					</ul>
				</div>
				<div className="bottom">
					<div className="c__table">
						<div className="thead">
							<div className="tr">
								<div className="th date">
									{t("HR.Manage.vacationList.Modal.Detail.date")}
								</div>
								<div className="th grant">
									{t("HR.Manage.vacationList.Modal.Detail.grant")}
								</div>
								<div className="th forward">
									{t("HR.Manage.vacationList.Modal.Detail.forward")}
								</div>
								<div className="th disappear">
									{t("HR.Manage.vacationList.Modal.Detail.disappear")}
								</div>
								<div className="th adjustment">
									{t("HR.Manage.vacationList.Modal.Detail.adjustment")}{" "}
									<PencilIcon />
								</div>
								<div className="th use">
									{t("HR.Manage.vacationList.Modal.Detail.use")}
								</div>
								<div className="th remain">
									{t("HR.Manage.vacationList.Modal.Detail.remain")}
								</div>
							</div>
						</div>
						<div className="tbody">
							{vacationDetail.annualVacations &&
								vacationDetail.annualVacations.map((vacation, index) => (
									<MonthRow
										{...{
											vacation,
											selectedUser,
											vacationDetailRequest,
											vacationSetting,
											vacationTimeCountRequest,
										}}
										key={index}
									/>
								))}
						</div>
					</div>
					<p className="desc">{t("HR.Manage.vacationList.Modal.Detail.desc")}</p>
				</div>
			</div>
			<div className="modal-footer">
				<button
					className="btn__solid"
					color="primary"
					onClick={() => setVacationDetailModal(false)}
				>
					{t("button.check")}
				</button>
			</div>
		</>
	);
};
export default Detail;
