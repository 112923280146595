import React, { useState } from "react";
import { PAGE_STATE } from "~/common/constants/state";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import Pagination from "~/common/components/Pagination";
import Modal from "~/pages/hr/ManagePage/vacationList/Used/Modal/Modal";
import List from "~/pages/hr/ManagePage/vacationList/Used/List";
import Empty from "~/pages/hr/ManagePage/Empty";
import { Case, If } from "~/common/components/If";
import { VACATION_STATUS, VACATION_STATUS_TEXT } from "~/common/constants/form";
import { getBetweenYears } from "~/utils/formatter";
import CustomSelect from "~/common/components/CustomSelect";
import { useTranslation } from "react-i18next";

const Used = ({
	vacationList,
	setYear,
	year,
	pagingObject,
	pageNumber,
	setPageNumber,
	previousVacationsRequest,
	vacationStatusRequest,
	selectedUser,
	currentPath,
	showAuthAlert,
	pageState,
	titleText,
	timeText,
}) => {
	const { t } = useTranslation();
	const [vacationManageModal, setVacationManageModal] = useState(false);
	const [selectedVacation, setSelectedVacation] = useState({});

	const fileClassName = (vacation) => {
		let result;
		if (vacation.HrVacationType.documents === "pre") {
			if (vacation.HrVacationFiles.length > 0) {
				result = "done";
			} else {
				result = "no-submit";
			}
		} else if (vacation.HrVacationType.documents === "post") {
			if (vacation.HrVacationFiles.length > 0) {
				result = "done";
			} else {
				result = "no-submit";
			}
		} else {
			result = "";
		}
		return result;
	};
	const fileText = (vacation) => {
		switch (fileClassName(vacation)) {
			case "no-submit":
				return "증명자료 미제출";
			case "done":
				return "증명자료 제출 완료";
			case "later":
				return "증명자료 사후 제출";
			case "":
				return "-";
		}
	};

	const onSubmit = async (data) => {
		const payload = {
			...data,
			dateAndTimeIdx: selectedVacation.idx,
		};
		vacationStatusRequest
			.asyncCall(payload, "body")
			.then(() =>
				previousVacationsRequest.call(
					{
						page: pageNumber,
						size: 8,
						userIdx: selectedUser.User.idx,
						year: year,
					},
					"queryString"
				)
			)
			.catch((e) => console.log(e));
		setVacationManageModal(false);
	};

	return (
		<>
			<div className="vacation-chk-header">
				<h3 className="title">{t("HR.Manage.vacationList.lastVacation")}</h3>
				{pageState === PAGE_STATE.VIEW && (
					<span className="total-tag used">
						{t("HR.Manage.vacationList.Used.total-tag") +
							vacationList.previous.totalTime}
					</span>
				)}
				<CustomSelect
					value={year}
					setValue={(value) => {
						setYear(value);
						setPageNumber(1);
					}}
					options={getBetweenYears(selectedUser.User.UserProfile.joinDate)}
				/>
			</div>
			<If condition={pageState}>
				<Case condition={PAGE_STATE.VIEW}>
					<div className="vacation-chk-list">
						<List
							{...{
								vacationList,
								setVacationManageModal,
								setSelectedVacation,
								titleText,
								timeText,
								fileClassName,
								fileText,
								currentPath,
								showAuthAlert,
							}}
						/>
					</div>
					<Pagination
						setPageNumber={setPageNumber}
						currentPage={pageNumber}
						totalPage={pagingObject.previous && pagingObject.previous.totalPage}
					/>
				</Case>
				<Case condition={PAGE_STATE.EMPTY}>
					<Empty currentTab={"used"} selectedUser={selectedUser} />
				</Case>
			</If>
			{/*지난휴가 리스트 클릭시 모달*/}
			{Object.keys(selectedVacation).length > 0 && (
				<ModalTemplate
					modalState={vacationManageModal}
					setModalToggle={setVacationManageModal}
					title={
						<>
							{titleText(selectedVacation)}
							{selectedVacation.HrVacation.HrVacationType.autoConfirm ? (
								<span className="permission auto-approval">
									{t("HR.Manage.vacationList.auto-approval")}
								</span>
							) : (
								<span
									className={`permission ${
										VACATION_STATUS[selectedVacation.status]
									}`}
								>
									{VACATION_STATUS_TEXT[VACATION_STATUS[selectedVacation.status]]}
								</span>
							)}
						</>
					}
					width={460}
					className="vacation-manage-modal"
					closeable={false}
				>
					<Modal
						{...{
							timeText,
							selectedVacation,
							titleText,
							onSubmit,
						}}
					/>
				</ModalTemplate>
			)}
		</>
	);
};
export default Used;
