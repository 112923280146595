import storageService from "~/utils/storage";
import { CLEAR_STATES } from "../reducers";

export const LOGIN = "auth/LOGIN";
export const MAIL = "auth/MAIL";

export const login = ({ accessToken }) => ({
	type: LOGIN,
	payload: {
		accessToken: accessToken,
	},
});

export const logout = () => {
	storageService.clear("auth", "local");
	return {
		type: CLEAR_STATES,
	};
};

export const mail = ({ accessToken }) => ({
	type: MAIL,
	payload: {
		accessToken: accessToken,
	},
});
