import React, { useEffect } from "react";
import { API_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useRepository from "~/common/hooks/useRepository";
import { ALARM_LIST } from "~/store/alarm/repository";

const AlarmRow = ({ alarm, a_idx }) => {
	const { t } = useTranslation();
	const readAlarmRequest = useRequest("post", `user/alarm/${alarm.idx}`);
	const [getAlarmList] = useRepository(ALARM_LIST);

	const handleRead = () => {
		if (alarm.read) return;
		readAlarmRequest.call();
	};

	useEffect(() => {
		switch (readAlarmRequest.state) {
			case API_STATE.done:
				getAlarmList();
				break;
			case API_STATE.error:
				console.error(readAlarmRequest.error);
				break;
			default:
				break;
		}
	}, [readAlarmRequest.state]);

	return (
		<div className="alarm-tr">
			<div className="alarm-number">{a_idx + 1}</div>
			<div className="alarm-type">
				<span className="online-exam">{alarm.type}</span>
			</div>
			<div className="alarm-content">
				{alarm.content ? (
					<Link className="alarm-link" to={alarm.content} onClick={handleRead}>
						{alarm.title}
					</Link>
				) : (
					alarm.title
				)}
			</div>
			<div className="alarm-date">{alarm.regDate}</div>
			<div className="alarm-status">
				<span
					className={`custom-badge ${alarm.read ? "alarm-read" : ""}`}
					onClick={handleRead}
				>
					{alarm.read ? t("MyPage.AlarmPage.read") : t("MyPage.AlarmPage.unread")}
				</span>
			</div>
		</div>
	);
};

export default AlarmRow;
