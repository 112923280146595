import React, { useEffect } from "react";
import Form from "~/common/components/Form";
import Address from "~/common/components/Address";
import Input from "~/common/components/Input";
import { useController } from "react-hook-form";

const AddressType = ({ useForm, form, apply }) => {
	const zipCode = useForm.watch(form.order.toString() + "/zipCode")
		? "(" + useForm.watch(form.order.toString() + "/zipCode") + ") "
		: "";
	const address = useForm.watch(form.order.toString() + "/address") ?? "";
	const addressDetail = useForm.watch(form.order.toString() + "/addressDetail") ?? "";

	useEffect(() => {
		useForm.setValue(form.order.toString(), zipCode + address + addressDetail);
	}, [zipCode, address, addressDetail]);

	useEffect(() => {
		apply && useForm.setValue(form.order.toString(), form.answer);
	}, []);

	const {
		fieldState: { invalid },
	} = useController({
		control: useForm.control,
		name: form.order.toString(),
		rules: { required: form.required },
	});

	return (
		<>
			<Form useForm={useForm}>
				{apply ? (
					<Input
						label={form.title}
						name={form.order.toString()}
						required={form.required}
						readonly={apply}
					/>
				) : (
					<Address
						label={form.title}
						name={{
							postCode: form.order.toString() + "/zipCode",
							address1: form.order.toString() + "/address",
							address2: form.order.toString() + "/addressDetail",
						}}
						required={form.required}
						invalid={invalid}
					/>
				)}
			</Form>
		</>
	);
};

export default AddressType;
