import { SET_IS_FAILED_PAYMENT } from "./actions";

export const INITIAL_STATE = {
	isFailedPayment: false,
};

export const REDUCER = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_IS_FAILED_PAYMENT:
			return { ...state, isFailedPayment: action.payload.isFailedPayment };
		default:
			return state;
	}
};
