import React, { Children, useEffect, useState } from "react";
import { getNestedValue } from "~/utils/object";

const DataEntry = ({ type, props, errorMessage, className, description, ...others }) => {
	const message = getNestedValue(errorMessage, props.name)?.message;

	return (
		<div className={`f__control ${className ? className : ""} ${message ? "has-error" : ""}`}>
			{React.createElement(type, {
				...{
					...props,
					...others,
				},
			})}
			{message !== undefined && message !== " " ? (
				<p className="f__feedback">{message}</p>
			) : (
				description !== undefined &&
				description !== "" && <p className="f__description">{description}</p>
			)}
		</div>
	);
};

const FormGroup = ({ idx, label, className, children }) => (
	<div className={`f__group ${className ? className : ""}`} key={idx}>
		{label && <label className="f__label">{label}</label>}
		{children}
	</div>
);

const Form = ({ children, useForm, className }) => {
	const [errorMessage, setErrorMessage] = useState({});
	const {
		formState: { errors },
		...props
	} = useForm;

	useEffect(() => {
		setErrorMessage(errors);
	}, [errors]);

	return (
		<div className={`c__form ${className}`}>
			{Children.map(children, (child, index) =>
				//조건에 따라 변경되는 Form Element 유무 체크
				child ? (
					//단일 Form Element
					child.props.name ? (
						<FormGroup
							idx={index}
							label={child.props.label}
							className={`${child.props.className ? child.props.className : ""} ${
								child.props.required ? "required" : ""
							}`}
						>
							<DataEntry
								type={child.type}
								props={child.props}
								description={child.props.description}
								{...{ errorMessage, ...props }}
							/>
						</FormGroup>
					) : //하나의 label 안에 여러개의 Form Element
					child.type.displayName === "Group" ? (
						<FormGroup
							idx={index}
							label={child.props.label}
							className={`${child.props.className ? child.props.className : ""} ${
								child.props.required ? "required" : ""
							}`}
						>
							<div className="f__control-wrap">
								{Children.map(child.props.children, (entryChild) =>
									entryChild.props.name ? (
										<DataEntry
											type={entryChild.type}
											props={entryChild.props}
											className={entryChild.props.className}
											description={entryChild.props.description}
											{...{ errorMessage, ...props }}
										/>
									) : (
										entryChild
									)
								)}
							</div>
						</FormGroup>
					) : //<></>으로 감싸지는 <FormGroup> 처리
					typeof child.type === "symbol" ? (
						Children.map(child.props.children, (d) =>
							d.props.name ? (
								<FormGroup
									idx={index}
									label={d.props.label}
									className={`${d.props.className ? d.props.className : ""} ${
										d.props.required ? "required" : ""
									}`}
								>
									<DataEntry
										type={d.type}
										props={d.props}
										description={d.props.description}
										{...{ errorMessage, ...props }}
									/>
								</FormGroup>
							) : d.type.displayName === "Group" ? (
								<FormGroup
									idx={index}
									label={d.props.label}
									className={`${d.props.className ? d.props.className : ""} ${
										d.props.required ? "required" : ""
									}`}
								>
									<div className="f__control-wrap">
										{Children.map(d.props.children, (entryChild) =>
											entryChild.props.name ? (
												<DataEntry
													type={entryChild.type}
													props={entryChild.props}
													className={entryChild.props.className}
													description={entryChild.props.description}
													{...{ errorMessage, ...props }}
												/>
											) : (
												entryChild
											)
										)}
									</div>
								</FormGroup>
							) : (
								d.props.children
							)
						)
					) : (
						child
					)
				) : (
					<></>
				)
			)}
		</div>
	);
};

export const Group = ({ children }) => {
	return children;
};
Group.displayName = "Group";

export default Form;

Form.defaultProps = {
	className: "horiz",
};
