import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import Form from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import React, { useEffect, useState } from "react";
import Delete from "~/pages/hr/ManagePage/vacationList/Used/Modal/Delete";
import ManageStatus from "~/pages/hr/ManagePage/vacationList/Used/Modal/ManageStatus";
import { useForm } from "react-hook-form";
import useAlert from "~/common/hooks/useAlert";
import { VACATION_CLASSNAME, VACATION_STATUS, VACATION_STATUS_TEXT } from "~/common/constants/form";
import { DateWithDayName } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const Modal = ({ timeText, selectedVacation, titleText, onSubmit }) => {
	const { t } = useTranslation();
	const [vacationDeleteAlert, setVacationDeleteAlert] = useState(false);
	const [changeStatusModal, setChangeStatusModal] = useState(false);

	const [showAccessAlert, AccessAlert] = useAlert();

	const modalForm = useForm({ defaultValues: { memo: selectedVacation.HrVacation.memo } });
	useEffect(() => {
		return () => {
			setChangeStatusModal(false);
			setVacationDeleteAlert(false);
		};
	}, []);

	const fileClassNameForModal = (vacation) => {
		let result;
		if (vacation.HrVacationType.documents === "pre") {
			if (vacation.HrVacationFiles.length > 0) {
				result = "complete";
			} else {
				result = "no-submit";
			}
		} else if (vacation.HrVacationType.documents === "post") {
			if (vacation.HrVacationFiles.length > 0) {
				result = "complete";
			} else {
				result = "no-submit";
			}
		} else {
			result = "";
		}
		return result;
	};

	return (
		<>
			<div className="modal-content">
				<div className="vacation-state">
					<div
						className={`vacation-name ${
							VACATION_CLASSNAME[selectedVacation.HrVacation.title]
						}`}
					>
						{selectedVacation.startDate === selectedVacation.endDate
							? DateWithDayName(selectedVacation.startDate)
							: `${DateWithDayName(selectedVacation.startDate)}
							   ~ 
							  ${DateWithDayName(selectedVacation.endDate)}`}
					</div>
					<div className="time">{timeText(selectedVacation)}</div>
				</div>
				{selectedVacation.HrVacation.HrVacationType.documents !== "none" && (
					<div className="c__form vacation-manage-form vertical c__file-upload-form">
						{/*제출완료*/}
						{selectedVacation.HrVacation.HrVacationFiles.length > 0 ? (
							<div
								className={`f__group complete ${fileClassNameForModal(
									selectedVacation.HrVacation
								)}`}
							>
								<>
									<div className="f__label">
										{t("HR.Manage.vacationList.ProofDataCheck")}
										<span className="status-badge">
											{t("HR.Manage.vacationList.submit")}
										</span>
									</div>
									{selectedVacation.HrVacation.HrVacationFiles &&
										selectedVacation.HrVacation.HrVacationFiles.map((f) => (
											<div className="f__control">
												<span>
													<a
														href={f.File?.location}
														target={"_blank"}
														rel="noopener noreferrer"
													>
														{f.File?.name}
													</a>
												</span>
											</div>
										))}
								</>
							</div>
						) : (
							<div className="f__group no-submit">
								<div
									className={`f__label ${fileClassNameForModal(
										selectedVacation.HrVacation
									)}`}
								>
									{t("HR.Manage.vacationList.ProofDataCheck")}
									<span className="status-badge">
										{t("HR.Manage.vacationList.notSubmit")}
									</span>
								</div>
							</div>
						)}
					</div>
				)}
				<Form useForm={modalForm} className="vacation-manage-form vertical">
					<Textarea
						label={t("HR.Manage.user")}
						name="memo"
						placeholder={selectedVacation.HrVacation.memo}
						readOnly
					/>
				</Form>
			</div>

			<div className="modal-footer">
				<button
					type="button"
					className="btn__solid btn-delete"
					color="red"
					onClick={() => setVacationDeleteAlert(true)}
				>
					{t("HR.Manage.vacationList.Used.btn-delete")}
				</button>
				<button
					className="btn__solid"
					color="primary"
					onClick={() => {
						selectedVacation.HrVacation.HrVacationType.autoConfirm
							? showAccessAlert()
							: setChangeStatusModal(true);
					}}
					// disabled
				>
					{t("HR.Manage.vacationList.Used.btn-change")}
				</button>
			</div>

			{/*휴가 삭제 버튼 클릭시 AlertModal*/}
			<ModalTemplate
				modalState={vacationDeleteAlert}
				setModalToggle={setVacationDeleteAlert}
				title={
					<>
						{titleText(selectedVacation)}
						{selectedVacation.HrVacation.HrVacationType.autoConfirm ? (
							<span className="permission auto-approval">
								{t("HR.Manage.vacationList.auto-approval")}
							</span>
						) : (
							<span
								className={`permission ${VACATION_STATUS[selectedVacation.status]}`}
							>
								{VACATION_STATUS_TEXT[VACATION_STATUS[selectedVacation.status]]}
							</span>
						)}
					</>
				}
				width={460}
				className="vacation-manage-modal alert-modal-wrap manage-delete-modal"
			>
				<Delete
					{...{
						setVacationDeleteAlert,
						onSubmit,
					}}
				/>
			</ModalTemplate>

			{/*상태변경 모달*/}
			<ModalTemplate
				modalState={changeStatusModal}
				setModalToggle={setChangeStatusModal}
				title={titleText(selectedVacation)}
				width={460}
				className="vacation-manage-modal"
			>
				<ManageStatus
					{...{
						timeText,
						selectedVacation,
						setChangeStatusModal,
						onSubmit,
					}}
				/>
			</ModalTemplate>
			<AccessAlert message={t("HR.Manage.vacationList.Used.AccessAlert")} type="warning" />
		</>
	);
};
export default Modal;
