import { setCompanyInfo, setMemberList } from "./actions";

export const COMPANY_INFO = {
	method: "get",
	url: "company/info",
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data;
		const { company, repProduct, certificate, signature } = responseData;
		dispatch(setCompanyInfo({ info: company, repProduct: repProduct, certificate: certificate, signature: signature  }));
		return responseData;
	},
};

export const COMPANY_GET_MEMBERS = {
	method: "get",
	url: "user/list",
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data;
		dispatch(setMemberList({ memberList: responseData }));
		return responseData;
	},
};
