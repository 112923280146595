export const SET_ORG_MEMBER_DATA = "organization/SET_ORG_MEMBER_DATA";
export const SET_MEMBER_LIST = "organization/SET_MEMBER_LIST";
export const SET_TREE_DATA = "organization/SET_TREE_DATA";
export const SET_EXIST_MEMBER_LIST = "organization/SET_EXIST_MEMBER_LIST";
export const SET_WORK_TYPE_LIST = "organization/SET_WORK_TYPE_LIST";
export const CLEAR_ORG_DATA = "organization/CLEAR_ORG_DATA";

export const setMemberList = ({ memberList }) => ({
	type: SET_MEMBER_LIST,
	payload: {
		memberList: memberList,
	},
});

export const setOrgMemberData = ({ orgMemberData }) => ({
	type: SET_ORG_MEMBER_DATA,
	payload: {
		orgMemberData: orgMemberData,
	},
});

export const setTreeData = ({ treeData }) => ({
	type: SET_TREE_DATA,
	payload: {
		treeData: treeData,
	},
});

export const setExistMemberList = ({ existMemberList }) => ({
	type: SET_EXIST_MEMBER_LIST,
	payload: {
		existMemberList: existMemberList,
	},
});

export const setWorkTypeList = ({ workTypeList }) => ({
	type: SET_WORK_TYPE_LIST,
	payload: {
		workTypeList: workTypeList,
	},
});

export const clearOrgData = () => ({
	type: CLEAR_ORG_DATA,
});
