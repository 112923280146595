import React, { useEffect, useState } from "react";

// Components
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import List from "~/pages/hr/VacationPage/ApplyVacationModal/List";
import Edit from "~/pages/hr/VacationPage/ApplyVacationModal/Edit";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import { CautionThickIcon } from "~/assets/svg/InterfacesIcon";

// Assets
import "~/assets/sass/pages/hr/attendance/modal/attendanceModal.scss";
import "~/assets/sass/pages/hr/attendance/common/typeColor.scss";
import useRequest from "~/common/hooks/useRequest";
import { timeToDateFormatter } from "~/utils/formatter";
import {
	differenceInCalendarDays,
	differenceInMinutes,
	lastDayOfMonth,
	lastDayOfYear,
} from "date-fns";
import { getKeyByValue } from "~/utils/object";
import TooltipTemplate from "~/common/components/TooltipPortal/TooltipTemplate";
import { useTranslation } from "react-i18next";

export const STATUTORY_VACATION_TYPE = {
	ANNUAL: "연차",
	MENSTRUAL: "보건",
	MATERNITY: "출산(유산,사산)휴가",
	MULTIPLE: "출산(유산,사산)휴가 (다태아)",
	PATERNITY: "배우자 출산휴가",
	FAMILY: "가족 돌봄 휴가",
	INFERTILITY: "난임 치료 휴가",
	CUSTOM: "커스텀",
};

export const VACATION_COLOR = {
	ANNUAL: "#03DBC0",
	MENSTRUAL: "#FFD74A",
	MATERNITY: "#0B7782",
	MULTIPLE: "#95D0D5",
	PATERNITY: "#FF429D",
	FAMILY: "#FFA370",
	INFERTILITY: "#71357C",
	UNDEFINED: "#4780FF",
};

const ApplyVacationModal = ({
	vacationTypeListModal,
	setVacationTypeListModal,
	getUpcomingVacation,
	vacationTimeCountRequest,
	info,
}) => {
	const { t } = useTranslation();
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [vacationTypeList, setVacationTypeList] = useState([]);
	const [modalTitle, setModalTitle] = useState("");
	const [modalClassName, setModalClassName] = useState("");
	const [selectedVacation, setSelectedVacation] = useState();
	const [defaultWorkTime, setDefaultWorkTime] = useState();
	const [workTime, setWorkTime] = useState({});
	const [workDays, setWorkDays] = useState([]);
	const [restTime, setRestTime] = useState();
	const [totalWorkMinutes, setTotalWorkMinutes] = useState("");

	const vacationTypeRequest = useRequest("get", `vacation/apply/type`);

	useEffect(() => {
		vacationTypeRequest.singleCall();
	}, []);

	useEffect(() => {
		switch (vacationTypeRequest.state) {
			case API_STATE.done:
				const newData = vacationTypeRequest.response.data.map((d) => ({
					...d,
					remainingTime: d.type === "연차" ? `${d.remainingTime}일` : d.remainingTime,
					statutoryType: getKeyByValue(STATUTORY_VACATION_TYPE, d.title),
					color: VACATION_COLOR[
						getKeyByValue(STATUTORY_VACATION_TYPE, d.title).toUpperCase()
					],
				}));
				setVacationTypeList(newData);
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				if (vacationTypeRequest.error.statusCode === 404) {
					setPageState(PAGE_STATE.VIEW);
				}
			default:
				break;
		}
	}, [vacationTypeRequest.state]);

	const vacationTimeRequest = useRequest("get", `vacation/time`);

	useEffect(() => {
		vacationTimeRequest.singleCall();
	}, []);

	useEffect(() => {
		switch (vacationTimeRequest.state) {
			case API_STATE.done:
				const responseTimeData = vacationTimeRequest.response.data.workTime;
				const responseDaysData = vacationTimeRequest.response.data.workDays;

				const newData = {
					morningVStartTime: [],
					morningVEndTime: [],
					afternoonVStartTime: [],
					afternoonVEndTime: [],
				};

				if (!responseTimeData) return;
				responseTimeData.forEach((d) => {
					newData.morningVStartTime.push(timeToDateFormatter(d.morningVStartTime));
					newData.morningVEndTime.push(timeToDateFormatter(d.morningVEndTime));
					newData.afternoonVStartTime.push(timeToDateFormatter(d.afternoonVStartTime));
					newData.afternoonVEndTime.push(timeToDateFormatter(d.afternoonVEndTime));
				});

				setWorkTime(newData);

				setRestTime({
					start: timeToDateFormatter(responseTimeData[0].restStartTime),
					end: timeToDateFormatter(responseTimeData[0].restEndTime),
				});

				responseTimeData.forEach((d) => {
					if (d.morningVStartTime === vacationTimeRequest.response.data.workStartTime) {
						setDefaultWorkTime({
							morningStart: timeToDateFormatter(d.morningVStartTime),
							morningEnd: timeToDateFormatter(d.morningVEndTime),
							afternoonStart: timeToDateFormatter(d.afternoonVStartTime),
							afternoonEnd: timeToDateFormatter(d.afternoonVEndTime),
						});
					} else {
						setDefaultWorkTime({
							morningStart: timeToDateFormatter(
								responseTimeData[0].morningVStartTime
							),
							morningEnd: timeToDateFormatter(responseTimeData[0].morningVEndTime),
							afternoonStart: timeToDateFormatter(
								responseTimeData[0].afternoonVStartTime
							),
							afternoonEnd: timeToDateFormatter(
								responseTimeData[0].afternoonVEndTime
							),
						});
					}
				});

				setWorkDays(responseDaysData);

				const commutingHours = differenceInMinutes(
					timeToDateFormatter(responseTimeData[0].afternoonVEndTime),
					timeToDateFormatter(responseTimeData[0].morningVStartTime)
				);

				const restHours = differenceInMinutes(
					timeToDateFormatter(responseTimeData[0].restEndTime),
					timeToDateFormatter(responseTimeData[0].restStartTime)
				);

				setTotalWorkMinutes(commutingHours - restHours);

				break;
			case API_STATE.error:
				if (vacationTimeRequest.error.statusCode === 404) {
					setPageState(PAGE_STATE.VIEW);
				}
			default:
				break;
		}
	}, [vacationTimeRequest.state]);

	const [vacationUnit, setVacationUnit] = useState("");
	const vacationSettingRequest = useRequest("get", `vacation/setting`);

	useEffect(() => {
		vacationSettingRequest.singleCall();
	}, []);

	useEffect(() => {
		switch (vacationSettingRequest.state) {
			case API_STATE.done:
				const responseData = vacationSettingRequest.response.data;
				switch (responseData.unit) {
					case "일":
						setVacationUnit("day");
						break;
					case "반차":
						setVacationUnit("half");
						break;
					default:
						setVacationUnit("time");
						break;
				}
				break;
			case API_STATE.error:
				if (vacationSettingRequest.error.statusCode === 404) {
					setPageState(PAGE_STATE.VIEW);
				}
			default:
				break;
		}
	}, [vacationSettingRequest.state]);

	useEffect(() => {
		switch (pageState) {
			case PAGE_STATE.VIEW:
				setModalClassName("apply-vacation-modal");
				setModalTitle(t("HR.Vacation.ApplyVacationModal.setModalTitle"));
				break;
			case PAGE_STATE.EDIT:
				setModalClassName("vacation-modal");
				const today = new Date();
				let expirationDate;
				switch (selectedVacation.refresh) {
					case "year":
						expirationDate = differenceInCalendarDays(today, lastDayOfYear(today));
						break;
					case "month":
						expirationDate = differenceInCalendarDays(today, lastDayOfMonth(today));
						break;
				}

				setModalTitle(
					<>
						{selectedVacation.title}
						<button type="button" className="c__tooltip-btn">
							<CautionThickIcon />
							<TooltipTemplate>
								가능 일수: {selectedVacation.remainingTime}
								<br />
								{selectedVacation.refresh !== "instant" && (
									<>
										사용 기한: D{expirationDate}
										<br />
									</>
								)}
								급여: {selectedVacation.paid ? "유급" : "무급"}
								<br />
								{/* TODO: 관리자 알림 설정 추후 개발 예정 */}
								{/*관리자 알림 신청: {selectedVacation.alarm ? "On" : "Off"}*/}
								<br />
								관리자 자동승인: {selectedVacation.autoConfirm ? "On" : "Off"}
								<br />
								연속사용: {selectedVacation.continuous ? "필수" : "선택"}
								<br />
								휴일: {selectedVacation.weekend ? "포함" : "미포함"}
							</TooltipTemplate>
						</button>
					</>
				);
				break;
			default:
				break;
		}
	}, [pageState]);

	return (
		<>
			<ModalTemplate
				modalState={vacationTypeListModal}
				setModalToggle={setVacationTypeListModal}
				title={modalTitle}
				width={460}
				height={556}
				className={modalClassName}
				closeable={false}
			>
				<If
					condition={pageState}
					{...{ setPageState, selectedVacation, setSelectedVacation }}
				>
					<Case condition={PAGE_STATE.VIEW}>
						<List
							{...{
								vacationTypeList,
							}}
						/>
					</Case>
					<Case condition={PAGE_STATE.EDIT}>
						<Edit
							{...{
								workDays,
								workTime,
								restTime,
								setVacationTypeListModal,
								vacationUnit,
								totalWorkMinutes,
								defaultWorkTime,
								getUpcomingVacation,
								vacationTimeCountRequest,
								info,
							}}
						/>
					</Case>
					<Case condition={PAGE_STATE.LOADING}>
						<Loading />
					</Case>
				</If>
			</ModalTemplate>
		</>
	);
};
export default ApplyVacationModal;
