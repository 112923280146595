import React, { useState } from "react";
import { useController } from "react-hook-form";
import { SolidCloseIcon } from "~/assets/svg/CloseIcon";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

/**
 * Tag component for useForm
 *
 * @param { Props } props
 *
 * @typedef Props
 * @property {import"react-hook-form".Control} control
 * @property {string} name
 * @property {string} className
 * @property {string=} placeholder
 * @property {string[]} defaultValue
 * @property {boolean} required
 * @property {Validation} validation
 *
 * @typedef Validation
 * @property {string|boolean} required
 * @property {string|number} min
 * @property {string|number} max
 * @property {number} maxLength
 * @property {number} minLength
 * @property {RegExp} pattern
 */
const Tag = (props) => {
	const { t } = useTranslation();
	const {
		control,
		name,
		validation,
		defaultValue,
		tagClassName = "",
		required,
		placeholder,
	} = props;
	const [inputToggle, setInputToggle] = useState(false);

	const {
		field: { onChange, value },
	} = useController({
		control,
		name,
		rules: { required: required && "필수 입력 사항 입니다.", ...validation },
		defaultValue: defaultValue || [],
	});

	const handleRemove = (item) => {
		onChange(value.filter((v) => v !== item));
	};

	const handleKeyDown = (newTag, key) => {
		if (key === "Enter" && newTag.length > 0) {
			if (value === undefined) {
				onChange([newTag]);
			} else if (!value.includes(newTag)) {
				onChange(value.concat(newTag));
			}
			setInputToggle(false);
		} else if (key === "Escape") {
			setInputToggle(false);
		}
	};

	return (
		<div className={`${tagClassName}`}>
			<ul style={{ display: "flex", flexDirection: "row" }}>
				{value?.map((tag, index) => (
					<li className={`c__tag-edit`} key={index}>
						{tag}{" "}
						<div
							onClick={() => {
								handleRemove(tag);
							}}
							className={`c__tag-edit-remove`}
						>
							<SolidCloseIcon />
						</div>
					</li>
				))}
				{inputToggle ? (
					<li className={`c__tag-add-input`} tabIndex="0">
						<input
							placeholder={placeholder}
							onKeyDown={(e) => {
								handleKeyDown(e.target.value, e.key);
							}}
							onBlur={() => {
								setInputToggle(false);
							}}
							autoFocus
						/>
					</li>
				) : (
					<li
						className={`c__tag-add-button`}
						onClick={() => {
							setInputToggle(true);
						}}
					>
						{t("components.Tag.li")}
					</li>
				)}
			</ul>
		</div>
	);
};

export default Tag;

Tag.defaultProps = {
	placeholder: translation.t("components.Tag.placeholder"),
};
