import React, { useEffect, useState } from "react";
import PageHeader from "~/common/components/PageHeader";
import TabNav from "~/common/components/TabNav";
import { useOutletContext } from "react-router-dom";
import { Case, If } from "~/common/components/If";
import { PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import View from "~/pages/hr/AttendancePage/TeamSchedule/View";
import useRepository from "~/common/hooks/useRepository";
import { TEAM_SCHEDULE } from "~/store/attendance/repository";

const TeamSchedule = () => {
	const { scheduleTabList, MENU, TYPE } = useOutletContext();
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [nowYear, setNowYear] = useState(new Date().getFullYear());
	const [nowMonth, setNowMonth] = useState(new Date().getMonth() + 1);

	const [getTeamScheduleRequest] = useRepository(TEAM_SCHEDULE);

	useEffect(() => {
		getTeamScheduleRequest({ year: nowYear, month: nowMonth }).then(async () => {
			await setPageState(PAGE_STATE.VIEW);
		});
	}, [nowMonth]);

	const Header = ({ buttonList = [] }) => {
		return (
			<>
				<PageHeader buttonList={buttonList} menu={MENU} type={TYPE} />
				<TabNav tabList={scheduleTabList} currentTab={"team"} />
			</>
		);
	};

	return (
		<>
			<If condition={pageState} {...{ MENU, TYPE }}>
				<Case condition={PAGE_STATE.VIEW}>
					<Header />
					<View
						{...{
							setNowMonth,
							setNowYear,
						}}
					/>
				</Case>
				<Case condition={PAGE_STATE.LOADING}>
					<Header />
					<Loading />
				</Case>
			</If>
		</>
	);
};
export default TeamSchedule;
