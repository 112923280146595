import React, { useEffect, useState } from "react";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import { useForm } from "react-hook-form";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import { Case, If } from "~/common/components/If";
import { useGlobalStore } from "~/store";
import { BIZ_TYPE } from "~/common/constants/form";
import BizNumForm from "~/pages/mypage/admin/BusinessPage/BizNumForm";
import Uncertified from "~/pages/mypage/admin/BusinessPage/Uncertified";
import Certified from "~/pages/mypage/admin/BusinessPage/Certified";
import Prospective from "~/pages/mypage/admin/BusinessPage/Prospective";

//Assets
import "~/assets/sass/pages/mypage/admin/business.scss";
import useRepository from "~/common/hooks/useRepository";
import { USER_INFO } from "~/store/user/repository";
import { useTranslation } from "react-i18next";

export const BUSINESS_PAGE_STATE = {
	PROSPECTIVE: "prospective",
	BIZFORM: "bizForm",
	UNCERTIFIED: "uncertified",
	PROCESSING: "processing",
	CERTIFIED: "certified",
};

export const BUSINESS_CERT_STATE = {
	WAITING: "대기",
	CERTIFIED: "인증완료",
	REJECTED: "반려",
};

const BusinessPage = () => {
	const { t } = useTranslation();
	const MENU = ["admin", "business"];
	const TYPE = "myPage";
	const [{ user }] = useGlobalStore();
	const [getUserInfo] = useRepository(USER_INFO);

	const changeBizForm = useForm();
	const [bizCertification, setBizCertification] = useState({});
	const [page, setPage] = useState("");
	const getBizImgRequest = useRequest("get", "admin/business");

	useEffect(() => {
		getBizImgRequest.singleCall();
	}, [getBizImgRequest]);

	useEffect(() => {
		switch (getBizImgRequest.state) {
			case API_STATE.done:
				const responseData = getBizImgRequest.response.data;
				setBizCertification({
					imgUrl: responseData.imgUrl,
					status: responseData.status,
				});

				setPage(BUSINESS_PAGE_STATE.CERTIFIED);

				// switch (getBizImgRequest.response.data.status) {
				// 	case BUSINESS_CERT_STATE.CERTIFIED:
				// 		break;
				// 	case BUSINESS_CERT_STATE.WAITING:
				// 		setPage(BUSINESS_PAGE_STATE.PROCESSING);
				// 		break;
				// }
				break;
			case API_STATE.error:
				switch (getBizImgRequest.error.statusCode) {
					case 404:
						if (
							user.company.certType === BIZ_TYPE.PROSPECTIVE ||
							user.company.certType === null
						) {
							setPage(BUSINESS_PAGE_STATE.PROSPECTIVE);
						} else {
							setPage(BUSINESS_PAGE_STATE.UNCERTIFIED);
						}
						break;
					case 500:
						alert(t("Alert.alert1"));
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	}, [getBizImgRequest.state, getBizImgRequest.response, getBizImgRequest.error]);

	return (
		<NavbarAndSidebarLayout menu={MENU} type={TYPE}>
			<PageHeader hasBreadCrumb={true} />
			<If
				condition={page}
				{...{
					setPage,
					user,
					bizCertification,
					changeBizForm,
					getBizImgRequest,
					getUserInfo,
				}}
			>
				<Case condition={BUSINESS_PAGE_STATE.PROSPECTIVE}>
					<Prospective />
				</Case>
				<Case condition={BUSINESS_PAGE_STATE.BIZFORM}>
					<BizNumForm />
				</Case>
				<Case condition={BUSINESS_PAGE_STATE.UNCERTIFIED}>
					<Uncertified />
				</Case>
				{/*<Case condition={BUSINESS_PAGE_STATE.PROCESSING}>*/}
				{/*	<Processing />*/}
				{/*</Case>*/}
				<Case condition={BUSINESS_PAGE_STATE.CERTIFIED}>
					<Certified />
				</Case>
			</If>
		</NavbarAndSidebarLayout>
	);
};
export default BusinessPage;
