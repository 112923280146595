import { setTeamSchedule } from "~/store/attendance/actions";
import { eachDayOfInterval, getDay, isAfter, isBefore } from "date-fns";
import { dashDateToDateFormatter, dateFormatter } from "~/utils/formatter";

/**
 * 팀원 스케줄 조회
 */

function getDayInterval(start, end) {
	return eachDayOfInterval({
		start: start,
		end: end,
	});
}

const isWeekday = (workDays, date) => {
	const day = getDay(date);
	return workDays.includes(day);
};

export const TEAM_SCHEDULE = {
	method: "post",
	url: "vacation/calendar",
	type: "body",
	template: (param) => ({
		year: param?.year ? param?.year : new Date().getFullYear(),
		month: param?.month ? param?.month : new Date().getMonth() + 1,
		userIdx: param?.userIdx ? param?.userIdx : "",
		vacationType: ["휴가", "재택근무", "외근", "초과근무"],
	}),
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data;
		const calendarEvents = [];

		Object.keys(responseData).forEach((type) => {
			calendarEvents.push(getScheduleByType(type, responseData[type]));
		});

		function getScheduleByType(type, data) {
			const newArray = [];
			data.forEach((d) => {
				if (d.startDate === d.endDate) {
					newArray.push(d.startDate);
				} else {
					const eachDays = getDayInterval(
						dashDateToDateFormatter(d.startDate),
						dashDateToDateFormatter(d.endDate)
					);

					eachDays.forEach((day) => {
						if (isWeekday(d.WorkDays, day)) {
							newArray.push(dateFormatter(day, "-"));
						}
					});
				}
			});

			const countedDates = newArray.reduce(function (allDates, date) {
				if (date in allDates) {
					allDates[date]++;
				} else {
					allDates[date] = 1;
				}
				return allDates;
			}, {});

			const events = [];

			for (let key in countedDates) {
				events.push({
					type: type,
					count: countedDates[key],
					start: key,
					data: data.filter(
						(v) =>
							v.startDate === key ||
							v.endDate === key ||
							(isBefore(
								dashDateToDateFormatter(v.startDate),
								dashDateToDateFormatter(key)
							) &&
								isAfter(
									dashDateToDateFormatter(v.endDate),
									dashDateToDateFormatter(key)
								))
					),
				});
			}

			return events;
		}

		dispatch(
			setTeamSchedule({
				teamVacation: responseData.vacations,
				teamBusinessTrip: responseData.businessTrip,
				teamRemoteWork: responseData.remoteWork,
				calendarEvents: calendarEvents.flat(),
			})
		);

		return { calendarEvents };
	},
};
