import React, { useEffect, useState } from "react";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import { Case, If } from "~/common/components/If";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import translation from "~/translations";

//assets
import "~/assets/sass/pages/community/communityList.scss";
import "~/assets/sass/pages/community/noticePage/noticePage.scss";
import PageHeader from "~/common/components/PageHeader";
import { SearchIcon, TableAlbumIcon, TableListIcon } from "~/assets/svg/InterfacesIcon";
import CustomSelect from "~/common/components/CustomSelect";
import { optionsGenerator } from "~/common/components/Select";
import ListView from "~/pages/community/NoticePage/NoticeList/component/listView";
import CardView from "~/pages/community/NoticePage/NoticeList/component/cardView";
import AlbumView from "~/pages/community/NoticePage/NoticeList/component/albumView";
import Pagination from "~/common/components/Pagination";
import Loading from "~/common/components/Loading";
import { useNavigate } from "react-router";
import { useGlobalStore } from "~/store";
import useRequest from "~/common/hooks/useRequest";
import NoData from "~/common/components/NoData";
import { useResetAtom } from "jotai/utils";
import { CurrentNoticeAtom, NoticePaginationAtom } from "~/atoms/Community";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";

const NoticeList = () => {
	const { t } = useTranslation();
	const [page, setPage] = useState(PAGE_STATE.LOADING);
	const menu = ["community", "notice"];
	const [viewType, setViewType] = useState("list");
	const [searchType, setSearchType] = useState("ALL");
	const [searchValue, setSearchValue] = useState();
	const [pageNumber, setPageNumber] = useAtom(NoticePaginationAtom);
	const [totalPage, setTotalPage] = useState();
	const [noticeList, setNoticeList] = useState([]);
	const [isBoardEmpty, setIsBoardEmpty] = useState(true);

	const navigate = useNavigate();
	const [
		{
			sidebar: { currentPath },
		},
	] = useGlobalStore();
	const clearCurrentNotice = useResetAtom(CurrentNoticeAtom);

	const searchOptions = {
		ALL: translation.t("Community.BoardPage.BoardPost.List.searchOptions.ALL"),
		TITLE: translation.t("Community.BoardPage.BoardPost.List.searchOptions.TITLE"),
		CONTENT: translation.t("Community.BoardPage.BoardPost.List.searchOptions.CONTENT"),
		NAME: translation.t("Community.BoardPage.BoardPost.List.searchOptions.NAME"),
		COMMENT: translation.t("Community.BoardPage.BoardPost.List.searchOptions.COMMENT"),
	};
	const pageSize = 10;

	const noticeListRequest = useRequest(
		"post",
		`community/notice/search${viewType === "album" ? "?type=CARD" : ""}`
	);

	const handleNavigateView = (noticeIdx) => {
		navigate(`detail/${noticeIdx}`);
	};

	const getNoticeList = () => {
		noticeListRequest.call({ size: pageSize, page: pageNumber }, "body");
	};

	const searchNoticeList = () => {
		noticeListRequest.call(
			{ size: pageSize, page: pageNumber, queryType: searchType, query: searchValue },
			"body"
		);
	};

	useEffect(() => {
		switch (noticeListRequest.state) {
			case API_STATE.done:
				if (noticeListRequest.response.data.notices.length !== 0) {
					setNoticeList(noticeListRequest.response.data.notices);
					setPageNumber(noticeListRequest.response.data.pagination.page);
					setTotalPage(noticeListRequest.response.data.pagination.totalPage);
					setIsBoardEmpty(noticeListRequest.response.data.boardEmpty);

					setPage(PAGE_STATE.VIEW);
				} else {
					setPage(PAGE_STATE.EMPTY);
				}
				break;
			case API_STATE.error:
				navigate("/404");
				break;
			default:
				break;
		}
	}, [noticeListRequest.state]);

	useEffect(() => {
		clearCurrentNotice();
		getNoticeList();
	}, [pageNumber, viewType]);

	const buttonList = [
		<button
			className="btn__solid"
			color="primary"
			onClick={() => navigate("/community/notice/write")}
		>
			{t("button.registering")}
		</button>,
	];

	return (
		<NavbarAndSidebarLayout menu={menu} type="main">
			<PageHeader {...{ menu }} buttonList={currentPath.auth === "edit" ? buttonList : []} />
			<section className="c__card notice-list-section">
				<div className="view-header">
					<ul className="view-list">
						<li
							className={`view-item ${viewType === "list" ? "active" : ""}`}
							onClick={() => {
								setPage(PAGE_STATE.LOADING);
								setViewType("list");
							}}
						>
							<TableListIcon />
						</li>
						<li
							className={`view-item ${viewType === "album" ? "active" : ""}`}
							onClick={() => {
								setPage(PAGE_STATE.LOADING);
								setPageNumber(1);
								setViewType("album");
							}}
						>
							<TableAlbumIcon />
						</li>
					</ul>
					<div className="search-box">
						<CustomSelect
							placeholder={t("Community.select-placeholder")}
							selectName="status-option"
							value={searchType}
							setValue={setSearchType}
							options={optionsGenerator(searchOptions)}
						/>
						<div className="c__search ">
							<input
								className="c__input"
								placeholder={t(
									"Community.BoardPage.BoardPost.List.searchPlaceholder"
								)}
								onChange={(e) => setSearchValue(e.target.value)}
								onKeyDown={async (e) => {
									if (e.key === "Enter") {
										await searchNoticeList();
									}
								}}
							/>
							<button type="button" className="btn-search" onClick={searchNoticeList}>
								<SearchIcon />
							</button>
						</div>
					</div>
				</div>
				<If condition={page} {...{ setPage, page }}>
					<Case condition={PAGE_STATE.VIEW}>
						<div className="p__notice-view">
							<If condition={viewType} {...{ noticeList, handleNavigateView }}>
								<Case condition={"list"}>
									<ListView />
								</Case>
								<Case condition={"card"}>
									<CardView />
								</Case>
								<Case condition={"album"}>
									<AlbumView />
								</Case>
							</If>
							<Pagination
								setPageNumber={setPageNumber}
								currentPage={pageNumber}
								totalPage={totalPage}
							/>
						</div>
					</Case>
					<Case condition={PAGE_STATE.LOADING}>
						<Loading />
					</Case>
					<Case condition={PAGE_STATE.EMPTY}>
						{!searchValue && noticeList.length !== 0 ? (
							<NoData
								main={
									viewType === "album" && !isBoardEmpty
										? t("Community.NoticePage.List.noticeNoData1.main1")
										: t("Community.NoticePage.List.noticeNoData1.main2")
								}
								sub={t("Community.NoticePage.List.noticeNoData1.sub")}
							/>
						) : (
							<NoData main={t("Community.NoticePage.List.noticeNodata2.main")} />
						)}
					</Case>
				</If>
			</section>
		</NavbarAndSidebarLayout>
	);
};

export default NoticeList;
