import React, {useEffect} from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { useController } from "react-hook-form";
import { SolidCloseIcon } from "~/assets/svg/CloseIcon";

const MultiCheckboxSelect = (props) => {
	const {
		control,
		name,
		validation,
		required,
		options,
		placeholder,
		disable,
		selectionLimit,
		selectClassName = "",
		selectedValues = [],
		valueKey,
	} = props;

	const { field } = useController({
		control,
		name,
		rules: {
			required:
				required === true ? "필수 입력 사항 입니다." : required === null ? " " : required,
			...validation,
		},
		defaultValue: selectedValues,
	});


	return (
		<Multiselect
			showCheckbox
			displayValue={valueKey}
			options={options}
			onSelect={(e) => field.onChange(e)}
			customCloseIcon={<SolidCloseIcon />}
			placeholder={placeholder}
			disable={disable}
			selectionLimit={selectionLimit ?? "-1"}
			hidePlaceholder={true}
			className={selectClassName}
			selectedValues={selectedValues}
		/>
	);
};

export default MultiCheckboxSelect;
