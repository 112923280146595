import React, { useState } from "react";

// Components
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import { PAGE_STATE } from "~/common/constants/state";
import Modal from "~/pages/vacation/ManagePage/vacationList/Expected/Modal/Modal";
import List from "~/pages/vacation/ManagePage/vacationList/Expected/List";
import Pagination from "~/common/components/Pagination";
import Empty from "~/pages/vacation/ManagePage/Empty";
import { Case, If } from "~/common/components/If";
import { useTranslation } from "react-i18next";

const Expected = ({
	vacationList,
	pageNumber,
	setPageNumber,
	pagingObject,
	hrUpcomingVacationRequest,
	vacationStatusRequest,
	selectedUser,
	currentPath,
	showAuthAlert,
	pageState,
	titleText,
	timeText,
}) => {
	const { t } = useTranslation();
	const [vacationManageModal, setVacationManageModal] = useState(false);
	const [selectedVacation, setSelectedVacation] = useState({});

	const onSubmit = async (data) => {
		const payload = { ...data, dateAndTimeIdx: selectedVacation.idx };
		vacationStatusRequest
			.asyncCall(payload, "body")
			.then(() =>
				hrUpcomingVacationRequest.call(
					{
						page: pageNumber,
						size: 8,
						userIdx: selectedUser.User.idx,
					},
					"queryString"
				)
			)
			.catch((e) => console.log(e));
		setVacationManageModal(false);
	};

	return (
		<>
			<div className="vacation-chk-header">
				<h3 className="title">{t("HR.Manage.vacationList.scheduleVacation")}</h3>
				{pageState === PAGE_STATE.VIEW && (
					<span className="total-tag">
						{t("HR.Manage.vacationList.Used.total-tag") +
							vacationList.upcoming.totalTime}
					</span>
				)}
			</div>

			<If condition={pageState}>
				<Case condition={PAGE_STATE.VIEW}>
					<div className="vacation-chk-list">
						<List
							{...{
								vacationList,
								setSelectedVacation,
								setVacationManageModal,
								titleText,
								timeText,
								currentPath,
								showAuthAlert,
							}}
						/>
					</div>

					<Pagination
						setPageNumber={setPageNumber}
						currentPage={pageNumber}
						totalPage={pagingObject.upcoming && pagingObject.upcoming.totalPage}
					/>
					{/*예정휴가 리스트 클릭시 모달*/}
					{Object.keys(selectedVacation).length > 0 && (
						<ModalTemplate
							modalState={vacationManageModal}
							setModalToggle={setVacationManageModal}
							title={titleText(selectedVacation)}
							width={460}
							className="vacation-manage-modal"
						>
							<Modal
								{...{
									vacationManageModal,
									setVacationManageModal,
									selectedVacation,
									titleText,
									timeText,
									onSubmit,
								}}
							/>
						</ModalTemplate>
					)}
				</Case>
				<Case condition={PAGE_STATE.EMPTY}>
					<Empty currentTab={"expected"} selectedUser={selectedUser} />
				</Case>
			</If>
		</>
	);
};
export default Expected;
