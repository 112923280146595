import React from "react";
import { useNavigate } from "react-router";
import { ClipIcon } from "~/assets/svg/InterfacesIcon";
import { deleteTagRegex, oneDayPastCheck } from "~/utils/formatter";
import { ALLOW_COMMENT_STATE } from "~/common/constants/state";
import defaultImg from "~/assets/img/defaultImg/default-img.png";
import { useAtomValue } from "jotai";
import { CurrentBoardAtom } from "~/atoms/Community";
import { useTranslation } from "react-i18next";

const CardView = ({ boardList }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const currentBoard = useAtomValue(CurrentBoardAtom);

	return (
		<>
			<div className="community-card-table">
				<div className="c__table">
					<div className="thead">
						<div className="tr">
							<div className="th title">{t("Community.board-table.title")}</div>
							<div className="th name">{t("Community.board-table.name")}</div>
							<div className="th date">{t("Community.board-table.date")}</div>
						</div>
					</div>
					<div className="tbody">
						{boardList.map((board, index) => (
							<div
								className="tr"
								key={index}
								onClick={() => navigate(`post/${board.idx}`)}
							>
								<div className="td title">
									{board?.contentFiles.length !== 0 && (
										<div className="table-image">
											<img
												src={board.contentFiles[0].path}
												alt={t("Community.board-img")}
												onError={(e) => (e.target.src = defaultImg)}
											/>
											<span className="tag">
												{board.contentFiles.length === 1
													? board.contentFiles.length
													: board.contentFiles.length - 1 + " +"}
											</span>
										</div>
									)}
									<div className="table-box">
										<div className="title-box">
											<div className="text">{board.title}</div>
											<div className="icon">
												{currentBoard?.allowFile &&
													board.uploadFiles.length > 0 && (
														<span className="clip">
															<ClipIcon />
														</span>
													)}
												{board.settings[0].value ===
													ALLOW_COMMENT_STATE.ALLOW &&
													board.commentCount !== 0 && (
														<span className="num">
															[{board.commentCount}]
														</span>
													)}
											</div>
										</div>
									</div>
								</div>
								<div className="td name">{board.user.name}</div>
								<div className="td date">{oneDayPastCheck(board.createdAt)}</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default CardView;
