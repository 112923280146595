import React, { useEffect, useState } from "react";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";

import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import { useTranslation } from "react-i18next";

//Assets
import "~/assets/sass/pages/mypage/signature/signature.scss";
import SignatureUploadModal from "~/pages/management/SignaturePage/SignatureUploadModal";
import { dateToString } from "~/utils/formatter";
import View from "./View";
import useInfiniteScroll from "~/common/hooks/useInfiniteScroll";


const SignaturePage = () => {

    const { t } = useTranslation();

    const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

    const [signatureList, setSignatureList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [page, setPage] = useState(1);
    const [deleteSignIdx, setDeleteSignIdx] = useState("");
    const [activeSignIdx, setActiveSignIdx] = useState("");

    const [modalTitle] = useState(t("MyPage.admin.Signature.index.modalTitle"));
    const [signatureUploadModal, setSignatureUploadModal] = useState(false);

    const userSignatureRequest = useRequest("get", `user/signature?size=24&page=${page}`);
    const deleteSignatureRequest = useRequest("delete", `user/signature/delete`);
    const activationSignatureRequest = useRequest("post", `user/signature/activation`);

    const lastElementRef = useInfiniteScroll(pageState, page, setPage, hasMore);

    const getSignatureList = () => {
        setSignatureList([]);
        if (page === 1) {
            setPageState(PAGE_STATE.LOADING);
            userSignatureRequest.call();
        } else {
            setPage(1);
        }
    };

    //서명 리스트 Request
    useEffect(() => {
        setPageState(PAGE_STATE.LOADING);
        userSignatureRequest.call();
    }, [page]);

    //서명 리스트 Response
    useEffect(() => {
        switch (userSignatureRequest.state) {
            case API_STATE.done:
                const responseData = userSignatureRequest.response.data;
                const newData = responseData.list.map((d) => ({
                    ...d,
                    updateDate: dateToString(d.updateDate, "yyyy-MM-dd"),
                }));

                if (responseData.pageable.totalPage === 0) {
                    setPageState(PAGE_STATE.EMPTY);
                } else {
                    if (responseData.pageable.pageNumber === 1) {
                        setSignatureList(newData);
                    } else {
                        setSignatureList([...signatureList, ...newData]);
                    }
                    setPageState(PAGE_STATE.VIEW);
                }
                setHasMore(page !== responseData.pageable.totalPage);
                break;
            case API_STATE.error:
                console.log("error", userSignatureRequest.error);
                break;
            default:
                break;
        }
    }, [userSignatureRequest.state, userSignatureRequest.error, userSignatureRequest.response]);

    //서명 삭제 Request
    useEffect(() => {
        if (deleteSignIdx !== "") {
            deleteSignatureRequest.call(deleteSignIdx, "path");
            setDeleteSignIdx("");
        }
    }, [deleteSignIdx, deleteSignatureRequest]);

    //서명 삭제 Response
    useEffect(() => {
        switch (deleteSignatureRequest.state) {
            case API_STATE.done:
                getSignatureList();
                break;
            case API_STATE.error:
                console.log("error", deleteSignatureRequest.error);
                break;
        }
    }, [
        deleteSignatureRequest.state,
        deleteSignatureRequest.error,
        deleteSignatureRequest.response,
    ]);

    //서명 활성화/비활성화 Request
    useEffect(() => {
        if (activeSignIdx !== "") {
            setActiveSignIdx("");
            activationSignatureRequest.call(activeSignIdx, "path");
        }
    }, [activeSignIdx, activationSignatureRequest]);

    //서명 활성화/비활성화 Response
    useEffect(() => {
        switch (activationSignatureRequest.state) {
            case API_STATE.done:
                getSignatureList();
                break;
            case API_STATE.error:
                console.log("error", activationSignatureRequest.error);
                break;
        }
    }, [
        activationSignatureRequest.state,
        activationSignatureRequest.error,
        activationSignatureRequest.response,
    ]);

    const headerButtons = [
        <button
            type="button"
            className="btn__solid"
            color="primary"
            onClick={() => setSignatureUploadModal(true)}
        >
            {t("button.registering")}
        </button>,
    ];

    return(
       <>
           <NavbarAndSidebarLayout menu={["management", "signature"]} type="main">
               <PageHeader
                   title="개인서명관리"
                   mainTitle="계정관리"
                   subTitle="개인서명관리"
                   buttonList={headerButtons}
               />
               <View
                   {...{
                       signatureList,
                       setActiveSignIdx,
                       setDeleteSignIdx,
                       lastElementRef,
                       pageState,
                   }}
               />
           </NavbarAndSidebarLayout>
           <ModalTemplate
               title={modalTitle}
               modalState={signatureUploadModal}
               setModalToggle={setSignatureUploadModal}
               width={500}
               height={502}
               className="modal-signatureUpload"
           >
               <SignatureUploadModal {...{ getSignatureList }} />
           </ModalTemplate>
       </>
    )
}

export default SignaturePage
