import React from "react";
import { useTranslation } from "react-i18next";

//Assets
import "~/assets/sass/pages/hr/modal/common/detail/memo.scss";
import { Case, If, OtherWise } from "~/common/components/If";
import { dateToString } from "~/utils/formatter";
import NoData from "~/common/components/NoData";

const MemoItem = ({ date, content }) => {
	return (
		<dd className="memo">
			<p className="date">{date}</p>
			<p className="content">{content}</p>
		</dd>
	);
};

const MemoView = ({ userDetail }) => {
	const { t } = useTranslation();
	const { memo } = userDetail;
	const reducedMemo = memo.reduce((acc, cur) => {
		const date = dateToString(cur.date, "yyyy.MM.dd");
		const month = date.substring(0, 7);
		const current = acc[month] || [];

		acc[month] = [...current, { date: date, content: cur.content }];
		return acc;
	}, {});

	const memoList = () => {
		return Object.keys(reducedMemo).map((month, i) => {
			return (
				<dl key={i} className="month-group">
					<dt className="month-title">{month}</dt>
					{reducedMemo[month].map((memo, j) => (
						<MemoItem key={`${i}-${j}`} date={memo.date} content={memo.content} />
					))}
				</dl>
			);
		});
	};
	return (
		<section className="teamInfo-memo detail scroll-wrap">
			<p className="section-title">
				{t("HR.TeamInfo.modal.MemberDetailModal.components.Memo.section-title")}
			</p>
			<If condition={Object.keys(reducedMemo).length}>
				<Case condition={0}>
					<NoData
						main={t("HR.TeamInfo.modal.MemberDetailModal.components.Memo.NoData")}
					/>
				</Case>
				<OtherWise>{memoList()}</OtherWise>
			</If>
		</section>
	);
};

export default MemoView;
