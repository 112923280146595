import React, { createContext, useContext, useReducer } from "react";
import { persistedStates, reducers } from "./reducers";

export const GlobalContext = createContext();

export const GlobalStoreProvider = ({ children }) => (
	<GlobalContext.Provider value={useReducer(reducers, persistedStates)}>
		{children}
	</GlobalContext.Provider>
);

/**
 * @returns {[persistedStates, Function]}
 */
export const useGlobalStore = () => useContext(GlobalContext);
