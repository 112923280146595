import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	CircleArrowRight,
	CircleArrowLeft,
	DisableArrowLeft,
	DisableArrowRight,
} from "~/assets/svg/WhyUsIcon";
import Slider from "react-slick";

export default function SlideHorizontal(props) {
	const sliderRef = useRef(null);
	const { t } = useTranslation();

	const [currentSlide, setCurrentSlide] = useState(0);
	var expandSlides;
	if (window.innerWidth >= 1440) {
		expandSlides = 1;
	} else if (window.innerWidth >=1024) {
  expandSlides = 3
 } else {expandSlides = 4}

	const next = () => {
		if (currentSlide < expandSlides) {
			sliderRef.current.slickNext();
			setCurrentSlide(currentSlide + 1);
		}
	};

	const previous = () => {
		if (currentSlide > 0) {
			sliderRef.current.slickPrev();
			setCurrentSlide(currentSlide - 1);
		}
	};
	const settings = {
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		accessibility: true,
		arrows: true,
		afterChange: (current) => setCurrentSlide(current),
		responsive: [
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<section>
			<div className="mt-4 mb-8">
				<button onClick={previous} className="mr-4">
					{currentSlide > 0 ? <CircleArrowLeft /> : <DisableArrowLeft />}
				</button>
				<button onClick={next}>
					{currentSlide < expandSlides ? <CircleArrowRight /> : <DisableArrowRight />}
				</button>
			</div>
			<div className="slider-container">
				<Slider ref={sliderRef} {...settings}>
					{props.props.map((item, index) => (
						<div
							key={index}
							className="border border-slate-300 border-solid p-6 rounded-xl !w-[90%] !mr-4"
						>
							<div className="pb-4">{item.icon}</div>
							<div className="text-xl font-semibold pb-4 md:h-[140px] h-[100px]">{item.title}</div>
							<div className="md:h-[200px] h-[190px]">{item.description}</div>
							{/* <div className="cursor-pointer underline underline-8 font-semibold">
								{t("WhyUs.solutions.learn-more")}
							</div> */}
						</div>
					))}
				</Slider>
			</div>
		</section>
	);
}
