import React, { useEffect, useState } from "react";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import { Case, If } from "~/common/components/If";
import Fee from "~/pages/mypage/admin/LicensePage/fee";
import Purchase from "~/pages/mypage/admin/LicensePage/purchase";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import useAlert from "~/common/hooks/useAlert";
import { useNavigate } from "react-router";
import { DateWithDayName } from "~/utils/formatter";
import { Helmet } from "react-helmet-async";
import { getTitle } from "~/utils/getTitle";
import { useTranslation } from "react-i18next";
import { useGlobalStore } from "~/store";

const LicensePage = () => {
	const { t } = useTranslation();
	const MENU = ["admin", "license"];
	const TYPE = "myPage";
	const [
		{
			user: { company },
		},
	] = useGlobalStore();
	const location = useLocation();
	const navigate = useNavigate();
	const qs = queryString.parse(location.search);

	const [page, setPage] = useState(qs.info === "fail" ? "purchase" : "fee");
	const [purchaseInfo, setPurchaseInfo] = useState({});
	const [period, setPeriod] = useState("month");
	const [freeLicense, setFreeLicense] = useState(false);

	const [showSuccessAlert, SuccessAlert] = useAlert();
	const [showApplyAlert, ApplyAlert] = useAlert();
	const [showUnsubscriptionAlert, UnsubscriptionAlert] = useAlert();
	const [showExpiredLicenseAlert, ExpiredLicenseAlert] = useAlert();

	const purchaseInfoRequest = useRequest("get", "admin/purchase/info");
	const unsubscriptionRequest = useRequest("get", "admin/subscription/deactivate");

	const resetPage = () => {
		purchaseInfoRequest.call();
		qs &&
			qs.info === "success" &&
			showSuccessAlert().then(() => {
				navigate("/myPage/admin/license");
			});
	};

	const handleApplyButton = () => {
		if (company.license === "free") {
			setPage("purchase");
			return;
		}
		setFreeLicense(true);
		showUnsubscriptionAlert().then(() => {
			unsubscriptionRequest.call();
		});
	};

	useEffect(() => {
		resetPage();
	}, []);

	useEffect(() => {
		switch (purchaseInfoRequest.state) {
			case API_STATE.done:
				const response = purchaseInfoRequest.response.data;
				setPurchaseInfo({
					...response.purchaseHistory,
					members: response.invitedUsersCnt,
					licenseStatus:
						Object.keys(purchaseInfoRequest.response.data).length <= 1
							? "free"
							: response.purchaseHistory.activation === true &&
							  response.purchaseHistory.expired === false
							? "pro"
							: "free",
				});
				break;
			case API_STATE.error:
				break;
		}
	}, [purchaseInfoRequest.state]);

	useEffect(() => {
		switch (unsubscriptionRequest.state) {
			case API_STATE.done:
				showSuccessAlert();
				purchaseInfoRequest.call();
				break;
			case API_STATE.error:
				navigate("/404");
				break;
		}
	}, [unsubscriptionRequest.state]);

	return (
		<NavbarAndSidebarLayout menu={MENU} type={TYPE} menuName={`license-${page}`}>
			<Helmet>
				<title>{getTitle(t("MyPage.admin.License.index.title"))}</title>
			</Helmet>
			<PageHeader />
			<If
				condition={page}
				{...{
					setPage,
					purchaseInfo,
					handleApplyButton,
					resetPage,
					period,
					setPeriod,
					setPurchaseInfo,
					qs,
					showSuccessAlert,
					showApplyAlert,
					ApplyAlert,
					showUnsubscriptionAlert,
					UnsubscriptionAlert,
					unsubscriptionRequest,
					showExpiredLicenseAlert,
					ExpiredLicenseAlert,
				}}
			>
				<Case condition={"fee"}>
					<Fee />
				</Case>
				<Case condition={"purchase"}>
					<Purchase />
				</Case>
			</If>
			<SuccessAlert
				type="success"
				confirm={t("button.check")}
				width={480}
				height={250}
				message={
					<>
						<h3>{t("MyPage.admin.License.index.SuccessAlert.h3")}</h3>
						{freeLicense && (
							<p>
								{DateWithDayName(purchaseInfo.expDate)}{" "}
								{t("MyPage.admin.License.index.SuccessAlert.p")}
							</p>
						)}
					</>
				}
			/>
		</NavbarAndSidebarLayout>
	);
};

export default LicensePage;
