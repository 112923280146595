import React from "react";
import PageHeader from "~/common/components/PageHeader";
import { useNavigate } from "react-router-dom";
// Assets
import "~/assets/sass/pages/community/boardPage/boardPage.scss";
import { BookMarkIcon, ProfileIcon } from "~/assets/svg/InterfacesIcon";
import noImg from "~/assets/img/defaultImg/default-img.png";
import DropdownTemplate, {
	DropDownItem,
} from "~/common/components/DropdownPortal/DropdownTemplate";
import useRequest from "~/common/hooks/useRequest";
import useAlert from "~/common/hooks/useAlert";
import { useAtom, useSetAtom } from "jotai";
import { BoardListAtom, CurrentBoardAtom, MyAuthAtom } from "~/atoms/Community";
import { useTranslation } from "react-i18next";

const View = ({ menu, type, boardListRequest }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const setCurrentBoard = useSetAtom(CurrentBoardAtom);
	const setMyAuth = useSetAtom(MyAuthAtom);
	const [boardList, setBoardList] = useAtom(BoardListAtom);
	const [showDeleteAlert, DeleteAlert] = useAlert();

	const headerButton = [
		<button
			className="btn__solid"
			color={"primary"}
			onClick={() => navigate("/community/list/add")}
		>
			{t("button.registering")}
		</button>,
	];

	const boardRequest = useRequest("get", `community`);
	const boardRemoveRequest = useRequest("delete", `community`);

	const favoriteRequest = useRequest("patch", `community/favorite`);

	const dispatchCurrentBoard = (data) => {
		const allowFileValue = data.Settings.find((s) => s.type === "ALLOW_FILE_UPLOAD").value;
		const isAccessLevelPublic = data.Settings.find((s) => s.type === "ACCESS_LEVEL").value;
		setCurrentBoard({
			...data,
			allowFile:
				allowFileValue === "ALLOW_FILE_UPLOAD_ALLOW"
					? true
					: allowFileValue === "ALLOW_FILE_UPLOAD_NOT_ALLOW"
					? false
					: undefined,
			isAccessLevelPublic:
				isAccessLevelPublic === "ACCESS_LEVEL_PUBLIC"
					? true
					: isAccessLevelPublic === "ACCESS_LEVEL_PRIVATE"
					? false
					: undefined,
		});
	};

	const cardList = () => {
		return boardList.map((board, index) => {
			const handleNavigateView = () => {
				boardRequest
					.asyncCall(board.idx, "path")
					.then(({ data }) => {
						dispatchCurrentBoard(data);
						setMyAuth(board.myAuth);
					})
					.finally(() => {
						navigate(`board/${board.idx}`);
					});
			};
			const handleNavigateEdit = () => {
				boardRequest
					.asyncCall(board.idx, "path")
					.then(({ data }) => {
						dispatchCurrentBoard(data);
					})
					.finally(() => {
						navigate(`add`);
					});
			};
			const handleRemove = () => {
				showDeleteAlert().then(() =>
					boardRemoveRequest
						.asyncCall(board.idx, "path")
						.then(boardListRequest.call)
						.catch()
				);
			};
			const handleFavorite = (event) => {
				event.stopPropagation();
				favoriteRequest
					.asyncCall(board.idx, "path")
					.then(({ data }) => {
						const newBoardList = boardList;
						newBoardList[index].isFavorite = data.isFavorite;
						setBoardList(newBoardList);
					})
					.finally(() => boardListRequest.call());
			};
			return (
				<div className="c__card" key={index} onClick={handleNavigateView}>
					<div className="custom-badge">
						<div className="badge-icon">
							<ProfileIcon />
							<b>{board.memberCount}</b>
						</div>
						<button
							type="button"
							onClick={handleFavorite}
							className={`badge-btn ${board.isFavorite ? "active" : ""}`}
						>
							<BookMarkIcon />
						</button>
					</div>
					<div className="community-img">
						<img
							src={board.imgPath ?? noImg}
							onError={(e) => {
								e.target.src = noImg;
							}}
							alt={t("Community.BoardPage.BoardList.View.community-img")}
						/>
					</div>
					<div className="community-container">
						<div className="community-header">
							<h3 className="community-title">{board.name}</h3>
							<div onClick={(event) => event.stopPropagation()}>
								{board.myAuth === "MANAGER" && (
									<DropdownTemplate target={index} vertical>
										<DropDownItem onClick={handleNavigateEdit}>
											{t("button.modify")}
										</DropDownItem>
										<DropDownItem onClick={handleRemove}>
											{t("button.delete")}
										</DropDownItem>
									</DropdownTemplate>
								)}
							</div>
						</div>
						<div className="community-content">{board.description}</div>
					</div>
				</div>
			);
		});
	};
	return (
		<>
			<PageHeader {...{ menu, type }} buttonList={headerButton} />
			<div className="c__cardRow community-list-card">{cardList()}</div>
			<DeleteAlert
				type="error"
				width={430}
				height={306}
				layout="horiz"
				message={
					<>
						<h3>{t("Community.BoardPage.BoardList.View.DeleteAlert.h3")}</h3>
						<p>{t("Community.BoardPage.BoardList.View.DeleteAlert.p")}</p>
					</>
				}
				desc={
					<div className="desc-box error pre-wrap">
						{t("Community.BoardPage.BoardList.View.DeleteAlert.desc-box")}
					</div>
				}
				confirm={t("button.check")}
				reject={t("button.cancel")}
			/>
		</>
	);
};

export default View;
