import React from "react";
import { RouterProvider } from "react-router";
import * as Sentry from "@sentry/react";
import { routers } from "./GenaralRouter";

const Router = () => {
	return (
		<Sentry.ErrorBoundary>
			<RouterProvider router={routers}></RouterProvider>
		</Sentry.ErrorBoundary>
	);
};

export default Router;
