import React, { useState } from "react";

// Components
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";

// Assets
import "~/assets/sass/pages/mypage/password/password.scss";
import PageHeader from "~/common/components/PageHeader";
import Validate from "./Validate";
import Change from "./Change";
import { Case, If } from "~/common/components/If";

const PasswordPage = () => {
	const MENU = ["account", "pw"];
	const TYPE = "myPage";
	const [step, setStep] = useState("validate");
	return (
		<NavbarAndSidebarLayout menu={MENU} type={TYPE}>
			<PageHeader hasBreadCrumb={true} />
			<If condition={step}>
				<Case condition={"validate"}>
					<Validate {...{ setStep }} />
				</Case>
				<Case condition={"change"}>
					<Change {...{ setStep }} />
				</Case>
			</If>
		</NavbarAndSidebarLayout>
	);
};

export default PasswordPage;
