import React from 'react';

const ButtonWave = (props) => {
    const {
        text="",
        onClick=()=>{},
        className="",
        ...rest
    } = props;

    const handleGetText = (text) => {
        const textArray = text.split("");
        if(textArray?.length > 0) {
            return textArray?.map((txt, idx) => (
                <span key={`${txt}_${idx}`}>{txt}</span>
            ))
        } else {
            return ""
        }

    }

    return(
        <button type="button"
                className={`${className} text-up`}
                onClick={onClick}
                {...rest}
                >
            { text !== "" && handleGetText(text) }
        </button>
    )
}

export default ButtonWave
