import Form, { Group } from "~/common/components/Form";
import Select from "~/common/components/Select";
import Textarea from "~/common/components/Textarea";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import NumberInput from "~/common/components/NumberInput";
import Input from "~/common/components/Input";
import { useTranslation } from "react-i18next";

const ManageAnnual = ({
	setAnnualManageModal,
	selectedUser,
	remainingTimeRequest,
	historyRequest,
}) => {
	const { t } = useTranslation();
	const annualVacationSettingsRequest = useRequest(
		"get",
		`hr/vacation/individual/setting/${selectedUser.User.idx}`
	);
	const [vacationSetting, setVacationSetting] = useState({});
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	useEffect(() => {
		annualVacationSettingsRequest.call();
	}, [selectedUser]);

	useEffect(() => {
		switch (annualVacationSettingsRequest.state) {
			case API_STATE.done:
				setVacationSetting(annualVacationSettingsRequest.response.data);
				setPageState(PAGE_STATE.VIEW);
				break;
			default:
				break;
		}
	}, [annualVacationSettingsRequest.state]);

	const onSubmit = async (data) => {
		const days = data.days * data.unit;
		const hours = data.unit * data.hours;
		const payload = {
			days: days,
			hours: hours,
			memo: data.memo,
			userIdx: selectedUser.User.idx,
		};
		remainingTimeRequest.asyncCall(payload, "body").then(() => {
			historyRequest.call(
				{
					page: 1,
					size: 8,
					userIdx: selectedUser.User.idx,
					year: new Date().getFullYear(),
				},
				"queryString"
			);
			setAnnualManageModal(false);
		});
	};

	const pText = (settings) => {
		switch (settings.unit) {
			case t("HR.Manage.half"):
				return t("HR.Manage.vacationList.Modal.ManageAnnual.pText1");
			case t("HR.Manage.day"):
				return t("HR.Manage.vacationList.Modal.ManageAnnual.pText2");
		}
	};

	const annualGrantForm = useForm();

	return (
		<>
			{pageState === PAGE_STATE.VIEW && (
				<>
					<div className="modal-content">
						<Form useForm={annualGrantForm} className="annual-grant-form">
							<Select
								label={t("HR.Manage.vacationList.Modal.ManageAnnual.SelectLabel")}
								name="unit"
								className="manage-type-wrap"
								options={[
									{
										label: t(
											"HR.Manage.vacationList.Modal.ManageAnnual.SelectOption1"
										),
										value: 1,
									},
									{
										label: t(
											"HR.Manage.vacationList.Modal.ManageAnnual.SelectOption2"
										),
										value: -1,
									},
								]}
								defaultValue={1}
							/>
							{vacationSetting.unit === "시간" ? (
								<Group label={t("HR.Manage.payday-wrap")} className="payday-wrap">
									<NumberInput name="days" unit="일" />
									<NumberInput name="hours" unit="시간" />
								</Group>
							) : (
								<>
									<Input
										label={t("HR.Manage.payday-wrap")}
										name="days"
										unit={t("HR.Manage.day")}
										type={"number"}
										className="payday-wrap"
										required={null}
										validation={
											vacationSetting.unit === "반차"
												? {
														pattern: {
															value: /^[0-9]{1,3}$|.[5]/,
															message: " ",
														},
												  }
												: {
														pattern: {
															value: /^[0-9]{1,3}$/,
															message: "",
														},
												  }
										}
									/>
								</>
							)}
							<p
								className={`desc ${
									annualGrantForm.formState.errors.days !== undefined && "error"
								}`}
							>
								{pText(vacationSetting)}
							</p>
						</Form>
						<Form useForm={annualGrantForm} className="annual-grant-form vertical">
							<Textarea
								name={"memo"}
								label={t("HR.Manage.memo")}
								className="memo-wrap"
							/>
						</Form>
					</div>
					<div className="modal-footer">
						<button
							className="btn__solid"
							color="gray"
							onClick={() => setAnnualManageModal(false)}
						>
							{t("button.cancel")}
						</button>
						<button
							className="btn__solid"
							color="primary"
							onClick={annualGrantForm.handleSubmit(onSubmit)}
						>
							{t("button.check")}
						</button>
					</div>
				</>
			)}
		</>
	);
};
export default ManageAnnual;
