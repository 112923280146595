import { RoundArrowIcon, SearchIcon } from "~/assets/svg/InterfacesIcon";
import React from "react";
import noImg from "~/assets/img/defaultImg/profile-large.png";
import { Case, If } from "~/common/components/If";
import { PAGE_STATE } from "~/common/constants/state";
import { optionsGenerator } from "~/common/components/Select";
import CustomSelect from "~/common/components/CustomSelect";
import useDebounce from "~/common/hooks/useDebounce";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const Coworkers = ({
	selectedUser,
	coworkers,
	setSelectedUser,
	setCoworkerPage,
	coworkerPage,
	setInput,
	onSubmit,
	page,
	searchType,
	setPageNumber,
	setSearchType,
	coworkerStatus,
	setCoworkerStatus,
}) => {
	const { t } = useTranslation();
	const searchOptions = {
		name: translation.t("HR.Manage.List.name"),
		id: translation.t("HR.Manage.List.id"),
		department: translation.t("HR.Manage.List.department"),
		position: translation.t("HR.Manage.List.position"),
	};
	const statusOptions = ["All", "근무중", "휴직", "퇴직"];

	return (
		<section className="c__card member-list-card">
			<div className="top">
				<div className="wrap">
					<CustomSelect
						value={searchType}
						setValue={setSearchType}
						options={optionsGenerator(searchOptions)}
					/>
					<div className="c__search">
						<input
							className="c__input"
							placeholder="Search"
							onChange={useDebounce((r) => {
								setInput(r.target.value);
							}, 300)}
						/>
						<button type="button" className="btn-search" onClick={() => onSubmit()}>
							<SearchIcon />
						</button>
					</div>
				</div>
				<ul className="keyword-list">
					{statusOptions.map((option, options_idx) => {
						return (
							<li
								key={options_idx}
								className={`keyword ${coworkerStatus === option ? "active" : null}`}
								onClick={() => {
									setCoworkerStatus(option);
									setCoworkerPage(1);
								}}
							>
								{option}
							</li>
						);
					})}
				</ul>
			</div>
			<If condition={page}>
				<Case condition={PAGE_STATE.VIEW}>
					<ul className="member-list">
						{coworkers.foundUsers.map((c) => {
							return (
								<li
									className={`row ${
										selectedUser.User.idx === c.User.idx ? "active" : ""
									}`}
									key={c.idx}
									onClick={() => {
										setSelectedUser(c);
										setPageNumber(1);
									}}
								>
									<div className="profile">
										<img src={c.User.img || noImg} alt={t("Img.profile")} />
									</div>

									<div className="wrap">
										<div>
											<div className="info-wrap">
												<div className="name">
													{c.User.UserProfile.name}
													<If condition={c.User.status}>
														<Case condition="대기">
															<span className="standby">
																({t("HR.Manage.List.waiting")})
															</span>
														</Case>
														<Case condition="탈퇴">
															<span className="withdraw">
																({t("HR.Manage.List.withdrawal")})
															</span>
														</Case>
													</If>
												</div>
												<div className="position">
													{c.User.UserProfile.department || "-"}/
													{c.User.UserProfile.position || "-"}
												</div>
											</div>
											<div className="email">{c.User.id}</div>
										</div>
										<div className="working-status">
											<If condition={c.User.UserProfile.employmentStatus}>
												<Case condition={0}>
													<span className="working">
														{t("HR.Manage.work")}
													</span>
												</Case>
												<Case condition={2}>
													<span className="absence">
														{t("HR.Manage.leave")}
													</span>
												</Case>
												<Case condition={3}>
													<span className="quit">
														{t("HR.Manage.resign")}
													</span>
												</Case>
											</If>
										</div>
									</div>
								</li>
							);
						})}
					</ul>
				</Case>
				<Case condition={PAGE_STATE.EMPTY}>
					<NoData className={"member-list"} main={t("HR.Manage.List.NoData")} />
				</Case>
			</If>
			<div className="tab-footer">
				<button
					className="prev-btn"
					onClick={() => setCoworkerPage(coworkerPage - 1)}
					disabled={coworkerPage <= 1}
				>
					<RoundArrowIcon />
					<span>{t("button.prev")}</span>
				</button>
				<button
					className="next-btn"
					onClick={() => setCoworkerPage(coworkerPage + 1)}
					disabled={coworkers.pageable.totalPage <= coworkerPage}
				>
					<span>{t("button.next")}</span>
					<RoundArrowIcon />
				</button>
			</div>
		</section>
	);
};
export default Coworkers;
