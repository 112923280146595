import React, { useEffect, useState } from "react";
import Form, { Group } from "~/common/components/Form";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import { useGlobalStore } from "~/store";
import Uploader from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/file/uploader";
import { useTranslation } from "react-i18next";

const File = ({ useForm, form, apply }) => {
	const { t } = useTranslation();
	const [
		{
			user: {
				company: { name },
			},
		},
	] = useGlobalStore();

	const [answer, setAnswer] = useState({ name: "", url: "" });

	useEffect(() => {
		if (apply) {
			// console.log("지원해서 답변 있을 때만");
			if (form?.answer?.search("name") > 0) {
				let answerObject = JSON.parse(form.answer);
				setAnswer({ name: answerObject.name, url: answerObject.url });
			} else {
				let tempName = name + "_" + form.title;
				setAnswer({ name: tempName, url: form.answer });
			}
		}
	}, []);

	/*const [tempFileName, setTempFileName] = useState();

	const tempAnswer = useForm.watch(form.order.toString());

	useEffect(() => {
		if (tempAnswer) {
			let answerObject = JSON.parse(tempAnswer);
			setTempFileName(answerObject?.name);
		}
	}, [tempAnswer]);

	console.log("watch", useForm.watch(form.order.toString()));*/

	return (
		<>
			{apply ? (
				<>
					<div className="c__form horiz">
						<div className="f__group  required">
							<label className="f__label">{form.title}</label>
							<div className="f__control-wrap">
								<div className="f__control  ">
									<a
										href={answer?.url}
										target="_blank"
										rel="noreferrer"
										style={{ lineHeight: "35px", height: 35 }}
									>
										{answer?.name}
									</a>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<Form useForm={useForm} className="horiz file-form">
					<Group label={form.title} required={form.required}>
						<Uploader
							name={form.order.toString()}
							type={UPLOAD_TYPE.ROUND_SUBMIT}
							required={form.required}
						/>
						<ul className="note-list">
							<li style={{ whiteSpace: "pre-wrap", lineBreak: "anywhere" }}>
								{form.content}
							</li>
							<li className="default-note">
								{t("Invest.Round.RoundDetailModal.Edit.componentFile.default-note")}
							</li>
						</ul>
					</Group>
				</Form>
			)}
		</>
	);
};

export default File;
