import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import translation from "~/translations";

// Components
import Form from "~/common/components/Form";
import NumberInput from "~/common/components/NumberInput";
import Select from "~/common/components/Select";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";

// Assets
import "~/assets/sass/pages/mypage/admin/license/purchase.scss";
import { loadTossPayments } from "@tosspayments/payment-sdk";
import { useGlobalStore } from "~/store";
import useRequest from "~/common/hooks/useRequest";
import { TOSS_CLIENT_KEY } from "~/common/constants/envKeys";
import { thousandFormatter, CardNumberFormatter } from "~/utils/formatter";
import { LICENSE_PERIOD } from "~/common/constants/form";
import { API_STATE } from "~/common/constants/state";
import { useNavigate } from "react-router";
import useAlert from "~/common/hooks/useAlert";
import { WEB_URL } from "~/common/constants/env";
import { CARD_METADATA } from "~/common/constants/payment";
import Input from "~/common/components/Input";
import { Trans, useTranslation } from "react-i18next";

const TOSS_ERROR_CODE = {
	INVALID_CARD_NUMBER() {
		return <h3>{translation.t("MyPage.admin.License.purchase.invalid-card-number")}</h3>;
	},
	NOT_SUPPORTED_CARD_TYPE() {
		return <h3>{translation.t("MyPage.admin.License.purchase.not-supported-card-type")}</h3>;
	},
	INVALID_CARD_PASSWORD() {
		return <h3>{translation.t("MyPage.admin.License.purchase.invalid-card-password")}</h3>;
	},
	INVALID_CARD_EXPIRATION() {
		return <h3>{translation.t("MyPage.admin.License.purchase.invalid-card-expiration")}</h3>;
	},
	INVALID_CARD_IDENTITY() {
		return <h3>{translation.t("MyPage.admin.License.purchase.invalid-card-identify")}</h3>;
	},
	INVALID_REJECT_CARD() {
		return (
			<>
				<h3>{translation.t("MyPage.admin.License.purchase.invalid-reject-card")}</h3>
				<p> {translation.t("MyPage.admin.License.purchase.invalid-reject-card-sub")}</p>
			</>
		);
	},
	INVALID_STOPPED_CARD() {
		return <h3>{translation.t("MyPage.admin.License.purchase.invalid-stopped-card")}</h3>;
	},
	INVALID_BIRTH_DAY_FORMAT() {
		return (
			<>
				<h3>{translation.t("MyPage.admin.License.purchase.invalid-birthday-format")}</h3>
				<p>{translation.t("MyPage.admin.License.purchase.invalid-birthday-format-sub")}</p>
			</>
		);
	},
	NOT_REGISTERED_CARD_COMPANY() {
		return (
			<h3>{translation.t("MyPage.admin.License.purchase.not-registered-card-company")}</h3>
		);
	},
	INVALID_EMAIL() {
		return <h3>{translation.t("MyPage.admin.License.purchase.invalid-email")}</h3>;
	},
	NOT_SUPPORTED_METHOD() {
		return <h3>{translation.t("MyPage.admin.License.purchase.not-supported-method")}</h3>;
	},
	INVALID_REQUEST() {
		return (
			<>
				<h3>{translation.t("MyPage.admin.License.purchase.invalid-request")}</h3>
				<p>{translation.t("MyPage.admin.License.purchase.invalid-request-sub")}</p>
			</>
		);
	},
	EXCEED_MAX_AUTH_COUNT() {
		return (
			<>
				<h3>{translation.t("MyPage.admin.License.purchase.exceed-max-auth-count")}</h3>
				<p>{translation.t("MyPage.admin.License.purchase.exceed-max-auth-count-sub")}</p>
			</>
		);
	},
	REJECT_CARD_COMPANY() {
		return <h3>{translation.t("MyPage.admin.License.purchase.reject-card-company")}</h3>;
	},
	REJECT_ACCOUNT_PAYMENT() {
		return <h3>{translation.t("MyPage.admin.License.purchase.reject-account-payment")}</h3>;
	},
	PAY_PROCESS_CANCELED() {
		return <h3>{translation.t("MyPage.admin.License.purchase.pay-process-canceled")}</h3>;
	},
	PAY_PROCESS_ABORTED() {
		return <h3>{translation.t("MyPage.admin.License.purchase.pay-process-aborted")}</h3>;
	},
	COMMON_ERROR() {
		return (
			<>
				<h3>{translation.t("MyPage.admin.License.purchase.common-error")}</h3>
				<p>{translation.t("MyPage.Alert")}</p>
			</>
		);
	},
};

const Purchase = ({ setPage, purchaseInfo, resetPage, period, showSuccessAlert, qs }) => {
	const { t } = useTranslation();
	const [{ user }] = useGlobalStore();
	const purchaseForm = useForm({
		defaultValues: { userName: user.info.name, userEmail: user.info.id },
	});
	const customerKeyRequest = useRequest("get", "admin/customerKey");
	const executePurchaseProcessRequest = useRequest("post", "admin/subscription/payment");
	const activatePromotion = useRequest("get", "admin/subscription/activate");
	const defaultCardRequest = useRequest("get", "admin/purchase/option");

	const [showAlert, InfoAlert] = useAlert();
	const [showCheckAlert, CheckAlert] = useAlert();

	const [refundPolicyModal, setRefundPolicyModal] = useState(false);
	const [defaultCard, setDefaultCard] = useState();

	const navigate = useNavigate();

	const overInvitedUserCount = purchaseInfo.members < 6 ? 6 : purchaseInfo.members;
	const isPromotion = purchaseInfo.promotion ?? true;

	const subscription = async (data) => {
		const tossPayments = await loadTossPayments(TOSS_CLIENT_KEY);
		const customerKey = await customerKeyRequest.asyncCall().then((r) => r.Company.customerKey);

		return await tossPayments.requestBillingAuth("카드", {
			customerKey,
			customerName: user.info.name,
			customerEmail: user.info.id,
			successUrl: `${WEB_URL}myPage/admin/license/success?phone=${data.phone}&bizNum=${data.bizNum}&email=${user.info.id}`,
			failUrl: `${WEB_URL}myPage/admin/license/fail`,
		});
	};

	const afterPurchase = () => {
		resetPage();
		setPage("fee");
		showSuccessAlert().then(() => navigate("/myPage/admin/license"));
	};

	const defaultCardCheck = (data) => {
		executePurchaseProcessRequest.call(
			{
				amount: overInvitedUserCount * LICENSE_PERIOD[period],
				phone: data.phone,
				bizNum: data.bizNum,
				mode: "web",
			},
			"body"
		);
	};

	const onSubmit = async (data) => {
		if (Object.keys(defaultCard).length !== 0) {
			showCheckAlert().then(() => defaultCardCheck(data));
			return;
		}
		if (Object.keys(purchaseInfo).length <= 2) {
			await subscription(data);
			return;
		}
		if (purchaseInfo.remainder === 1 && isPromotion) {
			activatePromotion.call();
			return;
		}
		if (purchaseInfo.remainder === 0 && isPromotion) {
			await subscription(data);
			return;
		}
		await subscription(data);
	};

	const checkQueryString = () => {
		Object.keys(qs).length !== 0 &&
			showAlert().then(() => {
				navigate("/myPage/admin/license");
			});
	};

	useEffect(() => {
		checkQueryString();
	}, []);

	useEffect(() => {
		defaultCardRequest.call();
	}, []);

	useEffect(() => {
		switch (defaultCardRequest.state) {
			case API_STATE.done:
				const response = defaultCardRequest.response.data;
				setDefaultCard(response.find((card) => card.default === 1) ?? {});
				break;
		}
	}, [defaultCardRequest.state]);

	useEffect(() => {
		switch (executePurchaseProcessRequest.state) {
			case API_STATE.done:
				afterPurchase();
				break;
			case API_STATE.error:
				navigate("/404");
				break;
		}
	}, [executePurchaseProcessRequest.state]);

	useEffect(() => {
		switch (activatePromotion.state) {
			case API_STATE.done:
				afterPurchase();
				break;
			case API_STATE.error:
				navigate("/404");
				break;
		}
	}, [activatePromotion.state]);

	return (
		<>
			<dl className="purchase-top-msg">
				<dt>{t("MyPage.admin.License.purchase.purchase-top-msg.dt")}</dt>
				<dd>{t("MyPage.admin.License.purchase.purchase-top-msg.dd")}</dd>
			</dl>
			<div className="c__card">
				<section className="purchase-info">
					<div className="column">
						<h2 className="form-title">
							{t("MyPage.admin.License.purchase.form-title")}
						</h2>
						<Form useForm={purchaseForm} className="purchase-form vertical">
							<div className="f__group">
								<label className="f__label">
									{t("MyPage.admin.License.purchase.info.name")}
								</label>
								<div className="f__control">
									<div className="c__input readonly">{user.info.name}</div>
								</div>
							</div>
							<div className="f__group">
								<label className="f__label">
									{t("MyPage.admin.License.purchase.info.id")}
								</label>
								<div className="f__control">
									<div className="c__input readonly">{user.info.id}</div>
								</div>
							</div>
							<NumberInput
								label={t("MyPage.admin.License.purchase.phone")}
								name="phone"
								placeholder="010-0000-0000"
								thousandSeparator={false}
								type={"phone"}
								required
							/>
							<Input
								label={t("MyPage.admin.License.purchase.bizNum.label")}
								name="bizNum"
								placeholder="0000000000 / 890101"
								validation={{
									minLength: {
										value: 6,
										message: t(
											"MyPage.admin.License.purchase.bizNum.minLength"
										),
									},
									maxLength: {
										value: 10,
										message: t(
											"MyPage.admin.License.purchase.bizNum.maxLength"
										),
									},
								}}
								required
							/>
						</Form>
					</div>
					<div className="refund-policy">
						<p className="refund-content">
							<p className="refund-title pre">
								{t("MyPage.admin.License.purchase.refund-policy.title")}
							</p>
							<p className="refund-content pre">
								<Trans
									i18nKey="MyPage.admin.License.purchase.refund-policy.content"
									components={[<b />]}
								/>
							</p>
						</p>
						<button
							type="button"
							className="btn-detail"
							onClick={() => setRefundPolicyModal(true)}
						>
							{t("MyPage.admin.License.purchase.refund-policy.btn-detail")}
						</button>
					</div>
				</section>
				<section className="purchase-confirm ir-consulting">
					<h2 className="form-title">
						{t("MyPage.admin.License.purchase.purchase-confirm.title")}
					</h2>
					<dl className="buy-product">
						<dt>
							{t("MyPage.admin.License.purchase.purchase-confirm.buy-product.dt")}
						</dt>
						<dd>
							{t("MyPage.admin.License.purchase.purchase-confirm.buy-product.dd")}
						</dd>
					</dl>
					<Form useForm={purchaseForm} className="confirm-form vertical">
						<Select
							label={t("MyPage.admin.License.purchase.purchase-confirm.itemOption")}
							name="itemOption"
							options={[
								{
									label: translation.t(
										"MyPage.admin.License.purchase.purchase-confirm.option.month"
									),
									value: "정기결제(월결제)",
								},
								// { label: "정기결제(연결제), value: "정기결제(연결제)" },
							]}
							defaultValue="정기결제(월결제)"
						/>
						<div className="f__group">
							<div className="f__label">
								{t("MyPage.admin.License.purchase.purchase-confirm.label")}
							</div>
							<div className="f__control">
								<dl className="item-sum">
									<div className="item-top">
										<div>
											<dt>
												{t(
													"MyPage.admin.License.purchase.purchase-confirm.sum"
												)}
											</dt>
											<dd>
												<b>
													{thousandFormatter(
														LICENSE_PERIOD[period].money *
															overInvitedUserCount
													)}
												</b>{" "}
												{t(
													"MyPage.admin.License.purchase.purchase-confirm.won"
												)}
											</dd>
										</div>
									</div>
									<div className="item-bottom">
										<div>
											<dt>
												└ Pro{" "}
												{thousandFormatter(LICENSE_PERIOD[period].money)}
												{t(
													"MyPage.admin.License.purchase.purchase-confirm.won"
												)}
												({LICENSE_PERIOD[period].date}){" "}
												{t(
													"MyPage.admin.License.purchase.purchase-confirm.invited"
												)}{" "}
												({purchaseInfo.members}
												{t(
													"MyPage.admin.License.purchase.purchase-confirm.people"
												)}
												)
											</dt>
											<dd>
												{thousandFormatter(
													LICENSE_PERIOD[period].money *
														overInvitedUserCount
												)}{" "}
												{t(
													"MyPage.admin.License.purchase.purchase-confirm.won"
												)}
											</dd>
										</div>
										<div>
											<dt>
												└{" "}
												{t(
													"MyPage.admin.License.purchase.purchase-confirm.discount"
												)}
											</dt>
											<dd>
												-{" "}
												{isPromotion
													? thousandFormatter(
															LICENSE_PERIOD[period].money *
																overInvitedUserCount
													  )
													: "0"}{" "}
												{t(
													"MyPage.admin.License.purchase.purchase-confirm.won"
												)}
											</dd>
										</div>
									</div>
								</dl>
								<dl className="price">
									<dt>
										{t("MyPage.admin.License.purchase.purchase-confirm.price")}
									</dt>
									<dd>
										<b>
											{isPromotion
												? 0
												: thousandFormatter(
														LICENSE_PERIOD[period].money *
															overInvitedUserCount
												  )}
										</b>{" "}
										{t("MyPage.admin.License.purchase.purchase-confirm.won")}
									</dd>
								</dl>
							</div>
						</div>
					</Form>
					<div className="purchase-agree">
						<div className="f__group">
							<div
								className={`f__control ${
									purchaseForm.formState.errors.agree ? "has-error" : ""
								}`}
							>
								<div className={`checkbox`}>
									<label className={`checkbox-custom`}>
										<input
											type="checkbox"
											{...purchaseForm.register("agree", {
												required: true,
											})}
										/>
										<span className="checkmark" />
										<span className="checkbox-label">
											{t(
												"MyPage.admin.License.purchase.purchase-agree.label"
											)}
										</span>
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className="btn-wrap">
						<button
							type="button"
							className="btn__solid angled"
							color="gray"
							onClick={() => setPage("fee")}
						>
							{t("button.cancel")}
						</button>
						{/*<button type="button" className="btn__solid angled" color="primary" onClick={()=>oneTimePayment()}>*/}
						<button
							type="button"
							className="btn__solid angled"
							color="primary"
							onClick={purchaseForm.handleSubmit(onSubmit)}
						>
							{t("MyPage.admin.License.purchase.btn-purchase")}
						</button>
					</div>
				</section>
			</div>

			{Object.keys(qs).length !== 0 && (
				<InfoAlert
					type="info"
					confirm={t("button.check")}
					width={430}
					height={250}
					message={TOSS_ERROR_CODE[qs.code ?? "COMMON_ERROR"]()}
				/>
			)}

			{defaultCard && Object.keys(defaultCard).length !== 0 && (
				<CheckAlert
					type="info"
					confirm={t("button.check")}
					reject={t("button.cancel")}
					width={430}
					height={250}
					message={
						<>
							<h3>{t("MyPage.admin.License.purchase.CheckAlert.h3")}</h3>
							<p>{`${CARD_METADATA[defaultCard.acquirerCode].name ?? ""}(${
								defaultCard.cardType ?? ""
							}) ${CardNumberFormatter(defaultCard.cardNumber)}`}</p>
						</>
					}
				/>
			)}
			<ModalTemplate
				title={t("MyPage.admin.License.purchase.refund-modal.title")}
				modalState={refundPolicyModal}
				setModalToggle={setRefundPolicyModal}
				width={574}
				className="refund-policy-modal"
			>
				<div className="modal-content">
					<p className="refund-phrase">
						<Trans
							i18nKey="MyPage.admin.License.purchase.refund-modal.refund-phrase.content1"
							components={[<b />]}
						/>
						<br />
						{t("MyPage.admin.License.purchase.refund-modal.refund-phrase.content2")}
					</p>
					<div className="desc-box">
						<div className="refund-item">
							<p>{t("MyPage.admin.License.purchase.refund-modal.desc.item1")}</p>
						</div>

						<div className="refund-item">
							<p>{t("MyPage.admin.License.purchase.refund-modal.desc.item2")}</p>
							<dl>
								<dt>
									<Trans
										i18nKey="MyPage.admin.License.purchase.refund-modal.desc.item2-dt"
										components={[<b />]}
									/>
								</dt>
								<dd>
									{t("MyPage.admin.License.purchase.refund-modal.desc.item2-dd")}
								</dd>
							</dl>
							{/*<dl>*/}
							{/*	<dt>*/}
							{/*		나. <b>연간 결제 회원</b>*/}
							{/*	</dt>*/}
							{/*	<dd>*/}
							{/*		연 기준은 12개월이고 잔여 이용료는 전체 연간결제 이용료를 12로*/}
							{/*		나누고, 이용한 하루라도 이용한 월을 제외하고 남은 금액을*/}
							{/*		환불합니다.*/}
							{/*	</dd>*/}
							{/*</dl>*/}
						</div>

						{/*<div className="refund-item">*/}
						{/*	<p>*/}
						{/*		3. 본 조의 환불 금액 기준은 연간 결제 회원이라 하더라도 월 결제*/}
						{/*		금액으로 계산 후 진행됩니다. 따라서 환불 시점에 따라 환불 금액이*/}
						{/*		존재하지 않는 경우도 있을 수 있습니다.*/}
						{/*	</p>*/}
						{/*</div>*/}
					</div>
				</div>
				<div className="modal-footer">
					<button
						className="btn__solid"
						color="gray"
						onClick={() => setRefundPolicyModal(false)}
					>
						{t("button.cancel")}
					</button>
					<button
						className="btn__solid"
						color="primary"
						onClick={() => setRefundPolicyModal(false)}
					>
						{t("button.check")}
					</button>
				</div>
			</ModalTemplate>
		</>
	);
};
export default Purchase;
