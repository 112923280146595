import React, { useEffect, useRef } from "react";

const ScrollTop = ({ className, children }) => {
	const scrollRef = useRef(null);
	useEffect(() => {
		scrollRef.current.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<div className={className} ref={scrollRef}>
			{children}
		</div>
	);
};

export default ScrollTop;
