import React, { useEffect } from "react";
import Form from "~/common/components/Form";
import Select from "~/common/components/Select";

const SelectType = ({ useForm, form, apply }) => {
	useEffect(() => {
		useForm.setValue(form.order.toString(), form.answer);
	}, []);

	function setOptions(options) {
		let newOptions = [];
		options.forEach((option) => {
			newOptions.push({ label: option, value: option });
		});

		return newOptions;
	}

	return (
		<>
			<Form useForm={useForm} className="radio-form-wrap horiz">
				<Select
					label={form.title}
					name={form.order.toString()}
					options={setOptions(form.selectOption)}
					required={form.required}
					readOnly={apply}
				/>
			</Form>
		</>
	);
};

export default SelectType;
