import React from "react";
import { useNavigate } from "react-router";
import SearchEmpty from "~/pages/invest/EvaluatePage/EvaluateList/Empty/searchEmpty";
import { PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";

//Assets
import defaultLogo from "~/assets/img/defaultImg/profile-large.png";
import "~/assets/sass/pages/invest/evaluateList.scss";
import { Case, If, OtherWise } from "~/common/components/If";
import Empty from "~/pages/invest/EvaluatePage/EvaluateList/Empty";
import { useTranslation } from "react-i18next";

const View = ({ lastElementRef, pageState, evaluateList }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const evaluateCard = () => {
		return evaluateList.map((evaluate, idx) => {
			const handleNavigateView = () => {
				navigate(`detail/${evaluate.idx}`);
			};
			return (
				<div
					key={idx}
					className={`c__card ${evaluate.status}`}
					onClick={handleNavigateView}
					ref={idx + 1 === evaluateList.length ? lastElementRef : null}
				>
					<div className="top">
						<div className="img-wrap">
							<img
								src={evaluate.VcCompany.logoSqu ?? defaultLogo}
								onError={(e) => {
									e.target.src = defaultLogo;
								}}
								alt={t("Invest.Evaluate.EvaluateList.View.img-wrap")}
							/>
						</div>
						<dl className="company">
							<dt className="name">{evaluate.VcCompany.name}</dt>
							<dt className="ceo">{evaluate.requester.name}</dt>
						</dl>
					</div>
					<p className="content">
						{evaluate.title}
						<br />
						{evaluate.content}
					</p>
					<p className="date">{evaluate.regDate}</p>
					<ol className="custom-step">
						<li className="step-waiting">{t("Invest.situation.request")}</li>
						{evaluate.status === "reject" ? (
							<li className="step-reject">{t("Invest.situation.reject")}</li>
						) : (
							<li className="step-evaluate">{t("Invest.situation.evaluate")}</li>
						)}
						<li className="step-complete">{t("HR.Manage.confirm")}</li>
					</ol>
					<div className="result-check">
						{t("Invest.Evaluate.EvaluateList.View.result")}
					</div>
				</div>
			);
		});
	};

	return (
		<If condition={pageState}>
			<Case condition={PAGE_STATE.EMPTY}>
				<Empty />
			</Case>
			<Case condition={"NODATA"}>
				<SearchEmpty />
			</Case>
			<OtherWise>
				{evaluateList.length === 0 ? (
					<Loading />
				) : (
					<>
						<div className="c__cardRow evaluate-list-wrap">{evaluateCard()}</div>
						{pageState === PAGE_STATE.LOADING && <Loading />}
					</>
				)}
			</OtherWise>
		</If>
	);
};

export default View;
