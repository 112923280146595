import React from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

/**
 * @param { Props } props
 *
 * @typedef Props
 * @property {object} control - useForm control
 * @property {string} name - field name
 * @property {string} placeholder - placeholder
 * @property {object} validation - validating object
 * @property {boolean} required - required validation
 * @property {boolean} showCount - show textarea length count
 * @property {number} maxLength - maxLength
 * @property {boolean} readOnly - readOnly
 */
const Textarea = (props) => {
	const {
		control,
		name,
		placeholder,
		validation,
		required,
		showCount,
		maxLength = 500,
		readOnly,
	} = props;

	const { t } = useTranslation();

	const {
		field: { onChange, value },
	} = useController({
		name,
		control,
		rules: {
			required:
				required === true ? t("Validate.require") : required === null ? " " : required,
			...validation,
		},
	});

	return (
		<>
			<textarea
				name={name}
				className={`c__textarea f__item `}
				placeholder={placeholder}
				value={value}
				onChange={(e) => e.target.value.length <= 500 && onChange(e.target.value)}
				readOnly={readOnly}
			/>
			{showCount && (
				<span className="character-length">
					{value.length}/{maxLength}
				</span>
			)}
		</>
	);
};

export default Textarea;
