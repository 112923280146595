import React from "react";

// Assets
import "~/assets/sass/pages/hr/setting/payday.scss";
import { QuestionMarkIcon } from "~/assets/svg/InterfacesIcon";
import TooltipTemplate from "~/common/components/TooltipPortal/TooltipTemplate";
import AuthButton from "~/common/components/AuthButton";
import { Trans, useTranslation } from "react-i18next";

const PaydayView = ({ showEditPage, Header, payday }) => {
	const { t } = useTranslation();
	const buttonList = [
		<AuthButton type="button" className="btn__solid" color="primary" onClick={showEditPage}>
			{t("button.Modify")}
		</AuthButton>,
	];

	return (
		<>
			<Header buttonList={buttonList} />
			<div className="c__card payday-card">
				<h3 className="title">
					{t("HR.HrSetting.PayDay.View.title")}
					<button type="button" className="c__tooltip-btn">
						<QuestionMarkIcon />
						<TooltipTemplate>{t("HR.HrSetting.PayDay.View.tooltip")}</TooltipTemplate>
					</button>
				</h3>
				<section>
					<p className="payday-title">{t("HR.HrSetting.PayDay.View.payday-title")}</p>
					<div className="c__form horiz payday-wrap">
						<div className="f__group">
							<label className="f__label">
								{t("HR.HrSetting.PayDay.View.payday-wrap.0")}
							</label>
							<div className="f__control">{payday.payCycle}</div>
						</div>
						<div className="f__group">
							<label className="f__label">
								{t("HR.HrSetting.PayDay.View.payday-wrap.1")}
							</label>
							<div className="f__control">
								{payday.payDay}
								{t("HR.Manage.day")}
							</div>
						</div>
					</div>
				</section>
				<div className="alert-content">
					<p className="alert-phrase">
						<Trans
							i18nKey="HR.HrSetting.PayDay.View.alert-phrase"
							components={[
								<b />,
								{ payCycle: payday.payCycle },
								{ payDay: payday.payDay },
							]}
						/>
					</p>
				</div>
			</div>
		</>
	);
};

export default PaydayView;
