import React, { useEffect, useState } from "react";
import { Case, If } from "~/common/components/If";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import View from "~/pages/community/NoticePage/NoticeView/View";
import PageHeader from "~/common/components/PageHeader";
import Loading from "~/common/components/Loading";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import { useNavigate, useParams } from "react-router";
import useRequest from "~/common/hooks/useRequest";
import { useSetAtom } from "jotai";
import { CurrentNoticeAtom } from "~/atoms/Community";

const NoticeView = () => {
	const [page, setPage] = useState(PAGE_STATE.LOADING);
	const [noticeMove, setNoticeMove] = useState({});
	const [noticeComment, setNoticeComment] = useState([]);
	const { idx } = useParams();
	const navigate = useNavigate();
	const setCurrentNotice = useSetAtom(CurrentNoticeAtom);

	const noticeDetailViewRequest = useRequest("get", `community/notice`);
	const noticeCommentRequest = useRequest("post", "community/notice/comment/search");
	const noticeViewCountRequest = useRequest("post", "community/board/increase-hit");

	useEffect(() => {
		noticeCommentRequest.call(idx, "path");
		noticeViewCountRequest.call(idx, "path");
		noticeDetailViewRequest.call(idx, "path");
	}, [idx]);

	useEffect(() => {
		switch (noticeCommentRequest.state) {
			case API_STATE.done:
				setNoticeComment({
					totalCount: noticeCommentRequest.response.data.pagination.totalCount,
					comments: noticeCommentRequest.response.data.comments,
				});
				break;
			case API_STATE.error:
				navigate("/404");
				break;
		}
	}, [noticeCommentRequest.state]);

	useEffect(() => {
		switch (noticeDetailViewRequest.state) {
			case API_STATE.done:
				setCurrentNotice(noticeDetailViewRequest.response.data.notice);
				setNoticeMove({
					next: noticeDetailViewRequest.response.data.next,
					prev: noticeDetailViewRequest.response.data.prev,
				});
				setPage(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
		}
	}, [noticeDetailViewRequest.state]);

	return (
		<NavbarAndSidebarLayout menu={["community", "notice"]} type="main">
			<If condition={page} {...{ setPage }}>
				<Case condition={PAGE_STATE.VIEW}>
					<View
						{...{
							noticeMove,
							noticeComment,
							noticeCommentRequest,
						}}
					/>
				</Case>
				<Case condition={PAGE_STATE.LOADING}>
					<PageHeader />
					<Loading />
				</Case>
			</If>
		</NavbarAndSidebarLayout>
	);
};

export default NoticeView;
