import React from "react";
import { useNavigate } from "react-router";
import { useGlobalStore } from "~/store";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

// Assets
import "~/assets/sass/pages/shareholder/empty.scss";
import evaluateBg from "~/assets/img/bg-evaluate-landing.png";
import enEvaluateBg from "~/assets/img/EN/bg-evaluate-landing_en.png";

const Init = () => {
	const { t } = useTranslation();
	const langImg = i18next.language;
	const navigate = useNavigate();
	const [
		{
			user: {
				check: { comCheck, irCheck },
			},
		},
	] = useGlobalStore();

	return (
		<>
			<div className="c__card evaluate-landing-card">
				<section className="explain-section">
					<img
						src={langImg === "ko" ? evaluateBg : enEvaluateBg}
						alt={t("Invest.Evaluate.EvaluateList.Init.img")}
					/>
				</section>
				<section className="content-section">
					<h3 className="landing-title">
						{t("Invest.Evaluate.EvaluateList.Init.landing-title")}
					</h3>
					<div className="landing-text">
						{t("Invest.Evaluate.EvaluateList.Init.landing-text")}
					</div>
					<div className="quick-menu-wrap">
						<p className="quick-menu-title">
							{t("Invest.Evaluate.EvaluateList.Init.quick-menu-title")}
						</p>
						<ul className="quick-tip-menu">
							{comCheck ? (
								<li className="menu-check-wrap">
									<div>
										<span className="chk-mark complete" />
										<span className="menu-check">
											{t(
												"Invest.Evaluate.EvaluateList.Init.comCheck.menu-check1"
											)}
										</span>
									</div>
								</li>
							) : (
								<li className="menu-check-wrap">
									<div>
										<span className="chk-mark" />
										<span className="menu-check">
											{t(
												"Invest.Evaluate.EvaluateList.Init.comCheck.menu-check2"
											)}
										</span>
									</div>
									<div
										className="link-caption"
										onClick={() => navigate("/company/info")}
									>
										{t(
											"Invest.Evaluate.EvaluateList.Init.comCheck.link-caption"
										)}
									</div>
								</li>
							)}
							{irCheck ? (
								<li className="menu-check-wrap">
									<div>
										<span className="chk-mark complete" />
										<span className="menu-check">
											{t(
												"Invest.Evaluate.EvaluateList.Init.irCheck.menu-check1"
											)}
										</span>
									</div>
								</li>
							) : (
								<li className="menu-check-wrap">
									<div>
										<span className="chk-mark " />
										<span className="menu-check">
											{t(
												"Invest.Evaluate.EvaluateList.Init.irCheck.menu-check2"
											)}
										</span>
									</div>
									<div
										className="link-caption"
										onClick={() => navigate("/ir/list")}
									>
										{t(
											"Invest.Evaluate.EvaluateList.Init.irCheck.link-caption"
										)}
									</div>
								</li>
							)}
						</ul>
						<div className="text-desc">
							<i>※</i>
							<p>{t("Invest.Evaluate.EvaluateList.Init.text-desc")}</p>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};
export default Init;
