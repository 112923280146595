import React from "react";
import { useGlobalStore } from "~/store";
import { dateToString, thousandFormatter } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const DebtStatus = ({}) => {
	const { t } = useTranslation();
	const [
		{
			ir: {
				finance: { IRFinanceDept },
			},
		},
	] = useGlobalStore();

	const debtList = () =>
		IRFinanceDept.map(({ borrower, value, interestRate, dueDate, repaymentTerms }, idx) => (
			<div key={idx} className="tr">
				<div className="td">{borrower}</div>
				<div className="td">{thousandFormatter(value)}</div>
				<div className="td">{dateToString(dueDate, "yyyy-MM-dd")}</div>
				<div className="td">{interestRate}%</div>
				<div className="td repayment-condition">{repaymentTerms}</div>
			</div>
		));

	return (
		<div className="debt-status-table">
			<div className="thead">
				<div className="tr">
					<div className="th">
						{t("IR.IrDetail.Finance.Edit.DynamicFields.thead.text1")}
					</div>
					<div className="th">
						{t("IR.IrDetail.Finance.Edit.DynamicFields.thead.text2")}
					</div>
					<div className="th">
						{t("IR.IrDetail.Finance.Edit.DynamicFields.thead.text3")}
					</div>
					<div className="th">
						{t("IR.IrDetail.Finance.Edit.DynamicFields.thead.text4")}
					</div>
					<div className="th repayment-condition">
						{t("IR.IrDetail.Finance.Edit.DynamicFields.thead.text5")}
					</div>
				</div>
			</div>
			<div className="tbody">{debtList()}</div>
		</div>
	);
};

export default DebtStatus;
