// 홈페이지 벨리데이션 체크
export const confirmHomepage = (url) => {
	if (url) {
		let domainExp =
			/^(((http(s?))\:\/\/))([0-9a-zA-Z\-]+\.)+[a-zA-Zㄱ-ㅎ|ㅏ-ㅣ|가-힣]{2,6}(\:[0-9]+)?(\/\S*)?/;
		let ipExp = /^(((http(s?))\:\/\/))(1|2)?\d?\d([.](1|2)?\d?\d){3}$/;
		return domainExp.test(url) || ipExp.test(url);
	} else return false;
};

// 새창열기
export function handleWindow(url) {
	if (url) {
		if (!confirmHomepage(url)) {
			if (url.startsWith("www.")) url = "http://" + url;
			else if (!url.startsWith("http://www.")) url = "http://www." + url;
		}
		window.open(url, "_blank");
	} else return false;
}
