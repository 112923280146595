import React, { useEffect, useState } from "react";

// Components
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";

// Assets
import "~/assets/sass/pages/hr/manage/manage.scss";
import "~/assets/sass/pages/hr/manage/vacationList.scss";
import "~/assets/sass/pages/hr/attendance/common/typeColor.scss";

// Pages
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import Coworkers from "~/pages/hr/ManagePage/List/Coworkers";
import VacationList from "~/pages/hr/ManagePage/vacationList";
import { Case, If } from "~/common/components/If";

const ManagePage = () => {
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [coworkerPage, setCoworkerPage] = useState(1);
	const [searchType, setSearchType] = useState("name");
	const [input, setInput] = useState("");
	const [coworkerStatus, setCoworkerStatus] = useState("근무중");
	const [coworkers, setCoworkers] = useState([]);
	const [selectedUser, setSelectedUser] = useState({});
	const [apiState, setApiState] = useState(API_STATE.loading);
	const [pageNumber, setPageNumber] = useState(1);

	const coworkersRequest = useRequest("get", "vacation/coworkers");

	const getCoworkers = () => {
		coworkersRequest.call(
			{
				page: input ? 1 : coworkerPage,
				size: 10,
				id: searchType === "id" ? input : "",
				name: searchType === "name" ? input : "",
				department: searchType === "department" ? input : "",
				position: searchType === "position" ? input : "",
				status: coworkerStatus === "All" ? "" : coworkerStatus,
			},
			"queryString"
		);
	};

	useEffect(() => {
		getCoworkers();
	}, [coworkerPage, input, coworkerStatus]);

	const onSubmit = () => {
		getCoworkers();
	};

	useEffect(() => {
		switch (coworkersRequest.state) {
			case API_STATE.done:
				if (coworkersRequest.response.data.foundUsers.length > 0) {
					setCoworkers(coworkersRequest.response.data);
					setSelectedUser(coworkersRequest.response.data.foundUsers[0]);
					setPageState(PAGE_STATE.VIEW);
					setCoworkerPage(Number(coworkersRequest.response.data.pageable.pageNumber));
				} else {
					setSelectedUser({
						User: {
							UserProfile: {
								idx: 0,
								name: "",
								phone: "",
								department: "",
								orgId: "",
							},
							id: "",
							idx: 0,
							img: null,
							lastLogin: "",
							regDate: "",
							status: "",
							updateDate: "",
						},

						defaultWorkEndTime: "",
						defaultWorkStartTime: "",
						deleted: false,
						gender: "",
						idx: 0,
						remainingVacationTime: 0,
						updateDate: "",
					});
					setPageState(PAGE_STATE.EMPTY);
				}
				setApiState(API_STATE.done);
				break;
			default:
				break;
		}
	}, [coworkersRequest.state]);

	return (
		<>
			<NavbarAndSidebarLayout menu={["hr", "manage"]} type="main">
				<PageHeader />
				<div className="hr-manage-cardRow">
					<If
						condition={apiState}
						{...{
							coworkers,
							selectedUser,
							setPageNumber,
							page: pageState,
						}}
					>
						<Case condition={API_STATE.done}>
							<Coworkers
								{...{
									coworkerPage,
									setCoworkerPage,
									setSelectedUser,
									searchType,
									setSearchType,
									setInput,
									onSubmit,
									pageNumber,
									coworkerStatus,
									setCoworkerStatus,
								}}
							/>
							<VacationList
								{...{
									selectedUser,
									pageNumber,
								}}
							/>
						</Case>
					</If>
				</div>
			</NavbarAndSidebarLayout>
		</>
	);
};
export default ManagePage;
