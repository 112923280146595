import React, { useEffect, useState } from "react";
import { ALLOW_COMMENT_STATE, API_STATE, PAGE_STATE } from "~/common/constants/state";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import { useForm } from "react-hook-form";
import PageHeader from "~/common/components/PageHeader";
import Textarea from "~/common/components/Textarea";
import Form, { Group } from "~/common/components/Form";
import CommentUpload from "~/common/components/FormImageUploader/CommentUpload";

//icon
import {
	ArrowIcon,
	ClipIcon,
	CommentIcon,
	DownLoadIcon,
	FileLinkIcon,
	ListIcon,
} from "~/assets/svg/InterfacesIcon";

//asset
import "~/assets/sass/pages/community/communityDetail.scss";
import "~/assets/sass/pages/community/boardPage/boardPage.scss";
import noImg from "~/assets/img/defaultImg/profile-large.png";
import { dateToString, formatBytes } from "~/utils/formatter";
import { useNavigate } from "react-router-dom";
import useRequest from "~/common/hooks/useRequest";
import Comment from "~/pages/community/BoardPage/BoardPost/Detail/component/comment";
import { useParams } from "react-router";
import useAlert from "~/common/hooks/useAlert";
import { useGlobalStore } from "~/store";
import { CurrentBoardAtom, MyAuthAtom } from "~/atoms/Community";
import { useAtomValue } from "jotai";
import { Trans, useTranslation } from "react-i18next";

const View = ({ menu, type, setPageState, board, prev, next, getBoardDetail }) => {
	const { t } = useTranslation();
	const commentForm = useForm();
	const navigate = useNavigate();
	const { idx, postIdx } = useParams();
	const commentRequest = useRequest("post", `/community/board/comment/search/${postIdx}`);
	const addCommentRequest = useRequest("post", `/community/board/comment/${postIdx}`);
	const deleteCommentRequest = useRequest("delete", `/community/board/${postIdx}`);
	const increaseHitRequest = useRequest("post", `/community/board/increase-hit`);

	const [commentList, setCommentList] = useState();
	const [commentCount, setCommentCount] = useState();
	const [showWarningAlert, WarningAlert] = useAlert();
	const [showAuthAlert, AuthAlert] = useAlert();
	const [
		{
			user: { info },
		},
	] = useGlobalStore();
	const myAuth = useAtomValue(MyAuthAtom);
	const currentBoard = useAtomValue(CurrentBoardAtom);

	useEffect(() => {
		increaseHitRequest.call(postIdx, "path");
		getBoardDetail();
	}, [postIdx]);

	useEffect(() => {
		if (board) {
			commentRequest.call();
		}
	}, [board]);

	const submit = (data) => {
		if (!data.comment && data.ImageList.length === 0) {
			commentForm.setError("comment", {
				type: "custom",
				message: t("Validate.require"),
			});
			return;
		}
		const requestData = {
			communityIdx: Number(idx),
			comment: data.comment,
			files: data.ImageList,
		};
		addCommentRequest.call(requestData, "body");
	};

	useEffect(() => {
		switch (addCommentRequest.state) {
			case API_STATE.done:
				commentRequest.call();
				commentForm.setValue("comment", "");
				commentForm.setValue("ImageList", []);

				if (addCommentRequest.response.statusCode === 500) {
					console.log("error");
					navigate("/404");
				}
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [addCommentRequest.state]);

	useEffect(() => {
		switch (commentRequest.state) {
			case API_STATE.done:
				const response = commentRequest.response.data;
				setCommentList(response.comments);
				setCommentCount(response.pagination.totalCount);
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [commentRequest.state, commentRequest.response]);

	const headerButton = [
		<button
			className="btn__solid"
			color="red"
			onClick={() => {
				if (board.user.idx === info.idx || myAuth === "MANAGER") {
					showWarningAlert()
						.then(() => {
							deleteCommentRequest
								.asyncCall(
									{
										data: {
											communityIdx: Number(idx),
										},
									},
									"body"
								)
								.then(() => {
									navigate(`/community/list/board/${idx}`);
								});
						})
						.catch(() => {});
				} else {
					showAuthAlert();
				}
			}}
		>
			{t("button.Delete")}
		</button>,
		<button
			className="btn__solid"
			color="primary"
			onClick={() =>
				board.user.idx === info.idx ? setPageState(PAGE_STATE.EDIT) : showAuthAlert()
			}
		>
			{t("button.Modify")}
		</button>,
	];

	return (
		<>
			<PageHeader
				{...{ menu, type }}
				buttonList={
					board?.user && board.user.idx === info.idx
						? headerButton
						: myAuth === "MANAGER"
						? [headerButton[0]]
						: []
				}
			/>
			<div className="c__card community-detail-card">
				{board && (
					<>
						<section className="content-section">
							<h3 className="content-title">{board.title}</h3>

							<div className="content-profile">
								<div className="profile-img">
									<img
										src={board.user.img ?? noImg}
										alt={t("Img.profile")}
										onError={(e) => {
											e.target.src = noImg;
										}}
									/>
								</div>
								<dl className="profile-info">
									<dt>
										<span className="profile-name"> {board.user.name}</span>
										<span className="profile-position">{board.user.id}</span>
									</dt>
									<dd>
										<span className="profile-date">
											{dateToString(board.createdAt, "yyyy.MM.dd HH:mm")}
										</span>
										<span className="profile-state">
											<Trans
												i18nKey="Community.BoardPage.BoardPost.Detail.View.profile-state"
												components={[{ board }]}
											/>
										</span>
									</dd>
								</dl>
							</div>

							<div
								className="content-text ql-editor"
								dangerouslySetInnerHTML={{ __html: board?.content }}
							/>

							<div className="content-file">
								{currentBoard?.allowFile && board.uploadFiles.length > 0 && (
									<>
										<div className="file-title">
											<ClipIcon />
											{t("Community.file-title")}
										</div>
										<ul className="file-list">
											{board.uploadFiles.map((file, index) => (
												<a
													href={file.path}
													download={file.name}
													key={index}
												>
													<li className="file-item" key={index}>
														<div className="file-icon">
															<FileLinkIcon />
														</div>
														<div className="file-contents">
															<p className="file-text">{file.name}</p>
															<p className="file-data">
																{formatBytes(file.size, 1)}
															</p>
														</div>
														<div className="file-download">
															<DownLoadIcon />
														</div>
													</li>
												</a>
											))}
										</ul>
									</>
								)}
							</div>
						</section>

						{board?.settings[0].value === ALLOW_COMMENT_STATE.ALLOW && (
							<section className="comment-section">
								<div className="comment-title">
									<CommentIcon />
									<span> {t("Community.comment")} </span>
									<span className="num">{commentCount}</span>
								</div>

								<div className="comment-box">
									<Form useForm={commentForm} className={"Community-ListForm"}>
										<Textarea
											name="comment"
											placeholder={t("Community.textarea-placeholder")}
										/>
										<Group>
											<CommentUpload
												name="ImageList"
												type={UPLOAD_TYPE.COMMUNITY_COMMENT}
												height="60px"
												width="60px"
												className={"community-img"}
												maxCount={5}
											/>
											<button
												className="btn__solid"
												color="primary"
												onClick={commentForm.handleSubmit(submit)}
											>
												{t("button.register")}
											</button>
										</Group>
									</Form>
								</div>

								<ul className="comment-list">
									{commentList?.map((comment, index) => (
										<Comment
											comment={comment}
											commentRequest={commentRequest}
											key={index}
										/>
									))}
								</ul>
							</section>
						)}
					</>
				)}
			</div>

			{(prev || next) && (
				<div className="c__card community-move-card">
					{next && (
						<div
							className="move-item"
							onClick={() =>
								navigate(`/community/list/board/${idx}/post/${next.idx}`)
							}
						>
							<div className="next-icon">
								<ArrowIcon />
							</div>
							<div className="move-title">{t("Community.move-next")}</div>
							<div className="move-text">{next.title}</div>
						</div>
					)}
					{prev && (
						<div
							className="move-item"
							onClick={() =>
								navigate(`/community/list/board/${idx}/post/${prev.idx}`)
							}
						>
							<div className="prev-icon">
								<ArrowIcon />
							</div>
							<div className="move-title">{t("Community.move-prev")}</div>
							<div className="move-text">{prev.title}</div>
						</div>
					)}
				</div>
			)}
			<div className="community-list-btn">
				<button
					className="btn__solid "
					color="primary"
					onClick={() => navigate(`/community/list/board/${idx}`)}
				>
					<ListIcon />
					{t("button.list")}
				</button>
			</div>

			<WarningAlert
				type="error"
				className="overtime-use-modal"
				layout="horiz"
				width={430}
				height={306}
				message={
					<>
						<h3>{t("Community.WarningAlert.h3")}</h3>
						<p>{t("Community.WarningAlert.p")}</p>
					</>
				}
				desc={<div className="desc-box error">{t("Community.WarningAlert.desc-box")}</div>}
				reject={t("button.cancel")}
				confirm={t("button.check")}
			/>
			<AuthAlert
				message={t("Community.BoardPage.BoardPost.Detail.View.AuthAlert")}
				type="warning"
				confirm={t("button.check")}
			/>
		</>
	);
};

export default View;
