import { SET_CURRENT_ROUND, SET_ROUND_SURVEY } from "./actions";

/**
 * @type {}
 */

export const INITIAL_STATE = {
	info: {},
	detail: "",
	faq: [],
	files: [],
	inquiry: [],
	survey: [],
};

export const REDUCER = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_CURRENT_ROUND:
			return {
				...state,
				info: action.payload.info,
				detail: action.payload.detail,
				faq: action.payload.faq,
				files: action.payload.files,
				// inquiry: action.payload.inquiry,
			};
		case SET_ROUND_SURVEY:
			return {
				...state,
				survey: action.payload.survey,
				answer: action.payload.answer,
			};
		// case SET_ROUND_SURVEY:
		// 	return {
		// 		...state,
		// 		survey: action.payload.survey,
		// 		answer: action.payload.answer,
		// 	};
		default:
			return state;
	}
};
