import Form from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import React from "react";
import { useForm } from "react-hook-form";
import FileUploader from "~/common/components/FileUploader";
import useAlert from "~/common/hooks/useAlert";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import { VACATION_CLASSNAME } from "~/common/constants/form";
import { DateWithDayName } from "~/utils/formatter";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const Modal = ({
	selectedVacation,
	setVacationManageModal,
	timeText,
	hrUpcomingVacationRequest,
	vacationStatusRequest,
	info,
	pageNumber,
}) => {
	const { t } = useTranslation();
	const [showAlert, Alert] = useAlert();
	const modalForm = useForm({
		defaultValues: {
			memo: selectedVacation.HrVacation.memo,
			admin: selectedVacation.adminMemo,
		},
	});

	const navigate = useNavigate();

	const submit = (payload) => {
		vacationStatusRequest
			.asyncCall(payload, "body")
			.then(() => {
				hrUpcomingVacationRequest.call(
					{
						page: pageNumber,
						size: 10,
						userIdx: info.idx,
					},
					"queryString"
				);
				setVacationManageModal(false);
			})
			.catch(() => navigate("/404"));
	};

	const ClickSubmit = (data) => {
		const payload = {
			file: data.file || "",
			dateAndTimeIdx: selectedVacation.idx,
			memo: data.memo,
		};
		submit(payload);
	};

	const handleDelete = () => {
		const payload = { dateAndTimeIdx: selectedVacation.idx, status: "deleted" };
		showAlert().then(() => {
			submit(payload);
		});
	};

	return (
		<>
			<div className="modal-content">
				<div className="vacation-state">
					<div
						className={`vacation-name ${
							VACATION_CLASSNAME[selectedVacation.HrVacation.title]
						}`}
					>
						{selectedVacation.startDate === selectedVacation.endDate
							? DateWithDayName(selectedVacation.startDate)
							: `${DateWithDayName(selectedVacation.startDate)}
							   ~ 
							  ${DateWithDayName(selectedVacation.endDate)}`}
					</div>
					<div className="time">{timeText(selectedVacation)}</div>
				</div>
				{selectedVacation.HrVacation.HrVacationType.documents !== "none" && (
					<Form
						useForm={modalForm}
						className="vacation-manage-form vertical c__file-upload-form"
					>
						{selectedVacation.HrVacation.HrVacationFiles.length > 0 ? (
							<div className="f__group complete">
								<label className="f__label">
									{t("HR.Manage.vacationList.ProofDataSubmit")}{" "}
									<span className="status-badge">
										{t("HR.Manage.vacationList.submit")}
									</span>
								</label>
								{selectedVacation.HrVacation.HrVacationFiles &&
									selectedVacation.HrVacation.HrVacationFiles.map((f) => (
										<div className="f__control">
											<span>
												<a
													href={f.File?.location}
													target={"_blank"}
													rel="noopener noreferrer"
												>
													{f.File?.name}
												</a>
											</span>
										</div>
									))}
							</div>
						) : (
							<FileUploader
								label={
									<>
										{t("HR.Manage.vacationList.ProofDataSubmit")}
										<span className="status-badge">
											{t("HR.Manage.vacationList.afterSubmit")}
										</span>
									</>
								}
								name="file"
								className="later"
								type={UPLOAD_TYPE.HR_VACATION}
							/>
						)}
					</Form>
				)}
				<Form useForm={modalForm} className="vacation-manage-form vertical">
					<Textarea label={t("HR.Manage.admin")} name="admin" readOnly />
				</Form>
				<Form useForm={modalForm} className="vacation-manage-form vertical">
					<Textarea label={t("HR.Manage.user")} name="memo" />
				</Form>
			</div>
			<div className="modal-footer">
				<button className="btn__solid" color="red" onClick={handleDelete}>
					{t("HR.Manage.vacationList.Used.btn-delete")}
				</button>
				<button
					className="btn__solid"
					color="primary"
					onClick={modalForm.handleSubmit(ClickSubmit)}
				>
					{t("button.check")}
				</button>
			</div>
			<Alert
				message={t("HR.Vacation.Expected.Alert")}
				reject={t("button.cancel")}
				confirm={t("button.delete")}
				type="error"
			/>
		</>
	);
};
export default Modal;
