import React, { useEffect, useState } from "react";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import AlertTemplate from "~/common/components/AlertPortal/AlertTemplate";

//Assets
import { QuestionMarkIcon, RoundChkBoxIcon } from "~/assets/svg/InterfacesIcon";
import defaultAvatar from "~/assets/img/defaultImg/profile-large.png";
import TooltipTemplate from "~/common/components/TooltipPortal/TooltipTemplate";
import useAlert from "~/common/hooks/useAlert";
import { useTranslation } from "react-i18next";

const Edit = ({
	setPage,
	selectUser,
	authorityList,
	authMemberListRequest,
	getAuthorityRequest,
	authEditAlertModal,
	setAuthEditAlertModal,
	authEditAlertContent,
	alertState,
}) => {
	const { t, i18n } = useTranslation();
	//수정한 권한 리스트
	const [editAuthorityList, setEditAuthorityList] = useState([]);
	//권한 수정 Request
	const editAuthorityRequest = useRequest("post", "admin/auth/edit");
	// 구성원 추가 여부 Request
	const hrWorkRequest = useRequest("get", "admin/invite");
	//접근권한, 담당권한 모두 선택 state
	const [viewCheckAll, setViewCheckAll] = useState({});
	const [editCheckAll, setEditCheckAll] = useState({});

	const [showWarningAlert, WarningAlert] = useAlert();

	const cantCheckEditAuthIdx = [7, 28, 19, 20, 26, 27];

	//변경한 권한 저장
	const handleSubmit = async () => {
		const isTeamMember = await hrWorkRequest
			.asyncCall()
			.then(
				(res) => res.data.result.find((user) => selectUser.idx === user.idx).isTeamMember
			);
		if (!isTeamMember) {
			if (editAuthorityList[1].subMenu.find((menu) => menu.viewAuth === true)) {
				setAuthEditAlertModal(false);
				return showWarningAlert();
			}
		}

		let newViewValue = [];
		editAuthorityList.forEach((d) => {
			d.subMenu.forEach((v) => {
				newViewValue.push({
					pluginMenuIdx: v.idx,
					auth: v.viewAuth,
				});
			});
		});
		let newEditValue = [];
		editAuthorityList.forEach((d) => {
			d.subMenu
				.filter((d) => d.idx !== 7)
				.filter((d) => d.idx !== 19)
				.filter((d) => d.idx !== 20)
				.forEach((v) => {
					if (v.editAuth === true) {
						newEditValue.push({
							pluginMenuIdx: v.idx,
							auth: v.editAuth,
						});
					}
				});
		});

		editAuthorityRequest.call({
			userIdx: selectUser.idx,
			viewAuth: newViewValue,
			editAuth: newEditValue,
		});
	};

	//변경한 권한 저장 Response
	useEffect(() => {
		switch (editAuthorityRequest.state) {
			case API_STATE.done:
				authMemberListRequest.call();
				setAuthEditAlertModal(false);
				getAuthorityRequest.call(selectUser.idx, "path");
				setPage(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error", editAuthorityRequest.error);
				alert(t("Alert.alert1"));
				break;
			default:
				break;
		}
	}, [editAuthorityRequest.state, editAuthorityRequest.done, editAuthorityRequest.error]);

	//유저 권한 array 복제
	useEffect(() => {
		setEditAuthorityList(authorityList);
	}, [authorityList]);

	//접근권한 체크박스 토글
	const handleViewChange = (sub) => {
		let newData = [];
		editAuthorityList.forEach((p) => {
			const value = p.subMenu.map((v) =>
				sub.idx === v.idx
					? {
							...v,
							viewAuth: !v.viewAuth,
							editAuth: !v.viewAuth === false ? false : v.editAuth,
					  }
					: v
			);
			newData.push({ ...p, subMenu: value });

			setEditAuthorityList(newData);
		});
	};

	//담당권한 체크박스 토글
	const handleEditChange = (sub) => {
		let newData = [];
		editAuthorityList.forEach((p) => {
			const value = p.subMenu.map((v) =>
				sub.idx === v.idx
					? {
							...v,
							editAuth: !v.editAuth,
							viewAuth: !v.editAuth === true ? true : v.viewAuth,
					  }
					: v
			);
			newData.push({ ...p, subMenu: value });

			setEditAuthorityList(newData);
		});
	};

	//체크박스 상태에 따른 모두 선택 상태 변경
	useEffect(() => {
		const viewArray = [];
		const editArray = [];

		editAuthorityList.forEach((d) => {
			d.subMenu.forEach((v) => {
				viewArray.push(v.viewAuth);
			});
		});

		editAuthorityList.forEach((d) => {
			d.subMenu.forEach((v) => {
				if (cantCheckEditAuthIdx.includes(v.idx)) return;
				editArray.push(v.editAuth);
			});
		});

		if (viewArray.includes(false)) {
			setViewCheckAll(false);
		} else {
			setViewCheckAll(true);
		}

		if (editArray.includes(false)) {
			setEditCheckAll(false);
		} else {
			setEditCheckAll(true);
		}
	}, [editAuthorityList]);

	//접근권한 모두 선택 토글에 따라 체크박스 상태 변경
	const handleViewCheckAll = () => {
		let newData = [];
		editAuthorityList.forEach((p) => {
			const value = p.subMenu.map((v) => ({
				...v,
				viewAuth: !viewCheckAll,
				editAuth: !viewCheckAll === false ? false : v.editAuth,
			}));
			newData.push({ ...p, subMenu: value });

			setEditAuthorityList(newData);
		});
	};

	//담당권한 모두 선택 토글에 따라 체크박스 상태 변경
	const handleEditCheckAll = () => {
		let newData = [];
		editAuthorityList.forEach((p) => {
			const value = p.subMenu.map((v) => ({
				...v,
				viewAuth: !editCheckAll === true ? true : v.viewAuth,
				editAuth: !editCheckAll,
			}));
			newData.push({ ...p, subMenu: value });

			setEditAuthorityList(newData);
		});
	};

	return (
		<>
			<div className="c__card role-table-card">
				<div className="role-table edit">
					<div className="thead">
						<div className="th selected-member">
							<div className="member-img">
								<img
									src={selectUser.img ? selectUser.img : defaultAvatar}
									alt={selectUser.name}
									onError={(e) => {
										e.target.src = defaultAvatar;
									}}
								/>
							</div>
							<dl className="member-info">
								<dt>
									{selectUser.name} {selectUser.position}
								</dt>
								<dd>{selectUser.id}</dd>
							</dl>
						</div>
						<div className="th chk-area">
							<div className="auth-type">
								<p>{t("MyPage.accessAuthority")}</p>
								<div className="c__tooltip-btn">
									<QuestionMarkIcon />
									<TooltipTemplate>
										{t("MyPage.readingAuthority")}
									</TooltipTemplate>
								</div>
							</div>
							<div className="switch-wrap">
								<label className={"switch"}>
									<input
										type="checkbox"
										checked={viewCheckAll}
										onChange={handleViewCheckAll}
										disabled={selectUser.role === "admin"}
									/>
									<span className="slider round" />
								</label>
							</div>
						</div>
						<div className="th chk-area">
							<div className="auth-type">
								<p>{t("MyPage.chargeAuthority")}</p>
								<div className="c__tooltip-btn">
									<QuestionMarkIcon />
									<TooltipTemplate>{t("MyPage.editAuthority")}</TooltipTemplate>
								</div>
							</div>
							<div className="switch-wrap">
								<label className={"switch"}>
									<input
										type="checkbox"
										checked={editCheckAll}
										onChange={handleEditCheckAll}
										disabled={selectUser.role === "admin"}
									/>
									<span className="slider round" />
								</label>
							</div>
						</div>
					</div>
					<div className="tbody">
						{editAuthorityList.map((menu, menu_idx) => {
							return (
								<div className="tr" key={menu_idx}>
									<div className="td role-colgroup-title">{menu.title}</div>
									<div className="role-colgroup-wrap">
										{menu.subMenu.map((sub, s_idx) => {
											return (
												<div className="role-colgroup" key={s_idx}>
													<div className="role-info">
														<p className="td page-name">{sub.title}</p>
														<p className="td page-desc">
															{i18n.language === "ko"
																? sub.description
																: sub.engDescription}
														</p>
													</div>
													<div className={`td chk-area`}>
														<label
															className={`round-checkbox ${
																selectUser.role === "admin" &&
																"disabled"
															}`}
														>
															<input
																type="checkbox"
																checked={sub.viewAuth}
																disabled={
																	selectUser.role === "admin"
																}
																onChange={() =>
																	handleViewChange(sub)
																}
															/>
															<RoundChkBoxIcon />
														</label>
													</div>
													{cantCheckEditAuthIdx.includes(sub.idx) ? (
														<div className={`td chk-area`}>-</div>
													) : (
														<div className={`td chk-area`}>
															<label
																className={`round-checkbox ${
																	selectUser.role === "admin" &&
																	"disabled"
																}`}
															>
																<input
																	type="checkbox"
																	disabled={
																		selectUser.role === "admin"
																	}
																	checked={
																		sub.editAuth === undefined
																			? false
																			: sub.editAuth
																	}
																	onChange={() =>
																		handleEditChange(sub)
																	}
																/>
																<RoundChkBoxIcon />
															</label>
														</div>
													)}
												</div>
											);
										})}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<AlertTemplate
				alertModalState={authEditAlertModal}
				setAlertModalToggle={setAuthEditAlertModal}
				alertType={"warning"}
			>
				<p className="alert-content">{authEditAlertContent}</p>
				<div className="alert-footer">
					<button
						className="btn__solid"
						color="gray"
						onClick={() => {
							setAuthEditAlertModal(false);
						}}
					>
						{t("button.cancel")}
					</button>
					{alertState === "submit" ? (
						<button className="btn__solid" color="primary" onClick={handleSubmit}>
							{t("button.check")}
						</button>
					) : (
						<button
							className="btn__solid"
							color="primary"
							onClick={() => {
								setAuthEditAlertModal(false);
								setPage(PAGE_STATE.VIEW);
							}}
						>
							{t("button.check")}
						</button>
					)}
				</div>
			</AlertTemplate>
			<WarningAlert
				type="warning"
				message={<p>{t("MyPage.admin.Auth.Edit.WarningAlert")}</p>}
				confirm={t("button.check")}
			/>
		</>
	);
};
export default Edit;
