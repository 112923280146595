import React from "react";
import { useGlobalStore } from "~/store";
import { dateToString, thousandFormatter } from "~/utils/formatter";
import PageHeader from "~/common/components/PageHeader";
import { PAGE_STATE } from "~/common/constants/state";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";
import Print from "~/common/components/Print";

const View = ({ shareholderDetail, menu, type, setPageState }) => {
	const { t } = useTranslation();
	const {
		title,
		regDate,
		updateDate,
		ShareholderMember: shareholderMember,
		sign: signature,
	} = shareholderDetail;

	const [
		{
			company: {
				info: {
					ceoName: companyCeo,
					address: companyAddress,
					addressDetail: companyAddressDetail,
					name: companyName,
				},
			},
		},
	] = useGlobalStore();

	const headerButtons = [
		<Print />,
		<AuthButton
			type="button"
			className="btn__solid btn-edit"
			color="primary"
			onClick={() => setPageState(PAGE_STATE.EDIT)}
		>
			{t("button.Edit")}
		</AuthButton>,
	];

	const reducedInfo = shareholderMember.reduce(
		(acc, cur) => ({
			total: acc.total + parseInt(cur.total),
			stock: acc.stock + parseInt(cur.stock),
		}),
		{ total: 0, stock: 0 }
	);

	const shareholderList = () =>
		shareholderMember.map(({ idx, name, address, regNum, stock, price, total, ratio }) => (
			<div className="tr" key={idx}>
				<div className="td shareholder-info">
					<span className="shareholder-name">{name}</span>
					<span className="shareholder-number">{regNum}</span>
				</div>
				<div className="td shareholder-address">{address}</div>
				<div className="td shareholder-price">
					{thousandFormatter(price)}
					{t("Tax.Dashboard.View.component.won")}
				</div>
				<div className="td shareholder-stock">
					{thousandFormatter(stock)}
					{t("Tax.Dashboard.View.component.stock")}
				</div>
				<div className="td shareholder-total">
					{thousandFormatter(total)}
					{t("Tax.Dashboard.View.component.won")}
				</div>
				<div className="td equity-ratio">{ratio}%</div>
			</div>
		));

	return (
		<>
			<PageHeader menu={menu} type={type} buttonList={headerButtons} />
			<div className="c__card shareholder-view-card">
				<section className="shareholder-header">
					<div className="date">Updated : {dateToString(updateDate, "yyyy-MM-dd")}</div>
					<h2>{title}</h2>
				</section>
				<section className="shareholder-wrap">
					<h3 className="section-title">
						{t("Shareholder.ShareholderDetail.Edit.DetailInfo.thead.info")}
					</h3>
					<div className="scroll-wrap">
						<div className="shareholder-table">
							<div className="thead">
								<div className="tr">
									<div className="th shareholder-info">
										{t(
											"Shareholder.ShareholderDetail.Edit.DetailInfo.thead.info"
										)}
									</div>
									<div className="th shareholder-address">
										{t(
											"Shareholder.ShareholderDetail.Edit.DetailInfo.thead.address"
										)}
									</div>
									<div className="th shareholder-price">
										{t(
											"Shareholder.ShareholderDetail.Edit.DetailInfo.thead.price"
										)}
									</div>
									<div className="th shareholder-stock">
										{t(
											"Shareholder.ShareholderDetail.Edit.DetailInfo.thead.stock"
										)}
									</div>
									<div className="th shareholder-total">
										{t(
											"Shareholder.ShareholderDetail.Edit.DetailInfo.thead.total"
										)}
									</div>
									<div className="th equity-ratio">
										{t(
											"Shareholder.ShareholderDetail.Edit.DetailInfo.thead.ratio"
										)}
									</div>
								</div>
							</div>
							<div className="tbody">{shareholderList()}</div>
							<div className="tfoot">
								<div className="tr">
									<div className="td total-label">
										{t("Shareholder.ShareholderDetail.View.Index.total")}
									</div>
									<div className="td total-stock">
										{thousandFormatter(reducedInfo.stock)}
										{t("Tax.Dashboard.View.component.stock")}
									</div>
									<div className="td total-amount">
										{thousandFormatter(reducedInfo.total)}
										{t("Tax.Dashboard.View.component.won")}
									</div>
									<div className="td total-ratio">100%</div>
								</div>
							</div>
						</div>
					</div>
					<p className="certified-phrase">
						{t("Shareholder.ShareholderDetail.View.Index.certified")}
					</p>
					<div className="create-date">{dateToString(regDate, "yyyy-MM-dd")}</div>
				</section>
				<section className="shareholder-footer">
					<div className="info-area">
						<h2 className="company-name">{companyName}</h2>
						<p className="company-address">
							{companyAddress} {companyAddressDetail}
						</p>
					</div>
					<div className="seal-area">
						<dl>
							<dd className="signatory-position">
								{t("Shareholder.ShareholderDetail.Edit.Index.position")}
							</dd>
							<dt className="signatory-name">{companyCeo}</dt>
						</dl>
						<div className="selected-signature">
							<img
								src={signature}
								alt={t("Shareholder.ShareholderDetail.View.Index.signatureImg")}
							/>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};
export default View;
