import React, { useEffect, useState } from "react";
import Form from "~/common/components/Form";
import FileUploader from "~/common/components/FileUploader";
import Textarea from "~/common/components/Textarea";
import DatePicker from "~/common/components/DatePicker";
import { useFieldArray } from "react-hook-form";
import { addDays, getDay } from "date-fns";
import { isInteger } from "~/utils/formatter";
import SelectedDate from "~/pages/vacation/VacationListPage/ApplyVacationModal/Edit/components/SelectedDate";
import { UPLOAD_TYPE } from "~/common/constants/upload";

const CommonVacation = ({
	useForm,
	selectedVacation,
	workTime,
	restTime,
	workDays,
	setSubmitDisable,
	defaultWorkTime,
}) => {
	const { control, setValue } = useForm;
	const { fields, replace } = useFieldArray({
		control,
		name: "dateAndTime",
	});
	const [isMaternity, setIsMaternity] = useState(false);
	const [isPaidOnly, setIsPaidOnly] = useState(false);
	const currentStartDate = useForm.watch("startDate");
	const currentEndDate = useForm.watch("endDate");
	const remainingDays = parseFloat(selectedVacation.remainingTime);

	const [maxEndDate, setMaxEndDate] = useState("");

	//date 선택 시 개수만큼 select 표시
	useEffect(() => {
		replace([
			{
				timeUnit: !isInteger(selectedVacation.days) ? "1" : "0",
				startDate: currentStartDate,
				endDate: currentEndDate,
				startTime: workTime.morningVStartTime[0],
				endTime: workTime.afternoonVEndTime[0],
			},
		]);
	}, [currentStartDate, currentEndDate]);

	const autoSetEndDate = (date, days) => {
		if (!selectedVacation.weekend) {
			const newArray = [];
			for (let i = 0; ; i++) {
				const newDate = addDays(date, i);
				if (workDays.includes(getDay(newDate))) {
					newArray.push(newDate);
				}
				if (newArray.length === days) {
					break;
				}
			}

			const newEndDate = newArray[newArray.length - 1];
			setValue("endDate", newEndDate);
			setMaxEndDate(newEndDate);
		} else {
			const endDateWeekend = addDays(date, days - 1);
			setValue("endDate", endDateWeekend);
			setMaxEndDate(endDateWeekend);
		}
	};

	useEffect(() => {
		if (remainingDays > 1) {
			autoSetEndDate(currentStartDate, remainingDays);
		}

		switch (selectedVacation.statutoryType) {
			case "MATERNITY":
			case "MULTIPLE":
				setIsMaternity(true);
				break;
			default:
				setIsMaternity(false);
				break;
		}
	}, []);

	useEffect(() => {
		isMaternity &&
			(isPaidOnly
				? autoSetEndDate(currentStartDate, 60)
				: autoSetEndDate(currentStartDate, remainingDays));
	}, [isMaternity, isPaidOnly]);

	const onChangeEndDate = (value) => {
		isInteger(remainingDays)
			? autoSetEndDate(value, remainingDays)
			: setValue("endDate", value);
	};

	//---종료일  readonly 기준
	const [endDateReadOnly] = useState(selectedVacation.continuous);

	const isWeekday = (date) => {
		const day = getDay(date);
		return workDays.includes(day);
	};

	useEffect(() => {
		selectedVacation.documents === "pre" && !useForm.watch("file")
			? setSubmitDisable(true)
			: setSubmitDisable(false);
	}, [useForm.watch("file")]);

	return (
		<>
			<Form useForm={useForm} className="annual-vacation-form vertical datePicker-wrap">
				<DatePicker
					label="시작일"
					name={"startDate"}
					onChange={onChangeEndDate}
					filterDate={!selectedVacation.weekend && isWeekday}
				/>
				<span className="dash"> - </span>
				<DatePicker
					label="종료일"
					name={"endDate"}
					readOnly={endDateReadOnly}
					minDate={currentStartDate}
					maxDate={maxEndDate}
					filterDate={!selectedVacation.weekend && isWeekday}
				/>
			</Form>
			{isMaternity && (
				<div className="checkbox paidOnly">
					<label className={`checkbox-custom`}>
						<input
							type="checkbox"
							checked={isPaidOnly}
							onChange={(e) => setIsPaidOnly(e.target.checked)}
						/>
						<span className="checkmark" />
						<span className="checkbox-label">
							유급 휴가 기간만 휴가사용 (유급휴가: 60일)
						</span>
					</label>
				</div>
			)}
			{!isInteger(selectedVacation.days) &&
				fields.map((field, index) => {
					const getName = (name) => `dateAndTime.${index}.${name}`;
					return (
						<SelectedDate
							key={field.id}
							name={getName}
							vacationUnit={"only-half"}
							{...{
								useForm,
								setValue,
								workTime,
								restTime,
								selectedVacation,
								defaultWorkTime,
							}}
						/>
					);
				})}
			{selectedVacation.documents !== "none" && (
				<Form
					useForm={useForm}
					className="annual-vacation-form vertical c__file-upload-form"
				>
					<FileUploader
						label="증명 자료 제출하기"
						name="file"
						type={UPLOAD_TYPE.HR_VACATION}
						required={selectedVacation.documents === "pre" && null}
					/>
					<p className={`file-phrase ${selectedVacation.documents}`}>
						{selectedVacation.documents === "pre"
							? "※ 해당 휴가 등록은 증명 자료가 필수입니다."
							: "※ 해당 휴가 등록은 증명서 추후 재출이 가능합니다."}
					</p>
				</Form>
			)}
			<Form useForm={useForm} className="annual-vacation-form vertical">
				<Textarea label="메모" name="memo" className="memo-textarea" />
			</Form>
		</>
	);
};

export default CommonVacation;
