import React, { useEffect, useState } from "react";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import TabMenu from "~/common/components/TabMenu";

import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import { Case, If } from "~/common/components/If";
import { CollapseArrow, SearchIcon } from "~/assets/svg/InterfacesIcon";

import "~/assets/sass/pages/community/MyPostPage/myPost.scss";
import "~/assets/sass/pages/community/MyPostPage/myPostModal.scss";
import noImg from "~/assets/img/defaultImg/profile-large.png";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import { useGlobalStore } from "~/store";
import Pagination from "~/common/components/Pagination";
import { useNavigate } from "react-router";
import Loading from "~/common/components/Loading";
import MyCommunity from "~/pages/community/MyPostPage/component/myCommunity";
import MyPost from "~/pages/community/MyPostPage/component/MyPost";
import MyComment from "~/pages/community/MyPostPage/component/MyComment";
import useAlert from "~/common/hooks/useAlert";
import NoData from "~/common/components/NoData";
import translation from "~/translations";
import { useTranslation } from "react-i18next";

const MyPostPage = () => {
	const { t } = useTranslation();
	const [currentTab, setCurrentTab] = useState("post");
	const [myCommunityModal, setMyCommunityModal] = useState(false);
	const [myPostPagination, setMyPostPagination] = useState({});
	const [myPostList, setMyPostList] = useState([]);
	const [myCommunityList, setMyCommunityList] = useState();
	const [searchValue, setSearchValue] = useState("");
	const [pageNumber, setPageNumber] = useState(1);
	const [postCount, setPostCount] = useState({});
	const [page, setPage] = useState(PAGE_STATE.LOADING);
	const [
		{
			user: {
				info: { id, name, img },
			},
		},
	] = useGlobalStore();

	const [showInfoAlert, InfoAlert] = useAlert();

	const navigate = useNavigate();

	const myPostRequest = useRequest("post", "community/my-page/article/search");
	const myCommentRequest = useRequest("post", "community/my-page/comment/search");
	const myCommunityRequest = useRequest("post", "community/my-page/community/search");

	const tabMenuList = [
		{ id: "post", title: translation.t("Community.MyPostPage.post") },
		{ id: "comment", title: translation.t("Community.MyPostPage.comment") },
	];

	const handleMyPageRequest = () => {
		currentTab === "post"
			? myPostRequest.call({ page: pageNumber, size: 11, query: searchValue }, "body")
			: myCommentRequest.call({ page: pageNumber, size: 11, query: searchValue }, "body");
	};

	const handleResetRequest = () => {
		currentTab === "post"
			? myPostRequest.call({ page: 1, size: 11, query: "" }, "body")
			: myCommentRequest.call({ page: 1, size: 11, query: "" }, "body");
	};

	const handleCurrentTab = (tab) => {
		if (currentTab !== tab) {
			setPage(PAGE_STATE.LOADING);
			setCurrentTab(tab);
			setPageNumber(1);
			setSearchValue("");
		}
		if (currentTab === tab) {
			handleResetRequest();
			setSearchValue("");
			setPageNumber(1);
		}
	};

	useEffect(() => {
		handleMyPageRequest();
	}, [pageNumber, currentTab]);

	useEffect(() => {
		myCommunityRequest.call();
	}, []);

	useEffect(() => {
		switch (myCommunityRequest.state) {
			case API_STATE.done:
				const response = myCommunityRequest.response.data;
				setMyCommunityList(response.communities);
				break;
			case API_STATE.error:
				navigate("/404");
				break;
		}
	}, [myCommunityRequest.state]);

	useEffect(() => {
		switch (myPostRequest.state) {
			case API_STATE.done:
				const response = myPostRequest.response.data;
				setPostCount({
					noticeCount: response.noticeCount,
					articleCount: response.articleCount,
				});
				setMyPostList(response.boards);
				setMyPostPagination(response.pagination);
				setPage(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				navigate("/404");
				break;
		}
	}, [myPostRequest.state]);

	useEffect(() => {
		switch (myCommentRequest.state) {
			case API_STATE.done:
				const response = myCommentRequest.response.data;
				setPostCount({ commentCount: response.commentCount });
				setMyPostList(response.comments);
				setMyPostPagination(response.pagination);
				setPage(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				navigate("/404");
				break;
		}
	}, [myCommentRequest.state]);

	return (
		<NavbarAndSidebarLayout menu={["community", "myPage"]} type="main">
			<PageHeader />
			<section className="c__card community-post-section">
				<div className="member-info">
					<div className="profile-img">
						<img
							src={img ?? noImg}
							alt={t("Img.profile")}
							onError={(e) => (e.target.src = noImg)}
						/>
					</div>
					<div className="profile-info">
						<span className="profile-name">{name}</span>
						<span className="profile-email">{id}</span>
						<div className="state-info">
							<If condition={currentTab}>
								<Case condition={"post"}>
									<p className="state">
										{t("Community.MyPostPage.writing")}
										<span className="state-num"> {postCount.noticeCount}</span>
									</p>
									<p className="state">
										{t("Community.MyPostPage.communityWriting")}
										<span className="state-num"> {postCount.articleCount}</span>
									</p>
								</Case>
								<Case condition={"comment"}>
									<p className="state">
										{t("Community.MyPostPage.comment")}
										<span className="state-num"> {postCount.commentCount}</span>
									</p>
								</Case>
							</If>
							<button
								type="button"
								className="btn-more"
								onClick={() => setMyCommunityModal((prevState) => !prevState)}
							>
								{t("Community.MyPostPage.belong")} <CollapseArrow />
							</button>
						</div>
					</div>
				</div>
				<div className="community-post-wrap">
					<TabMenu
						tabItem={tabMenuList}
						currentTab={currentTab}
						setCurrentTab={handleCurrentTab}
					/>
					<div className="community-post-table">
						<div className="table-header">
							<h3 className="table-title">{t("Community.MyPostPage.fullDetails")}</h3>
							<div className="c__search">
								<input
									className="c__input"
									placeholder={t(
										"Community.BoardPage.BoardPost.List.searchPlaceholder"
									)}
									value={searchValue}
									onChange={(e) => setSearchValue(e.target.value)}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											handleMyPageRequest();
										}
									}}
								/>
								<button
									type="button"
									className="btn-search"
									onClick={handleMyPageRequest}
								>
									<SearchIcon />
								</button>
							</div>
						</div>
						<If condition={page}>
							<Case condition={PAGE_STATE.VIEW}>
								<div className="community-post-list">
									<If
										condition={currentTab}
										{...{ myPostList, myCommunityList, showInfoAlert }}
									>
										<Case condition={"post"}>
											<MyPost />
										</Case>
										<Case condition={"comment"}>
											<MyComment />
										</Case>
									</If>
								</div>
							</Case>
						</If>
						<Pagination
							setPageNumber={setPageNumber}
							totalPage={myPostPagination.totalPage}
							currentPage={pageNumber}
						/>
					</div>
				</div>
				<InfoAlert
					type="info"
					className="overtime-use-modal"
					layout="vertical"
					width={430}
					height={306}
					message={
						<>
							<h3>{t("Community.MyPostPage.overtime-use-modal.h3")}</h3>
							<p>{t("Community.MyPostPage.overtime-use-modal.p")}</p>
						</>
					}
					confirm={t("button.check")}
				/>
				{/* MyPost Modal */}
				<ModalTemplate
					title={t("Community.MyPostPage.belong")}
					modalState={myCommunityModal}
					setModalToggle={setMyCommunityModal}
					width={728}
					height={630}
					className="MyPost-modal"
				>
					<div className="modal-content">
						<ul className="modal-list">
							{myCommunityList ? (
								myCommunityList?.length === 0 ? (
									<NoData main={t("Community.MyPostPage.communityNoData")} />
								) : (
									<MyCommunity {...{ myCommunityList }} />
								)
							) : (
								<Loading />
							)}
						</ul>
					</div>
					<div className="modal-footer">
						<button
							className="btn__solid"
							color="primary"
							onClick={() => setMyCommunityModal((prevState) => !prevState)}
						>
							{t("button.check")}
						</button>
					</div>
				</ModalTemplate>
			</section>
		</NavbarAndSidebarLayout>
	);
};
export default MyPostPage;
