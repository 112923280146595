import React, { useState, useEffect } from "react";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import { Case, If, OtherWise } from "~/common/components/If";

//Assets
import SquareInitialImg from "~/assets/img/defaultImg/logo-square.png";
import { LightPlusIcon } from "~/assets/svg/MathIcon";
import "src/assets/sass/component/imageUploader.scss";
import { EditIcon } from "~/assets/svg/InterfacesIcon";

/**
 * TODO: useUpload 사용하도록 변경 @JoonhyungCho
 */

const SingleImageUploader = ({
	uploaderConstant,
	width,
	height,
	round,
	setImg,
	img,
	deletable,
	editable,
	handleEdit,
	name,
}) => {
	const [status, setStatus] = useState("init");
	const uploadRequest = useRequest("post", "file/st/upload");

	const onChangeImage = (e) => {
		const file = e.target.files[0];

		if (uploaderConstant !== undefined) {
			if (file.type.includes("image")) {
				const formData = new FormData();
				formData.append("path", uploaderConstant.path);
				formData.append("type", uploaderConstant.type);
				formData.append("file", file);

				uploadRequest.call(formData);
			} else {
				alert("이미지 형식을 확인해주세요.");
				setStatus("init");
			}
		} else {
			console.log("error: undefined uploaderConstant");
			setStatus("error");
		}
	};

	useEffect(() => {
		switch (uploadRequest.state) {
			case API_STATE.done:
				const responseData = uploadRequest.response.data;
				setImg(responseData);
				setStatus("done");
				break;
			case API_STATE.loading:
				setStatus("loading");
				break;
			case API_STATE.error:
				setStatus("error");
				console.log("error", uploadRequest.error);
				break;
			default:
				break;
		}
	}, [uploadRequest.state, uploadRequest.response, uploadRequest.error]);

	const handleDelete = () => {
		setImg("");
		setStatus("init");
	};

	useEffect(() => {
		if (img === undefined || img === "") {
			setStatus("init");
		} else {
			setStatus("done");
		}
	}, [status, img]);

	return (
		<div
			className={`single-uploader ${round && "round"}`}
			style={{ width: width, height: height }}
		>
			<If condition={status}>
				<Case condition={"done"}>
					<div className="upload-preview">
						<img
							src={img}
							alt={img}
							onError={(e) => {
								e.target.src = SquareInitialImg;
							}}
						/>
						<span className="upload-preview-actions">
							<button type="button">
								<a href={img} target="_blank" rel="noreferrer">
									<i className="simple-icon-size-fullscreen" />
								</a>
							</button>
							{deletable && (
								<button type="button" onClick={handleDelete}>
									<i className="simple-icon-trash" />
								</button>
							)}
							{editable && (
								<button onClick={handleEdit}>
									<EditIcon />
								</button>
							)}
						</span>
					</div>
				</Case>
				<Case condition={"loading"}>
					<div className="uploader-select">
						<span className="uploader-actions">
							<div className="c__spin" />
						</span>
					</div>
				</Case>
				<OtherWise>
					<div className={`uploader-select ${status === "error" && "error"}`}>
						<input type="file" accept="image/*" name={name ?? "file"} onChange={onChangeImage} />
						<If condition={status}>
							<Case condition={"init"}>
								<span className="uploader-actions">
									<LightPlusIcon />
									Upload
								</span>
							</Case>
							<Case condition={"error"}>
								<span className="uploader-actions">
									<i className="simple-icon-trash" />
									<p className="error-msg">Upload Fail</p>
								</span>
							</Case>
						</If>
					</div>
				</OtherWise>
			</If>
		</div>
	);
};

export default SingleImageUploader;

SingleImageUploader.defaultProps = {
	round: false,
	width: 160,
	height: 160,
	deletable: true,
};
