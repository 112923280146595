import React from "react";
import { Case, If } from "~/common/components/If";
import NoData from "~/common/components/NoData";

const Empty = ({ currentTab, selectedUser }) => {
	const user = selectedUser.User.UserProfile.name;
	return (
		<div className="vacation-chk-list">
			<If condition={currentTab}>
				<Case condition={"expected"}>
					<NoData main={`${user}님의 예정 휴가 일정이 존재하지 않습니다.`} />
				</Case>
				<Case condition={"used"}>
					<NoData main={`${user}님의 "지난 휴가 일정이 존재하지 않습니다.`} />
				</Case>
				<Case condition={"all"}>
					<NoData
						main={`${user}님의 관리내역이 존재하지 않습니다.`}
						sub={
							"관리내역에는 휴가의 승인, 대기, 반려 여부 및 연차 부여, 차감 내역이 보여집니다."
						}
					/>
				</Case>
			</If>
		</div>
	);
};

export default Empty;
