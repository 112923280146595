export const SET_BOARD_LIST = "community/SET_BOARD_LIST";
export const SET_CURRENT_BOARD = "community/SET_CURRENT_BOARD";
export const SET_COMMUNITY_AUTH = "community/SET_COMMUNITY_AUTH";
export const SET_CURRENT_NOTICE = "community/SET_CURRENT_NOTICE";
export const CLEAR_CURRENT_NOTICE = "community/CLEAR_CURRENT_NOTICE";

export const setBoardList = ({ boardList }) => {
	return {
		type: SET_BOARD_LIST,
		payload: {
			boardList: boardList,
		},
	};
};

export const setCurrentBoard = ({ currentBoard }) => {
	return {
		type: SET_CURRENT_BOARD,
		payload: {
			currentBoard: currentBoard,
		},
	};
};

export const setCommunityAuth = ({ myAuth }) => {
	return {
		type: SET_COMMUNITY_AUTH,
		payload: {
			myAuth: myAuth,
		},
	};
};

export const setCurrentNotice = ({ currentNotice }) => {
	return {
		type: SET_CURRENT_NOTICE,
		payload: {
			currentNotice: currentNotice,
		},
	};
};

export const clearCurrentNotice = () => {
	return {
		type: CLEAR_CURRENT_NOTICE,
		payload: {
			currentNotice: {
				content: "",
				contentFiles: [],
				createdAt: "",
				hits: 0,
				idx: "",
				settings: [{ idx: "", type: "", value: "" }],
				title: "",
				updatedAt: null,
				uploadFiles: [],
				user: {},
			},
		},
	};
};
