import React, { useEffect, useState } from "react";
import SingleImageUploader from "~/common/components/ImageUploader/SingleImageUploader";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import { API_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import { useTranslation } from "react-i18next";

const File = ({ setModalToggle, getSignatureList, signatureListRequest }) => {
	const { t } = useTranslation();
	const [uploadedImg, setUploadedImg] = useState("");
	const signatureRequest = useRequest("post", "admin/signature");

	const handleSubmit = () => {
		signatureRequest.call({
			imgUrl: uploadedImg,
			type: "IMG",
		});
	};

	useEffect(() => {
		switch (signatureRequest.state) {
			case API_STATE.done:
				signatureListRequest && signatureListRequest.call();
				getSignatureList();
				setModalToggle(false);
				break;
			case API_STATE.error:
				alert(t("MyPage.SignaturePage.SignatureUploadModal.UploadAlert"));
				console.log("error", signatureRequest.error);
				break;
			default:
				break;
		}
	}, [signatureRequest.state, signatureRequest.response, signatureRequest.error]);

	return (
		<>
			<SingleImageUploader
				uploaderConstant={UPLOAD_TYPE.COMPANY_SIGN}
				width={200}
				height={110}
				img={uploadedImg}
				setImg={setUploadedImg}
			/>
			<p className="notice pre">
				{t("MyPage.admin.Signature.SignatureUploadModal.components.notice")}
			</p>
			<div className="modal-footer">
				<button
					type="button"
					onClick={() => setModalToggle(false)}
					className="btn__solid"
					color="gray"
				>
					{t("button.cancel")}
				</button>
				<button className="btn__solid" color="primary" onClick={handleSubmit}>
					{t("button.registering")}
				</button>
			</div>
		</>
	);
};
export default File;
