import { SET_VACATION_TYPE, SET_TEAM_SCHEDULE } from "./actions";

export const INITIAL_STATE = {
	vacationType: {
		idx: "",
		title: "",
		days: "",
		weekend: true,
		continuous: true,
		paid: true,
		gender: "",
		documents: "",
		alarm: true,
		autoConfirm: true,
		activate: true,
		type: "",
		refresh: "",
		startupCompanyIdx: "",
		vacationAuthorization: [],
		remainingTime: "",
	},
	teamVacation: [],
	teamBusinessTrip: [],
	teamRemoteWork: [],
	calendarEvents: [],
};

export const REDUCER = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_VACATION_TYPE:
			return {
				...state,
				vacationType: action.payload.vacationType,
			};
		case SET_TEAM_SCHEDULE:
			return {
				...state,
				teamVacation: action.payload.teamVacation,
				teamBusinessTrip: action.payload.teamBusinessTrip,
				teamRemoteWork: action.payload.teamRemoteWork,
				calendarEvents: action.payload.calendarEvents,
			};
		default:
			return state;
	}
};
