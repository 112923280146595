import React, { useEffect, useState } from "react";
import RequestMsg from "~/pages/invest/EvaluatePage/EvaluateDetail/View/components/requestMsg";
import AcceptInfo from "~/pages/invest/EvaluatePage/EvaluateDetail/View/components/acceptInfo";
import { useGlobalStore } from "~/store";
import defaultAvatar from "~/assets/img/defaultImg/profile-large.png";
import { isInteger } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const Confirm = () => {
	const { t } = useTranslation();
	const [
		{
			evaluate: {
				currentEvaluate: { Judge },
			},
		},
	] = useGlobalStore();
	const [percent, setPercent] = useState(null);

	const completeJudgeCount = Judge.filter((j) => j.status === "심사완료").length;
	const totalJudgeCount = Judge.length;

	useEffect(() => {
		if (completeJudgeCount !== 0) {
			const result = ((completeJudgeCount / totalJudgeCount) * 100).toFixed(2);
			isInteger(result) ? setPercent(parseInt(result)) : setPercent(result);
		} else {
			setPercent(0);
		}
	}, []);

	return (
		<>
			<section className="c__card message">
				<RequestMsg />
			</section>
			<AcceptInfo />
			<section className="c__card proceeding">
				<div className="top">
					<h5 className="percent">
						{percent}
						<i>%</i>
					</h5>
					<p className="main">{t("Invest.Evaluate.EvaluateDetail.Confirm.main")}</p>
					{completeJudgeCount === totalJudgeCount ? (
						<p className="sub">{t("Invest.Evaluate.EvaluateDetail.Confirm.sub1")}</p>
					) : (
						<p className="sub">{t("Invest.Evaluate.EvaluateDetail.Confirm.sub2")}</p>
					)}
				</div>
				<ul className="judge-list">
					{Judge.map((judge, j_idx) => {
						return (
							<li
								className={
									judge.status ===
									t("Invest.Evaluate.EvaluateDetail.Confirm.audit-complete")
										? "complete-judge"
										: undefined
								}
								key={j_idx}
							>
								<img
									src={judge.img ? judge.img : defaultAvatar}
									onError={(e) => {
										e.target.src = defaultAvatar;
									}}
									alt={judge.name}
								/>
							</li>
						);
					})}
				</ul>
			</section>
		</>
	);
};

export default Confirm;
