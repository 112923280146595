import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import * as env from "~/common/constants/env";
import PageHeader from "~/common/components/PageHeader";
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import useRequest from "~/common/hooks/useRequest";
import View from "~/pages/vc/VcDetail/View";

//Assets
import "~/assets/sass/pages/vc/detail.scss";
import "~/assets/sass/pages/vc/noData.scss";
import axios from "axios";

export const getNewsList = (data, newsPaging, newsOffset) => {
	return axios.patch(
		`${env.EM_NEWS_API_URL}/news/search?page=${newsPaging}&size=${newsOffset}`,
		data
	);
};

const VcDetail = () => {
	const { idx } = useParams();
	const navigate = useNavigate();

	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [newsState, setNewsState] = useState(PAGE_STATE.LOADING);
	const [vcInfo, setVcInfo] = useState([]);
	const [portfolioList, setPortfolioList] = useState([]);
	const [newsList, setNewsList] = useState([]);
	const [newsOffset] = useState(5);
	const [newsPaging] = useState(1);

	const vcRequest = useRequest("get", `company/vc`);

	const [retry, setRetry] = useState(false);

	const getCompanyNews = async (companyName, newsPaging, newsOffset) => {
		await getNewsList(
			{
				title: companyName,
			},
			newsPaging,
			newsOffset
		)
			.then((response) => {
				const responseStatus = response.data.status;
				if (responseStatus === 0) {
					setNewsList(response.data.data.content);
					setNewsState(PAGE_STATE.VIEW);
				} else if (responseStatus === 500) {
					console.log("500 error", response);
					setRetry(true);
					setNewsState("error");
				} else {
					console.log("error", response);
					setNewsState(PAGE_STATE.VIEW);
				}
			})
			.catch((e) => {
				console.log("error", e);
				setNewsState(PAGE_STATE.VIEW);
			});
	};

	useEffect(() => {
		vcRequest.singleCall(idx, "path");
	}, []);

	useEffect(() => {
		if (retry) {
			getCompanyNews(vcInfo.name, newsPaging, newsOffset, retry);
		}
	}, [retry]);

	useEffect(() => {
		switch (vcRequest.state) {
			case API_STATE.done:
				setVcInfo(vcRequest.response.data);

				const portfolios = vcRequest.response.data.VcStartupPortfolioInfo;
				let arr = {};
				portfolios.forEach((p) => {
					const { year } = p;
					if (!arr[year]) arr[year] = [];
					arr[year].push(p);
				});
				arr["all"] = portfolios;
				setPortfolioList(arr);

				getCompanyNews(vcRequest.response.data.name, newsPaging, newsOffset, retry);
				setPageState(PAGE_STATE.VIEW);

				break;
			case API_STATE.error:
				console.log("error", vcRequest.state);
				navigate("/404");
				break;
		}
	}, [vcRequest.state]);

	return (
		<>
			<NavbarAndSidebarLayout menu={["vc", "vcInfo"]} type="main" menuName={"vc-info"}>
				<PageHeader />
				<If condition={pageState} {...{ setPageState }}>
					<Case condition={PAGE_STATE.VIEW}>
						<View {...{ vcInfo, portfolioList, newsList, newsState }} />
					</Case>
					<Case condition={PAGE_STATE.LOADING}>
						<Loading />
					</Case>
				</If>
			</NavbarAndSidebarLayout>
		</>
	);
};

export default VcDetail;
