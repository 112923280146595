export const SET_VC_LIST = "evaluate/SET_VC_LIST";
export const SET_COMPANY_VC_KEYWORD = "company/SET_COMPANY_KEYWORD";

export const setVcList = ({ vcList }) => {
	return {
		type: SET_VC_LIST,
		payload: {
			vcList: vcList,
		},
	};
};

export const setCompanyVcKeyword = ({ fields, types }) => {
	return {
		type: SET_COMPANY_VC_KEYWORD,
		payload: {
			fields: fields,
			types: types,
		},
	};
};
