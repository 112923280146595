import React, { useEffect, useState } from "react";
import { If, Case } from "~/common/components/If";



//Assets
import "~/assets/sass/pages/mypage/signature/signatureUpload.scss";
import InviteResult from "./content/InviteResult";
import InviteForm from "./content/inviteForm";


const InviteModal = ({ setModalToggle, teamInviteRequest, setModalWidth , excelData,setExcelData }) => {
	const [step, setStep] = useState("form");
	const [inviteResponseData, setInviteResponseData] = useState([]);

	useEffect(() => {
		if (step === "result") {
			setModalWidth(550);
		} else {
			setModalWidth(700);
		}
	}, [step, setModalWidth]);

	return (
		<If
			condition={step}
			setStep={setStep}
			{...{ setModalToggle, inviteResponseData, setInviteResponseData }}
		>
			<Case condition={"form"}>
				<InviteForm excelData={excelData} setExcelData={setExcelData}/>
			</Case>
			<Case condition={"result"}>
				<InviteResult teamInviteRequest={teamInviteRequest} />
			</Case>
		</If>
	);
};
export default InviteModal;
