import React from "react";

import {
	ComposedChart,
	Line,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from "recharts";

/**
 * @param { Props } props
 *
 * @typedef Props
 * @property {{[key:string]: any}[]} data
 * @property {{[key:string]:{name:string,color: string, lineColor: string, type: "line" | "bar"}}} mapTable
 * @property {string} xAxisKey
 * @property {string} axisColor
 * @property {string} gridColor
 * @property {number} height
 */
const TrendChart = (props) => {
	const {
		data,
		mapTable,
		xAxisKey,
		axisColor = "#9b9b9b",
		gridColor = "#EBEEF1",
		height,
	} = props;

	const Dot = (props) => {
		const { cx, cy, r, color } = props;
		if (cx === null || cy === null) {
			return <></>;
		} else {
			return <circle cx={cx} cy={cy} r={r} fill="white" strokeWidth={2} stroke={color} />;
		}
	};

	const RectCursor = (props) => {
		const {
			points: {
				0: { x, y },
			},
			width,
			height,
		} = props;
		const rectWidth = width / data.length;
		return (
			<rect
				fill="rgba(0,0,0,0.03)"
				x={x - rectWidth / 2}
				y={y}
				width={rectWidth}
				height={height}
			/>
		);
	};

	const TickLabel = (props) => {
		const {
			x,
			y,
			height,
			width,
			payload: { value },
			fontSize,
			direction,
		} = props;
		return (
			<text
				orientation="left"
				width={width}
				height={height}
				type="number"
				x={x}
				y={y}
				stroke="none"
				className="recharts-text recharts-cartesian-axis-tick-value"
				textAnchor={direction === "x" ? "middle" : "end"}
			>
				<tspan
					x={x}
					dy={fontSize / (direction === "x" ? 1 : 2)}
					style={{ fontSize: fontSize + "px", fill: "#9b9b9b" }}
				>
					{value}
				</tspan>
			</text>
		);
	};

	const ToolTip = ({ label, payload = [] }) => {
		const keyRenderer = () => {
			if (payload === null) {
				return <></>;
			}
			return payload.map(({ name: key, value }) => {
				const { name, color, type } = mapTable[key];
				return (
					<div
						className="recharts-tooltip-payload"
						style={{ marginBottom: "1px", display: "flex", flexDirection: "row" }}
						key={name}
					>
						<div
							style={{
								height: "15px",
								width: "10px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginRight: "5px",
							}}
						>
							{type === "bar" ? (
								<svg viewBox="0 0 4 10" height={10} width={4}>
									<rect height={10} width={4} fill={color} />
								</svg>
							) : (
								<svg viewBox="0 0 8 8" height={8} width={8}>
									<Dot cy={4} cx={4} r={3} color={color} />
								</svg>
							)}
						</div>
						<div
							style={{
								fontSize: "13px",
								height: "15px",
								paddingTop: "1px",
								color: "#9b9b9b",
							}}
						>
							{name} : {value}
						</div>
					</div>
				);
			});
		};
		return (
			<div className="recharts-tooltip-wrapper">
				<div
					className="recharts-default-tooltip"
					style={{
						padding: "10px",
						backgroundColor: "rgba(255, 255, 255, 0.9)",
						border: "1px solid rgb(204, 204, 204)",
						whiteSpace: "nowrap",
					}}
				>
					<p
						className="recharts-tooltip-label"
						style={{ fontWeight: "bold", color: "#9b9b9b", marginBottom: "5px" }}
					>
						{label}
					</p>
					{keyRenderer()}
				</div>
			</div>
		);
	};

	const chartRenderer = () => {
		return Object.keys(mapTable).map((key) => {
			const { type, color } = mapTable[key];
			if (type === "line") {
				const { lineColor } = mapTable[key];
				return (
					<Line
						key={key}
						isAnimationActive={false}
						connectNulls
						dataKey={key}
						stroke={lineColor}
						strokeWidth={2}
						dot={<Dot color={color} />}
						activeDot={<Dot color={color} />}
					/>
				);
			} else if (type === "bar") {
				return <Bar key={key} isAnimationActive={false} dataKey={key} fill={color} />;
			} else {
				return <div key={key}></div>;
			}
		});
	};

	return (
		<ResponsiveContainer height={height}>
			<ComposedChart data={data} margin={{ top: 5, left: -30 }}>
				<CartesianGrid
					stroke={gridColor}
					y={false}
					vertical={false}
					strokeWidth={1}
					strokeDasharray="3 3"
				/>
				<XAxis
					dataKey={xAxisKey}
					tickLine={false}
					tick={<TickLabel direction="x" fontSize={11} />}
					tickCount={5}
					stroke={axisColor}
				/>
				<YAxis
					tickLine={false}
					tick={<TickLabel direction="y" fontSize={11} />}
					stroke={axisColor}
					// Y 좌표계의 최대값이 데이터 최대값이 되지 않게 하기 위한 snippet
					// domain={[
					// 	"auto",
					// 	(max) => Math.round(max + 10 ** (max.toString().length - 1) / 2),
					// ]}
				/>
				<Tooltip isAnimationActive={false} content={<ToolTip />} cursor={<RectCursor />} />
				{chartRenderer()}
			</ComposedChart>
		</ResponsiveContainer>
	);
};

export default TrendChart;
