import React from "react";
import { useGlobalStore } from "~/store";
import { useTranslation } from "react-i18next";

const ProcessStep = () => {
	const { t } = useTranslation();
	const [
		{
			evaluate: { currentEvaluate },
		},
	] = useGlobalStore();
	const completeCount = currentEvaluate.Judge.filter((d) => d.status === "complete").length;

	return (
		<section className="c__card process-step-wrap">
			<h5>{t("Invest.Evaluate.EvaluateDetail.View.components.process-step-wrap.h5")}</h5>
			<ul className={`progress-step-bar ${currentEvaluate.statusType}`}>
				<li className="step">
					<i>1</i>
					<p className="status">{t("Invest.situation.request")}</p>
					<dl className="step-tooltip">
						<dt>
							{t(
								"Invest.Evaluate.EvaluateDetail.View.components.process-step-wrap.step-audit-request"
							)}
						</dt>
						<dd>
							{currentEvaluate.regDate} {t("Invest.situation.request")}
						</dd>
					</dl>
				</li>
				<li className="step">
					<i>2</i>
					<p className="status">{t("Invest.situation.audit")}</p>
					<dl className="step-tooltip">
						<dt>
							{currentEvaluate.statusType === "reject"
								? t("Invest.situation.reject")
								: `${t(
										"Invest.Evaluate.EvaluateDetail.View.components.process-step-wrap.step-audit"
								  )}${completeCount + "/" + currentEvaluate.Judge.length}`}
						</dt>
						<dd>
							{currentEvaluate.confirmDate}{" "}
							{currentEvaluate.statusType === "reject"
								? t("Invest.situation.reject")
								: t("Invest.situation.audit")}
						</dd>
					</dl>
				</li>
				<li className="step">
					<i>3</i>
					<p className="status">{t("Invest.situation.complete")}</p>
					<dl className="step-tooltip">
						<dt>{t("Invest.situation.complete")}</dt>
						<dd>
							{currentEvaluate.completeDate} {t("Invest.situation.complete")}
						</dd>
					</dl>
				</li>
			</ul>
		</section>
	);
};

export default ProcessStep;
