import React from "react";
import { ClipIcon } from "~/assets/svg/InterfacesIcon";
import { deleteTagRegex, oneDayPastCheck } from "~/utils/formatter";
import { ALLOW_COMMENT_STATE } from "~/common/constants/state";
import defaultImg from "~/assets/img/defaultImg/default-img.png";
import { useTranslation } from "react-i18next";

const AlbumView = (props) => {
	const { t } = useTranslation();
	const { noticeList, handleNavigateView } = props;

	return (
		<>
			<div className="community-album-table">
				<section className="c__cardRow">
					{noticeList &&
						noticeList.map((notice, index) => (
							<div className="c__card" key={index}>
								<div
									className="album-container"
									onClick={() => handleNavigateView(notice.idx)}
								>
									<div className="album-image">
										<img
											src={notice.contentFiles[0].path}
											alt={t("Community.board-img")}
											onError={(e) => (e.target.src = defaultImg)}
										/>
										<span className="tag">
											{notice.contentFiles.length === 1
												? notice.contentFiles.length
												: notice.contentFiles.length - 1 + " +"}
										</span>
									</div>

									<div className="title-box">
										<div className="text">{notice.title}</div>
										<div className="icon">
											{notice.uploadFiles.length !== 0 && (
												<span className="clip">
													<ClipIcon />
												</span>
											)}
											{notice.settings[0].value ===
												ALLOW_COMMENT_STATE.ALLOW &&
												notice.commentCount !== 0 && (
													<span className="num">
														[{notice.commentCount}]
													</span>
												)}
										</div>
									</div>
									<div className="title-name">
										<span className="name">{notice.user.name}</span>
										<span className="status">
											{oneDayPastCheck(notice.createdAt)}
										</span>
									</div>
								</div>
							</div>
						))}
				</section>
			</div>
		</>
	);
};

export default AlbumView;
