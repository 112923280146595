export const SET_SELECTED_IDX = "shareholder/SET_SELECTED_IDX";
export const CLEAR_SELECTED_IDX = "shareholder/CLEAR_SELECTED_IDX";

export const selectShareholder = (idx) => {
	return {
		type: SET_SELECTED_IDX,
		payload: {
			idx,
		},
	};
};

export const clearSelectedShareholder = () => ({
	type: CLEAR_SELECTED_IDX,
});
