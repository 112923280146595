import React from "react";
import { useNavigate } from "react-router-dom";

const PwChangeComplete = () => {
	const navigate = useNavigate();

	return (
		<>
			<div className="phrase-wrap">
				<h3>Change Password!</h3>
				<p>
					비밀번호가 성공적으로 변경되었습니다.
					<br />
					새로운 비밀번호로 gitbal Startup를 이용해주세요!
				</p>
			</div>
			<div className="btn-wrap">
				<button className="btn__solid" color="primary" onClick={() => navigate("/login")}>
					로그인
				</button>
			</div>
		</>
	);
};
export default PwChangeComplete;
