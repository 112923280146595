import { SET_VC_LIST, SET_COMPANY_VC_KEYWORD } from "./actions";

/**
 * @type {}
 */

export const INITIAL_STATE = {
	vcList: null,
	fields: [],
	types: [],
};

export const REDUCER = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_VC_LIST:
			return {
				...state,
				vcList: action.payload.vcList,
			};
		case SET_COMPANY_VC_KEYWORD:
			return {
				...state,
				fields: action.payload.fields,
				types: action.payload.types,
			};

		default:
			return state;
	}
};
