import translation from "~/translations";

export const RoundType = {
	1: "Pre Seed",
	2: "Seed",
	3: "Pre Series",
	4: "Series A",
	5: "Series B",
	6: "Series C",
	7: "Series D",
	8: "Series E",
	9: "M&A",
	10: "Pre IPO",
	11: "IPO",
};

export const ProgressType = {
	0: translation.t("contants.type.ProgressType.0"),
	1: translation.t("contants.type.ProgressType.1"),
};

export const ScheduleType = {
	businessTrip: translation.t("contants.type.ScheduleType.businessTrip"),
	remoteWork: translation.t("contants.type.ScheduleType.remoteWork"),
	overtime: translation.t("contants.type.ScheduleType.overtime"),
	// annual: "휴가",
};

export const MyPostType = {
	NOTICE: { label: translation.t("contants.type.MyPostType.NOTICE"), value: "내가 쓴 글" },
	COMMUNITY: {
		label: translation.t("contants.type.MyPostType.COMMUNITY"),
		value: "내가 쓴 댓글",
	},
};
