import React, { useEffect, useState } from "react";
import { Case, If } from "~/common/components/If";
import { useGlobalStore } from "~/store";

import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";

import Empty from "./Empty";
import View from "./View";
import Edit from "./Edit";

import "~/assets/sass/pages/company/info/common/modal.scss";
import PageHeader from "~/common/components/PageHeader";
import useRepository from "~/common/hooks/useRepository";
import { COMPANY_VC_KEYWORD } from "~/store/vc/repository";
import { useTranslation } from "react-i18next";
import useRequest from "~/common/hooks/useRequest";

const PAGE_STATE = {
	LOADING: "LOADING",
	EMPTY: "EMPTY",
	VIEW: "VIEW",
	EDIT: "EDIT",
};

const CompanyInfoPage = () => {
	const { t } = useTranslation();
	const [page, setPage] = useState(PAGE_STATE.LOADING);

	const [
		{
			user: {
				check: { comCheck },
			},
		},
	] = useGlobalStore();

	const [getCompanyVcKeyword] = useRepository(COMPANY_VC_KEYWORD);

	const certTypeSelect = [
		{
			label: t("Company.Info.Edit.options1"),
			value: "preliminary start-up"
		},
		{
			label: t("Company.Info.Edit.options2"),
			value: "individual business"
		},
		{
			label: t("Company.Info.Edit.options3"),
			value: "corporate business"
		},
	]

	const showEditPage = () => {
		setPage(PAGE_STATE.EDIT);
	};

	const Header = ({ buttonList = [] }) => {
		return (
			<PageHeader
				title={t("Company.Info.Header.title")}
				mainTitle={t("Company.Info.Header.title")}
				subTitle={t("Company.Info.Header.sub-title")}
				buttonList={buttonList}
			/>
		);
	};

	// data
	const companyInfoApi = useRequest("get", `company/info`);
	const [companyInfoData, setCompanyInfoData] = useState({});
	const [dataSetCheck, setDataSetCheck] = useState(false);

	const handleGetCompnayInfo = async () => {
		await companyInfoApi
			.asyncCall()
			.then(async (res) => {
				setDataSetCheck(true);
				setCompanyInfoData(res.data);
			})
			.catch((e) => {
				console.log(e);
			});
	}

	useEffect(() => {
		getCompanyVcKeyword();
		if(comCheck) {
			handleGetCompnayInfo();
		}
	}, [comCheck]);

	// useEffect(() => {
	// 	getCompanyVcKeyword();
	// 	setPage(comCheck === true ? PAGE_STATE.VIEW : PAGE_STATE.EMPTY);
	// }, []);

	useEffect(() => {
		if(dataSetCheck) {
			if(companyInfoData?.company?.regNum) {
				setPage(PAGE_STATE.VIEW); // VIEW
				setDataSetCheck(false);
			} else {
				setPage(PAGE_STATE.EMPTY);
			}
		}
	}, [dataSetCheck, companyInfoData])



	return (
		<>
			<NavbarAndSidebarLayout menu={["company", "info"]} type="main">
				<If condition={page} {...{ PAGE_STATE, setPage }}>
					<Case condition={PAGE_STATE.EMPTY}>
						<Empty {...{ Header, showEditPage }} />
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						<View companyInfoData={companyInfoData} certTypeSelect={certTypeSelect} />
					</Case>
					<Case condition={PAGE_STATE.EDIT}>
						<Edit companyInfoData={companyInfoData} certTypeSelect={certTypeSelect} handleGetCompnayInfo={handleGetCompnayInfo} />
					</Case>
					<Case condition={PAGE_STATE.LOADING}>
						<PageHeader />
					</Case>
				</If>
			</NavbarAndSidebarLayout>
			{/* 기업정보(쓰기) 비공개설정모달
            <div className="c__modal toggleOpen show-mask">
               <div className="modal-container modal-private">
                   <div className="modal-content">
                       <CautionIcon/>
                       <div className="dialog-content">
                           <h4 className="dialog-title">비공개로 설정하시겠습니까?</h4>
                           <p className="dialog-text">
                               기업 정보를 비공개로 설정할 시 투자사로부터 <br/>
                               심사요청을 받을 수 없습니다.
                           </p>
                       </div>
                   </div>
                   <div className="modal-footer">
                       <button type="button" className="btn__solid" color="primary">예</button>
                       <button type="button" className="btn__solid" color="gray">아니요</button>
                   </div>
               </div>
            </div> */}
		</>
	);
};

export default CompanyInfoPage;
