import React, { useState } from "react";

// Components
import PageHeader from "~/common/components/PageHeader";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import DropdownTemplate from "~/common/components/DropdownPortal/DropdownTemplate";

//Assets
import "~/assets/sass/pages/mypage/admin/vc-connect.scss";
import logoImg from "~/assets/img/defaultImg/logo-square.png";
import { ConnectIcon } from "~/assets/svg/MathIcon";
import { ConnectPageIcon } from "~/svg/PageIntroIcon";

const VcConnectPage = () => {
	const [connectVcModal, setConnectVcModal] = useState(false);
	const [disConnectVcModal, setDisConnectVcModal] = useState(false);

	return (
		<NavbarAndSidebarLayout menu={["admin", "vcconnect"]} type={"myPage"}>
			<PageHeader />
			<div className="page-top">
				<div className="pageIntro">
					<ConnectPageIcon />
					<div className="content">
						<h3>
							<b>투자사와 연결</b>하고 <b>정보를 공유</b>합니다.
						</h3>
						<p>
							기업의 이벤트, 투자포트폴리오, 주주총회 정보를 투자사들과 간편하게
							공유하고 빠른 성장 지원을 받아보세요.
						</p>
					</div>
				</div>
				<div className="button-group">
					<button type="button" className="active">
						전체
					</button>
					<button type="button" disabled>
						요청
					</button>
					<button type="button" disabled>
						완료
					</button>
					<button type="button" disabled>
						취소
					</button>
				</div>
			</div>
			<div className="c__card">
				<div className={"vc-connect-table"}>
					<ul className="thead">
						<li className="th connect-num">No</li>
						<li className="th connect-name">투자사</li>
						<li className="th connect-check" />
						<li className="th connect-date">요청날짜</li>
						<li className="th connect-status">연결상태</li>
					</ul>
					{/*<p className="no-content">정보가 없습니다.</p>*/}
					<div className="table-body">
						<ul className="tr">
							<li className="td connect-num">1</li>
							<li className="td connect-name">
								<div className="connect-vc">
									<div className="connect-vc-logo">
										<img src={logoImg} alt="기업로고" />
									</div>
									<dl>
										<dt className="connect-vc-name">vc테스트</dt>
										<dd className="connect-vc-url">
											https://www.sunbonpartners.com
										</dd>
									</dl>
								</div>
							</li>
							<li className="td connect-check active">
								<ConnectIcon />
							</li>
							<li className="td connect-date">2020-12-03 13:39</li>
							<li className="td connect-status">
								요청
								<DropdownTemplate target={1} vertical>
									<li
										className="c__dropdownItem"
										onClick={() => setConnectVcModal(true)}
									>
										연결하기
									</li>
									<li
										className="c__dropdownItem"
										onClick={() => setDisConnectVcModal(true)}
									>
										연결끊기
									</li>
								</DropdownTemplate>
							</li>
						</ul>
						<ul className="tr">
							<li className="td connect-num">1</li>
							<li className="td connect-name">
								<div className="connect-vc">
									<div className="connect-vc-logo">
										<img src={logoImg} alt="기업로고" />
									</div>
									<dl>
										<dt className="connect-vc-name">vc테스트</dt>
										<dd className="connect-vc-url">
											https://www.sunbonpartners.com
										</dd>
									</dl>
								</div>
							</li>
							<li className="td connect-check active">
								<ConnectIcon />
							</li>
							<li className="td connect-date">2020-12-03 13:39</li>
							<li className="td connect-status">
								완료
								<DropdownTemplate target={1} vertical>
									<li
										className="c__dropdownItem"
										onClick={() => setConnectVcModal(true)}
									>
										연결하기
									</li>
									<li
										className="c__dropdownItem"
										onClick={() => setDisConnectVcModal(true)}
									>
										연결끊기
									</li>
								</DropdownTemplate>
							</li>
						</ul>
						<ul className="tr">
							<li className="td connect-num">1</li>
							<li className="td connect-name">
								<div className="connect-vc">
									<div className="connect-vc-logo">
										<img src={logoImg} alt="기업로고" />
									</div>
									<dl>
										<dt className="connect-vc-name">vc테스트</dt>
										<dd className="connect-vc-url">
											https://www.sunbonpartners.com
										</dd>
									</dl>
								</div>
							</li>
							<li className="td connect-check active">
								<ConnectIcon />
							</li>
							<li className="td connect-date">2020-12-03 13:39</li>
							<li className="td connect-status inactive">
								취소
								<DropdownTemplate target={1} vertical>
									<li
										className="c__dropdownItem"
										onClick={() => setConnectVcModal(true)}
									>
										연결하기
									</li>
									<li
										className="c__dropdownItem"
										onClick={() => setDisConnectVcModal(true)}
									>
										연결끊기
									</li>
								</DropdownTemplate>
							</li>
						</ul>
					</div>
				</div>
			</div>

			{/*투자사 연결히기 모달*/}
			<ModalTemplate
				modalState={connectVcModal}
				setModalToggle={setConnectVcModal}
				title="투자사 연결하기"
				width={580}
				className="connect-vc-modal"
			>
				<div className="modal-content">
					<div className="column">
						<dl className="connect-vc-title">
							<dt>
								<b>선보엔젤파트너스</b>에
							</dt>
							<dd>
								<b>포트폴리오를 연결</b>합니다.
							</dd>
						</dl>
						<p className="connect-vc-content">
							선보엔젤파트너스에서 투자정보 요청이 들어왔습니다.
							<br />
							포트폴리오를 연결할 시 주주명부, IR정보, 피드 정보가 제공됩니다.
						</p>
					</div>
					<button type="button" className="btn__outline" color="primary">
						연결하기
					</button>
				</div>
			</ModalTemplate>

			{/*투자사 연결끊기 모달*/}
			<ModalTemplate
				modalState={disConnectVcModal}
				setModalToggle={setDisConnectVcModal}
				title="투자사 연결끊기"
				width={580}
				className="connect-vc-modal disconnect"
			>
				<div className="modal-content">
					<div className="column">
						<dl className="connect-vc-title">
							<dt>
								<b>선보엔젤파트너스</b>와
							</dt>
							<dd>
								<b>연결해제</b> 하시겠습니까?
							</dd>
						</dl>
						<p className="connect-vc-content">
							투자사 연결을 끊을 시 VC에게 제공되는 스타트업의 주주명부,
							<br />
							IR정보, 피드 정보가 비공개 되어 투자기회를 놓치실 수 있습니다!
						</p>
					</div>
					<button type="button" className="btn__outline" color="primary">
						연결끊기
					</button>
				</div>
			</ModalTemplate>
		</NavbarAndSidebarLayout>
	);
};
export default VcConnectPage;
