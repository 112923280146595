import React, { useEffect, useState } from "react";
import { API_STATE } from "~/common/constants/state";
import { useNavigate } from "react-router-dom";
import { CARD_METADATA } from "~/common/constants/payment";
import useRequest from "~/common/hooks/useRequest";
import DropdownTemplate, {
	DropDownItem,
} from "~/common/components/DropdownPortal/DropdownTemplate";

//assets
import "~/assets/sass/pages/mypage/admin/paymentPage/management.scss";
import { PlusIcon } from "~/assets/svg/MathIcon";
import { DefaultIcon } from "~/assets/svg/PaymentCardIcon";
import AddPaymentModal from "~/pages/mypage/admin/PaymentPage/management/addPaymentModal";
import { useTranslation } from "react-i18next";

const Management = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [cardList, setCardList] = useState([]);
	const [myCard, setMyCard] = useState();

	const paymentCardsRequest = useRequest("get", "admin/purchase/option");
	const changeDefaultCardRequest = useRequest("patch", "/admin/purchase/option");
	const deletePaymentCardRequest = useRequest("delete", "/admin/purchase/option");

	const [addPaymentModal, setAddPaymentModal] = useState(false);

	const getPaymentCards = () => {
		paymentCardsRequest.call();
	};

	useEffect(() => {
		getPaymentCards();
	}, []);

	useEffect(() => {
		switch (paymentCardsRequest.state) {
			case API_STATE.done:
				const response = paymentCardsRequest.response.data;
				setCardList(response);
				setMyCard(response.find((card) => card.default));
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [paymentCardsRequest.state]);

	useEffect(() => {
		switch (changeDefaultCardRequest.state) {
			case API_STATE.done:
				getPaymentCards();
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [changeDefaultCardRequest.state]);

	useEffect(() => {
		switch (deletePaymentCardRequest.state) {
			case API_STATE.done:
				getPaymentCards();
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [deletePaymentCardRequest.state]);

	const cardNumberFormatter = (cardNumber) => {
		let newCardNumber = cardNumber.replace(/(.{4})/g, "$1-");
		if (newCardNumber[newCardNumber.length - 1] === "-")
			newCardNumber = newCardNumber.slice(0, -1);
		return newCardNumber;
	};

	const changeDefaultCard = (cardIdx) => {
		changeDefaultCardRequest.call(cardIdx, "path");
	};

	const deletePaymentCard = (cardIdx) => {
		deletePaymentCardRequest.call(cardIdx, "path");
	};

	return (
		<>
			<div className="c__card my-payment-card">
				<h3>{t("MyPage.admin.Payment.management.my-payment-card")}</h3>
				{myCard && (
					<div className="payment-card-wrap">
						<div className="payment-card">
							<div className="payment-img">
								{CARD_METADATA[myCard.acquirerCode]?.icon ?? <DefaultIcon />}
							</div>
							<div className="payment-info">
								<p className="card-name">{`${
									CARD_METADATA[myCard.acquirerCode]?.name ?? "○○"
								}${t("MyPage.admin.Payment.management.card")}(${
									myCard.cardType
								})`}</p>
								<p className="card-number">
									{myCard.cardNumber && cardNumberFormatter(myCard.cardNumber)}
								</p>
							</div>
						</div>
					</div>
				)}
			</div>

			<div className="c__card all-payment-card">
				<div className="payment-card-header">
					<h3>{t("MyPage.admin.Payment.management.all-payment-card")}</h3>
					<button
						type="button"
						className="plus-btn"
						onClick={() => {
							setAddPaymentModal(true);
						}}
					>
						<PlusIcon />
						{t("MyPage.admin.Payment.management.plus-btn")}
					</button>
				</div>
				<div className="payment-card-wrap">
					{cardList &&
						cardList.map((card, index) => (
							<div className="payment-card" key={index}>
								<div className="payment-img">
									{CARD_METADATA[card.acquirerCode]?.icon ?? <DefaultIcon />}
								</div>
								<div className="payment-info">
									<p className="card-name">{`${
										CARD_METADATA[card.acquirerCode]?.name ?? "○○"
									}카드(${card.cardType})`}</p>
									<p className="card-number">
										{cardNumberFormatter(card.cardNumber)}
									</p>
								</div>
								{card !== myCard && (
									<DropdownTemplate target={index} vertical>
										<DropDownItem
											onClick={() => {
												changeDefaultCard(card.idx);
											}}
										>
											{t("MyPage.admin.Payment.management.changeDefaultCard")}
										</DropDownItem>
										<DropDownItem
											onClick={() => {
												deletePaymentCard(card.idx);
											}}
										>
											{t("MyPage.admin.Payment.management.delete")}
										</DropDownItem>
									</DropdownTemplate>
								)}
							</div>
						))}
				</div>
			</div>

			<AddPaymentModal
				{...{
					addPaymentModal,
					setAddPaymentModal,
					getPaymentCards,
				}}
			/>
		</>
	);
};

export default Management;
