import React, { useEffect, useState } from "react";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import PageHeader from "~/common/components/PageHeader";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import View from "~/pages/vc/VcList/View";
import useRequest from "~/common/hooks/useRequest";
import "~/assets/sass/pages/vc/info.scss";
import Search from "~/pages/vc/VcList/View/search";
import useRepository from "~/common/hooks/useRepository";
import { COMPANY_VC_KEYWORD } from "~/store/vc/repository";
import useInfiniteScroll from "~/common/hooks/useInfiniteScroll";
import { useTranslation } from "react-i18next";

const List = () => {
	const { t } = useTranslation();
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	const [allVcCount, setAllVcCount] = useState(0);
	const [allPortfolioCount, setAllPortfolioCount] = useState(0);

	const vcListRequest = useRequest("post", "company/vc/list");
	const [getCompanyVcKeyword] = useRepository(COMPANY_VC_KEYWORD);
	const [vcList, setVcList] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [page, setPage] = useState(1);

	const [searchName, setSearchName] = useState("");
	const [searchType, setSearchType] = useState("");

	const lastElementRef = useInfiniteScroll(pageState, page, setPage, hasMore);

	useEffect(() => {
		getCompanyVcKeyword();
	}, []);

	useEffect(() => {
		setPageState(PAGE_STATE.LOADING);
		vcListRequest.call(
			{
				size: 21,
				page: page,
				name: searchName,
				type: searchType,
				field: [],
			},
			"body"
		);
	}, [page, searchName, searchType]);

	useEffect(() => {
		switch (vcListRequest.state) {
			case API_STATE.done:
				const responseData = vcListRequest.response.data;

				if (responseData.pageable.totalPage === 0) {
					setPageState(PAGE_STATE.EMPTY);
				} else {
					if (responseData.pageable.pageNumber === 1) {
						setVcList(responseData.foundCompanies);
					} else {
						setVcList([...vcList, ...responseData.foundCompanies]);
					}

					setPageState(PAGE_STATE.VIEW);
				}

				setHasMore(page !== responseData.pageable.totalPage);

				break;
			case API_STATE.error:
				alert(t("Alert.alert2"));
				break;
		}
	}, [vcListRequest.state, vcListRequest.response, vcListRequest.error]);

	const vcCountsRequest = useRequest("get", "company/vc/counts");

	useEffect(() => {
		vcCountsRequest.singleCall();
	}, []);

	useEffect(() => {
		switch (vcCountsRequest.state) {
			case API_STATE.done:
				setAllVcCount(vcCountsRequest.response.data.vcCompanies);
				setAllPortfolioCount(vcCountsRequest.response.data.portFolio);
				break;
			case API_STATE.error:
				console.log("Error: ", vcCountsRequest.error);
				break;
		}
	}, [vcCountsRequest.state, vcCountsRequest.response, vcCountsRequest.error]);

	return (
		<NavbarAndSidebarLayout
			menu={["vc", "vcInfo"]}
			type="main"
			menuName={"vc-info"}
			backTop={true}
		>
			<PageHeader />
			<Search
				{...{
					allVcCount,
					allPortfolioCount,
					setPage,
					setSearchName,
					setSearchType,
				}}
			/>
			<View {...{ lastElementRef, vcList, pageState }} />
		</NavbarAndSidebarLayout>
	);
};

export default List;
