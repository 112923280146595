import React from "react";
import Form from "~/common/components/Form";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Agree = (props) => {
	const { t } = useTranslation();
	const { useForm, form, apply } = props;

	const {
		field,
		fieldState: { invalid },
	} = useController({
		control: useForm.control,
		name: form.order.toString(),
		rules: { required: form.required },
		defaultValue: apply ? form.answer : false,
	});

	return (
		<>
			<Form useForm={useForm}>
				<div className={`f__group agree-type ${form.required ? "required" : ""}`}>
					<label className={"f__label"}>{form.title}</label>
					<div className="f__control-wrap">
						<div className={`f__control ${invalid ? "has-error" : ""}`}>
							<div className="c__textarea">{form.content}</div>
							<label className={`checkbox-custom ${apply ? "disabled" : null}`}>
								<input
									type="checkbox"
									disabled={apply}
									checked={field.value}
									onChange={() => field.onChange(!field.value)}
								/>
								<span className="checkmark" />
								<span className="checkbox-label">
									{form.agreeDescription
										? form.agreeDescription
										: t(
												"Invest.Round.RoundDetailModal.Edit.component.agree.checkbox-label"
										  )}
								</span>
							</label>
						</div>
					</div>
				</div>
			</Form>
		</>
	);
};

export default Agree;
