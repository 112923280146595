import React from "react";
import MarketSection from "./MarketSection";
import ProductSection from "./ProductSection";
import TechSection from "./TechSection";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const View = ({ showEditPage, Header }) => {
	const { t } = useTranslation();
	const buttonList = [
		<AuthButton
			type={"button"}
			className={"btn__solid"}
			color={"primary"}
			onClick={showEditPage}
		>
			{t("button.Edit")}
		</AuthButton>,
	];
	return (
		<>
			<Header buttonList={buttonList} />
			<div className="ir-view">
				<div className="c__card product-view">
					<div className="data-form">
						<section>
							<ProductSection />
						</section>
						<section>
							<TechSection />
						</section>
						<section>
							<MarketSection />
						</section>
					</div>
				</div>
			</div>
		</>
	);
};

export default View;
