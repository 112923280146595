import React, { useEffect } from "react";
import Form from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";

const Long = ({ useForm, form, apply }) => {
	useEffect(() => {
		useForm.setValue(form.order.toString(), form.answer);
	}, []);

	return (
		<>
			<Form useForm={useForm}>
				<Textarea
					label={form.title}
					name={form.order.toString()}
					showCount={false}
					required={form.required}
					readOnly={apply}
				/>
			</Form>
		</>
	);
};

export default Long;
