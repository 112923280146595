import React, { useState } from "react";
import {
	removeNode,
	updateNode,
	addNode,
	removeExpanded,
	getChildren,
} from "~/common/components/TreeView/utils";
import { v4 as uuid } from "uuid";
import { removeFromObject } from "~/utils/object";
import { useTranslation } from "react-i18next";

// API
import useRequest from "~/common/hooks/useRequest";

// Store
import { useGlobalStore } from "~/store";

// Components
import TreeView from "~/common/components/TreeView";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";

//Assets
import "~/assets/sass/pages/hr/modal/organizationManageModal.scss";
import { PlusIcon } from "~/assets/svg/MathIcon";
import { ModifyIcon } from "~/assets/svg/TeamInfoIcon";
import { DeleteIcon } from "~/assets/svg/InterfacesIcon";
import OrgInput from "~/pages/hr/TeamInfoPage/modal/orgInput";
import OrgDelete from "~/pages/hr/TeamInfoPage/modal/orgDelete";

const ManageOrgModal = ({ setModalToggle, setSelectedOrg, getInitialState }) => {
	const { t } = useTranslation();
	const [{ user, organization }] = useGlobalStore();

	const [editorTree, setEditorTree] = useState(organization.treeData);

	const [newNodeList, setNewNodeList] = useState([]);
	const [modifyList, setModifyList] = useState({});
	const [deleteList, setDeleteList] = useState([]);

	const [removeModal, setRemoveModal] = useState(false);
	const [addModal, setAddModal] = useState(false);
	const [modifyModal, setModifyModal] = useState(false);

	const [rowInfo, setRowInfo] = useState({});

	const updateOrg = useRequest("post", "hr/org/edit");
	const createOrg = useRequest("post", "hr/org/add");

	const isNewNode = (id) => !!newNodeList.find((d) => d === id);
	const pushUniqueItem = (list, newItem) => [...new Set([...list, ...newItem])];

	const handleAdd = (title) => {
		const newId = uuid();
		setNewNodeList(pushUniqueItem(newNodeList, [newId]));
		const isInit = !(editorTree.length > 0);
		setEditorTree(addNode(editorTree, { title: title, id: newId }, isInit));
	};
	const handleRename = (title) => {
		const { row, tree, setTree } = rowInfo;
		if (!isNewNode(row.node.id)) {
			setModifyList({ ...modifyList, [row.node.id]: title });
		}
		setTree(updateNode(tree, row.path, { ...row.node, title: title }));
	};
	const handleRemove = () => {
		const { row, tree, setTree } = rowInfo;
		setModifyList(removeFromObject(modifyList, [row.node.id]));
		if (!isNewNode(row.node.id)) {
			setDeleteList(pushUniqueItem(deleteList, getChildren(row.node, "id")));
		}
		setTree(removeNode(tree, row.path));
	};

	const handleSubmit = async () => {
		setSelectedOrg([]);
		const expendedTree = removeExpanded(editorTree);
		if (expendedTree.lenght > 0) {
			await createOrg.asyncCall(expendedTree);
		} else {
			const editList = Object.keys(modifyList).map((id) => ({
				id: id,
				title: modifyList[id],
			}));
			await updateOrg.asyncCall({
				org: expendedTree,
				editList: editList,
				deleteList: deleteList,
			});
		}
		await getInitialState();
		setModalToggle(false);
	};

	const buttonList = (rowInfo) => {
		const handleClickModify = () => {
			setRowInfo(rowInfo);
			setModifyModal(true);
		};
		const handleClickRemove = () => {
			setRowInfo(rowInfo);
			setRemoveModal(true);
		};
		return [
			<button className="btn-modify" key="modify" onClick={handleClickModify}>
				<ModifyIcon />
			</button>,
			<button className="btn-delete" key="remove" onClick={handleClickRemove}>
				<DeleteIcon />
			</button>,
		];
	};

	return (
		<>
			<div className="modal-content">
				<div className="modal-body-top">
					<div className="company">{user.company.name}</div>
					<button className="btn__outline" onClick={() => setAddModal(true)}>
						<PlusIcon />
						{t("HR.TeamInfo.modal.ManageOrgModal.addModal")}
					</button>
				</div>
				<div className="scroll-wrap">
					<div className="org-list">
						<TreeView
							className={"hiddenButtons"}
							tree={editorTree}
							setTree={setEditorTree}
							canMove={true}
							buttonList={buttonList}
						/>
					</div>
				</div>
			</div>
			<div className="modal-footer">
				<button key="submit" className="btn__solid" color="primary" onClick={handleSubmit}>
					{t("button.save")}
				</button>
				<button
					key="cancel"
					className="btn__solid"
					color="gray"
					onClick={() => setModalToggle(false)}
				>
					{t("button.cancel")}
				</button>
			</div>
			<ModalTemplate
				title={t("HR.TeamInfo.modal.ManageOrgModal.addModal")}
				modalState={addModal}
				setModalToggle={setAddModal}
				width={400}
				height={180}
				className="org-add-modal"
			>
				<OrgInput handleSubmit={handleAdd} />
			</ModalTemplate>
			<ModalTemplate
				title={t("HR.TeamInfo.modal.ManageOrgModal.modifyModal")}
				modalState={modifyModal}
				setModalToggle={setModifyModal}
				width={400}
				height={180}
				className="org-add-modal"
			>
				<OrgInput handleSubmit={handleRename}>
					<p className="notice">{t("HR.TeamInfo.modal.ManageOrgModal.notice")}</p>
				</OrgInput>
			</ModalTemplate>
			<ModalTemplate
				title={t("HR.TeamInfo.modal.ManageOrgModal.removeModal")}
				modalState={removeModal}
				setModalToggle={setRemoveModal}
				width={400}
				height={180}
				className="org-delete-modal"
			>
				<OrgDelete row={rowInfo.row} handleRemove={handleRemove} />
			</ModalTemplate>
		</>
	);
};

export default ManageOrgModal;
