import React, { useEffect, useState } from "react";
import { Case, If } from "~/common/components/If";
// import { useGlobalStore } from "~/store";

import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";

import Empty from "./Empty";
import View from "./View";
import Edit from "./Edit";

import "~/assets/sass/pages/company/invest.scss";
// import PageHeader from "~/common/components/PageHeader";
import LoadingPage from "~/pages/LoadingPage";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import PageHeader from "~/common/components/PageHeader";

const InvestPage = () => {
	const [page, setPage] = useState(PAGE_STATE.LOADING);

	const getInvestInfoRequest = useRequest("get", "company/invest/info");

	useEffect(() => {
		getInvestInfoRequest.singleCall();
	}, []);

	useEffect(() => {
		switch (getInvestInfoRequest.state) {
			case API_STATE.done:
				const {
					investInfo: { status, target, value },
				} = getInvestInfoRequest.response.data;
				if (status === null && target === null && value === null) {
					setPage(PAGE_STATE.EMPTY);
				} else {
					setPage(PAGE_STATE.VIEW);
				}
				break;
			default:
				break;
		}
	}, [getInvestInfoRequest.state]);

	const showViewPage = () => {
		getInvestInfoRequest.call();
	};

	const showEditPage = () => {
		setPage(PAGE_STATE.EDIT);
	};

	const Header = ({ buttonList = [] }) => {
		return (
			<PageHeader
				title="투자정보"
				mainTitle="기업"
				subTitle="투자정보"
				buttonList={buttonList}
			/>
		);
	};

	return (
		<>
			<NavbarAndSidebarLayout menu={["company", "invest"]} type="main">
				<If
					condition={page}
					data={getInvestInfoRequest.response?.data}
					{...{ showViewPage, showEditPage }}
				>
					<Case condition={PAGE_STATE.EMPTY}>
						<Empty {...{ Header }} />
					</Case>
					<Case condition={PAGE_STATE.EDIT}>
						<Edit />
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						<View />
					</Case>
					<Case condition={PAGE_STATE.LOADING}>
						<LoadingPage />
					</Case>
				</If>
			</NavbarAndSidebarLayout>
		</>
	);
};

export default InvestPage;
