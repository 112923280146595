import {
	BcCardIcon,
	CitiIcon,
	GwangjuBankIcon,
	HanaIcon,
	HyundaiIcon,
	JejuBankIcon,
	JeonbukBankIcon,
	KakaoBankIcon,
	KBankIcon,
	LotteIcon,
	NhBankIcon,
	PostIcon,
	SaemaulIcon,
	SamsungIcon,
	SavingBankIcon,
	ShinHanIcon,
	SuhyeopIcon,
	TossBankIcon,
	WooriIcon,
} from "~/assets/svg/PaymentCardIcon";
import React from "react";

export const CARD_METADATA = {
	"3K": {
		name: "기업비씨",
		icon: <BcCardIcon />,
	},
	46: {
		name: "광주",
		icon: <GwangjuBankIcon />,
	},
	71: {
		name: "롯데",
		icon: <LotteIcon />,
	},
	30: {
		name: "산업",
	},
	31: {
		name: "비씨",
		icon: <BcCardIcon />,
	},
	51: {
		name: "삼성",
		icon: <SamsungIcon />,
	},
	38: {
		name: "새마을",
		icon: <SaemaulIcon />,
	},
	41: {
		name: "신한",
		icon: <ShinHanIcon />,
	},
	62: {
		name: "신협",
	},
	36: {
		name: "씨티",
		icon: <CitiIcon />,
	},
	33: {
		name: "우리",
		icon: <WooriIcon />,
	},
	37: {
		name: "우체국",
		icon: <PostIcon />,
	},
	39: {
		name: "저축",
		icon: <SavingBankIcon />,
	},
	35: {
		name: "전북",
		icon: <JeonbukBankIcon />,
	},
	42: {
		name: "제주",
		icon: <JejuBankIcon />,
	},
	15: {
		name: "카카오뱅크",
		icon: <KakaoBankIcon />,
	},
	"3A": {
		name: "케이뱅크",
		icon: <KBankIcon />,
	},
	24: {
		name: "토스뱅크",
		icon: <TossBankIcon />,
	},
	21: {
		name: "하나",
		icon: <HanaIcon />,
	},
	61: {
		name: "현대",
		icon: <HyundaiIcon />,
	},
	11: {
		name: "국민",
	},
	91: {
		name: "농협",
		icon: <NhBankIcon />,
	},
	34: {
		name: "수협",
		icon: <SuhyeopIcon />,
	},
	"6D": {
		name: "다이너스",
	},
	"6I": {
		name: "디스커버",
	},
	"4M": {
		name: "마스터",
	},
	"3C": {
		name: "유니온페이",
	},
	"4J": {
		name: "JCB",
	},
	"4V": {
		name: "비자",
	},
};
