import React, { useEffect, useState } from "react";
import { If, Case, OtherWise } from "~/common/components/If";
import { useTranslation } from "react-i18next";

//Assets
import "~/assets/sass/pages/mypage/signature/signatureUpload.scss";
import SignaturePolicy from "~/pages/mypage/admin/SignaturePage/SignatureUploadModal/content/SignaturePolicy";
import File from "./components/File";
import SingleImageUploader from "~/common/components/ImageUploader/SingleImageUploader";
import useAlert from "~/common/hooks/useAlert";

const SignatureUploadModal = ({
	setModalTitle,
	setModalToggle,
	getSignatureList,
	type,
	signatureListRequest,
	signImg,
	setSignImg,
	uploaderConstant,
}) => {
	const { t } = useTranslation();
	const [step, setStep] = useState("init");

	const [showAlert, Alert] = useAlert();
	const [showCancelAlert, CancelAlert] = useAlert();

	useEffect(() => {
		if (step === "init") {
			setModalTitle(t("MyPage.admin.Signature.SignatureUploadModal.index.init-title"));
		} else if (step === "upload") {
			setModalTitle(t("MyPage.admin.Signature.SignatureUploadModal.index.upload-title"));
		}
	}, [step, setModalTitle]);

	const [previewImg, setPreviewImg] = useState("");

	const handleSubmit = () => {
		if(previewImg) {
			// showAlert().then(() => {
			// 	console.log("completed ", previewImg)
			// 	setSignImg(previewImg);
			// 	setModalToggle(false);
			// });
			showAlert()
		} else {
			showCancelAlert();
		}
	}


	return (
		<If condition={step} setStep={setStep} id={"signature"} {...{ setModalToggle }}>
			<Case condition={"upload"}>
				<div className="modal-content">
					<div className={`signature-file`}>
						<SingleImageUploader
							uploaderConstant={uploaderConstant}
							width={200}
							height={110}
							img={previewImg}
							setImg={setPreviewImg}
							name={"certImg"}
						/>
						<p className="notice pre">
							{t("MyPage.admin.Signature.SignatureUploadModal.components.notice")}
						</p>
						<div className="modal-footer">
							<button
								type="button"
								onClick={() => setModalToggle(false)}
								className="btn__solid"
								color="gray"
							>
								{t("button.cancel")}
							</button>
							<button className="btn__solid" color="primary" onClick={handleSubmit}>
								{t("button.registering")}
							</button>
						</div>
						{/*<File {...{ getSignatureList, signatureListRequest, setModalToggle }} />*/}
					</div>
				</div>
				<Alert
					type="success"
					message={"등록이 완료되었습니다."}
					confirm={t("button.check")}
					mask={false}
					handleConfirm={() => {
						setSignImg(previewImg);
						setModalToggle(false);
					}}
				/>
				<CancelAlert
					type="error"
					message={"이미지를 업로드 해주세요."}
					confirm={t("button.check")}
					mask={false}
				/>
			</Case>
			<OtherWise>
				<SignaturePolicy />
			</OtherWise>
		</If>
	);
};
export default SignatureUploadModal;
