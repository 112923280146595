import React from "react";
import { useNavigate } from "react-router-dom";
import { dateToString } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const CommentRow = ({ myComment, myCommunityList, showInfoAlert }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const isPostTypeBoard = myComment.type === "NOTICE";
	const RowClick = () => {
		isPostTypeBoard
			? navigate(`/community/notice/detail/${myComment.board.idx}`)
			: myCommunityList.find((community) => community.idx === myComment.community.idx)
			? navigate(
					`/community/list/board/${myComment.community.idx}/post/${myComment.board.idx}`
			  )
			: showInfoAlert();
	};

	return (
		<div className="tr" onClick={RowClick}>
			<div className="td content">
				{myComment.files.length !== 0 && (
					<div className="img-wrap">
						<img src={myComment.files[0].path} alt={t("Community.img-wrap")} />
					</div>
				)}
				<p className="content-text">{myComment.comment}</p>
			</div>
			<div className="td name">
				{isPostTypeBoard ? (
					<b>{t("contants.type.MyPostType.NOTICE")}</b>
				) : (
					myComment.community.name
				)}
			</div>
			<div className="td write">{myComment.board.title}</div>
			<div className="td date">{dateToString(myComment.createdAt, "yyyy.MM.dd")}</div>
		</div>
	);
};

export default CommentRow;
