import React, { useEffect, useState } from "react";
import { PaginationEdgeIcon, PaginationIcon } from "~/assets/svg/InterfacesIcon";

const Pagination = (props) => {
	const { currentPage, totalPage, setPageNumber } = props;

	const [paginationInfo, setPaginationInfo] = useState({
		pageOffset: 5,
		pageRange: [1],
	});
	const createPageRange = () => {
		const pageRange = [];
		const { pageOffset } = paginationInfo;
		let startPage = 1,
			endPage = 1;

		if (currentPage > 3) {
			startPage = currentPage - 2;
		}
		endPage = startPage + pageOffset - 1;

		if (endPage > totalPage) {
			startPage = startPage - (endPage - totalPage);
			if (startPage < 1) startPage = 1;
			endPage = totalPage;
		}

		for (let i = startPage; i <= endPage; i++) {
			pageRange.push(i);
		}
		setPaginationInfo((prevState) => ({ ...prevState, pageRange }));
	};

	useEffect(() => {
		createPageRange();
	}, [currentPage, totalPage]);

	return (
		<>
			<div className="pagination">
				<button
					type="button"
					className="first-btn"
					onClick={() => currentPage !== 1 && setPageNumber(1)}
					disabled={currentPage <= 1}
				>
					<PaginationEdgeIcon />
				</button>
				<button
					type="button"
					className="prev-btn"
					onClick={() => currentPage !== 1 && setPageNumber((prevState) => prevState - 1)}
					disabled={currentPage <= 1}
				>
					<PaginationIcon />
				</button>
				{paginationInfo.pageRange.map((page, index) => (
					<button
						type="button"
						className={`btn-num ${page === currentPage ? "active" : ""}`}
						key={index}
						onClick={() => setPageNumber(page)}
					>
						{page}
					</button>
				))}
				<button
					type="button"
					className="next-btn"
					onClick={() =>
						currentPage !== totalPage && setPageNumber((prevState) => prevState + 1)
					}
					disabled={currentPage >= totalPage}
				>
					<PaginationIcon />
				</button>
				<button
					type="button"
					className="last-btn"
					onClick={() => currentPage !== totalPage && setPageNumber(totalPage)}
					disabled={currentPage >= totalPage}
				>
					<PaginationEdgeIcon />
				</button>
			</div>
		</>
	);
};

export default Pagination;
