import React, { useEffect } from "react";
import storageService from "~/utils/storage";
import useRepository from "~/common/hooks/useRepository";
import { USER_INFO } from "~/store/user/repository";
import { Helmet } from "react-helmet-async";
import { getTitle } from "~/utils/getTitle";
import { GET_TITLE } from "~/common/constants/form";
import useResize from "~/common/hooks/useResize";
import { useLocation } from "react-router";

const WithAuthRouter = () => {
	const location = useLocation();
	const path = location.pathname.split("/").splice("", 3).join("");

	const size = useResize().windowOuterSize;
	const [getUserInfo] = useRepository(USER_INFO);

	const token = storageService.get("auth", "accessToken", "local");

	const hasToken = token !== undefined;

	useEffect(() => {
		if (hasToken) {
			getUserInfo();
		}
	}, [hasToken, location]);

	return (
		<Helmet>
			{/* <meta name="viewport" content={`width=${size >= 768 ? "device-width" : 767}`} /> */}
			<title>
				{GET_TITLE[path]
					? getTitle(GET_TITLE[path])
					: "Persona | One click away from the global team"}
			</title>
		</Helmet>
	);
};

export default WithAuthRouter;
