import React from "react";
import { useNavigate } from "react-router";
import { ClipIcon } from "~/assets/svg/InterfacesIcon";
import { deleteTagRegex, oneDayPastCheck } from "~/utils/formatter";
import { ALLOW_COMMENT_STATE } from "~/common/constants/state";
import defaultImg from "~/assets/img/defaultImg/default-img.png";
import { useAtomValue } from "jotai";
import { CurrentBoardAtom } from "~/atoms/Community";
import { useTranslation } from "react-i18next";

const AlbumView = ({ boardList }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const currentBoard = useAtomValue(CurrentBoardAtom);
	return (
		<>
			<div className="community-album-table">
				<section className="c__cardRow">
					{boardList &&
						boardList.map((board, index) => (
							<div
								className="c__card"
								key={index}
								onClick={() => navigate(`post/${board.idx}`)}
							>
								<div className="album-container">
									<div className="album-image">
										<img
											src={board.contentFiles[0]?.path}
											alt={t("Community.board-img")}
											onError={(e) => (e.target.src = defaultImg)}
										/>
										<span className="tag">
											{board.contentFiles.length === 1
												? board.contentFiles.length
												: board.contentFiles.length - 1 + " +"}
										</span>
									</div>
									<div className="title-box">
										<div className="text">{board.title}</div>
										<div className="icon">
											{currentBoard?.allowFile &&
												board.uploadFiles.length > 0 && (
													<span className="clip">
														<ClipIcon />
													</span>
												)}
											{board.settings[0].value ===
												ALLOW_COMMENT_STATE.ALLOW &&
												board.commentCount !== 0 && (
													<span className="num">
														[{board.commentCount}]
													</span>
												)}
										</div>
									</div>
									<div className="title-name">
										<span className="name">{board.user.name}</span>
										<span className="status">
											{oneDayPastCheck(board.createdAt)}
										</span>
									</div>
								</div>
							</div>
						))}
				</section>
			</div>
		</>
	);
};

export default AlbumView;
