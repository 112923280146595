import React, { useEffect, useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import { useTranslation } from "react-i18next";

const Draw = ({ setModalToggle, getSignatureList }) => {
	const { t } = useTranslation();
	let canvasRef = useRef(null);
	const uploadRequest = useRequest("post", "file/st/upload");
	const signatureRequest = useRequest("post", "user/signature");

	const [drawSignature, setDrawSignature] = useState();

	const handleSubmit = () => {
		setDrawSignature(dataURLtoFile(canvasRef.current.getDataURL()));
	};

	useEffect(() => {
		if (drawSignature) {
			const formData = new FormData();
			formData.append("path", UPLOAD_TYPE.USER_SIGN.path);
			formData.append("type", UPLOAD_TYPE.USER_SIGN.type);
			formData.append("file", drawSignature);

			uploadRequest.call(formData);
		}
	}, [drawSignature]);

	const uploadSignature = (imgUrl) => {
		signatureRequest.call({
			imgUrl: imgUrl,
			type: "SIGN",
		});
	};

	useEffect(() => {
		switch (uploadRequest.state) {
			case API_STATE.done:
				setDrawSignature(null);
				const responseData = uploadRequest.response.data;
				uploadSignature(responseData);
				break;
			case API_STATE.error:
				alert(t("MyPage.SignaturePage.SignatureUploadModal.ImgAlert"));
				console.log("error", uploadRequest.error);
				break;
			default:
				break;
		}
	}, [uploadRequest.state, uploadRequest.response, uploadRequest.error]);

	useEffect(() => {
		switch (signatureRequest.state) {
			case API_STATE.done:
				getSignatureList();
				setModalToggle(false);
				break;
			case API_STATE.error:
				alert(t("MyPage.SignaturePage.SignatureUploadModal.UploadAlert"));
				console.log("error", signatureRequest.error);
				break;
			default:
				break;
		}
	}, [signatureRequest.state, signatureRequest.response, signatureRequest.error]);

	const dataURLtoFile = (dataurl) => {
		const arr = dataurl.split(",");
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		const name = Date.now() + "." + mime.split("/")[1];

		return new File([u8arr], name, { type: mime });
	};

	return (
		<>
			<CanvasDraw
				ref={canvasRef}
				className={"signature-pad"}
				brushRadius={1}
				lazyRadius={0}
				brushColor={"#000"}
				catenaryColor={"#0D9488"}
				canvasWidth={440}
				canvasHeight={195}
				hideGrid={true}
			/>
			<button onClick={() => canvasRef.current.clear()}>
				<svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="8" viewBox="0 0 8 8">
					<path
						id="prefix__패스_234"
						className="st0"
						d="M7.9,4.3L4.7,7.9C4.6,8,4.3,8,4.2,7.9c0,0,0,0,0,0C4,7.7,4,7.4,4.1,7.2L7,4L4.1,0.8
			        C4,0.6,4,0.3,4.1,0.1C4.3,0,4.5,0,4.6,0.1c0,0,0,0,0,0l2.2,2.4l1,1.1C8,3.9,8,4.1,7.9,4.3z M0.7,7.9C0.6,8,0.3,8,0.2,7.9
			        c0,0,0,0,0,0C0,7.7,0,7.4,0.1,7.2L3,4L0.1,0.8C0,0.6,0,0.3,0.1,0.1C0.3,0,0.5,0,0.6,0.1c0,0,0,0,0,0l1.9,2.2l1.3,1.4
			        C4,3.9,4,4.1,3.9,4.3L0.7,7.9z"
					/>
				</svg>
				{t("MyPage.SignaturePage.SignatureUploadModal.draw")}
			</button>
			<div className="modal-footer">
				<button className="btn__solid" color="primary" onClick={handleSubmit}>
					{t("button.registering")}
				</button>
			</div>
		</>
	);
};
export default Draw;
