import { useGlobalStore } from "~/store";
import DropdownTemplate from "~/common/components/DropdownPortal/DropdownTemplate";
import { dateToString } from "~/utils/formatter";
import { LockIcon, UnLockIcon } from "~/assets/svg/TeamInfoIcon";
import React, { useEffect, useState } from "react";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import { useTranslation } from "react-i18next";

const IrCard = ({
	idx,
	title,
	date,
	lock,
	color,
	status,
	onDelete,
	onPwSetup,
	onPwChange,
	onPwReset,
	onClick,
	onSelect,
	irListRequest,
}) => {
	const { t } = useTranslation();
	const [
		{
			sidebar: { currentPath },
		},
	] = useGlobalStore();

	const [colorModal, setColorModal] = useState(false);
	const [selectColor, setSelectColor] = useState(color);

	const bgColorRequest = useRequest("post", "ir/color");
	const CopyIrRequest = useRequest("post", "ir/duplicate");

	const colorArray = [
		"#00BFF3",
		"#F464AC",
		"#6A3FFF",
		"#EF3567",
		"#FEA83E",
		"#819800",
		"#3751E2",
		"#101D6D",
		"#C13D7F",
		"#AC0F39",
		"#CF780E",
		"#536200",
		"#62C6BE",
		"#01C78A",
		"#9A9A9A",
		"#505050",
		"#A86042",
		"#2A3100",
	];
	const showColorModal = (state) => {
		setColorModal(state);
		setSelectColor(color);
	};

	const handleIrCopy = () => {
		CopyIrRequest.call(idx, "path");
	};

	useEffect(() => {
		switch (CopyIrRequest.state) {
			case API_STATE.done:
				irListRequest.call();
				break;
			case API_STATE.error:
				console.error(CopyIrRequest.error);
				break;
		}
	}, [CopyIrRequest.state]);

	const colorList = () => {
		return colorArray.map((color, index) => (
			<div className="tdCell" key={index}>
				<div
					className={`colorCell${index + 1} ${selectColor === color ? " active" : ""}`}
					onClick={() => {
						setSelectColor(color);
					}}
				></div>
			</div>
		));
	};

	return (
		<>
			<div className="c__card ir-list-card" onClick={onSelect}>
				{currentPath.auth !== "view" && (
					<DropdownTemplate target={`dropdown_${idx}`} vertical>
						{lock ? (
							<>
								<li className="c__dropdownItem" onClick={onPwChange}>
									{t("Shareholder.ShareholderList.View.Index.pwChange")}
								</li>
								<li className="c__dropdownItem" onClick={onPwReset}>
									{t("Shareholder.ShareholderList.View.Index.pwClear")}
								</li>
							</>
						) : (
							<li className="c__dropdownItem" onClick={onPwSetup}>
								{t("Shareholder.ShareholderList.View.Index.pwSet")}
							</li>
						)}

						<li className="c__dropdownItem" onClick={handleIrCopy}>
							{t("IR.IrList.View.IrCard.Index.IRCopy")}
						</li>
						<li className="c__dropdownItem" onClick={onDelete}>
							{t("IR.IrList.View.IrCard.Index.IRDelete")}
						</li>
						<li
							className="c__dropdownItem"
							onClick={() => {
								setSelectColor(color);
								setColorModal(true);
							}}
						>
							{t("IR.IrList.View.IrCard.Index.changeColor")}
						</li>
					</DropdownTemplate>
				)}
				<div className="ir-card-wrap" onClick={onClick}>
					<div
						className="card-head"
						style={{ backgroundColor: selectColor ? selectColor : color }}
					>
						<div>
							<h3>{title}</h3>
						</div>
					</div>
					<div className="card-container">
						<div className="progress-group">
							<div className="progress-number">{status}%</div>
							<div className="progress">
								<div className="progress-bar" style={{ width: `${status}%` }} />
							</div>
						</div>
						<div className="card-status">
							<dl>
								<div>
									<dt>Updated:</dt>
									<dd>{dateToString(date, "yyyy-MM-dd")}</dd>
								</div>
							</dl>
							{lock ? (
								<span className="lock-status locked">
									<LockIcon />
								</span>
							) : (
								<span className="lock-status unlocked">
									<UnLockIcon />
								</span>
							)}
						</div>
					</div>
				</div>
			</div>
			{/* 색상변경 팝업 */}
			<ModalTemplate
				modalState={colorModal}
				setModalToggle={showColorModal}
				title={t("IR.IrList.View.IrCard.Index.modalTitle")}
				width={260}
				height={250}
				className="bgColorChangeModal"
			>
				<div className="modal-content">{colorList()}</div>
				<div className="modal-footer">
					<button
						type="button"
						color="primary"
						className="btn__solid"
						onClick={() => {
							bgColorRequest
								.asyncCall({ irIdx: idx, color: selectColor }, "body")
								.then(async () => {
									setColorModal(false);
									irListRequest.call();
								});
						}}
					>
						{t("button.check")}
					</button>
				</div>
			</ModalTemplate>
		</>
	);
};

export default IrCard;
