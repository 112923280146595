import storageService from "./storage";

export default class persistHelper {
	/**
	 * @template T
	 * @param {string} groupName - group name
	 * @param {T} initialState - initial states
	 * @param {"local"|"session"} type - storage type
	 * @return {T}
	 * @example persistHelper.getInitialStates("user", {logged: false}, "local");
	 */
	static initState = (groupName, initialState, type = "session") => {
		var object = {};
		Object.keys(initialState).forEach((key) => {
			const value = storageService.get(groupName, key, type);
			if (value) {
				object[key] = value;
			} else {
				object[key] = initialState[key];
			}
		});
		return object;
	};

	/**
	 * @template T
	 * @param {string} groupName - group name
	 * @param {T} newState - new states
	 * @param {"local"|"session"} type - storage type
	 * @return {T}
	 * @example persistHelper.setPersist("company", {}, "local")
	 */
	static setPersist = (groupName, newState, type = "session") => {
		storageService.set(groupName, newState, type);
		return newState;
	};
}
