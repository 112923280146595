import React, { useEffect, useState } from "react";
import { BUSINESS_PAGE_STATE } from "~/pages/mypage/admin/BusinessPage";
import SingleImageUploader from "~/common/components/ImageUploader/SingleImageUploader";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import AlertTemplate from "~/common/components/AlertPortal/AlertTemplate";
import { BIZ_TYPE } from "~/common/constants/form";

//Assets
import "~/assets/sass/pages/mypage/admin/business/uncertified.scss";
import { useTranslation } from "react-i18next";

const Uncertified = ({ user, setPage, changeBizForm, getBizImgRequest, getUserInfo }) => {
	const { t } = useTranslation();
	const [uploadedImg, setUploadedImg] = useState("");
	const [uploaderConstant] = useState(UPLOAD_TYPE.COMPANY_BUSINESS);
	const [warningModal, setWarningModal] = useState(false);
	const changeBizNumType = useRequest("post", "admin/business/type");
	const businessRequest = useRequest("post", "admin/business");

	const handleSubmit = () => {
		if (uploadedImg !== "") {
			// 예비창업자일 때만 사업자구분번호 변경 Request
			if (user.company.certType === BIZ_TYPE.PROSPECTIVE || user.company.certType === null) {
				const formData = {
					type: changeBizForm.watch("type"),
					regNum: changeBizForm.watch("regNum"),
				};
				changeBizNumType.call(formData);
			}
			//사업자등록증 제출 Request
			businessRequest.call({ imgUrl: uploadedImg });
		} else {
			setWarningModal(true);
		}
	};

	//사업자구분번호 변경 Response
	useEffect(() => {
		switch (changeBizNumType.state) {
			case API_STATE.done:
				break;
			case API_STATE.error:
				console.log("error", API_STATE.error);
				alert(t("Alert.alert1"));
				break;
			default:
				break;
		}
	}, [changeBizNumType.state, changeBizNumType.done, changeBizNumType.error]);

	const confirmRequest = useRequest("post", `admin/business/confirm`);

	useEffect(() => {
		switch (confirmRequest.state) {
			case API_STATE.done:
				getUserInfo().then(() => {
					setPage(BUSINESS_PAGE_STATE.CERTIFIED);
					getBizImgRequest.call();
				});
				break;
			case API_STATE.error:
				console.log("error", confirmRequest.error);
				alert(t("Alert.alert1"));
				break;
			default:
				break;
		}
	}, [confirmRequest.state, confirmRequest.response, confirmRequest.error]);

	useEffect(() => {
		switch (businessRequest.state) {
			case API_STATE.done:
				confirmRequest.call(user.company.idx, "path");
				// getUserInfo().then(() => {
				// setPage(BUSINESS_PAGE_STATE.PROCESSING);
				// getBizImgRequest.call();
				// });
				break;
			case API_STATE.error:
				switch (businessRequest.error.statusCode) {
					case 409:
						alert(t("MyPage.admin.Business.Uncertified.alert"));
						break;
					case 500:
						alert(t("Alert.alert1"));
						console.log("error", businessRequest.error);
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	}, [businessRequest.state, businessRequest.response, businessRequest.error]);

	return (
		<>
			<div className="c__card uncertified">
				<div className="card-top">
					<dl className="content">
						<dt>{t("MyPage.admin.Business.Uncertified.content.dt")}</dt>
						<dd className="pre">{t("MyPage.admin.Business.Uncertified.content.dd")}</dd>
					</dl>
					<SingleImageUploader
						uploaderConstant={uploaderConstant}
						width={168}
						height={200}
						img={uploadedImg}
						setImg={setUploadedImg}
					/>
				</div>
				<div className="card-bottom">
					{user.company.certType === null ||
					user.company.certType === BIZ_TYPE.PROSPECTIVE ? (
						<button
							className="btn__solid"
							color="gray"
							onClick={() => setPage(BUSINESS_PAGE_STATE.BIZFORM)}
						>
							{t("button.prev")}
						</button>
					) : null}
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={handleSubmit}
					>
						{t("button.submit")}
					</button>
				</div>
			</div>
			<AlertTemplate
				alertModalState={warningModal}
				setAlertModalToggle={setWarningModal}
				alertType={"warning"}
			>
				<p className="alert-content">
					{t("MyPage.admin.Business.Uncertified.alert-content")}
				</p>
				<div className="alert-footer">
					<button
						className="btn__solid"
						color="primary"
						onClick={() => setWarningModal(false)}
					>
						{t("button.check")}
					</button>
				</div>
			</AlertTemplate>
		</>
	);
};
export default Uncertified;
