import React, { useMemo } from "react";
import { useGlobalStore } from "~/store";
import { thousandFormatter } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const DebtPosition = ({}) => {
	const { t } = useTranslation();
	const [
		{
			ir: {
				finance: {
					currentLiability,
					nonCurrentLiability,
					capital,
					surplus,
					capitalAdjustment,
					otherComprehensiveIncome,
					retainedEarnings,
				},
			},
		},
	] = useGlobalStore();

	const TotalDebt = useMemo(
		() => +currentLiability + +nonCurrentLiability,
		[currentLiability, nonCurrentLiability]
	);
	const TotalEquity = useMemo(
		() =>
			+capital +
			+surplus +
			+capitalAdjustment +
			+otherComprehensiveIncome +
			+retainedEarnings,
		[capital, surplus, capitalAdjustment, otherComprehensiveIncome, retainedEarnings]
	);
	const DebtAndEquity = useMemo(() => TotalDebt + TotalEquity, [TotalDebt, TotalEquity]);

	return (
		<div className="f__group sub-section-table">
			<div className="unit-wrap sub-title">
				{t("IR.IrDetail.Finance.Edit.Positions.subTitle2")}
				<span className="unit">
					{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
					{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
				</span>
			</div>
			<div className="f__row">
				<div className="f__column">
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section3.title")}
						</div>
						<div className="f__control">{thousandFormatter(TotalDebt)}</div>
					</div>
				</div>
				<div className="f__field">
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section3.label1")}
						</div>
						<div className="f__control">{thousandFormatter(currentLiability)}</div>
					</div>
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section3.label2")}
						</div>
						<div className="f__control">{thousandFormatter(nonCurrentLiability)}</div>
					</div>
				</div>
			</div>
			<div className="f__row">
				<div className="f__column">
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section4.title")}
						</div>
						{thousandFormatter(TotalEquity)}
					</div>
				</div>
				<div className="f__field">
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section4.label1")}
						</div>
						<div className="f__control">{thousandFormatter(capital)}</div>
					</div>
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section4.label2")}
						</div>
						<div className="f__control">{thousandFormatter(surplus)}</div>
					</div>
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section4.label3")}
						</div>
						<div className="f__control">{thousandFormatter(capitalAdjustment)}</div>
					</div>
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section4.label4")}
						</div>
						<div className="f__control">
							{thousandFormatter(otherComprehensiveIncome)}
						</div>
					</div>
					<div className="f__group">
						<div className="f__label">
							{t("IR.IrDetail.Finance.Edit.Positions.section4.label5")}
						</div>
						<div className="f__control">{thousandFormatter(retainedEarnings)}</div>
					</div>
				</div>
			</div>
			<div className="total">
				<div className="total-label">{t("IR.IrDetail.Finance.Edit.Positions.total2")}</div>
				<div className="f__control">{thousandFormatter(DebtAndEquity)}</div>
			</div>
			<dl className="alert-content">
				<div>
					<dt>{t("IR.IrDetail.Finance.Edit.Positions.section3.title")}</dt>
					<dd>
						{t("IR.IrDetail.Finance.Edit.Positions.section3.label1")} +{" "}
						{t("IR.IrDetail.Finance.Edit.Positions.section3.label2")}
					</dd>
				</div>
				<div>
					<dt>{t("IR.IrDetail.Finance.Edit.Positions.section4.title")}</dt>
					<dd>
						{t("IR.IrDetail.Finance.Edit.Positions.section4.label1")} +{" "}
						{t("IR.IrDetail.Finance.Edit.Positions.section4.label2")} +{" "}
						{t("IR.IrDetail.Finance.Edit.Positions.section4.label3")} +{" "}
						{t("IR.IrDetail.Finance.Edit.Positions.section4.label4")} +{" "}
						{t("IR.IrDetail.Finance.Edit.Positions.section4.label5")}
					</dd>
				</div>
				<div>
					<dt>{t("IR.IrDetail.Finance.Edit.Positions.total2")}</dt>
					<dd>
						{t("IR.IrDetail.Finance.Edit.Positions.section3.title")} +{" "}
						{t("IR.IrDetail.Finance.Edit.Positions.section4.title")}
					</dd>
				</div>
			</dl>
		</div>
	);
};

export default DebtPosition;
