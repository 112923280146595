import React from "react";

//Assets
import "~/assets/sass/pages/inspection/inspection.scss";
import inspectionImg from "~/assets/img/Inspection-img.png";

const Inspection = () => {
	return (
		<section className="inspection-section">
			<div className="inspection-box">
				<div className="inspection-left">
					<h3 className="inspection-title">웹사이트 서버점검</h3>
					<p className="inspection-text">
						웹사이트 서버점검으로 인한 홈페이지 <br /> 이용에 불편을 드려 죄송합니다.
					</p>
					<div className="inspection-date">
						일자 : 2024년 2월 14일 <br />
						시간 : 오후 2시~2시30분 까지
					</div>
					<div className="inspection-comment">
						<p className="comment-ko">
							더 빠르고, 사용하기 쉬운 사이트로 리뉴얼 중입니다. <br /> 빠른 시일 내
							작업을 완료하고, 새로운 모습으로 찾아 뵙겠습니다.
						</p>
						<p className="comment-en">
							We’re busy updating our website for you and will be back soon
						</p>
					</div>
				</div>
				<div className="inspection-right">
					<img src={inspectionImg} alt="서버점검" />
				</div>
			</div>
		</section>
	);
};

export default Inspection;
