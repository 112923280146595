export const API_STATE = {
	ready: "READY",
	loading: "LOADING",
	done: "DONE",
	error: "ERROR",
};

export const PAGE_STATE = {
	LOADING: "LOADING",
	EMPTY: "EMPTY",
	VIEW: "VIEW",
	EDIT: "EDIT",
};

export const INVEST_STATE = {
	WAITING: "승인대기",
	CONFIRM: "승인",
	REJECT: "반려",
	COMPLETE: "완료",
};

export const ROUND_STATE = {
	WAITING: "오픈대기",
	PROCEEDING: "진행중",
	CLOSING: "지원마감",
	REVIEW: "심사중",
	COMPLETE: "종료",
};

export const ALLOW_COMMENT_STATE = {
	ALLOW: "ALLOW_COMMENT_ALLOW",
	NOT_ALLOW: "ALLOW_COMMENT_NOT_ALLOW",
};

export const ALLOW_FILE_UPLOAD = {
	ALLOW: "ALLOW_FILE_UPLOAD_ALLOW",
	NOT_ALLOW: "ALLOW_FILE_UPLOAD_NOT_ALLOW",
};
