import React from "react";
import { BUSINESS_PAGE_STATE } from "~/pages/mypage/admin/BusinessPage";

//Assets
import "~/assets/sass/pages/mypage/admin/business/prospective.scss";
import { useTranslation } from "react-i18next";

const Prospective = ({ setPage }) => {
	const { t } = useTranslation();
	return (
		<div className="c__card prospective">
			<div className="card-top">
				<dl className="content">
					<dt>{t("MyPage.admin.Business.Prospective.content.dt")}</dt>
					<dd className="pre">{t("MyPage.admin.Business.Prospective.content.dd")}</dd>
				</dl>
			</div>
			<div className="card-bottom">
				<button
					type="button"
					className="btn__solid"
					color="primary"
					onClick={() => setPage(BUSINESS_PAGE_STATE.BIZFORM)}
				>
					{t("MyPage.admin.Business.Prospective.btn-certified")}
				</button>
			</div>
		</div>
	);
};
export default Prospective;
