import React, { useEffect } from "react";
import useRequest from "~/common/hooks/useRequest";
import { useForm } from "react-hook-form";
import MineEdit from "~/pages/hr/TeamInfoPage/modal/MemberDetailModal/Edit/MineEdit";
import useAlert from "~/common/hooks/useAlert";
import { API_STATE } from "~/common/constants/state";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Edit = ({ setModalToggle, profileInfo, userDetailRequest }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const editForm = useForm({
		defaultValues: {
			UserProfile: profileInfo.user,
			UserCareer: profileInfo.career.map((d) => ({
				...d,
				content: d.content.replace(/<br\s*\/?>/gm, "\n"),
			})),
		},
	});

	const [showCareerAlert, CareerAlert] = useAlert();

	const userDetailMutation = useRequest("post", "hr/team/info/edit/user");

	const submit = (data) => {
		const propertyProvider = (object, propList) => {
			return propList.reduce((acc, cur) => {
				acc[cur] = object[cur];
				return acc;
			}, {});
		};

		const commonProfileData = [
			"name",
			"phone",
			"introduction",
			"zipCode",
			"address",
			"addressDetail",
			"facebook",
			"twitter",
			"instagram",
			"linkedin",
			"youtube",
			"regNum",
			"bank",
			"account",
			"workStartDate",
			"workEndDate",
		];

		const requestData = {
			idx: profileInfo.user.idx,
			img: data.UserProfile.img,
			UserProfile: propertyProvider(data.UserProfile, commonProfileData),
			UserCareer: data.UserCareer.map((d) => ({
				...d,
				content: d.content.replace(/(?:\r\n|\r|\n)/g, "<br />"),
			})),
			UserMemo: undefined,
		};

		const isCareerValid =
			requestData.UserCareer.filter(
				({ content, companyName }) => content.length < 1 && companyName.length < 1
			).length === 0;
		if (!isCareerValid) {
			showCareerAlert();
		} else {
			userDetailMutation.call(requestData);
		}
	};

	useEffect(() => {
		switch (userDetailMutation.state) {
			case API_STATE.done:
				userDetailRequest.asyncCall().then(() => {
					setModalToggle(false);
				});

				break;
			case API_STATE.error:
				console.error(userDetailMutation.error);
				navigate("/404");
				break;
			default:
				break;
		}
	}, [userDetailMutation.state]);

	return (
		<>
			<MineEdit useForm={editForm} userDetail={profileInfo} isHrTeamMember={false} />
			<div className="modal-footer">
				<button
					type="button"
					key="cancel"
					className="btn__solid"
					color="gray"
					onClick={() => setModalToggle(false)}
				>
					{t("button.cancel")}
				</button>
				<button
					type="button"
					key="edit"
					className="btn__solid"
					color="primary"
					onClick={editForm.handleSubmit(submit)}
				>
					{t("button.confirm")}
				</button>
			</div>
			<CareerAlert
				type="warning"
				message={t("HR.TeamInfo.modal.MemberDetailModal.EditView.CareerAlert")}
			/>
		</>
	);
};

export default Edit;
