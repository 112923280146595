import React from "react";
import { If, Case, OtherWise } from "~/common/components/If";
import { dateToString, thousandFormatter } from "~/utils/formatter";
import { differenceInDays } from "date-fns";
import { numToKorean } from "num-to-korean";
import { useTranslation } from "react-i18next";

//Assets
import "~/assets/sass/pages/hr/modal/common/detail/hr.scss";
// import { RoundChkIcon } from "~/assets/svg/TeamInfoIcon";

const HrView = ({ userDetail: { user }, hide }) => {
	const { t } = useTranslation();
	const diffrenceFromJoin = differenceInDays(new Date(), new Date(user.joinDate));
	return (
		<section className="teamInfo-hr detail">
			<p className="section-title">
				{t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.section-title")}
			</p>
			<dl>
				<div className="row">
					<dt>{t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.SelectLabel1")}</dt>
					<dd>
						<If condition={user.department}>
							<Case condition={null}>
								<p className="noData">
									{t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.noData1")}
								</p>
							</Case>
							<OtherWise>{user.department}</OtherWise>
						</If>
					</dd>
				</div>
				<div className="row">
					<dt>{t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.InputLabel")}</dt>
					<dd>
						<If condition={user.position}>
							<Case condition={null}>
								<p className="noData">
									{t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.noData2")}
								</p>
							</Case>
							<OtherWise>{user.position}</OtherWise>
						</If>
					</dd>
				</div>
				{hide !== true && (
					<>
						<div className="row">
							<dt>
								{t(
									"HR.TeamInfo.modal.MemberDetailModal.components.Hr.DatePickerLabel1"
								)}
							</dt>
							<dd>
								<If condition={user.joinDate}>
									<Case condition={null}>
										<p className="noData">
											{t(
												"HR.TeamInfo.modal.MemberDetailModal.components.Hr.noData3"
											)}
										</p>
									</Case>
									<OtherWise>
										<>
											{dateToString(user.joinDate, "yyyy.MM.dd ")}
											<span className="note">
												{diffrenceFromJoin}
												{t(
													"HR.TeamInfo.modal.MemberDetailModal.components.Hr.note"
												)}
											</span>
										</>
									</OtherWise>
								</If>
							</dd>
						</div>
						<div className="row">
							<dt>{t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.period")}</dt>
							<dd>
								{user.workPeriod ? (
									user.workPeriod
								) : (
									<p className="noData">
										{t(
											"HR.TeamInfo.modal.MemberDetailModal.components.Hr.noData4"
										)}
									</p>
								)}
							</dd>
						</div>
						<div className="row">
							<dt>
								{t(
									"HR.TeamInfo.modal.MemberDetailModal.components.Hr.SelectLabel2"
								)}
							</dt>
							<dd className="working-type">
								{user.workingTypeTitle ? (
									user.workingTypeTitle
								) : (
									<p className="noData">
										{t(
											"HR.TeamInfo.modal.MemberDetailModal.components.Hr.noData5"
										)}
									</p>
								)}
								{/*TODO: ?
								<span className="note">
									출근시간대 09:00 ~ 10:00, 주 40시간 근무, 쉬는날
								</span>
							*/}
							</dd>
						</div>

						<div className="row">
							<dt>
								{t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.present")}
							</dt>
							<dd className="annual-income">
								<If condition={user.joinDate}>
									<Case condition={null}>
										<p className="noData">
											{t(
												"HR.TeamInfo.modal.MemberDetailModal.components.Hr.noData6"
											)}
										</p>
									</Case>
									<OtherWise>
										<>
											{thousandFormatter(user.salary)}
											{t(
												"HR.TeamInfo.modal.MemberDetailModal.components.Hr.unit"
											)}{" "}
											({numToKorean(user.salary)}
											{t(
												"HR.TeamInfo.modal.MemberDetailModal.components.Hr.unit"
											)}
											)
										</>
									</OtherWise>
								</If>
								{/* TODO: user.계약시작일, 종료일 
									<span className="note">2021.01.15 ~ 2022.01.15</span>
									<div className="contract completed">
										<span className="status">
											<RoundChkIcon />
											계약서 완료
										</span>
										<button type="button" className="btn__outline btn-contract">
											계약서 보기
										</button>
									</div>
								*/}
							</dd>
						</div>
					</>
				)}

				<div className="row working-status">
					<dt>{t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.status")}</dt>
					<If condition={user.employmentStatus}>
						<Case condition={0}>
							<dd className="working">
								{t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.working")}
							</dd>
						</Case>
						<Case condition={2}>
							<dd className="absence">
								{t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.absence")}
							</dd>
						</Case>
						<Case condition={3}>
							<dd className="quit">
								{t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.quit")}{" "}
								<span className="note">
									(
									{t(
										"HR.TeamInfo.modal.MemberDetailModal.components.Hr.quitDate2"
									)}{" "}
									: {user.resignDate})
								</span>
							</dd>
						</Case>
						<OtherWise>
							<dd>
								<p className="noData">
									{t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.noData7")}
								</p>
							</dd>
						</OtherWise>
					</If>
				</div>
			</dl>
		</section>
	);
};

export default HrView;
