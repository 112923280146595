import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";

import { PAGE_STATE } from "~/common/constants/state";
import { Case, If } from "~/common/components/If";

import Pagination from "~/common/components/Pagination";
import TabMenu from "~/common/components/TabMenu";
import { VACATION_STATUS, VACATION_STATUS_TEXT } from "~/common/constants/form";
import { getBetweenYears } from "~/utils/formatter";
import CustomSelect from "~/common/components/CustomSelect";
import { useTranslation } from "react-i18next";

import List from "~/pages/vacation/VacationListPage/Used/List";
import Modal from "~/pages/vacation/VacationListPage/Used/Modal/Modal";
import Empty from "~/pages/vacation/VacationListPage/Empty";


const Used = () => {
	const {
		vacationTabList,
		info,
		vacationList,
		pageNumber,
		pagingObject,
		previousState,
		year,
		setPageNumber,
		setYear,
		vacationStatusRequest,
		previousVacationsRequest,
		handleTab,
		titleText,
		timeText,
	} = useOutletContext();
	const { t } = useTranslation();
	const [vacationManageModal, setVacationManageModal] = useState(false);
	const [selectedVacation, setSelectedVacation] = useState({});

	useEffect(() => {
		if (!info.idx) return;
		previousVacationsRequest.call(
			{ page: pageNumber, size: 10, userIdx: info.idx, year },
			"queryString"
		);
	}, [pageNumber, year, info.idx]);

	return (
		<>
			<TabMenu tabItem={vacationTabList} currentTab={"used"} setCurrentTab={handleTab} />
			<div className="c__card">
				<div className="vacation-list-wrap">
					<div className="vacation-chk-header">
						<h3 className="title">{t("HR.Manage.vacationList.lastVacation")}</h3>
						{previousState === PAGE_STATE.VIEW && (
							<span className="total-tag used">
								{`${t("HR.Manage.vacationList.Used.total-tag")} ${
									vacationList.previousVacations.totalTime
								}`}
							</span>
						)}
						<CustomSelect
							value={year}
							setValue={(value) => {
								setYear(value);
								setPageNumber(1);
							}}
							options={getBetweenYears(info.joinDate)}
						/>
					</div>
					<If condition={previousState}>
						<Case condition={PAGE_STATE.VIEW}>
							<div className="vacation-chk-list">
								<List
									{...{
										vacationList,
										setSelectedVacation,
										setVacationManageModal,
										titleText,
										timeText,
									}}
								/>
							</div>
							<Pagination
								currentPage={pageNumber}
								totalPage={pagingObject.previousVacations.totalPage}
								setPageNumber={setPageNumber}
							/>
						</Case>
						<Case condition={PAGE_STATE.EMPTY}>
							<Empty currentTab={"used"} info={info} />
						</Case>
					</If>
				</div>
			</div>
			{Object.keys(selectedVacation).length > 0 && (
				<ModalTemplate
					modalState={vacationManageModal}
					setModalToggle={setVacationManageModal}
					title={
						<>
							{titleText(selectedVacation)}
							{selectedVacation.HrVacation.HrVacationType.autoConfirm ? (
								<span className="permission auto-approval">
									{t("HR.Manage.vacationList.auto-approval")}
								</span>
							) : (
								<span
									className={`permission ${
										VACATION_STATUS[selectedVacation.status]
									}`}
								>
									{VACATION_STATUS_TEXT[VACATION_STATUS[selectedVacation.status]]}
								</span>
							)}
						</>
					}
					width={460}
					className="vacation-manage-modal"
				>
					<Modal
						{...{
							selectedVacation,
							setVacationManageModal,
							timeText,
							previousVacationsRequest,
							vacationStatusRequest,
							info,
							pageNumber,
							year,
						}}
					/>
				</ModalTemplate>
			)}
		</>
	);
};
export default Used;
