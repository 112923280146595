export const makeRandomPassword = () => {
    // 숫자 4자리 생성
    const numbers = '0123456789';
    let numberPart = '';
    for (let i = 0; i < 4; i++) {
        numberPart += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }

    // 영어 4자리 생성 (대소문자 포함)
    const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let letterPart = '';
    for (let i = 0; i < 4; i++) {
        letterPart += letters.charAt(Math.floor(Math.random() * letters.length));
    }

    // 숫자 4자리와 영어 4자리 결합
    return numberPart + letterPart;
}
