import React, { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { useController } from "react-hook-form";
import { ko } from "date-fns/esm/locale";

const DATE_PICKER_TYPE = {
	DATE: "date",
	MONTH: "month",
	QUARTER: "quarter",
	YEAR: "year",
	TIME: "time",
};

const DATE_FORMAT = {
	[DATE_PICKER_TYPE.DATE]: "yyyy. MM. dd.",
	[DATE_PICKER_TYPE.MONTH]: "yyyy. MM",
	[DATE_PICKER_TYPE.QUARTER]: "yyyy년 q분기",
	[DATE_PICKER_TYPE.YEAR]: "yyyy",
	[DATE_PICKER_TYPE.TIME]: "HH:mm",
};

/**
 * @param { Props } props
 *
 * @typedef Props
 * @property {object} control - useForm control
 * @property {string} name - field name
 * @property {object} validation - validating object
 * @property {Date} defaultValue -
 * @property {string} datePickerName -
 * @property {boolean} month - show month picker || default date picker
 * @property {boolean} year - show year picker || default date picker
 * @property {boolean} quarter - show quarter picker || default date picker
 * @property {boolean} time - show time picker || default date picker
 * @property {boolean} disabled
 * @property {string} placeholderText
 * @property {boolean} readOnly
 * @property {Date} includeTimes - 특정시간만 표시
 * @property {Date} minDate - 최소값 선택, 해당 날짜 이전은 표시 X / maxDate 필수
 * @property {Date} maxDate - 최대값 선택, 해당 날짜 이후는 표시 X / minDate 필수
 * @property {Date} minTime - 최소값 선택, 해당 시간 이전은 표시 X / maxTime 필수
 * @property {Date} maxTime - 최대값 선택, 해당 시간 이후는 표시 X / minTime 필수
 * @property {function} timeClassName - 함수로 특정 시간의 className 변경 가능
 * @property {function} filterDate - 함수로 특정 날짜만 필터 가능
 * @property {string} calendarClassName - "react-datepicker" 와 같은 레벨에 className 표시
 * @property {array} excludeTimes - 배열로 특정 시간 제거
 * @property {array} injectTimes - 배열로 특정 시간 표시
 * @property {boolean} showTimeInput - time input 표시 여부
 * @property {boolean} required - required validation
 */
const DatePicker = (props) => {
	const {
		control,
		name,
		validation,
		defaultValue,
		datePickerName,
		month,
		year,
		quarter,
		time,
		onChange = () => {},
		disabled,
		placeholderText,
		readOnly,
		includeTimes,
		minDate,
		maxDate,
		minTime,
		maxTime,
		timeClassName,
		filterDate,
		calendarClassName,
		excludeTimes,
		injectTimes,
		showTimeInput,
		required,
	} = props;

	const type = year
		? DATE_PICKER_TYPE.YEAR
		: quarter
		? DATE_PICKER_TYPE.QUARTER
		: month
		? DATE_PICKER_TYPE.MONTH
		: time
		? DATE_PICKER_TYPE.TIME
		: DATE_PICKER_TYPE.DATE;

	const { field } = useController({
		control,
		name,
		rules: {
			required: required && "필수 입력 사항 입니다.",
			...validation,
		},
		defaultValue: defaultValue ? defaultValue : new Date(),
	});

	useEffect(() => {
		if (disabled) {
			field.onChange(null);
		} else {
			if (field.value !== null) {
				field.onChange(field.value);
			} else {
				field.onChange(new Date());
			}
		}
	}, [disabled]);

	useEffect(() => {
		if (placeholderText && field.value === null) {
			field.onChange(null);
		}
	}, [field.value, disabled]);

	return (
		<ReactDatePicker
			onChange={(date) => {
				field.onChange(date);
				onChange(date);
			}}
			selected={field.value !== null && new Date(field.value)}
			dateFormat={DATE_FORMAT[type]}
			className={"c__input c__datePicker " + (datePickerName ? datePickerName : "")}
			showYearDropdown
			scrollableYearDropdown
			showQuarterYearPicker={type === "quarter"}
			showMonthYearPicker={type === "month"}
			showFullMonthYearPicker={type === "date"}
			showTimeSelect={type === "time"}
			showTimeSelectOnly={type === "time"}
			timeIntervals={type === "time" && 30}
			useShortMonthInDropdown
			dateFormatCalendar=" "
			showMonthDropdown
			dropdownMode="select"
			locale={ko}
			timeCaption={type === "time" && null}
			placeholderText={placeholderText}
			disabled={disabled}
			readOnly={readOnly}
			includeTimes={includeTimes ? includeTimes : null}
			minDate={minDate ? minDate : null}
			maxDate={maxDate ? maxDate : null}
			minTime={minTime ? minTime : null}
			maxTime={maxTime ? maxTime : null}
			timeClassName={timeClassName}
			filterDate={filterDate}
			portalId={`datepicker-portal`}
			calendarClassName={calendarClassName}
			excludeTimes={excludeTimes ? excludeTimes : null}
			injectTimes={injectTimes ? injectTimes : null}
			showTimeInput={showTimeInput}
		/>
		// <div
		// 	className={`c__select f__item ${className} ${selectToggle ? "open" : ""}`}
		// 	tabIndex={0}
		// 	onKeyDown={(e) => e.key === "Tab" && setSelectToggle(false)}
		// 	onFocus={() => {
		// 		setSelectToggle(true);
		// 	}}
		// 	onBlur={() => {
		// 		setSelectToggle(false);
		// 	}}
		// >
		// 	<p
		// 		className={`c__selection-item`}
		// 		onMouseDown={() => {
		// 			setSelectToggle(!selectToggle);
		// 		}}
		// 	>
		// 		{field.value === undefined || field.value === null ? (
		// 			<span className="placeholder">{placeholder}</span>
		// 		) : (
		// 			<span>{(options.find((v) => v.value === field.value) || {}).label}</span>
		// 		)}
		// 		<DownArrowIcon />
		// 	</p>
		// 	<ul className="c__select-dropdown">
		// 		{options.map((option, index) => (
		// 			<li
		// 				key={index}
		// 				className={`c__select-dropdown-item ${
		// 					field.value === option.value && "selected"
		// 				}`}
		// 				onClick={() => {
		// 					field.onChange(option.value);
		// 					setSelectToggle(false);
		// 				}}
		// 			>
		// 				{option.label}
		// 			</li>
		// 		))}
		// 	</ul>
		// </div>
	);
};

export default DatePicker;
