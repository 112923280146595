import { WarningIcon } from "~/assets/svg/AlertIcon";
import Form from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Delete = ({ setVacationDeleteAlert, onSubmit }) => {
	const { t } = useTranslation();
	const defaultValues = {
		status: "deleted",
		memo: "",
	};
	const vacationDeleteForm = useForm({ defaultValues: defaultValues });
	return (
		<>
			<div className="modal-content">
				<div className="alert-content">
					<WarningIcon color={"#ff4444"} />
					<div className="content-wrap">
						<h3>{t("HR.Manage.vacationList.Used.content-wrap.h3")} </h3>
						<p className="warning-phrase">
							{t("HR.Manage.vacationList.Used.content-wrap.p")}
						</p>
					</div>
				</div>
				<Form useForm={vacationDeleteForm} className="vacation-manage-form vertical">
					<Textarea label={t("HR.Manage.memo")} name="memo" />
				</Form>
			</div>
			<div className="modal-footer">
				<button
					className="btn__solid"
					color="gray"
					onClick={() => setVacationDeleteAlert(false)}
				>
					{t("button.back")}
				</button>
				<button
					className="btn__solid"
					color="red"
					onClick={vacationDeleteForm.handleSubmit(onSubmit)}
				>
					{t("button.Delete")}
				</button>
			</div>
		</>
	);
};
export default Delete;
