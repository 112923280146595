import React, { useMemo } from "react";
import { useGlobalStore } from "~/store";
import { thousandFormatter } from "~/utils/formatter";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const View = ({ Header, showEditPage }) => {
	const { t } = useTranslation();
	const [
		{
			ir: { shareholder },
		},
	] = useGlobalStore();

	const buttonList = [
		<AuthButton type="button" className="btn__solid" color="primary" onClick={showEditPage}>
			{t("button.Edit")}
		</AuthButton>,
	];

	const shareholderList = () =>
		shareholder.map(({ name, total, ratio, etc }, idx) => (
			<div key={idx} className="tr">
				<div className="td shareholder-name">{name}</div>
				<div className="td shareholder-amount">{thousandFormatter(total)}</div>
				<div className="td shareholder-ratio">{ratio}%</div>
				<div className="td shareholder-remark">{etc}</div>
			</div>
		));

	const reducedAmount = useMemo(
		() => shareholder.reduce((acc, cur) => acc + parseInt(cur.total), 0),
		[shareholder]
	);

	return (
		<>
			<Header buttonList={buttonList} />
			<div className="ir-view">
				<div className="c__card shareholder-view">
					<h3 className="section-title">
						{t("IR.IrDetail.Shareholder.Edit.Index.title")}
					</h3>
					<div className="scroll-wrap">
						<div className="shareholder-table">
							<div className="tr thead">
								<div className="th shareholder-name">
									{t("IR.IrDetail.Shareholder.Edit.Index.th1")}
								</div>
								<div className="th shareholder-amount">
									{t("IR.IrDetail.Shareholder.Edit.Index.th2")}
								</div>
								<div className="th shareholder-ratio">
									{t("IR.IrDetail.Shareholder.Edit.Index.th3")}
								</div>
								<div className="th shareholder-remark">
									{t("IR.IrDetail.Shareholder.Edit.Index.th4")}
								</div>
							</div>
							{shareholder.length !== 0 ? (
								<>
									<div className="tbody">{shareholderList()}</div>
									<div className="tfoot tr">
										<div className="td">
											{t("IR.IrDetail.Shareholder.Edit.Index.total")}
										</div>
										<div className="td shareholder-amount">
											{thousandFormatter(reducedAmount)}
										</div>
										<div className="td shareholder-ratio">100%</div>
										<div className="td" />
									</div>
								</>
							) : (
								<div className="no-content">
									{t("IR.IrDetail.Shareholder.View.noContent")}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default View;
