import React from "react";

// Assets
import gitbalIco from "~/assets/img/newLanding/ico-gitbal.gif";
import { Trans, useTranslation } from "react-i18next";

const TitleSection = () => {
	const { t } = useTranslation();
	return (
		<section className="title-section">
			<div className="title-wrap">
				<h1>
					{t("Landing.Section.TitleSection.h1.1")}
					<div className="ico-wrap">
						<img src={gitbalIco} alt={t("Landing.Section.TitleSection.img-alt")} />
					</div>
				</h1>
				<h1>{t("Landing.Section.TitleSection.h1.2")}</h1>
				{/*<h1>*/}
				{/*	<Trans i18nKey="Landing.Section.TitleSection.h1.3" components={[<b />]} />*/}
				{/*</h1>*/}
			</div>
		</section>
	);
};

export default TitleSection;
