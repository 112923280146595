import {
	INITIAL_STATE as userState,
	PERSISTED_STATE as persistedUserState,
	REDUCER as userReducer,
} from "./user/reducers";
import {
	INITIAL_STATE as sidebarState,
	PERSISTED_STATE as persistedSidebarState,
	REDUCER as sidebarReducer,
} from "./sidebar/reducers";
import {
	INITIAL_STATE as authState,
	PERSISTED_STATE as persistedAuthState,
	REDUCER as authReducer,
} from "./auth/reducers";
import {
	INITIAL_STATE as shareholderState,
	REDUCER as shareholderReducer,
} from "./shareholder/reducers";
import { INITIAL_STATE as irState, REDUCER as irReducer } from "./ir/reducers";
import {
	INITIAL_STATE as organizationState,
	REDUCER as organizationReducer,
} from "./organization/reducers";
import { INITIAL_STATE as companyState, REDUCER as companyReducer } from "./company/reducers";
import { INITIAL_STATE as hrSettingState, REDUCER as hrSettingReducer } from "./hrSetting/reducers";
import { INITIAL_STATE as evaluateState, REDUCER as evaluateReducer } from "./evaluate/reducers";
import { INITIAL_STATE as vcState, REDUCER as vcReducer } from "./vc/reducers";
import { INITIAL_STATE as pluginState, REDUCER as pluginReducer } from "./plugin/reducers";
import {
	INITIAL_STATE as attendanceState,
	REDUCER as attendanceReducer,
} from "./attendance/reducers";
import { INITIAL_STATE as roundState, REDUCER as roundReducer } from "./round/reducers";
import {
	INITIAL_STATE as taxState,
	PERSISTED_STATE as persistedTaxState,
	REDUCER as taxReducer,
} from "./tax/reducers";
import {
	INITIAL_STATE as communityState,
	PERSISTED_STATE as persistedCommunityState,
	REDUCER as communityReducer,
} from "./community/reducers";
import { INITIAL_STATE as alarmState, REDUCER as alarmReducer } from "./alarm/reducers";
import { INITIAL_STATE as paymentState, REDUCER as paymentReducer } from "./payment/reducers";

export const CLEAR_STATES = "root/CLEAR_STATES";

const initialStates = {
	user: userState,
	sidebar: sidebarState,
	auth: authState,
	organization: organizationState,
	company: companyState,
	shareholder: shareholderState,
	ir: irState,
	hrSetting: hrSettingState,
	evaluate: evaluateState,
	vc: vcState,
	attendance: attendanceState,
	plugin: pluginState,
	round: roundState,
	tax: taxState,
	community: communityState,
	alarm: alarmState,
	payment: paymentState,
};

export const persistedStates = {
	...initialStates,
	user: persistedUserState,
	sidebar: persistedSidebarState,
	auth: persistedAuthState,
	tax: persistedTaxState,
	community: persistedCommunityState,
};

export const reducers = (
	{
		user,
		sidebar,
		auth,
		organization,
		company,
		shareholder,
		ir,
		hrSetting,
		evaluate,
		vc,
		attendance,
		plugin,
		round,
		tax,
		community,
		alarm,
		payment,
	},
	action
) => {
	if (action.type === CLEAR_STATES) {
		return initialStates;
	} else {
		return {
			user: userReducer(user, action),
			sidebar: sidebarReducer(sidebar, action),
			auth: authReducer(auth, action),
			organization: organizationReducer(organization, action),
			company: companyReducer(company, action),
			shareholder: shareholderReducer(shareholder, action),
			ir: irReducer(ir, action),
			hrSetting: hrSettingReducer(hrSetting, action),
			evaluate: evaluateReducer(evaluate, action),
			vc: vcReducer(vc, action),
			attendance: attendanceReducer(attendance, action),
			plugin: pluginReducer(plugin, action),
			round: roundReducer(round, action),
			tax: taxReducer(tax, action),
			community: communityReducer(community, action),
			alarm: alarmReducer(alarm, action),
			payment: paymentReducer(payment, action),
		};
	}
};
