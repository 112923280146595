import React from "react";
import SearchEmpty from "~/pages/invest/EvaluatePage/EvaluateList/Empty/searchEmpty";
import { PAGE_STATE } from "~/common/constants/state";
import { Case, If, OtherWise } from "~/common/components/If";
import EmptyCase from "~/common/components/EmptyCase";
import Loading from "~/common/components/Loading";
import { useTranslation } from "react-i18next";

//Assets
import defaultImg from "~/assets/img/defaultImg/default-img.png";

const View = ({ pageState, roundList, getRoundDetail, lastElementRef }) => {
	const { t } = useTranslation();
	const roundCard = () => {
		return roundList.map((round, idx) => {
			return (
				<div
					key={idx}
					className="c__card list-card"
					onClick={() => getRoundDetail(round.idx)}
					ref={idx + 1 === roundList.length ? lastElementRef : null}
					id={`${round.vcName}-${round.idx}`}
				>
					<div className="custom-badge">{round.vcName}</div>
					<div className={`complete-card ${round.apply ? "active" : ""}`}>
						<span>{round.apply ? t("Invest.Round.RoundList.round-complete") : ""}</span>
					</div>
					<div className="rounding-img">
						<img
							src={round.img ?? defaultImg}
							onError={(e) => {
								e.target.src = defaultImg;
							}}
							alt={t("Invest.Round.RoundList.rounding-img")}
						/>
					</div>
					<div className="rounding-container">
						<h3 className="rounding-content">{round.title}</h3>
						<div className="rounding-container-footer">
							<span className="update">{round.regDate}</span>
							<span className={`due-date ${round.isEnd ? "end" : "open"}`}>
								{round.status}
							</span>
						</div>
					</div>
				</div>
			);
		});
	};

	return (
		<If condition={pageState}>
			<Case condition={PAGE_STATE.EMPTY}>
				<EmptyCase
					main={t("Invest.Round.RoundList.EmptyCase.main")}
					sub={t("Invest.Round.RoundList.EmptyCase.sub")}
				/>
			</Case>
			<Case condition={"NODATA"}>
				<SearchEmpty />
			</Case>
			<OtherWise>
				{roundList.length === 0 ? (
					<Loading />
				) : (
					<>
						<div className="c__cardRow">{roundCard()}</div>
						{pageState === PAGE_STATE.LOADING && <Loading />}
					</>
				)}
			</OtherWise>
		</If>
	);
};

export default View;
