import React, { useState } from "react";
import { useForm } from "react-hook-form";
import AuthenticationLayout from "~/layouts/AuthenticationLayout";
import { Case, If } from "~/common/components/If";
import JoinPageHead from "~/pages/auth/JoinPage/components/JoinPageHead";
import JoinPolicy from "~/pages/auth/JoinPage/Contents/JoinPolicy";
import JoinType from "~/pages/auth/JoinPage/Contents/JoinType";
import JoinForm from "~/pages/auth/JoinPage/Contents/JoinForm";
import JoinAuthMailSend from "~/pages/auth/JoinPage/Contents/JoinAuthMailSend";
import translation from "~/translations";

// Assets
import "~/assets/sass/pages/auth/join.scss";

export const Terms = {
	SERVICE: translation.t("Auth.Join.Terms.service"),
	PRIVACY: translation.t("Auth.Join.Terms.privacy"),
	MARKETING: translation.t("Auth.Join.Terms.marketing"),
};

const JoinPage = () => {
	const currentPath = "join";
	const [currentStep, setCurrentStep] = useState("policy");
	const [bizType, setBizType] = useState("");
	const [checkPolicy, setCheckPolicy] = useState([]);

	const registerForm = useForm();

	return (
		<AuthenticationLayout contentSide={false} PATH={currentPath}>
			<section className={`section-` + currentStep}>
				<JoinPageHead currentStep={currentStep} />
				<If condition={currentStep} {...{ setCurrentStep, bizType, setBizType }}>
					<Case condition={"policy"}>
						<JoinPolicy {...{ checkPolicy, setCheckPolicy }} />
					</Case>
					<Case condition={"bizType"}>
						<JoinType />
					</Case>
					<Case condition={"joinForm"}>
						<JoinForm registerForm={registerForm} checkPolicy={checkPolicy} />
					</Case>
					<Case condition={"mailSend"}>
						<JoinAuthMailSend registerForm={registerForm} />
					</Case>
				</If>
			</section>
		</AuthenticationLayout>
	);
};

export default JoinPage;
