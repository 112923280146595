import React, {useEffect, useState} from 'react';
import TabMenu from "~/common/components/TabMenu";
import Pagination from "~/common/components/Pagination";
import noImg from "~/assets/img/defaultImg/profile-large.png";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import {useTranslation} from "react-i18next";
import useRequest from "~/common/hooks/useRequest";
import {DateWithDayName, hourAndMinuteFormatter} from "~/utils/formatter";
import translation from "~/translations";
import Modal from "~/pages/vacation/ManagePage/vacationList/Expected/Modal/Modal";
import UsedModal from "~/pages/vacation/ManagePage/vacationList/Used/Modal/Modal";
import {VACATION_STATUS, VACATION_STATUS_TEXT} from "~/common/constants/form";

const StatusList = ({ selectedUser, pageNumber, setPageNumber}) => {
    const { t } = useTranslation();
    const tabMenuList = [
        { id: "pending", title: "승인대기" },
        { id: "rejected", title: "반려" },
        { id: "confirm", title: "승인완료" },
    ];
    const currentDate = new Date();
    const [year] = useState(currentDate.getFullYear());

    const [currentTab, setCurrentTab] = useState("pending");
    const [pagingObject, setPagingObject] = useState({});

    const [listData, setListData] = useState([]);
    const [searchFilter, setSearchFilter] = useState({
        page: 1,
        type: "pending" // pending, rejected, confirm
    });

    const [selectedVacation, setSelectedVacation] = useState({}); // 선택한 아이템

    // modal
    const [vacationStatusModal, setVacationStatusModal] = useState(false); // 승인대기, 반려
    const [vacationInfoModal, setVacationInfoModal] = useState(false); // 승인완료

    // api
    const vacationStatusRequest = useRequest("post", "vacation/status"); // 연차 상태변경
    const upcomingRequest = useRequest("get", "vacation/upcoming"); // 연차현황 목록


    // function
    const titleText = (v) => {
        if (!v.HrVacation) return "연차";
        if (
            v.HrVacation.type === "연차" ||
            (v.HrVacation.HrVacationType && v.HrVacation.HrVacationType.days === 0.5)
        ) {
            if (v.timeUnit === "하루종일") return v.HrVacation.type;
            return v.timeUnit;
        }
        return v.HrVacation.title;
    };

    const timeText = (v) => {
        if (!v.HrVacation) return " - ";
        if (
            titleText(v) === "오전반차" ||
            titleText(v) === "오후반차" ||
            titleText(v) === "시간반차" ||
            (v.HrVacation.HrvactionType && v.HrVacation.HrVacationType.days === 0.5)
        ) {
            return `${hourAndMinuteFormatter(v.startTime)}
				 ~
				${hourAndMinuteFormatter(v.endTime)}`;
        }
        return translation.t("HR.Manage.allDay");
    };

    const handleCurrentTab = (tab) => {
        setCurrentTab(tab);
        setPageNumber(1);
        setSearchFilter({
            page: 1,
            type: tab
        })
    };

    // 승인대기, 반려, 삭제 submit
    const onSubmit = async (data) => {
        const payload = { ...data, dateAndTimeIdx: selectedVacation.idx };
        vacationStatusRequest
            .asyncCall(payload, "body")
            .then(() => {
                    upcomingRequest.asyncCall({
                        page: 1,
                        size: 8,
                        type: searchFilter.type ?? "pending",
                    }, "queryString").then((res) => {
                        setListData(res.data.upcomingVacation);
                        setPagingObject((prevState) => {
                            return { ...prevState, previous: res.data.pageable };
                        });
                    })
                }
            )
            .catch((e) => console.log(e));
        setVacationStatusModal(false);
    };

    // 승인완료 submit
    const onSubmitInfo = async (data) => {
        const payload = {
            ...data,
            dateAndTimeIdx: selectedVacation.idx,
        };
        vacationStatusRequest
            .asyncCall(payload, "body")
            .then(() => {
                    upcomingRequest.asyncCall({
                        page: 1,
                        size: 8,
                        type: searchFilter.type ?? "pending",
                    }, "queryString").then((res) => {
                        setListData(res.data.upcomingVacation);
                        setPagingObject((prevState) => {
                            return { ...prevState, previous: res.data.pageable };
                        });
                    })
                }
            )
            .catch((e) => console.log(e));
        setVacationInfoModal(false);
    };

    const handleSelectItem = (userData, status) => {
        setSelectedVacation(userData)
        if(status === "pending" || status === "rejected") {
            setVacationStatusModal(true);
        }
        if(status === "confirm") {
            setVacationInfoModal(true);
        }
    }

    const handleGetUpcomingRequest = async (searchFilter) => {
        upcomingRequest.asyncCall({
            page: searchFilter.page ?? 1,
            size: 8,
            type: searchFilter.type ?? "pending",
        }, "queryString").then((res) => {
            setListData(res.data.upcomingVacation);
            setPagingObject((prevState) => {
                return { ...prevState, previous: res.data.pageable };
            });
        })
    }

    useEffect(() => {
        (searchFilter) && handleGetUpcomingRequest(searchFilter);
    }, [searchFilter]);


    return(
        <>
            <section className="c__card vacation-chk-card status-list">
                <div className="vacation-chk-wrap">
                    <div className="vacation-chk-tab">
                        <TabMenu
                            tabItem={tabMenuList}
                            currentTab={currentTab}
                            setCurrentTab={handleCurrentTab}
                        />
                    </div>
                    <div className="vacation-chk-card">
                        <div className="vacation-chk-header">
                            <h3 className="title">{tabMenuList.filter(item=>item.id === currentTab)[0].title}</h3>
                            <span className={'total-status-badge'}>총 {listData?.length ?? 0}명</span>
                        </div>
                        <div className="vacation-chk-list">
                            {
                                listData?.map((item, idx) => (
                                    <div key={item.idx}
                                         className="row"
                                         onClick={() => handleSelectItem(item, item.status)}
                                    >
                                        <div className="cell user">
                                            <div className="profile">
                                                <img src={noImg} alt={""} />
                                            </div>
                                            <span>{item.history.Receiver.User.UserProfile.name}</span>
                                        </div>
                                        <div className="cell company-info">
                                            {item.history.Receiver.User.UserProfile.orgName}/{item.history.Receiver.User.UserProfile.position}
                                        </div>
                                        <div className="cell date">
                                            { item.startDate ? DateWithDayName(new Date(item.startDate).toString()) : "" }
                                        </div>
                                        <div className="cell term">{item.timeUnit}</div>
                                        <div className="cell status">
                                            { item.status === "pending" && <span className={"status-badge waiting"}>승인대기</span> }
                                            { item.status === "rejected" && <span className={"status-badge reject"}>반려</span> }
                                            { item.status === "confirm" && <span className={"status-badge confirm"}>승인완료</span> }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <Pagination
                            setPageNumber={setPageNumber}
                            currentPage={pageNumber}
                            totalPage={pagingObject.previous && pagingObject.previous.totalPage}
                        />
                    </div>
                </div>
            </section>

            {/* 승인대기, 반려 팝업 */}
            {Object.keys(selectedVacation).length > 0 && (
                <ModalTemplate
                    modalState={vacationStatusModal}
                    setModalToggle={setVacationStatusModal}
                    title={titleText(selectedVacation)}
                    width={460}
                    className="vacation-manage-modal"
                >
                    <Modal
                        {...{
                            vacationManageModal: vacationStatusModal,
                            setVacationManageModal: setVacationStatusModal,
                            selectedVacation,
                            titleText,
                            timeText,
                            onSubmit,
                        }}
                    />
                </ModalTemplate>
            )}

            {/* 승인완료 팝업 */}
            {Object.keys(selectedVacation).length > 0 && (
                <ModalTemplate
                    modalState={vacationInfoModal}
                    setModalToggle={setVacationInfoModal}
                    title={
                        <>
                            {titleText(selectedVacation)}
                            {selectedVacation.HrVacation.HrVacationType.autoConfirm ? (
                                <span className="permission auto-approval">
									{t("HR.Manage.vacationList.auto-approval")}
								</span>
                            ) : (
                                <span
                                    className={`permission ${
                                        VACATION_STATUS[selectedVacation.status]
                                    }`}
                                >
									{VACATION_STATUS_TEXT[VACATION_STATUS[selectedVacation.status]]}
								</span>
                            )}
                        </>
                    }
                    width={460}
                    className="vacation-manage-modal"
                    closeable={false}
                >
                    <UsedModal
                        {...{
                            timeText,
                            selectedVacation,
                            titleText,
                            onSubmit: onSubmitInfo,
                        }}
                    />
                </ModalTemplate>
            )}
        </>
    )
}

export default StatusList
