import React from "react";
import Loading from "~/common/components/Loading";

import PageHeader from "~/common/components/PageHeader";

const LoadingPage = ({ menu, type, buttonList, withCard = false }) => {
	return (
		<>
			<PageHeader {...{ menu, type, buttonList }} />
			<Loading className={withCard ? "c__card" : ""} />
		</>
	);
};

export default LoadingPage;
