import React, { useEffect, useState } from "react";
import { API_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import Form, { Group } from "~/common/components/Form";
import Input from "~/common/components/Input";
import Radio from "~/common/components/Radio";
import AlertTemplate from "~/common/components/AlertPortal/AlertTemplate";
import { useNavigate } from "react-router";
import { BIZ_TYPE } from "~/common/constants/form";
import NumberInput from "~/common/components/NumberInput";
import { useTranslation } from "react-i18next";

const JoinForm = ({ registerForm, setCurrentStep, bizType, checkPolicy }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const currentPassword = registerForm.watch("pw");
	const [bizNum, setBizNum] = useState("");
	const [email, setEmail] = useState("");
	const [checkBizNum, setCheckBizNum] = useState(false);
	const [checkId, setCheckId] = useState(false);

	const [validateModal, setValidateModal] = useState(false);
	const [validateModalType, setValidateModalType] = useState("");
	const [validateModalMsg, setValidateModalMsg] = useState("");

	const registerRequest = useRequest("post", "user/register");
	const checkBizNumDuplicateRequest = useRequest("get", `user/check/biznum?biznum=${bizNum}`);
	const checkIdDuplicateRequest = useRequest("get", `user/check/id?id=${email}`);

	const BIZTYPE = BIZ_TYPE();

	//이메일 validation
	const handleEmailDuplicateCheck = (id) => {
		if (
			!id.match(
				/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{1,10}$/i
			)
		) {
			registerForm.setError("id", {
				type: "pattern",
				message: t("Auth.Join.Form.auth-form.validation.0"),
			});
		} else {
			registerForm.clearErrors("id");
			setEmail(id);
		}
	};

	//이메일 중복체크 Request
	useEffect(() => {
		if (email !== "") {
			checkIdDuplicateRequest.call();
		}
	}, [email]);

	//이메일 중복체크 Response
	useEffect(() => {
		switch (checkIdDuplicateRequest.state) {
			case API_STATE.done:
				setValidateModalType("success");
				setValidateModal(true);
				setValidateModalMsg(t("Auth.Join.Form.auth-form.validation.1"));
				setCheckId(true);
				break;
			case API_STATE.error:
				switch (checkIdDuplicateRequest.error.statusCode) {
					case 409:
						setValidateModalType("error");
						setValidateModal(true);
						setValidateModalMsg(t("Auth.Join.Form.auth-form.validation.2"));
						break;
					case 500:
						alert(t("Auth.Join.Form.auth-form.validation.3"));
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	}, [checkIdDuplicateRequest.state, checkIdDuplicateRequest.error]);

	//사업자등록번호 validation
	const handleBizNumCheck = (regNum) => {
		let sum = 0;
		const key = [1, 3, 7, 1, 3, 7, 1, 3, 5];

		for (let i = 0; i < 9; i++) {
			sum += key[i] * regNum[i];
		}

		const numCheck = 10 - ((sum + Math.floor((regNum[8] * key[8]) / 10)) % 10);

		const bizFirst = regNum.slice(0, 3);
		const bizSecond = regNum.slice(3, 5);
		const bizThird = regNum.slice(5, 9);

		if (regNum.match(/[^0-9]/g) || regNum.length !== 10) {
			//숫자 외 형식 및 자리수 체크
			registerForm.setError("regNum", {
				type: "checkNum",
				message: t("Auth.Join.Form.auth-form.validation.4"),
			});
		} else if (
			//사업자등록번호가 맞는지 체크
			numCheck % 10 === Number(regNum[9]) &&
			101 <= bizFirst &&
			bizFirst <= 999 &&
			Number(bizSecond) !== 0 &&
			Number(bizThird) !== 0
		) {
			registerForm.clearErrors("regNum");
			setBizNum(regNum);
		} else {
			registerForm.setError("regNum", {
				type: "checkFormat",
				message: t("Auth.Join.Form.auth-form.validation.5"),
			});
		}
	};

	//사업자등록번호 중복체크 Request
	useEffect(() => {
		if (bizNum !== "") {
			checkBizNumDuplicateRequest.call();
		}
	}, [bizNum]);

	//사업자등록번호 중복체크 Response
	useEffect(() => {
		switch (checkBizNumDuplicateRequest.state) {
			case API_STATE.done:
				setValidateModalType("success");
				setValidateModal(true);
				setValidateModalMsg(t("Auth.Join.Form.auth-form.validation.6"));
				setCheckBizNum(true);
				break;
			case API_STATE.error:
				switch (checkBizNumDuplicateRequest.error.statusCode) {
					case 409:
						setValidateModalType("error");
						setValidateModal(true);
						setValidateModalMsg(t("Auth.Join.Form.auth-form.validation.7"));
						break;
					case 500:
						alert(t("Auth.Join.Form.auth-form.validation.8"));
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	}, [checkBizNumDuplicateRequest.state, checkBizNumDuplicateRequest.error]);

	//회원가입 Request
	const request = (data) => {
		const formData = {
			id: data.id,
			name: data.name,
			pw: data.pw,
			companyType: data.companyType === undefined ? bizType : data.companyType,
			regNum: data.regNum === undefined ? "" : data.regNum,
			terms: checkPolicy,
			companyName : data.companyName
		};
		
		if (bizType === BIZTYPE.PROSPECTIVE.value) {
			if (checkId) {
				registerRequest.call(formData);
			} else {
				setValidateModalType("warning");
				setValidateModal(true);
				setValidateModalMsg(t("Auth.Join.Form.auth-form.validation.9"));
			}
		} else {
			if (checkBizNum && checkId) {
				registerRequest.call(formData);
			} else {
				setValidateModalType("warning");
				setValidateModal(true);
				setValidateModalMsg(
					checkBizNum
						? t("Auth.Join.Form.auth-form.validation.9")
						: t("Auth.Join.Form.auth-form.validation.10")
				);
			}
		}
	};

	//회원가입 Response
	useEffect(() => {
		switch (registerRequest.state) {
			case API_STATE.done:
				setCurrentStep("mailSend");
				break;
			case API_STATE.error:
				switch (registerRequest.error.statusCode) {
					case 409:
						alert(t("Auth.Join.Form.auth-form.validation.11"));
						break;
					case API_STATE.error:
						switch (registerRequest.error.statusCode) {
							case 409:
								alert(t("Auth.Join.Form.auth-form.validation.11"));
								break;
							case 500:
								alert(t("Auth.Join.Form.auth-form.validation.12"));
								break;
							default:
								break;
						}
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	}, [registerRequest.state, registerRequest.error, setCurrentStep]);

	return (
		<>
			<Form useForm={registerForm} className="auth-form vertical">
				<Input
					label={t("Auth.Join.Form.auth-form.name")}
					placeholder={t("Auth.Join.Form.auth-form.name")}
					name="name"
					autoComplete="new-password"
					required
				/>
				<Group label={t("Auth.Join.Form.auth-form.email.label")} required>
					<Input
						placeholder="example@gitbal.com"
						name="id"
						type="email"
						autoComplete="new-password"
						required
					/>
					<button
						className="btn__solid"
						color={"primary"}
						onClick={() => handleEmailDuplicateCheck(registerForm.watch("id"))}
					>
						{t("button.duplicate")}
					</button>
				</Group>
				<Input
					label={t("Auth.Join.Form.auth-form.password.label")}
					placeholder={t("Auth.Join.Form.auth-form.password.placeholder")}
					required
					name="pw"
					type="password"
					autoComplete="new-password"
					validation={{
						pattern: {
							value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/,
							message: t("Auth.Join.Form.auth-form.password.pattern"),
						},
					}}
				/>
				<Input
					label={t("Auth.Join.Form.auth-form.password-check.label")}
					placeholder={t("Auth.Join.Form.auth-form.password-check.placeholder")}
					required
					name="rePw"
					type="password"
					autoComplete="new-password"
					validation={{
						validate: (value) =>
							value === currentPassword ||
							t("Auth.Join.Form.auth-form.password-check.validate"),
					}}
				/>
				{bizType === "entrepreneur" && (
					<>
					
					<Radio
							label={t("Auth.Join.Form.auth-form.cofound-number.radio.label")}
							name="companyType"
							defaultValue={BIZTYPE.PERSONAL.value}
							options={[
								{
									label: t(
										"Auth.Join.Form.auth-form.cofound-number.radio.options.0"
									),
									value: BIZTYPE.PERSONAL.value,
								},
								{
									label: t(
										"Auth.Join.Form.auth-form.cofound-number.radio.options.1"
									),
									value: BIZTYPE.CORPORATION.value,
								},
							]}
						/>
					
						<Group label={t("Auth.Join.Form.auth-form.cofound-number.label")} required>
							<NumberInput
								placeholder={t(
									"Auth.Join.Form.auth-form.cofound-number.placeholder"
								)}
								name="regNum"
								type="company"
								autoComplete="new-password"
								required
							/>
							<button
								className="btn__solid"
								color={"primary"}
								onClick={() => handleBizNumCheck(registerForm.watch("regNum"))}
							>
								{t("Auth.Join.Form.auth-form.cofound-number.button")}
							</button>
						</Group>
					</>
				)}
				{bizType === "entrepreneur" && (
					<>
				<Input
					label='기업명'//{t("Auth.Join.Form.auth-form.name")}
					placeholder='기업명'//{t("Auth.Join.Form.auth-form.name")}
					name="companyName"
					autoComplete="new-password"
					required
					maxLength={20}
				/>
				</>
				)}
			</Form>
			<div className="btn-wrap">
				<button
					className="btn__solid"
					color="gray"
					onClick={() => setCurrentStep("bizType")}
				>
					{t("button.prev")}
				</button>
				<button
					className="btn__solid"
					color="primary"
					onClick={registerForm.handleSubmit(request)}
				>
					{t("button.next")}
				</button>
			</div>
			<p className="navigate-login">
				{t("Auth.Join.navigate-login.title")}
				<button onClick={() => navigate("/login")}>{t("button.login")}</button>
			</p>
			<AlertTemplate
				alertModalState={validateModal}
				setAlertModalToggle={setValidateModal}
				alertType={validateModalType}
			>
				<p className="alert-content">{validateModalMsg}</p>
				<div className="alert-footer">
					<button
						className="btn__solid"
						color={"primary"}
						onClick={() => {
							setValidateModal(false);
							setEmail("");
							setBizNum("");
						}}
					>
						{t("button.check")}
					</button>
				</div>
			</AlertTemplate>
		</>
	);
};

export default JoinForm;
