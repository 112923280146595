import React, {useRef, useState} from 'react';
import {useGlobalStore} from "~/store";
import {useTranslation} from "react-i18next";
import {format, getMonth, getYear} from "date-fns";
import translation from "~/translations";
import {DetailSearchIcon, FilterIcon} from "~/assets/svg/TeamInfoIcon";
import useAlert from "~/common/hooks/useAlert";
import Calendar from "~/common/components/Calendar";
import CalendarDetailModal from "~/pages/hr/AttendancePage/TeamSchedule/View/calendarDetailModal";

const calendarType = {
    VACATIONS: "휴가",
    BUSINESSTRIP: "외근",
    REMOTEWORK: "재택",
    OVERTIME: "초과근무",
};

const TeamSchedule = (props) => {

    const [
        {
            attendance: { calendarEvents },
        },
    ] = useGlobalStore();
    const calendarRef = useRef();
    const { t } = useTranslation();

    const [nowYear, setNowYear] = useState(new Date().getFullYear());
    const [nowMonth, setNowMonth] = useState(new Date().getMonth() + 1);

    const [showAlert, Alert] = useAlert();

    const customButtons = {
        prev: {
            text: "prev",
            click: () => {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.prev();
                const thisMonth = getMonth(calendarApi.getDate()) + 1;
                const thisYear = getYear(calendarApi.getDate());

                setNowMonth(thisMonth);
                setNowYear(thisYear);
            },
        },
        next: {
            text: "next",
            click: () => {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.next();
                const thisMonth = getMonth(calendarApi.getDate()) + 1;
                const thisYear = getYear(calendarApi.getDate());

                setNowMonth(thisMonth);
                setNowYear(thisYear);
            },
        },
        Today: {
            text: translation.t("HR.Attendance.TeamSchedule.customButtons"),
            click: () => {
                const calendarApi = calendarRef.current.getApi();
                calendarApi.today();
                setNowYear(new Date().getFullYear());
                setNowMonth(new Date().getMonth() + 1);
            },
        },
        filter: {
            text: <FilterIcon />,
            click: () => {
                showAlert();
            },
        },
        search: {
            text: <DetailSearchIcon />,
            click: () => {
                showAlert();
            },
        },
    };


    // const headerToolbar = {
    //     left: "prev next Today",
    //     center: "title",
    //     right: "filter search",
    // };

    const headerToolbar = {
        left: "",
        center: "title",
        right: "prev next Today"
    };

    const [dayScheduleDetailModal, setDayScheduleDetailModal] = useState(false);
    const [daySchedule, setDaySchedule] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedDateType, setSelectedDateType] = useState(null);

    const vacationStatus = {
        confirm: "완료",
        pending: "대기",
    };

    const handleDateClick = (e) => {
        const filteredData = e.event.extendedProps.data.map((d) => ({
            ...d,
            status: vacationStatus[d.status],
        }));

        setDaySchedule(filteredData);
        setSelectedDate(format(e.event.start, "EEEE, dd MMMM yyyy"));
        setSelectedDateType({
            type: e.event.extendedProps.type,
            typeName: calendarType[e.event.extendedProps.type.toUpperCase()],
        });
        setDayScheduleDetailModal(true);
    };

    const renderEventContent = (eventInfo) => {
        const type = eventInfo.event.extendedProps.type;

        return (
            <dl className={`calendar-events ${type}`}>
                <dt>{calendarType[type.toUpperCase()]}</dt>
                <dd>{eventInfo.event.extendedProps.count}</dd>
            </dl>
        );
    };

    return(
        <>
            <div className="team-schedule">
                <Calendar
                    {...{
                        customButtons,
                        headerToolbar,
                        handleDateClick,
                        renderEventContent,
                    }}
                    calendarEvents={calendarEvents}
                    refName={calendarRef}
                />
            </div>
            {dayScheduleDetailModal && (
                <CalendarDetailModal
                    {...{
                        daySchedule,
                        dayScheduleDetailModal,
                        setDayScheduleDetailModal,
                        selectedDate,
                        selectedDateType,
                    }}
                />
            )}
            <Alert
                type="info"
                message={t("HR.Attendance.TeamSchedule.Alert.message")}
                confirm={t("button.check")}
            />
        </>
    )
}

export default TeamSchedule
