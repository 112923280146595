import React, { useEffect } from "react";
import useRequest from "~/common/hooks/useRequest";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { API_STATE } from "~/common/constants/state";
import { useNavigate } from "react-router";

const Callback = ({ info }) => {
	const location = useLocation();
	const navigate = useNavigate();

	const query = queryString.parse(location.search) ?? null;

	const executePurchaseProcessRequest = useRequest("get", "admin/subscription/payment");

	const callbackRequest = {
		success() {
			executePurchaseProcessRequest.call(
				{
					bizNum: query.bizNum,
					phone: query.phone,
					email: query.email,
					authKey: query.authKey,
					customerKey: query.customerKey,
					mode: "web",
				},
				"queryString"
			);
		},
		fail() {
			navigate(`/myPage/admin/license?info=fail&code=${query.code}`);
		},
	};

	useEffect(() => {
		callbackRequest[info]();
	}, []);

	useEffect(() => {
		switch (executePurchaseProcessRequest.state) {
			case API_STATE.done:
				navigate("/myPage/admin/license?info=success");
				break;
			case API_STATE.error:
				navigate(`/myPage/admin/license?info=fail&code=COMMON_ERROR`);
		}
	}, [executePurchaseProcessRequest.state]);
	return <div />;
};

export default Callback;
