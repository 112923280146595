import translation from "~/translations";

export const BANK_METADATA = {
	"02": {
		name: translation.t("contants.bank.02"),
	},
	"03": {
		name: translation.t("contants.bank.03"),
	},
	"04": {
		name: translation.t("contants.bank.04"),
	},
	"07": {
		name: translation.t("contants.bank.07"),
	},
	11: {
		name: translation.t("contants.bank.11"),
	},
	20: {
		name: translation.t("contants.bank.20"),
	},
	23: {
		name: translation.t("contants.bank.23"),
	},
	27: {
		name: translation.t("contants.bank.27"),
	},
	31: {
		name: translation.t("contants.bank.31"),
	},
	32: {
		name: translation.t("contants.bank.32"),
	},
	34: {
		name: translation.t("contants.bank.34"),
	},
	35: {
		name: translation.t("contants.bank.35"),
	},
	37: {
		name: translation.t("contants.bank.37"),
	},
	39: {
		name: translation.t("contants.bank.39"),
	},
	45: {
		name: translation.t("contants.bank.45"),
	},
	48: {
		name: translation.t("contants.bank.48"),
	},
	71: {
		name: translation.t("contants.bank.71"),
	},
	81: {
		name: translation.t("contants.bank.81"),
	},
	88: {
		name: translation.t("contants.bank.88"),
	},
	89: {
		name: translation.t("contants.bank.89"),
	},
};
