import { SET_PLUGIN_LIST } from "./actions";

/**
 * @type {}
 */

export const INITIAL_STATE = {
	pluginList: [],
};

export const REDUCER = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_PLUGIN_LIST:
			return {
				...state,
				pluginList: action.payload.pluginList,
			};
		default:
			return state;
	}
};
