import React, { useEffect, useState } from "react";
import { useGlobalStore } from "~/store";
import { PAGE_STATE } from "~/common/constants/state";
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import EvaluateList from "~/pages/invest/EvaluatePage/EvaluateList";
import PageHeader from "~/common/components/PageHeader";
import Init from "~/pages/invest/EvaluatePage/EvaluateList/Init";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";

const Vc = () => {
	const [
		{
			user: { check },
		},
	] = useGlobalStore();
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	useEffect(() => {
		check.comCheck && check.irCheck ? setPageState(PAGE_STATE.VIEW) : setPageState("init");
	}, []);

	return (
		<NavbarAndSidebarLayout menu={["invest", "review"]} type="main" backTop={true}>
			<PageHeader />
			<If condition={pageState}>
				<Case condition={PAGE_STATE.LOADING}>
					<Loading />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<EvaluateList />
				</Case>
				<Case condition={"init"}>
					<Init />
				</Case>
			</If>
		</NavbarAndSidebarLayout>
	);
};

export default Vc;
