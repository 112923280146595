import React from "react";
import PropTypes from "prop-types";

import makeStyles from "@material-ui/styles/makeStyles";
import stylesNode from "./node-content-renderer-style";

const useStyles = makeStyles(stylesNode);

function isDescendant(older, younger) {
	return (
		!!older.children &&
		typeof older.children !== "function" &&
		older.children.some((child) => child === younger || isDescendant(child, younger))
	);
}

function NodeContentRenderer(props) {
	const {
		scaffoldBlockPxWidth,
		connectDragSource,
		connectDragPreview,
		isDragging,
		canDrop,
		canDrag,
		node,
		title,
		draggedNode,
		treeIndex,
		isSearchMatch,
		isSearchFocus,
		buttons,
		className,
		didDrop,
		lowerSiblingCounts,
		listIndex,
		willRerange,
		handleClick,
		swapFrom,
		isSelected,
	} = props;

	const styles = useStyles();

	const nodeTitle = title || node.title;

	const isDraggedDescendant = (draggedNode && isDescendant(draggedNode, node)) || false;
	const isLandingPadActive = !didDrop && isDragging;

	const onDrag = swapFrom !== null ? true : false;

	if (isLandingPadActive) {
		willRerange(listIndex !== treeIndex);
	}

	// Construct the scaffold representing the structure of the tree
	const scaffold = [];

	lowerSiblingCounts.forEach((lowerSiblingCount, i) => {
		if (i > 0) {
			scaffold.push(
				<div
					className={styles.lineBlock + (isLandingPadActive ? " landingPadActive " : "")}
					key={`pre_${1 + i}`}
					style={{ width: scaffoldBlockPxWidth }}
				/>
			);
		}
	});

	const rowBox = (
		<div
			className={
				styles.row +
				(isDraggedDescendant && canDrag ? ` ${styles.draggedDescendant}` : "") +
				(isLandingPadActive ? ` ${styles.rowLandingPad}` : "") +
				(isLandingPadActive && !canDrop ? ` ${styles.rowCancelPad}` : "") +
				(isSearchMatch ? ` ${styles.rowSearchMatch}` : "") +
				(isSearchFocus ? ` ${styles.rowSearchFocus}` : "") +
				(onDrag ? ` ${styles.onDrag}` : "") +
				(isSelected ? ` ${styles.isSelected}` : "") +
				(className ? ` ${className}` : "")
			}
			style={{ ...styles }}
		>
			<div
				className={
					styles.rowContents + (!canDrag ? ` ${styles.rowContentsDragDisabled}` : "")
				}
			>
				<>
					<div className={styles.rowLabel}>
						<span className={styles.rowTitle}>{nodeTitle}</span>
					</div>
					<div
						className={
							styles.rowToolbar +
							(isLandingPadActive ? ` ${styles.rowLandingPad}` : "")
						}
					>
						{buttons}
					</div>
				</>
			</div>
		</div>
	);

	const nodeContent = (
		<div className={styles.nodeContent}>
			<div
				className={
					styles.rowWrapper + (!canDrag ? ` ${styles.rowWrapperDragDisabled}` : "")
				}
				onClick={handleClick}
			>
				{scaffold}
				{canDrag ? connectDragSource(rowBox) : rowBox}
				<div
					className="preview-cover"
					style={{
						zIndex: "-1",
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						height: "100%",
						width: "100%",
						backgroundColor: "white",
						opacity: "1",
					}}
				/>
				{connectDragPreview(
					<div
						className={" preview"}
						style={{
							backgroundColor: "#eaeaea",
							zIndex: "-2",
							position: "absolute",
							height: "38px",
							padding: "0 30px 0 30px",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							fontWeight: "600",
							display: "flex",
							alignItems: "center",
							color: "#595959",
							borderRadius: "5px",
							border: "1px solid #bfbfbf",
							opacity: "1",
						}}
					>
						{nodeTitle}
					</div>
				)}
			</div>
		</div>
	);

	return nodeContent;
}

NodeContentRenderer.defaultProps = {
	// buttons: null,
	canDrag: false,
	canDrop: false,
	className: "",
	draggedNode: null,
	isSearchFocus: false,
	isSearchMatch: false,
	parentNode: null,
	style: {},
	swapDepth: null,
	swapFrom: null,
	swapLength: null,
	title: null,
	toggleChildrenVisibility: null,
	handleClick: (e) => {
		return e;
	},
};

NodeContentRenderer.propTypes = {
	// buttons: PropTypes.arrayOf(PropTypes.node),
	canDrag: PropTypes.bool,
	canDrop: PropTypes.bool,
	className: PropTypes.string,
	connectDragPreview: PropTypes.func.isRequired,
	connectDragSource: PropTypes.func.isRequired,
	didDrop: PropTypes.bool.isRequired,
	draggedNode: PropTypes.shape({}),
	isDragging: PropTypes.bool.isRequired,
	isOver: PropTypes.bool.isRequired,
	isSearchFocus: PropTypes.bool,
	isSearchMatch: PropTypes.bool,
	listIndex: PropTypes.number.isRequired,
	lowerSiblingCounts: PropTypes.arrayOf(PropTypes.number).isRequired,
	node: PropTypes.shape({}).isRequired,
	parentNode: PropTypes.shape({}),
	path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
	rowDirection: PropTypes.string.isRequired,
	scaffoldBlockPxWidth: PropTypes.number.isRequired,

	// Drag and drop API functions
	// Drag source
	style: PropTypes.shape({}),
	swapDepth: PropTypes.number,
	swapFrom: PropTypes.number,
	swapLength: PropTypes.number,
	title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
	toggleChildrenVisibility: PropTypes.func, // Needed for dndManager
	// Drop target
	treeId: PropTypes.string.isRequired,
	treeIndex: PropTypes.number.isRequired,

	//
	handleClick: PropTypes.func,
};

export default NodeContentRenderer;
