import React, { useEffect, useState } from "react";
import { ClipIcon, RoundMinusIcon, UploadIcon } from "~/assets/svg/InterfacesIcon";
import "~/assets/sass/component/fileUploader.scss";
import useUpload from "~/common/hooks/useUpload";
import { formatBytes } from "~/utils/formatter";
import useAlert from "~/common/hooks/useAlert";
import { useTranslation } from "react-i18next";

const FileUploaderAdd = ({ type, format = "*", fileList, setFileList }) => {
	const { t } = useTranslation();
	const { upload } = useUpload(type, null);
	const [fileSize, setFileSize] = useState(0);
	const [showWarningAlert, WarningAlert] = useAlert();

	const handleChange = async (e) => {
		const files = e.target.files;
		if (fileList.length + files.length <= 5) {
			const data = await upload(files);
			setFileList((prevState) => [
				...prevState,
				{ name: files[0].name, path: data, size: files[0].size },
			]);
		} else {
			showWarningAlert();
		}
	};

	const handleDelete = (name) => {
		setFileList((prevState) => prevState.filter((file) => file.name !== name));
	};

	useEffect(() => {
		let oldFileSize = 0;
		fileList.forEach((f) => (oldFileSize += Number(f.size)));
		setFileSize(oldFileSize);
	}, [fileList]);

	return (
		<>
			{/* 커뮤니티 추가 컴포넌트 */}
			<WarningAlert
				type="warning"
				confirm={t("button.check")}
				message={t("components.FileUploaderAdd.WarningAlert")}
			/>
			<div className="c__fileUploaderAdd">
				<div className="fileUpload-btn">
					<label className="file-label" htmlFor={"file-input"}>
						<input
							type="file"
							accept={format}
							name="file-input"
							className="file-input"
							onChange={handleChange}
						/>
						<UploadIcon />
						{t("components.FileUploaderAdd.file-label")}
					</label>
					<span className="file-text">{t("components.FileUploaderAdd.file-text")}</span>
				</div>

				{fileList.map((file, index) => (
					<div className="fileUpload-add" key={index}>
						<div className="file-preview">
							<ClipIcon />
							<p className="file-name">{file.name}</p>
						</div>
						<div className="btn-round-delete" onClick={() => handleDelete(file.name)}>
							<RoundMinusIcon />
						</div>
					</div>
				))}
			</div>
			{/*<ErrorAlert message="파일 업로드 중 에러가 발생했습니다." type="error" confirm="확인" />*/}
		</>
	);
};

export default FileUploaderAdd;
