import React from "react";
import { useNavigate } from "react-router-dom";
import { MyPostType } from "~/common/constants/type";
import { dateToString } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const PostRow = ({ myPost, myCommunityList, showInfoAlert }) => {
	const { t } = useTranslation();
	const isPostTypeBoard = MyPostType[myPost.type] === MyPostType.NOTICE;
	const navigate = useNavigate();

	const RowClick = () => {
		isPostTypeBoard
			? navigate(`/community/notice/detail/${myPost.idx}`)
			: myCommunityList.find((community) => community.idx === myPost.community.idx)
			? navigate(`/community/list/board/${myPost.community.idx}/post/${myPost.idx}`)
			: showInfoAlert();
	};

	return (
		<div className="tr" onClick={RowClick}>
			<div className="td name">
				{isPostTypeBoard ? (
					<b>{t("contants.type.MyPostType.NOTICE")}</b>
				) : (
					myPost.community.name
				)}
			</div>
			<div className="td title">{myPost.title}</div>
			<div className="td num">{myPost.hitCnt}</div>
			<div className="td date">{dateToString(myPost.createdAt, "yyyy.MM.dd")}</div>
		</div>
	);
};

export default PostRow;
