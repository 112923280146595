import {
	SET_MEMBER_LIST,
	SET_TREE_DATA,
	SET_ORG_MEMBER_DATA,
	SET_EXIST_MEMBER_LIST,
	SET_WORK_TYPE_LIST,
	CLEAR_ORG_DATA,
} from "./actions";
import { updateAll, getChildren } from "~/common/components/TreeView/utils";

/**
 * @typedef ORG_STATE
 * @property {[]=} treeData
 * @property {[]=} orgMemberData
 * @property {[]=} userList
 * @property {boolean=} isTreeEmpty
 */

/**
 * @type {ORG_STATE}
 */
export const INITIAL_STATE = {
	memberList: null,
	treeData: null,
	orgMemberData: null,
	existMemberList: null,
	workTypeList: null,
};

export const REDUCER = (state = INITIAL_STATE, action) => {
	const userCount = (node) => {
		const count = state.orgMemberData.filter((m) => node.id === m.orgId).length;
		const reducedCount = getChildren(node, "id").reduce((acc, cur) => {
			acc += state.orgMemberData.filter((m) => cur === m.orgId).length;
			return acc;
		}, 0);
		return {
			expanded: true,
			count: count,
			reducedCount: reducedCount,
		};
	};

	switch (action.type) {
		case SET_MEMBER_LIST:
			const memberList = action.payload.memberList;
			return {
				...state,
				memberList: memberList,
			};
		case SET_ORG_MEMBER_DATA:
			const orgMemberData = action.payload.orgMemberData;
			return {
				...state,
				orgMemberData: orgMemberData,
			};
		case SET_TREE_DATA:
			const treeData = action.payload.treeData;
			return {
				...state,
				treeData: updateAll(treeData, userCount),
				isTreeEmpty: !treeData ? true : !(treeData.length > 0),
			};
		case SET_EXIST_MEMBER_LIST:
			const existMemberList = action.payload.existMemberList;
			return {
				...state,
				existMemberList: existMemberList,
			};
		case SET_WORK_TYPE_LIST:
			const workTypeList = action.payload.workTypeList;
			return {
				...state,
				workTypeList: workTypeList,
			};
		case CLEAR_ORG_DATA:
			return {
				...INITIAL_STATE,
			};
		default:
			return state;
	}
};
