import React, { useEffect, useState } from "react";
import useRepository from "~/common/hooks/useRepository";
import * as env from "~/common/constants/env";
import { PAGE_STATE } from "~/common/constants/state";
import { thousandFormatter } from "~/utils/formatter";
import { Trans, useTranslation } from "react-i18next";

// Store
import { useGlobalStore } from "~/store";
import { OKCEO_ACCOUNT_DATA } from "~/store/tax/repository";

// Components
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import { OkceoNoContent } from "~/pages/Tax/Dashboard/View";

// Assets
// import { ChartIcon } from "~/assets/svg/InterfacesIcon";

const AccountCard = ({ okceoToken, selectedCompany }) => {
	const { t } = useTranslation();
	const [
		{
			tax: { account },
		},
	] = useGlobalStore();
	const [cardState, setCardState] = useState(PAGE_STATE.LOADING);
	const [getAccountData] = useRepository(OKCEO_ACCOUNT_DATA);

	useEffect(() => {
		getAccountData({ selectedCompany: selectedCompany });
	}, []);

	useEffect(() => {
		if (account) {
			setCardState(PAGE_STATE.VIEW);
		} else {
			setCardState(PAGE_STATE.EMPTY);
		}
	}, [account]);

	return (
		<div className="c__card account-card">
			<div className="card-head">
				<h2 className="card-title">
					<Trans
						i18nKey="Tax.Dashboard.View.component.AccountCard.card-title"
						components={[<span />]}
					/>
				</h2>
				<div className="extra-condition">
					<button
						type="button"
						className="btn__outline angled"
						color="gray"
						onClick={() =>
							window.open(
								`${env.OKCEO_API_URL}/ssolink?token=${okceoToken}&cid=${selectedCompany}`
							)
						}
					>
						{t("Tax.Dashboard.View.component.AccountCard.btn-attachment")}
					</button>
					<button
						type="button"
						className="btn__outline angled"
						color="gray"
						onClick={() =>
							window.open(
								`${env.OKCEO_API_URL}/ssolink?token=${okceoToken}&cid=${selectedCompany}`
							)
						}
					>
						{t("Tax.Dashboard.View.component.AccountCard.btn-detail")}
					</button>
					{/*<button type="button" className="btn__outline btn-detail" color="gray">*/}
					{/*	<ChartIcon />*/}
					{/*</button>*/}
				</div>
			</div>
			<div className="card-content">
				<If condition={cardState}>
					<Case condition={PAGE_STATE.LOADING}>
						<Loading />
					</Case>
					<Case condition={PAGE_STATE.EMPTY}>
						<OkceoNoContent {...{ okceoToken, selectedCompany }} />
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						<div className="total-wrap">
							<h3 className="total-wrap-title">
								{t("Tax.Dashboard.View.component.AccountCard.total-wrap-title")}
							</h3>
							<div className="amount-wrap">
								<p className="amount">
									{thousandFormatter(account?.totalBalance)}
									<i>{t("Tax.Dashboard.View.component.won")}</i>
								</p>
							</div>
						</div>
						<div className="total-account">
							<p className="label">
								{t("Tax.Dashboard.View.component.AccountCard.label.total")}{" "}
								{account?.totalAccountCount}
								{t("Tax.Dashboard.View.component.AccountCard.label.account")}
							</p>
							<p className="amount-wrap">
								<span>{t("Tax.Dashboard.View.component.AccountCard.amount")}</span>
								{thousandFormatter(account?.totalWithdrawal)}
								<i className="unit">{t("Tax.Dashboard.View.component.won")}</i>
							</p>
						</div>
						<div className="card-account-list">
							{account?.accounts &&
								account?.accounts.map((a, a_idx) => {
									return (
										<div key={a_idx}>
											<p className="account-name">{a.bankName}</p>
											<div className="account-info">
												<p className="account-name">{a.accountName}</p>
												<p className="account-num">{a.accountDisplay}</p>
											</div>
											<p className="price">
												<span className="price-number">
													{thousandFormatter(a.accountBalance)}
												</span>
												{t("Tax.Dashboard.View.component.won")}
											</p>
										</div>
									);
								})}
						</div>
						<div
							className="summary-detail"
							onClick={() =>
								window.open(
									`${env.OKCEO_API_URL}/ssolink?token=${okceoToken}&cid=${selectedCompany}`
								)
							}
						>
							&gt; {t("Tax.Dashboard.View.component.AccountCard.btn-detail")}
						</div>
					</Case>
				</If>
				{/*<div className="bar-chart-wrap">*/}
				{/*	<p className="title">앞으로 7일간</p>*/}
				{/*	<div className="row">*/}
				{/*		<dl className="bar-chart" color="plus">*/}
				{/*			<dt className="label">입금예정액</dt>*/}
				{/*			<dd className="bar"></dd>*/}
				{/*			<dd className="amount">0원</dd>*/}
				{/*		</dl>*/}
				{/*		<dl className="bar-chart" color="minus">*/}
				{/*			<dt className="label">출금예정액</dt>*/}
				{/*			<dd className="bar"></dd>*/}
				{/*			<dd className="amount">0원</dd>*/}
				{/*		</dl>*/}
				{/*	</div>*/}
				{/*</div>*/}
				{/*<div className="bar-chart-wrap">*/}
				{/*	<p className="title">*/}
				{/*		전체<span>(일자를 알 수 없는 입출금 예정액 포함)</span>*/}
				{/*	</p>*/}
				{/*	<div className="row">*/}
				{/*		<dl className="bar-chart" color="plus">*/}
				{/*			<dt className="label">입금예정액</dt>*/}
				{/*			<dd className="bar" />*/}
				{/*			<dd className="amount">12,122,122원</dd>*/}
				{/*		</dl>*/}
				{/*		<dl className="bar-chart" color="minus">*/}
				{/*			<dt className="label">출금금예정액</dt>*/}
				{/*			<dd className="bar" />*/}
				{/*			<dd className="amount">12,122,122원</dd>*/}
				{/*		</dl>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		</div>
	);
};

export default AccountCard;
