import React, { useEffect } from "react";
import Form from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import DatePicker from "~/common/components/DatePicker";
import { useFieldArray } from "react-hook-form";
import { eachDayOfInterval, getDay } from "date-fns";
import SelectedDate from "~/pages/vacation/VacationListPage/ApplyVacationModal/Edit/components/SelectedDate";

const AnnualVacation = (props) => {
	const {
		useForm,
		workTime,
		restTime,
		workDays,
		vacationUnit,
		selectedVacation,
		setPageState,
		defaultWorkTime,
	} = props;
	const { control, setValue } = useForm;
	const { fields, replace } = useFieldArray({
		control,
		name: "dateAndTime",
	});

	const selectedStartDate = useForm.watch("startDate");
	const selectedEndDate = useForm.watch("endDate");

	const eachDayArray = eachDayOfInterval({
		start: selectedStartDate,
		end: selectedEndDate,
	});

	const onChangeEndDate = (value) => {
		setValue("endDate", value);
	};

	//date 선택 시 개수만큼 select 표시
	useEffect(() => {
		let newArray = [];

		eachDayArray.forEach((date) => {
			if (workDays.includes(getDay(date))) {
				newArray.push({
					timeUnit: "0",
					startDate: date,
					endDate: date,
					startTime: workTime.morningVStartTime[0],
					endTime: workTime.afternoonVEndTime[0],
				});
			}
		});

		replace(newArray);
	}, [selectedStartDate, selectedEndDate]);

	const isWeekday = (date) => {
		const day = getDay(date);
		return workDays.includes(day);
	};

	return (
		<>
			<Form useForm={useForm} className="annual-vacation-form vertical datePicker-wrap">
				<DatePicker
					label="시작일"
					name={"startDate"}
					onChange={onChangeEndDate}
					filterDate={isWeekday}
				/>
				<span className="dash"> - </span>
				<DatePicker
					label="종료일"
					name={"endDate"}
					minDate={useForm.watch("startDate")}
					filterDate={isWeekday}
				/>
			</Form>
			{fields.map((field, index) => {
				const getName = (name) => `dateAndTime.${index}.${name}`;
				return (
					<SelectedDate
						key={field.id}
						name={getName}
						{...{
							useForm,
							setValue,
							workTime,
							restTime,
							vacationUnit,
							selectedVacation,
							setPageState,
							defaultWorkTime,
						}}
					/>
				);
			})}
			<Form useForm={useForm} className="annual-vacation-form vertical">
				<Textarea label="메모" name="memo" className="memo-textarea" />
			</Form>
		</>
	);
};

export default AnnualVacation;
