import React, { useState } from "react";

// Components
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";

// Assets
import { PAGE_STATE } from "~/common/constants/state";
import Modal from "~/pages/hr/ManagePage/vacationList/All/Modal/Modal";
import { WarningIcon } from "~/assets/svg/AlertIcon";
import Pagination from "~/common/components/Pagination";
import List from "~/pages/hr/ManagePage/vacationList/All/List";
import Empty from "~/pages/hr/ManagePage/Empty";
import { Case, If } from "~/common/components/If";
import { VACATION_STATUS, VACATION_STATUS_TEXT } from "~/common/constants/form";
import { DateWithDayName, getBetweenYears } from "~/utils/formatter";
import CustomSelect from "~/common/components/CustomSelect";
import { Trans, useTranslation } from "react-i18next";

const All = ({
	vacationList,
	pageNumber,
	setPageNumber,
	pagingObject,
	setYear,
	year,
	selectedUser,
	vacationHistoryEditRequest,
	historyRequest,
	currentPath,
	showAuthAlert,
	pageState,
	timeText,
	titleText,
}) => {
	const { t } = useTranslation();
	const [vacationAllManageModal, setVacationAllManageModal] = useState(false);
	const [selectedHistory, setSelectedHistory] = useState({});

	const dateText = (history) => {
		let result = "";
		if (history.HrVacationDateAndTime !== null) {
			result =
				history.HrVacationDateAndTime.startDate === history.HrVacationDateAndTime.endDate
					? DateWithDayName(history.HrVacationDateAndTime.startDate)
					: `${DateWithDayName(
							history.HrVacationDateAndTime.startDate
					  )} ~ ${DateWithDayName(history.HrVacationDateAndTime.endDate)}`;
		} else {
			result = "-";
		}
		return result;
	};

	const eventClassNameMap = {
		Vplus: "history plus",
		Vminus: "history minus",
		rejected: "history minus",
		pending: "history",
		confirm: "history grant",
		deleted: "history minus",
	};

	const eventTextMap = (history) => {
		const user = history?.Performer?.User.UserProfile.name ?? "";
		const receiverUser = history?.Receiver?.User.UserProfile.name ?? "";
		const date = DateWithDayName(history.createdDate, "MM월 dd일");
		const receiverDate = DateWithDayName(
			history?.HrVacationDateAndTime?.startDate,
			"MM월 dd일"
		);

		return {
			Vplus() {
				return (
					<>
						<WarningIcon />
						<Trans
							i18nKey="HR.Manage.vacationList.ALL.Vplus"
							components={[{ user }, { date }, { history }]}
						/>
					</>
				);
			},
			Vminus() {
				return (
					<>
						<WarningIcon />
						<Trans
							i18nKey="HR.Manage.vacationList.ALL.Vminus"
							components={[{ user }, { date }, { history }]}
						/>
					</>
				);
			},
			rejected() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.rejected"
						components={[{ user }, { receiverDate }]}
					/>
				);
			},
			pending() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.pending"
						components={[{ user }, { receiverDate }]}
					/>
				);
			},
			confirm() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.confirm"
						components={[{ user }, { receiverDate }]}
					/>
				);
			},
			deleted() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.deleted"
						components={[{ user }, { receiverDate }]}
					/>
				);
			},
			autoVplus() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.autoVplus"
						components={[{ date }, { history }]}
					/>
				);
			},
			reset() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.reset"
						components={[{ user }, { history }]}
					/>
				);
			},
			policyChange() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.policyChange"
						components={[{ user }, { history }]}
					/>
				);
			},
			escalation() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.escalation"
						components={[{ receiverUser }]}
					/>
				);
			},
			destory() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.destory"
						components={[{ receiverUser }]}
					/>
				);
			},
			apply() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.apply"
						components={[{ receiverDate }]}
					/>
				);
			},
			autoConfirm() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.autoConfirm"
						components={[{ receiverDate }]}
					/>
				);
			},
		};
	};

	const onSubmit = async (data) => {
		const payload = {
			...data,
			historyIdx: selectedHistory.idx,
		};
		vacationHistoryEditRequest
			.asyncCall(payload, "body")
			.then(() =>
				historyRequest.call(
					{
						page: pageNumber,
						size: 8,
						userIdx: selectedUser.User.idx,
						year: year,
					},
					"queryString"
				)
			)
			.catch((e) => console.log(e));
		setVacationAllManageModal(false);
	};

	return (
		<>
			<div className="vacation-chk-header">
				<h3 className="title">{t("HR.Manage.vacationList.ALL.title")}</h3>
				<CustomSelect
					value={year}
					setValue={(value) => {
						setYear(value);
						setPageNumber(1);
					}}
					options={getBetweenYears(selectedUser.User.UserProfile.joinDate)}
				/>
			</div>
			<If condition={pageState}>
				<Case condition={PAGE_STATE.VIEW}>
					<div className="vacation-chk-list">
						<List
							{...{
								setVacationAllManageModal,
								setSelectedHistory,
								titleText,
								dateText,
								eventClassNameMap,
								eventTextMap,
								vacationList,
								currentPath,
								showAuthAlert,
							}}
						/>
					</div>
					<Pagination
						setPageNumber={setPageNumber}
						currentPage={pageNumber}
						totalPage={pagingObject.history.totalPage}
					/>

					{/*전체내역 관리자 확인 모달*/}
					{Object.keys(selectedHistory).length > 0 && (
						<ModalTemplate
							modalState={vacationAllManageModal}
							setModalToggle={setVacationAllManageModal}
							title={
								<>
									{titleText(selectedHistory)}
									{selectedHistory.HrVacationDateAndTime !== null && (
										<span
											className={`permission ${
												VACATION_STATUS[selectedHistory.event]
											}`}
										>
											{
												VACATION_STATUS_TEXT[
													VACATION_STATUS[selectedHistory.event]
												]
											}
										</span>
									)}
								</>
							}
							width={460}
							className="vacation-manage-modal all"
						>
							<Modal
								{...{
									selectedHistory,
									setVacationAllManageModal,
									eventTextMap,
									titleText,
									timeText,
									onSubmit,
								}}
							/>
						</ModalTemplate>
					)}
				</Case>
				<Case condition={PAGE_STATE.EMPTY}>
					<Empty currentTab={"all"} selectedUser={selectedUser} />
				</Case>
			</If>
		</>
	);
};
export default All;
