import React, { useEffect, useState } from "react";
import { PlusIcon } from "~/assets/svg/MathIcon";
import Form, { Group } from "~/common/components/Form";
import Select from "~/common/components/Select";
import DatePicker from "~/common/components/DatePicker";
import { RoundMinusIcon } from "~/assets/svg/InterfacesIcon";
import { useFieldArray, useForm } from "react-hook-form";
import { ScheduleType } from "~/common/constants/type";
import { dateToString } from "~/utils/formatter";
import {
	addHours,
	addMinutes,
	eachMinuteOfInterval,
	isAfter,
	isBefore,
	isEqual,
	isWithinInterval,
	setHours,
	setMilliseconds,
	setMinutes,
	setSeconds,
	subHours,
	areIntervalsOverlapping,
	isSameDay,
	isSameMinute,
	subMinutes,
	getDay,
} from "date-fns";
import useRequest from "~/common/hooks/useRequest";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const InputRow = (props) => {
	const {
		useForm,
		name,
		remove,
		field,
		isDayOff,
		selectedDate,
		currentWorkStartTime,
		currentWorkEndTime,
		vacationTimes,
		scheduleForm,
		idx,
		midnight,
		isDateWeekend,
	} = props;
	const { t } = useTranslation();
	const rowType = useForm.watch(name("type"));
	const isVacation = field.type === "annual";

	const rowErrors = scheduleForm.formState.errors.schedule;

	const rowErrorsType = rowErrors?.[idx] && Object.values(rowErrors?.[idx])[0];

	const ScheduleOptionsGenerator = (type) => {
		return Object.keys(type).map((key) => ({
			label: (
				<>
					<span className={`vacation-name ${key}`} />
					{type[key]}
				</>
			),
			value: key,
		}));
	};

	const currentStartTime = useForm.watch(name("startTime"));

	// const handleSortSchedule = () => {
	// 	const orderedSchedule = useForm.watch("schedule").sort((a, b) => a.startTime - b.startTime);
	// 	scheduleForm.setValue("schedule", orderedSchedule);
	// };

	const onChangeStartTime = (value) => {
		let endTime = addHours(value, 1);
		if (isSameDay(value, endTime)) {
			useForm.setValue(name("endTime"), endTime);
		} else {
			useForm.setValue(name("endTime"), midnight);
		}
		// handleSortSchedule();
	};

	const onChangeEndTime = (value) => {
		if (isBefore(value, currentStartTime)) {
			let startTime = subHours(value, 1);
			if (isBefore(startTime, midnight)) {
				useForm.setValue(name("startTime"), midnight);
			} else {
				useForm.setValue(name("startTime"), startTime);
			}
		}
	};

	const getWithinTime = (v) => {
		const isWithinWorkTime = isWithinInterval(v, {
			start:
				rowType === "overtime"
					? addMinutes(currentWorkStartTime, 1)
					: subMinutes(currentWorkStartTime, 1),
			end:
				rowType === "overtime"
					? subMinutes(currentWorkEndTime, 1)
					: addMinutes(currentWorkEndTime, 1),
		});
		const isWithinVacationTime =
			vacationTimes.length !== 0
				? isWithinInterval(v, {
						start: vacationTimes[0],
						end: vacationTimes[vacationTimes.length - 1],
				  })
				: false;

		if (rowType === "businessTrip" || rowType === "remoteWork") {
			return (
				(isWithinWorkTime && !isWithinVacationTime) ||
				t("HR.Attendance.MySchedule.weeklyCard.schedule-row.validate.0")
			);
		} else if (rowType === "overtime" && !isDateWeekend) {
			return (
				!isWithinWorkTime ||
				t("HR.Attendance.MySchedule.weeklyCard.schedule-row.validate.1")
			);
		} else return null;
	};

	const schedule = useForm.watch("schedule");

	const isOverlap = () => {
		const hasTimes = (d) => d.startTime !== null && d.endTime !== null;
		if (schedule.length > 1 && schedule.every(hasTimes)) {
			let splitArr = [];
			schedule.forEach((d, idx) => {
				for (let i = idx; i < schedule.length - 1; i++) {
					if (
						areIntervalsOverlapping(
							{
								start: setMilliseconds(d.startTime, 0),
								end: setMilliseconds(d.endTime, 0),
							},
							{
								start: setMilliseconds(schedule[i + 1].startTime, 0),
								end: setMilliseconds(schedule[i + 1].endTime, 0),
							}
						)
					) {
						splitArr.push(i, i + 1);
					}

					let arr = Array.from(new Set(splitArr));

					arr.forEach((i) => {
						scheduleForm.setError(`schedule.${i}.startTime`, {
							type: "isoverlap",
							message: t(
								"HR.Attendance.MySchedule.weeklyCard.schedule-row.validate.2"
							),
						});
					});
				}
			});
		}
	};

	return (
		<div className={`schedule-row ${rowErrors?.[idx] ? "has-error" : ""}`}>
			<Form useForm={useForm} className="schedule-form">
				<Select
					name={name("type")}
					options={ScheduleOptionsGenerator(
						isVacation
							? { annual: t("HR.Attendance.MySchedule.schedule-type.annual") }
							: isDayOff(selectedDate.initDate)
							? { overtime: t("HR.Attendance.MySchedule.schedule-type.overtime") }
							: ScheduleType
					)}
					placeholder={t(
						"HR.Attendance.MySchedule.weeklyCard.schedule-row.type.placeholder"
					)}
					disabled={isVacation}
					required
				/>
				<Group className="timePicker-group">
					<DatePicker
						name={name("startTime")}
						time
						required
						placeholderText={t(
							"HR.Attendance.MySchedule.weeklyCard.schedule-row.startTime.placeholder"
						)}
						calendarClassName={"time-vacation"}
						readOnly={isVacation}
						showTimeInput
						disabled={rowType === ""}
						defaultValue={field.startTime ?? null}
						onChange={onChangeStartTime}
						validation={{
							validate: {
								withinTime: (v) => getWithinTime(v),
								overlap: async () => await isOverlap(),
							},
						}}
					/>
					<span className="dash"> - </span>
					<DatePicker
						name={name("endTime")}
						time
						required
						placeholderText={t(
							"HR.Attendance.MySchedule.weeklyCard.schedule-row.endTime.placeholder"
						)}
						calendarClassName={"time-vacation"}
						readOnly={isVacation}
						disabled={rowType === ""}
						defaultValue={field.endTime ?? null}
						showTimeInput
						onChange={onChangeEndTime}
						validation={{
							validate: {
								withinTime: (v) => getWithinTime(v),
								overlap: async () => await isOverlap(),
							},
						}}
					/>
				</Group>
				{!isVacation && (
					<button type="button" className="btn-round-delete" onClick={remove}>
						<RoundMinusIcon />
					</button>
				)}
			</Form>
			{rowErrors?.[idx] && <p className="f__feedback">{rowErrorsType?.message}</p>}
		</div>
	);
};

const Edit = ({
	selectedDate,
	setModalToggle,
	setSelectedDate,
	userWorkType,
	isDayOff,
	getSchedule,
	getScheduleSummary,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { workTimes } = userWorkType;
	const [sortSchedule, setSortSchedule] = useState(selectedDate.schedule);
	const [vacationTimes, setVacationTimes] = useState([]);
	const midnight = setHours(setMinutes(setSeconds(new Date(), 0), 0), 0);

	const scheduleForm = useForm({
		defaultValues: {
			workStartTime: selectedDate.workTime.startTime,
			workEndTime: selectedDate.workTime.endTime,
			schedule: sortSchedule,
		},
	});

	// 0 -> Sunday, 6 -> Saturday
	const isDateWeekend =
		getDay(selectedDate.initDate) === 0 || getDay(selectedDate.initDate) === 6;

	const { fields, append, remove } = useFieldArray({
		control: scheduleForm.control,
		name: "schedule",
	});

	const ROW_INIT_DATA = {
		type: "",
		date: dateToString(selectedDate.initDate, "yyyy-MM-dd"),
		startTime: null,
		endTime: null,
	};

	const currentWorkStartTime = scheduleForm.watch("workStartTime");
	const currentWorkEndTime = scheduleForm.watch("workEndTime");

	const handleClose = () => {
		setSelectedDate({});
		setModalToggle(false);
	};

	function getTimeOfInterval(arr, data = []) {
		let newData = [...data];

		arr.forEach((d) => {
			newData.push(
				eachMinuteOfInterval({ start: d.startTime, end: d.endTime }, { step: 30 })
			);
		});

		return newData
			.flat()
			.sort()
			.reduce((times, time) => {
				const length = times.length;
				if (length === 0 || !isEqual(times[length - 1], time)) {
					times.push(time);
				}
				return times;
			}, []);
	}

	useEffect(() => {
		const vacationArr = selectedDate.schedule.filter((d) => d.type === "annual");

		const reduceVacationTimes = getTimeOfInterval(vacationArr);

		setVacationTimes(reduceVacationTimes);
	}, []);

	function isRegularTime(value, type) {
		return workTimes.findIndex((e) =>
			isEqual(
				setMilliseconds(type === "start" ? e.startTime : e.endTime, 0),
				setMilliseconds(value, 0)
			)
		);
	}

	const handleTime = (value, type) => {
		switch (type) {
			case "start":
				if (isRegularTime(value, type) !== -1) {
					scheduleForm.setValue(
						"workEndTime",
						workTimes[isRegularTime(value, type)].endTime
					);
				} else if (!isBefore(value, currentWorkEndTime)) {
					scheduleForm.setValue("workEndTime", addHours(value, 1));
				}
				break;
			case "end":
				if (isRegularTime(value, type) !== -1) {
					scheduleForm.setValue(
						"workStartTime",
						workTimes[isRegularTime(value, type)].startTime
					);
				} else if (!isAfter(value, currentWorkStartTime)) {
					scheduleForm.setValue("workStartTime", subHours(value, 1));
				}
				break;
		}
	};

	const modifyScheduleRequest = useRequest("post", `vacation/schedule`);

	const submit = async (data) => {
		const newSchedules = data.schedule
			.filter((d) => d.type !== "annual")
			.map((d) => ({
				type: d.type === "annual" ? "휴가" : ScheduleType[d.type],
				startTime: dateToString(d.startTime, "HH:mm"),
				endTime:
					"24:00" && isSameMinute(d.endTime, midnight)
						? "24:00"
						: dateToString(d.endTime, "HH:mm"),
			}));

		const requestData = {
			date: selectedDate.formattedDate,
			workStartTime: dateToString(data.workStartTime, "HH:mm"),
			workEndTime: dateToString(data.workEndTime, "HH:mm"),
			schedules: newSchedules,
		};

		await modifyScheduleRequest
			.asyncCall(requestData)
			.then(async () => {
				await getSchedule();
				await getScheduleSummary();
				setModalToggle(false);
			})
			.catch((e) => {
				console.log(e);
				navigate("/404");
			});
	};

	return (
		<>
			<div className="modal-content">
				{!isDayOff(selectedDate.initDate) && (
					<div className="flexible-time-wrap">
						<Form useForm={scheduleForm} className="schedule-form">
							<Group
								className="timePicker-group"
								label={t("HR.Attendance.MySchedule.weeklyCard.schedule-form")}
							>
								<DatePicker
									time
									name={"workStartTime"}
									calendarClassName={"time-vacation regular-work-time"}
									includeTimes={workTimes.map((d) => d.startTime)}
									onChange={(e) => handleTime(e, "start")}
									showTimeInput
								/>
								<span className="dash"> - </span>
								<DatePicker
									name={"workEndTime"}
									time
									calendarClassName={"time-vacation regular-work-time"}
									includeTimes={workTimes.map((d) => d.endTime)}
									onChange={(e) => handleTime(e, "end")}
									showTimeInput
								/>
							</Group>
						</Form>
					</div>
				)}

				<div className="schedule-form-wrap">
					<div style={{ display: "flex" }}>
						<button
							className="dashed-btn plus-btn"
							onClick={() => append(ROW_INIT_DATA)}
						>
							<PlusIcon />
							{t("HR.Attendance.MySchedule.weeklyCard.schedule-form-wrap")}
						</button>
					</div>
					{fields.map((field, index) => {
						const getName = (propName) => `schedule.${index}.${propName}`;
						const removeItem = () => remove(index);
						return (
							<InputRow
								key={field.id}
								idx={index}
								name={getName}
								remove={removeItem}
								useForm={scheduleForm}
								{...{
									sortSchedule,
									setSortSchedule,
									vacationTimes,
									field,
									currentWorkStartTime,
									currentWorkEndTime,
									isDayOff,
									selectedDate,
									scheduleForm,
									midnight,
									isDateWeekend,
								}}
							/>
						);
					})}
				</div>
			</div>
			<div className="modal-footer">
				<button type="button" className="btn__solid" color="gray" onClick={handleClose}>
					{t("button.cancel")}
				</button>
				<button
					type="button"
					className="btn__solid"
					color="primary"
					onClick={scheduleForm.handleSubmit(submit)}
				>
					{t("button.check")}
				</button>
			</div>
		</>
	);
};

export default Edit;
