import React, { useEffect } from "react";

import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import { RoundMinusIcon } from "~/assets/svg/InterfacesIcon";
import NumberInput from "~/common/components/NumberInput";
import { useTranslation } from "react-i18next";

const InputRow = ({ useForm, name, remove, reducedAmount }) => {
	const { t } = useTranslation();
	const ratio = (parseInt(useForm.watch(name("total"))) / reducedAmount) * 100;

	useEffect(() => {
		if (ratio > 0) {
			useForm.setValue(name("ratio"), ratio.toFixed(2));
		} else {
			useForm.setValue(name("ratio"), 0);
		}
	}, [ratio]);

	return (
		<Form useForm={useForm} className="tr">
			<Input
				placeholder={t("IR.IrDetail.Shareholder.Edit.Index.th1")}
				className={"td shareholder-name"}
				name={name("name")}
				type="text"
				required
			/>
			<NumberInput
				className={"td shareholder-amount"}
				placeholder="0"
				name={name("total")}
				string
			/>
			<NumberInput
				className={"td shareholder-ratio"}
				placeholder={t("IR.IrDetail.Shareholder.Edit.Index.th3")}
				name={name("ratio")}
				decimalScale={1}
				string
				readonly
			/>
			<Input
				className={"td shareholder-remark"}
				placeholder={t("IR.IrDetail.Shareholder.Edit.Index.th4")}
				name={name("etc")}
				type="text"
			/>
			<div className="td">
				<button type="button" className="btn-round-delete" onClick={remove}>
					<RoundMinusIcon />
				</button>
			</div>
		</Form>
	);
};

export default InputRow;
