import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useGlobalStore } from "~/store";

import noImg from "~/assets/img/defaultImg/profile-large.png";
import { PlusIcon } from "~/assets/svg/MathIcon";

import { Case, If } from "~/common/components/If";
import TabMenu from "~/common/components/TabMenu";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import AuthButton from "~/common/components/AuthButton";
import useAlert from "~/common/hooks/useAlert";

import Expected from "~/pages/vacation/ManagePage/vacationList/Expected/Expected";
import Used from "~/pages/vacation/ManagePage/vacationList/Used/Used";
import All from "~/pages/vacation/ManagePage/vacationList/All/All";
import VacationStatus from "~/pages/vacation/ManagePage/vacationList/Modal/VacationStatus";
import ManageAnnual from "~/pages/vacation/ManagePage/vacationList/Modal/ManageAnnual";

import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";

import { AirplaneIcon, HourglassIcon, VacationModalIcon } from "~/assets/svg/InterfacesIcon";
import { hourAndMinuteFormatter } from "~/utils/formatter";
import Detail from "~/pages/vacation/ManagePage/vacationList/Modal/Detail";
import NoData from "~/common/components/NoData";


import translation from "~/translations";


const VacationList = ({ selectedUser, pageNumber, setPageNumber, page, coworkers }) => {
	const { t } = useTranslation();
	const tabMenuList = [
		{ id: "used", title: translation.t("HR.Manage.vacationList.lastVacation") },
		{ id: "expected", title: translation.t("HR.Manage.vacationList.scheduleVacation") },
		{ id: "all", title: translation.t("HR.Manage.vacationList.ALL.title") },
	];

	const currentDate = new Date();

	const navigate = useNavigate();
	const [
		{
			sidebar: { currentPath },
		},
	] = useGlobalStore();

	const [currentTab, setCurrentTab] = useState("used");
	const [annualManageModal, setAnnualManageModal] = useState(false);
	const [applyVacationModal, setApplyVacationModal] = useState(false);
	const [vacationList, setVacationList] = useState({});
	const [pagingObject, setPagingObject] = useState({});
	const [year, setYear] = useState(currentDate.getFullYear());
	const [pageState, setPageState] = useState({
		history: PAGE_STATE.LOADING,
		upcoming: PAGE_STATE.LOADING,
		previous: PAGE_STATE.LOADING,
	});
	const [vacationDetailModal, setVacationDetailModal] = useState(false);
	const [vacationCount, setVacationCount] = useState({});

	const [showAuthAlert, AuthAlert] = useAlert();
	const [showWarningAlert, WarningAlert] = useAlert();

	const remainingTimeRequest = useRequest("post", "vacation/remain/time");
	const vacationStatusRequest = useRequest("post", "vacation/status");
	const vacationHistoryEditRequest = useRequest("post", "vacation/history");
	const vacationTimeCountRequest = useRequest("get", "vacation/time/count");
	const hrUpcomingVacationRequest = useRequest("get", "vacation/upcoming");
	const previousVacationsRequest = useRequest("get", "vacation/previous");
	const historyRequest = useRequest("get", "vacation/history");

	const handleCurrentTab = (tab) => {
		setCurrentTab(tab);
		setYear(new Date().getFullYear());
		setPageNumber(1);
	};

	const titleText = (v) => {
		if (!v.HrVacation) return "연차";
		if (
			v.HrVacation.type === "연차" ||
			(v.HrVacation.HrVacationType && v.HrVacation.HrVacationType.days === 0.5)
		) {
			if (v.timeUnit === "하루종일") return v.HrVacation.type;
			return v.timeUnit;
		}
		return v.HrVacation.title;
	};

	const timeText = (v) => {
		if (!v.HrVacation) return " - ";
		if (
			titleText(v) === "오전반차" ||
			titleText(v) === "오후반차" ||
			titleText(v) === "시간반차" ||
			(v.HrVacation.HrvactionType && v.HrVacation.HrVacationType.days === 0.5)
		) {
			return `${hourAndMinuteFormatter(v.startTime)}
				 ~
				${hourAndMinuteFormatter(v.endTime)}`;
		}
		return translation.t("HR.Manage.allDay");
	};

	const getVacation = async () => {
		const MethodAndUrl = [
			{
				request: historyRequest,
				type: "queryString",
				param: { page: pageNumber, size: 8, userIdx: selectedUser.User.idx, year: year },
			},
			{
				request: previousVacationsRequest,
				type: "queryString",
				param: { page: pageNumber, size: 8, userIdx: selectedUser.User.idx, year: year },
			},
			{
				request: hrUpcomingVacationRequest,
				type: "queryString",
				param: {
					page: pageNumber,
					size: 8,
					userIdx: selectedUser.User.idx,
				},
			},
		];

		const requests = MethodAndUrl.map((data) => {
			data.request.call(data.param, data.type);
		});

		await Promise.all(requests);
	};

	const checkUserRequirements = () => {
		const joinDate = selectedUser.User.UserProfile.joinDate;
		const workType = selectedUser.User.UserProfile.workingType;

		if (joinDate && workType) {
			setAnnualManageModal(true);
		} else {
			showWarningAlert();
		}
	};

	useEffect(() => {
		switch (previousVacationsRequest.state) {
			case API_STATE.done:
				const response = previousVacationsRequest.response.data;
				setVacationList((prevState) => {
					return {
						...prevState,
						previous: {
							data: response.previousVacations,
							totalTime: response.totalTime,
						},
					};
				});
				setPagingObject((prevState) => {
					return { ...prevState, previous: response.pageable };
				});
				break;
			default:
				break;
		}
	}, [previousVacationsRequest.state]);

	useEffect(() => {
		switch (hrUpcomingVacationRequest.state) {
			case API_STATE.done:
				const response = hrUpcomingVacationRequest.response.data;
				setVacationList((prevState) => {
					return {
						...prevState,
						upcoming: {
							data: response.upcomingVacation,
							totalTime: response.totalTime,
						},
					};
				});
				setPagingObject((prevState) => {
					return { ...prevState, upcoming: response.pageable };
				});
				break;
			default:
				break;
		}
	}, [hrUpcomingVacationRequest.state]);

	useEffect(() => {
		switch (historyRequest.state) {
			case API_STATE.done:
				const response = historyRequest.response.data;
				setVacationList((prevState) => {
					return { ...prevState, history: response.histories };
				});
				setPagingObject((prevState) => {
					return { ...prevState, history: response.pageable };
				});
				break;
			default:
				break;
		}
	}, [historyRequest.state]);

	useEffect(() => {
		if (!selectedUser.User.idx) return;
		vacationTimeCountRequest.call(selectedUser.User.idx, "path");
	}, [selectedUser, remainingTimeRequest.state, vacationStatusRequest.state]);

	useEffect(() => {
		switch (vacationTimeCountRequest.state) {
			case API_STATE.done:
				setVacationCount({
					totalVacationTime: `${vacationTimeCountRequest.response.data.totalVacationTime}일`,
					remainingVacationTime: `${vacationTimeCountRequest.response.data.remainingVacationTime}일`,
				});
				break;
			case API_STATE.error:
				console.log(vacationTimeCountRequest.error);
				// navigate("/404");
				break;
		}
	}, [vacationTimeCountRequest.state]);

	useEffect(() => {
		if (Object.keys(vacationList).length !== 3) {
			return;
		}
		setPageState((prevState) => {
			return {
				...prevState,
				history: vacationList.history.length !== 0 ? PAGE_STATE.VIEW : PAGE_STATE.EMPTY,
				previous:
					vacationList.previous.data.length !== 0 ? PAGE_STATE.VIEW : PAGE_STATE.EMPTY,
				upcoming:
					vacationList.upcoming.data.length !== 0 ? PAGE_STATE.VIEW : PAGE_STATE.EMPTY,
			};
		});
	}, [vacationList]);

	useEffect(() => {
		getVacation();
	}, [selectedUser, pageNumber, year]);

	return (
		<>
			<section className="c__card vacation-chk-card">
				<div className="member-info-top">
					{selectedUser.User.UserProfile && (
						<div className="member-info">
							{coworkers.foundUsers.includes(selectedUser) ? (
								<>
									<div className="profile">
										<img
											src={selectedUser.User.img || noImg}
											alt={t("Img.profile")}
										/>
									</div>
									<div className="member-info-detail">
										<span className="name">
											{selectedUser && selectedUser.User.UserProfile.name}
										</span>
										<span className="position">
											{selectedUser &&
												selectedUser.User.UserProfile.department}
											/
											{selectedUser && selectedUser.User.UserProfile.position}
										</span>
										<div className="email">
											{selectedUser && selectedUser.User.id}
										</div>
									</div>
								</>
							) : (
								<>
									<div className="profile">
										<img src={noImg} alt={t("Img.profile")} />
									</div>
									<div className="member-info-detail">
										<span className="position">
											{t("HR.Manage.vacationList.position")}
										</span>
									</div>
								</>
							)}
						</div>
					)}
					{coworkers.foundUsers.includes(selectedUser) ? (
						<div className="vacation-info">
							<div className="sum-wrap">
								<div className="sum-info">
									<AirplaneIcon />
									{t("HR.Manage.totalAnnual")}:
									<span>{vacationCount?.totalVacationTime}</span>
								</div>
								<div className="sum-info">
									<HourglassIcon /> {t("HR.Manage.remainAnnual")}:
									<span
										className={
											vacationCount?.remainingVacationTime?.charAt(0) === "-"
												? "withdraw"
												: ""
										}
									>
										{vacationCount?.remainingVacationTime}
									</span>
								</div>
							</div>

							<div className="btn-group">
								<AuthButton
									type="button"
									className="btn__solid"
									color="gray"
									onClick={() => setApplyVacationModal(true)}
								>
									{t("HR.Manage.vacationList.AuthButton1")}
								</AuthButton>
								<AuthButton
									type="button"
									className="btn__solid"
									color="primary"
									onClick={checkUserRequirements}
								>
									<PlusIcon />
									{t("HR.Manage.vacationList.AuthButton2")}
								</AuthButton>
							</div>
						</div>
					) : (
						<div className="vacation-info">
							<div className="sum-wrap">
								<div className="sum-info">
									<AirplaneIcon />
									{t("HR.Manage.totalAnnual")}:<span>0{t("HR.Manage.day")}</span>
								</div>
								<div className="sum-info">
									<HourglassIcon /> {t("HR.Manage.remainAnnual")}:
									<span>0{t("HR.Manage.day")}</span>
								</div>
							</div>

							<div className="btn-group">
								<AuthButton type="button" className="btn__solid" color="gray">
									{t("HR.Manage.vacationList.AuthButton1")}
								</AuthButton>
								<AuthButton type="button" className="btn__solid" color="primary">
									<PlusIcon />
									{t("HR.Manage.vacationList.AuthButton2")}
								</AuthButton>
							</div>
						</div>
					)}
				</div>
				<div className="vacation-chk-wrap">
					<div className="vacation-chk-tab">
						<TabMenu
							tabItem={tabMenuList}
							currentTab={currentTab}
							setCurrentTab={handleCurrentTab}
						/>
						<button
							type="button"
							className="btn-detail"
							onClick={() => {
								if (coworkers.foundUsers.includes(selectedUser))
									setVacationDetailModal(true);
							}}
						>
							<VacationModalIcon />
						</button>
					</div>
					<div className="vacation-chk-card">
						{coworkers.foundUsers.includes(selectedUser) ? (
							page === PAGE_STATE.VIEW && (
								<If
									condition={currentTab}
									{...{
										selectedUser,
										currentPath,
										showAuthAlert,
										vacationList,
										pagingObject,
										year,
										setYear,
										pageNumber,
										setPageNumber,
										titleText,
										timeText,
									}}
								>
									<Case condition={"used"}>
										<Used
											{...{
												previousVacationsRequest,
												vacationStatusRequest,
												pageState: pageState.previous,
											}}
										/>
									</Case>
									<Case condition={"expected"}>
										<Expected
											{...{
												hrUpcomingVacationRequest,
												vacationStatusRequest,
												pageState: pageState.upcoming,
											}}
										/>
									</Case>
									<Case condition={"all"}>
										<All
											{...{
												vacationHistoryEditRequest,
												historyRequest,
												pageState: pageState.history,
											}}
										/>
									</Case>
								</If>
							)
						) : (
							<NoData main={t("HR.Manage.vacationList.NoData")} />
						)}
					</div>
				</div>
			</section>
			{/*휴가 현황 모달*/}
			<ModalTemplate
				modalState={applyVacationModal}
				setModalToggle={setApplyVacationModal}
				title={
					selectedUser.User.UserProfile.name +
					translation.t("HR.Manage.vacationList.applyVacationModal")
				}
				width={460}
				height={556}
				className="apply-vacation-modal personal-chk"
			>
				<VacationStatus
					{...{
						selectedUser,
					}}
				/>
			</ModalTemplate>
			{/*연차관리 모달*/}
			<ModalTemplate
				modalState={annualManageModal}
				setModalToggle={setAnnualManageModal}
				title={t("HR.Manage.vacationList.annualManageModal")}
				width={460}
				height={438}
			>
				<ManageAnnual
					{...{
						setAnnualManageModal,
						selectedUser,
						remainingTimeRequest,
						historyRequest,
					}}
				/>
			</ModalTemplate>
			<ModalTemplate
				modalState={vacationDetailModal}
				setModalToggle={setVacationDetailModal}
				height={630}
				className="vacation-detail-modal"
				closeable={false}
			>
				<Detail {...{ selectedUser, setVacationDetailModal, vacationTimeCountRequest }} />
			</ModalTemplate>
			<AuthAlert
				message={t("HR.Manage.Alert.AuthAlert")}
				type="warning"
				confirm={t("button.check")}
			/>
			<WarningAlert
				message={
					<>
						{selectedUser.User.UserProfile.name} {t("HR.Manage.Alert.WarningAlert")}
					</>
				}
				type="warning"
				confirm={t("button.check")}
			/>
		</>
	);
};
export default VacationList;
