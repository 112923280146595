import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";

//Assets
import "~/assets/sass/pages/hr/modal/common/edit/career.scss";
import { SolidCloseIcon } from "~/assets/svg/CloseIcon";
import { PlusIcon } from "~/assets/svg/MathIcon";
import DatePicker from "~/common/components/DatePicker";
import Form, { Group } from "~/common/components/Form";
import Input from "~/common/components/Input";
import Textarea from "~/common/components/Textarea";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";

const Career = ({ name, remove, useForm }) => {
	const { t } = useTranslation();
	return (
		<li className="career-input-group">
			<button className="btn__close" onClick={remove}>
				<SolidCloseIcon />
			</button>
			<Form useForm={useForm} className="career-form">
				<Input
					placeholder={t(
						"HR.TeamInfo.modal.MemberDetailModal.components.Career.companyName"
					)}
					name={name("companyName")}
					className="company-name"
					required={null}
				/>
				<Group className="datePicker-group">
					<DatePicker name={name("startDate")} month />
					<span className="dash"> - </span>
					<DatePicker name={name("endDate")} month />
				</Group>
				<Textarea
					name={name("content")}
					placeholder={t("HR.TeamInfo.modal.MemberDetailModal.components.Career.content")}
					className="textarea-wrap"
					required={null}
				/>
			</Form>
		</li>
	);
};

const CareerEdit = ({ useForm }) => {
	const { t } = useTranslation();
	const { control, getValues } = useForm;

	const { fields, append, remove } = useFieldArray({
		control,
		name: "UserCareer",
	});

	const [userId] = useState(getValues("UserProfile.idx"));

	const CAREER_INIT_DATA = {
		userIdx: userId,
		startDate: new Date().toISOString(),
		endDate: new Date().toISOString(),
		companyName: "",
		content: "",
	};

	return (
		<section className="teamInfo-career edit">
			<div className="title-wrap">
				<p className="section-title">
					{t("HR.TeamInfo.modal.MemberDetailModal.components.Career.section-title")}
				</p>
				<button
					className="btn__outline btn__add"
					color="primary"
					onClick={() => append(CAREER_INIT_DATA)}
				>
					<PlusIcon />
					{t("HR.TeamInfo.modal.MemberDetailModal.components.Career.btn__add")}
				</button>
			</div>
			<ul>
				{fields.length !== 0 ? (
					fields.map((field, index) => {
						const getName = (name) => `UserCareer.${index}.${name}`;
						const removeItem = () => remove(index);
						return (
							<Career
								key={field.id}
								name={getName}
								remove={removeItem}
								useForm={useForm}
							/>
						);
					})
				) : (
					<li>
						<NoData
							main={t("HR.TeamInfo.modal.MemberDetailModal.components.Career.NoData")}
						/>
					</li>
				)}
			</ul>
		</section>
	);
};

export default CareerEdit;
