import React, { useEffect, useState } from "react";
import View from "./View";

import { API_STATE, INVEST_STATE, PAGE_STATE } from "~/common/constants/state";
import Search from "~/pages/invest/EvaluatePage/EvaluateList/View/search";
import useInfiniteScroll from "~/common/hooks/useInfiniteScroll";
import useRequest from "~/common/hooks/useRequest";
import { getKeyByValue } from "~/utils/object";
import { dateToString } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const EvaluateList = () => {
	const {t} = useTranslation();
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	const evaluateListRequest = useRequest("post", "invest/search");
	const [evaluateList, setEvaluateList] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [page, setPage] = useState(1);
	const [searchName, setSearchName] = useState("");
	const [searchStatus, setSearchStatus] = useState([]);
	const lastElementRef = useInfiniteScroll(pageState, page, setPage, hasMore);

	const getEvaluateList = () => {
		evaluateListRequest.call(
			{
				size: 20,
				page: page,
				companyName: searchName,
				status: searchStatus,
			},
			"body"
		);
	};

	useEffect(() => {
		setPageState(PAGE_STATE.LOADING);
		getEvaluateList();
	}, [page, searchStatus, searchName]);

	useEffect(() => {
		switch (evaluateListRequest.state) {
			case API_STATE.done:
				const responseData = evaluateListRequest.response.data;
				const filteredData = responseData.list.map((d) => ({
					...d,
					status: getKeyByValue(INVEST_STATE, d.status).toLowerCase(),
					regDate: dateToString(d.regDate, "yyyy-MM-dd"),
				}));

				if (responseData.pageable.totalPage === 0) {
					setEvaluateList([]);
					if (searchName === "" && searchStatus.length === 0) {
						setPageState(PAGE_STATE.EMPTY);
					} else {
						setPageState("NODATA");
					}
				} else {
					if (responseData.pageable.pageNumber === 1) {
						setEvaluateList(filteredData);
					} else {
						setEvaluateList([...evaluateList, ...filteredData]);
					}

					setPageState(PAGE_STATE.VIEW);
				}
				setHasMore(page !== responseData.pageable.totalPage);
				break;
			case API_STATE.error:
				alert(t("Alert.alert2"));
				break;
		}
	}, [evaluateListRequest.state, evaluateListRequest.response, evaluateListRequest.error]);

	return (
		<>
			<Search
				{...{
					setSearchName,
					setSearchStatus,
					setPage,
				}}
			/>
			<View {...{ lastElementRef, pageState, evaluateList }} />
		</>
	);
};

export default EvaluateList;
