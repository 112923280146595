import translation from "~/translations";

export const COUNTRY_TYPE = () => ({
	KR: { label: translation.t("contants.form.COUNTRY_TYP.KR"), value: "한국" },
	CN: { label: translation.t("contants.form.COUNTRY_TYP.CN"), value: "중국" },
	US: { label: translation.t("contants.form.COUNTRY_TYP.US"), value: "미국" },
});

export const LISTED_TYPE = {
	true: translation.t("contants.form.LISTED_TYPE.true"),
	false: translation.t("contants.form.LISTED_TYPE.false"),
};

// export const COMPANY_TYPE = {
// 	0: "예비 창업",
// 	1: "개인사업자",
// 	2: "법인사업자",
// };

export const BIZ_TYPE = () => ({
	PROSPECTIVE: { label: translation.t("contants.form.BIZ_TYPE.PROSPECTIVE"), value: "예비 창업" },
	PERSONAL: { label: translation.t("contants.form.BIZ_TYPE.PERSONAL"), value: "개인 사업자" },
	CORPORATION: {
		label: translation.t("contants.form.BIZ_TYPE.CORPORATION"),
		value: "법인 사업자",
	},
});

export const AREA_TYPE = {
	0: translation.t("contants.form.AREA_TYPE.0"),
	1: translation.t("contants.form.AREA_TYPE.1"),
};

export const IP_TYPE = {
	0: translation.t("contants.form.IP_TYPE.0"),
	1: translation.t("contants.form.IP_TYPE.1"),
};

export const DAY_TYPE = {
	0: translation.t("contants.form.DAY_TYPE.0"),
	1: translation.t("contants.form.DAY_TYPE.1"),
	2: translation.t("contants.form.DAY_TYPE.2"),
	3: translation.t("contants.form.DAY_TYPE.3"),
	4: translation.t("contants.form.DAY_TYPE.4"),
	5: translation.t("contants.form.DAY_TYPE.5"),
	6: translation.t("contants.form.DAY_TYPE.6"),
};

export const EMPLOYEE_TYPE = {
	1: translation.t("contants.form.EMPLOYEE_TYPE.1"),
	2: translation.t("contants.form.EMPLOYEE_TYPE.2"),
	3: translation.t("contants.form.EMPLOYEE_TYPE.3"),
};

export const VACATION_UNIT = {
	0: translation.t("contants.form.VACATION_UNIT.0"),
	1: translation.t("contants.form.VACATION_UNIT.1"),
	2: translation.t("contants.form.VACATION_UNIT.2"),
	3: translation.t("contants.form.VACATION_UNIT.3"),
};

export const EMPLOYMENT_STATUS = {
	0: translation.t("contants.form.EMPLOYMENT_STATUS.0"),
	2: translation.t("contants.form.EMPLOYMENT_STATUS.2"),
	3: translation.t("contants.form.EMPLOYMENT_STATUS.3"),
};

export const PERIOD_TYPE = () => ({
	THIS_YEAR: { label: translation.t("contants.form.PERIOD_TYPE.THIS_YEAR"), value: "THIS_YEAR" },
	LAST_YEAR: { label: translation.t("contants.form.PERIOD_TYPE.LAST_YEAR"), value: "LAST_YEAR" },
	THIS_QUARTER: {
		label: translation.t("contants.form.PERIOD_TYPE.THIS_QUARTER"),
		value: "THIS_QUARTER",
	},
	LAST_QUARTER: {
		label: translation.t("contants.form.PERIOD_TYPE.LAST_QUARTER"),
		value: "LAST_QUARTER",
	},
	THIS_MONTH: {
		label: translation.t("contants.form.PERIOD_TYPE.THIS_MONTH"),
		value: "THIS_MONTH",
	},
	LAST_MONTH: {
		label: translation.t("contants.form.PERIOD_TYPE.LAST_MONTH"),
		value: "LAST_MONTH",
	},
	TODAY: { label: translation.t("contants.form.PERIOD_TYPE.TODAY"), value: "TODAY" },
	YESTERDAY: { label: translation.t("contants.form.PERIOD_TYPE.YESTERDAY"), value: "YESTERDAY" },
});

export const VACATION_STATUS = {
	pending: "wait",
	confirm: "complete",
	rejected: "reject",
};

export const VACATION_STATUS_TEXT = {
	wait: translation.t("contants.form.VACATION_STATUS_TEXT.wait"),
	complete: translation.t("contants.form.VACATION_STATUS_TEXT.complete"),
	reject: translation.t("contants.form.VACATION_STATUS_TEXT.reject"),
	delete: translation.t("contants.form.VACATION_STATUS_TEXT.delete"),
};

export const VACATION_CLASSNAME = {
	연차: "annual",
	오전반차: "annual",
	오후반차: "annual",
	"출산(유산,사산)휴가": "pregnancy",
	"출산(유산,사산)휴가 (다태아)": "multi-pregnancy",
	"배우자 출산휴가": "spouse",
	보건: "health",
	"가족 돌봄 휴가": "family-care",
	"난임 치료 휴가": "sterility",
	병가: "sick",
};

export const LICENSE_PERIOD = {
	month: { date: translation.t("contants.form.LICENSE_PERIOD.month"), money: 11000 },
	year: { date: translation.t("contants.form.LICENSE_PERIOD.year"), money: 9000 },
};

export const GET_TITLE = {
	login: translation.t("contants.form.GET_TITLE.login"),
	join: translation.t("contants.form.GET_TITLE.join"),
	joinconfirm: translation.t("contants.form.GET_TITLE.joinconfirm"),
	dashboard: translation.t("contants.form.GET_TITLE.dashboard"),
	companyinfo: translation.t("contants.form.GET_TITLE.companyinfo"),
	companyproduct: translation.t("contants.form.GET_TITLE.companyproduct"),
	companymember: translation.t("contants.form.GET_TITLE.companymember"),
	companyinvest: translation.t("contants.form.GET_TITLE.companyinvest"),
	hrlist: translation.t("contants.form.GET_TITLE.hrlist"),
	hrorganization: translation.t("contants.form.GET_TITLE.hrorganization"),
	hrattendance: translation.t("contants.form.GET_TITLE.hrattendance"),
	hrvacation: translation.t("contants.form.GET_TITLE.hrvacation"),
	hrmanage: translation.t("contants.form.GET_TITLE.hrmanage"),
	hrsetting: translation.t("contants.form.GET_TITLE.hrsetting"),
	shareholderlist: translation.t("contants.form.GET_TITLE.shareholderlist"),
	irlist: translation.t("contants.form.GET_TITLE.irlist"),
	investreview: translation.t("contants.form.GET_TITLE.investreview"),
	investround: translation.t("contants.form.GET_TITLE.investround"),
	vcinfo: translation.t("contants.form.GET_TITLE.vcinfo"),
	taxdashboard: translation.t("contants.form.GET_TITLE.taxdashboard"),
	taxsetting: translation.t("contants.form.GET_TITLE.taxsetting"),
	communitynotice: translation.t("contants.form.GET_TITLE.communitynotice"),
	communitylist: translation.t("contants.form.GET_TITLE.communitylist"),
	"communitymy-page": translation.t("contants.form.GET_TITLE.communitymy-page"),
	myPageaccount: translation.t("contants.form.GET_TITLE.myPageaccount"),
	myPagealarm: translation.t("contants.form.GET_TITLE.myPagealarm"),
	myPageplugin: translation.t("contants.form.GET_TITLE.myPageplugin"),
	myPageadmin: translation.t("contants.form.GET_TITLE.myPageadmin"),
};
