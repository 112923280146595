
import React, {useEffect, useState} from "react";
import {PAGE_STATE} from "~/common/constants/state";
import {Case, If} from "~/common/components/If";
import Loading from "~/common/components/Loading";
import {useOutletContext} from "react-router-dom";
import useRepository from "~/common/hooks/useRepository";
import {TEAM_SCHEDULE} from "~/store/attendance/repository";
import PageHeader from "~/common/components/PageHeader";
import TabNav from "~/common/components/TabNav";
import translation from "~/translations";
import {useTranslation} from "react-i18next";
import MySchedule from "~/pages/DashboardPage/Contents/MySchedule";
import TeamSchedule from "~/pages/DashboardPage/Contents/TeamSchedule";


const Vacation = (props) => {
    // ----- const
    const { t } = useTranslation();
    const MENU = ["hr", "attendance"];
    const TYPE = "main";
    const scheduleTabList = [
        { id: "my", title: translation.t("HR.Attendance.TAB_LIST.my"), path: "../my" },
        { id: "team", title: translation.t("HR.Attendance.TAB_LIST.team"), path: "../team" },
    ];

    // ----- state
    const [activeTab, setActiveTab] = useState("team"); // default tab active
    const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
    const [nowYear, setNowYear] = useState(new Date().getFullYear());
    const [nowMonth, setNowMonth] = useState(new Date().getMonth() + 1);

    const [getTeamScheduleRequest] = useRepository(TEAM_SCHEDULE);

    // ----- function
    const calendarTabInfo = (item) => {
        setActiveTab(item.id)
    }

    useEffect(() => {
        getTeamScheduleRequest({ year: nowYear, month: nowMonth }).then(async () => {
            await setPageState(PAGE_STATE.VIEW);
        });
    }, [nowMonth]);

    const Header = () => {
        return (
            <>
                <h3 className={"c__title"}>{t("Dashboard.Contents.annualInfo.title")}</h3>
                <TabNav tabList={scheduleTabList} currentTab={activeTab} tabClick={calendarTabInfo}/>
            </>
        );
    };

    return (
        <div className={"c__card dashboard_vacation"}>
            <If condition={pageState} {...{ MENU, TYPE }}>
                <Case condition={PAGE_STATE.VIEW}>
                    <Header />
                    {
                        activeTab === "my" ?
                            <MySchedule/> :
                            <TeamSchedule/>
                    }
                </Case>
                <Case condition={PAGE_STATE.LOADING}>
                    <Header />
                    <Loading />
                </Case>
            </If>
        </div>
    )
}

export default Vacation
