import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import "~/assets/sass/component/paymentBanner.scss";
import { VacationModalIcon } from "~/assets/svg/InterfacesIcon";
import useRepository from "~/common/hooks/useRepository";
import { langImg } from "~/pages/LandingPage/LandingConstant";
import { useGlobalStore } from "~/store";
import { PAYMENT_INFO } from "~/store/payment/repository";

const PaymentBanner = () => {
	const navigate = useNavigate();
	const [getPaymentInfo] = useRepository(PAYMENT_INFO);
	const [
		{
			user: { info },
			payment: { isFailedPayment },
		},
	] = useGlobalStore();

	useEffect(() => {
		if (info.role !== "admin") return;
		getPaymentInfo();
	}, [info.role]);
	return (
		isFailedPayment &&
		info.role === "admin" && (
			<div className="payment-banner">
				<p className="main-desc">결제에 실패했습니다.</p>
				<p className="sub-desc">
					자세한 사항은 [관리자 메뉴 - 청구]에서 확인할 수 있습니다.
				</p>
				<button
					type="button"
					className="btn__solid btn-more"
					onClick={() => navigate("/myPage/admin/payment")}
				>
					확인하기
				</button>
			</div>
		)
	);
};

export default PaymentBanner;
