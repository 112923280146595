import React from "react";
import defaultLogo from "~/assets/img/defaultImg/logo-square.png";
import { handleWindow } from "~/common/components/Confirm";
import { useTranslation } from "react-i18next";

const Basic = ({ vcInfo }) => {
	const { t } = useTranslation();
	// 연차계산
	function opEstablishmentYear(establishmentDate) {
		if (!establishmentDate) return t("VcInfo.VcDetail.basic.Unknown");
		const standard = establishmentDate.replaceAll("-", "");
		const standardYear = Number(standard.slice(0, 4));
		const standardMonth = Number(standard.slice(4, 6));
		const standardDate = Number(standard.slice(6, 8));
		const now = new Date();
		const year = now.getFullYear();
		const month = now.getMonth() + 1;
		const date = now.getDate();

		let fCal = 0;
		let sCal = 0;
		// 일자
		const calDate = date - standardDate;
		if (calDate < 0) fCal = -1;
		// 개월
		let calMonth = month - standardMonth + fCal;
		if (calMonth < 0) {
			sCal = -1;
			calMonth = calMonth + 12;
		}
		// 연도
		const calYear = year - standardYear + sCal;
		fCal = 0;
		sCal = 0;

		return (
			calYear + t("VcInfo.VcDetail.basic.year") + calMonth + t("VcInfo.VcDetail.basic.month")
		);
	}

	return (
		<>
			<li className="company-data-top">
				<div className="logo-wrap">
					<img
						src={vcInfo.default_logo_url ?? defaultLogo}
						onError={(e) => {
							e.target.src = defaultLogo;
						}}
						alt={t("VcInfo.VcDetail.img")}
					/>
				</div>
				<p className="company">
					<span className="company-associate">{vcInfo.invest_association}</span>
					<span className="company-name">{vcInfo.name}</span>
				</p>
				<div className="company-url" onClick={() => handleWindow(vcInfo.home_page)}>
					{vcInfo.home_page ? vcInfo.home_page : t("VcInfo.VcDetail.basic.company-url")}
				</div>
			</li>
			<li className="column">
				<div className="company-data">
					<p className="company-ceo">
						<b>{vcInfo.ceo}</b>
						{t("VcInfo.VcDetail.basic.company-ceo")}
					</p>
					<dl className="company-tel">
						<dt>T</dt>
						<dd>{vcInfo.phone}</dd>
					</dl>
					<dl className="company-mail">
						<dt>E</dt>
						<dd>{vcInfo.email}</dd>
					</dl>
					<p className="company-address">{vcInfo.address}</p>
				</div>
				<div className="company-data">
					<h3 className="basic-info-title">
						{t("VcInfo.VcDetail.basic.basic-info-title")}
					</h3>
					<ul className="basic-info-content">
						<li>
							{t("VcInfo.VcDetail.basic.type")} :{" "}
							{vcInfo.invest_association
								? vcInfo.invest_association
								: t("VcInfo.VcDetail.basic.Unknown")}
						</li>
						<li>
							{t("VcInfo.VcDetail.basic.view")} :{" "}
							{vcInfo.viewCount ? vcInfo.viewCount : 0}
						</li>
						<li>
							{t("VcInfo.VcDetail.basic.annual")} :{" "}
							{opEstablishmentYear(vcInfo.establishment_date)}
						</li>
						<li>
							{t("VcInfo.VcDetail.basic.employee")} :{" "}
							{vcInfo.employee_count
								? vcInfo.employee_count + t("MyPage.admin.License.purchase.people")
								: t("VcInfo.VcDetail.basic.Unknown")}
							}
						</li>
					</ul>
				</div>
			</li>
		</>
	);
};
export default Basic;
