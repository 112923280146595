import React, { useEffect } from "react";
// import useChannelBoot from "~/common/hooks/useChannelBoot";
import { useGlobalStore } from "~/store";
import storageService from "~/utils/storage";
import useResize from "~/common/hooks/useResize";
import { Helmet } from "react-helmet-async";
import { getTitle } from "~/utils/getTitle";
import { GET_TITLE } from "~/common/constants/form";
import { useLocation } from "react-router";

const WithoutAuthRouter = () => {
	const location = useLocation();
	const size = useResize().windowOuterSize;

	const path = location.pathname.split("/").splice("", 3).join("");

	return (
		<Helmet>
			{/* <meta name="viewport" content={`width=${size >= 768 ? "device-width" : 767}`} /> */}
			<title>
				{GET_TITLE[path]
					? getTitle(GET_TITLE[path])
					: "Persona | One click away from the global team"}
			</title>
		</Helmet>
	);
};

export default WithoutAuthRouter;
