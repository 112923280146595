import React from "react";

// Components
import AuthenticationLayout from "~/layouts/AuthenticationLayout";
import LoginForm from "./LoginForm";

// Assets
import "~/assets/sass/pages/auth/login.scss";

const LoginPage = () => {
	const currentPath = "login";

	return (
		<AuthenticationLayout contentSide={true} PATH={currentPath}>
			<LoginForm />
		</AuthenticationLayout>
	);
};

export default LoginPage;
