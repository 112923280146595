import { SET_SELECTED_IDX, CLEAR_SELECTED_IDX } from "./actions";

export const INITIAL_STATE = {
	selectedIdx: null,
};

export const REDUCER = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_SELECTED_IDX:
			return {
				...state,
				selectedIdx: action.payload.idx,
			};
		case CLEAR_SELECTED_IDX:
			return {
				...state,
				...INITIAL_STATE,
			};
		default:
			return state;
	}
};
