import React, {useEffect} from "react";
import { minutesToHours } from "date-fns";
import { ClockIcon, CoffeeIcon } from "~/assets/svg/InterfacesIcon";
import { dateToString } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const SummaryCard = ({ scheduleTimes, userWorkType }) => {
	const { t } = useTranslation();
	const { restHours, defaultWorkTimes } = userWorkType;

	function minutesToString(minutes) {
		const hours = minutesToHours(minutes);
		const restMinutes = minutes - 60 * hours;

		if (hours === 0) {
			return (
				<>
					<b>{restMinutes}</b>
					{t("HR.Attendance.MySchedule.summaryCard.work-schedule-card.minute")}
				</>
			);
		} else {
			if (restMinutes !== 0) {
				return (
					<>
						<b>{hours}</b>
						{t("HR.Attendance.MySchedule.summaryCard.work-schedule-card.hour")}{" "}
						<b>{restMinutes}</b>
						{t("HR.Attendance.MySchedule.summaryCard.work-schedule-card.minute")}
					</>
				);
			} else {
				return (
					<>
						<b>{hours}</b>
						{t("HR.Attendance.MySchedule.summaryCard.work-schedule-card.hour")}
					</>
				);
			}
		}
	}

	useEffect(() => {
		console.log("scheduleTimes -> ", scheduleTimes)
		scheduleTimes && console.log("scheduleTimes -> ", scheduleTimes)
	}, [scheduleTimes])

	return (
		<>
			<section className="schedule-summary">
				<div className="c__card schedule-status-section">
					<div className="schedule-status">
						<h5 className="title-wrap">
							<span className="title">
								{t("HR.Attendance.MySchedule.summaryCard.schedule-status.title.0")}
							</span>
							<span className="time">
								{minutesToString(scheduleTimes.work.result)}
							</span>
						</h5>
						<div className="progress-step-bar">
							<div
								className="progress expect"
								style={{
									width:
										(scheduleTimes.work.result / scheduleTimes.work.max) * 100 +
										"%",
								}}
							/>
						</div>
					</div>
					<div className="schedule-status">
						<h5 className="title-wrap">
							<span className="title">
								{t("HR.Attendance.MySchedule.summaryCard.schedule-status.title.1")}
							</span>
							<span className="time">
								{minutesToString(scheduleTimes.overwork.result)}
							</span>
						</h5>
						<div className="progress-step-bar">
							<div
								className="progress overtime"
								style={{
									width:
										(scheduleTimes.overwork.result /
											scheduleTimes.overwork.max) *
											100 +
										"%",
								}}
							/>
						</div>
					</div>
					<div className="schedule-status">
						<h5 className="title-wrap">
							<span className="title">
								{t("HR.Attendance.MySchedule.summaryCard.schedule-status.title.2")}
							</span>
							<span className="time">
								{minutesToString(scheduleTimes.vacation.result)}
							</span>
						</h5>
						<div className="progress-step-bar">
							<div
								className="progress holiday"
								style={{
									width:
										(scheduleTimes.vacation.result /
											scheduleTimes.vacation.max) *
											100 +
										"%",
								}}
							/>
						</div>
					</div>
				</div>
				<div className="c__card work-schedule-card">
					<p className="title">
						{t("HR.Attendance.MySchedule.summaryCard.work-schedule-card.title.0")}
					</p>
					<div className="time-wrap">
						<ClockIcon />
						<p className="time work">
							{defaultWorkTimes && dateToString(defaultWorkTimes.startTime, "HH:mm")}
						</p>
						{/*
						{workTimes.length > 1 && (
							<button
								className="btn-setting"
								onClick={() => setEditDefaultWorkTimeModal(true)}
							>
								<WheelIcon />
							</button>
						)}
*/}
					</div>
				</div>
				<div className="c__card work-schedule-card">
					<p className="title">
						{t("HR.Attendance.MySchedule.summaryCard.work-schedule-card.title.1")}
					</p>
					<div className="time-wrap">
						<CoffeeIcon />
						<p className="time rest">
							{restHours}
							{t("HR.Attendance.MySchedule.summaryCard.work-schedule-card.hour")}
						</p>
					</div>
				</div>
			</section>
		</>
	);
};

export default SummaryCard;
