import React from "react";

//Assets
import { CautionThickIcon, NothingIcon, ThinChkIcon } from "~/assets/svg/InterfacesIcon";
import TooltipTemplate from "~/common/components/TooltipPortal/TooltipTemplate";

export const DetailCompare = ({ free, pro, name, tooltip }) => {
	return (
		<div className="tr">
			<div className="td item-detail pre">
				{name}
				{tooltip && (
					<button type="button" className="c__tooltip-btn">
						<CautionThickIcon />
						<TooltipTemplate>{tooltip}</TooltipTemplate>
					</button>
				)}
			</div>
			<div className="td item-free">{free ? <ThinChkIcon /> : <NothingIcon />}</div>
			<div className="td item-pro">{pro ? <ThinChkIcon /> : <NothingIcon />}</div>
			<div className="td item-enterprise">
				<ThinChkIcon />
			</div>
		</div>
	);
};
