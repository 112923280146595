import Form from "~/common/components/Form";
import Radio from "~/common/components/Radio";
import Textarea from "~/common/components/Textarea";
import React from "react";
import { useForm } from "react-hook-form";
import { VACATION_CLASSNAME } from "~/common/constants/form";
import { DateWithDayName } from "~/utils/formatter";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const ManageStatus = ({ timeText, selectedVacation, setChangeStatusModal, onSubmit }) => {
	const { t } = useTranslation();
	const defaultValues = {
		status: selectedVacation.status,
		memo: "",
	};
	const vacationManageForm = useForm({ defaultValues: defaultValues });

	return (
		<>
			<div className="modal-content">
				<div className="vacation-state">
					<div
						className={`vacation-name ${
							VACATION_CLASSNAME[selectedVacation.HrVacation.title]
						}`}
					>
						{selectedVacation.startDate === selectedVacation.endDate
							? DateWithDayName(selectedVacation.startDate)
							: `${DateWithDayName(selectedVacation.startDate)}
							   ~ 
							  ${DateWithDayName(selectedVacation.endDate)}`}
					</div>
					<div className="time">{timeText(selectedVacation)}</div>
				</div>
				<Form useForm={vacationManageForm} className="vacation-manage-form vertical">
					<Radio
						label={t("HR.Manage.later")}
						name="status"
						className="divided3"
						options={[
							{ label: translation.t("HR.Manage.pending"), value: "pending" },
							{ label: translation.t("HR.Manage.confirm"), value: "confirm" },
							{ label: translation.t("HR.Manage.rejected"), value: "rejected" },
						]}
						boolean
					/>
				</Form>
				<Form useForm={vacationManageForm} className="vacation-manage-form vertical">
					<Textarea label={t("HR.Manage.memo")} name="memo" />
				</Form>
			</div>
			<div className="modal-footer">
				<button
					className="btn__solid"
					color="gray"
					onClick={() => setChangeStatusModal(false)}
				>
					{t("button.back")}
				</button>
				<button
					className="btn__solid"
					color="primary"
					onClick={vacationManageForm.handleSubmit(onSubmit)}
				>
					{t("button.confirm")}
				</button>
			</div>
		</>
	);
};
export default ManageStatus;
