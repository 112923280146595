import ReactQuill, { Quill } from "react-quill";
import React, { useEffect, useMemo, useRef } from "react";
import useUpload from "~/common/hooks/useUpload";
import ImageResize from "quill-image-resize";
import { useTranslation } from "react-i18next";
Quill.register("modules/imageResize", ImageResize);

const quillFormats = [
	"header",
	"bold",
	"italic",
	"underline",
	"strike",
	"blockquote",
	"list",
	"bullet",
	"indent",
	"link",
	"image",
];

const Editor = ({ useForm, validation, setImageList, uploadType, placeholder }) => {
	const { t, i18n } = useTranslation();
	const quillRef = useRef(null);
	const { upload } = useUpload(uploadType, null);
	useEffect(() => {
		useForm.register("content", { required: true, ...validation, defaultValue: "" });
	}, [useForm.register]);

	const imageHandler = () => {
		const input = document.createElement("input");
		input.setAttribute("type", "file");
		input.setAttribute("accept", "image/*");
		input.click();
		input.addEventListener("change", async () => {
			const file = input.files;

			if (file) {
				try {
					if (quillRef.current) {
						const data = await upload(file);
						const editor = quillRef.current.getEditor();
						const range = editor.getSelection();
						editor.insertEmbed(range.index, "image", data);
						editor.setSelection({
							...range,
							index: range.index + 1,
						});
						setImageList((prevState) => [
							...prevState,
							{ name: file[0].name, path: data, size: file[0].size },
						]);
					}
				} catch (e) {
					console.error(e);
				}
			}
		});
	};

	const quillModules = useMemo(() => {
		return {
			imageResize: {
				displayStyles: {
					backgroundColor: "black",
					border: "none",
					color: "white",
				},
				parchment: Quill.import("parchment"),
				modules: ["Resize", "DisplaySize", "Toolbar"],
			},
			toolbar: {
				container: [
					[{ header: [1, 2, 3, false] }],
					["bold", "italic", "underline", "strike", "blockquote"],
					[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
					["link", "image", "video"],
					["clean"],
				],
				handlers: {
					image: imageHandler,
				},
			},
		};
	}, [i18n.language]);

	return (
		<div className="f__group">
			<div className={`f__control ${useForm.formState.errors.content ? "has-error" : ""}`}>
				<ReactQuill
					ref={quillRef}
					theme="snow"
					placeholder={placeholder ?? t("components.Editor")}
					value={useForm.watch("content") || ""}
					onChange={(e) => {
						useForm.setValue("content", e === "<p><br></p>" ? "" : e);
						useForm.trigger("content");
					}}
					modules={quillModules}
					formats={quillFormats}
				/>
				{useForm.formState.errors.content && (
					<span className="quill__feedback">{t("Validate.require")}</span>
				)}
			</div>
		</div>
	);
};

export default Editor;
