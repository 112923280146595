import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "~/assets/img/newLanding/logo.png";
import {
	HamburgerMenuIcon,
	PersonaLogo,
	MenuIconResponsive,
	CloseIconResponsive,
 NavArrowDown
} from "~/assets/svg/LandingIcon";
import OutsideAlerter from "~/common/hooks/OutsideAlerter";
import { useSetAtom } from "jotai";
import { promotionModalAtom, bookDemoModalAtom } from "~/atoms/Landing";
import TransLanguageSelect from "~/common/components/TransLanguageSelect";
import { useTranslation } from "react-i18next";

const LandingHeader = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const current = location.pathname;
	const navigate = useNavigate();
	const navigateLinks = [
		// {
  //  name: (
  //   <div className="flex gap-2 items-center">
  //     {t("Landing.LandingHeader.nav.platform")} <NavArrowDown />
  //   </div>
  // ),
		// 	link: "",
		// },
		// {
		// 	name: t("Landing.LandingHeader.nav.why-us"),
		// 	link: "/why-us",
		// },
		{
			name: t("Landing.LandingHeader.nav.pricing"),
			link: "/pricing",
		},
	];

	const [sideBarMenu, setSideBarMenu] = useState(false);
	const sideBarToggle = () => {
		setSideBarMenu(!sideBarMenu);
	};
	const clickOutHandler = () => setSideBarMenu(false);
	const setBookDemoModal = useSetAtom(bookDemoModalAtom);

	return (
		<>
			<div className="absolute top-0 z-50 w-full max-w-[1920px] mx-auto left-0 right-0 text-center">
				<header className="flex items-center justify-between py-8 w-[95%] sm:w-[85%] m-auto">
					<div className="flex items-center gap-16">
						<div href={"/"} className="cursor-pointer" onClick={() => navigate("/")}>
							<PersonaLogo />
						</div>
						<div className="flex items-start gap-8 justify-between md:flex hidden">
							{navigateLinks.map((item, index) => (
								<div
									key={index}
									onClick={() => navigate(item.link)}
									className=" hover:cursor-pointer px-4 py-2 rounded-xl"
								>
									{item.name}
								</div>
							))}
						</div>
					</div>
					<div className="md:flex hidden gap-2 items-center">
						<div className="cursor-pointer">
							<TransLanguageSelect />
						</div>
						<button
							className="w-[160px] bg-green-950 text-white py-3 rounded-lg font-semibold lg:block hidden"
							onClick={() => {
								setBookDemoModal(true);
							}}
						>
							{t("Landing.LandingHeader.nav.button.book-a-demo")}
						</button>
					</div>
					<div className="md:hidden block p-2" onClick={sideBarToggle}>
						<MenuIconResponsive />
					</div>
					{sideBarMenu ? (
						<OutsideAlerter callback={clickOutHandler}>
							<div
								className={`bg-white absolute top-0 right-0 w-screen border border-solid border-black animate-appear`}
							>
								<div className=" w-[95%] mx-auto flex justify-between mt-10">
									<PersonaLogo />
									<div onClick={sideBarToggle} className="mr-1">
										<CloseIconResponsive />
									</div>
								</div>
								<div className="py-4">
									{navigateLinks.map((item, index) => (
										<div
											key={index}
											onClick={() => navigate(item.link)}
											className=" hover:cursor-pointer px-4 py-2 rounded-xl flex justify-center"
										>
											{item.name}
										</div>
									))}
								</div>
							</div>
						</OutsideAlerter>
					) : (
						""
					)}
				</header>
			</div>
		</>
	);
};

export default LandingHeader;


//  *OLD CODE*

// import React, { useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import logo from "~/assets/img/newLanding/logo.png";
// import { HamburgerMenuIcon } from "~/assets/svg/LandingIcon";
// import OutsideAlerter from "~/common/hooks/OutsideAlerter";
// import { useSetAtom } from "jotai";
// import { promotionModalAtom } from "~/atoms/Landing";
// // import TransLanguageSelect from "~/common/components/TransLanguageSelect";
// import { useTranslation } from "react-i18next";

// const LandingHeader = () => {
// 	const { t } = useTranslation();
// 	const location = useLocation();
// 	const current = location.pathname;
// 	const navigate = useNavigate();

// 	const [loginToggle, setLoginToggle] = useState("none");
// 	const clickHandler = () => setLoginToggle("none");

// 	const [sideBarMenu, setSideBarMenu] = useState(false);
// 	const sideBarToggle = () => {
// 		setSideBarMenu(!sideBarMenu);
// 	};
// 	const clickOutHandler = () => setSideBarMenu(false);
// 	const setPromotionModal = useSetAtom(promotionModalAtom);

// 	return (
// 		<>
// 			<header className="landing-header">
// 				<div className="wrap">
// 					<h1 className="logo-wrap" onClick={() => navigate("/")}>
// 						<div className="img-wrap">
// 							<img src={logo} alt={t("Landing.LandingHeader.alt")} />
// 						</div>
// 						<span>DAO</span>
// 					</h1>
// 					<ul className="nav">
// 						<li
// 							className={`nav-item ${current === "/" ? "selected" : ""}`}
// 							onClick={() => navigate("/")}
// 						>
// 							{t("Landing.LandingHeader.nav.1")}
// 						</li>
// 						<li className="nav-item">
// 							<a href={"https://docs.gitbal.io"} target="_blank">
// 								{t("Landing.LandingHeader.nav.2")}
// 							</a>
// 						</li>
// 						<li className="nav-item">
// 							<a
// 								href={"https://map.gitbal.io/"}
// 								target="_blank"
// 								rel="noopener noreferrer"
// 							>
// 								{t("Landing.LandingHeader.nav.3")}
// 							</a>
// 						</li>
// 						<li
// 							className={`nav-item ${current === "/case" ? "selected" : ""}`}
// 							onClick={() => {
// 								navigate("/case");
// 							}}
// 						>
// 							{t("Landing.LandingHeader.nav.4")}
// 						</li>
// 					</ul>
// 					<OutsideAlerter callback={clickHandler}>
// 						<div className="right-wrap">
// 							{/*<TransLanguageSelect />*/}
// 							<div className="login-wrap">
// 								<div
// 									className={`btn__outline btn-login angled ${
// 										loginToggle === "" ? "active" : ""
// 									}`}
// 									color="landing"
// 									onClick={() => {
// 										if (loginToggle === "none") {
// 											setLoginToggle("");
// 										} else {
// 											setLoginToggle("none");
// 										}
// 									}}
// 								>
// 									{t("Landing.LandingHeader.login-wrap.login")}
// 									<div className="nav-dropdown" style={{ display: loginToggle }}>
// 										<button
// 											type="button"
// 											className="dropdown-link"
// 											onClick={() => navigate("/login")}
// 										>
// 											{t("Landing.LandingHeader.login-wrap.st")}
// 										</button>
// 										<button
// 											type="button"
// 											className="dropdown-link"
// 											onClick={() => window.open("http://vc.gitbal.io")}
// 										>
// 											{t("Landing.LandingHeader.login-wrap.vc")}
// 										</button>
// 									</div>
// 								</div>
// 								{/*<button*/}
// 								{/*	type="button"*/}
// 								{/*	className="btn__solid btn-start"*/}
// 								{/*	// onClick={() => navigate("/login")}*/}
// 								{/*	onClick={() => setPromotionModal(true)}*/}
// 								{/*>*/}
// 								{/*	{t("Landing.LandingHeader.btn-start")}*/}
// 								{/*</button>*/}

// 								{/*햄버거 메뉴*/}
// 								<button type="button" className="btn-menu" onClick={sideBarToggle}>
// 									<span className="menu-text">Menu</span>
// 									<HamburgerMenuIcon />
// 								</button>
// 							</div>
// 						</div>
// 					</OutsideAlerter>
// 				</div>
// 			</header>
// 			<OutsideAlerter callback={clickOutHandler}>
// 				<div className={`landing-sidebar ${sideBarMenu ? "active" : "inactive"}`}>
// 					<button type="button" className="btn-menu" onClick={sideBarToggle}>
// 						<HamburgerMenuIcon />
// 					</button>
// 					<ul className="nav">
// 						<li
// 							className={`nav-item ${current === "/" ? "selected" : ""}`}
// 							onClick={() => navigate("/")}
// 						>
// 							{t("Landing.LandingHeader.landing-sidebar.nav.1")}
// 						</li>
// 						<li>
// 							<a href={"https://docs.gitbal.io"} target="_blank" className="nav-item">
// 								{t("Landing.LandingHeader.landing-sidebar.nav.2")}
// 							</a>
// 						</li>
// 						<li className="nav-item">
// 							{t("Landing.LandingHeader.landing-sidebar.nav.3")}
// 						</li>
// 						<li
// 							className={`nav-item ${current === "/case" ? "selected" : ""}`}
// 							onClick={() => {
// 								navigate("/case");
// 							}}
// 						>
// 							{t("Landing.LandingHeader.landing-sidebar.nav.4")}
// 						</li>
// 						<li className="nav-item disabled">
// 							{t("Landing.LandingHeader.landing-sidebar.nav.5")}
// 						</li>
// 					</ul>
// 					<div className="site-group">
// 						<button
// 							type="button"
// 							className="btn__solid btn-site angled"
// 							onClick={() => navigate("/login")}
// 						>
// 							{t("Landing.LandingHeader.landing-sidebar.st-login")}
// 						</button>
// 						<button
// 							type="button"
// 							className="btn__solid btn-site angled"
// 							onClick={() => window.open("http://vc.gitbal.io")}
// 						>
// 							{t("Landing.LandingHeader.landing-sidebar.vc-login")}
// 						</button>
// 					</div>
// 				</div>
// 			</OutsideAlerter>
// 		</>
// 	);
// };

// export default LandingHeader;
