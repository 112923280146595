import React from "react";
import { DateWithDayName } from "~/utils/formatter";

const List = (props) => {
	const {
		setVacationAllManageModal,
		setSelectedHistory,
		eventClassNameMap,
		eventTextMap,
		vacationList,
		currentPath,
		showAuthAlert,
	} = props;

	const handleClick = (h) => {
		if (currentPath.auth === "view") {
			showAuthAlert();
		} else {
			setVacationAllManageModal(true);
			setSelectedHistory(h);
		}
	};

	return (
		vacationList.histories.length > 0 &&
		vacationList.histories.map((h, h_idx) => (
			<div className="row" key={h_idx} onClick={() => handleClick(h)}>
				<div className="date all">{DateWithDayName(h.createdDate)}</div>
				<div className={eventClassNameMap[h.event] ?? "history"}>
					{eventTextMap(h)[h?.event]()}
				</div>
			</div>
		))
	);
};
export default List;
