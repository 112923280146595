import React, { useEffect } from "react";
import Form, { Group } from "~/common/components/Form";
import Input from "~/common/components/Input";
import { useTranslation } from "react-i18next";

const Link = ({ useForm, form, apply }) => {
	const { t } = useTranslation();
	useEffect(() => {
		useForm.setValue(form.order.toString(), form.answer);
	}, []);

	return (
		<>
			<Form useForm={useForm}>
				<Group label={form.title} required={form.required}>
					<Input name={form.order.toString()} required={form.required} readonly={apply} />
					<ul className="note-list">
						<li>{t("Invest.Round.RoundDetailModal.Edit.component.link.note-list")}</li>
					</ul>
				</Group>
			</Form>
		</>
	);
};

export default Link;
