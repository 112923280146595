import React, { useMemo } from "react";
import { useGlobalStore } from "~/store";
import { thousandFormatter } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const IncomeStatus = () => {
	const { t } = useTranslation();
	const [
		{
			ir: {
				finance: { sales, cost, sga, otherIncome, nonOperatingExpenses, tax },
			},
		},
	] = useGlobalStore();

	const GrossProfit = useMemo(() => +sales - +cost, [sales, cost]);

	const OperatingProfit = useMemo(() => GrossProfit - +sga, [GrossProfit, sga]);

	const OrdinaryProfit = useMemo(
		() => OperatingProfit + +otherIncome - +nonOperatingExpenses,
		[OperatingProfit, otherIncome, nonOperatingExpenses]
	);

	const NetIncome = useMemo(() => OrdinaryProfit - +tax, [OrdinaryProfit, tax]);

	return (
		<div className="f__group income-status-table">
			<div className="unit-wrap sub-title">
				{t("IR.IrDetail.Finance.Edit.IncomeStatus.title")}
				<span className="unit">
					{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
					{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
				</span>
			</div>
			<ul className="f__control">
				<li>
					<div className="title">
						{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title1")}
					</div>
					<div className="num">{thousandFormatter(sales)}</div>
				</li>
				<li>
					<div className="title">
						{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title2")}
					</div>
					<div className="num">{thousandFormatter(cost)}</div>
				</li>
				<li>
					<div className="title">
						{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title3")}
					</div>
					<div className="num">{thousandFormatter(GrossProfit)}</div>
				</li>
				<li>
					<div className="title">
						{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title4")}
					</div>
					<div className="num">{thousandFormatter(sga)}</div>
				</li>
				<li>
					<div className="title">
						{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title5")}
					</div>
					<div className="num">{thousandFormatter(OperatingProfit)}</div>
				</li>
				<li>
					<div className="title">
						{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title6")}
					</div>
					<div className="num">{thousandFormatter(otherIncome)}</div>
				</li>
				<li>
					<div className="title">
						{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title7")}
					</div>
					<div className="num">{thousandFormatter(nonOperatingExpenses)}</div>
				</li>
				<li>
					<div className="title">
						{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title8")}
					</div>
					<div className="num">{thousandFormatter(OrdinaryProfit)}</div>
				</li>
				<li>
					<div className="title">
						{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title9")}
					</div>
					<div className="num">{thousandFormatter(tax)}</div>
				</li>
				<li>
					<div className="title">
						{t("IR.IrDetail.Finance.Edit.IncomeStatus.list.title10")}
					</div>
					<div className="num">{thousandFormatter(NetIncome)}</div>
				</li>
			</ul>
		</div>
	);
};

export default IncomeStatus;
