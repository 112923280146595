import React, { useEffect, useState } from "react";
import { Case, If } from "~/common/components/If";

import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import Empty from "./Empty";
import View from "./View";

import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import { useNavigate } from "react-router";
import useRequest from "~/common/hooks/useRequest";
import Loading from "~/common/components/Loading";
import { useGlobalStore } from "~/store";
import { clearSelectedShareholder } from "~/store/shareholder/actions";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const ShareholderList = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [page, setPage] = useState(PAGE_STATE.LOADING);
	const [shareholderList, setShareholderList] = useState([]);
	const [, dispatch] = useGlobalStore();

	const headerButtons = [
		<AuthButton
			className="btn__solid"
			color="primary"
			onClick={() => navigate("/shareholder/add")}
		>
			{t("button.registering")}
		</AuthButton>,
	];

	const shareholderRequest = useRequest("get", "shareholder");

	useEffect(() => {
		shareholderRequest.singleCall();
		dispatch(clearSelectedShareholder());
		setPage(PAGE_STATE.LOADING);
	}, []);

	useEffect(() => {
		switch (shareholderRequest.state) {
			// case API_STATE.loading:
			// 	setPage(PAGE_STATE.LOADING);
			// 	break;
			case API_STATE.done:
				const response = shareholderRequest.response.data.sort((a, b) =>
					b.regDate.localeCompare(a.regDate)
				);

				if (response.length > 0) {
					setShareholderList(response);
					setPage(PAGE_STATE.VIEW);
				} else {
					setPage(PAGE_STATE.EMPTY);
				}
				break;
			default:
				break;
		}
	}, [shareholderRequest.state, shareholderRequest.response]);

	return (
		<NavbarAndSidebarLayout menu={["shareholder", "list"]} type="main">
			<PageHeader buttonList={headerButtons} />
			<If condition={page} {...{ setPage, shareholderRequest }}>
				<Case condition={PAGE_STATE.EMPTY}>
					<Empty />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<View {...{ shareholderList }} />
				</Case>
				<Case condition={PAGE_STATE.LOADING}>
					<Loading />
				</Case>
			</If>
		</NavbarAndSidebarLayout>
	);
};

export default ShareholderList;
