import React, { useEffect } from "react";
import { API_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";

// Store
import { useGlobalStore } from "~/store";
import { Okceologin } from "~/store/tax/actions";

// Components
import { Case, If, OtherWise } from "~/common/components/If";
import View from "~/pages/Tax/Dashboard/View";
import Empty from "~/pages/Tax/Dashboard/Empty";

const Dashboard = () => {
	const [
		{
			tax: { logged },
		},
		dispatch,
	] = useGlobalStore();
	const MENU = ["tax", "dashboard"];
	const TYPE = "main";

	const okceoConnectRequest = useRequest("get", "tax/check/is-connected");

	useEffect(() => {
		okceoConnectRequest.singleCall();
	});

	//okceo 연동 확인
	useEffect(() => {
		switch (okceoConnectRequest.state) {
			case API_STATE.done:
				const response = okceoConnectRequest.response.data.isTokenExist;
				if (response === true) {
					dispatch(
						Okceologin({ accessToken: okceoConnectRequest.response.data.okceoToken })
					);
				}
				break;
			case API_STATE.error:
				console.log("error");
				break;
			default:
				break;
		}
	}, [okceoConnectRequest.state, dispatch]);

	return (
		<NavbarAndSidebarLayout menu={MENU} type={TYPE}>
			<If condition={logged}>
				<Case condition={true}>
					<View />
				</Case>
				<OtherWise>
					<Empty />
				</OtherWise>
			</If>
		</NavbarAndSidebarLayout>
	);
};

export default Dashboard;
