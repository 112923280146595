import Form from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { VACATION_CLASSNAME } from "~/common/constants/form";
import { DateWithDayName, YearDateWithDayName } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const Modal = ({
	selectedHistory,
	setVacationAllManageModal,
	eventTextMap,
	timeText,
	onSubmit,
}) => {
	const { t } = useTranslation();
	const defaultValues = {
		memo: selectedHistory.memo,
	};
	const [modifyMemo, setModifyMemo] = useState(defaultValues.memo ? true : false);
	const vacationAllManageForm = useForm({ defaultValues: defaultValues });
	const eventsWithoutDateAndTime = ["Vplus", "Vminus", "autoVplus", "reset", "policyChange"];
	const HrVacationDateAndTime = selectedHistory.HrVacationDateAndTime;
	const HrAgreeName = selectedHistory?.Performer?.User?.UserProfile?.name;
	const HrAgreeDate = YearDateWithDayName(selectedHistory?.createdDate);

	const handleVacationAllManageModal = () => {
		setVacationAllManageModal(false);
	};

	const handleModifyMemo = () => {
		setModifyMemo(false);
	};

	return (
		<>
			<div className="modal-content">
				{eventsWithoutDateAndTime.includes(selectedHistory.event) ? (
					<div className="vacation-state">
						<div
							className={`history ${
								selectedHistory.event === "Vminus" ? "minus" : "plus"
							}`}
						>
							{eventTextMap(selectedHistory)[selectedHistory.event]()}
						</div>
					</div>
				) : (
					<div className="vacation-state">
						<div
							className={`vacation-name ${
								VACATION_CLASSNAME[HrVacationDateAndTime.HrVacation?.type]
							}`}
						>
							{HrVacationDateAndTime.startDate === HrVacationDateAndTime.endDate
								? DateWithDayName(HrVacationDateAndTime.startDate)
								: `${DateWithDayName(HrVacationDateAndTime.startDate)}
							   ~ 
							  ${DateWithDayName(HrVacationDateAndTime.endDate)}`}
						</div>
						<div className="time">{timeText(HrVacationDateAndTime)}</div>
					</div>
				)}
				<div className="c__form vacation-manage-form vertical">
					<div className="f__group">
						<div className="f__label">승인 내역</div>
						<div className="f__control history-info">
							<span>{HrAgreeDate}</span>
							<span>{HrAgreeName ? HrAgreeName : "자동승인"}</span>
						</div>
					</div>
				</div>
				<Form useForm={vacationAllManageForm} className="vacation-manage-form vertical">
					<Textarea
						label={t("HR.Manage.admin-memo")}
						name="memo"
						readOnly={modifyMemo}
						placeholder={defaultValues.memo ? "" : "메모를 입력해주세요."}
					/>
				</Form>
			</div>
			<div className="modal-footer">
				{modifyMemo ? (
					<>
						<button className="btn__solid" color="gray" onClick={handleModifyMemo}>
							{t("button.modify")}
						</button>
						<button
							className="btn__solid"
							color="primary"
							onClick={handleVacationAllManageModal}
						>
							{t("button.check")}
						</button>
					</>
				) : (
					<>
						<button
							className="btn__solid"
							color="gray"
							onClick={handleVacationAllManageModal}
						>
							{t("button.cancel")}
						</button>
						<button
							className="btn__solid"
							color="primary"
							onClick={vacationAllManageForm.handleSubmit(onSubmit)}
						>
							{t("button.save")}
						</button>
					</>
				)}
			</div>
		</>
	);
};
export default Modal;
