import React from "react";
import PageHeader from "~/common/components/PageHeader";
import noImg from "~/assets/img/defaultImg/profile-large.png";
import AuthButton from "~/common/components/AuthButton";
import { Case, If, OtherWise } from "~/common/components/If";
import initImg from "~/assets/img/init-org.png";
import { useTranslation } from "react-i18next";

const ViewCard = ({ img, name, position, department, introduction }) => {
	const { t } = useTranslation();
	return (
		<div className="c__card">
			<div className="member-img">
				<img
					src={img || noImg}
					alt={t("Company.Member.View.img")}
					onError={(e) => {
						e.target.src = noImg;
					}}
				/>
			</div>
			<dl>
				<dt className="member-name">{name}</dt>
				<dd className="member-position">
					<span>{department}</span>
					<span>{position}</span>
				</dd>
				<dd className="member-career">{introduction}</dd>
			</dl>
		</div>
	);
};

const View = ({ showEditPage, menu, type, data }) => {
	const { t } = useTranslation();
	const buttonList = [
		<AuthButton
			type="button"
			className="btn__solid btn-edit"
			color="primary"
			onClick={showEditPage}
		>
			{data.length ? t("Company.Member.View.edit") : t("Company.Member.View.add")}
		</AuthButton>,
	];

	const users = () => {
		return data.map(({ img, name, department, position, introduction }, index) => (
			<ViewCard key={index} {...{ img, name, department, position, introduction }} />
		));
	};
	return (
		<>
			<PageHeader {...{ menu, type, buttonList }} />
			<If condition={data.length}>
				<Case condition={0}>
					<div className="page-init">
						<p className="main">{t("Company.Member.View.main")}</p>
						<p className="sub">{t("Company.Member.View.sub")}</p>
						<img src={initImg} alt={t("Company.Member.View.initImg")} />
					</div>
				</Case>
				<OtherWise>
					<div className="c__cardRow company-member-view">{users()}</div>
				</OtherWise>
			</If>
		</>
	);
};

export default View;
