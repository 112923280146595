import React from "react";
import Slider from "react-slick";
import noImg from "~/assets/img/defaultImg/default-img.png";

/**
 * Image slider component with 'react-slick'
 *
 * @param { Props } props
 *
 * @typedef Props
 * @property {{idx:number,img:string}[]} list - image src list
 */
const ImageSlider = ({ list }) => {
	const imageList = list === undefined || list.length === 0 ? [{ img: noImg }] : list;
	const settings = {
		customPaging: (i) => (
			<div className="img-wrap">
				<img
					src={imageList[i]?.img || noImg}
					alt="슬라이드"
					onError={(e) => {
						e.target.src = noImg;
					}}
				/>
			</div>
		),
		dots: true,
		arrows: false,
		dotsClass: "slick-dots slick-thumb",
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	const images = () => {
		return imageList.map((image, i) => (
			<div key={i}>
				<img
					src={image?.img || noImg}
					alt="슬라이드"
					onError={(e) => {
						e.target.src = noImg;
					}}
				/>
			</div>
		));
	};

	return (
		<Slider {...settings} className="product-carouse">
			{images()}
		</Slider>
	);
};

export default ImageSlider;
