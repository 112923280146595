import React, { useState } from "react";
// Assets
import { PAGE_STATE } from "~/common/constants/state";
import { useForm } from "react-hook-form";
import AnnualVacation from "~/pages/vacation/VacationListPage/ApplyVacationModal/Edit/annualVacation";
import CommonVacation from "~/pages/vacation/VacationListPage/ApplyVacationModal/Edit/commonVacation";
import { Case, If, OtherWise } from "~/common/components/If";
import { VACATION_UNIT } from "~/common/constants/form";
import { dayFormatter, durationToMinutesFormatter, dateToString } from "~/utils/formatter";
import { differenceInMinutes, format, setHours } from "date-fns";
import useRequest from "~/common/hooks/useRequest";
import useAlert from "~/common/hooks/useAlert";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Edit = ({
	setPageState,
	selectedVacation,
	workTime,
	restTime,
	workDays,
	vacationUnit,
	totalWorkMinutes,
	setVacationTypeListModal,
	getUpcomingVacation,
	defaultWorkTime,
	vacationTimeCountRequest,
	info,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const current = location.pathname;
	const applyVacationForm = useForm({
		defaultValues: {
			vacationTypeIdx: selectedVacation?.idx,
			memo: "",
			startDate: setHours(new Date(), 0),
			endDate: setHours(new Date(), 12),
		},
	});
	const [submitDisable, setSubmitDisable] = useState(false);
	const vacationRequest = useRequest("post", "vacation");

	const [overTime, setOverTime] = useState("");
	const [showSuccessAlert, SuccessAlert] = useAlert();
	const [showErrorAlert, ErrorAlert] = useAlert();
	const [showWarningAlert, WarningAlert] = useAlert();

	const remainingMinutes = durationToMinutesFormatter(
		selectedVacation.remainingTime,
		totalWorkMinutes
	);

	const submit = (data) => {
		if (selectedVacation.type === "연차") {
			checkAnnualOvertimeUse(data);
		} else {
			onSubmit(data);
		}
	};

	const vacationTotalMinutes = (data) => {
		const vacationTypeCount = (type) =>
			data.dateAndTime.filter((d) => d.timeUnit === type).length;

		const dayVacationMinutes = vacationTypeCount("0") * totalWorkMinutes;
		const halfVacationMinutes =
			(vacationTypeCount("1") + vacationTypeCount("2")) * (totalWorkMinutes / 2);

		let timeVacation = [];
		data.dateAndTime.forEach((d) => {
			if (d.timeUnit === "3") {
				timeVacation.push(differenceInMinutes(d.endTime, d.startTime));
			}
		});

		let timeVacationMinutes = timeVacation.reduce(function (accumulator, currentValue) {
			return accumulator + currentValue;
		}, 0);

		return dayVacationMinutes + halfVacationMinutes + timeVacationMinutes;
	};

	const checkAnnualOvertimeUse = (data) => {
		const total = vacationTotalMinutes(data);

		if (selectedVacation.remainingTime.toString().charAt(0) === "-") {
			setOverTime(dayFormatter(total, totalWorkMinutes));
			CheckOverUse(data);
			return;
		}
		if (total > remainingMinutes) {
			setOverTime(dayFormatter(total - remainingMinutes, totalWorkMinutes));
			CheckOverUse(data);
			return;
		}
		return onSubmit(data);
	};

	const CheckOverUse = (data) => {
		showWarningAlert()
			.then(async () => {
				onSubmit(data);
			})
			.catch(() => {});
	};

	const onSubmit = (data) => {
		let newData = [];
		data.dateAndTime.forEach((d) => {
			newData.push({
				timeUnit: VACATION_UNIT[d.timeUnit],
				startDate: dateToString(d.startDate, "yyyy-MM-dd"),
				endDate: dateToString(d.endDate, "yyyy-MM-dd"),
				startTime: format(d.startTime, "HH:mm"),
				endTime: format(d.endTime, "HH:mm"),
			});
		});

		const requestData = {
			vacationTypeIdx: data.vacationTypeIdx,
			memo: data.memo,
			file: data.file,
			dateAndTime: newData,
		};

		vacationRequest
			.asyncCall(requestData)
			.then(async (data) => {
				const currentUrl = "/vacation/request/expected";

				if (data.statusCode === 200) {
					await showSuccessAlert().then(() => {
						setVacationTypeListModal(false);
						if (current === currentUrl) {
							getUpcomingVacation();
						} else {
							navigate(currentUrl);
						}
						vacationTimeCountRequest.call(info.idx, "path");
					});
				} else {
					await showErrorAlert();
				}
			})
			.catch((msg) => console.log(msg));
	};

	return (
		<>
			<div className="modal-content">
				<If
					condition={selectedVacation?.type}
					useForm={applyVacationForm}
					{...{
						selectedVacation,
						workTime,
						restTime,
						workDays,
						vacationUnit,
						defaultWorkTime,
					}}
				>
					<Case condition={"연차"}>
						<AnnualVacation {...{ setSubmitDisable, setPageState }} />
					</Case>
					<OtherWise>
						<CommonVacation {...{ setSubmitDisable }} />
					</OtherWise>
				</If>
			</div>
			<div className="modal-footer">
				<button
					type="button"
					className="btn__solid"
					color="gray"
					onClick={() => setPageState(PAGE_STATE.VIEW)}
				>
					{t("button.back")}
				</button>
				<button
					type="button"
					className="btn__solid"
					color="primary"
					disabled={submitDisable}
					onClick={applyVacationForm.handleSubmit(submit)}
				>
					{t("button.apply")}
				</button>
			</div>
			<SuccessAlert
				message={t("HR.Vacation.ApplyVacationModal.Edit.SuccessAlert")}
				confirm={t("button.check")}
				type="success"
			/>
			<ErrorAlert
				message={t("HR.Vacation.ApplyVacationModal.Edit.ErrorAlert")}
				confirm={t("button.check")}
				type="error"
			/>
			<WarningAlert
				type="error"
				className="overtime-use-modal"
				layout="horiz"
				width={430}
				height={306}
				message={
					<>
						<h3>{t("HR.Vacation.ApplyVacationModal.Edit.WarningAlertH3")}</h3>
						<p>
							<b>{overTime}</b>
							{t("HR.Vacation.ApplyVacationModal.Edit.WarningAlertP")}
						</p>
					</>
				}
				desc={
					<div className="desc-box error">
						{t("HR.Vacation.ApplyVacationModal.Edit.WarningAlertDesc")}
					</div>
				}
				reject={t("button.cancel")}
				confirm={t("HR.Vacation.ApplyVacationModal.Edit.overuse")}
			/>
		</>
	);
};

export default Edit;
