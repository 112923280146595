import React, { useLayoutEffect, useRef, useState } from "react";
import { isSaturday, isSunday, isToday } from "date-fns";
import { ModifyIcon } from "~/assets/svg/TeamInfoIcon";
import { PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import { Case, If } from "~/common/components/If";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const CardRow = ({
	pageState,
	weeklySchedule,
	currentViewType,
	editScheduleModalToggle,
	isDayOff,
}) => {
	let canvasRef = useRef(null);
	const { t } = useTranslation();
	const [refWidth, setRefWidth] = useState();
	function getSpecificDay(day) {
		let dayClassName = [];

		if (isToday(day)) {
			dayClassName.push("today");
		}

		switch (true) {
			case isSaturday(day):
				dayClassName.push("sat");
				break;
			case isSunday(day):
				dayClassName.push("sun");
				break;
		}

		return dayClassName.join(" ");
	}

	useLayoutEffect(() => {
		if (canvasRef.current === null) return;
		setRefWidth(canvasRef.current.offsetWidth);
	}, [pageState]);

	return (
		<If condition={pageState}>
			<Case condition={PAGE_STATE.VIEW}>
				{weeklySchedule?.map((day, day_idx) => {
					return (
						<div className="row" key={day_idx}>
							<div className={`td date ${getSpecificDay(day.initDate)}`}>
								{day.formattedDate}
								{isToday(day.initDate) && (
									<span className="today-badge">
										{t("HR.Attendance.MySchedule.weeklyCard.btn-today")}
									</span>
								)}
							</div>
							{currentViewType === "Default" ? (
								<div className="td">
									<ul
										className="progress-step-bar"
										ref={(e) => (canvasRef.current = e)}
									>
										{day.schedule.map((s, s_idx) => {
											const barWidth =
												(refWidth / day.totalTime) * s.diffMinutes;
											return (
												<li
													key={s_idx}
													className={`vacation-name-bar ${s.type}`}
													style={
														day.totalTime && {
															flex: s.diffMinutes / day.totalTime,
														}
													}
												>
													{refWidth && (
														<div
															className={`time-badge-wrap ${
																barWidth > 120 ? "show" : "hide"
															}`}
														>
															<div
																className="time-badge"
																style={{
																	opacity: barWidth > 120 && "1",
																}}
															>
																{s.startTime} - {s.endTime}
															</div>
														</div>
													)}
												</li>
											);
										})}
									</ul>
								</div>
							) : (
								<ul className="td schedule-type">
									{isDayOff(day.initDate) && (
										<li className="vacation-name day-off">
											{t(
												"HR.Attendance.MySchedule.weeklyCard.vacation-name-dayOff"
											)}
										</li>
									)}
									{day.schedule.map((s, s_idx) => {
										console.log(day);
										return (
											<li key={s_idx} className={`vacation-name ${s.type}`}>
												{s.typeName} ({s.startTime} ~ {s.endTime})
											</li>
										);
									})}
								</ul>
							)}
							<div className="td modify-wrap">
								<button
									className="btn-modify"
									onClick={() => editScheduleModalToggle(day)}
								>
									<ModifyIcon />
								</button>
							</div>
						</div>
					);
				})}
			</Case>
			<Case condition={PAGE_STATE.LOADING}>
				<Loading />
			</Case>
		</If>
	);
};

export default CardRow;
