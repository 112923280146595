import React, { useEffect } from "react";

//Assets
import RectangleInitialImg from "~/assets/img/defaultImg/logo-rac.png";
import SquareInitialImg from "~/assets/img/defaultImg/logo-square.png";
import { useGlobalStore } from "~/store";
import useRepository from "~/common/hooks/useRepository";
import { COMPANY_INFO } from "~/store/company/repository";
import { Trans, useTranslation } from "react-i18next";

const Detail = ({ setPage }) => {
	const { t } = useTranslation();
	const [
		{
			company: { info },
		},
	] = useGlobalStore();

	const [getCompanyInfo] = useRepository(COMPANY_INFO);

	useEffect(() => {
		getCompanyInfo();
	}, []);

	return (
		<div className="c__card">
			<div className="logo-content">
				<h3 className="logo-content-title">
					{t("MyPage.admin.Logo.Detail.logo-content-title")}
				</h3>
				<p className="logo-content-limit">
					{t("MyPage.admin.Logo.Detail.logo-content-limit")}
				</p>
				<div className="logo-uploader-group">
					<div className="logo-uploader-container square">
						<p className="logo-type">
							<Trans
								i18nKey="MyPage.admin.Logo.Detail.logo-square"
								components={[<b />]}
							/>
						</p>
						<div className="logo-uploader">
							<div className="single-uploader no-img">
								<div className="upload-preview">
									<div className="uploader-wrap">
										<img
											src={info.logoSqu ? info.logoSqu : SquareInitialImg}
											onError={(e) => {
												e.target.src = SquareInitialImg;
											}}
											alt={t("MyPage.admin.Logo.Detail.no-logo-alt")}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="logo-uploader-container rectangle">
						<p className="logo-type">
							<Trans
								i18nKey="MyPage.admin.Logo.Detail.logo-rectangle"
								components={[<b />]}
							/>
						</p>
						<div className="logo-uploader">
							<div className="single-uploader no-img">
								<div className="upload-preview">
									<div className="uploader-wrap">
										<img
											src={info.logoRec ? info.logoRec : RectangleInitialImg}
											onError={(e) => {
												e.target.src = RectangleInitialImg;
											}}
											alt={t("MyPage.admin.Logo.Detail.no-logo-alt")}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="business-bottom">
				<button
					type="button"
					className="btn__solid"
					color="primary"
					onClick={() => setPage("edit")}
				>
					{t("button.Edit")}
				</button>
			</div>
		</div>
	);
};

export default Detail;
