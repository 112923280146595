import React, { useEffect, useState } from "react";
import useRequest from "~/common/hooks/useRequest";
import useRepository from "~/common/hooks/useRepository";
import * as env from "~/common/constants/env";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import { companyNumberFormatter, thousandFormatter } from "~/utils/formatter";
import storageService from "~/utils/storage";

// Store
import {
	OKCEO_ACCOUNT_DATA,
	OKCEO_COST_DATA,
	OKCEO_SALES_DATA,
	OKCEO_FLOW_DATA,
	OKCEO_PROFIT_DATA,
	OKCEO_DUTY_DATA,
} from "~/store/tax/repository";

// Components
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import EmptyCase from "~/common/components/EmptyCase";
import PageHeader from "~/common/components/PageHeader";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import ProfitCard from "~/pages/Tax/Dashboard/View/component/ProfitCard";
import AccountCard from "~/pages/Tax/Dashboard/View/component/AccountCard";
import SalesCard from "~/pages/Tax/Dashboard/View/component/ChartCard/SalesCard";
import CostCard from "~/pages/Tax/Dashboard/View/component/ChartCard/CostCard";
import TaxCard from "~/pages/Tax/Dashboard/View/component/TaxCard";
import FlowCard from "~/pages/Tax/Dashboard/View/component/ProfitCard/FlowCard";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export const OkceoNoContent = ({ okceoToken, selectedCompany }) => {
	const { t } = useTranslation();
	return (
		<div className="no-content">
			<p className="title">{t("Tax.Dashboard.View.no-content.title")}</p>
			<p className="desc">{t("Tax.Dashboard.View.no-content.desc")}</p>
			<button
				type="button"
				className="btn__outline angled"
				color="primary"
				onClick={() =>
					window.open(
						`${env.OKCEO_API_URL}/ssolink?token=${okceoToken}&cid=${selectedCompany}`
					)
				}
			>
				{t("Tax.btn-collection")}
			</button>
		</div>
	);
};

const View = ({ menu, type }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [myCompanyListModal, setMyCompanyListModal] = useState(false);
	const [okceoCompanyList, setOkceoCompanyList] = useState([true]);
	const [selectedCompany, setSelectedCompany] = useState();
	const [getAccountData] = useRepository(OKCEO_ACCOUNT_DATA);
	const [getCostData] = useRepository(OKCEO_COST_DATA);
	const [costSelectedYear, setCostSelectedYear] = useState("THIS_YEAR");
	const [getSalesData] = useRepository(OKCEO_SALES_DATA);
	const [salesSelectedYear, setSalesSelectedYear] = useState("THIS_YEAR");
	const [getFlowData] = useRepository(OKCEO_FLOW_DATA);
	const [flowSelectedYear, setFlowSelectedYear] = useState("THIS_YEAR");
	const [getProfitData] = useRepository(OKCEO_PROFIT_DATA);
	const [profitSelectedYear, setProfitSelectedYear] = useState("THIS_YEAR");
	const [getDutyData] = useRepository(OKCEO_DUTY_DATA);

	const closeModal = () => {
		setMyCompanyListModal(false);
	};

	const okceoToken = storageService.get("okceo.auth", "accessToken", "local");

	const buttonList = [
		<>
			<button
				type="button"
				className="btn__outline btn-company-list"
				color="primary"
				onClick={() => setMyCompanyListModal(true)}
			>
				{t("Tax.btn-company-list")}
			</button>
			<button
				type="button"
				className="btn__solid btn-okceo"
				color="primary"
				onClick={() =>
					window.open(
						`${env.OKCEO_API_URL}/ssolink?token=${okceoToken}&cid=${selectedCompany}`
					)
				}
			>
				{t("Tax.btn-okceo")}
			</button>
		</>,
	];

	//기업목록 조회
	const okceoCompanyRequest = useRequest("get", "tax/companies", "okceo");

	useEffect(() => {
		okceoCompanyRequest.call();
	}, []);

	useEffect(() => {
		switch (okceoCompanyRequest.state) {
			case API_STATE.done:
				if (okceoCompanyRequest.response.statusCode !== 200) {
					setPageState(PAGE_STATE.EMPTY);
					break;
				}
				const response = okceoCompanyRequest.response.data.companies;
				setOkceoCompanyList(response);
				//첫 번째 기업 선택
				if (response.length !== 0) {
					setSelectedCompany(response[0].id);
					okceoDashboardRequest.call(
						`?okceoCompanyId=${response[0].id}&periodType=THIS_YEAR`,
						"path"
					);
				} else {
					setPageState(PAGE_STATE.EMPTY);
				}
				break;
			case API_STATE.error:
				setPageState(PAGE_STATE.EMPTY);
			default:
				break;
		}
	}, [okceoCompanyRequest.state]);

	//대시보드 조회
	const okceoDashboardRequest = useRequest("get", "tax/dash-board", "okceo");

	useEffect(() => {
		switch (okceoDashboardRequest.state) {
			case API_STATE.done:
				getAccountData({ selectedCompany: selectedCompany });
				getCostData({ selectedCompany: selectedCompany, selectedYear: "THIS_YEAR" });
				setCostSelectedYear("THIS_YEAR");
				getSalesData({ selectedCompany: selectedCompany, selectedYear: "THIS_YEAR" });
				setSalesSelectedYear("THIS_YEAR");
				getFlowData({ selectedCompany: selectedCompany, selectedYear: "THIS_YEAR" });
				setFlowSelectedYear("THIS_YEAR");
				getProfitData({ selectedCompany: selectedCompany, selectedYear: "THIS_YEAR" });
				setProfitSelectedYear("THIS_YEAR");
				getDutyData({ selectedCompany: selectedCompany });
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				setPageState(PAGE_STATE.EMPTY);
				break;
			default:
				break;
		}
	}, [okceoDashboardRequest.state]);

	const handleClick = (id) => {
		setSelectedCompany(id);
	};

	const handleSubmit = () => {
		okceoDashboardRequest.call(
			`?okceoCompanyId=${selectedCompany}&periodType=THIS_YEAR`,
			"path"
		);
		closeModal();
	};

	return (
		<>
			<PageHeader {...{ menu, type, buttonList }} />
				<If condition={pageState} {...{ okceoToken, selectedCompany }}>
					<Case condition={PAGE_STATE.LOADING}>
						<Loading />
					</Case>
					<Case condition={PAGE_STATE.EMPTY}>
						<EmptyCase
							main={t("Tax.Dashboard.View.EmptyCase.main")}
							sub={t("Tax.Dashboard.View.EmptyCase.sub")}
						/>
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						<div className="c__cardRow">
							<AccountCard />
							<CostCard {...{ costSelectedYear, setCostSelectedYear }} />
							<SalesCard {...{ salesSelectedYear, setSalesSelectedYear }} />
							<FlowCard {...{ flowSelectedYear, setFlowSelectedYear }} />
							<ProfitCard {...{ profitSelectedYear, setProfitSelectedYear }} />
							<TaxCard />
						</div>
					</Case>
				</If>
			<ModalTemplate
				modalState={myCompanyListModal}
				setModalToggle={setMyCompanyListModal}
				title={t("Tax.Dashboard.View.ModalTemplate.modal-title")}
				width={780}
				height={402}
				className="company-list-modal"
			>
				<div className="modal-content">
					<div className="list-table">
						<div className="tr thead">
							<div className="th company">
								{t("Tax.Dashboard.View.ModalTemplate.company")}
							</div>
							<div className="th number">
								{t("Tax.Dashboard.View.ModalTemplate.number")}
							</div>
							<div className="th name">
								{t("Tax.Dashboard.View.ModalTemplate.name")}
							</div>
							<div className="th balance">
								{t("Tax.Dashboard.View.ModalTemplate.balance")}
							</div>
							<div className="th deposit">
								{t("Tax.Dashboard.View.ModalTemplate.deposit")}
							</div>
							<div className="th cost">
								{t("Tax.Dashboard.View.ModalTemplate.cost")}
							</div>
						</div>
						<div className="tbody">
							{okceoCompanyList.length !== 0 ? (
								okceoCompanyList.map((company, company_idx) => {
									return (
										<div
											className={`tr ${
												selectedCompany === company.id ? "active" : ""
											}`}
											key={company_idx}
											onClick={() => handleClick(company.id)}
										>
											<div className="td company">{company.name}</div>
											<div className="td number">
												{companyNumberFormatter(company.businessNumber)}
											</div>
											<div className="td name">{company.owner}</div>
											<div className="td balance">
												{thousandFormatter(company.withdrawalAmount)}
											</div>
											<div className="td deposit">
												{thousandFormatter(company.saleSupplyAmount)}
											</div>
											<div className="td cost">
												{thousandFormatter(company.purchaseSupplyAmount)}
											</div>
										</div>
									);
								})
							) : (
								<div className="no-content">
									{t("Tax.Dashboard.View.ModalTemplate.no-content")}
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button type="button" className="btn__solid" color="gray" onClick={closeModal}>
						{t("button.cancel")}
					</button>
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={handleSubmit}
					>
						{t("button.application")}
					</button>
				</div>
			</ModalTemplate>
		</>
	);
};

export default View;
