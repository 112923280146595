import React from "react";
import roundCase1 from "~/assets/img/landing/round-case1.jpg";
import roundCase2 from "~/assets/img/landing/round-case2.jpg";
import roundCase3 from "~/assets/img/landing/round-case3.jpg";
import enRoundCase1 from "~/assets/img/EN/round-case1_en.png";
import enRoundCase2 from "~/assets/img/EN/round-case2_en.png";
import enRoundCase3 from "~/assets/img/EN/round-case3_en.png";
import ventureSquareLogo from "~/assets/img/landing/logo-ventureSquare-rac.png";
import sunboLogo from "~/assets/img/landing/logo-sunbo.png";
import caseBoomco from "~/assets/img/landing/startupImg-boomco.png";
import caseHaas from "~/assets/img/landing/startupImg-haas.jpg";
import caseTeamEverywhere from "~/assets/img/landing/startupImg-everywhere.png";
import boomcoLogo from "~/assets/img/landing/logo-startup1.png";
import haasLogo from "~/assets/img/landing/logo-startup2.png";
import teamEverywhereLogo from "~/assets/img/landing/logo-startup9.png";
import translation from "~/translations";
import { Trans } from "react-i18next";
import i18next from "i18next";

export const langImg = i18next.language;

export const VcCase = [
	{
		idx: 1,
		img: (
			<img
				src={langImg === "ko" ? roundCase3 : enRoundCase3}
				alt={translation.t("Landing.LandingConstant.VcCase.common.alt")}
			/>
		),
		title: translation.t("Landing.LandingConstant.VcCase.1.title"),
		companyLogo: (
			<img
				src={ventureSquareLogo}
				alt={translation.t("Landing.LandingConstant.VcCase.common.logo")}
			/>
		),
		companyName: translation.t("Landing.LandingConstant.VcCase.1.companyName"),
		url: "https://www.venturesquare.net/",
		homepage: "venturesquare.net",
		desc: translation.t("Landing.LandingConstant.VcCase.1.desc"),
		period: translation.t("Landing.LandingConstant.VcCase.1.period"),
		place: translation.t("Landing.LandingConstant.VcCase.common.place"),
	},
	{
		idx: 2,
		img: (
			<img
				src={langImg === "ko" ? roundCase2 : enRoundCase2}
				alt={translation.t("Landing.LandingConstant.VcCase.common.alt")}
			/>
		),
		title: translation.t("Landing.LandingConstant.VcCase.2.title"),
		companyLogo: (
			<img
				src={sunboLogo}
				alt={translation.t("Landing.LandingConstant.VcCase.common.logo")}
			/>
		),
		companyName: translation.t("Landing.LandingConstant.VcCase.2.companyName"),
		url: "https://www.sunbonpartners.com/",
		homepage: "sunbonpartners.com",
		desc: translation.t("Landing.LandingConstant.VcCase.2.desc"),
		period: translation.t("Landing.LandingConstant.VcCase.2.period"),
		place: translation.t("Landing.LandingConstant.VcCase.common.place"),
	},
	{
		idx: 3,
		img: (
			<img
				src={langImg === "ko" ? roundCase1 : enRoundCase1}
				alt={translation.t("Landing.LandingConstant.VcCase.common.alt")}
			/>
		),
		title: translation.t("Landing.LandingConstant.VcCase.3.title"),
		companyLogo: (
			<img
				src={ventureSquareLogo}
				alt={translation.t("Landing.LandingConstant.VcCase.common.logo")}
			/>
		),
		companyName: translation.t("Landing.LandingConstant.VcCase.3.companyName"),
		url: "https://www.venturesquare.net/",
		homepage: "venturesquare.net",
		desc: translation.t("Landing.LandingConstant.VcCase.3.desc"),
		period: translation.t("Landing.LandingConstant.VcCase.3.period"),
		place: translation.t("Landing.LandingConstant.VcCase.common.place"),
	},
];

export const StartupCase = [
	{
		img: (
			<img
				src={caseTeamEverywhere}
				alt={translation.t("Landing.LandingConstant.StartupCase.common.alt")}
			/>
		),
		companyLogo: (
			<img
				src={teamEverywhereLogo}
				alt={translation.t("Landing.LandingConstant.StartupCase.common.logo")}
			/>
		),
		title: (
			<Trans
				i18nKey="Landing.LandingConstant.StartupCase.1.title"
				components={[<b className="blue" />]}
			/>
		),
		desc: translation.t("Landing.LandingConstant.StartupCase.1.desc"),
		companyInfo: translation.t("Landing.LandingConstant.StartupCase.1.companyInfo"),
		serviceInfo: translation.t("Landing.LandingConstant.StartupCase.1.serviceInfo"),
	},
	{
		img: (
			<img
				src={caseBoomco}
				alt={translation.t("Landing.LandingConstant.StartupCase.common.alt")}
			/>
		),
		companyLogo: (
			<img
				src={boomcoLogo}
				alt={translation.t("Landing.LandingConstant.StartupCase.common.logo")}
			/>
		),
		title: (
			<Trans
				i18nKey="Landing.LandingConstant.StartupCase.2.title"
				components={[<b className="orange" />]}
			/>
		),
		desc: translation.t("Landing.LandingConstant.StartupCase.2.desc"),
		companyInfo: translation.t("Landing.LandingConstant.StartupCase.2.companyInfo"),
		serviceInfo: translation.t("Landing.LandingConstant.StartupCase.2.serviceInfo"),
	},
	{
		img: (
			<img
				src={caseHaas}
				alt={translation.t("Landing.LandingConstant.StartupCase.common.alt")}
			/>
		),
		companyLogo: (
			<img
				src={haasLogo}
				alt={translation.t("Landing.LandingConstant.StartupCase.common.logo")}
			/>
		),
		title: (
			<Trans
				i18nKey="Landing.LandingConstant.StartupCase.3.title"
				components={[<b className="sky" />]}
			/>
		),
		desc: translation.t("Landing.LandingConstant.StartupCase.3.desc"),
		companyInfo: translation.t("Landing.LandingConstant.StartupCase.3.companyInfo"),
		serviceInfo: translation.t("Landing.LandingConstant.StartupCase.3.serviceInfo"),
	},
];
