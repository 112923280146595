import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";

import "~/assets/sass/pages/ir/list.scss";
import "~/assets/sass/pages/ir/modal/IrModal.scss";
import useRequest from "~/common/hooks/useRequest";
import useAlert from "~/common/hooks/useAlert";
import { useNavigate } from "react-router";
import { useGlobalStore } from "~/store";
import { selectIr } from "~/store/ir/actions";
import { CautionIcon } from "~/assets/svg/InterfacesIcon";
import IrCard from "~/pages/ir/IrList/View/IrCard";
import { Trans, useTranslation } from "react-i18next";

const View = ({ irListRequest, irList }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [, dispatch] = useGlobalStore();

	const [pwModal, setPwModal] = useState(false);
	const [pwSetupModal, setPwSetupModal] = useState(false);
	const [pwChangeModal, setPwChangeModal] = useState(false);
	const [pwResetModal, setPwResetModal] = useState(false);

	const pwRequest = useRequest("post", "ir/pw/check");

	const pwSetRequest = useRequest("post", "ir/pw/set");

	const [showWrongAlert, WrongAlert] = useAlert();
	const pwChangeRequest = useRequest("post", "ir/pw/edit");

	const pwResetRequest = useRequest("post", "ir/pw/reset");

	const [showDeleteAlert, DeleteAlert] = useAlert();
	const deleteRequest = useRequest("delete", "ir/delete");

	const passwordForm = useForm();

	const [alertMsg, setAlertMsg] = useState("");
	const [showSuccessAlert, SuccessAlert] = useAlert();

	const irCardList = () => {
		return irList.map(({ idx, title, updateDate, lock, status, color }) => {
			return (
				<IrCard
					key={idx}
					idx={idx}
					title={title}
					date={updateDate}
					lock={lock}
					color={color}
					onClick={() => {
						dispatch(selectIr(idx));
						if (lock === false) {
							navigate(`/ir/${idx}/basic`);
						} else {
							setPwModal(true);
						}
					}}
					status={status}
					onSelect={() => {
						passwordForm.reset();
						passwordForm.setValue("irIdx", idx);
					}}
					onPwSetup={() => setPwSetupModal(true)}
					onPwChange={() => setPwChangeModal(true)}
					onPwReset={() => setPwResetModal(true)}
					onDelete={() => {
						showDeleteAlert()
							.then(async () => {
								await deleteRequest.asyncCall(idx, "path");
								await irListRequest.asyncCall();
							})
							.catch(() => {});
					}}
					irListRequest={irListRequest}
				/>
			);
		});
	};

	const submitPassword = () => {
		passwordForm.handleSubmit((data) => {
			pwRequest
				.asyncCall(data)
				.then(() => {
					navigate(`/ir/${data.irIdx}`);
				})
				.catch(() => {
					showWrongAlert();
				});
		})();
	};

	return (
		<>
			<div className="pageIntro">
				<CautionIcon />
				<div className="content">
					<h3>
						<Trans i18nKey="IR.IrList.View.Index.contente.title" components={[<b />]} />
					</h3>
					<p className="pre">{t("IR.IrList.View.Index.contente.text")}</p>
				</div>
			</div>
			<div className="c__cardRow">{irCardList()}</div>

			{/*드롭다운 관리자메뉴 모달*/}
			{/*----- 암호입력*/}
			<ModalTemplate
				modalState={pwModal}
				setModalToggle={setPwModal}
				title={t("Shareholder.ShareholderList.View.Index.pwInsert")}
				width={500}
				className="password-form"
			>
				<div className="modal-content">
					<Form useForm={passwordForm}>
						<Input
							name={"pw"}
							label={"password"}
							type={"password"}
							validation={{
								required: t("Shareholder.ShareholderList.View.Index.pwInsertMsg"),
							}}
							onEnter={submitPassword}
						/>
					</Form>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => setPwModal(false)}
					>
						{t("button.cancel")}
					</button>
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={submitPassword}
					>
						{t("button.check")}
					</button>
				</div>
			</ModalTemplate>
			{/*----- 암호설정*/}
			<ModalTemplate
				modalState={pwSetupModal}
				setModalToggle={setPwSetupModal}
				title={t("Shareholder.ShareholderList.View.Index.pwSet")}
				width={500}
				className="password-form"
			>
				<div className="modal-content">
					<Form useForm={passwordForm}>
						<Input
							name={"pw"}
							label={"password"}
							type={"password"}
							validation={{
								required: t("Shareholder.ShareholderList.View.Index.pwInsertMsg"),
							}}
						/>
					</Form>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => setPwSetupModal(false)}
					>
						{t("button.cancel")}
					</button>
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={passwordForm.handleSubmit(async (data) => {
							await pwSetRequest.asyncCall(data);
							await irListRequest.asyncCall();
							setAlertMsg(t("Shareholder.ShareholderList.View.Index.setAlertMsg"));
							showSuccessAlert().then(() => setPwSetupModal(false));
						})}
					>
						{t("button.check")}
					</button>
				</div>
			</ModalTemplate>

			{/*----- 암호변경*/}
			<ModalTemplate
				modalState={pwChangeModal}
				setModalToggle={setPwChangeModal}
				title={t("Shareholder.ShareholderList.View.Index.pwChange")}
				width={500}
				className="password-form"
			>
				<div className="modal-content">
					<Form useForm={passwordForm}>
						<Input
							name={"pw"}
							label={"password"}
							type={"password"}
							validation={{
								required: t("Shareholder.ShareholderList.View.Index.pwInsertMsg"),
							}}
						/>
						<Input
							name={"newPw"}
							label={"new password"}
							type={"password"}
							validation={{
								required: t(
									"Shareholder.ShareholderList.View.Index.newPwInsertMsg"
								),
							}}
						/>
					</Form>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => setPwChangeModal(false)}
					>
						{t("button.cancel")}
					</button>
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={passwordForm.handleSubmit((data) => {
							pwChangeRequest
								.asyncCall(data)
								.then(async () => {
									await irListRequest.asyncCall();
									setAlertMsg(
										t("Shareholder.ShareholderList.View.Index.setNewPwAlertMsg")
									);
									showSuccessAlert().then(() => setPwChangeModal(false));
								})
								.catch(() => {
									showWrongAlert();
								});
						})}
					>
						{t("button.check")}
					</button>
				</div>
			</ModalTemplate>

			{/*----- 암호 초기화*/}
			<ModalTemplate
				modalState={pwResetModal}
				setModalToggle={setPwResetModal}
				title={t("Shareholder.ShareholderList.View.Index.pwClear")}
				width={500}
				className="password-form"
			>
				<div className="modal-content">
					<p className="modal-content-text">
						{t("Shareholder.ShareholderList.View.Index.pwClearMsg")}
					</p>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn__solid"
						color="primary"
						onClick={passwordForm.handleSubmit(async (data) => {
							await pwResetRequest.asyncCall(data.irIdx, "path");
							await irListRequest.asyncCall();
							setAlertMsg(
								t("Shareholder.ShareholderList.View.Index.setPwClearAlertMsg")
							);
							showSuccessAlert().then(() => setPwResetModal(false));
						})}
					>
						{t("button.check")}
					</button>
				</div>
			</ModalTemplate>

			{/* TODO: merge alerts (get message params on 'show' function) */}
			<WrongAlert
				type="warning"
				message={t("Shareholder.ShareholderList.View.Index.wrongPwMsg")}
				confirm={t("button.close")}
			/>
			<DeleteAlert
				message={t("IR.IrList.View.Index.deleteMsg")}
				confirm={t("button.check")}
				reject={t("button.cancel")}
			/>
			<SuccessAlert message={alertMsg} />
		</>
	);
};
export default View;
