import React from "react";
import { PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import { Case, If } from "~/common/components/If";
import { useTranslation } from "react-i18next";

const ActivityCount = ({ data, pageState, auth }) => {
	const { t } = useTranslation();
	const valueRenderer = (value) => {
		if (value === undefined) return <Loading />;
		return (
			<>
				<b>{value}</b>
				{t("Dashboard.Contents.ActivityCount.active-state-item.valueRender")}
			</>
		);
	};

	const ActivityTemplate = ({ invest, round, connect }) => {
		return (
			<ul className="active-state-card">
				<li className="active-state-item">
					<div className="title">
						{t("Dashboard.Contents.ActivityCount.active-state-item.invest-title")}
					</div>
					{auth.evaluate === "edit" || auth.admin ? (
						<div className="count">{valueRenderer(invest)}</div>
					) : (
						<div className="count no-auth">
							{t("Dashboard.Contents.ActivityCount.active-state-item.no-auth")}
						</div>
					)}
				</li>
				<li className="active-state-item">
					<div className="title">
						{t("Dashboard.Contents.ActivityCount.active-state-item.show-title")}
					</div>
					{auth.admin ? (
						<div className="count">{valueRenderer(0)}</div>
					) : (
						<div className="count no-auth">
							{t("Dashboard.Contents.ActivityCount.active-state-item.no-auth")}
						</div>
					)}
				</li>
				<li className="active-state-item">
					<div className="title">
						{t("Dashboard.Contents.ActivityCount.active-state-item.round-title")}
					</div>
					{auth.round === "edit" || auth.admin ? (
						<div className="count">{valueRenderer(round)}</div>
					) : (
						<div className="count no-auth">
							{t("Dashboard.Contents.ActivityCount.active-state-item.no-auth")}
						</div>
					)}
				</li>
				<li className="active-state-item">
					<div className="title">
						{t("Dashboard.Contents.ActivityCount.active-state-item.investment-title")}
					</div>
					{auth.admin ? (
						<div className="count">{valueRenderer(connect)}</div>
					) : (
						<div className="count no-auth">
							{t("Dashboard.Contents.ActivityCount.active-state-item.no-auth")}
						</div>
					)}
				</li>
			</ul>
		);
	};

	return (
		<article className="active-state">
			<div className="article-title">
				{t("Dashboard.Contents.ActivityCount.article-title")}
			</div>
			<If condition={pageState}>
				<Case condition={PAGE_STATE.LOADING}>
					<ActivityTemplate />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<ActivityTemplate
						invest={data.invest}
						round={data.round}
						connect={data.connect}
					/>
				</Case>
			</If>
		</article>
	);
};

export default ActivityCount;
