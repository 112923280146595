import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UPLOAD_TYPE } from "~/common/constants/upload";

import Input from "~/common/components/Input";
import Form, { Group } from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import PageHeader from "~/common/components/PageHeader";

//assets
import "~/assets/sass/pages/community/communityRegister.scss";
import { useNavigate } from "react-router-dom";
import useAlert from "~/common/hooks/useAlert";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";

import useRequest from "~/common/hooks/useRequest";
import SingleImageUploader from "~/common/components/FormImageUploader/SingleImageUploader";
import Toggle from "~/common/components/Toggle";
import { useAtomValue } from "jotai";
import { CurrentBoardAtom } from "~/atoms/Community";
import { ObjectIsNotEmpty } from "~/utils/object";
import { Trans, useTranslation } from "react-i18next";

const Edit = ({ menu, type }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isEdit, setIsEdit] = useState(false);
	const currentBoard = useAtomValue(CurrentBoardAtom);
	const communityForm = useForm({
		defaultValues: {
			name: currentBoard?.name,
			description: currentBoard?.description,
			imgPath: currentBoard?.imgPath,
			allowFile: currentBoard?.allowFile,
			isAccessLevelPublic: currentBoard?.isAccessLevelPublic,
		},
	});

	useEffect(() => {
		setIsEdit(ObjectIsNotEmpty(currentBoard));
	}, []);

	const boardUpdateRequest = useRequest(
		isEdit ? "put" : "post",
		`community/${isEdit ? currentBoard?.idx : ""}`
	);

	const submit = (data) => {
		const requestData = {
			name: data?.name,
			description: data?.description,
			imgPath: data?.imgPath,
			settings: [
				{
					type: "ALLOW_FILE_UPLOAD",
					value: data.allowFile
						? "ALLOW_FILE_UPLOAD_ALLOW"
						: "ALLOW_FILE_UPLOAD_NOT_ALLOW",
				},
				{
					type: "ACCESS_LEVEL",
					value: data.isAccessLevelPublic
						? "ACCESS_LEVEL_PUBLIC"
						: "ACCESS_LEVEL_PRIVATE",
				},
			],
		};
		if (isEdit) {
			requestData.settings.find((s) => s.type === "ALLOW_FILE_UPLOAD").idx = Number(
				currentBoard.Settings?.find((setting) => setting.type === "ALLOW_FILE_UPLOAD")?.idx
			);
			requestData.settings.find((s) => s.type === "ACCESS_LEVEL").idx = Number(
				currentBoard.Settings?.find((setting) => setting.type === "ACCESS_LEVEL")?.idx
			);
		}

		boardUpdateRequest
			.asyncCall(requestData, "body")
			.then(() => {
				navigate("/community/list");
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const [showCancelAlert, CancelAlert] = useAlert();

	const headerButton = [
		<button
			className="btn__solid"
			color={"gray"}
			onClick={() => {
				showCancelAlert().then(() => navigate("/community/list"));
			}}
		>
			{t("button.cancel")}
		</button>,
		<button
			className="btn__solid"
			color={"primary"}
			onClick={communityForm.handleSubmit(submit)}
		>
			{isEdit ? t("button.modify") : t("button.save")}
		</button>,
	];

	return (
		<>
			<NavbarAndSidebarLayout menu={["community", "community"]} type="main">
				<PageHeader {...{ menu, type }} buttonList={headerButton} />
				<div className="c__card community-register-card">
					<Form useForm={communityForm} className={"Community-ListForm"}>
						<Input
							name="name"
							placeholder={t("Community.BoardPage.BoardList.Edit.input-placeholder")}
							required
						/>
						<Textarea
							name="description"
							placeholder={t(
								"Community.BoardPage.BoardList.Edit.textarea-placeholder"
							)}
							required
						/>
						<SingleImageUploader
							label={t(
								"Community.BoardPage.BoardList.Edit.SingleImageUploader-label"
							)}
							name="imgPath"
							type={UPLOAD_TYPE.COMMUNITY_IMAGE}
							// img={currentBoard?.imgPath}
							height="104px"
							width="165px"
							className={"community-img"}
						/>
						<Group className="switch-group">
							<label className="switch-label">
								{t("Community.BoardPage.BoardList.Edit.switch-label1")}
							</label>
							<Toggle name="allowFile" defaultValue={true} />
						</Group>
						<Group className="switch-group">
							<label className="switch-label">
								{t("Community.BoardPage.BoardList.Edit.switch-label2")}
							</label>
							<Toggle name="isAccessLevelPublic" defaultValue={false} />
						</Group>
					</Form>
				</div>
			</NavbarAndSidebarLayout>
			<CancelAlert
				message={
					<>
						{t("Community.BoardPage.BoardList.Edit.CancelAlert1")}{" "}
						{isEdit
							? t("Community.BoardPage.BoardList.Edit.isEdit.Edit")
							: t("Community.BoardPage.BoardList.Edit.isEdit.Register")}
						{t("Community.BoardPage.BoardList.Edit.CancelAlert2")}
					</>
				}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
		</>
	);
};

export default Edit;
