import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import Checkbox from "~/common/components/Checkbox";
import Radio from "~/common/components/Radio";
import { useForm } from "react-hook-form";
import useRequest from "~/common/hooks/useRequest";
import useAlert from "~/common/hooks/useAlert";
import { useGlobalStore } from "~/store";
import { API_STATE } from "~/common/constants/state";

const AddVacationModal = ({
	setVacationAddModal,
	getVacationTypeList,
	getModalVacationTypeList,
}) => {
	const navigate = useNavigate();
	const [
		{
			hrSetting: { selectedVacation },
		},
	] = useGlobalStore();

	const vacationAddForm = useForm({
		defaultValues: {
			...selectedVacation,
		},
	});

	const vacationSubmitRequest = useRequest(
		selectedVacation.idx !== "" ? "put" : "post",
		"vacation/type"
	);

	const vacationSubmit = (formData) => {
		const payload = {
			...formData,
			days: Number(formData.days),
		};

		showAlert().then(() => vacationSubmitRequest.asyncCall(payload, "body"));
	};

	useEffect(() => {
		switch (vacationSubmitRequest.state) {
			case API_STATE.done:
				getVacationTypeList();
				getModalVacationTypeList();
				setVacationAddModal(false);
				break;
			case API_STATE.error:
				console.log("error", vacationSubmitRequest.error);
				// navigate("/404");
				break;
			default:
				break;
		}
	}, [vacationSubmitRequest.state, vacationSubmitRequest.done]);

	const [showAlert, Alert] = useAlert();

	return (
		<>
			<div className="modal-content">
				<Form useForm={vacationAddForm}>
					<Input
						label="휴가 이름"
						name="title"
						validation={{
							required: "휴가 이름을 입력해주세요",
						}}
						required
						readonly={selectedVacation.type === "법정필수휴가"}
					/>
					<Input
						label="지급일"
						name="days"
						unit="일"
						type={"number"}
						validation={{
							required: "지급일을 입력해주세요",
							pattern: {
								value: /^[0-9]{1,3}$|0.5/,
								message: "0.5 이외 다른 소수점 및 음수는 입력할 수 없습니다",
							},
						}}
						required
					/>
					<p className="desc">
						※ 0.5 입력 시 반차 단위 휴가 지급이 가능 합니다.
						<br />
						<span>0.5 이외 다른 소수점 및 음수는 입력할 수 없습니다.</span>
					</p>
					<Checkbox
						label="지급 대상"
						name="employeeType"
						options={[
							{ label: "정규직", value: "정규직" },
							{ label: "계약직", value: "계약직" },
							{ label: "아르바이트", value: "파트타임" },
						]}
						labelName="label"
						className="checkbox-wrap"
					/>
					<Radio
						label="휴일포함"
						name="weekend"
						options={[
							{ label: "미포함", value: false },
							{ label: "포함", value: true },
						]}
						boolean
					/>
					<Radio
						label="연차 지급 주기"
						name="refresh"
						className="divided3"
						options={[
							{ label: "매년", value: "year" },
							{ label: "매월", value: "month" },
							{ label: "즉시", value: "instant" },
						]}
						boolean
					/>
					<Radio
						label="연속 사용"
						name="continuous"
						options={[
							{ label: "필수", value: true },
							{ label: "선택", value: false },
						]}
						boolean
					/>
					<Radio
						label="급여 지급"
						name="paid"
						options={[
							{ label: "유급", value: true },
							{ label: "무급", value: false },
						]}
						boolean
					/>
					<Radio
						label="적용 성별"
						name="gender"
						className="divided3"
						options={[
							{ label: "모두", value: "A" },
							{ label: "남자만", value: "M" },
							{ label: "여자만", value: "F" },
						]}
						boolean
					/>
					<Radio
						label="증명자료 제출"
						name="documents"
						className="divided3"
						options={[
							{ label: "안함", value: "none" },
							{ label: "사전 제출", value: "pre" },
							{ label: "사후 제출", value: "post" },
						]}
						boolean
					/>
					{/* TODO: 관리자 알림 설정 추후 개발 예정 */}
					{/*<Radio*/}
					{/*	label="관리자 알림 신청"*/}
					{/*	name="alarm"*/}
					{/*	options={[*/}
					{/*		{ label: "ON", value: true },*/}
					{/*		{ label: "OFF", value: false },*/}
					{/*	]}*/}
					{/*	boolean*/}
					{/*/>*/}
					<Radio
						label="관리자 자동 승인"
						name="autoConfirm"
						options={[
							{ label: "ON", value: true },
							{ label: "OFF", value: false },
						]}
						boolean
					/>
				</Form>
			</div>
			<div className="modal-footer">
				<button
					className="btn__solid"
					color="gray"
					onClick={() => setVacationAddModal(false)}
				>
					취소
				</button>
				<button
					className="btn__solid"
					color="primary"
					onClick={vacationAddForm.handleSubmit(vacationSubmit)}
				>
					{selectedVacation.idx !== "" ? "수정하기" : "추가하기"}
				</button>
			</div>
			<Alert
				message={`해당 휴가를 ${
					selectedVacation.idx !== "" ? "수정" : "추가"
				}하시겠습니까?`}
				reject={"취소"}
				confirm={"확인"}
				type={"info"}
			/>
		</>
	);
};
export default AddVacationModal;
