import React, { useEffect } from "react";
import { useController } from "react-hook-form";

import { LightPlusIcon } from "~/assets/svg/MathIcon";
import "~/assets/sass/component/imageUploader.scss";
import { API_STATE } from "~/common/constants/state";
import useUpload from "~/common/hooks/useUpload";
import useAlert from "~/common/hooks/useAlert";
import Loading from "../Loading";
import { Case, If, OtherWise } from "../If";

const MultiImageUploader = ({
	type,
	name,
	control,
	height = "inhert",
	width = "200px",
	maxCount,
}) => {
	const {
		field: { onChange, value },
	} = useController({
		control,
		name,
		defaultValue: [],
	});

	const { url, upload, state } = useUpload(type);

	const [showErrorAlert, ErrorAlert] = useAlert();

	useEffect(() => {
		switch (state) {
			case API_STATE.error:
				showErrorAlert();
				break;
			case API_STATE.done:
				onChange(value.concat(url));
				break;
			default:
				break;
		}
	}, [state]);

	const ImageList = () => {
		if (value?.length > 0) {
			return value.map((image, idx) => {
				const handleRemove = () => onChange(value.filter((_, i) => i !== idx));
				return (
					<div
						key={idx}
						className="upload-preview"
						style={{ height: height, width: width }}
					>
						<img src={image} alt="제품이미지" />
						<span className="upload-preview-actions">
							<button>
								<a href={image} target="_blank" rel="noreferrer">
									<i className="simple-icon-size-fullscreen" />
								</a>
							</button>
							<button onClick={handleRemove}>
								<i className="simple-icon-trash" />
							</button>
						</span>
					</div>
				);
			});
		} else {
			return <></>;
		}
	};

	const Uploader = () => {
		return (
			<>
				<input
					type="file"
					accept="image/*"
					name="file"
					onChange={({ target }) => target.files.length > 0 && upload(target.files)}
				/>
				<span className="uploader-actions">
					<LightPlusIcon />
					Upload
				</span>
			</>
		);
	};

	const Uploading = () => {
		return <Loading size={40} />;
	};

	return (
		<>
			<div className="multiple-uploader">
				{maxCount === undefined ||
					((value?.length ?? 0) < maxCount && (
						<div className="uploader-select" style={{ height: height, width: width }}>
							<If condition={state}>
								<Case condition={API_STATE.loading}>
									<Uploading />
								</Case>
								<OtherWise>
									<Uploader />
								</OtherWise>
							</If>
						</div>
					))}
				<ImageList />
			</div>
			<ErrorAlert
				message="이미지 업로드 중 에러가 발생했습니다."
				type="error"
				confirm="확인"
			/>
		</>
	);
};

export default MultiImageUploader;
