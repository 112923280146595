export const SET_CURRENT_ROUND = "round/SET_CURRENT_ROUND";
export const SET_ROUND_SURVEY = "round/SET_ROUND_SURVEY";

export const setCurrentRound = ({ round, faq, content, files }) => {
	return {
		type: SET_CURRENT_ROUND,
		payload: {
			info: round,
			detail: content,
			faq: faq,
			files: files,
			// inquiry: roundDetail.round.inquiry,
		},
	};
};

export const setRoundSurvey = ({ survey, answer }) => {
	return {
		type: SET_ROUND_SURVEY,
		payload: {
			survey: survey,
			answer: answer,
		},
	};
};
