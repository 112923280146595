import React from "react";
import EmptyCase from "~/common/components/EmptyCase";
import { useTranslation } from "react-i18next";
import AuthButton from "~/common/components/AuthButton";

const Empty = ({ Header, showEditPage }) => {
	const { t } = useTranslation();

	const buttonList = [
		<AuthButton type="button" className="btn__solid" color="primary" onClick={showEditPage}>
			{t("button.registering")}
		</AuthButton>,
	];

	return (
		<>
			<Header buttonList={buttonList} />
			<EmptyCase main={t("Company.Info.Empty.main")} sub={t("Company.Info.Empty.sub")} />
		</>
	);
};

export default Empty;
