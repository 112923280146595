import React from "react";
import Form from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import DynamicFields from "~/pages/ir/IrDetail/Product/Edit/TechSection/DynamicFields";
import { useTranslation } from "react-i18next";

const TechSection = ({ useForm }) => {
	const { t } = useTranslation();
	return (
		<>
			<div className="section-title">
				{t("IR.IrDetail.Product.Edit.TechSection.Index.tech")}
			</div>
			<Form useForm={useForm} className="sub-section vertical">
				<Textarea
					label={t("IR.IrDetail.Product.Edit.TechSection.Index.mainTech")}
					name="Product.mainTech"
				/>
				<Textarea
					label={t("IR.IrDetail.Product.Edit.TechSection.Index.advantage")}
					name="Product.advantages"
				/>
			</Form>
			<DynamicFields
				useForm={useForm}
				name="Ip"
				title={t("IR.IrDetail.Product.Edit.TechSection.Index.property")}
			/>
		</>
	);
};

export default TechSection;
