import React from "react";
import { useGlobalStore } from "~/store";
import { useForm } from "react-hook-form";
import MainPlan from "./MainPlan";
import Awards from "./Awards";
import DynamicFields from "~/pages/ir/IrDetail/Outcome/Edit/DynamicFields";
import useRequest from "~/common/hooks/useRequest";
import useRepository from "~/common/hooks/useRepository";
import { USER_INFO } from "~/store/user/repository";
import useAlert from "~/common/hooks/useAlert";
import { useTranslation } from "react-i18next";

const Edit = ({ showViewPage, Header }) => {
	const { t } = useTranslation();
	const [
		{
			ir: {
				selectedIdx: irIdx,
				outcome: {
					IROutcomeInvest: Invest,
					IROutcomeAward: Award,
					IROutcomeExport: Export,
					IROutcomeFund: Fund,
					IROutcomePlan: Plan,
				},
			},
		},
	] = useGlobalStore();
	const [getUserInfo] = useRepository(USER_INFO);

	const outcomeForm = useForm({
		defaultValues: { irIdx, Invest, Award, Export, Fund, Plan },
	});

	const irMutation = useRequest("post", "ir/outcome/edit");

	const submit = async (data) => {
		irMutation
			.asyncCall(data)
			.then(() => {
				getUserInfo().then(showViewPage);
			})
			.catch((msg) => console.log(msg));
	};

	const [showCancelAlert, CancelAlert] = useAlert();

	const buttonList = [
		<button
			type="button"
			className="btn__solid"
			color="gray"
			onClick={() => {
				showCancelAlert().then(() => showViewPage());
			}}
		>
			{t("button.cancel")}
		</button>,
		<button
			type="button"
			className="btn__solid"
			color="primary"
			onClick={outcomeForm.handleSubmit(submit)}
		>
			{t("button.save")}
		</button>,
	];

	return (
		<>
			<Header buttonList={buttonList} />
			<div className="ir-view">
				<div className="c__card outcome-edit">
					<div className="data-form">
						<section>
							<h3 className="section-title">
								{t("IR.IrDetail.Outcome.Edit.Index.title")}
							</h3>
							<div className="c__form vertical sub-section">
								<DynamicFields
									useForm={outcomeForm}
									name="Invest"
									title={t("IR.IrDetail.Outcome.Edit.Index.field1.title")}
									placeholder={{
										name: t("IR.IrDetail.Outcome.Edit.Index.field1.name"),
										value: t("IR.IrDetail.Outcome.Edit.Index.field1.value"),
									}}
								/>
								<Awards
									useForm={outcomeForm}
									title={t("IR.IrDetail.Outcome.Edit.Index.award")}
									name="Award"
								/>
							</div>
							<div className="c__form vertical sub-section">
								<DynamicFields
									useForm={outcomeForm}
									name="Export"
									title={t("IR.IrDetail.Outcome.Edit.Index.field2.title")}
									placeholder={{
										name: t("IR.IrDetail.Outcome.Edit.Index.field2.name"),
										value: t("IR.IrDetail.Outcome.Edit.Index.field2.value"),
									}}
								/>
								<DynamicFields
									useForm={outcomeForm}
									name="Fund"
									title={t("IR.IrDetail.Outcome.Edit.Index.field3.title")}
									placeholder={{
										name: t("IR.IrDetail.Outcome.Edit.Index.field3.name"),
										value: t("IR.IrDetail.Outcome.Edit.Index.field3.value"),
									}}
								/>
							</div>
						</section>
						<MainPlan useForm={outcomeForm} name="Plan" />
					</div>
				</div>
			</div>
			<CancelAlert
				message={t("IR.IrDetail.Outcome.Edit.Index.msg")}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
		</>
	);
};

export default Edit;
