import React, { useState, useEffect } from "react";
import { Case, If } from "~/common/components/If";
import TabMenu from "~/common/components/TabMenu";
import Info from "~/pages/invest/RoundPage/modal/RoundDetailModal/View/component/info";
import Detail from "~/pages/invest/RoundPage/modal/RoundDetailModal/View/component/detail";
import Faq from "~/pages/invest/RoundPage/modal/RoundDetailModal/View/component/faq";
import Inquiry from "~/pages/invest/RoundPage/modal/RoundDetailModal/View/component/inquiry";
import Edit from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit";
import "~/assets/sass/pages/invest/round/detailModal.scss";
import { useGlobalStore } from "~/store";
import { ArrowIcon } from "~/assets/svg/InterfacesIcon";
import { setRoundSurvey } from "~/store/round/actions";
import { useForm } from "react-hook-form";
import useAlert from "~/common/hooks/useAlert";
import { SolidCloseIcon } from "~/assets/svg/CloseIcon";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const View = ({ setModalToggle, getRoundList, roundStatus, ROUND_STATUS, isProductExist }) => {
	const { t } = useTranslation();
	const [
		{
			round: { info, survey },
		},
		dispatch,
	] = useGlobalStore();

	const [page, setPage] = useState("info");
	const [currentTab, setCurrentTab] = useState("detail");
	const tabMenuList = [
		{
			id: "detail",
			title: translation.t("Invest.Round.RoundDetailModal.View.tabMenuList.detail"),
		},
		{ id: "faq", title: "FAQ" },
		{
			id: "inquiry",
			title: translation.t("Invest.Round.RoundDetailModal.View.tabMenuList.inquiry"),
			disable: true,
		},
	];
	const [modalTitle, setModalTitle] = useState(
		<>{t("Invest.Round.RoundDetailModal.View.modalTitle")}</>
	);
	const [isAgree, setIsAgree] = useState(false);

	const surveyForm = useForm();
	const [showWarningAlert, WarningAlert] = useAlert();
	const [showExitAlert, ExitAlert] = useAlert();

	function filteredData(data) {
		let newData = [];

		survey.forEach((d) => {
			if (d.type === "CATEGORY" || d.type === "TEXT") {
				newData.push({ ...d, answer: "" });
			} else {
				newData.push({ ...d, answer: data[d.order] });
			}
		});

		return newData;
	}

	const viewInfoPage = () => {
		if (info.apply) {
			setPage("info");
		} else {
			const answer = surveyForm.watch();
			showWarningAlert().then(async () => {
				await dispatch(setRoundSurvey({ survey: filteredData(answer) }));
				setPage("info");
			});
		}
	};

	useEffect(() => {
		if (page === "form") {
			setModalTitle(
				<button onClick={viewInfoPage} className="prev-btn">
					<ArrowIcon />
					{t("button.prev")}
				</button>
			);
		} else {
			setModalTitle(<>{t("Invest.Round.RoundDetailModal.View.modalTitle")}</>);
		}
	}, [page]);

	const exitModal = () => {
		if (info.status === "진행중" && !info.apply && isAgree) {
			showExitAlert().then(() => setModalToggle(false));
		} else {
			setModalToggle(false);
		}
	};

	return (
		<>
			<div className="modal-header">
				<p className="modal-title">{modalTitle}</p>
				<button className="modal-close" onClick={exitModal}>
					<SolidCloseIcon />
				</button>
			</div>
			<If condition={page}>
				<Case condition={"info"}>
					<div className="modal-content round-information">
						<Info
							{...{
								setPage,
								roundStatus,
								ROUND_STATUS,
								isAgree,
								setIsAgree,
								isProductExist,
							}}
						/>
						<section className="rounding-tabs-wrap">
							<TabMenu
								tabItem={tabMenuList}
								currentTab={currentTab}
								setCurrentTab={setCurrentTab}
							/>
							<If condition={currentTab}>
								<Case condition={"detail"}>
									<Detail />
								</Case>
								<Case condition={"faq"}>
									<Faq />
								</Case>
								<Case condition={"inquiry"}>
									<Inquiry />
								</Case>
							</If>
						</section>
					</div>
				</Case>
				<Case condition={"form"}>
					<Edit
						{...{
							setModalToggle,
							getRoundList,
							filteredData,
							surveyForm,
							viewInfoPage,
						}}
					/>
				</Case>
			</If>
			<ExitAlert
				message={t("Invest.Round.RoundDetailModal.View.ExitAlert")}
				type="error"
				confirm={t("button.end")}
				reject={t("button.cancel")}
			/>
			<WarningAlert
				message={
					<>
						<h3>{t("Invest.Round.RoundDetailModal.View.WarningAlert.h3")}</h3>
						<p>{t("Invest.Round.RoundDetailModal.View.WarningAlert.p")}</p>
					</>
				}
				layout={"horiz"}
				type="info"
				confirm={t("button.check")}
				reject={t("button.cancel")}
			/>
		</>
	);
};

export default View;
