import React from "react";
import { VACATION_CLASSNAME, VACATION_STATUS, VACATION_STATUS_TEXT } from "~/common/constants/form";
import { DateWithDayName } from "~/utils/formatter";

const List = ({
	vacationList,
	setSelectedVacation,
	setVacationManageModal,
	titleText,
	timeText,
}) => {
	return vacationList.upcomingVacation.data.map((v, v_idx) => (
		<div
			className="row"
			onClick={() => {
				setSelectedVacation(v);
				setVacationManageModal(true);
			}}
			key={v_idx}
		>
			<div className={`vacation-name ${VACATION_CLASSNAME[v.HrVacation.title] ?? "others"}`}>
				{titleText(v)}
			</div>
			<div className="date">
				{v.startDate === v.endDate
					? DateWithDayName(v.startDate)
					: `${DateWithDayName(v.startDate)} ~ ${DateWithDayName(v.endDate)}`}
			</div>
			<div className="time">{timeText(v)}</div>
			{/*<div className="evidence">*/}
			{/*	<span className={`${fileClassName(v.HrVacation)}`}>{v.HrVacation.memo}</span>*/}
			{/*</div>*/}
			<div className="permission">
				<span className={VACATION_STATUS[v.status]}>
					{VACATION_STATUS_TEXT[VACATION_STATUS[v.status]]}
				</span>
			</div>
		</div>
	));
};
export default List;
