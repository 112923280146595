import React, { useState } from "react";
import { DartIcon } from "~/assets/svg/TeamInfoIcon";

import sampleThumbImg from "~/assets/img/sample/round1_thumb.png";
import { useTranslation } from "react-i18next";

const Rounds = () => {
	const { t } = useTranslation();
	const [roundList] = useState([]);
	const roundCard = () => {
		return (
			<>
				{/*진행중인 라운드*/}
				<div className="c__card">
					<div className="rounding-img">
						<img src={sampleThumbImg} alt={t("Invest.Round.RoundList.rounding-img")} />
					</div>
					<div className="rounding-container">
						<h4 className="rounding-content">
							블루포인트파트너스 엑셀러레이션 프로그램
						</h4>
						<div className="rounding-container-footer">
							<span className="update">2020-00-00</span>
							<button type="button" className="btn__solid" color="primary">
								참여하기
							</button>
						</div>
					</div>
				</div>
				{/*진행 마감 된 라운드*/}
				<div className="c__card apply-end">
					<div className="complete-card">
						<span>{t("Invest.Round.RoundList.round-deadline")}</span>
					</div>
					<div className="rounding-img">
						<img src={sampleThumbImg} alt={t("Invest.Round.RoundList.rounding-img")} />
					</div>
					<div className="rounding-container">
						<h4 className="rounding-content">2019 BLUEPOINT DEMODAY</h4>
						<div className="rounding-container-footer">
							<span className="update">2020-00-00</span>
							<button type="button" className="btn__solid" color="primary">
								참여하기
							</button>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<li className="round-notice">
			<h4 className="detail-info-title">{t("VcInfo.VcDetail.rounds.detail-info-title")}</h4>
			<div className="c__cardRow rounding-list">
				{roundList.length === 0 ? (
					<div className="c__card no-rounding-card">
						<DartIcon />
						<span className="phrase">{t("VcInfo.VcDetail.rounds.phrase")}</span>
					</div>
				) : (
					roundCard()
				)}
			</div>
		</li>
	);
};

export default Rounds;
