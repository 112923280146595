import React, { useEffect } from "react";
import { useGlobalStore } from "~/store";
import { useLocation } from "react-router-dom";
import useRequest from "~/common/hooks/useRequest";
import { mail } from "~/store/auth/actions";
import { useForm } from "react-hook-form";
import { API_STATE } from "~/common/constants/state";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";

const PwChangeForm = ({ setCurrentStep }) => {
	const [{ auth }, dispatch] = useGlobalStore();
	const location = useLocation();
	const pwChangeForm = useForm();
	const currentPassword = pwChangeForm.watch("pw");

	const token = location.search.split("?token=")[1];

	const passwordChangeRequest = useRequest("post", "user/pw/change");

	useEffect(() => {
		dispatch(mail({ accessToken: token }));
	}, [dispatch, token]);

	const request = (data) => {
		if (auth.accessToken === "") return;
		passwordChangeRequest.call({ pw: data.pw });
	};

	useEffect(() => {
		switch (passwordChangeRequest.state) {
			case API_STATE.done:
				setCurrentStep("complete");
				break;
			case API_STATE.error:
			default:
				if (!passwordChangeRequest.error) return;
				// switch (passwordChangeRequest.error.statusCode) {
				//     case 409:
				//         alert("중복된 아이디입니다.");
				//         break;
				//     case 500:
				//         alert("잠시 후 다시 시도해주세요.");
				//         break;
				//     default:
				//         break;
				// }
				console.log("error", passwordChangeRequest.error);
				break;
		}
	}, [
		passwordChangeRequest.state,
		passwordChangeRequest.response,
		passwordChangeRequest.error,
		setCurrentStep,
	]);

	return (
		<>
			<div className="phrase-wrap">
				<h3>새로운 비밀번호를 입력해주세요.</h3>
				<p>안전한 비밀번호로 내 정보를 보호하세요.</p>
			</div>
			<Form useForm={pwChangeForm} className="auth-form">
				<Input
					type={"password"}
					name={"pw"}
					placeholder="비밀번호 (8~16자의 영문 대/소문자, 숫자, 특수문자를 사용해주세요.)"
					autoComplete="new-password"
					validation={{
						required: "필수 입력 사항 입니다.",
						pattern: {
							value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/,
							message: "8~16자 영문 대소문자, 숫자, 특수문자를 사용해주세요.",
						},
					}}
				/>
				<Input
					placeholder="비밀번호 확인"
					name="rePw"
					type="password"
					autoComplete="new-password"
					validation={{
						required: "필수 입력 사항 입니다.",
						validate: (value) =>
							value === currentPassword || "비밀번호가 일치하지 않습니다.",
					}}
				/>
			</Form>
			<div className="btn-wrap">
				<button
					className="btn__solid"
					color="primary"
					onClick={pwChangeForm.handleSubmit(request)}
				>
					다음
				</button>
			</div>
		</>
	);
};
export default PwChangeForm;
