import React from "react";
import { useGlobalStore } from "~/store";
import { Trans, useTranslation } from "react-i18next";

const AcceptInfo = () => {
	const { t } = useTranslation();
	const [
		{
			evaluate: {
				currentEvaluate: {
					promotionUrl,
					businessPlan,
					businessFileName,
					irIdx,
					confirmDate,
				},
				irList,
			},
			sidebar: { currentPath },
		},
	] = useGlobalStore();

	return (
		<>
			<section className="c__card accept">
				<div className="top">
					<p className="phrase pre-wrap">
						<Trans
							i18nKey="Invest.Evaluate.EvaluateDetail.View.components.acceptInfo.phrase.after"
							components={[<b />]}
						/>
					</p>
					<ul className="c__form horiz submit-info">
						<li className="f__group">
							<p className="f__label">
								{t(
									"Invest.Evaluate.EvaluateDetail.View.components.acceptInfo.formIR.f__label"
								)}
							</p>
							<p className="f__control">{irList[irIdx]}</p>
						</li>
						<li className="f__group">
							<p className="f__label">
								{t(
									"Invest.Evaluate.EvaluateDetail.View.components.acceptInfo.formPlan.f__label"
								)}
							</p>
							<p className="f__control">
								{businessPlan !== "" &&
									(currentPath.auth === "edit" ? (
										<a href={businessPlan} target="_blank" rel="noreferrer">
											{businessFileName}
										</a>
									) : (
										t(
											"Invest.Evaluate.EvaluateDetail.View.components.acceptInfo.f__permission"
										)
									))}
							</p>
						</li>
						<li className="f__group">
							<p className="f__label">
								{t(
									"Invest.Evaluate.EvaluateDetail.View.components.acceptInfo.formURL.f__label"
								)}
							</p>
							<p className="f__control">
								{promotionUrl !== "" &&
									(currentPath.auth === "edit" ? (
										<a href={promotionUrl} target="_blank" rel="noreferrer">
											{promotionUrl}
										</a>
									) : (
										t(
											"Invest.Evaluate.EvaluateDetail.View.components.acceptInfo.f__permission"
										)
									))}
							</p>
						</li>
					</ul>
				</div>
				<div className="card-bottom">
					<h5>
						{t(
							"Invest.Evaluate.EvaluateDetail.View.components.acceptInfo.card-bottom.h5"
						)}
					</h5>
					<p>
						{t(
							"Invest.Evaluate.EvaluateDetail.View.components.acceptInfo.card-bottom.p"
						)}
						: {confirmDate}
					</p>
				</div>
			</section>
		</>
	);
};

export default AcceptInfo;
