import React, { useEffect, useState } from "react";
import { Case, If } from "~/common/components/If";

import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import View from "./View";

import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import Loading from "~/common/components/Loading";
import { useGlobalStore } from "~/store";
import { clearSelectedIr } from "~/store/ir/actions";
import useAlert from "~/common/hooks/useAlert";
import Empty from "~/pages/ir/IrList/Empty";

import { useNavigate } from "react-router";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const IrList = () => {
	const { t } = useTranslation();
	const [page, setPage] = useState(PAGE_STATE.LOADING);
	const [irList, setIrList] = useState([]);
	const [, dispatch] = useGlobalStore();

	const irListRequest = useRequest("get", "ir/list");

	const addIrMutation = useRequest("post", "ir/add");
	const [showAddAlert, AddAlert] = useAlert();

	const navigate = useNavigate();

	const handleAddIr = () => {
		showAddAlert()
			.then(async () => {
				navigate("/ir/add");
				addIrMutation.asyncCall();
				setPage(PAGE_STATE.LOADING);
			})
			.catch(() => {});
	};

	useEffect(() => {
		setTimeout(() => {
			irListRequest.singleCall();
		}, 500);

		dispatch(clearSelectedIr());
		setPage(PAGE_STATE.LOADING);
	}, []);

	const headerButtons = [
		<AuthButton className="btn__solid" color="primary" onClick={handleAddIr}>
			{t("IR.IrList.Index.create")}
		</AuthButton>,
	];

	useEffect(() => {
		switch (irListRequest.state) {
			// case API_STATE.loading:
			// 	setPage(PAGE_STATE.LOADING);
			// 	break;
			case API_STATE.done:
				const response = irListRequest.response.data;
				if (response.length === 0) {
					setPage(PAGE_STATE.EMPTY);
				} else {
					setIrList(response);
					setPage(PAGE_STATE.VIEW);
				}
				break;
			default:
				break;
		}
	}, [irListRequest.state, irListRequest.response]);

	return (
		<NavbarAndSidebarLayout menu={["ir", "list"]} type="main">
			<PageHeader buttonList={headerButtons} />
			<If condition={page} {...{ setPage, irListRequest }}>
				<Case condition={PAGE_STATE.EMPTY}>
					<Empty />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<View {...{ irList }} />
				</Case>
				<Case condition={PAGE_STATE.LOADING}>
					<Loading />
				</Case>
			</If>
			<AddAlert
				message={t("IR.IrList.Index.newIRmsg")}
				confirm={t("button.add")}
				reject={t("button.cancel")}
			/>
		</NavbarAndSidebarLayout>
	);
};

export default IrList;
