import React from "react";

//Assets
import "~/assets/sass/pages/hr/modal/common/detail/profile.scss";
import sampleMember from "~/assets/img/defaultImg/profile-large.png";
import { PhoneIcon } from "~/assets/svg/TeamInfoIcon";
import { MsgIcon } from "~/assets/svg/InterfacesIcon";
import useAlert from "~/common/hooks/useAlert";
import { useTranslation } from "react-i18next";

const ProfileView = ({ userDetail: { user } }) => {
	const { t } = useTranslation();
	const [showCopyAlert, CopyAlert] = useAlert();

	const copyClipBoard = async (e) => {
		try {
			await navigator.clipboard.writeText(e);
			showCopyAlert();
		} catch (error) {
			alert(t("HR.TeamInfo.modal.MemberDetailModal.components.Profile.CopyFailed"));
		}
	};

	return (
		<>
			<div className="teamInfo-profile detail">
				<div className="profile-img-view">
					<img
						src={user.img ?? sampleMember}
						onError={(e) => {
							e.target.src = sampleMember;
						}}
						alt={t("Img.profile")}
						className="profile-img"
					/>
					<img
						src=""
						alt={t("HR.TeamInfo.modal.MemberDetailModal.components.Profile.profileImg")}
					/>
				</div>
				<div className="name">
					{user.name}
					<span className="position">{user.position}</span>
				</div>
				<div
					className="profile-intro-view"
					style={{ whiteSpace: "pre-wrap", lineBreak: "anywhere" }}
				>
					{user.introduction
						? user.introduction
						: t("HR.TeamInfo.modal.MemberDetailModal.components.Profile.noData")}
				</div>
				<div className="contact">
					<button
						type="button"
						className="btn-contact"
						onClick={() => copyClipBoard(user.phone)}
						disabled={!user.phone}
					>
						<PhoneIcon />
					</button>
					<button
						type="button"
						className="btn-contact"
						onClick={() => copyClipBoard(user.id)}
					>
						<MsgIcon />
					</button>
				</div>
			</div>
			<CopyAlert
				message={t("HR.TeamInfo.modal.MemberDetailModal.components.Profile.CopyAlert")}
				type="success"
				confirm={t("button.check")}
			/>
		</>
	);
};

export default ProfileView;
