import React, { useEffect, useState } from "react";
import useRuquest from "~/common/hooks/useRequest";
import { useNavigate } from "react-router";
import { API_STATE } from "~/common/constants/state";
import useAlert from "~/common/hooks/useAlert";
import { useForm } from "react-hook-form";
import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import { useTranslation } from "react-i18next";

// Store
import { useGlobalStore } from "~/store";
import { login } from "~/store/auth/actions";

// Assets
import "~/assets/sass/pages/auth/login.scss";
import logo from "~/assets/img/logo.svg";
import useRequest from "~/common/hooks/useRequest";

const LoginForm = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [, dispatch] = useGlobalStore();
	const loginForm = useForm();
	const loginRequest = useRuquest("post", "user/login");
	const [showAlert, Alert] = useAlert();
	const [alertMsg, setAlertMsg] = useState("");
	const [showRetryAlert, RetryAlert] = useAlert();

	const submit = (data) => {
		loginRequest.call(data);
	};

	const registerRetryRequest = useRequest(
		"post",
		`/user/register/resend/${loginForm.watch().id}`
	);

	const retryRequest = () => {
		registerRetryRequest.call(loginForm.watch());
	};

	useEffect(() => {
		console.log("loginRequest -> ", loginRequest);
		switch (loginRequest.state) {
			case API_STATE.done:
				dispatch(login({ accessToken: loginRequest.response.data.accessToken }));
				navigate("/dashboard");
				break;
			case API_STATE.error:
				switch (loginRequest.error.statusCode) {
					case 400:
						setAlertMsg(t("Auth.Login.auth-form.AlertMsg.0"));
						showAlert();
						break;
					case 401:
						showRetryAlert().then(() => retryRequest());
						break;
					case 404:
						setAlertMsg(
							`가입되지 않은 계정입니다.\n아이디를 확인하거나 관리자에게 문의해주세요.`
						);
						showAlert();
						break;
					case 500:
						console.error(loginRequest.error);
						navigate("/404");
						break;
				}
				// showAlert();
				break;
			default:
				break;
		}
	}, [loginRequest.state, loginRequest.error, loginRequest.response, dispatch]);

	return (
		<>
			<h1 className="logo" onClick={() => navigate("/")}>
				<img src={logo} alt={t("Auth.Login.image-alt")} />
			</h1>
			<Form useForm={loginForm} className="auth-form">
				<Input
					placeholder={t("Auth.Login.auth-form.id.placeholder")}
					name="id"
					type="email"
					required
					onEnter={loginForm.handleSubmit(submit)}
				/>
				<Input
					placeholder={t("Auth.Login.auth-form.password.placeholder")}
					name="pw"
					type="password"
					validation={{
						minLength: {
							value: 8,
							message: t("Auth.Login.auth-form.password.validation"),
						},
					}}
					required
					onEnter={loginForm.handleSubmit(submit)}
				/>
			</Form>
			<div className="login-form-bottom">
				<div className="login-option">
					<div className="checkbox">
						<label className={`checkbox-custom`}>
							<input type="checkbox" />
							<span className="checkmark" />
							<span className="checkbox-label">
								{t("Auth.Login.auth-form.login-form.login-option")}
							</span>
						</label>
					</div>
					<button className="btn-pwd-find" onClick={() => navigate("/findpw/find")}>
						{t("Auth.Login.auth-form.login-form.btn-pwd-find")}
					</button>
				</div>
				<button
					className="btn__solid btn-login"
					color="primary"
					onClick={loginForm.handleSubmit(submit)}
				>
					{t("button.login")}
				</button>
			</div>
			<p className="register-btn-wrap">
				{t("Auth.Login.auth-form.register-btn-wrap.title")}
				<button className="btn-register" onClick={() => navigate("/join")}>
					{t("Auth.Login.auth-form.register-btn-wrap.btn-register")}
				</button>
			</p>
			<Alert message={alertMsg} confirm={t("button.check")} type="warning" />
			<RetryAlert
				message={t("Auth.Login.auth-form.AlertMsg.1")}
				confirm="재전송"
				reject="취소"
				type="warning"
			/>
		</>
	);
};

export default LoginForm;
