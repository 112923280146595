import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Radio from "~/common/components/Radio";
import Select from "~/common/components/Select";
import Form, { Group } from "~/common/components/Form";
import translation from "~/translations";

// Assets
import "~/assets/sass/pages/hr/setting/payday.scss";
import { QuestionMarkIcon } from "~/assets/svg/InterfacesIcon";
import TooltipTemplate from "~/common/components/TooltipPortal/TooltipTemplate";
import useRuquest from "~/common/hooks/useRequest";
import useAlert from "~/common/hooks/useAlert";
import { Trans, useTranslation } from "react-i18next";

const PaydayEdit = ({ showViewPage, Header, payday }) => {
	const { t } = useTranslation();
	const paydayForm = useForm({
		defaultValues: {
			payCycle: payday.payCycle,
			payDay: payday.payDay,
		},
	});

	const modifyPaydayRequest = useRuquest("post", "admin/payday");

	const submit = async (data) => {
		let requestData = {
			...data,
			payDay: data.payDay === "" ? 32 : data.payDay,
		};

		await modifyPaydayRequest.asyncCall(requestData, "body").then(() => {
			showViewPage();
		});
	};

	const [showCancelAlert, CancelAlert] = useAlert();

	const buttonList = [
		<button
			type="button"
			className="btn__solid"
			color="gray"
			onClick={() => {
				showCancelAlert().then(() => showViewPage());
			}}
		>
			{t("button.cancel")}
		</button>,
		<button
			type="button"
			className="btn__solid"
			color="primary"
			onClick={paydayForm.handleSubmit(submit)}
		>
			{t("button.check")}
		</button>,
	];

	const [lastDate, setLastDate] = useState(payday.lastDay);

	const handleLastDate = () => {
		setLastDate(!lastDate);
	};

	const dateOption = () => {
		let arr = [];
		for (let i = 1; i < 32; i++) {
			arr.push({ label: i + "일", value: i });
		}
		return arr;
	};

	useEffect(() => {
		if (lastDate) {
			paydayForm.setValue("payDay", "");
		} else {
			paydayForm.setValue("payDay", payday.payDay);
		}
	}, [lastDate]);

	return (
		<>
			<Header buttonList={buttonList} />
			<div className="c__card payday-card edit">
				<h3 className="title">
					{t("HR.HrSetting.PayDay.View.title")}
					<button type="button" className="c__tooltip-btn">
						<QuestionMarkIcon />
						<TooltipTemplate>{t("HR.HrSetting.PayDay.View.tooltip")}</TooltipTemplate>
					</button>
				</h3>
				<section>
					<p className="payday-title">{t("HR.HrSetting.PayDay.View.payday-title")}</p>
					<Form useForm={paydayForm} className="payday-wrap payday-edit-wrap">
						<Radio
							label={t("HR.HrSetting.PayDay.View.payday-wrap.0")}
							name="payCycle"
							options={[
								{
									label: translation.t(
										"HR.HrSetting.PayDay.Edit.padyday-edit-wrap.Radio.options.0"
									),
									value: "당월",
								},
								{
									label: translation.t(
										"HR.HrSetting.PayDay.Edit.padyday-edit-wrap.Radio.options.1"
									),
									value: "익월",
								},
							]}
							boolean
						/>
						<Group
							label={t("HR.HrSetting.PayDay.Edit.padyday-edit-wrap.Select.label")}
							className={"select-payday"}
						>
							<Select
								name="payDay"
								options={dateOption()}
								disabled={lastDate}
								placeholder={
									lastDate &&
									t(
										"HR.HrSetting.PayDay.Edit.padyday-edit-wrap.Select.placeholder"
									)
								}
							/>
							<div className="checkbox lastDay">
								<label className={`checkbox-custom`}>
									<input
										checked={lastDate}
										onChange={handleLastDate}
										type="checkbox"
									/>
									<span className="checkmark" />
									<span className="checkbox-label">
										{t(
											"HR.HrSetting.PayDay.Edit.padyday-edit-wrap.Select.checkbox-label"
										)}
									</span>
								</label>
							</div>
						</Group>
					</Form>
				</section>
				<div className="alert-content">
					<p className="alert-phrase">
						<Trans
							i18nKey="HR.HrSetting.PayDay.Edit.padyday-edit-wrap.alert-phrase"
							components={[<i />]}
						/>
					</p>
				</div>
			</div>
			<CancelAlert
				message={t("HR.HrSetting.PayDay.Edit.padyday-edit-wrap.CancelAlert")}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
		</>
	);
};

export default PaydayEdit;
