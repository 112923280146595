import React from "react";
import EmptyCase from "~/common/components/EmptyCase";
import { useNavigate } from "react-router-dom";
import PageHeader from "~/common/components/PageHeader";
import { useTranslation } from "react-i18next";

const Empty = ({ menu, type }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const headerButton = [
		<button
			className="btn__solid"
			color={"primary"}
			onClick={() => navigate("/community/list/add")}
		>
			{t("button.registering")}
		</button>,
	];

	return (
		<>
			<PageHeader {...{ menu, type }} buttonList={headerButton} />
			<EmptyCase
				main={t("Community.BoardPage.BoardList.Empty.main")}
				sub={t("Community.BoardPage.BoardList.Empty.sub")}
			/>
		</>
	);
};

export default Empty;
