import React, { useEffect } from "react";
import Form, { Group } from "~/common/components/Form";
import MultiCheckboxSelect from "~/common/components/MultiCheckboxSelect";

const MultiSelectType = ({ useForm, form, apply }) => {
	useEffect(() => {
		useForm.setValue(form.order.toString(), form.answer);
	}, []);

	function setOptions(options) {
		let newOptions = [];
		options.forEach((option) => {
			newOptions.push({ label: option, value: option });
		});

		return newOptions;
	}
	const selectedValues = useForm.watch(form.order.toString());

	return (
		<>
			<Form useForm={useForm} className="radio-form-wrap horiz multi-select-form-wrap">
				<Group label={form.title} required={form.required}>
					<MultiCheckboxSelect
						valueKey={"value"}
						name={form.order.toString()}
						options={setOptions(form.selectOption)}
						selectionLimit={10}
						placeholder={"선택해주세요"}
						selectedValues={selectedValues}
					/>
				</Group>
			</Form>
		</>
	);
};

export default MultiSelectType;
