import React from "react";
import DebtPosition from "./DebtPosition";
import DebtStatus from "./DebtStatus";
import FinancialPosition from "./FinancialPosition";
import IncomeStatus from "./IncomeStatus";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const View = ({ Header, showEditPage }) => {
	const { t } = useTranslation();
	const buttonList = [
		<AuthButton type="button" className="btn__solid" color="primary" onClick={showEditPage}>
			{t("button.Edit")}
		</AuthButton>,
	];
	return (
		<>
			<Header buttonList={buttonList} />
			<div className="ir-view">
				<div className="c__card finance-view">
					<div className="data-form">
						<section>
							<h3 className="section-title">
								{t("IR.IrDetail.Finance.Edit.Positions.title")}
							</h3>
							<div className="c__form vertical sub-section">
								<FinancialPosition />
								<DebtPosition />
							</div>
						</section>
						<section>
							<div className="c__form vertical sub-section ">
								<IncomeStatus />
							</div>
						</section>
						<section className="debt-status">
							<h3 className="section-title unit-wrap">
								{t("IR.IrDetail.Finance.Edit.DynamicFields.title")}
								<span className="unit">
									{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
									{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
								</span>
							</h3>
							<DebtStatus />
						</section>
					</div>
				</div>
			</div>
		</>
	);
};

export default View;
