import { Case, If } from "~/common/components/If";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import React, { useEffect, useState } from "react";
import useRequest from "~/common/hooks/useRequest";
import { getKeyByValue } from "~/utils/object";
import {
	STATUTORY_VACATION_TYPE,
	VACATION_COLOR,
} from "~/pages/hr/VacationPage/ApplyVacationModal";
import List from "~/pages/hr/ManagePage/vacationList/Modal/List";

const VacationStatus = ({ selectedUser }) => {
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	const VacationStatusRequest = useRequest("get", "/hr/vacation/individual/type");
	const [vacationStatusList, setVacationStatusList] = useState([]);

	useEffect(() => {
		VacationStatusRequest.call({ userIdx: selectedUser.User.idx }, "queryString");
	}, [selectedUser]);

	useEffect(() => {
		switch (VacationStatusRequest.state) {
			case API_STATE.done:
				const newData = VacationStatusRequest.response.data.map((d) => ({
					...d,
					remainingTime: d.type === "연차" ? `${d.remainingTime}일` : d.remainingTime,
					statutoryType: getKeyByValue(STATUTORY_VACATION_TYPE, d.title),
					color: VACATION_COLOR[
						getKeyByValue(STATUTORY_VACATION_TYPE, d.title).toUpperCase()
					],
				}));
				setVacationStatusList(newData);
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				if (VacationStatusRequest.error.statusCode === 404) {
					setPageState(PAGE_STATE.VIEW);
				}
			default:
				break;
		}
	}, [VacationStatusRequest.state]);

	return (
		<>
			<If condition={pageState}>
				<Case condition={PAGE_STATE.VIEW}>
					<List {...{ vacationStatusList }} />
				</Case>
				<Case condition={PAGE_STATE.LOADING}>
					<Loading />
				</Case>
			</If>
		</>
	);
};
export default VacationStatus;
