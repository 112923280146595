import React, { useState, useEffect } from "react";
import { useGlobalStore } from "~/store";
import { getFlatDataFromTree } from "react-sortable-tree";

import Form, { Group } from "~/common/components/Form";
import Input from "~/common/components/Input";
import Select, { optionsGenerator } from "~/common/components/Select";
import DatePicker from "~/common/components/DatePicker";

//Assets
import "~/assets/sass/pages/hr/modal/common/edit/hr.scss";
import NumberInput from "~/common/components/NumberInput";
import Radio from "~/common/components/Radio";
import { useTranslation } from "react-i18next";

const HrEdit = ({ useForm }) => {
	const { t } = useTranslation();
	const { watch, setValue } = useForm;
	const [hasWorkEndDate, setHasWorkEndDate] = useState(watch("UserProfile.workEndDate") === null);
	const [{ organization }] = useGlobalStore();

	const [department] = useState(
		optionsGenerator(
			getFlatDataFromTree({
				treeData: organization.treeData,
				getNodeKey: ({ node }) => node.id,
			}).reduce((acc, { node }) => {
				acc[node.id] = node.title;
				return acc;
			}, {})
		)
	);

	// const [workType] = useState(organization?.workTypeList);
	const [workType, setWorkType] = useState(null);

	const orgName = watch("UserProfile.orgId");

	useEffect(() => {
		setValue("UserProfile.department", department.find((d) => d.value === orgName)?.label);
	}, [orgName]);

	const isResigned = watch("UserProfile.employmentStatus") === "3";

	const handleWorkEndDate = () => {
		setHasWorkEndDate(!hasWorkEndDate);
	};

	useEffect(() => {
		!isResigned
			? setValue("UserProfile.resignDate", null)
			: setValue("UserProfile.resignDate", watch("UserProfile.resignDate"));
	}, [isResigned]);

	useEffect(() => {
		if(organization && organization?.workTypeList) {
			// setWorkType(organization?.workTypeList);
		}
	}, [organization])

	return (
		<section className="teamInfo-hr edit">
			<p className="section-title">
				{t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.section-title")}
			</p>
			<Form useForm={useForm}>
				<Select
					label={t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.SelectLabel1")}
					name="UserProfile.orgId"
					placeholder={
						department.length === 0
							? t(
									"HR.TeamInfo.modal.MemberDetailModal.components.Hr.SelectPlaceholder1"
							  )
							: t(
									"HR.TeamInfo.modal.MemberDetailModal.components.Hr.SelectPlaceholder2"
							  )
					}
					options={department}
					autofocus
				/>
				<Input
					label={t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.InputLabel")}
					name="UserProfile.position"
				/>
				<DatePicker
					label={t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.DatePickerLabel1")}
					name="UserProfile.joinDate"
					required
					placeholderText={t(
						"HR.TeamInfo.modal.MemberDetailModal.components.Hr.DatePickerPlaceholder1"
					)}
				/>
				<DatePicker
					label={t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.DatePickerLabel2")}
					name="UserProfile.workStartDate"
					required
					placeholderText={t(
						"HR.TeamInfo.modal.MemberDetailModal.components.Hr.DatePickerPlaceholder2"
					)}
				/>
				<Group
					label={t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.GroupLabel")}
					className="work-end-wrap"
				>
					<DatePicker
						name="UserProfile.workEndDate"
						disabled={hasWorkEndDate}
						placeholderText={
							hasWorkEndDate
								? t(
										"HR.TeamInfo.modal.MemberDetailModal.components.Hr.DatePickerPlaceholder3"
								  )
								: ""
						}
					/>
					<div className="checkbox">
						<label className={`checkbox-custom`}>
							<input
								type="checkbox"
								checked={hasWorkEndDate}
								onChange={handleWorkEndDate}
							/>
							<span className="checkmark" />
							<span className="checkbox-label">
								{t(
									"HR.TeamInfo.modal.MemberDetailModal.components.Hr.checkbox-label"
								)}
							</span>
						</label>
					</div>
				</Group>
				{
					workType ?
						<Select
							label={t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.SelectLabel2")}
							name="UserProfile.workingType"
							placeholder={t(
								"HR.TeamInfo.modal.MemberDetailModal.components.Hr.SelectPlaceholder3"
							)}
							options={optionsGenerator(workType)}
							required
						/> :
						<Select
							label={t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.SelectLabel2")}
							name="UserProfile.workingType"
							placeholder={t(
								"HR.TeamInfo.modal.MemberDetailModal.components.Hr.SelectPlaceholder3"
							)}
							options={[]}
							required
						/>
				}
				{/*<NumberInput*/}
				{/*	label={t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.NumberInputLabel")}*/}
				{/*	name="UserProfile.salary"*/}
				{/*	unit={t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.unit")}*/}
				{/*	thousandSeparator*/}
				{/*/>*/}
				{/*<Radio*/}
				{/*	label={t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.status")}*/}
				{/*	name="UserProfile.employmentStatus"*/}
				{/*	className={"employmentStatus"}*/}
				{/*	options={optionsGenerator({*/}
				{/*		0: t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.working"),*/}
				{/*		2: t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.absence"),*/}
				{/*		3: t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.quit"),*/}
				{/*	})}*/}
				{/*/>*/}
				{/*{isResigned && (*/}
				{/*	<DatePicker*/}
				{/*		label={t("HR.TeamInfo.modal.MemberDetailModal.components.Hr.quitDate1")}*/}
				{/*		name="UserProfile.resignDate"*/}
				{/*		required*/}
				{/*		className="quit-date"*/}
				{/*	/>*/}
				{/*)}*/}
			</Form>
		</section>
	);
};

export default HrEdit;
