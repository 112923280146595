import React from "react";
import defaultAvatar from "~/assets/img/defaultImg/profile-large.png";
import { phoneNumberFormatter } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const CardFront = ({ profile }) => {
	const { t } = useTranslation();
	return (
		<div className="c__card front">
			<div className="content-wrap">
				<div className="content">
					<p className="profile-intro">{profile.introduction}</p>
					<div className="profile-img">
						<img
							src={profile.img ? profile.img : defaultAvatar}
							onError={(e) => {
								e.target.src = defaultAvatar;
							}}
							alt={t("Img.profile")}
						/>
						{profile.role === "admin" && (
							<p className="profile-authority">{t("MyPage.Admin")}</p>
						)}
					</div>
					<div className="profile-position">{profile.position}</div>
					<h3 className="profile-name">{profile.name}</h3>
					<ul className="contact-info">
						<li>{phoneNumberFormatter(profile.phone)}</li>
						<li>{profile.id}</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default CardFront;
