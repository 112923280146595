import React from "react";
import { useController } from "react-hook-form";

const Toggle = (props) => {
	const { control, name, labels, validation, defaultValue } = props;

	const { field } = useController({
		control,
		name,
		rules: validation,
		defaultValue: defaultValue,
	});

	return (
		<label className="c__toggle">
			<input
				type="checkbox"
				checked={field.value}
				value={field.value}
				onChange={() => field.onChange(!field.value)}
			/>
			<div className="slider">
				<span className="background" />
				<span className="ball" />
			</div>
			<span className="toggle-label">{labels !== undefined && labels[field.value]}</span>
		</label>
	);
};

export default Toggle;

Toggle.defaultProps = {
	defaultValue: false,
};
