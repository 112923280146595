import React from "react";

import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import { RoundMinusIcon } from "~/assets/svg/InterfacesIcon";
import { PlusIcon } from "~/assets/svg/MathIcon";
import NumberInput from "~/common/components/NumberInput";
import { useFieldArray } from "react-hook-form";
import DatePicker from "~/common/components/DatePicker";
import { useTranslation } from "react-i18next";

const InputRow = ({ useForm, name, remove }) => {
	return (
		<Form useForm={useForm} className="tr">
			<Input
				className="td borrower-name"
				name={name("borrower")}
				type="text"
				required={null}
			/>
			<NumberInput className="td" name={name("value")} required={null} string />
			<DatePicker name={name("dueDate")} className="td" required />
			<NumberInput
				className="td"
				name={name("interestRate")}
				required={null}
				string
				max={100}
			/>
			<Input
				className="td repayment-condition"
				name={name("repaymentTerms")}
				type="text"
				required={null}
			/>
			<div className="td btn-wrap">
				<button type="button" className="btn-round-delete" onClick={remove}>
					<RoundMinusIcon />
				</button>
			</div>
		</Form>
	);
};

const DynamicFields = ({ useForm }) => {
	const { t } = useTranslation();
	const { control } = useForm;

	const { fields, append, remove } = useFieldArray({
		control,
		name: "Dept",
	});

	const ROW_INIT_DATA = {
		borrower: "",
		value: "",
		dueDate: new Date().toISOString(),
		interestRate: "",
		repaymentTerms: "",
	};

	return (
		<>
			<section className="debt-status">
				<h3 className="section-title unit-wrap">
					{t("IR.IrDetail.Finance.Edit.DynamicFields.title")}
					<span className="unit">
						{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
						{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
					</span>
				</h3>
				<div className="debt-status-table">
					<div className="thead">
						<div className="tr">
							<div className="th">
								{t("IR.IrDetail.Finance.Edit.DynamicFields.thead.text1")}
							</div>
							<div className="th">
								{t("IR.IrDetail.Finance.Edit.DynamicFields.thead.text2")}
							</div>
							<div className="th">
								{t("IR.IrDetail.Finance.Edit.DynamicFields.thead.text3")}
							</div>
							<div className="th">
								{t("IR.IrDetail.Finance.Edit.DynamicFields.thead.text4")}
							</div>
							<div className="th repayment-condition">
								{t("IR.IrDetail.Finance.Edit.DynamicFields.thead.text5")}
							</div>
						</div>
					</div>
					<div className="tbody">
						{fields.map((field, index) => {
							const getName = (propName) => `Dept.${index}.${propName}`;
							const removeItem = () => remove(index);
							return (
								<InputRow
									key={field.id}
									name={getName}
									remove={removeItem}
									useForm={useForm}
								/>
							);
						})}
					</div>
				</div>
				<button
					className="dashed-btn plus-btn"
					type="button"
					onClick={() => append(ROW_INIT_DATA)}
				>
					<PlusIcon />
					{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.addInput")}
				</button>
			</section>
		</>
	);
};

export default DynamicFields;
