import React, { useState } from "react";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import TabMenu from "~/common/components/TabMenu";
import { Case, If } from "~/common/components/If";
import HistoryList from "~/pages/mypage/admin/PaymentPage/historyList";
import Management from "~/pages/mypage/admin/PaymentPage/management";
import translation from "~/translations";

//asset
import "~/assets/sass/pages/mypage/admin/paymentPage/common.scss";

const PaymentPage = () => {
	const MENU = ["admin", "payment"];
	const TYPE = "myPage";
	const [currentTab, setCurrentTab] = useState("history");

	const tabMenuList = [
		{ id: "history", title: translation.t("MyPage.admin.Payment.tabMenuList.history") },
		{ id: "management", title: translation.t("MyPage.admin.Payment.tabMenuList.management") },
	];

	return (
		<NavbarAndSidebarLayout
			menu={MENU}
			type={TYPE}
			// menuName={"payment" + currentTab}
		>
			<PageHeader />
			<TabMenu tabItem={tabMenuList} {...{ currentTab, setCurrentTab }} />
			<If condition={currentTab}>
				<Case condition={"history"}>
					<HistoryList />
				</Case>
				<Case condition={"management"}>
					<Management />
				</Case>
			</If>
		</NavbarAndSidebarLayout>
	);
};

export default PaymentPage;
