import React, { useState } from "react";
import defaultLogo from "~/assets/img/defaultImg/logo-square.png";
import { useTranslation } from "react-i18next";

const Portfolio = ({ portfolioList }) => {
	const { t } = useTranslation();
	const [year, setYear] = useState("all");

	// 포트폴리오 기간 : 현재로부터 5년
	const yearLimit = 5;
	function portfolioYearList() {
		let i = 0;
		let arr = [];
		while (i < yearLimit) {
			arr.push(new Date().getFullYear() - i);
			i++;
		}
		return arr;
	}

	const portfolioCard = () => {
		return portfolioList[year].map((portfolio, p_idx) => (
			<li className="startup-item " key={p_idx}>
				<div className="img-wrap">
					<img
						src={portfolio.default_logo_url ?? defaultLogo}
						onError={(e) => {
							e.target.src = defaultLogo;
						}}
						alt={t("VcInfo.VcDetail.basic.logo-wrap")}
					/>
				</div>
				<ul className="company-info">
					<li className="notice">{t("VcInfo.VcDetail.portfolio.notice")}</li>
					<li className="company-name">{portfolio.name}</li>
				</ul>
			</li>
		));
	};

	return (
		<li className="portfolio-map">
			<div className="row">
				<h4 className="detail-info-title">
					{t("VcInfo.VcDetail.portfolio.detail-info-title")}
				</h4>
				<select
					className="c__select"
					defaultValue={"all"}
					onChange={({ target }) => {
						setYear(target.value);
					}}
				>
					<option key={"all"} value={"all"}>
						{t("VcInfo.VcDetail.portfolio.all")}
					</option>
					{portfolioYearList().map((key) => {
						return (
							<option key={key} value={key}>
								{key}
							</option>
						);
					})}
				</select>
			</div>
			<div className="company-rel-wrap">
				<ul className="startup-list">
					{portfolioList[year] === undefined || portfolioList[year].length === 0 ? (
						<p className="no-content">{t("VcInfo.VcDetail.portfolio.no-content")}</p>
					) : (
						portfolioCard()
					)}
				</ul>
			</div>
		</li>
	);
};

export default Portfolio;
