import React, { useEffect, useState } from "react";
import useDebounce from "~/common/hooks/useDebounce";
import { SearchIcon } from "~/assets/svg/InterfacesIcon";
import noImg from "~/assets/img/defaultImg/profile-large.png";
import DropdownTemplate, {
	DropDownItem,
} from "~/common/components/DropdownPortal/DropdownTemplate";
import { useGlobalStore } from "~/store";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import { useNavigate } from "react-router-dom";
import InviteModal from "~/pages/community/BoardPage/BoardPost/List/Modal/inviteModal";
import { useParams } from "react-router";
import useAlert from "~/common/hooks/useAlert";
import NoData from "~/common/components/NoData";
import { useAtom } from "jotai";
import { MyAuthAtom } from "~/atoms/Community";
import { useTranslation } from "react-i18next";

const Member = ({ managerList, participantList, getMember }) => {
	const { t } = useTranslation();
	const [
		{
			user: { info },
		},
	] = useGlobalStore();
	const [myAuth, setMyAuth] = useAtom(MyAuthAtom);
	const navigate = useNavigate();
	const { idx } = useParams();

	const [showAlert, Alert] = useAlert();

	const [initManagerList, setInitManagerList] = useState([]);

	const [memberSearchText, setMemberSearchText] = useState("");
	const [inviteModalToggle, setInviteModalToggle] = useState(false);

	const kickMemberRequest = useRequest("delete", "community/member/kick");
	const leaveMemberRequest = useRequest("delete", `community/member/leave/${idx}`);
	const changeMemberAuthRequest = useRequest("patch", "/community/member/auth");
	const initMemberRequest = useRequest("post", `community/member/search/${idx}`);
	const invitableMembersRequest = useRequest("post", `/community/member/invite/search/${idx}`);

	useEffect(() => {
		getInitMember();
	}, []);

	const getInitMember = () => {
		initMemberRequest.call(
			{
				page: 1,
				size: 1000,
			},
			"body"
		);
	};

	useEffect(() => {
		switch (initMemberRequest.state) {
			case API_STATE.done:
				const response = initMemberRequest.response.data;
				setInitManagerList(response.managers);
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [initMemberRequest.state]);

	useEffect(() => {
		switch (kickMemberRequest.state) {
			case API_STATE.done:
				getInitMember();
				[...managerList, ...participantList].find(
					(member) =>
						member.memberIdx === kickMemberRequest.response.data.kickedMember.idx
				).userIdx === info.idx
					? navigate("/community/list")
					: getMember(memberSearchText);
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [kickMemberRequest.state]);

	useEffect(() => {
		switch (leaveMemberRequest.state) {
			case API_STATE.done:
				navigate("/community/list");
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [leaveMemberRequest.state]);

	useEffect(() => {
		switch (changeMemberAuthRequest.state) {
			case API_STATE.done:
				getInitMember();
				getMember(memberSearchText);
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [changeMemberAuthRequest.state]);

	useEffect(() => {
		getMember(memberSearchText);
	}, [memberSearchText]);

	const kickMember = (memberIdx) => {
		kickMemberRequest.call(
			{ data: { communityIdx: Number(idx), memberIdx: Number(memberIdx) } },
			"body"
		);
	};

	const leaveCommunity = () => {
		leaveMemberRequest.call();
	};

	const changeMemberAuth = (memberIdx, userIdx, auth) => {
		changeMemberAuthRequest.call(
			{ communityIdx: Number(idx), memberIdx: Number(memberIdx), type: auth },
			"body"
		);
		if (userIdx === info.idx) {
			setMyAuth(auth);
		}
	};

	const clickInviteBtn = () => {
		invitableMembersRequest
			.asyncCall(
				{
					page: 1,
					size: 50,
				},
				"body"
			)
			.then(({ data }) => {
				if (data.users.length < 1) {
					showAlert();
				} else {
					setInviteModalToggle(true);
				}
			});
	};

	return (
		<>
			<section className="c__card community-list-member">
				<div className="card-header">
					<div className="card-title">
						<p className="title">
							{t("Community.BoardPage.BoardPost.List.member.title")}{" "}
							<span className="num">
								{managerList.length + participantList.length}
							</span>
						</p>
						{myAuth === "MANAGER" && (
							<button
								className="btn__outline angled"
								color="primary"
								onClick={clickInviteBtn}
							>
								{t("Community.BoardPage.BoardPost.List.button-invite")}
							</button>
						)}
					</div>
					<div className="c__search">
						<input
							className="c__input"
							placeholder={t("Community.BoardPage.BoardPost.List.input-placeholder")}
							onChange={useDebounce((r) => setMemberSearchText(r.target.value), 300)}
						/>
						<button type="button" className="btn-search">
							<SearchIcon />
						</button>
					</div>
				</div>

				<div className="member-section">
					{managerList.length || participantList.length ? (
						<>
							<div className="member-wrap">
								<h3 className="participant">
									{t("Community.BoardPage.BoardPost.List.member.manager")}{" "}
									<span>{managerList.length}</span>
								</h3>
								<ul className="member-list">
									{managerList?.map((manager, index) => (
										<li className="member-item" key={index}>
											<div className="profile-img">
												<img
													src={manager.img ?? noImg}
													alt={t("Img.profile")}
													onError={(e) => {
														e.target.src = noImg;
													}}
												/>
											</div>

											<div className="profile-info">
												<span className="profile-name">{manager.name}</span>
												<div className="profile-email">{manager.id}</div>
											</div>
											{myAuth === "MANAGER" && (
												<DropdownTemplate
													target={manager.memberIdx}
													vertical
												>
													<DropDownItem
														className={
															initManagerList.length <= 1
																? "disabled"
																: ""
														}
														onClick={() => {
															if (initManagerList.length > 1) {
																manager.userIdx === info.idx
																	? leaveCommunity()
																	: kickMember(manager.memberIdx);
															}
														}}
													>
														{manager.userIdx === info.idx
															? t(
																	"Community.BoardPage.BoardPost.List.member.DropDownItem-out"
															  )
															: t(
																	"Community.BoardPage.BoardPost.List.member.DropDownItem-export"
															  )}
													</DropDownItem>

													<DropDownItem
														className={
															initManagerList.length <= 1
																? "disabled"
																: ""
														}
														onClick={() => {
															if (initManagerList.length > 1) {
																changeMemberAuth(
																	manager.memberIdx,
																	manager.userIdx,
																	"MEMBER"
																);
															}
														}}
													>
														{t(
															"Community.BoardPage.BoardPost.List.member.DropDownItem-participant"
														)}
													</DropDownItem>
												</DropdownTemplate>
											)}
										</li>
									))}
								</ul>
							</div>
							<div className="member-wrap">
								<h3 className="participant">
									{t("Community.BoardPage.BoardPost.List.member.participant")}{" "}
									<span>{participantList.length}</span>
								</h3>
								<ul className="member-list">
									{participantList?.map((participant, index) => (
										<li className="member-item" key={index}>
											<div className="profile-img">
												<img
													src={participant.img ?? noImg}
													alt={t("Img.profile")}
													onError={(e) => {
														e.target.src = noImg;
													}}
												/>
											</div>

											<div className="profile-info">
												<span className="profile-name">
													{participant.name}
												</span>
												{participant.employmentStatus === 3 && (
													<span className="retired-tag">
														{t(
															"Community.BoardPage.BoardPost.List.member.retired"
														)}
													</span>
												)}
												<div className="profile-email">
													{participant.id}
												</div>
											</div>

											{(myAuth === "MANAGER" ||
												participant.userIdx === info.idx) && (
												<DropdownTemplate
													target={participant.memberIdx}
													vertical
												>
													<DropDownItem
														onClick={() =>
															participant.userIdx === info.idx
																? leaveCommunity()
																: kickMember(participant.memberIdx)
														}
													>
														{participant.userIdx === info.idx
															? t(
																	"Community.BoardPage.BoardPost.List.member.DropDownItem-out"
															  )
															: t(
																	"Community.BoardPage.BoardPost.List.member.DropDownItem-export"
															  )}
													</DropDownItem>
													<DropDownItem
														className={
															myAuth === "MANAGER" ? "" : "disabled"
														}
														onClick={() => {
															if (myAuth === "MANAGER") {
																changeMemberAuth(
																	participant.memberIdx,
																	participant.userIdx,
																	"MANAGER"
																);
															}
														}}
													>
														{t(
															"Community.BoardPage.BoardPost.List.member.DropDownItem-manager"
														)}
													</DropDownItem>
												</DropdownTemplate>
											)}
										</li>
									))}
								</ul>
							</div>
						</>
					) : (
						<NoData
							main={t("Community.BoardPage.BoardPost.List.searchNoData.main")}
							sub={t("Community.BoardPage.BoardPost.List.searchNoData.sub")}
						/>
					)}
				</div>
			</section>
			<InviteModal
				{...{
					inviteModalToggle,
					setInviteModalToggle,
					getMember,
				}}
			/>
			<Alert
				message={t("Community.BoardPage.BoardPost.List.member.Alert-message")}
				type="info"
				confirm={t("button.check")}
			/>
		</>
	);
};

export default Member;
