import React from "react";
import { useNavigate } from "react-router";
import RequestMsg from "~/pages/invest/EvaluatePage/EvaluateDetail/View/components/requestMsg";
import imgReject from "~/assets/img/img-reject.png";
import { useGlobalStore } from "~/store";
import { Trans, useTranslation } from "react-i18next";

const Reject = () => {
	const { t } = useTranslation();
	const [
		{
			evaluate: {
				currentEvaluate: { VcCompany },
			},
		},
	] = useGlobalStore();

	const navigate = useNavigate();

	return (
		<>
			<section className="c__card message">
				<RequestMsg />
			</section>
			<section className="c__card reject">
				<p className="content">
					<Trans
						i18nKey="Invest.Evaluate.EvaluateDetail.Reject.content"
						components={[<i />, { VcCompany }]}
					/>
				</p>
				<div className="img-wrap">
					<img
						src={imgReject}
						alt={t("Invest.Evaluate.EvaluateDetail.Reject.img-wrap")}
					/>
				</div>
				<button
					type="button"
					className="btn__solid"
					color="primary"
					onClick={() => navigate(`/invest/review`)}
				>
					{t("button.check")}
				</button>
			</section>
		</>
	);
};

export default Reject;
