import React from "react";
import AlarmRow from "./AlarmRow";
import { useTranslation } from "react-i18next";
import { useGlobalStore } from "~/store";

const View = () => {
	const { t } = useTranslation();
	const [
		{
			alarm: { alarmList },
		},
	] = useGlobalStore();
	return (
		<div className="c__card">
			<div className="alarm-table">
				<div className="alarm-thead">
					<div className="alarm-number">No</div>
					<div className="alarm-type">{t("MyPage.AlarmPage.type")}</div>
					<div className="alarm-content">{t("MyPage.AlarmPage.content")}</div>
					<div className="alarm-date">{t("MyPage.AlarmPage.date")}</div>
					<div className="alarm-status">{t("MyPage.AlarmPage.status")}</div>
				</div>
				<div className="alarm-tbody">
					{alarmList.length !== 0 ? (
						alarmList.map((alarm, a_idx) => (
							<AlarmRow key={a_idx} {...{ alarm, a_idx }} />
						))
					) : (
						<div className="no-content">{t("MyPage.AlarmPage.no-content")}</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default View;
