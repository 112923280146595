import React from "react";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import Edit from "~/pages/community/BoardPage/BoardPost/Detail/Edit";
import { useNavigate, useParams } from "react-router";

const BoardPostAdd = () => {
	const navigate = useNavigate();
	const { idx } = useParams();
	const onCancel = () => {
		navigate(`/community/list/board/${idx}`);
	};
	return (
		<NavbarAndSidebarLayout menu={["community", "community"]} type="main">
			<Edit {...{ onCancel }} />
		</NavbarAndSidebarLayout>
	);
};

export default BoardPostAdd;
