import React, { useEffect, useState } from "react";
import { If, Case, OtherWise } from "~/common/components/If";
import SignatureUpload from "~/pages/mypage/SignaturePage/SignatureUploadModal/content/SignatureUpload";
import SignaturePolicy from "~/pages/mypage/SignaturePage/SignatureUploadModal/content/SignaturePolicy";

//Assets
import "~/assets/sass/pages/mypage/signature/signatureUpload.scss";
import { useTranslation } from "react-i18next";

const SignatureUploadModal = ({ setModalTitle, setModalToggle, getSignatureList }) => {
	const { t } = useTranslation();
	const [step, setStep] = useState("init");

	useEffect(() => {
		if (step === "init") {
			setModalTitle(t("MyPage.SignaturePage.SignatureUploadModal.initModalTitle"));
		} else if (step === "upload") {
			setModalTitle(t("MyPage.SignaturePage.SignatureUploadModal.uploadModalTitle"));
		}
	}, [step]);

	return (
		<If condition={step} setStep={setStep} id={"signature"} {...{ setModalToggle }}>
			<Case condition={"upload"}>
				<SignatureUpload {...{ getSignatureList }} />
			</Case>
			<OtherWise>
				<SignaturePolicy />
			</OtherWise>
		</If>
	);
};
export default SignatureUploadModal;
