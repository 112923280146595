import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import React, { useEffect, useState } from "react";
import PluginDetailModal from "~/pages/mypage/PluginPage/PluginDetailModal";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import useAlert from "~/common/hooks/useAlert";
import { useGlobalStore } from "~/store";
import { PluginDescConstant } from "~/pages/mypage/PluginPage/PluginConstant";
import useRequest from "~/common/hooks/useRequest";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const PluginItem = ({ plugin, getPluginList }) => {
	const { t, i18n } = useTranslation();
	const langChange = i18next.language;
	const [
		{
			user: {
				info: { license },
			},
		},
	] = useGlobalStore();

	const [pluginStatus, setPluginStatus] = useState(PAGE_STATE.VIEW);
	const [pluginDetailModal, setPluginDetailModal] = useState(false);
	const [pluginTitle, setPluginTitle] = useState("");
	const [selectedPlugin, setSelectedPlugin] = useState({});

	const [showLicenseAlert, LicenseAlert] = useAlert();
	const [showUninstallAlert, UninstallAlert] = useAlert();
	const [showWarningAlert, WarningAlert] = useAlert();

	const pluginDetailRequest = useRequest("get", `plugin`);
	const usePluginRequest = useRequest("post", "plugin/use");

	const handleClick = (idx) => {
		pluginDetailRequest.call(idx, "path");
	};
	const handleUninstall = (plugin) => {
		if (plugin.idx === 1 || plugin.idx === 13) {
			showWarningAlert();
		} else {
			showUninstallAlert().then(async () => {
				usePluginRequest.call(plugin.Auth.idx, "path");
			});
		}
	};

	const handleInstall = (idx) => {
		setPluginStatus(PAGE_STATE.LOADING);
		setTimeout(() => usePluginRequest.call(idx, "path"), 1000);
	};

	const handleInstallAccordingToLicense = {
		free(plugin) {
			if (plugin.license.toLowerCase() !== "free") return showLicenseAlert();
			handleInstall(plugin.Auth.idx);
		},
		pro(plugin) {
			handleInstall(plugin.Auth.idx);
		},
	};

	useEffect(() => {
		switch (pluginDetailRequest.state) {
			case API_STATE.done:
				const response = pluginDetailRequest.response.data;

				setPluginTitle(i18n.language === "ko" ? response.name : response.engName);
				setSelectedPlugin({
					...response,
					license:
						PluginDescConstant.find((p) => response.idx === p.idx)?.license ?? "Free",
				});
				setPluginDetailModal(true);
				break;
			case API_STATE.error:
				console.log("error", pluginDetailRequest.error);
				alert(t("Alert.alert1"));
				break;
			default:
				break;
		}
	}, [pluginDetailRequest.state, pluginDetailRequest.response, pluginDetailRequest.error]);

	useEffect(() => {
		switch (usePluginRequest.state) {
			case API_STATE.done:
				setPluginDetailModal(false);
				getPluginList();
				setPluginStatus(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error", usePluginRequest.error);
				alert(t("Alert.alert1"));
				break;
			default:
				break;
		}
	}, [usePluginRequest.state]);

	return (
		<>
			<div className="c__card" onClick={() => !plugin.prepare && handleClick(plugin.idx)}>
				<div className="top">
					<div className="img-wrap">
						<img
							src={plugin.thumbImg}
							alt={plugin.name + t("MyPage.PluginPage.PluginDetailModal.plugin")}
						/>
					</div>
					<div className="detail-wrap">
						<div className="title-wrap">
							<h3>{langChange === "ko" ? plugin.name : plugin.engName}</h3>
							{/*{d.Auth.use ? "사용중 uninstall" : "사용중 아님 install"}*/}
							{/*<span>{d.license === "free" ? "무료" : "유료"}</span>*/}
							<span>{plugin.license}</span>
						</div>
						<div
							className={`plugin-detail ${plugin.tagList.length !== 0 ? "tag" : ""}`}
						>
							<p>
								{langChange === "ko" ? plugin.introduction : plugin.engIntroduction}
							</p>
							<div className="plugin-tag">
								{langChange === "ko"
									? plugin.tagList.map((tag, tag_index) => (
											<span key={tag_index}>{tag}</span>
									  ))
									: plugin.engTagList.map((engTag, engTag_index) => (
											<span key={engTag_index}>{engTag}</span>
									  ))}
							</div>
						</div>
					</div>
				</div>
				<div className="bottom">
					{plugin.prepare ? (
						<span className="unused">{t("MyPage.PluginPage.PluginItem.unused")}</span>
					) : plugin.Auth && plugin.Auth.use ? (
						<button
							className="btn__outline btn-active"
							color={"primary"}
							onClick={(e) => {
								handleUninstall(plugin);
								e.stopPropagation();
							}}
						>
							UNINSTALL
						</button>
					) : (
						<button
							className={`btn__solid btn-inactive ${
								pluginStatus === PAGE_STATE.LOADING ? "loading" : ""
							}`}
							color={"primary"}
							onClick={(e) => {
								e.stopPropagation();
								handleInstallAccordingToLicense[license](plugin);
							}}
						>
							<If condition={pluginStatus}>
								<Case condition={PAGE_STATE.VIEW}>INSTALL</Case>
								<Case condition={PAGE_STATE.LOADING}>
									<Loading />
								</Case>
							</If>
						</button>
					)}
				</div>
			</div>
			<ModalTemplate
				title={pluginTitle}
				modalState={pluginDetailModal}
				setModalToggle={setPluginDetailModal}
				className="plugin-detail-modal"
			>
				<PluginDetailModal
					{...{
						selectedPlugin,
						handleInstall,
						handleUninstall,
						handleInstallAccordingToLicense,
						license,
					}}
				/>
			</ModalTemplate>
			<LicenseAlert
				confirm={t("button.check")}
				type="info"
				width={430}
				height={260}
				message={<h3>{t("MyPage.PluginPage.PluginItem.LicenseAlert")}</h3>}
				className="overtime-use-modal"
			/>
			<UninstallAlert
				message={
					<>
						<h3>{t("MyPage.PluginPage.PluginItem.UninstallAlertH3")}</h3>
						<p>{t("MyPage.PluginPage.PluginItem.UninstallAlertP")}</p>
					</>
				}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type="warning"
				layout="horiz"
				width={430}
				className="overtime-use-modal"
			/>
			<WarningAlert
				message={t("MyPage.PluginPage.PluginItem.WarningAlert")}
				confirm={t("button.check")}
				type={"info"}
			/>
		</>
	);
};

export default PluginItem;
