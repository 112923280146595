import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Checkbox from "~/common/components/Checkbox";
import Form from "~/common/components/Form";
import { API_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import { findPropertyValue } from "~/utils/object";
import { SearchIcon } from "~/assets/svg/InterfacesIcon";
import DefaultProfileImg from "~/assets/img/defaultImg/profile-large.png";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";

const UserRow = ({ onChange, option, checked }) => {
	const { t } = useTranslation();

	return option.employmentStatus !== 3 ? (
		<div className="tr">
			<div className="td mem-check">
				<label className="checkbox-custom checkbox">
					<input
						type="checkbox"
						checked={checked}
						onChange={(e) => onChange(e)}
						value={option.value}
					/>
					<span className="checkmark" />
				</label>
			</div>
			<div className="td mem-name">
				<div className="img-wrap">
					<img
						src={option.img || DefaultProfileImg}
						alt={t("Img.profile")}
						onError={(e) => {
							e.target.src = DefaultProfileImg;
						}}
					/>
				</div>
				{option.label}
			</div>
			<div className="td mem-group">{option.department ?? "-"}</div>
			<div className="td mem-position">{option.position ?? "-"}</div>
		</div>
	) : (
		<></>
	);
};

const Modal = ({ setModalToggle, importUser, fields }) => {
	const { t } = useTranslation();
	const teamMemberRequest = useRequest("get", "user/list");

	useEffect(() => {
		teamMemberRequest.singleCall();
	}, []);

	const [response, setResponse] = useState([]);

	useEffect(() => {
		switch (teamMemberRequest.state) {
			case API_STATE.done:
				setResponse(
					teamMemberRequest.response.data.map((data) => ({
						label: data.name,
						email: data.id,
						value: data.idx,
						position: data.position,
						department: data.department,
						img: data.img,
						employmentStatus: data.employmentStatus,
					}))
				);
				break;
			default:
				break;
		}
	}, [teamMemberRequest.state]);

	const userListForm = useForm();

	const closeModal = () => {
		setModalToggle(false);
	};

	const handleClick = (data) => {
		const selectedUsers = response.filter((user) => data.list.includes(user.value.toString()));
		const newUsers = selectedUsers
			.map((user) => ({
				name: user.label,
				position: user.position,
				department: user.department,
				introduction: "",
				img: user.img,
			}))
			.filter(
				(user) =>
					fields.findIndex(
						(field) =>
							field.name === user.name &&
							field.department === user.department &&
							field.position === user.position
					) === -1
			);
		importUser(newUsers);
		closeModal();
	};

	return (
		<>
			<div className="modal-content">
				<div className="table-top">
					<div className="mem-total">
						Total : {response.length}
						{t("Company.Info.View.num")}
					</div>
					<div className="c__search">
						<input
							className="c__input"
							placeholder={t("Company.Member.Edit.inputPlaceholder")}
							onChange={({ target }) => {
								setResponse(
									findPropertyValue(response, target.value, [
										"label",
										"email",
										"position",
										"department",
									])
								);
							}}
						/>
						<button type="button" className="btn-search">
							<SearchIcon />
						</button>
					</div>
				</div>
				<div className="c__table teamInfo-table">
					<div className="thead">
						<div className="tr">
							<label className={`th mem-check checkbox-custom`}>
								{/*<input*/}
								{/*	type="checkbox"*/}
								{/*	value={allCheck}*/}
								{/*	onChange={() => setAllCheck(!allCheck)}*/}
								{/*/>*/}
								{/*<span className="checkmark" />*/}
							</label>
							<div className="th mem-name">{t("Company.Member.Edit.mem-name")}</div>
							<div className="th mem-group">{t("Company.Member.Edit.mem-group")}</div>
							<div className="th mem-position">
								{t("Company.Member.Edit.mem-position")}
							</div>
						</div>
					</div>
					<div className="tbody">
						{response && response.length !== 0 ? (
							<Form useForm={userListForm}>
								<Checkbox name="list" options={response}>
									<UserRow />
								</Checkbox>
							</Form>
						) : (
							<NoData
								main={t("Company.Member.Edit.NoData.main")}
								sub={t("Company.Member.Edit.NoData.sub")}
							/>
						)}
					</div>
				</div>
			</div>
			<div className="modal-footer">
				<button onClick={closeModal} className="btn__solid" color="gray" key="cancel">
					{t("button.cancel")}
				</button>
				<button
					onClick={userListForm.handleSubmit(handleClick)}
					className="btn__solid"
					color="primary"
					key="submit"
				>
					{t("button.bring")}
				</button>
			</div>
		</>
	);
};

export default Modal;
