import React, { useState } from "react";
import TabMenu from "~/common/components/TabMenu";
import { Case, If } from "~/common/components/If";

import ProfileView from "../components/ProfileView";
import HrView from "../components/HrView";
import PersonalView from "../components/PersonalView";
import CareerView from "../components/CareerView";
import ScrollTop from "~/common/components/ScrollTop";
import translation from "~/translations";

const MineView = ({ userDetail }) => {
	const [currentTab, setCurrentTab] = useState("basic");

	const tabMenuList = [
		{ id: "basic", title: translation.t("HR.TeamInfo.modal.MemberDetailModal.EditView.basic") },
		{
			id: "career",
			title: translation.t("HR.TeamInfo.modal.MemberDetailModal.EditView.career"),
		},
	];

	return (
		<>
			<div className="modal-content">
				<ProfileView {...{ userDetail }} />
				<div className="tabInfo-area">
					<TabMenu
						tabItem={tabMenuList}
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
					<ScrollTop className="scroll-wrap">
						<If condition={currentTab} userDetail={userDetail}>
							<Case condition={"basic"}>
								<HrView />
								<PersonalView />
							</Case>
							<Case condition={"career"}>
								<CareerView />
							</Case>
						</If>
					</ScrollTop>
				</div>
			</div>
		</>
	);
};

export default MineView;
