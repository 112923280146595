import React from "react";
import { RoundArrowIcon } from "~/assets/svg/InterfacesIcon";
import { useTranslation } from "react-i18next";

const Footer = ({ totalCardStep, cardStep, setCardStep, statusType }) => {
	const { t } = useTranslation();
	const handleBackward = () => {
		setCardStep(cardStep - 1);
	};

	const handleForward = () => {
		setCardStep(cardStep + 1);
	};

	return (
		<div className="tab-footer">
			{statusType !== "waiting" && (
				<button className="prev-btn" onClick={handleBackward} disabled={cardStep === 1}>
					<RoundArrowIcon />
					<span>{t("button.prev")}</span>
				</button>
			)}
			{statusType !== "waiting" && (
				<button
					className="next-btn"
					disabled={cardStep === totalCardStep}
					onClick={handleForward}
				>
					<span>{t("button.next")}</span>
					<RoundArrowIcon />
				</button>
			)}
		</div>
	);
};

export default Footer;
