import React, { useEffect, useState } from "react";
import bgIntro from "~/assets/img/bg-intro.png";
import { useGlobalStore } from "~/store";
import { useNavigate } from "react-router";
import { PAGE_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import { NODE_ENV } from "~/common/constants/env";
import { Trans, useTranslation } from "react-i18next";
import {stringHasLength} from "~/utils/string";
import { USER_INFO } from "~/store/user/repository";
// component
import UserInfo from "~/pages/DashboardPage/Contents/UserInfo";
import Vacation from "~/pages/DashboardPage/Contents/Vacation";
import ApplyVacationModal from "~/pages/vacation/VacationListPage/ApplyVacationModal";
import InvestRound from "~/pages/DashboardPage/Contents/InvestRound";
import ActivityCount from "~/pages/DashboardPage/Contents/ActivityCount";
import EmployNumTrend from "~/pages/DashboardPage/Contents/EmployeeNumTrend";
import RecentAlert from "~/pages/DashboardPage/Contents/RecentAlert";
import RecentNotice from "~/pages/DashboardPage/Contents/RecentNotice";

const Contents = () => {

	//메뉴권한 설정, 데이터가 없는경우는 "none"으로 기본값을 설정합니다.
	const settingAuth = () =>{
		/*
		{
				"admin": true,
				"company": "edit",
				"ir": "edit",
				"hr": "edit",
				"round": "edit",
				"evaluate": "edit"
		}
		 */
		return {
			admin: user?.info?.role === "admin",
			company: stringHasLength(sidebar?.authMap?.main?.company?.info) ? sidebar?.authMap?.main?.company?.info : "none",
			ir: stringHasLength(sidebar?.authMap?.main?.ir?.list) ? sidebar?.authMap?.main?.ir?.list : "none",
			hr: stringHasLength(sidebar?.authMap?.main?.hr?.team) ? sidebar?.authMap?.main?.hr?.team : "none",
			round: stringHasLength(sidebar?.authMap?.main?.invest?.round) ? sidebar?.authMap?.main?.invest?.round : "none",
			evaluate: stringHasLength(sidebar?.authMap?.main?.invest?.review) ? sidebar?.authMap?.main?.invest?.review : "none",
		}
	}

	const { t } = useTranslation();
	const navigate = useNavigate();
	const [
		{
			user: { info },
		},
	] = useGlobalStore();

	const [{ user, sidebar }] = useGlobalStore();
	const [auth, setAuth] = useState(settingAuth());

	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [response, setResponse] = useState({
		alarmList: [],
		employeeList: [],
		roundList: [],
		activityCount: {
			invest: null,
			round: null,
			connect: null,
		},
	});

	// 휴가 신청 팝업
	const [vacationTypeListModal, setVacationTypeListModal] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [year, setYear] = useState(new Date().getFullYear());

	// api
	const dashboardRequest = useRequest("get", "user/dashboard");
	const previousVacationsRequest = useRequest("get", "vacation/previous");
	const historyRequest = useRequest("get", "vacation/history");
	const hrUpcomingVacationRequest = useRequest("get", "vacation/upcoming");
	const vacationTimeCountRequest = useRequest("get", "vacation/time/count");
	const communityRequest = useRequest("get", "/community/board/all"); // 5 latest posts

	// function
	const getAllRequest = async () => {
		const MethodAndUrls = [
			{
				request: previousVacationsRequest,
				type: "queryString",
				params: { page: pageNumber, size: 10, userIdx: info.idx, year },
			},
			{
				request: historyRequest,
				type: "queryString",
				params: { page: pageNumber, size: 10, userIdx: info.idx, year },
			},
			{
				request: hrUpcomingVacationRequest,
				type: "queryString",
				params: { page: pageNumber, size: 10, userIdx: info.idx },
			},
		];

		const Requests = MethodAndUrls.map((data) => {
			data.request.call(data.params, data.type);
		});

		await Promise.all(Requests);
	};

	useEffect(() => {
		setAuth({
			admin: user?.info?.role === "admin",
			company: sidebar?.authMap?.main?.company?.info,
			ir: sidebar?.authMap?.main?.ir?.list,
			hr: sidebar?.authMap?.main?.hr?.team,
			round: sidebar?.authMap?.main?.invest?.round,
			evaluate: sidebar?.authMap?.main?.invest?.review,
		});
	}, [user.info]);

	useEffect(() => {
		dashboardRequest.asyncCall().then(({ data: response }) => {
			let totalData = {};
			let manageInfo = {};

			if(user.info.role === "admin") {
				manageInfo = {
					upcmReq: response.upcmReq ?? 0,
					totalMember: response.totalMember ?? 0,
					activeMember: response.activeMember ?? 0,
					noactiveMember: response.noactiveMember ?? 0,
				}
			}
			totalData = {
				alarmList: response.alarmAllReadedOrNot,
				// roundList:
				// 	NODE_ENV === "development"
				// 		? response.round
				// 		: user.info.idx === 3 || user.info.idx === 1
				// 			? response.round
				// 			: [],
				employeeList: response.employee,
				activityCount: {
					invest: response.investCount,
					round: response.roundCount,
					connect: response.connectCount,
				},
				manageInfo: manageInfo
			}

			communityRequest.asyncCall({nItems: 5}, "queryString").then(({data: res}) => {
				totalData = {
					...totalData,
					communityList: res.board
				}
				setResponse(totalData);
			});

			// setResponse({
				// alarmList: response.alarm,
				// roundList:
				// 	NODE_ENV === "development"
				// 		? response.round
				// 		: user.info.idx === 3 || user.info.idx === 1
				// 			? response.round
				// 			: [],
				// employeeList: response.employee,
				// activityCount: {
				// 	invest: response.investCount,
				// 	round: response.roundCount,
				// 	connect: response.connectCount,
				// },
				// manageInfo: manageInfo
			// });

			setPageState(PAGE_STATE.VIEW);

		});
	}, []);


	useEffect(() => {
		if (!info.idx) return;
		getAllRequest();
	}, [info.idx]);


	return (
		<>
			<div className={"p__dashboard_container p__schedule"}>
				{ (auth && USER_INFO) &&
					<UserInfo
						auth={auth}
						setVacationTypeListModal={setVacationTypeListModal}
						userInfo={USER_INFO}
						manageInfo={response.manageInfo}
					/>
				}
				<Vacation />
				{vacationTypeListModal && (
					<ApplyVacationModal
						{...{
							vacationTypeListModal,
							setVacationTypeListModal,
							getUpcomingVacation: getAllRequest,
							vacationTimeCountRequest,
							info,
						}}
					/>
				)}
				<div className={"my-activity"}>
					<div className="c__card activity-board">
						<RecentNotice
							data={response.communityList}
							pageState={"VIEW"}
						/>
					</div>
					<div className={"c__card activity-recent-alarm"}>
						<RecentAlert data={response.alarmList} pageState={pageState} />
					</div>
				</div>
			</div>

			{/*2024.05.13 이전 UI*/}
			{/*<div className="c__card">*/}
			{/*	<section className="intro-section">*/}
			{/*		<div className="intro-phrase-wrap">*/}
			{/*			<div className="greeting-wrap">*/}
			{/*			<span className="greeting-title">*/}
			{/*				{t("Dashboard.Contents.greeting-title")}*/}
			{/*			</span>*/}
			{/*				<p>{t("Dashboard.Contents.greeting-description")}</p>*/}
			{/*			</div>*/}
			{/*			<div className="img-wrap">*/}
			{/*				<img src={bgIntro} alt="인트로 배경이미지" />*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*		{(auth.admin ||*/}
			{/*			auth.company === "edit" ||*/}
			{/*			auth.ir === "edit" ||*/}
			{/*			auth.hr === "edit") && (*/}
			{/*			<div className="quick-tip-wrap">*/}
			{/*				<p className="quick-tip-title">*/}
			{/*					<Trans*/}
			{/*						i18nKey="Dashboard.Contents.quick-tip-title"*/}
			{/*						components={[<b />]}*/}
			{/*					/>*/}
			{/*				</p>*/}
			{/*				<ul className="quick-tip-menu">*/}
			{/*					<CheckList {...{ user, auth }} />*/}
			{/*				</ul>*/}
			{/*				{auth.admin && (*/}
			{/*					<div className="quick-connect-wrap">*/}
			{/*						<span>{t("Dashboard.Contents.quick-connect-wrap.span")}</span>*/}
			{/*						<a onClick={() => navigate("/myPage/admin/team")}>*/}
			{/*							{t("Dashboard.Contents.quick-connect-wrap.a")}*/}
			{/*						</a>*/}
			{/*					</div>*/}
			{/*				)}*/}
			{/*			</div>*/}
			{/*		)}*/}

			{/*		/!* 라이센스 없을 시 *!/*/}
			{/*		/!*<div className="quick-tip-wrap fail">*!/*/}
			{/*		/!*	<ul className="quick-tip-menu">*!/*/}
			{/*		/!*		<li className="menu-check-wrap">*!/*/}
			{/*		/!*			<div className="desc-wrap">*!/*/}
			{/*		/!*				<span className="chk-mark">1</span>*!/*/}

			{/*		/!*				/!* 관리자 *!/*!/*/}
			{/*		/!*				<span className="menu-check">*!/*/}
			{/*		/!*					라이센스가 적용되어 있지 않습니다. 라이센스를 확인해주세요.*!/*/}
			{/*		/!*				</span>*!/*/}

			{/*		/!*				/!* 유저 *!/*!/*/}
			{/*		/!*				/!*<span className="menu-check">*!/*!/*/}
			{/*		/!*				/!*	라이센스가 적용되어 있지 않습니다. 관리자에게 문의하세요.*!/*!/*/}
			{/*		/!*				/!*</span>*!/*!/*/}
			{/*		/!*			</div>*!/*/}
			{/*		/!*			<div className="link-caption">*!/*/}
			{/*		/!*				<a>라이센스 확인하러 가기</a>*!/*/}
			{/*		/!*			</div>*!/*/}
			{/*		/!*		</li>*!/*/}
			{/*		/!*	</ul>*!/*/}
			{/*		/!*</div>*!/*/}
			{/*	</section>*/}
			{/*	<section className="article-section">*/}
			{/*		<RecentAlert data={response.alarmList} pageState={pageState} />*/}
			{/*		{(auth.admin || auth.round !== "none") && (*/}
			{/*			<InvestRound data={response.roundList} pageState={pageState} />*/}
			{/*		)}*/}
			{/*		{(auth.admin || auth.evaluate === "edit" || auth.round === "edit") && (*/}
			{/*			<ActivityCount*/}
			{/*				data={response.activityCount}*/}
			{/*				pageState={pageState}*/}
			{/*				auth={auth}*/}
			{/*			/>*/}
			{/*		)}*/}
			{/*		{auth.admin && (*/}
			{/*			<EmployNumTrend data={response.employeeList} pageState={pageState} />*/}
			{/*		)}*/}
			{/*	</section>*/}
			{/*	/!*초기화면*!/*/}
			{/*	/!*<section className="article-section-initial">*!/*/}
			{/*	/!*    <article className="invest-round">*!/*/}
			{/*	/!*        <div className="article-title">투자 라운드</div>*!/*/}
			{/*	/!*        <div className="invest-round-initial">*!/*/}
			{/*	/!*            <CautionIcon/>*!/*/}
			{/*	/!*            <p>*!/*/}
			{/*	/!*                스타트업을 위한 투자라운딩 정보를 받아보시려면<br/>*!/*/}
			{/*	/!*                <b>On-invest 플러그인</b>을 활성화해주세요.*!/*/}
			{/*	/!*                <a href="/" className="plugin-shortcut">플러그인 바로가기</a>*!/*/}
			{/*	/!*            </p>*!/*/}
			{/*	/!*        </div>*!/*/}
			{/*	/!*    </article>*!/*/}
			{/*	/!*	<article className="member-vary-chart">*!/*/}
			{/*	/!*		<div className="article-title">인력증감 추이</div>*!/*/}
			{/*	/!*		<div className="member-vary-initial">*!/*/}
			{/*	/!*			<p>데이터가 없습니다.</p>*!/*/}
			{/*	/!*		</div>*!/*/}
			{/*	/!*	</article>*!/*/}
			{/*	/!*</section>*!/*/}
			{/*</div>*/}

		</>
	);
};

export default Contents;
