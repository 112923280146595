import React from "react";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";

const SearchEmpty = () => {
	const { t } = useTranslation();
	return (
		<NoData
			main={t("Invest.Evaluate.EvaluateList.Empty.searchEmpty.main")}
			sub={t("Invest.Evaluate.EvaluateList.Empty.searchEmpty.sub")}
		/>
	);
};

export default SearchEmpty;
