import React from "react";
import faceboockIco from "~/assets/img/sns/ico-facebook.png";
import twitterIco from "~/assets/img/sns/ico-twitter.png";
import youtubeIco from "~/assets/img/sns/ico-youtube.png";
import linkedinIco from "~/assets/img/sns/ico-linkedin.png";
import instargramIco from "~/assets/img/sns/ico-instargram.png";
import { useGlobalStore } from "~/store";
import { dateToString } from "~/utils/formatter";
import { Link } from "react-router-dom";
import defaultLogo from "~/assets/img/defaultImg/logo-square.png";
import defaultImg from "~/assets/img/defaultImg/default-img.png";
import useAlert from "~/common/hooks/useAlert";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const Info = ({ setPage, roundStatus, isAgree, setIsAgree, ROUND_STATUS, isProductExist }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [
		{
			user: {
				check: { comCheck },
			},
			round: { info },
		},
	] = useGlobalStore();

	const [showAlert, Alert] = useAlert();
	const [showComCheckAlert, ComCheckAlert] = useAlert();
	const [showProductCheckAlert, ProductCheckAlert] = useAlert();

	const getRoundForm = () => {
		if (info.status === "진행중" && !info.apply) {
			if (!isAgree) {
				showAlert();
			} else if (!comCheck) {
				showComCheckAlert();
			} else if (!isProductExist) {
				showProductCheckAlert();
			} else {
				setPage("form");
			}
		} else {
			setPage("form");
		}
	};

	const ApplyButton = () =>
		info.applyMethod === "온라인 질의 작성하기" ? (
			<button
				type="button"
				className={`${info.apply ? "btn__outline" : "btn__solid"} btn-status`}
				color={info.status !== "진행중" && !info.apply ? "gray" : "primary"}
				onClick={() => getRoundForm()}
				disabled={info.status !== "진행중" && !info.apply}
			>
				{info.apply
					? translation.t("Invest.Round.RoundDetailModal.View.component.info.applyView")
					: info.status === "진행중"
					? translation.t(
							"Invest.Round.RoundDetailModal.View.component.info.applySupport"
					  )
					: translation.t(
							"Invest.Round.RoundDetailModal.View.component.info.applyDeadline"
					  )}
			</button>
		) : (
			<a
				className={`${info.apply ? "btn__outline" : "btn__solid"} btn-status ${
					info.status !== "진행중" && !info.apply ? "disabled" : ""
				}`}
				color={info.status !== "진행중" && !info.apply ? "gray" : "primary"}
				href={info.status !== "진행중" && !info.apply ? "" : info.survey}
				onClick={(e) => {
					info.status !== "진행중" && !info.apply && e.preventDefault();
				}}
				target="_blank"
				rel="noreferrer"
			>
				{info.apply
					? t("Invest.Round.RoundDetailModal.View.component.info.applyView")
					: info.status === "진행중"
					? t("Invest.Round.RoundDetailModal.View.component.info.applySupport")
					: t("Invest.Round.RoundDetailModal.View.component.info.applyDeadline")}
			</a>
		);

	return (
		<>
			<section className="rounding-main">
				<div className="rounding-main-header">
					<div>
						<h3 className="rounding-title">{info.title}</h3>
					</div>
					<div className="date-wrap">
						<h4 className="rounding-status">
							{ROUND_STATUS(info.endDate)[info.status]}
						</h4>
						<p className="rounding-update">
							{t("Invest.Round.RoundDetailModal.View.component.info.rounding-update")}
							: {dateToString(info.regDate, "yyyy-MM-dd")}
						</p>
					</div>
				</div>
				<div className="rounding-main-container">
					<div className="rounding-summary-left">
						<div className="rounding-img">
							<img
								src={info.img || defaultImg}
								alt={t(
									"Invest.Round.RoundDetailModal.View.component.info.rounding-img"
								)}
								onError={(e) => {
									e.target.src = defaultImg;
								}}
							/>
						</div>
						<div className="rounding-host-wrap">
							<div className="rounding-host">
								<div className="rounding-host-logo">
									<img
										src={info.managerImg || defaultLogo}
										alt={t("Company.Info.logo")}
										onError={(e) => {
											e.target.src = defaultLogo;
										}}
									/>
								</div>
								<dl className="rounding-host-data">
									<dt className="host-name">{info.managerName}</dt>
									<dd className="host-email">{info.managerEmail}</dd>
								</dl>
							</div>
							<ul className="share-sns">
								{info.facebook && (
									<li>
										<button type="button">
											<a
												href={info.facebook}
												target="_blank"
												rel="noreferrer"
											>
												<img
													src={faceboockIco}
													alt={t(
														"Invest.Round.RoundDetailModal.View.component.info.facebookIco"
													)}
												/>
											</a>
										</button>
									</li>
								)}
								{info.twitter && (
									<li>
										<button type="button">
											<a href={info.twitter} target="_blank" rel="noreferrer">
												<img
													src={twitterIco}
													alt={t(
														"Invest.Round.RoundDetailModal.View.component.info.twitterIco"
													)}
												/>
											</a>
										</button>
									</li>
								)}
								{info.instagram && (
									<li>
										<button type="button">
											<a
												href={info.instagram}
												target="_blank"
												rel="noreferrer"
											>
												<img
													src={instargramIco}
													alt={t(
														"Invest.Round.RoundDetailModal.View.component.info.instargramIco"
													)}
												/>
											</a>
										</button>
									</li>
								)}
								{info.linkedin && (
									<li>
										<button type="button">
											<a
												href={info.linkedin}
												target="_blank"
												rel="noreferrer"
											>
												<img
													src={linkedinIco}
													alt={t(
														"Invest.Round.RoundDetailModal.View.component.info.linkedinIco"
													)}
												/>
											</a>
										</button>
									</li>
								)}
								{info.youtube && (
									<li>
										<button type="button">
											<a href={info.youtube} target="_blank" rel="noreferrer">
												<img
													src={youtubeIco}
													alt={t(
														"Invest.Round.RoundDetailModal.View.component.info.youtubeIco"
													)}
												/>
											</a>
										</button>
									</li>
								)}
							</ul>
						</div>
					</div>
					<div className="rounding-summary-right">
						<p className="rounding-summary">{info.description}</p>
						<dl className="rounding-instructions">
							<div className="period">
								<dt>
									{t("Invest.Round.RoundDetailModal.View.component.info.Date")}
								</dt>
								<dd>
									{dateToString(info.startDate, "yyyy-MM-dd")} ~
									{dateToString(info.endDate, "yyyy-MM-dd")}
								</dd>
							</div>
							<div className="place">
								<dt>
									{t("Invest.Round.RoundDetailModal.View.component.info.Place")}
								</dt>
								<dd>
									{info.place === "장소등록"
										? `${info?.address} ${info?.addressDetail}`
										: info.place}
								</dd>
							</div>
						</dl>
						{info.applyMethod !== "오프라인" && (
							<>
								<div className="apply-agree">
									{info.status === "진행중" && !info.apply && (
										<>
											<div className="checkbox">
												<label className={`checkbox-custom`}>
													<input
														type="checkbox"
														checked={isAgree}
														onChange={() => setIsAgree(!isAgree)}
													/>
													<span className="checkmark" />
													<span className="checkbox-label">
														{t(
															"Invest.Round.RoundDetailModal.View.component.info.consent-label"
														)}
													</span>
												</label>
											</div>
											<button type="button" className="btn-ghost">
												<Link to={"/policy/terms"} target="_blank">
													{t("button.detail")}
												</Link>
											</button>
										</>
									)}
								</div>
								{info.status !== "오픈대기" && <ApplyButton />}
							</>
						)}
					</div>
				</div>
			</section>
			<Alert
				message={t("Invest.Round.RoundDetailModal.View.component.info.consent-alert")}
				type="info"
				confirm={t("button.check")}
			/>
			<ComCheckAlert
				message={
					<>
						<h3>
							{t(
								"Invest.Round.RoundDetailModal.View.component.info.ComCheckAlert.h3"
							)}
						</h3>
						<p>
							{t("Invest.Round.RoundDetailModal.View.component.info.ComCheckAlert.p")}
						</p>
						<button className="btn-linked" onClick={() => navigate("/company/info")}>
							{t(
								"Invest.Round.RoundDetailModal.View.component.info.ComCheckAlert.btn-linked"
							)}
						</button>
					</>
				}
				type="info"
				confirm={t("button.check")}
				layout={"horiz"}
				className={"comCheck-alert"}
				height={230}
				width={435}
			/>
			<ProductCheckAlert
				message={
					<>
						<h3>제품정보 미입력 상태입니다.</h3>
						<p>라운드 지원을 위해 제품정보를 먼저 입력해주세요</p>
						<button className="btn-linked" onClick={() => navigate("/company/product")}>
							제품설정 바로가기
						</button>
					</>
				}
				type="info"
				confirm={t("button.check")}
				layout={"horiz"}
				className={"comCheck-alert"}
				height={230}
				width={435}
			/>
		</>
	);
};

export default Info;
