import React from "react";
import PageHeader from "~/common/components/PageHeader";
import TabNav from "~/common/components/TabNav";
import { useOutletContext } from "react-router-dom";
import List from "~/pages/vacation/SettingPage/View/list";
import SettingPolicy from "~/pages/vacation/SettingPage/View/settingPolicy";
import "~/assets/sass/pages/hr/setting/vacation.scss";
import { useGlobalStore } from "~/store";
import useAlert from "~/common/hooks/useAlert";

const Vacation = () => {
	const { hrSettingTabList, menu, type } = useOutletContext();
	const [
		{
			sidebar: { currentPath },
		},
	] = useGlobalStore();

	const [showAuthAlert, AuthAlert] = useAlert();

	return (
		<>
			<PageHeader menu={menu} type={type} />
			<TabNav tabList={hrSettingTabList} currentTab={"vacation"} />
			<div className="c__card vacation-card">
				<SettingPolicy {...{ currentPath, showAuthAlert }} />
				<List {...{ currentPath, showAuthAlert }} />
			</div>
			<AuthAlert
				message={
					<>
						권한이 없습니다. <br />
						관리자에게 문의하세요.
					</>
				}
				type="warning"
				confirm="확인"
			/>
		</>
	);
};

export default Vacation;
