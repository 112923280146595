import React from "react";
import PageHeader from "~/common/components/PageHeader";
import { dateToString, thousandFormatter } from "~/utils/formatter";
import { ProgressType, RoundType } from "~/common/constants/type";
import { Case, If, OtherWise } from "~/common/components/If";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const HistoryRow = ({ date, name, step, comValue, value }) => {
	const { t } = useTranslation();
	return (
		<li className="form-data-group">
			<span className="history-date">{dateToString(date, "yyyy-MM")}</span>
			<ul className="history-content">
				<li>
					{t("Company.Invest.View.investDate")} - {dateToString(date, "yyyy-MM-dd")}
				</li>
				<li>
					{t("Company.Invest.investName")} - {name}
				</li>
				<li>
					{t("Company.Invest.View.investRound")} - {RoundType[step]}
				</li>
				<li>
					{t("Company.Invest.comValue")} - {thousandFormatter(comValue)}
				</li>
				<li>
					{t("Company.Invest.View.investAmount")} - {thousandFormatter(value)}
				</li>
			</ul>
		</li>
	);
};

const View = ({ menu, type, showEditPage, data }) => {
	const { t } = useTranslation();
	const { investInfo, investHistory } = data;
	const buttonList = [
		<AuthButton type="button" className="btn__solid" color="primary" onClick={showEditPage}>
			{t("button.Edit")}
		</AuthButton>,
	];

	const reducedAmount = investHistory.reduce((acc, cur) => {
		return acc + parseInt(cur.value);
	}, 0);

	const reducedHistory = investHistory
		.sort((a, b) => b.date.localeCompare(a.date))
		.reduce((acc, cur) => {
			const date = dateToString(cur.date, "yyyy.MM.dd");
			const year = date.substring(0, 4);
			const current = acc[year] || [];

			acc[year] = [...current, { ...cur, date: date }];
			return acc;
		}, {});

	const historyList = () =>
		Object.keys(reducedHistory)
			.reverse()
			.map((year) => (
				<div key={year} className="yearly-history">
					<h3 className="year">{year}</h3>
					<ol>
						{reducedHistory[year].map(({ date, name, step, comValue, value }, idx) => (
							<HistoryRow key={idx} {...{ date, name, step, comValue, value }} />
						))}
					</ol>
				</div>
			));

	return (
		<>
			<PageHeader {...{ menu, type, buttonList }} />
			<div className="c__card invest-view">
				<div className="data-form">
					<section className="input-group-section">
						<h3 className="section-title unit-wrap">
							{t("Company.Invest.Edit.investAttract")}
							<span className="unit">{t("Company.unit")}</span>
						</h3>
						<div className="c__form horiz">
							<div className="f__group">
								<label className="f__label">{t("Company.Invest.View.round")}</label>
								<div className="f__control">{RoundType[investInfo.round]}</div>
							</div>
							<div className="f__group">
								<label className="f__label">
									{t("Company.Invest.investStatus")}
								</label>
								<div className="f__control">{ProgressType[investInfo.status]}</div>
							</div>
							<div className="f__group">
								<label className="f__label">
									{t("Company.Invest.investValue")}
								</label>
								<div className="f__control">
									{thousandFormatter(investInfo.value)}
								</div>
							</div>
							<div className="f__group">
								<label className="f__label">
									{t("Company.Invest.investTarget")}
								</label>
								<div className="f__control">
									{thousandFormatter(investInfo.target)}
								</div>
							</div>
						</div>
					</section>
					<section className="input-group-section invest-history-section">
						<h3 className="section-title unit-wrap">
							{t("Company.Invest.View.unit-wrap")}
							{thousandFormatter(reducedAmount)}){" "}
							<span className="unit">{t("Company.unit")}</span>
						</h3>
						<div className="invest-history">
							<If condition={investHistory.length}>
								<Case condition={0}>
									<p className="no-content">
										{t("Company.Invest.View.no-content")}
									</p>
								</Case>
								<OtherWise>{historyList()}</OtherWise>
							</If>
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

export default View;
