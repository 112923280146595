import React from "react";
import { Navigate } from "react-router";

export const WithAuthorization = ({ children }) => {
	const hasLogin = localStorage.getItem("gitbal.auth");
	return hasLogin ? children : <Navigate to="/login" />;
};

export const WithoutAuthorization = ({ children, restricted }) => {
	const hasLogin = localStorage.getItem("gitbal.auth");
	return hasLogin && restricted ? <Navigate to="/dashboard" /> : children;
};
