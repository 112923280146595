import React, { useState } from "react";
import { GraphIcon, LikeIcon } from "~/assets/svg/TeamInfoIcon";
import { handleWindow } from "~/common/components/Confirm";
import { useNavigate } from "react-router";
import defaultLogo from "~/assets/img/defaultImg/logo-square.png";
import { PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import Empty from "~/pages/vc/VcList/Empty";
import { useTranslation } from "react-i18next";
import AuthButton from "~/common/components/AuthButton";

export const FavIcon = () => {
	return (
		<svg viewBox="64 64 896 896" width="1em" height="1em" fill="currentColor">
			<path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z" />
			<path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z" />
		</svg>
	);
};

const View = ({ lastElementRef, pageState, vcList }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [dropdownState, setDropdownState] = useState(false);

	function parseField(json) {
		const objArr = JSON.parse(json);
		const arr = [];
		const responseArr = [];

		objArr.map((v) => {
			arr.push(v.label);
		});

		arr.map((v, i) => {
			if (i + 1 === arr.length) responseArr.push(v);
			else responseArr.push(v + ", ");
		});
		return responseArr;
	}

	const portfolio = (info) => {
		return (
			<div className="c__dropdown-wrap">
				<button
					className={`btn-dropdown ${dropdownState ? "visible" : ""}`}
					onClick={() => setDropdownState(!dropdownState)}
				>
					<ul className="logo-group" onClick={() => setDropdownState(false)}>
						{info.slice(0, 6).map((portfolio, p_idx) => (
							<li className="img-wrap" key={p_idx}>
								<img
									src={portfolio.default_logo_url ?? defaultLogo}
									onError={(e) => {
										e.target.src = defaultLogo;
									}}
									alt={t("VcInfo.VcList.View.img-wrap")}
								/>
							</li>
						))}
						<li className="company-plus-btn">
							<FavIcon />
						</li>
					</ul>
				</button>
				<div className={`c__dropdown ${dropdownState ? "visible" : ""}`}>
					<p className="dropdown-title">
						{t("VcInfo.VcList.View.dropdown-title")}
						<span className="num">{info.length}</span>
					</p>
					<ul className="detail-list">
						{info.map((portfolio, p_idx) => (
							<li className="c__dropdownItem" key={p_idx}>
								<div className="img-wrap">
									<img
										src={portfolio.default_logo_url ?? defaultLogo}
										onError={(e) => {
											e.target.src = defaultLogo;
										}}
										alt={t("VcInfo.VcList.View.img-wrap")}
									/>
								</div>
								<dl>
									<dt />
									<dd>{portfolio.name}</dd>
								</dl>
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	};

	const vcCard = () => {
		return vcList.map((vc, vc_idx) => {
			return (
				<div
					className="c__card"
					key={vc_idx}
					ref={vc_idx + 1 === vcList.length ? lastElementRef : null}
				>
					<AuthButton
						className="btn-auth"
						onClick={() => {
							navigate(`view/${vc.vcIdx}`);
						}}
					>
						<div className="card-top">
							<div className="img-wrap">
								<img
									src={vc.default_logo_url ?? defaultLogo}
									onError={(e) => {
										e.target.src = defaultLogo;
									}}
									alt={t("VcInfo.VcList.View.img-wrap")}
								/>
							</div>
							<div className="company-data">
								<h4 className="company-name">{vc.name}</h4>
								<p className="company-info">{vc.invest_association}</p>
								<p className="company-tag">
									<LikeIcon />
									<span className="tag-text">
										{vc.vc_invest_ratio
											? parseField(vc.vc_invest_ratio)
											: t("VcInfo.VcList.View.no-tag-text")}
									</span>
								</p>
								<a
									className="company-url"
									onClick={(e) => {
										e.stopPropagation();
										handleWindow(vc.home_page);
									}}
								>
									{vc.home_page
										? vc.home_page
										: t("VcInfo.VcList.View.no-company-url")}
								</a>
							</div>
						</div>
					</AuthButton>
					<div className="card-bottom">
						<p className="company-portfolio">
							<GraphIcon />
							{t("VcInfo.VcList.View.company-portfolio")}
							<b>
								{vc.VcStartupPortfolioInfo.length
									? vc.VcStartupPortfolioInfo.length
									: 0}
							</b>
						</p>
						{vc.VcStartupPortfolioInfo.length !== 0 &&
							portfolio(vc.VcStartupPortfolioInfo)}
					</div>
				</div>
			);
		});
	};

	return (
		<>
			{pageState === PAGE_STATE.EMPTY ? (
				<Empty />
			) : (
				<>
					<div className="c__cardRow vc-info-list">{vcCard()}</div>
					{pageState === PAGE_STATE.LOADING && <Loading />}
				</>
			)}
		</>
	);
};

export default View;
