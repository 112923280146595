import React, { useMemo } from "react";
import Form from "~/common/components/Form";
import NumberInput from "~/common/components/NumberInput";
import { thousandFormatter } from "~/utils/formatter";
import { useTranslation } from "react-i18next";

const Positions = ({ useForm }) => {
	const { t } = useTranslation();
	const { watch } = useForm;

	//재무상태표
	const quickAsset = watch("Finance.quickAsset");
	const inventories = watch("Finance.inventories");
	const otherCurrentAsset = watch("Finance.otherCurrentAsset");

	const CurrentAssets = useMemo(
		() => +quickAsset + +inventories + +otherCurrentAsset,
		[quickAsset, inventories, otherCurrentAsset]
	);

	const investment = watch("Finance.investment");
	const tangibleAsset = watch("Finance.tangibleAsset");
	const inTangibleAsset = watch("Finance.inTangibleAsset");
	const otherNonCurrentAsset = watch("Finance.otherNonCurrentAsset");

	const NonCurrentAssets = useMemo(
		() => +investment + +tangibleAsset + +inTangibleAsset + +otherNonCurrentAsset,
		[investment, tangibleAsset, inTangibleAsset, otherNonCurrentAsset]
	);

	const TotalAssets = useMemo(
		() => CurrentAssets + NonCurrentAssets,
		[CurrentAssets, NonCurrentAssets]
	);

	//부채생태표
	const currentLiability = watch("Finance.currentLiability");
	const nonCurrentLiability = watch("Finance.nonCurrentLiability");

	const TotalDebt = useMemo(
		() => +currentLiability + +nonCurrentLiability,
		[currentLiability, nonCurrentLiability]
	);

	const capital = watch("Finance.capital");
	const surplus = watch("Finance.surplus");
	const capitalAdjustment = watch("Finance.capitalAdjustment");
	const otherComprehensiveIncome = watch("Finance.otherComprehensiveIncome");
	const retainedEarnings = watch("Finance.retainedEarnings");

	const TotalEquity = useMemo(
		() =>
			+capital +
			+surplus +
			+capitalAdjustment +
			+otherComprehensiveIncome +
			+retainedEarnings,
		[capital, surplus, capitalAdjustment, otherComprehensiveIncome, retainedEarnings]
	);
	const DebtAndEquity = useMemo(() => TotalDebt + TotalEquity, [TotalDebt, TotalEquity]);

	return (
		<section>
			<h3 className="section-title">{t("IR.IrDetail.Finance.Edit.Positions.title")}</h3>
			<div className="c__form vertical sub-section finance-table">
				<div className="f__group sub-section-table">
					<div className="unit-wrap sub-title">
						{t("IR.IrDetail.Finance.Edit.Positions.subTitle1")}
						<span className="unit">
							{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
							{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
						</span>
					</div>
					<div className="f__row">
						<div className="f__column">
							<div className="f__group">
								<div className="f__label">
									{t("IR.IrDetail.Finance.Edit.Positions.section1.title")}
								</div>
								<div className="f__control">
									<input
										className="c__input"
										value={thousandFormatter(CurrentAssets)}
										readOnly
									/>
								</div>
							</div>
						</div>
						<div className="f__field">
							<Form useForm={useForm}>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section1.label1")}
									name="Finance.quickAsset"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section1.label2")}
									name="Finance.inventories"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section1.label3")}
									name="Finance.otherCurrentAsset"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
							</Form>
						</div>
					</div>
					<div className="f__row">
						<div className="f__column">
							<div className="f__group">
								<div className="f__label">
									{t("IR.IrDetail.Finance.Edit.Positions.section2.title")}
								</div>
								<div className="f__control">
									<input
										className="c__input"
										value={thousandFormatter(NonCurrentAssets)}
										readOnly
									/>
								</div>
							</div>
						</div>
						<div className="f__field">
							<Form useForm={useForm}>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section2.label1")}
									name="Finance.investment"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section2.label2")}
									name="Finance.tangibleAsset"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section2.label3")}
									name="Finance.inTangibleAsset"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section2.label4")}
									name="Finance.otherNonCurrentAsset"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
							</Form>
						</div>
					</div>
					<div className="total">
						<div className="total-label">
							{t("IR.IrDetail.Finance.Edit.Positions.total1")}
						</div>
						<div className="f__control">{thousandFormatter(TotalAssets)}</div>
					</div>
					<dl className="alert-content">
						<div>
							<dt>{t("IR.IrDetail.Finance.Edit.Positions.total1")}</dt>
							<dd>
								{t("IR.IrDetail.Finance.Edit.Positions.section1.title")} +{" "}
								{t("IR.IrDetail.Finance.Edit.Positions.section2.title")}
							</dd>
						</div>
						<div>
							<dt>{t("IR.IrDetail.Finance.Edit.Positions.section1.title")}</dt>
							<dd>
								{t("IR.IrDetail.Finance.Edit.Positions.section1.label1")} +{" "}
								{t("IR.IrDetail.Finance.Edit.Positions.section1.label2")} +{" "}
								{t("IR.IrDetail.Finance.Edit.Positions.section1.label3")}
							</dd>
						</div>
						<div>
							<dt>{t("IR.IrDetail.Finance.Edit.Positions.section2.title")}</dt>
							<dd>
								{t("IR.IrDetail.Finance.Edit.Positions.section2.label1")} +{" "}
								{t("IR.IrDetail.Finance.Edit.Positions.section2.label2")} +{" "}
								{t("IR.IrDetail.Finance.Edit.Positions.section2.label3")} +{" "}
								{t("IR.IrDetail.Finance.Edit.Positions.section2.label4")}
							</dd>
						</div>
					</dl>
				</div>
				<div className="f__group sub-section-table">
					<div className="unit-wrap sub-title">
						{t("IR.IrDetail.Finance.Edit.Positions.subTitle2")}
						<span className="unit">
							{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
							{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
						</span>
					</div>
					<div className="f__row">
						<div className="f__column">
							<div className="f__group">
								<div className="f__label">
									{t("IR.IrDetail.Finance.Edit.Positions.section3.title")}
								</div>
								<div className="f__control">
									<input
										className="c__input"
										value={thousandFormatter(TotalDebt)}
										readOnly
									/>
								</div>
							</div>
						</div>
						<div className="f__field">
							<Form useForm={useForm}>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section3.label1")}
									name="Finance.currentLiability"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section3.label2")}
									name="Finance.nonCurrentLiability"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
							</Form>
						</div>
					</div>
					<div className="f__row">
						<div className="f__column">
							<div className="f__group">
								<div className="f__label">
									{t("IR.IrDetail.Finance.Edit.Positions.section4.title")}
								</div>
								<div className="f__control">
									<input
										className="c__input"
										value={thousandFormatter(TotalEquity)}
										readOnly
									/>
								</div>
							</div>
						</div>
						<div className="f__field">
							<Form useForm={useForm}>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section4.label1")}
									name="Finance.capital"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section4.label2")}
									name="Finance.surplus"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section4.label3")}
									name="Finance.capitalAdjustment"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section4.label4")}
									name="Finance.otherComprehensiveIncome"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
								<NumberInput
									placeholder="0"
									label={t("IR.IrDetail.Finance.Edit.Positions.section4.label5")}
									name="Finance.retainedEarnings"
									validation={{
										maxLength: {
											value: 20,
											message: t(
												"IR.IrDetail.Finance.Edit.Positions.section1.msg"
											),
										},
									}}
								/>
							</Form>
						</div>
					</div>
					<div className="total">
						<div className="total-label">
							{t("IR.IrDetail.Finance.Edit.Positions.total2")}
						</div>
						<div className="f__control">{thousandFormatter(DebtAndEquity)}</div>
					</div>
					<dl className="alert-content">
						<div>
							<dt>{t("IR.IrDetail.Finance.Edit.Positions.section3.title")}</dt>
							<dd>
								{t("IR.IrDetail.Finance.Edit.Positions.section3.label1")} +{" "}
								{t("IR.IrDetail.Finance.Edit.Positions.section3.label2")}
							</dd>
						</div>
						<div>
							<dt>{t("IR.IrDetail.Finance.Edit.Positions.section4.title")}</dt>
							<dd>
								{t("IR.IrDetail.Finance.Edit.Positions.section4.label1")} +{" "}
								{t("IR.IrDetail.Finance.Edit.Positions.section4.label2")} +{" "}
								{t("IR.IrDetail.Finance.Edit.Positions.section4.label3")} +{" "}
								{t("IR.IrDetail.Finance.Edit.Positions.section4.label4")} +{" "}
								{t("IR.IrDetail.Finance.Edit.Positions.section4.label5")}
							</dd>
						</div>
						<div>
							<dt>{t("IR.IrDetail.Finance.Edit.Positions.total2")}</dt>
							<dd>
								{t("IR.IrDetail.Finance.Edit.Positions.section3.title")} +{" "}
								{t("IR.IrDetail.Finance.Edit.Positions.section4.title")}
							</dd>
						</div>
					</dl>
				</div>
			</div>
		</section>
	);
};

export default Positions;
