import React from "react";
import { Case, If, OtherWise } from "~/common/components/If";
import AlertPortal from "~/common/components/AlertPortal/index";
import PropsProvider from "~/common/components/PropsProvider";

//Assets
import "~/assets/sass/component/alert.scss";
import { InfoIcon, SuccessIcon, WarningIcon } from "~/assets/svg/AlertIcon";

const AlertTemplate = (props) => {
	const {
		alertModalState,
		setAlertModalToggle,
		children,
		mask,
		width,
		height,
		className,
		alertType,
		layout,
	} = props;

	return (
		<If condition={alertModalState}>
			<Case condition={true}>
				<AlertPortal>
					<div className={`c__alert toggleOpen ${mask && "show-mask"} ${className}`}>
						<div
							className="alert-container"
							style={{ width: width, minHeight: height }}
						>
							<div className={`alert-body ${layout}`}>
								<If condition={alertType}>
									<Case condition="info">
										<InfoIcon color={"#feca40"} />
									</Case>
									<Case condition="warning">
										<WarningIcon color={"#ff5d00"} />
									</Case>
									<Case condition="error">
										<WarningIcon color={"#ff4444"} />
									</Case>
									<Case condition="success">
										<SuccessIcon color={"#0D9488"} />
									</Case>
									<OtherWise>
										<SuccessIcon color={"#0D9488"} />
									</OtherWise>
								</If>
								<PropsProvider props={{ alertModalState, setAlertModalToggle }}>
									{children}
								</PropsProvider>
							</div>
						</div>
					</div>
				</AlertPortal>
			</Case>
		</If>
	);
};

export default AlertTemplate;

AlertTemplate.defaultProps = {
	mask: true,
	width: 430,
	height: 230,
	layout: "vertical",
};
