import React, { useState } from "react";
import TabMenu from "~/common/components/TabMenu";
import { Case, If, OtherWise } from "~/common/components/If";
import Desc from "~/pages/mypage/PluginPage/PluginDetailModal/components/Desc";
import Screenshot from "~/pages/mypage/PluginPage/PluginDetailModal/components/Screenshot";
import Update from "~/pages/mypage/PluginPage/PluginDetailModal/components/Update";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

//Assets
import "~/assets/sass/pages/mypage/plugin/detailModal.scss";
import { Helmet } from "react-helmet-async";
import { getTitle } from "~/utils/getTitle";

const PluginDetailModal = ({
	selectedPlugin,
	handleUninstall,
	handleInstallAccordingToLicense,
	license,
}) => {
	const { t } = useTranslation();
	const [currentTab, setCurrentTab] = useState("desc");
	const tabMenuList = [
		{ id: "desc", title: translation.t("MyPage.PluginPage.PluginDetailModal.desc") },
		{
			id: "screenshot",
			title: translation.t("MyPage.PluginPage.PluginDetailModal.screenshot"),
		},
		{ id: "update", title: translation.t("MyPage.PluginPage.PluginDetailModal.update") },
	];

	return (
		<>
			<Helmet>
				<title>
					{getTitle(
						`${t("MyPage.PluginPage.PluginDetailModal.plugin")}}/${selectedPlugin.name}`
					)}
				</title>
			</Helmet>
			<div className="plugin-banner">
				<img src={selectedPlugin.bannerImg} alt={selectedPlugin.name} />
			</div>
			<div className="detail-container">
				<div className="tab-section">
					<TabMenu
						tabItem={tabMenuList}
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
					<If condition={currentTab} {...{ selectedPlugin }}>
						<Case condition={"desc"}>
							<Desc />
						</Case>
						<Case condition={"screenshot"}>
							<Screenshot />
						</Case>
						<OtherWise>
							<Update />
						</OtherWise>
					</If>
				</div>
				<div className="summary-section">
					<div className="img-wrap">
						<img src={selectedPlugin.thumbImg} alt={selectedPlugin.name} />
					</div>
					{selectedPlugin.Auth.use ? (
						<button
							className="btn__outline btn-active plugin-status"
							color={"primary"}
							onClick={(e) => {
								handleUninstall(selectedPlugin);
								e.stopPropagation();
							}}
						>
							UNINSTALL
						</button>
					) : (
						<button
							className="btn__solid btn-inactive plugin-status"
							color={"primary"}
							onClick={(e) => {
								handleInstallAccordingToLicense[license](selectedPlugin);
								e.stopPropagation();
							}}
						>
							INSTALL
						</button>
					)}
					<dl className="plugin-summary">
						<div>
							<dt>{t("MyPage.PluginPage.PluginDetailModal.version")} : </dt>
							<dd>{selectedPlugin.version}</dd>
						</div>
						<div>
							<dt>{t("MyPage.PluginPage.PluginDetailModal.authority")} : </dt>
							<dd>{t("MyPage.PluginPage.PluginDetailModal.allUsers")}</dd>
						</div>
						<div>
							<dt>{t("MyPage.PluginPage.PluginDetailModal.license")} : </dt>
							<dd>{selectedPlugin.license}</dd>
						</div>
						<div>
							<dt>{t("Company.Member.Edit.mem-state")} : </dt>
							<dd className={`${selectedPlugin.Auth.use ? "active" : ""}`}>
								{selectedPlugin.Auth.use
									? `${t("MyPage.PluginPage.PluginDetailModal.active")} (active)`
									: `${t(
											"MyPage.PluginPage.PluginDetailModal.deactive"
									  )} (deactive)`}
							</dd>
						</div>
					</dl>
				</div>
			</div>
		</>
	);
};

export default PluginDetailModal;
