import useRequest from "~/common/hooks/useRequest";
import { useGlobalStore } from "~/store";
import { useNavigate } from "react-router";
import * as Sentry from "@sentry/react";

const useRepository = ({
	method,
	url,
	onSuccess = ({ response }) => response,
	onError = ({ error }) => error,
	type,
	template = (e) => e,
	endpoint,
}) => {
	const request = useRequest(method, url, endpoint);
	const navigate = useNavigate();
	const [, dispatch] = useGlobalStore();

	const call = async (param) => {
		return new Promise(async (resolve) => {
			request
				.asyncCall(template(param), type)
				.then(async (response) => {
					const responseData = await onSuccess({ response, dispatch });
					resolve(responseData);
				})
				.catch(async (error) => {
					const errorData = await onError({ error, dispatch, navigate });
					Sentry.captureException(error);
					resolve(errorData);
				});
		});
	};

	return [call, request.response?.data, request.state];
};

export default useRepository;
