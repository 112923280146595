import React from "react";
import { Case, If } from "~/common/components/If";
import NoData from "~/common/components/NoData";

const Empty = ({ currentTab, info }) => {
	return (
		<div className="vacation-chk-list">
			<If condition={currentTab}>
				<Case condition={"expected"}>
					<NoData main={`${info.name}님의 예정 휴가 일정이 존재하지 않습니다.`} />
				</Case>
				<Case condition={"used"}>
					<NoData main={`${info.name}님의 지난 휴가 일정이 존재하지 않습니다.`} />
				</Case>
				<Case condition={"all"}>
					<NoData main={`${info.name}님의 전체 내역 일정이 존재하지 않습니다.`} />
				</Case>
			</If>
		</div>
	);
};

export default Empty;
