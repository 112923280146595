import React from "react";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";

const Update = () => {
	const { t } = useTranslation();
	return <NoData main={t("MyPage.PluginPage.PluginDetailModal.NoData")} />;
};

export default Update;
