import React from "react";
import ReactDOM from "react-dom";

const DropdownPortal = ({ children, target }) => {
	const el = document.getElementById(target);
	return el ? ReactDOM.createPortal(<>{children}</>, el) : <></>;
};

export default DropdownPortal;
DropdownPortal.displayName = "DropdownPortal";
