import React, { useState } from "react";
import { useTranslation } from "react-i18next";

//Assets
import "~/assets/sass/pages/hr/modal/orgDelete.scss";

export const OrgDelete = ({ row, setModalToggle, handleRemove }) => {
	const { t } = useTranslation();
	const { node } = row;
	const [checked, setChecked] = useState(false);
	const hasChildren = node.children.length > 0;
	return (
		<>
			<div className="modal-content">
				<div className="text">
					<p>
						‘{node.title}’ {t("HR.TeamInfo.modal.orgDelete.delete")}
					</p>
				</div>
				{hasChildren > 0 && (
					<div className="checkbox">
						<label className={`checkbox-custom`}>
							<input
								type="checkbox"
								checked={checked}
								onChange={(e) => setChecked(e.target.checked)}
							/>
							<span className="checkmark" />
							<span className="checkbox-label">
								{t("HR.TeamInfo.modal.orgDelete.allDelete")}
							</span>
						</label>
					</div>
				)}
			</div>
			<div className="modal-footer">
				<button
					disabled={!checked && hasChildren}
					onClick={() => {
						handleRemove();
						setModalToggle(false);
					}}
					key="delete"
					className="btn__solid"
					color="black"
				>
					{t("button.delete")}
				</button>
				<button
					key="cancel"
					className="btn__solid"
					color="gray"
					onClick={() => setModalToggle(false)}
				>
					{t("button.cancel")}
				</button>
			</div>
		</>
	);
};

export default OrgDelete;
