import React from "react";
import { useController } from "react-hook-form";
import PropsProvider from "../PropsProvider";

const DefaultRenderer = ({ checked, onChange, option, labelName, valueName }) => {
	return (
		<div className="checkbox">
			<label className={`checkbox-custom`}>
				<input
					checked={checked}
					onChange={onChange}
					type="checkbox"
					value={option[valueName]}
				/>
				<span className="checkmark" />
				<span className="checkbox-label">{option[labelName]}</span>
			</label>
		</div>
	);
};

/**
 * @param { Props } props
 *
 * @typedef Props
 * @property {object} control - useForm control
 * @property {string} name - field name
 * @property {object} validation - validating object
 * @property {{ label: string, value: any}[]} options
 * @property {string} groupName - checkbox className
 * @property {string} labelName - custom label property name
 * @property {string} valueName - custom value property name
 * @property {boolean} required - required validation
 */
const Checkbox = (props) => {
	const {
		control,
		name,
		validation,
		options,
		children,
		groupName = "",
		labelName = "label",
		valueName = "value",
		required,
	} = props;

	const { field } = useController({
		control,
		name,
		rules: {
			required:
				required === true ? "필수 입력 사항 입니다." : required === null ? " " : required,
			...validation,
		},
	});

	const value = field.value || [];

	return (
		<div className={`${groupName}`}>
			{options.map((option, index) => {
				const onChange = (e) => {
					if (value.find((v) => v === e.target.value)) {
						if (e.target.value === "ALL") {
							field.onChange([]);
						} else {
							field.onChange(value.filter((v) => v !== e.target.value));
						}
					} else {
						field.onChange([...value, e.target.value]);
					}
				};
				const checked = value.includes(option[valueName]?.toString());
				return children ? (
					<PropsProvider
						key={index}
						props={{ checked, onChange, option, labelName, valueName }}
					>
						{children}
					</PropsProvider>
				) : (
					<DefaultRenderer
						key={index}
						{...{ checked, onChange, option, labelName, valueName }}
					/>
				);
			})}
		</div>
	);
};

export default Checkbox;
