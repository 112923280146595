import React, { useEffect, useState } from "react";

import { useGlobalStore } from "~/store";

import MainMenu from "./MainMenu";
import SubMenu from "./SubMenu";
import { MenuToggleIcon } from "~/assets/svg/SidebarMenuIcon";
import useResize from "~/common/hooks/useResize";
import { setSidebarLayout } from "~/store/sidebar/actions";
import { useLocation } from "react-router";
// import PerfectScrollbar from "react-perfect-scrollbar";

const SideBar = ({ currentMenu, currentType, pageClassName }) => {
	const [
		{
			sidebar,
			user,
			payment: { isFailedPayment },
		},
		dispatch,
	] = useGlobalStore();
	const [currentMainMenuId, currentSubMenuId] = currentMenu;
	const [selectedMainMenuId, setSelectedMainMenuId] = useState(currentMainMenuId);

	const laptopSize = 1440;
	const mobileSize = 767;

	const size = useResize().windowInnerSize;

	const dashboardSidebarControl = selectedMainMenuId === "dashboard";

	const location = useLocation();
	const { pathname  } = location;
	

	useEffect(() => {
		switch (true) {
			case size <= mobileSize:
				dispatch(setSidebarLayout("sidebar-hidden"));
				break;
			case size <= laptopSize:
			case pageClassName === "dashboard":
				dispatch(setSidebarLayout("subMenu-hidden"));
				break;
			case size > laptopSize:
				dispatch(setSidebarLayout(dashboardSidebarControl ? "subMenu-hidden" : "init"));
				break;
		}
	}, [size]);

	const handleSidebar = () => {
		switch (true) {
			case size <= mobileSize:
				switch (true) {
					case sidebar.layout !== "sidebar-hidden":
						dispatch(setSidebarLayout("sidebar-hidden"));
						break;
					case sidebar.layout === "sidebar-hidden":
						dispatch(setSidebarLayout("mainMenu-float"));
						break;
				}
				break;
			case size <= laptopSize:
				switch (true) {
					case sidebar.layout !== "sidebar-hidden":
						dispatch(setSidebarLayout("sidebar-hidden"));
						break;
					case sidebar.layout === "sidebar-hidden":
						dispatch(setSidebarLayout("subMenu-hidden"));
						break;
				}
				break;
			case size > laptopSize:
				if (dashboardSidebarControl) {
					switch (sidebar.layout) {
						case "subMenu-hidden":
							dispatch(setSidebarLayout("sidebar-hidden"));
							break;
						case "sidebar-hidden":
							dispatch(setSidebarLayout("subMenu-hidden sidebar-show"));
							break;
						case "subMenu-hidden sidebar-show":
							dispatch(setSidebarLayout("sidebar-hidden"));
							break;
						default:
							break;
					}
				} else {
					switch (sidebar.layout) {
						case "init":
							dispatch(setSidebarLayout("subMenu-hidden"));
							break;
						case "subMenu-hidden":
							dispatch(setSidebarLayout("sidebar-hidden"));
							break;
						case "sidebar-hidden":
							dispatch(setSidebarLayout("subMenu-hidden sidebar-show"));
							break;
						case "subMenu-hidden sidebar-show":
							dispatch(setSidebarLayout("init"));
							break;
						default:
							break;
					}
				}
				break;
		}
	};

	const handleSelectedMainMenu = (id) => {
		switch (true) {
			case size <= mobileSize:
				if (id === selectedMainMenuId) {
					sidebar.layout === "mainMenu-float"
						? dispatch(setSidebarLayout(sidebar.layout + " " + "subMenu-float"))
						: dispatch(setSidebarLayout("mainMenu-float"));
				} else {
					sidebar.layout === "mainMenu-float" &&
						dispatch(setSidebarLayout(sidebar.layout + " " + "subMenu-float"));
				}
				break;
			case size <= laptopSize:
				if (id === selectedMainMenuId) {
					sidebar.layout === "subMenu-hidden"
						? dispatch(setSidebarLayout("subMenu-float"))
						: dispatch(setSidebarLayout("subMenu-hidden"));
				} else {
					sidebar.layout === "subMenu-hidden" &&
						dispatch(setSidebarLayout("subMenu-float"));
				}
				break;
			case size > laptopSize:
				sidebar.layout.includes("subMenu-hidden") && dispatch(setSidebarLayout("init"));
				break;
		}

		setSelectedMainMenuId(id);
	};

	useEffect(()=> {
		pathname.includes('alarm') && dispatch(setSidebarLayout("subMenu-hidden"));
	},[pathname])

	const sidebarContent = () => {
		const getMenuById = (list, id) => {
			return list.find((menu) => menu.id === id);
		};
		const mainMenuList = sidebar.sideBarList[currentType];
		const selectedMainMenu = getMenuById(mainMenuList, selectedMainMenuId);
		const subMenuList = (selectedMainMenu || {}).subMenu;
		const currentMenu = currentMainMenuId === selectedMainMenuId && currentSubMenuId;

		return (
			<>
				<button
					className="menu-button"
					onClick={handleSidebar}
					style={
						isFailedPayment && user.info.role === "admin"
							? { top: "60px" }
							: { top: "0px" }
					}
				>
					<MenuToggleIcon />
				</button>
				{/*<CustomScroll>*/}
				<MainMenu
					list={mainMenuList}
					selectedMenu={selectedMainMenu}
					handleClick={handleSelectedMainMenu}
					currentType={currentType}
					user={user}
				/>
				{/*</CustomScroll>*/}
				<SubMenu
					list={subMenuList}
					currentMenu={currentMenu}
					currentType={currentType}
					user={user}
				/>
			</>
		);
	};

	return (
		<div className={`l__sidebar ${sidebar.layout}`}>
			{/*<PerfectScrollbar>*/}
			{sidebar.sideBarList && sidebarContent()}
			{/*</PerfectScrollbar>*/}
		</div>
	);
};

export default SideBar;
