import React from "react";
import initImg from "~/assets/img/init-org.png";

const EmptyCase = ({ main, sub }) => {
	return (
		<div className="page-init">
			<p className="main">{main}</p>
			<p className="sub">{sub}</p>
			<img src={initImg} alt="empty" />
		</div>
	);
};

export default EmptyCase;
