import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Case, If } from "~/common/components/If";
import { PAGE_STATE } from "~/common/constants/state";
import { WarningIcon } from "~/assets/svg/AlertIcon";
import List from "~/pages/vacation/VacationListPage/All/List";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import Modal from "~/pages/vacation/VacationListPage/All/Modal/Modal";
import Empty from "~/pages/vacation/VacationListPage/Empty";
import { useGlobalStore } from "~/store";
import useAlert from "~/common/hooks/useAlert";
import Pagination from "~/common/components/Pagination";
import TabMenu from "~/common/components/TabMenu";
import { VACATION_STATUS_TEXT } from "~/common/constants/form";
import { DateWithDayName, getBetweenYears } from "~/utils/formatter";
import CustomSelect from "~/common/components/CustomSelect";
import { Trans, useTranslation } from "react-i18next";

const All = () => {
	const {
		vacationTabList,
		info,
		vacationList,
		pageNumber,
		pagingObject,
		historyState,
		year,
		setPageNumber,
		setYear,
		handleTab,
		titleText,
		timeText,
		historyRequest,
	} = useOutletContext();
	const { t } = useTranslation();
	const [vacationAllManageModal, setVacationAllManageModal] = useState(false);
	const [selectedHistory, setSelectedHistory] = useState({});
	const { HrVacationDateAndTime } = selectedHistory;

	const [
		{
			sidebar: { currentPath },
		},
	] = useGlobalStore();

	const [showAuthAlert, AuthAlert] = useAlert();

	const dateText = (history) => {
		let result = "";
		if (history.HrVacationDateAndTime) {
			result =
				history.HrVacationDateAndTime.startDate === history.HrVacationDateAndTime.endDate
					? DateWithDayName(history.HrVacationDateAndTime.startDate)
					: `${DateWithDayName(history.HrVacationDateAndTime.startDate)}
					   ~ 
					  ${DateWithDayName(history.HrVacationDateAndTime.endDate)}`;
		}
		result = "-";

		return result;
	};

	const eventClassNameMap = {
		Vplus: "history plus",
		Vminus: "history minus",
		rejected: "history minus",
		pending: "history",
		confirm: "history grant",
		deleted: "history minus",
	};

	const eventTextMap = (history) => {
		const user = history?.Performer?.User.UserProfile.name ?? "";
		const receiverUser = history?.Receiver?.User.UserProfile.name ?? "";
		const date = DateWithDayName(history.createdDate, "MM월 dd일");
		const receiverDate = DateWithDayName(
			history?.HrVacationDateAndTime?.startDate,
			"MM월 dd일"
		);

		return {
			Vplus() {
				return (
					<>
						<WarningIcon />
						<Trans
							i18nKey="HR.Manage.vacationList.ALL.Vplus"
							components={[{ user }, { date }, { history }]}
						/>
					</>
				);
			},
			Vminus() {
				return (
					<>
						<WarningIcon />
						<Trans
							i18nKey="HR.Manage.vacationList.ALL.Vminus"
							components={[{ user }, { date }, { history }]}
						/>
					</>
				);
			},
			rejected() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.rejected"
						components={[{ user }, { receiverDate }]}
					/>
				);
			},
			pending() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.pending"
						components={[{ user }, { receiverDate }]}
					/>
				);
			},
			confirm() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.confirm"
						components={[{ user }, { receiverDate }]}
					/>
				);
			},
			deleted() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.deleted"
						components={[{ user }, { receiverDate }]}
					/>
				);
			},
			autoVplus() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.autoVplus"
						components={[{ date }, { history }]}
					/>
				);
			},
			reset() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.reset"
						components={[{ user }, { history }]}
					/>
				);
			},
			policyChange() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.policyChange"
						components={[{ user }, { history }]}
					/>
				);
			},
			escalation() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.escalation"
						components={[{ receiverUser }]}
					/>
				);
			},
			destory() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.destory"
						components={[{ receiverUser }]}
					/>
				);
			},
			apply() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.apply"
						components={[{ receiverDate }]}
					/>
				);
			},
			autoConfirm() {
				return (
					<Trans
						i18nKey="HR.Manage.vacationList.ALL.autoConfirm"
						components={[{ receiverDate }]}
					/>
				);
			},
		};
	};

	const statusClassNameMap = {
		pending: "wait",
		confirm: "complete",
		deleted: "delete",
		rejected: "reject",
		reset: "wait",
	};

	useEffect(() => {
		if (!info.idx) return;
		historyRequest.call({ page: pageNumber, size: 10, userIdx: info.idx, year }, "queryString");
	}, [pageNumber, year, info.idx]);

	return (
		<>
			<TabMenu tabItem={vacationTabList} currentTab={"all"} setCurrentTab={handleTab} />
			<div className="c__card">
				<div className="vacation-list-wrap">
					<div className="vacation-chk-header">
						<h3 className="title">{t("HR.Vacation.title")}</h3>
						<CustomSelect
							value={year}
							setValue={(value) => {
								setYear(value);
								setPageNumber(1);
							}}
							options={getBetweenYears(info.joinDate)}
						/>
					</div>
					<If condition={historyState}>
						<Case condition={PAGE_STATE.VIEW}>
							<div className="vacation-chk-list">
								<List
									{...{
										setVacationAllManageModal,
										setSelectedHistory,
										titleText,
										dateText,
										eventClassNameMap,
										eventTextMap,
										vacationList,
										currentPath,
										showAuthAlert,
									}}
								/>
							</div>
							<Pagination
								currentPage={pageNumber}
								totalPage={pagingObject.histories.totalPage}
								setPageNumber={setPageNumber}
							/>
						</Case>
						<Case condition={PAGE_STATE.EMPTY}>
							<Empty currentTab={"all"} info={info} />
						</Case>
					</If>
				</div>
			</div>
			{/*전체내역 관리자 확인 모달*/}
			{Object.keys(selectedHistory).length > 0 && (
				<ModalTemplate
					modalState={vacationAllManageModal}
					setModalToggle={setVacationAllManageModal}
					title={
						<>
							{titleText(HrVacationDateAndTime)}
							{selectedHistory.HrVacationDateAndTime && (
								<span
									className={`permission ${
										statusClassNameMap[
											selectedHistory.HrVacationDateAndTime.status
										] ?? "wait"
									}`}
								>
									{
										VACATION_STATUS_TEXT[
											statusClassNameMap[
												selectedHistory.HrVacationDateAndTime.status
											] ?? "wait"
										]
									}
								</span>
							)}
						</>
					}
					width={460}
					className="vacation-manage-modal all"
				>
					<Modal
						{...{
							selectedHistory,
							setVacationAllManageModal,
							eventTextMap,
							titleText,
							timeText,
							dateText,
						}}
					/>
				</ModalTemplate>
			)}
			<AuthAlert
				message={t("HR.Manage.Alert.AuthAlert")}
				type="warning"
				confirm={t("button.check")}
			/>
		</>
	);
};
export default All;
