import React from "react";
import "~/assets/sass/component/tabMenu.scss";

const TabMenu = ({ tabItem, currentTab, setCurrentTab }) => {
	return (
		<ul className="c__tabMenu">
			{tabItem.map((t, index) => {
				return (
					<li
						key={index}
						className={`c__tabMenuItem ${currentTab === t.id && "active"} ${
							t.disable ? "disabled" : ""
						}`}
						onClick={() => !t.disable && setCurrentTab(t.id)}
					>
						{t.title}
					</li>
				);
			})}
		</ul>
	);
};

export default TabMenu;
