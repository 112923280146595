import {
	SET_SELECTED_IDX,
	CLEAR_SELECTED_IDX,
	SET_BASIC_INFO,
	SET_HISTORY,
	SET_MEMBER,
	SET_SHAREHOLDER,
	SET_FINANCE,
	SET_PRODUCT,
	SET_OUTCOME,
} from "./actions";

/**
 * @typedef basicInfo
 * @property {string} name
 * @property {string} regNum
 * @property {string} type
 * @property {string} bizType
 * @property {string} bizStatus
 * @property {string} ceoName
 * @property {string} ceoContact
 * @property {string} ceoEmail
 * @property {string} address
 * @property {string} addressDetail
 * @property {string} zipCode
 * @property {string} country
 * @property {string} homepage
 * @property {string} introduction
 * @property {string} description
 * @property {string} industryField
 * @property {string} logoSqu
 * @property {string} logoRec
 * @property {string} sales
 * @property {string} salesYear
 * @property {string} capital
 * @property {string} faceValue
 * @property {string} totalStock
 * @property {string} settlement
 * @property {string} comValue
 * @property {string} fundUse
 * @property {string} exitPlan
 * @property {string} sign
 * @property {Date} estDate
 * @property {Date} regDate
 * @property {Date} updateDate
 * @property {number=} employeeNum
 * @property {boolean} listed
 * @property {[]} IRBasicInfoAttraction
 * @property {[]} IRBasicInfoSubsidy
 */

/**
 * @typedef HISTORY
 * @property {number} idx
 * @property {string} content
 * @property {Date} date
 * @property {Date} regDate
 * @property {Date} updateDate
 */

/**
 * @typedef SHAREHOLDER
 * @property {number} idx
 * @property {string} name
 * @property {string} total
 * @property {string} ratio
 * @property {string} etc
 * @property {Date} regDate
 * @property {Date} updateDate
 */

/**
 * @typedef FINANCE
 * @property {number=} idx
 * @property {string} quickAsset 당좌자산
 * @property {string} inventories 재고자산
 * @property {string} otherCurrentAsset 기타유동자산
 * @property {string} otherNonCurrentAsset 기타비유동자산
 * @property {string} investment 투자자산
 * @property {string} tangibleAsset 유형자산
 * @property {string} inTangibleAsset 무형자산
 * @property {string} currentLiability 유동부채
 * @property {string} nonCurrentLiability 비유동부채
 * @property {string} capital 자본금
 * @property {string} surplus 자본잉여금
 * @property {string} capitalAdjustment 자본조정
 * @property {string} otherComprehensiveIncome 기타포괄손익누계액
 * @property {string} retainedEarnings 이익잉여금
 * @property {string} sales 매출액
 * @property {string} cost 매출원가
 * @property {string} sga 판매관리비
 * @property {string} otherIncome 영업외수익
 * @property {string} nonOperatingExpenses 영업외비용
 * @property {string} tax 법인세
 * @property {[]} IRFinanceDept
 */

/**
 * @typedef PRODUCT
 * @property {number=} idx
 * @property {string} description 설명
 * @property {string} differentiation 특징 및 차별성
 * @property {string} mainTech 주요보유기술
 * @property {string} advantages 장점/특징/차별성
 * @property {string} marketResearch 시장 리서치
 * @property {string} positioning 포지셔닝
 * @property {Date} regDate
 * @property {Date} updateDate
 * @property {[]} IRProductCustomer 주요 매출처
 * @property {[]} IRProductMarket 주요 목표 시장 및 규모
 * @property {[]} IRProductIp 지적재산권 현황
 * @property {[]} IRProductPlayer 시장 플레이어
 */

/**
 * @typedef OUTCOME
 * @property {number=} idx
 * @property {Date} regDate
 * @property {Date} updateDate
 * @property {[]} IROutcomeInvest 투자
 * @property {[]} IROutcomeAward 수상실적
 * @property {[]} IROutcomeExport 수출
 * @property {[]} IROutcomeFund 정책자금
 * @property {[]} IROutcomePlan 주요계획
 */

/**
 * @typedef IR_STATE
 * @property {number=} selectedIdx
 * @property {basicInfo} basicInfo
 * @property {HISTORY[]} history
 * @property {SHAREHOLDER[]} shareholder
 * @property {FINANCE} finance
 * @property {PRODUCT} product
 */

/**
 * @type {IR_STATE}
 */
export const INITIAL_STATE = {
	selectedIdx: null,

	basicInfo: {
		name: "",
		regNum: "",
		type: "",
		bizType: "",
		bizStatus: "",
		ceoName: "",
		ceoContact: "",
		ceoEmail: "",
		address: "",
		addressDetail: "",
		zipCode: "",
		country: "",
		homepage: "",
		introduction: "",
		description: "",
		industryField: "",
		logoSqu: "",
		logoRec: "",
		sales: "",
		salesYear: "",
		capital: "",
		faceValue: "",
		totalStock: "",
		settlement: "",
		comValue: "",
		fundUse: "",
		exitPlan: "",
		sign: "",
		estDate: new Date(),
		regDate: new Date(),
		updateDate: new Date(),
		employeeNum: null,
		listed: false,
		IRBasicInfoAttraction: [],
		IRBasicInfoSubsidy: [],
	},
	history: [],
	member: [],
	shareholder: [],
	finance: {
		idx: undefined,
		quickAsset: "",
		inventories: "",
		otherCurrentAsset: "",
		otherNonCurrentAsset: "",
		investment: "",
		tangibleAsset: "",
		inTangibleAsset: "",
		currentLiability: "",
		nonCurrentLiability: "",
		capital: "",
		surplus: "",
		capitalAdjustment: "",
		otherComprehensiveIncome: "",
		retainedEarnings: "",
		sales: "",
		cost: "",
		sga: "",
		otherIncome: "",
		nonOperatingExpenses: "",
		tax: "",
		regDate: new Date(),
		updateDate: new Date(),
		IRFinanceDept: [],
	},
	product: {
		idx: null,
		description: "",
		differentiation: "",
		mainTech: "",
		advantages: "",
		marketResearch: "",
		positioning: "",
		regDate: new Date(),
		updateDate: new Date(),
		IRProductCustomer: [],
		IRProductMarket: [],
		IRProductIp: [],
		IRProductPlayer: [],
	},
	outcome: {
		idx: null,
		regDate: new Date(),
		updateDate: new Date(),
		IROutcomeInvest: [],
		IROutcomeAward: [],
		IROutcomeExport: [],
		IROutcomeFund: [],
		IROutcomePlan: [],
	},
};

export const REDUCER = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SET_SELECTED_IDX:
			return {
				...state,
				selectedIdx: action.payload.idx,
			};
		case CLEAR_SELECTED_IDX:
			return {
				...state,
				...INITIAL_STATE,
			};
		case SET_BASIC_INFO:
			return {
				...state,
				basicInfo: Object.assign({}, state.basicInfo, action.payload.basicInfo),
			};
		case SET_HISTORY:
			return {
				...state,
				history: action.payload.history,
			};
		case SET_MEMBER:
			return {
				...state,
				member: action.payload.member,
			};
		case SET_SHAREHOLDER:
			return {
				...state,
				shareholder: action.payload.shareholder,
			};
		case SET_FINANCE:
			return {
				...state,
				finance: action.payload.finance,
			};
		case SET_PRODUCT:
			return {
				...state,
				product: action.payload.product,
			};
		case SET_OUTCOME:
			return {
				...state,
				outcome: action.payload.outcome,
			};
		default:
			return state;
	}
};
