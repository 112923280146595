import { dateToString } from "~/utils/formatter";
import { add } from "date-fns";
import { setIsFailedPayment } from "./actions";

export const PAYMENT_INFO = {
	method: "get",
	url: `admin/purchase/history/?startDate=${dateToString(
		add(new Date(), { years: -1 }),
		"yyyy-MM-dd"
	)}&endDate=${dateToString(new Date(), "yyyy-MM-dd")} 23:59:59&pageSize=10&page=1`,
	onSuccess: async ({ response, dispatch }) => {
		const lastPaymentInfo =
			response.data.histories[0].length === 0
				? true
				: response.data.histories[0][0].purchaseResult;
		dispatch(setIsFailedPayment(!lastPaymentInfo));
		return response.data;
	},
};
