/**
 * @param {number} start
 * @param {number} end
 * @example range(0,5)
 */
export const range = (start, end) => {
	if (start < end) {
		return [...Array(end - start + 1)].map((_, i) => start + i);
	} else {
		return [...Array(start - end + 1)].map((_, i) => end + i).reverse();
	}
};
