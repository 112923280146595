import React, { useEffect, useState } from "react";
import { CollapseArrow } from "~/assets/svg/InterfacesIcon";
import RequestMsg from "~/pages/invest/EvaluatePage/EvaluateDetail/View/components/requestMsg";
import AcceptInfo from "~/pages/invest/EvaluatePage/EvaluateDetail/View/components/acceptInfo";
import { useGlobalStore } from "~/store";
import { useTranslation } from "react-i18next";

//Assets
import defaultAvatar from "~/assets/img/defaultImg/profile-large.png";
import defaultLogo from "~/assets/img/defaultImg/profile-large.png";

const Complete = ({ cardStep }) => {
	const { t } = useTranslation();
	const [
		{
			evaluate: {
				currentEvaluate: { Judge, comment, score, VcCompany },
			},
		},
	] = useGlobalStore();
	const [toggle, setToggle] = useState(false);

	useEffect(() => {
		setToggle(false);
	}, [cardStep]);

	return (
		<>
			<section className="c__card message">
				<RequestMsg />
			</section>
			<AcceptInfo />
			<section className={`c__card complete ${toggle && "active"}`}>
				<div className="top">
					<h5 className="percent">
						{score ? score : 0}
						<i>{t("Invest.Evaluate.EvaluateDetail.View.Complete.percentText")}</i>
					</h5>
					<p className="main">{t("Invest.Evaluate.EvaluateDetail.View.Complete.main")}</p>
					<p className="sub">{t("Invest.Evaluate.EvaluateDetail.View.Complete.sub")}</p>
				</div>
				<div className="content">
					<ul className="commentary-wrap">
						{Judge.map((judge, j_idx) => {
							return (
								<li className="commentary" key={j_idx}>
									<p className="img-wrap">
										<img
											src={judge.img ? judge.img : defaultAvatar}
											onError={(e) => {
												e.target.src = defaultAvatar;
											}}
											alt={judge.name}
										/>
									</p>
									<dl className="commentary-text">
										<dt className="judge">
											{VcCompany.name}
											<b>{judge.name}</b>
										</dt>
										<dd className="text">{judge.comment}</dd>
									</dl>
								</li>
							);
						})}
					</ul>
					<div className="total-commentary-wrap">
						<div className="img-wrap">
							<img
								src={VcCompany.logoSqu ?? defaultLogo}
								onError={(e) => {
									e.target.src = defaultAvatar;
								}}
								alt={VcCompany.name}
							/>
						</div>
						<div className="total-commentary">
							<p className="vc">
								{VcCompany.name}{" "}
								{t("Invest.Evaluate.EvaluateDetail.View.Complete.vc")}
							</p>
							<p className="text">{comment}</p>
						</div>
					</div>
				</div>
				<button
					type="button"
					className={`btn-collapse ${
						toggle ? "btn-arrow-close active" : "btn-arrow-open"
					}`}
					onClick={() => setToggle(!toggle)}
				>
					{toggle ? t("button.close") : t("button.open")}
					<CollapseArrow />
				</button>
			</section>
		</>
	);
};

export default Complete;
