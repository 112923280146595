import CryptoJS from "crypto-js";
import { CRYPTO_KEY } from "~/common/constants/envKeys";

export default class cryptoData {
	static enctypt = (data) => {
		return CryptoJS.AES.encrypt(JSON.stringify(data), CRYPTO_KEY).toString();
	};

	static dectypt = (ciphertext) => {
		const bytes = CryptoJS.AES.decrypt(ciphertext, CRYPTO_KEY);
		return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	};
}
