import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";

import Form, { Group } from "~/common/components/Form";
import Select from "~/common/components/Select";
import Input from "~/common/components/Input";
import Textarea from "~/common/components/Textarea";
import NumberInput from "~/common/components/NumberInput";
import DatePicker from "~/common/components/DatePicker";

import "~/assets/sass/pages/ir/basicInfo.scss";
import { PlusIcon } from "~/assets/svg/MathIcon";
import SignatureSelectModal from "~/common/components/SignatureSelectModal";
import { useGlobalStore } from "~/store";
import { dateFormatter } from "~/utils/formatter";
import { range } from "~/utils/array";
import DynamicFields from "./DynamicFields";
import SingleImageUploader from "~/common/components/ImageUploader/SingleImageUploader";
import useRequest from "~/common/hooks/useRequest";
import { BIZ_TYPE } from "~/common/constants/form";
import useRepository from "~/common/hooks/useRepository";
import { USER_INFO } from "~/store/user/repository";
import useAlert from "~/common/hooks/useAlert";
import axios from "axios";
import * as env from "~/common/constants/env";
import { optionGeneratorByObjectValues } from "~/common/components/CustomSelect";
import { useTranslation } from "react-i18next";
import SignatureUploadModal from "~/pages/mypage/admin/SignaturePage/SignatureUploadModal";
import { API_STATE } from "~/common/constants/state";

export const biznumQuery = (data) => {
	return axios.patch(`${env.EM_COMPANY_API_URL}/company/num`, data);
};

const Edit = ({ showViewPage, Header }) => {
	const { t } = useTranslation();
	const [
		{
			ir: {
				selectedIdx: irIdx,
				basicInfo: {
					IRBasicInfoAttraction: Attraction,
					IRBasicInfoSubsidy: Subsidy,
					...BasicInfo
				},
			},
		},
	] = useGlobalStore();
	const [getUserInfo] = useRepository(USER_INFO);

	const basicForm = useForm({ defaultValues: { irIdx, Attraction, Subsidy, BasicInfo } });

	const irMutation = useRequest("post", "ir/basicInfo/edit");
	const signatureListRequest = useRequest("get", `admin/signature?size=6&page=1`);
	const userSignatureRequest = useRequest("get", "user/signature");

	const [signatureModal, setSignatureModal] = useState(false);
	const [digitalDataModal, setDigitalDataModalModal] = useState(false);

	const [tempSignature, setTempSignature] = useState();

	useEffect(() => {
		userSignatureRequest.singleCall();
		signatureListRequest.singleCall();
	}, []);

	useEffect(() => {
		switch (signatureListRequest.state) {
			case API_STATE.done:
				setTempSignature(
					signatureListRequest.response.data.list.find((s) => s.img === signature)
				);
				break;
			case API_STATE.error:
				console.log("error > ", signatureListRequest.error);
				break;
			default:
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signatureListRequest.state, signatureListRequest.error, signatureListRequest.response]);

	const submit = async (data) => {
		irMutation
			.asyncCall(data)
			.then(() => {
				getUserInfo().then(showViewPage);
			})
			.catch((msg) => console.log(msg));
	};

	const findCompany = () => {
		biznumQuery({ bizNum: basicForm.getValues("BasicInfo.regNum") })
			.then((res) => {
				const data = res.data.data;
				if (data !== null) {
					basicForm.setValue("BasicInfo.name", data.companyName);
					basicForm.setValue("BasicInfo.ceoName", data.representationName);
					// basicForm.setValue("BasicInfo.type", data.companyType); //기업구분
					basicForm.setValue("BasicInfo.bizType", data.businessCategoryDesc);
					basicForm.setValue(
						"BasicInfo.estDate",
						dateFormatter(data.establishmentDate, "-")
					);
					basicForm.setValue("BasicInfo.employeeNum", data.employeeCount);
					basicForm.setValue("BasicInfo.address", data.address);
					basicForm.setValue("BasicInfo.homepage", data.homepage);
					const tel = data.tel.replace(/\D/g, "");
					basicForm.setValue("BasicInfo.ceoContact", tel);
				} else {
					alert(t("IR.IrDetail.BasicInfo.Edit.Index.noCompanyt"));
				}
			})
			.catch();
	};

	const [showCancelAlert, CancelAlert] = useAlert();

	const buttonList = [
		<button
			type="button"
			className="btn__solid"
			color="gray"
			onClick={() => {
				showCancelAlert().then(() => showViewPage());
			}}
		>
			{t("button.cancel")}
		</button>,
		<button
			type="button"
			className="btn__solid"
			color="primary"
			onClick={basicForm.handleSubmit(submit)}
		>
			{t("button.save")}
		</button>,
	];

	const signature = basicForm.watch("BasicInfo.sign");
	const setSignature = (signature) => {
		basicForm.setValue("BasicInfo.sign", signature);
	};

	const MonthOption = range(1, 12).map((i) => ({
		label: `${i}${t("contants.form.LICENSE_PERIOD.month")}`,
		value: `${i}${t("contants.form.LICENSE_PERIOD.month")}`,
	}));

	const BizType = BIZ_TYPE();

	return (
		<>
			<Header buttonList={buttonList} />
			<div className="ir-view">
				<div className="c__card basicInfo-edit">
					{/*<div className="date">*/}
					{/*	Updated: {dateToString(BasicInfo.updateDate, "yyyy-MM-dd")}*/}
					{/*</div>*/}
					<Form useForm={basicForm}>
						<Input
							name="BasicInfo.irTitle"
							className="ir-title"
							validation={{
								maxLength: {
									value: 15,
									message: t("IR.IrDetail.BasicInfo.Edit.Index.textLimit"),
								},
							}}
							placeholder={t("IR.IrDetail.BasicInfo.Edit.IR-Title")}
							required
						/>
					</Form>
					<form className="data-form">
						<section className="input-group-section">
							<h3 className="section-title">{t("Company.Info.Header.sub-title")}</h3>
							<Form useForm={basicForm}>
								{/*<Input*/}
								{/*	label={t("Community.board-table.title")}*/}
								{/*	name="BasicInfo.irTitle"*/}
								{/*	validation={{*/}
								{/*		maxLength: {*/}
								{/*			value: 15,*/}
								{/*			message: t(*/}
								{/*				"IR.IrDetail.BasicInfo.Edit.Index.textLimit"*/}
								{/*			),*/}
								{/*		},*/}
								{/*	}}*/}
								{/*	required*/}
								{/*/>*/}
								<Group
									label={t("Company.Info.registration-num")}
									className={"registration-num"}
									required
								>
									<NumberInput name="BasicInfo.regNum" type="company" required />
									{/* <button
										type="button"
										className="btn__outline btn__api angled"
										color="primary"
										onClick={findCompany}
									>
										{t("IR.IrDetail.BasicInfo.Edit.Index.import")}
									</button> */}
								</Group>
								<Input
									label={t("IR.IrDetail.BasicInfo.Edit.Index.companyName")}
									name="BasicInfo.name"
									required
								/>
								<Input
									label={t("IR.IrDetail.BasicInfo.Edit.Index.ceo")}
									name="BasicInfo.ceoName"
									required
								/>
								<Select
									label={t("IR.IrDetail.BasicInfo.Edit.Index.bizmanType")}
									name="BasicInfo.type"
									options={optionGeneratorByObjectValues(BizType)}
									required
								/>
								<Input
									label={t("IR.IrDetail.BasicInfo.Edit.Index.bizType")}
									name="BasicInfo.bizType"
									required
								/>
								<Input
									label={t("IR.IrDetail.BasicInfo.Edit.Index.bizStatus")}
									name="BasicInfo.bizStatus"
								/>
								<DatePicker
									label={t("IR.IrDetail.BasicInfo.Edit.Index.estDate")}
									name="BasicInfo.estDate"
								/>
								<NumberInput
									label={t("Company.Info.employeeNum")}
									name="BasicInfo.employeeNum"
									string
									required
								/>
								<Input label={t("Company.Info.address")} name="BasicInfo.address" />
								<Input
									label={t("IR.IrDetail.BasicInfo.Edit.Index.homepage")}
									name="BasicInfo.homepage"
								/>
								<NumberInput
									label={t("IR.IrDetail.BasicInfo.Edit.Index.tel")}
									name="BasicInfo.ceoContact"
									type="phone"
									string
								/>
							</Form>
						</section>
						<section className="input-group-section">
							<h3 className="section-title">
								{t("IR.IrDetail.BasicInfo.Edit.Index.title")}
							</h3>
							<Form useForm={basicForm}>
								<NumberInput
									label={t("IR.IrDetail.BasicInfo.Edit.Index.label.capital")}
									name="BasicInfo.capital"
									string
								/>
								<NumberInput
									label={t("IR.IrDetail.BasicInfo.Edit.Index.label.faceValue")}
									name="BasicInfo.faceValue"
									string
								/>
								<NumberInput
									label={t("IR.IrDetail.BasicInfo.Edit.Index.label.totalStock")}
									name="BasicInfo.totalStock"
									string
								/>
								<Select
									label={t("IR.IrDetail.BasicInfo.Edit.Index.label.settlement")}
									name="BasicInfo.settlement"
									options={MonthOption}
								/>
								<NumberInput
									label={t("IR.IrDetail.BasicInfo.Edit.Index.label.comValue")}
									name="BasicInfo.comValue"
									string
								/>
							</Form>
						</section>
						<section id="investment">
							<Form useForm={basicForm} className="sub-section vertical">
								<Textarea
									label={t("IR.IrDetail.BasicInfo.Edit.Index.label.fundUse")}
									name="BasicInfo.fundUse"
								/>
								<Textarea
									label={t("IR.IrDetail.BasicInfo.Edit.Index.label.exitPlan")}
									name="BasicInfo.exitPlan"
								/>
							</Form>
							<div className="c__form vertical sub-section">
								<DynamicFields
									useForm={basicForm}
									name="Attraction"
									title={t(
										"IR.IrDetail.BasicInfo.Edit.Index.dynamicFields.title1"
									)}
								/>
								<DynamicFields
									useForm={basicForm}
									name="Subsidy"
									title={t(
										"IR.IrDetail.BasicInfo.Edit.Index.dynamicFields.title2"
									)}
								/>
							</div>
						</section>
						<section>
							<div className="seal-area">
								<span className="seal-label">Signature</span>
								<div className="seal-uploader">
									{/*서명 등록전*/}
									{signature ? (
										<SingleImageUploader
											img={signature}
											setImg={setSignature}
											width={236}
											height={122}
											deletable={false}
											editable={true}
											handleEdit={(e) => {
												e.preventDefault();
												setSignatureModal(true);
											}}
										/>
									) : (
										<button
											className="dashed-btn plus-btn"
											type="button"
											onClick={() => setSignatureModal(true)}
										>
											<PlusIcon />
											{t("Shareholder.ShareholderDetail.Edit.Index.button")}
										</button>
									)}
								</div>
							</div>
						</section>
					</form>
				</div>
			</div>

			{/* 법인인감 등록하기 모달 */}
			<ModalTemplate
				modalState={signatureModal}
				setModalToggle={setSignatureModal}
				title={t("Shareholder.ShareholderDetail.Edit.Index.button")}
				width={700}
				height={"unset"}
				className={"modal-sign"}
				closeable={false}
			>
				<SignatureSelectModal
					selectedSignature={signature}
					setSelectedSignature={setSignature}
					setDigitalDataModalModal={setDigitalDataModalModal}
					signatureListRequest={signatureListRequest}
				/>
			</ModalTemplate>

			{/* 법인인감 Policy 모달 */}
			<ModalTemplate
				title={t("MyPage.admin.Signature.index.modalTitle")}
				modalState={digitalDataModal}
				setModalToggle={setDigitalDataModalModal}
				width={500}
				height={380}
				className="modal-signatureUpload"
			>
				<SignatureUploadModal {...{ getSignatureList: () => {}, signatureListRequest }} />
			</ModalTemplate>

			<CancelAlert
				message={t("IR.IrDetail.BasicInfo.Edit.Index.cancelAlert")}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
		</>
	);
};

export default Edit;
