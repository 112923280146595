import React, { useState } from "react";
import { dateToString } from "~/utils/formatter";
import useRequest from "~/common/hooks/useRequest";
import { isEqual } from "date-fns";
import { useTranslation } from "react-i18next";

const EditDefaultWorkTime = ({ setModalToggle, userWorkType, getSchedule, getUserWorkType }) => {
	const { t } = useTranslation();
	const { defaultWorkTimes, workTimes } = userWorkType;
	const [defaultTime, setDefaultTime] = useState(defaultWorkTimes);

	const handleClose = () => {
		setModalToggle(false);
	};

	const modifyDefaultWorkTimeRequest = useRequest("put", `hr/vacation/defaultWorkTime`);

	const handleSubmit = async () => {
		const requestData = {
			defaultWorkStartTime: dateToString(defaultTime.startTime, "HH:mm"),
			defaultWorkEndTime: dateToString(defaultTime.endTime, "HH:mm"),
		};

		await modifyDefaultWorkTimeRequest
			.asyncCall(requestData)
			.then(async () => {
				await getUserWorkType();
				await getSchedule();
				setModalToggle(false);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<>
			<div className="modal-content">
				{workTimes.map((time, t_idx) => (
					<label
						className={`c__radio ${
							isEqual(defaultTime.startTime, time.startTime) ? "selected" : ""
						}`}
						key={t_idx}
					>
						<input
							checked={isEqual(defaultTime.startTime, time.startTime)}
							type="radio"
							value={defaultTime.startTime}
							name="defaultTime"
							onChange={() => setDefaultTime(time)}
						/>
						<span className="radio-label">{dateToString(time.startTime, "HH:mm")}</span>
						<span className="checkmark" />
					</label>
				))}
			</div>
			<div className="modal-footer">
				<button type="button" className="btn__solid" color="gray" onClick={handleClose}>
					{t("button.cancel")}
				</button>
				<button type="button" className="btn__solid" color="primary" onClick={handleSubmit}>
					{t("button.check")}
				</button>
			</div>
		</>
	);
};

export default EditDefaultWorkTime;
