import React from "react";
import { useTranslation } from "react-i18next";
import NewLandingLayout from "~/layouts/LandingLayout/index";
import {
	WomanStripe,
	BagDarkIcon,
	DoorDarkIcon,
	DocDarkIcon,
	ClockDarkIcon,
	ExpenseDarkIcon,
	ServiceDarkIcon,
} from "~/assets/svg/HrManagementIcon";
import Testimonials from "~/common/components/Testimonials";
import SlideHorizontalFade from "~/common/components/SlideHorizontalFade";
import dashboard from "~/assets/img/persona/hrManagementPage/Dashboard.png";
import dashboard1 from "~/assets/img/persona/hrManagementPage/Dashboard1.png";
import dashboardFade from "~/assets/img/persona/hrManagementPage/Dashboard-fade.png";
import dashboardFade1 from "~/assets/img/persona/hrManagementPage/Dashboard-fade1.png";
import shakeHandRight from "~/assets/img/persona/hrManagementPage/Shake-hand-left.png";
import shakeHandLeft from "~/assets/img/persona/hrManagementPage/Shake-hand-right.png";

export default function HrManagement() {
	const { t } = useTranslation();
	const employeeItems = [
		{
			title: t("HrManagement.employees.items.1.title"),
			description: t("HrManagement.employees.items.1.description"),
			color: "bg-red-50",
		},
		{
			title: t("HrManagement.employees.items.2.title"),
			description: t("HrManagement.employees.items.2.description"),
			color: "bg-orange-100",
		},
	];
	const BenefitItems = [
		{
			icon: <BagDarkIcon />,
			title: t("HrManagement.benefits.items.1"),
			color: "bg-green-50",
		},
		{
			icon: <DoorDarkIcon />,
			title: t("HrManagement.benefits.items.2"),
			color: "bg-gray-100",
		},
		{
			icon: <DocDarkIcon />,
			title: t("HrManagement.benefits.items.3"),
			color: "bg-violet-50",
		},
	];
	const BenefitItems2 = [
		{
			icon: <ClockDarkIcon />,
			title: t("HrManagement.benefits.items.4"),
			color: "bg-yellow-50",
		},
		{
			icon: <ExpenseDarkIcon />,
			title: t("HrManagement.benefits.items.5"),
			color: "bg-red-50",
		},
		{
			icon: <ServiceDarkIcon />,
			title: t("HrManagement.benefits.items.6"),
			color: "bg-green-50",
		},
	];

	return (
		<NewLandingLayout>
			<section>
				<main className="h-full w-full max-w-[1920px] mx-auto">
					<section className="bg-[#DEEDF0]">
						<div className="w-[95%] sm:w-[85%] m-auto pt-48 md:flex block justify-between">
							<div className="lg:flex block justify-between w-full">
								<div className="mt-[-50px] md:mb-0 mb-16">
									<div className="text-[#0D9488] font-semibold">
										{t("HrManagement.header-green")}
									</div>
									<div className="sm:text-4xl text-3xl font-semibold w-full">
										{t("HrManagement.header")}
									</div>
									<div className="pt-4 m lg:mb-8 sm:mb-0">
										{t("HrManagement.sub-header")}
									</div>
								</div>
								<div className="flex justify-center">
									<div className="">
										<WomanStripe />
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="w-[95%] sm:w-[85%] m-auto">
						<div className="py-16">
							<div className="text-center">
								<div className="sm:text-4xl text-3xl font-semibold text-center">
									{t("HrManagement.benefits.header")}
								</div>
								<div className="md:w-[40%] w-full m-auto py-2">
									{t("HrManagement.benefits.sub-header")}
								</div>
							</div>
							<div className="md:flex block justify-between md:my-12 mt-8">
								{BenefitItems.map((item, index) => (
									<div
										key={index}
										className={`${item.color} p-8 rounded-bl-2xl rounded-tr-2xl md:w-[31%] w-full md:mb-0 mb-4`}
									>
										<div className="font-semibold text-lg text-green-950 my-4">
											{item.title}
										</div>
										<div>{item.icon}</div>
									</div>
								))}
							</div>
							<div className="md:flex block justify-between md:my-12 mt-8">
								{BenefitItems2.map((item, index) => (
									<div
										key={index}
										className={`${item.color} p-8 rounded-tl-2xl rounded-br-2xl md:w-[31%] w-full md:mb-0 mb-4`}
									>
										<div>{item.icon}</div>
										<div className="font-semibold text-lg text-green-950 my-4">
											{item.title}
										</div>
									</div>
								))}
							</div>
						</div>
					</section>
					<section className="bg-[#042F2E] text-white pt-8 max-w-[1920px] text-center">
						<div className="mx-auto max-w-[1920px] w-[95%] sm:w-[85%]">
							<div className="mt-24 pb-24">
								<div className="font-semibold">{t("Eor.payroll.header")}</div>
								<div className="sm:text-4xl text-3xl font-semibold">
									{t("Eor.payroll.title")}
								</div>
								<div className="md:w-[50%] w-full m-auto pt-4 pb-8">
									{t("Eor.payroll.description")}
								</div>
								<section>
									<SlideHorizontalFade
										props={[
											{
												img: dashboard,
												imgFade: dashboardFade,
												title: t(
													"HrManagement.onboard-offboard.slide-items.1.title"
												),
												description: t(
													"HrManagement.onboard-offboard.slide-items.1.description"
												),
											},
											{
												img: dashboardFade1,
												imgFade: dashboard1,
												title: t(
													"HrManagement.onboard-offboard.slide-items.1.title"
												),
												description: t(
													"HrManagement.onboard-offboard.slide-items.1.description"
												),
											},
										]}
									/>
								</section>
							</div>
						</div>
					</section>
					<section>
						<div className="w-[95%] sm:w-[85%] m-auto md:py-32 py-16">
							<div className="text-center">
								<div className="text-[#0D9488] font-semibold">
									{t("HrManagement.employees.header-green")}
								</div>
								<div className="sm:text-4xl text-3xl font-semibold">
									{t("HrManagement.employees.header")}
								</div>
								<div className="py-3">
									{t("HrManagement.employees.description")}
								</div>
							</div>
							<div className="flex md:flex-row flex-col justify-center md:gap-8 gap-4 pt-8">
								{employeeItems.map((item, index) => (
									<div
										className={`${item.color} py-4 px-8 rounded-2xl h-[200px]`}
										key={index}
									>
										<div className="text-lg font-semibold py-2">
											{item.title}
										</div>
										<div>{item.description}</div>
									</div>
								))}
							</div>
						<div className="mt-16">
							<div className="flex lg:flex-row flex-col justify-between items-center gap-8 py-8">
								<div className="lg:w-[50%] w-full">
									<div className="text-[#0D9488] font-semibold">
										{t(
											"HrManagement.employees.expense-management.header-green"
										)}
									</div>
									<div className="sm:text-4xl text-3xl font-semibold">
										{t("HrManagement.employees.expense-management.header")}
									</div>
									<div>
										{t("HrManagement.employees.expense-management.description")}
									</div>
								</div>
								<div>
									<img src={shakeHandRight} alt=""/>
								</div>
							</div>
							<div className="flex lg:flex-row flex-col-reverse justify-between items-center gap-8 pt-8">
								<div>
									<img src={shakeHandLeft} alt=""/>
								</div>
								<div className="lg:w-[50%] w-full">
									<div className="text-[#0D9488] font-semibold">
										{t("HrManagement.employees.time-tracking.header-green")}
									</div>
									<div className="sm:text-4xl text-3xl font-semibold">
										{t("HrManagement.employees.time-tracking.header")}
									</div>
									<div>
										{t("HrManagement.employees.time-tracking.description")}
									</div>
								</div>
							</div>
						</div>
						</div>
					</section>
					<hr />
					<Testimonials />
				</main>
			</section>
		</NewLandingLayout>
	);
}
