import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import iCalendarPlugin from "@fullcalendar/icalendar";

//Assets
import "~/assets/sass/vendors-extensions/fullcalendar.scss";

/**
 * @param { Props } props
 *
 * @typedef Props
 * @property {object} headerToolbar - 캘린더 상당 버튼, 타이틀
 * @property {object} customButtons - headerToolbar에서 사용되는 버튼 커스텀
 * @property {string=} calendarEvents -
 * @property {string=} handleDateClick -
 * @property {string=} renderEventContent -
 * @property {string=} refName -
 */
const Calendar = (props) => {
	const {
		headerToolbar,
		customButtons,
		calendarEvents,
		handleDateClick,
		renderEventContent,
		refName,
	} = props;

	return (
		<>
			<FullCalendar
				plugins={[interactionPlugin, dayGridPlugin, iCalendarPlugin]}
				initialView="dayGridMonth"
				headerToolbar={headerToolbar}
				customButtons={customButtons}
				events={calendarEvents}
				eventClick={handleDateClick}
				eventContent={renderEventContent}
				ref={refName}
				// height={1000}
			/>
		</>
	);
};

export default Calendar;
