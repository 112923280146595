import React, { useEffect, useState } from "react";
import useRepository from "~/common/hooks/useRepository";
import * as env from "~/common/constants/env";
import { PAGE_STATE } from "~/common/constants/state";
import { dateFormatter, thousandFormatter } from "~/utils/formatter";
import { Trans, useTranslation } from "react-i18next";

// Store
import { useGlobalStore } from "~/store";
import { OKCEO_DUTY_DATA } from "~/store/tax/repository";

// Components
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import { OkceoNoContent } from "~/pages/Tax/Dashboard/View";

// Assets
// import { ChartIcon } from "~/assets/svg/InterfacesIcon";

const TaxCard = ({ okceoToken, selectedCompany }) => {
	const { t } = useTranslation();
	const [
		{
			tax: { duty, logged },
		},
	] = useGlobalStore();
	const [cardState, setCardState] = useState(PAGE_STATE.LOADING);
	const [getDutyData] = useRepository(OKCEO_DUTY_DATA);

	useEffect(() => {
		getDutyData({ selectedCompany: selectedCompany });
	}, []);

	useEffect(() => {
		if (duty) {
			setCardState(PAGE_STATE.VIEW);
		} else {
			setCardState(PAGE_STATE.EMPTY);
		}
	}, [duty]);

	return (
		<div className="c__card tax-card">
			<div className="card-head">
				<h2 className="card-title">
					<Trans
						i18nKey="Tax.Dashboard.View.component.TaxCard.card-title"
						components={[<span />]}
					/>
				</h2>
				<div className="extra-condition">
					{/*<button type="button" className="btn__outline" color="gray">*/}
					{/*	연결된 PRO보기*/}
					{/*</button>*/}
					<button
						type="button"
						className="btn__outline angled"
						angled
						onClick={() => {
							logged === true
								? window.open(
										`${env.OKCEO_API_URL}/ssolink?token=${okceoToken}&cid=${selectedCompany}`
								  )
								: window.open(`https://okceo.co.kr`);
						}}
					>
						{t("Tax.Dashboard.View.component.TaxCard.btn-detail")}
					</button>
					{/*<button type="button" className="btn__outline btn-ico" color="gray">*/}
					{/*	<ChartIcon />*/}
					{/*</button>*/}
				</div>
			</div>
			<div className="card-content">
				<If condition={cardState}>
					<Case condition={PAGE_STATE.LOADING}>
						<Loading />
					</Case>
					<Case condition={PAGE_STATE.EMPTY}>
						<OkceoNoContent {...{ okceoToken, selectedCompany }} />
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						<div className="total-wrap">
							<div>
								<h3 className="title">
									{t("Tax.Dashboard.View.component.TaxCard.title1")}
								</h3>
								<span className="date">{dateFormatter(duty?.vatDate)}</span>
								<p className="amount">
									{thousandFormatter(duty?.vatExpectAmount)}
									<i className="unit">{t("Tax.Dashboard.View.component.won")}</i>
								</p>
							</div>
							<h4 className="d-day">D-{duty?.vatDday}</h4>
						</div>
						<div className="total-wrap">
							<div>
								<h3 className="title">
									{t("Tax.Dashboard.View.component.TaxCard.title2")}
								</h3>
								<span className="date">{dateFormatter(duty?.corpDate)}</span>
								<p className="amount">
									{thousandFormatter(duty?.corpExpectAmount)}
									<i className="unit">{t("Tax.Dashboard.View.component.won")}</i>
								</p>
							</div>
							<h4 className="d-day">D-{duty?.corpDday}</h4>
						</div>
					</Case>
				</If>
			</div>
		</div>
	);
};

export default TaxCard;
