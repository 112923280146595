import { SET_USER, CLEAR_USER } from "./actions";
import persistHelper from "~/utils/persist";

export const INITIAL_STATE = {
	check: {
		bizCheck: false,
		comCheck: false,
		irCheck: false,
		userRequirements: false,
	},
	company: {
		idx: null,
		name: "",
		type: "",
		regNum: "",
		certType: "",
		logo: "",
		license: "",
	},
	info: {
		department: "",
		id: "",
		idx: null,
		img: null,
		lastLogin: null,
		name: "",
		phone: null,
		position: "",
		regDate: null,
		role: null,
		status: null,
		license: null,
		updateDate: null,
		hrTeamMember: false,
	},
};

export const PERSISTED_STATE = persistHelper.initState("user", INITIAL_STATE);

export const REDUCER = (state = PERSISTED_STATE, action) => {
	switch (action.type) {
		case SET_USER:
			return persistHelper.setPersist("user", {
				...state,
				check: action.payload.check,
				company: action.payload.company,
				info: action.payload.info,
			});
		case CLEAR_USER:
			return persistHelper.setPersist("user", {
				...state,
			});
		default:
			return state;
	}
};
