import {
	setAccountData,
	setCostData,
	setSalesData,
	setFlowData,
	setProfitData,
	setDutyData,
} from "~/store/tax/actions";

/**
 * 세무 - 계좌
 */
export const OKCEO_ACCOUNT_DATA = {
	method: "get",
	url: "tax/account",
	type: "path",
	endpoint: "okceo",
	template: (param) => `?okceoCompanyId=${param?.selectedCompany}`,
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data;
		if (response.statusCode === 200) {
			dispatch(setAccountData({ account: responseData }));
		} else {
			dispatch(setAccountData({ account: null }));
		}
		return responseData;
	},
};

/**
 * 세무 - 비용(지출)
 */
export const OKCEO_COST_DATA = {
	method: "get",
	url: "tax/spend",
	type: "path",
	endpoint: "okceo",
	template: (param) =>
		`?okceoCompanyId=${param?.selectedCompany}&periodType=${param?.selectedYear}`,
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data;
		if (response.statusCode === 200) {
			dispatch(setCostData({ cost: responseData }));
		} else {
			dispatch(setCostData({ cost: null }));
		}
		return responseData;
	},
};

/**
 * 세무 - 매출
 */
export const OKCEO_SALES_DATA = {
	method: "get",
	url: "tax/sale",
	type: "path",
	endpoint: "okceo",
	template: (param) =>
		`?okceoCompanyId=${param?.selectedCompany}&periodType=${param?.selectedYear}`,
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data;
		if (response.statusCode === 200) {
			dispatch(setSalesData({ sales: responseData }));
		} else {
			dispatch(setSalesData({ sales: null }));
		}
		return responseData;
	},
};

/**
 * 세무 - 현금흐름
 */
export const OKCEO_FLOW_DATA = {
	method: "get",
	url: "tax/cash-flow",
	type: "path",
	endpoint: "okceo",
	template: (param) =>
		`?okceoCompanyId=${param?.selectedCompany}&periodType=${param?.selectedYear}`,
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data;
		if (response.statusCode === 200) {
			dispatch(setFlowData({ flow: responseData }));
		} else {
			dispatch(setFlowData({ flow: null }));
		}
		return responseData;
	},
};

/**
 * 세무 - 손익
 */
export const OKCEO_PROFIT_DATA = {
	method: "get",
	url: "tax/profit",
	type: "path",
	endpoint: "okceo",
	template: (param) =>
		`?okceoCompanyId=${param?.selectedCompany}&periodType=${param?.selectedYear}`,
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data;
		if (response.statusCode === 200) {
			dispatch(setProfitData({ profit: responseData }));
		} else {
			dispatch(setProfitData({ profit: null }));
		}
		return responseData;
	},
};

/**
 * 세무 - 세금
 */
export const OKCEO_DUTY_DATA = {
	method: "get",
	url: "tax/duty",
	type: "path",
	endpoint: "okceo",
	template: (param) => `?okceoCompanyId=${param?.selectedCompany}`,
	onSuccess: ({ response, dispatch }) => {
		const responseData = response.data;
		if (response.statusCode === 200) {
			dispatch(setDutyData({ duty: responseData }));
		} else {
			dispatch(setDutyData({ duty: null }));
		}
		return responseData;
	},
};
