import React from "react";
import Form from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import DynamicFields from "~/pages/ir/IrDetail/Product/Edit/ProductSection/DynamicFields";
import { useTranslation } from "react-i18next";

const ProductSection = ({ useForm }) => {
	const { t } = useTranslation();
	return (
		<>
			<div className="section-title">
				{t("IR.IrDetail.Product.Edit.ProductSection.Index.product")}
			</div>
			<Form useForm={useForm} className="sub-section vertical">
				<Textarea
					label={t("IR.IrDetail.Product.Edit.ProductSection.Index.desc")}
					name="Product.description"
				/>
				<Textarea
					label={t("IR.IrDetail.Product.Edit.ProductSection.Index.spec")}
					name="Product.differentiation"
				/>
			</Form>
			<DynamicFields
				useForm={useForm}
				name="Customer"
				title={t("IR.IrDetail.Product.Edit.ProductSection.Index.customer")}
			/>
		</>
	);
};

export default ProductSection;
