import React, { useEffect, useState } from "react";

// Components
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";

// Assets
import "~/assets/sass/pages/mypage/plugin/plugin.scss";
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import PluginList from "~/pages/mypage/PluginPage/PluginList";
import useRequest from "~/common/hooks/useRequest";
import { useNavigate } from "react-router-dom";
import { ConvertFirstLetterToUpperCase } from "~/utils/formatter";

const PluginPage = () => {
	const navigate = useNavigate();
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [pluginList, setPluginList] = useState([]);

	const pluginListRequest = useRequest("get", "plugin/list");

	const getPluginList = () => {
		pluginListRequest.call();
	};

	useEffect(() => {
		getPluginList();
	}, []);

	useEffect(() => {
		switch (pluginListRequest.state) {
			case API_STATE.done:
				const response = pluginListRequest.response.map((plugin) => ({
					...plugin,
					license: ConvertFirstLetterToUpperCase(plugin.license),
					tagList: plugin.tag ? plugin.tag.split("|") : [],
					engTagList: plugin.engTag ? plugin.engTag.split("|") : [],
				}));

				setPluginList(response);
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error", pluginListRequest.error);
				navigate("/404");
				break;
		}
	}, [pluginListRequest.state]);

	return (
		<NavbarAndSidebarLayout menu={["plugin", "list"]} type="main">
			<PageHeader />
			<If condition={pageState}>
				<Case condition={PAGE_STATE.LOADING}>
					<Loading />
				</Case>
				<Case condition={PAGE_STATE.VIEW}>
					<PluginList {...{ getPluginList, pluginList }} />
				</Case>
			</If>
		</NavbarAndSidebarLayout>
	);
};

export default PluginPage;
