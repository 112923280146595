import React, { useEffect, useState } from "react";
import { Case, If } from "~/common/components/If";
import { useGlobalStore } from "~/store";

import "~/assets/sass/pages/company/product.scss";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";

import useRequest from "~/common/hooks/useRequest";
import { setCurrentProduct, setProductList } from "~/store/company/actions";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";

// import PageHeader from "~/common/components/PageHeader";
import Empty from "./Empty";
import View from "./View";
import Edit from "../ProductEdit";
import LoadingPage from "~/pages/LoadingPage";
import PageHeader from "~/common/components/PageHeader";

const CompanyProductListPage = () => {
	const [, dispatch] = useGlobalStore();
	const [page, setPage] = useState(PAGE_STATE.LOADING);

	const companyProductRequest = useRequest("get", "company/product");

	useEffect(() => {
		companyProductRequest.singleCall();
		dispatch(setCurrentProduct({}));
	}, []);

	useEffect(() => {
		switch (companyProductRequest.state) {
			case API_STATE.done:
				const productList = companyProductRequest.response.data;
				const sortedList = productList.sort((a, b) =>
					a.representation < b.representation ? 1 : -1
				);
				dispatch(setProductList({ productList: sortedList }));
				setPage(productList.length > 0 ? PAGE_STATE.VIEW : PAGE_STATE.EMPTY);
				break;
			default:
				break;
		}
	}, [companyProductRequest.state, companyProductRequest.response, dispatch]);

	const Header = ({ buttonList = [] }) => {
		return (
			<PageHeader
				title="제품정보"
				mainTitle="기업"
				subTitle="제품정보"
				buttonList={buttonList}
			/>
		);
	};

	return (
		<>
			<NavbarAndSidebarLayout menu={["company", "product"]} type="main">
				<If condition={page} {...{ setPage, companyProductRequest }}>
					<Case condition={PAGE_STATE.EMPTY}>
						<Empty {...{ Header }} />
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						<View />
					</Case>
					<Case condition={PAGE_STATE.EDIT}>
						<Edit />
					</Case>
					<Case condition={PAGE_STATE.LOADING}>
						<LoadingPage />
					</Case>
				</If>
			</NavbarAndSidebarLayout>
		</>
	);
};

export default CompanyProductListPage;
