import React from "react";
import { useController } from "react-hook-form";

const SwitchRadio = ({ control, className, options, name, defaultValue, required, validation }) => {
	const { field } = useController({
		control,
		name,
		defaultValue,
		rules: {
			required: required && "필수 입력 사항 입니다.",
			...validation,
		},
	});

	return (
		<ul className={`c__switch-radio ${className ?? ""}`}>
			{options.map((option, index) => (
				<li
					className={`${field.value === option.value ? "active" : ""}`}
					onClick={() => field.onChange(option.value)}
					key={`switch-radio__${index}`}
				>
					{option.label}
				</li>
			))}
		</ul>
	);
};

export default SwitchRadio;
