import React from "react";
import { useGlobalStore } from "~/store";
import {
	companyNumberFormatter,
	dateToString,
	phoneNumberFormatter,
	thousandFormatter,
} from "~/utils/formatter";
import { BIZ_TYPE } from "~/common/constants/form";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const InvestmentItem = ({ date, name, price }) => {
	return (
		<ul className="f__control">
			<li>{dateToString(date, "yyyy-MM-dd")}</li>
			<li className="attraction-name">{name}</li>
			<li className="attraction-price">{thousandFormatter(price)}</li>
		</ul>
	);
};

const View = ({ showEditPage, Header }) => {
	const { t } = useTranslation();
	const [
		{
			ir: { basicInfo },
		},
	] = useGlobalStore();

	const buttonList = [
		<AuthButton type="button" className="btn__solid" color="primary" onClick={showEditPage}>
			{t("button.Edit")}
		</AuthButton>,
	];

	return (
		<>
			<Header buttonList={buttonList} />
			<div className="ir-view">
				<div className="c__card basicInfo-view">
					<h2 className="ir-title">{basicInfo.irTitle}</h2>
					<form className="data-form">
						<section className="input-group-section">
							<h3 className="section-title">{t("Company.Info.Header.sub-title")}</h3>
							<div className="c__form horiz data-view">
								<div className="f__group">
									<label className="f__label">
										{t("Company.Info.registration-num")}
									</label>
									<div className="f__control">
										{companyNumberFormatter(basicInfo.regNum)}
									</div>
								</div>
								<div className="f__group">
									<label className="f__label">
										{t("Company.Info.companyName")}
									</label>
									<div className="f__control">{basicInfo.name}</div>
								</div>
								<div className="f__group">
									<label className="f__label">{t("Company.Info.ceo")}</label>
									<div className="f__control">{basicInfo.ceoName}</div>
								</div>
								<div className="f__group">
									<label className="f__label">{t("Company.Info.type")}</label>
									<div className="f__control">{BIZ_TYPE[basicInfo.type]}</div>
								</div>
								<div className="f__group">
									<label className="f__label">
										{t("IR.IrDetail.BasicInfo.View.Index.label.bizType")}
									</label>
									<div className="f__control">
										{basicInfo.bizType}
										{basicInfo.bizType && basicInfo.bizStatus && "/"}
										{basicInfo.bizStatus}
									</div>
								</div>
								<div className="f__group">
									<label className="f__label">{t("Company.Info.estDate")}</label>
									<div className="f__control">
										{dateToString(basicInfo.estDate, "yyyy-MM-dd")}
									</div>
								</div>
								<div className="f__group">
									<label className="f__label">{t("Company.Info.listed")}</label>
									<div className="f__control">
										{basicInfo.listed
											? t("contants.form.LISTED_TYPE.true")
											: t("contants.form.LISTED_TYPE.false")}
									</div>
								</div>
								<div className="f__group">
									<label className="f__label">
										{t("Company.Info.employeeNum")}
									</label>
									<div className="f__control">
										{thousandFormatter(basicInfo.employeeNum)}
									</div>
								</div>
								<div className="f__group">
									<label className="f__label">{t("Company.Info.address")}</label>
									<div className="f__control">{basicInfo.address}</div>
								</div>
								<div className="f__group">
									<label className="f__label">{t("Company.Info.homepage")}</label>
									<div className="f__control">{basicInfo.homepage}</div>
								</div>
								<div className="f__group">
									<label className="f__label">
										{t("Company.Info.ceoContact")}
									</label>
									<div className="f__control">
										{phoneNumberFormatter(basicInfo.ceoContact)}
									</div>
								</div>
							</div>
						</section>
						<section className="input-group-section">
							<h3 className="section-title">
								{t("IR.IrDetail.BasicInfo.Edit.Index.title")}
							</h3>
							<div className="c__form horiz data-view">
								<div className="f__group">
									<label className="f__label">
										{t("IR.IrDetail.BasicInfo.Edit.Index.label.capital")}
									</label>
									<div className="f__control">
										{thousandFormatter(basicInfo.capital)}
									</div>
								</div>
								<div className="f__group">
									<label className="f__label">
										{t("IR.IrDetail.BasicInfo.Edit.Index.label.faceValue")}
									</label>
									<div className="f__control">
										{thousandFormatter(basicInfo.faceValue)}
									</div>
								</div>
								<div className="f__group">
									<label className="f__label">
										{t("IR.IrDetail.BasicInfo.Edit.Index.label.totalStock")}
									</label>
									<div className="f__control">
										{thousandFormatter(basicInfo.totalStock)}
									</div>
								</div>
								<div className="f__group">
									<label className="f__label">
										{t("IR.IrDetail.BasicInfo.Edit.Index.label.settlement")}
									</label>
									<div className="f__control">{basicInfo.settlement}</div>
								</div>
								<div className="f__group">
									<label className="f__label">
										{t("IR.IrDetail.BasicInfo.Edit.Index.label.comValue")}
									</label>
									<div className="f__control">{basicInfo.comValue}</div>
								</div>
							</div>
						</section>
						<section>
							<div className="c__form vertical sub-section">
								<div className="f__group">
									<label className="f__label">
										{t("IR.IrDetail.BasicInfo.Edit.Index.label.fundUse")}
									</label>
									<div className="f__control">
										<textarea
											className="c__textarea f__item"
											value={basicInfo.fundUse}
											readOnly
										/>
									</div>
								</div>
								<div className="f__group">
									<label className="f__label">
										{t("IR.IrDetail.BasicInfo.Edit.Index.label.exitPlan")}
									</label>
									<div className="f__control">
										<textarea
											className="c__textarea f__item"
											value={basicInfo.exitPlan}
											readOnly
										/>
									</div>
								</div>
							</div>
							<div className="c__form vertical sub-section">
								<div className="f__group">
									<label className="f__label unit-wrap">
										{t("IR.IrDetail.BasicInfo.View.Index.label.invest")}
										<span className="unit">{t("Company.unit")}</span>
									</label>
									{basicInfo.IRBasicInfoAttraction.map(
										({ date, value, content }, idx) => (
											<InvestmentItem
												key={idx}
												date={date}
												name={content}
												price={value}
											/>
										)
									)}
								</div>
								<div className="f__group">
									<label className="f__label unit-wrap">
										{t("IR.IrDetail.BasicInfo.Edit.Index.dynamicFields.title2")}
										<span className="unit">{t("Company.unit")}</span>
									</label>
									{basicInfo.IRBasicInfoSubsidy.map(
										({ date, value, content }, idx) => (
											<InvestmentItem
												key={idx}
												date={date}
												name={content}
												price={value}
											/>
										)
									)}
								</div>
							</div>
							<div className="seal-area">
								<span className="seal-label">Signature</span>
								{basicInfo.sign && (
									<img
										className="seal-uploader"
										src={basicInfo.sign}
										alt="sign"
									/>
								)}
							</div>
						</section>
					</form>
				</div>
			</div>
		</>
	);
};

export default View;
