import React, { useState } from "react";
import { useController } from "react-hook-form";
import DaumPostcode from "react-daum-postcode";

import ModalTemplate from "../ModalPortal/ModalTemplate";
import { useTranslation } from "react-i18next";

const Address = (props) => {
	const { t } = useTranslation();
	const [findAddressModal, setFindAddressModal] = useState(false);

	const { control, name, setValue, required, invalid } = props;

	const postCode = useController({
		control,
		name: name["postCode"],
	}).field;

	const address1 = useController({
		control,
		name: name["address1"],
	}).field;

	const address2 = useController({
		control,
		name: name["address2"],
	}).field;

	return (
		<div className={`f__control ${invalid ? "has-error" : null}`}>
			<div className="postcode-wrap">
				<button
					type="button"
					className="btn__postcode btn__outline angled"
					color="primary"
					onClick={() => setFindAddressModal(true)}
				>
					{t("components.Address.postcode-wrap.button")}
				</button>
				<input
					type="text"
					value={
						postCode.value && address1.value
							? `(${postCode.value}) ${address1.value}`
							: ""
					}
					className="c__input"
					disabled
					placeholder={t("components.Address.postcode-wrap.input")}
				/>
			</div>
			<input
				type="text"
				value={address2.value ? address2.value : ""}
				className="c__input f__item"
				onChange={(e) => address2.onChange(e.target.value)}
				placeholder={t("components.Address.input")}
			/>
			{required && invalid && <div className="f__feedback">{t("Validate.require")}</div>}
			<ModalTemplate
				title={t("components.Address.modal.title")}
				modalState={findAddressModal}
				setModalToggle={setFindAddressModal}
				height={550}
				width={500}
				className="findAddress-modal"
			>
				<div style={{ overflow: "hidden", borderRadius: "0px 0px 15px 15px" }}>
					<DaumPostcode
						style={{ height: "550px" }}
						onComplete={(e) => {
							setFindAddressModal(false);
							setValue(name["postCode"], e.zonecode);
							setValue(name["address1"], e.address);
							setValue(name["address2"], "");
						}}
					/>
				</div>
			</ModalTemplate>
		</div>
	);
};

export default Address;

Address.defaultProps = {
	className: "c__addressGroup",
};
