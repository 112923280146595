import React from "react";
import { BellIcon } from "~/assets/svg/TeamInfoIcon";
import { useGlobalStore } from "~/store";
import { setSelectedVacation } from "~/store/hrSetting/actions";

const VacationRow = ({ vacationTypeList, setVacationAddModal, currentPath, showAuthAlert }) => {
	const [, dispatch] = useGlobalStore();

	const handleClick = (vacation) => {
		if (currentPath.auth === "view") {
			showAuthAlert();
		} else {
			let employeeType = [];
			vacation.vacationAuthorization.forEach((d) => {
				employeeType.push(d.HrEmployeeType.name);
			});

			dispatch(
				setSelectedVacation({
					idx: vacation.idx,
					type: vacation.type,
					title: vacation.title,
					days: vacation.days,
					employeeType: employeeType,
					weekend: vacation.weekend,
					refresh: vacation.refresh,
					continuous: vacation.continuous,
					paid: vacation.paid,
					gender: vacation.gender,
					documents: vacation.documents,
					alarm: vacation.alarm,
					autoConfirm: vacation.autoConfirm,
				})
			);

			setVacationAddModal(true);
		}
	};

	return (
		<>
			{vacationTypeList.map((vacation, v_idx) => {
				return (
					<div
						key={v_idx}
						className="vacation-list-row"
						onClick={() => handleClick(vacation)}
					>
						<div className="row-name-wrap">
							<div className="vacation-name">{vacation.title}</div>
							{vacation.paid ? "" : <span className="unpaid-badge">무급</span>}
						</div>
						<div className="detail-status-wrap">
							{/* TODO: 관리자 알림 설정 추후 개발 예정 */}
							{/*<span className={`alarm-status ${vacation.alarm && "active"}`}>*/}
							<span className={`alarm-status`}>
								<BellIcon />
							</span>
							<span className="day-status">{vacation.days}일</span>
							<span
								className={`${
									vacation.type === "법정필수휴가" ? "public" : "contract"
								}-holiday`}
							>
								{vacation.type}
							</span>
							<span
								className={`auto-approval-${vacation.autoConfirm ? "on" : "off"}`}
							>
								자동승인{vacation.autoConfirm ? "ON" : "OFF"}
							</span>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default VacationRow;
