import React, { useEffect, useRef, useState } from "react";
import TopNav from "~/common/components/TopNav";
import SideBar from "~/common/components/SideBar";
import Footer from "~/common/components/Footer";
import PropsProvider from "~/common/components/PropsProvider";
import { useGlobalStore } from "~/store";
import { setCurrentMenu } from "~/store/sidebar/actions";
import { useNavigate } from "react-router";
import BackToTopButton from "~/common/components/BackToTopButton";
import useDebounce from "~/common/hooks/useDebounce";
import PaymentBanner from "~/common/components/PaymentBanner";

const NavbarAndSidebarLayout = ({ menu = [], type = "", children, menuName, backTop = false }) => {
	const [mainMenuId, subMenuId] = menu;

	const pageClassName = menuName
		? menuName
		: subMenuId
		? [mainMenuId, subMenuId].join("-")
		: mainMenuId;

	const [
		{
			sidebar: { authMap },
			user: { info },
			payment: { isFailedPayment },
		},
		dispatch,
	] = useGlobalStore();

	const navigate = useNavigate();

	const shouldMount =
		mainMenuId === "dashboard" || authMap[type]?.[mainMenuId]?.[subMenuId] !== "none";

	useEffect(() => {
		if (shouldMount) {
			dispatch(
				setCurrentMenu({
					main: mainMenuId,
					sub: subMenuId,
					type: type,
				})
			);
		} else {
			navigate("/dashboard");
		}
	}, []);

	const mainRef = useRef(null);
	const [showBackTopBtn, setShowBackTopBtn] = useState(false);

	const handleScroll = () => {
		if (backTop && mainRef.current !== null) {
			const scrollTop = mainRef.current.scrollTop;
			if (scrollTop > window.innerHeight / 2) {
				setShowBackTopBtn(true);
			} else {
				setShowBackTopBtn(false);
			}
		}
	};

	const failBannerHeight = isFailedPayment && info.role === "admin" ? "60px" : "0px";

	return (
		<div className="l__dashboard">
			<PaymentBanner />
			<TopNav />
			<div className="l__mainContainer">
				<SideBar currentMenu={menu} currentType={type} pageClassName={pageClassName} />
				<main
					className="l__main"
					ref={mainRef}
					onScroll={useDebounce(handleScroll, 1000)}
					style={{ maxHeight: `calc(100vh - 100px - ${failBannerHeight})` }}
				>
					<div className={`l__content p__${pageClassName}`}>
						{shouldMount && (
							<PropsProvider props={{ menu: menu, type: type }}>
								{children}
							</PropsProvider>
						)}
					</div>
					<Footer />
				</main>
				{backTop && showBackTopBtn && <BackToTopButton mainRef={mainRef} />}
			</div>
		</div>
	);
};

export default NavbarAndSidebarLayout;
