import React, { useEffect, useState } from "react";
import { QuestionMarkIcon } from "~/assets/svg/InterfacesIcon";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import EditModal from "~/pages/hr/HrSettingPage/WorkType/EditModal";
import { useGlobalStore } from "~/store";
import { clearWorkType, setWorkType } from "~/store/hrSetting/actions";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import { timeToDateFormatter } from "~/utils/formatter";
import useAlert from "~/common/hooks/useAlert";

// Assets
import "~/assets/sass/pages/hr/setting/workType.scss";
import TooltipTemplate from "~/common/components/TooltipPortal/TooltipTemplate";
import DropdownTemplate from "~/common/components/DropdownPortal/DropdownTemplate";

const View = (props) => {
	const [
		{
			sidebar: { currentPath },
		},
	] = useGlobalStore();
	const { workTypeEditModal, setWorkTypeEditModal, workTypeRequest, workTypeList } = props;
	const [workTimeTotal, setWorkTimeTotal] = useState("0시간"); //선택한 타입 총 근무시간
	const [weekTimeTotal, setWeekTimeTotal] = useState("0시간"); //선택한 타입 주휴일 포함 총 근무시간
	const [selectIdx, setSelectIdx] = useState(null);

	const [, dispatch] = useGlobalStore();
	const [showDeleteAlert, DeleteAlert] = useAlert();
	const deleteRequest = useRequest("delete", "hr/work");
	const [showWrongAlert, WrongAlert] = useAlert();

	const getWorkTypeRequest = useRequest("get", `/hr/work`);

	useEffect(() => {
		switch (getWorkTypeRequest.state) {
			case API_STATE.done:
				const response = getWorkTypeRequest.response.data;
				let newHrWorkTimes = [];
				response.HrWorkTimes.forEach((d) => {
					newHrWorkTimes.push({
						...d,
						workStartTime: timeToDateFormatter(d.workStartTime),
						workEndTime: timeToDateFormatter(d.workEndTime),
					});
				});

				dispatch(
					setWorkType({
						...response,
						HrWorkEmployeeType: response.HrWorkEmployeeType.idx.toString(),
						restStartTime: timeToDateFormatter(response.restStartTime),
						restEndTime: timeToDateFormatter(response.restEndTime),
						workDays:
							response.workDays.length !== 0
								? response.workDays.join().split(",")
								: [],
						paidDayOff:
							response.paidDayOff.length !== 0
								? response.paidDayOff.join().split(",")
								: [],
						HrWorkTimes: newHrWorkTimes,
					})
				);

				setWorkTypeEditModal(true);
				break;
			case API_STATE.error:
				if (getWorkTypeRequest.error.statusCode === 404) {
					// setPageState(PAGE_STATE.EMPTY);
				}
			default:
				break;
		}
	}, [getWorkTypeRequest.state]);

	const showEditModal = (type) => {
		setSelectIdx(type.idx);
		setWorkTimeTotal(type.workTimeTotal);
		setWeekTimeTotal(type.weekTimeTotal);
		getWorkTypeRequest.call(type.idx, "path");
	};

	const closeEditModal = () => {
		setWorkTypeEditModal(false);
	};

	useEffect(() => {
		if (!workTypeEditModal) {
			setSelectIdx(null);
			setWorkTimeTotal("0시간");
			setWeekTimeTotal("0시간");
			dispatch(clearWorkType());
		}
	}, [workTypeEditModal]);

	const onDelete = (idx) => {
		showDeleteAlert()
			.then(async () => {
				await deleteRequest.asyncCall(idx, "path");
			})
			.catch(() => {});
	};

	useEffect(() => {
		switch (deleteRequest.state) {
			case API_STATE.done:
				workTypeRequest.asyncCall();
				break;
			case API_STATE.error:
				switch (deleteRequest.error.statusCode) {
					case 403:
						showWrongAlert();
						break;
					case 500:
						console.log("Error: ", deleteRequest.error.message);
						alert("잠시 후 다시 시도해주세요.");
						break;
					default:
						break;
				}
				break;
			default:
				break;
		}
	}, [deleteRequest.state, deleteRequest.error, deleteRequest.response]);

	const [showAuthAlert, AuthAlert] = useAlert();

	const handleClick = (idx) => {
		if (currentPath.auth === "view") {
			showAuthAlert();
		} else {
			onDelete(idx);
		}
	};

	const handleEditClick = (type) => {
		if (currentPath.auth === "view") {
			showAuthAlert();
		} else {
			showEditModal(type);
		}
	};

	const workTypeCard = () =>
		workTypeList.map((type, type_idx) => (
			<>
				<div className={"c__card type-card"} key={type_idx}>
					<DropdownTemplate target={`dropdown_${type_idx}`} vertical>
						<li className="c__dropdownItem" onClick={() => handleClick(type.idx)}>
							삭제
						</li>
					</DropdownTemplate>
					<div className="content" key={type_idx} onClick={() => handleEditClick(type)}>
						<div className="type">
							<span>{type.title}</span>
							{/*<span className="main-tag">대표유형</span>*/}
						</div>
						<div className="time-wrap">
							<p className="week-time">주 {type.workTimeTotal}</p>
							<p className="total-time">주휴일 포함시 주 {type.weekTimeTotal}</p>
						</div>
					</div>
				</div>
			</>
		));

	return (
		<>
			<h3 className="title">
				근무유형
				<button type="button" className="c__tooltip-btn">
					<QuestionMarkIcon />
					<TooltipTemplate>직원들의 근무 유형을 설정합니다.</TooltipTemplate>
				</button>
			</h3>
			<div className="c__cardRow card-wrap type-card-wrap">{workTypeCard()}</div>
			<ModalTemplate
				modalState={workTypeEditModal}
				setModalToggle={setWorkTypeEditModal}
				title={"근무유형 추가하기"}
				width={574}
				className="type-add-modal"
				closeable={false}
			>
				<EditModal
					{...{
						closeEditModal,
						workTypeRequest,
						selectIdx,
						workTimeTotal,
						setWorkTimeTotal,
						weekTimeTotal,
						setWeekTimeTotal,
					}}
				/>
			</ModalTemplate>
			<DeleteAlert
				type="error"
				message="해당 근무유형을 삭제합니다."
				confirm="확인"
				reject="취소"
			/>
			<WrongAlert
				type="info"
				message={
					<div className="alert-workType">
						<p>해당 근무유형이 1명이상에게 적용되어 있습니다.</p>
						<span>근무유형이 1명이상 적용되어 있으면 삭제가 불가능 합니다.</span>
					</div>
				}
				confirm="확인"
			/>
			<AuthAlert
				message={
					<>
						권한이 없습니다. <br />
						관리자에게 문의하세요.
					</>
				}
				type="warning"
				confirm="확인"
			/>
		</>
	);
};

export default View;
