import React from "react";

//Assets
import "~/assets/sass/component/tooltip.scss";

const TooltipTemplate = (props) => {
	const { children, className = "" } = props;

	return <div className={`c__tooltip ${className}`}>{children}</div>;
};

export default TooltipTemplate;
