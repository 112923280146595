import React, { useEffect, useState } from "react";

// Components
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";

// Assets
import "~/assets/sass/pages/mypage/account/account.scss";
import useRequest from "~/common/hooks/useRequest";
import { Case, If, OtherWise } from "~/common/components/If";
import CardFront from "./CardFront";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import Loading from "~/common/components/Loading";
import CardBack from "./CardBack";
import { useNavigate } from "react-router";
import { useGlobalStore } from "~/store";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import Edit from "~/pages/mypage/AccountPage/Edit";
import { useTranslation } from "react-i18next";

const AccountPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [{ user }] = useGlobalStore();
	const [memberDetailModal, setMemberDetailModal] = useState(false);
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const [profileInfo, setProfileInfo] = useState({});

	const EditProfile = () => {
		if (user.info.isHrTeamMember) {
			navigate(`/hr/list?idx=${user.info.idx}`);
		} else {
			setMemberDetailModal(true);
		}
	};

	const userDetailRequest = useRequest("get", "user/profile");

	useEffect(() => {
		if (!user) return;
		userDetailRequest.singleCall();
	}, [user]);

	useEffect(() => {
		switch (userDetailRequest.state) {
			case API_STATE.done:
				const responseData = userDetailRequest.response.data;
				setProfileInfo({
					user: {
						...responseData.user,
						...responseData.userProfile,
						position: user?.info?.position,
					},
					career: responseData.userCareer,
				});

				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.log("error");
				break;
			default:
				break;
		}
	}, [userDetailRequest.state]);

	const headerButtons = [
		<button className="btn__solid" color="primary" onClick={EditProfile}>
			{t("button.modify")}
		</button>,
	];

	return (
		<>
			<NavbarAndSidebarLayout menu={["account", "profile"]} type={"myPage"}>
				<PageHeader buttonList={headerButtons} />
				<If condition={pageState}>
					<Case condition={PAGE_STATE.VIEW}>
						<div className="c__card-wrap">
							<CardFront profile={profileInfo.user} />
							<CardBack careerList={profileInfo.career} />
						</div>
					</Case>
					<OtherWise>
						<Loading />
					</OtherWise>
				</If>
			</NavbarAndSidebarLayout>
			<ModalTemplate
				title={t("MyPage.AccountPage.teamInfo-modal-title")}
				modalState={memberDetailModal}
				setModalToggle={setMemberDetailModal}
				width={800}
				className="teamInfo-modal"
			>
				<Edit {...{ profileInfo, userDetailRequest }} />
			</ModalTemplate>
		</>
	);
};

export default AccountPage;
