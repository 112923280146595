import React, { useEffect, useState } from "react";
import { RoundArrowIcon } from "~/assets/svg/InterfacesIcon";
import { addDays, startOfWeek, subDays } from "date-fns";
import { dateToString } from "~/utils/formatter";
import { PAGE_STATE } from "~/common/constants/state";
import CardRow from "~/pages/hr/AttendancePage/MySchedule/View/weeklyCard/cardRow";
import { useTranslation } from "react-i18next";

const WeeklyCard = (props) => {
	const { t } = useTranslation();
	const {
		currentDate,
		setCurrentDate,
		setStartOfCurrentWeek,
		editScheduleModalToggle,
		getSchedule,
		weeklySchedule,
		scheduleType,
		isDayOff,
		// canvasRef,
		// refWidth,
	} = props;
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	const viewType = ["Default", "Detail"];
	const [currentViewType, setCurrentViewType] = useState(viewType[0]);

	const getThisWeek = () => {
		setCurrentDate(new Date());
		setStartOfCurrentWeek(startOfWeek(new Date(), { weekStartsOn: 1 }));
	};

	const getPrevWeek = () => {
		setCurrentDate(subDays(currentDate, 7));
		setStartOfCurrentWeek(startOfWeek(subDays(currentDate, 7), { weekStartsOn: 1 }));
	};

	const getNextWeek = () => {
		setCurrentDate(addDays(currentDate, 7));
		setStartOfCurrentWeek(startOfWeek(addDays(currentDate, 7), { weekStartsOn: 1 }));
	};

	useEffect(() => {
		getSchedule();
	}, [currentDate]);

	useEffect(() => {
		if (weeklySchedule && weeklySchedule.length !== 0) {
			setPageState(PAGE_STATE.VIEW);
		}
	}, [weeklySchedule]);

	return (
		<>
			<section className="c__card week-schedule-section">
				<div className="top-control-wrap">
					<div className="date-wrap">
						<span className="week-period">
							{dateToString(weeklySchedule[0]?.initDate, "MM. dd") +
								" ~ " +
								dateToString(weeklySchedule[6]?.initDate, "MM. dd")}
						</span>
						<div className="btn-wrap">
							<button className="prev-btn" onClick={() => getPrevWeek()}>
								<RoundArrowIcon />
							</button>
							<button className="next-btn" onClick={() => getNextWeek()}>
								<RoundArrowIcon />
							</button>
							<button
								type="button"
								className="btn__outline btn-today"
								color="primary"
								onClick={() => getThisWeek()}
							>
								{t("HR.Attendance.TeamSchedule.customButtons")}
							</button>
						</div>
					</div>
					<div className="wrap">
						<div className="c__radio-wrap">
							{viewType.map((type, type_idx) => {
								return (
									<label className="c__radio" key={type_idx}>
										<input
											type="radio"
											onChange={() => setCurrentViewType(type)}
											checked={currentViewType === type}
										/>
										<span className="checkmark" />
										<span className="radio-label">{type}</span>
									</label>
								);
							})}
						</div>
						<div className="schedule-wrap">
							<ul className="schedule-type-wrap">
								{scheduleType.map((type, type_idx) => {
									return (
										<li className={`vacation-name ${type.id}`} key={type_idx}>
											{type.title.slice(0, 2)}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
				<div className={`schedule-table ${currentViewType?.toLowerCase()}-wrap`}>
					<CardRow
						{...{
							pageState,
							scheduleType,
							weeklySchedule,
							currentViewType,
							editScheduleModalToggle,
							isDayOff,
						}}
					/>
				</div>
			</section>
		</>
	);
};

export default WeeklyCard;
