import React from "react";

import Form from "~/common/components/Form";
import Input from "~/common/components/Input";
import { RoundMinusIcon } from "~/assets/svg/InterfacesIcon";
import { PlusIcon } from "~/assets/svg/MathIcon";
import NumberInput from "~/common/components/NumberInput";
import { useFieldArray } from "react-hook-form";
import DatePicker from "~/common/components/DatePicker";
import { useTranslation } from "react-i18next";

const InputRow = ({ useForm, name, remove }) => {
	const { t } = useTranslation();
	return (
		<Form useForm={useForm} className="row-wrap">
			<DatePicker name={name("date")} className="date" required />
			<Input
				placeholder={t("IR.IrDetail.Outcome.Edit.Index.field3.name")}
				className="content"
				name={name("content")}
				type="text"
				required
			/>
			<NumberInput
				placeholder={t("IR.IrDetail.Outcome.Edit.Index.field3.value")}
				className="value"
				name={name("value")}
				required
				string
			/>
			<button type="button" className="btn-round-delete" onClick={remove}>
				<RoundMinusIcon />
			</button>
		</Form>
	);
};

const DynamicFields = ({ title, name, useForm }) => {
	const { t } = useTranslation();
	const { control } = useForm;

	const { fields, append, remove } = useFieldArray({
		control,
		name,
	});

	const ROW_INIT_DATA = {
		content: "",
		value: "",
		date: new Date().toISOString(),
	};

	return (
		<>
			<div className="f__group dynamic-input">
				<div className="f__label">{title}</div>
				<div className="unit-wrap">
					<button
						className="dashed-btn plus-btn"
						type="button"
						onClick={() => append(ROW_INIT_DATA)}
					>
						<PlusIcon />
						{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.addInput")}
					</button>
					<span className="inline-unit">
						{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.unit")}:{" "}
						{t("IR.IrDetail.BasicInfo.Edit.DynamicFields.thousand")}
					</span>
				</div>
				<div className="f__control sub-section-wrap">
					{fields.map((field, index) => {
						const getName = (propName) => `${name}.${index}.${propName}`;
						const removeItem = () => remove(index);
						return (
							<InputRow
								key={field.id}
								name={getName}
								remove={removeItem}
								useForm={useForm}
							/>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default DynamicFields;
