import React, { useEffect, useState } from "react";
import { API_STATE, PAGE_STATE, ROUND_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import useInfiniteScroll from "~/common/hooks/useInfiniteScroll";
import { dateToString } from "~/utils/formatter";
import View from "./View";
import Search from "~/pages/invest/RoundPage/RoundList/View/search";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import RoundDetailModal from "~/pages/invest/RoundPage/modal/RoundDetailModal";
import "~/assets/sass/pages/invest/round/list.scss";
import { differenceInDays } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RoundList = () => {
	const { t, i18n } = useTranslation();
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
	const roundListRequest = useRequest("post", "round/search");
	const [roundList, setRoundList] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [page, setPage] = useState(1);
	const [searchTitle, setSearchTitle] = useState("");
	const [searchApply, setSearchApply] = useState("전체");
	const [searchStatus, setSearchStatus] = useState(Object.values(ROUND_STATE));
	const lastElementRef = useInfiniteScroll(pageState, page, setPage, hasMore);
	const [searchParams, setSearchParams] = useSearchParams();
	const roundIdx = searchParams.get("idx");

	const getRoundList = () => {
		roundListRequest.call(
			{
				size: 20,
				page: page,
				title: searchTitle,
				status: searchStatus,
				apply: searchApply,
			},
			"body"
		);
	};

	useEffect(() => {
		setPageState(PAGE_STATE.LOADING);
		getRoundList();
	}, [page, searchStatus, searchTitle, i18n.language]);

	function getDiffDays(endDate) {
		const count = differenceInDays(new Date(endDate), new Date());

		return `D-${count === 0 ? "day" : count}`;
	}

	function roundStatus(status, endDate) {
		let newStatus;
		switch (status) {
			case "진행중":
				newStatus = getDiffDays(endDate);
				break;
			case "지원마감":
			case "심사중":
			case "종료":
				newStatus = t("Invest.situation.deadline");
				break;
			default:
				newStatus = status;
				break;
		}

		return newStatus;
	}
	const ROUND_STATUS = (endDate) => ({
		진행중: getDiffDays(endDate),
		지원마감: t("Invest.situation.deadline"),
		심사중: t("Invest.situation.deadline"),
		종료: t("Invest.situation.deadline"),
	});

	useEffect(() => {
		switch (roundListRequest.state) {
			case API_STATE.done:
				const responseData = roundListRequest.response.data;
				const filteredData = responseData.list.map((d) => ({
					idx: d.round.idx,
					img: d.round.img,
					apply: d.apply,
					vcName: d.round.VcCompany.name,
					title: d.round.title,
					status: ROUND_STATUS(d.round.endDate)[d.round.status],
					isEnd:
						d.round.status === "지원마감" ||
						d.round.status === "심사중" ||
						d.round.status === "종료",
					regDate: dateToString(d.round.regDate, "yyyy-MM-dd"),
				}));

				if (responseData.pageable.totalPage === 0) {
					setRoundList([]);
					if (searchTitle === "" && searchStatus.length === 5 && searchApply === "전체") {
						setPageState(PAGE_STATE.EMPTY);
					} else {
						setPageState("NODATA");
					}
				} else {
					if (responseData.pageable.pageNumber === 1) {
						setRoundList(filteredData);
					} else {
						setRoundList([...roundList, ...filteredData]);
					}

					setPageState(PAGE_STATE.VIEW);
				}
				setHasMore(page !== responseData.pageable.totalPage);
				break;
			case API_STATE.error:
				alert(t("Alert.alert2"));
				break;
		}
	}, [roundListRequest.state]);

	const [selectedIdx, setSelectedIdx] = useState(null);
	const [roundDetailModal, setRoundDetailModal] = useState(false);

	const getRoundDetail = (idx) => {
		setSelectedIdx(idx);
		setRoundDetailModal(true);
	};

	useEffect(() => {
		if (roundIdx) {
			getRoundDetail(parseInt(roundIdx));
			setSearchParams({});
		}
	}, []);

	return (
		<>
			<Search
				{...{
					setSearchTitle,
					setSearchStatus,
					setSearchApply,
					setPage,
				}}
			/>
			<View {...{ pageState, roundList, getRoundDetail, selectedIdx, lastElementRef }} />
			<ModalTemplate
				modalState={roundDetailModal}
				setModalToggle={setRoundDetailModal}
				width={1100}
				className="round-modal"
				closeable={false}
			>
				<RoundDetailModal {...{ selectedIdx, getRoundList, roundStatus, ROUND_STATUS }} />
			</ModalTemplate>
		</>
	);
};

export default RoundList;
