import React from "react";

import { phoneNumberFormatter, ssnFormatter } from "~/utils/formatter";

//Assets
import "~/assets/sass/pages/hr/modal/common/detail/personal.scss";
import { Case, If, OtherWise } from "~/common/components/If";
import { BANK_METADATA } from "~/common/constants/bank";
import { useTranslation } from "react-i18next";
// import { RoundChkIcon, RoundXIcon } from "~/assets/svg/TeamInfoIcon";

const PersonalView = ({ userDetail: { user }, hide }) => {
	const { t } = useTranslation();
	return (
		<section className="teamInfo-personal detail">
			<p className="section-title">
				{t("HR.TeamInfo.modal.MemberDetailModal.components.Personal.section-title")}
			</p>
			<dl>
				<div className="row">
					<dt>{t("HR.TeamInfo.modal.MemberDetailModal.components.Personal.email")}</dt>
					<dd>{user.id}</dd>
				</div>
				<div className="row">
					<dt>{t("HR.TeamInfo.modal.MemberDetailModal.components.Personal.name")}</dt>
					<dd>{user.name}</dd>
				</div>
				<div className="row">
					<dt>{t("HR.TeamInfo.modal.MemberDetailModal.components.Personal.phone")}</dt>
					<dd>
						<If condition={user.phone}>
							<Case condition={null}>
								<p className="noData">
									{t(
										"HR.TeamInfo.modal.MemberDetailModal.components.Personal.noData1"
									)}
								</p>
							</Case>
							<OtherWise>{phoneNumberFormatter(user.phone)}</OtherWise>
						</If>
					</dd>
				</div>
				{hide !== true && (
					<>
						<div className="row">
							<dt>
								{t(
									"HR.TeamInfo.modal.MemberDetailModal.components.Personal.address2"
								)}
							</dt>
							<dd className="address">
								{user.zipCode ? (
									`${user.address} ${user.addressDetail}`
								) : (
									<p className="noData">
										{t(
											"HR.TeamInfo.modal.MemberDetailModal.components.Personal.noData2"
										)}
									</p>
								)}
							</dd>
						</div>

						<div className="row">
							<dt>
								{t(
									"HR.TeamInfo.modal.MemberDetailModal.components.Personal.account"
								)}
							</dt>
							<dd className="account">
								<If condition={user.account}>
									<Case condition={null}>
										<p className="noData">
											{t(
												"HR.TeamInfo.modal.MemberDetailModal.components.Personal.noData3"
											)}
										</p>
									</Case>
									<OtherWise>
										<>
											{BANK_METADATA[user.bank]?.name} {user.account}
										</>
									</OtherWise>
								</If>

								{/* TODO: 계좌 실명인증
									(박진혁)
									<span className="verification completed">
										<RoundChkIcon />
										실명인증 완료
									</span>
									<span className="verification uncompleted">
										<RoundXIcon />
										실명인증 미완료
									</span> 
								*/}
							</dd>
						</div>
						<div className="row">
							<dt>
								{t(
									"HR.TeamInfo.modal.MemberDetailModal.components.Personal.regNum"
								)}
							</dt>
							<dd className="personal-num">
								{user.regNum ? (
									ssnFormatter(user.regNum)
								) : (
									<p className="noData">
										{t(
											"HR.TeamInfo.modal.MemberDetailModal.components.Personal.noData4"
										)}
									</p>
								)}
							</dd>
						</div>
					</>
				)}
			</dl>
		</section>
	);
};

export default PersonalView;
