import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import PageHeader from "~/common/components/PageHeader";
import TabNav from "~/common/components/TabNav";
import View from "~/pages/hr/HrSettingPage/WorkType/View";
import useRequest from "~/common/hooks/useRequest";
import { differenceInMinutes } from "date-fns";
import { hoursDurationFormatter, timeToDateFormatter } from "~/utils/formatter";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const WorkType = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { hrSettingTabList, menu, type } = useOutletContext();
	const [pageState, setPageState] = useState(PAGE_STATE.VIEW);
	const [workTypeEditModal, setWorkTypeEditModal] = useState(false);
	const [workTypeList, setWorkTypeList] = useState([]);

	const workTypeRequest = useRequest("get", `hr/work`);

	useEffect(() => {
		workTypeRequest.singleCall();
	}, []);

	useEffect(() => {
		switch (workTypeRequest.state) {
			case API_STATE.done:
				const responseData = workTypeRequest.response.data;
				let newData = [];
				responseData.forEach((d) => {
					const commutingHours = differenceInMinutes(
						timeToDateFormatter(d.HrWorkTimes[0]?.workEndTime),
						timeToDateFormatter(d.HrWorkTimes[0]?.workStartTime)
					);

					const restHours = differenceInMinutes(
						timeToDateFormatter(d?.restEndTime),
						timeToDateFormatter(d?.restStartTime)
					);
					const workHours = commutingHours - restHours;

					newData.push({
						...d,
						workTimeTotal: hoursDurationFormatter(workHours * d.workDays.length),
						weekTimeTotal: hoursDurationFormatter(
							workHours * (d.workDays.length + d.paidDayOff.length)
						),
					});
				});
				setWorkTypeList(newData);
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				navigate("/404");
		}
	}, [workTypeRequest.state]);

	const buttonList = [
		<AuthButton
			type="button"
			className="btn__solid"
			color="primary"
			onClick={() => setWorkTypeEditModal(true)}
		>
			{t("button.Add")}
		</AuthButton>,
	];

	return (
		<>
			<If condition={pageState}>
				<Case condition={PAGE_STATE.VIEW}>
					<PageHeader menu={menu} type={type} buttonList={buttonList} />
					<TabNav tabList={hrSettingTabList} currentTab={"worktype"} />
					<View
						{...{
							workTypeEditModal,
							setWorkTypeEditModal,
							workTypeRequest,
							workTypeList,
						}}
					/>
				</Case>
				<Case condition={PAGE_STATE.EMPTY}>
					<div>Empty Page</div>
				</Case>
				<Case condition={PAGE_STATE.LOADING}>
					<PageHeader menu={menu} type={type} buttonList={buttonList} />
					<Loading />
				</Case>
			</If>
		</>
	);
};

export default WorkType;
