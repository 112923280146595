import React, { useEffect, useState } from "react";
import { useGlobalStore } from "~/store";
import { Case, If, MultiCases } from "~/common/components/If";
import Category from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/category";
import Short from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/short";
import Long from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/long";
import RadioType from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/radioType";
import Agree from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/agree";
import Text from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/text";
import File from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/file";
import Link from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/link";
import AddressType from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/addressType";
import TagType from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/tagType";
import Date from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/date";
import "~/assets/sass/pages/invest/round/roundForm.scss";
import useRequest from "~/common/hooks/useRequest";
import Loading from "~/common/components/Loading";
import useAlert from "~/common/hooks/useAlert";
import SelectType from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/selectType";
import { useTranslation } from "react-i18next";
import MultiSelectType from "~/pages/invest/RoundPage/modal/RoundDetailModal/Edit/component/multiSelectType";

const FormCards = ({ useForm, survey, apply }) => {
	function setOptions(options) {
		let newOptions = [];
		options.forEach((option) => {
			newOptions.push({ label: option.title, value: option.title });
		});

		return newOptions;
	}

	return survey.map((form, f_idx) => (
		<If condition={form.type} {...{ useForm, form, apply }} key={f_idx}>
			<Case condition={"CATEGORY"}>
				<Category />
			</Case>
			<Case condition={"SHORT"}>
				<Short />
			</Case>
			<Case condition={"LONG"}>
				<Long />
			</Case>
			<MultiCases condition={["CHOICE", "ALTERNATIVE"]}>
				<RadioType {...{ setOptions }} />
			</MultiCases>
			<Case condition={"AGREE"}>
				<Agree />
			</Case>
			<Case condition={"TEXT"}>
				<Text />
			</Case>
			<Case condition={"FILE"}>
				<File />
			</Case>
			<Case condition={"LINK"}>
				<Link />
			</Case>
			<Case condition={"ADDRESS"}>
				<AddressType />
			</Case>
			<Case condition={"TAG"}>
				<TagType />
			</Case>
			<Case condition={"DATE"}>
				<Date />
			</Case>
			<Case condition={"SELECT"}>
				<SelectType />
			</Case>
			<Case condition={"MULTISELECT"}>
				<MultiSelectType />
			</Case>
		</If>
	));
};

const Edit = ({ setModalToggle, getRoundList, filteredData, surveyForm, viewInfoPage }) => {
	const { t } = useTranslation();
	const [
		{
			round: {
				info: { idx, apply },
				survey,
			},
		},
	] = useGlobalStore();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		survey && survey.length !== 0 && setLoading(false);
	}, [survey]);

	const roundApplyMutation = useRequest("post", "round/apply");

	const submit = async (data) => {
		showSubmitAlert().then(() => onSubmit(filteredData(data)));
	};

	const onSubmit = (newData) => {
		// const submitData = newData.map((d) => ({
		// 	...d,
		// 	answer: d.type === "FILE" ? JSON.stringify(d.answer) : d.answer,
		// }));

		// console.log("submitData", newData);

		roundApplyMutation
			.asyncCall({ roundIdx: idx, result: newData })
			.then(() => {
				setModalToggle(false);
				getRoundList();
			})
			.catch((e) => {
				console.log("error:", e);
				switch (e.statusCode) {
					case 401:
						setErrorMsg(t("Invest.Round.RoundDetailModal.Edit.setErrorMsg1"));
						break;
					case 404:
						setErrorMsg(t("Invest.Round.RoundDetailModal.Edit.setErrorMsg2"));
						break;
					case 409:
						setErrorMsg(t("Invest.Round.RoundDetailModal.Edit.setErrorMsg3"));
						break;
					default:
						setErrorMsg(t("Invest.Round.RoundDetailModal.Edit.setErrorMsg4"));
						break;
				}
				showErrorAlert();
			});
	};

	const [showErrorAlert, ErrorAlert] = useAlert();
	const [showSubmitAlert, SubmitAlert] = useAlert();
	const [errorMsg, setErrorMsg] = useState("");

	const [roundTitle] = useState(survey ? survey.find((d) => d.type === "TITLE") ?? "" : "");

	return (
		<>
			<If condition={loading}>
				<Case condition={true}>
					<Loading />
				</Case>
				<Case condition={false}>
					<div className="modal-content round-form">
						<div className="round-form-head">
							<b>{t("Invest.Round.RoundDetailModal.Edit.round-form-head.b")}</b>
							<div className="round-title">
								<h3>{roundTitle.title}</h3>
								<p>{roundTitle.content}</p>
							</div>
						</div>
						<FormCards useForm={surveyForm} {...{ survey, apply }} />
					</div>
				</Case>
			</If>
			<div className="modal-footer">
				{apply ? (
					<button
						type="button"
						className="btn__solid"
						color="gray"
						onClick={() => setModalToggle(false)}
					>
						{t("button.check")}
					</button>
				) : (
					<>
						<button
							type="button"
							className="btn__solid"
							color="gray"
							onClick={viewInfoPage}
						>
							{t("button.back")}
						</button>
						<button
							type="button"
							className="btn__solid"
							color="primary"
							id="round_submit"
							onClick={surveyForm.handleSubmit(submit)}
						>
							{t("button.Submit")}
						</button>
					</>
				)}
			</div>
			<ErrorAlert message={errorMsg} type="error" confirm={t("button.check")} />
			<SubmitAlert
				message={
					<>
						<h3>{t("Invest.Round.RoundDetailModal.Edit.SubmitAlert.h3")}</h3>
						<p>{t("Invest.Round.RoundDetailModal.Edit.SubmitAlert.p")}</p>
					</>
				}
				layout={"horiz"}
				type="warning"
				confirm={t("button.check")}
				reject={t("button.cancel")}
			/>
		</>
	);
};

export default Edit;
