import React from "react";
import ReactDOM from "react-dom";
import App from "~/App";
import * as Sentry from "@sentry/react";
import "~/translations";

import { API_URL, BUILD_TIME, NODE_ENV } from "./common/constants/env";
import { DSN_KEY, FUSIONCHARTS_KEY } from "~/common/constants/envKeys";
import FusionCharts from "fusioncharts";

if (NODE_ENV === "development") {
	console.log(`ENDPOINT:\t\t${API_URL}\nBUILDTIME:\t\t${BUILD_TIME}`);
}

Sentry.init({
	dsn: DSN_KEY,
	integrations: [
		new Sentry.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({ console: true }),
	],
	tracesSampleRate: 1.0,
	environment: process.env.REACT_APP_ENV,
});
Sentry.setTag("gitbal", "startup");

FusionCharts.options["license"]({
	key: FUSIONCHARTS_KEY,
	creditLabel: false,
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
