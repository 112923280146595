import React, { useEffect, useState } from "react";
import Detail from "~/pages/hr/AttendancePage/TeamSchedule/Detail";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import { PAGE_STATE } from "~/common/constants/state";
import { Case, If } from "~/common/components/If";

const CalendarDetailModal = ({
	daySchedule,
	dayScheduleDetailModal,
	setDayScheduleDetailModal,
	selectedDate,
	selectedDateType,
}) => {
	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	useEffect(() => {
		daySchedule.length === 0 ? setPageState(PAGE_STATE.LOADING) : setPageState(PAGE_STATE.VIEW);
	}, [daySchedule]);

	return (
		<ModalTemplate
			modalState={dayScheduleDetailModal}
			setModalToggle={setDayScheduleDetailModal}
			width={480}
			height={480}
			className="calendar-detail-modal"
		>
			<If condition={pageState}>
				<Case condition={PAGE_STATE.VIEW}>
					<Detail {...{ daySchedule, selectedDate, selectedDateType }} />
				</Case>
			</If>
		</ModalTemplate>
	);
};

export default CalendarDetailModal;
