import React, {useEffect, useState} from "react";


//Components
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import SummaryCard from "~/pages/hr/AttendancePage/MySchedule/View/summaryCard";
import WeeklyCard from "~/pages/hr/AttendancePage/MySchedule/View/weeklyCard";
import Edit from "~/pages/hr/AttendancePage/MySchedule/Edit/index.js";

// Assets
import "~/assets/sass/pages/hr/attendance/personalSchedule.scss";
import {
    dateToString,
    DateWithDayName,
    durationToMinutesFormatter,
    timeToDateFormatter,
} from "~/utils/formatter";
import { useNavigate, useOutletContext } from "react-router";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import {
    differenceInHours,
    eachDayOfInterval,
    endOfWeek,
    startOfWeek,
    getDay,
    differenceInMinutes,
} from "date-fns";
import EditDefaultWorkTime from "~/pages/hr/AttendancePage/MySchedule/Edit/editDefaultWorkTime";
import translation from "~/translations";
import { useTranslation } from "react-i18next";

const MySchedule = (props) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [pageState, setPageState] = useState(PAGE_STATE.LOADING);
    const [userWorkType, setUserWorkType] = useState({});
    const [scheduleTimes, setScheduleTimes] = useState();
    const [weeklySchedule, setWeeklySchedule] = useState([]);

    const [currentDate, setCurrentDate] = useState(new Date());

    const [editScheduleModal, setEditScheduleModal] = useState(false);
    const [editDefaultWorkTimeModal, setEditDefaultWorkTimeModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState({});

    const workTypeRequest = useRequest("get", `vacation/workType`);
    const scheduleSummaryRequest = useRequest("get", "vacation/schedule/time");
    const scheduleRequest = useRequest("get", `vacation/schedule`);

    const [startOfCurrentWeek, setStartOfCurrentWeek] = useState(
        startOfWeek(new Date(), { weekStartsOn: 1 })
    );

    const scheduleType = [
        { id: "work", title: translation.t("HR.Attendance.MySchedule.schedule-type.work") },
        { id: "annual", title: translation.t("HR.Attendance.MySchedule.schedule-type.annual") },
        {
            id: "businessTrip",
            title: translation.t("HR.Attendance.MySchedule.schedule-type.businessTrip"),
        },
        {
            id: "remoteWork",
            title: translation.t("HR.Attendance.MySchedule.schedule-type.remoteWork"),
        },
        { id: "overtime", title: translation.t("HR.Attendance.MySchedule.schedule-type.overtime") },
    ];

    const getUserWorkType = () => {
        workTypeRequest.call();
    };

    const getScheduleSummary = () => {
        scheduleSummaryRequest.call(
            { monday: dateToString(startOfCurrentWeek, "yyyy-MM-dd") },
            "queryString"
        );
    };

    const getSchedule = () => {
        scheduleRequest.call(
            {
                monday: dateToString(startOfCurrentWeek, "yyyy-MM-dd"),
            },
            "queryString"
        );
    };

    const editScheduleModalToggle = (day) => {
        const newData = day.schedule
            .filter((d) => d.type !== "work")
            .map((d) => ({
                type: d.type,
                date: dateToString(day.initDate, "yyyy-MM-dd"),
                startTime: timeToDateFormatter(d.startTime),
                endTime: timeToDateFormatter(d.endTime),
            }));
        setSelectedDate({
            formattedDate: dateToString(day.initDate, "yyyy-MM-dd"),
            initDate: day.initDate,
            schedule: newData,
            workTime: day.workTime,
        });
        setEditScheduleModal(true);
    };

    function filteredData(data) {
        let newData = [];
        data.forEach((d) =>
            newData.push({
                diffMinutes: differenceInMinutes(
                    timeToDateFormatter(d.endTime),
                    timeToDateFormatter(d.startTime)
                ),
                startTime: dateToString(timeToDateFormatter(d.startTime), "HH:mm"),
                endTime: dateToString(timeToDateFormatter(d.endTime), "HH:mm"),
                type: scheduleType.find((type) => d.type === type.title)?.id ?? "",
                typeName: d.type,
            })
        );

        return newData;
    }


    const getDaysOfWeek = (responseData) => {
        const lastData = endOfWeek(currentDate, { weekStartsOn: 1 });
        const eachDay = eachDayOfInterval({
            start: startOfCurrentWeek,
            end: lastData,
        });

        let newEachDay = [];

        eachDay.forEach((d) => {
            newEachDay.push({
                initDate: d,
                formattedDate: DateWithDayName(d, "MM. dd"),
                schedule: filteredData(responseData[getDay(d)].schedules),
                totalTime:
                    filteredData(responseData[getDay(d)].schedules).length !== 0 &&
                    filteredData(responseData[getDay(d)].schedules).reduce(
                        (acc, cal) => acc + cal.diffMinutes,
                        0
                    ),
                workTime: {
                    startTime: timeToDateFormatter(
                        responseData[getDay(d)].selectedWorkTime.workStartTime
                    ),
                    endTime: timeToDateFormatter(
                        responseData[getDay(d)].selectedWorkTime.workEndTime
                    ),
                },
            });
        });

        setWeeklySchedule(newEachDay);
    };

    /**
     * @param {Date} day KST date
     * @returns {boolean} 휴무일
     */
    function isDayOff(day) {
        return userWorkType?.workDays?.findIndex((d) => d === getDay(day)) === -1;
    }

    useEffect(() => {
        getUserWorkType();
    }, []);

    useEffect(() => {
        getScheduleSummary();
    }, [currentDate]);

    useEffect(() => {
        switch (workTypeRequest.state) {
            case API_STATE.done:
                const responseData = workTypeRequest.response.data.foundHrMember;

                const workTimes = responseData.HrWorkType.HrWorkTimes.map((d) => ({
                    startTime: timeToDateFormatter(d.workStartTime),
                    endTime: timeToDateFormatter(d.workEndTime),
                }));

                const resatHours = differenceInHours(
                    timeToDateFormatter(responseData.HrWorkType.restEndTime),
                    timeToDateFormatter(responseData.HrWorkType.restStartTime)
                );

                const workDays = responseData.HrWorkType.HrWorkDays.filter(
                    (d) => d.type === "근무일"
                ).map((d) => d.day);

                const defaultWorkTimes = {
                    startTime: responseData.defaultWorkStartTime
                        ? timeToDateFormatter(responseData.defaultWorkStartTime)
                        : workTimes[0]?.startTime,
                    endTime: responseData.defaultWorkEndTime
                        ? timeToDateFormatter(responseData.defaultWorkEndTime)
                        : workTimes[0]?.endTime,
                };

                setUserWorkType({
                    defaultWorkTimes: defaultWorkTimes,
                    workTimes: workTimes ?? [],
                    workDays: workDays ?? [],
                    restHours: resatHours ?? null,
                });

                getScheduleSummary();
                break;
            case API_STATE.error:
                navigate("/404");
        }
    }, [workTypeRequest.state]);

    useEffect(() => {
        switch (scheduleSummaryRequest.state) {
            case API_STATE.done:
                const responseData = scheduleSummaryRequest.response.data;

                setScheduleTimes({
                    work: {
                        max: durationToMinutesFormatter(responseData.workTimeMax),
                        result: durationToMinutesFormatter(responseData.resultWorkTime),
                    },
                    overwork: {
                        max: durationToMinutesFormatter(responseData.overTimeMax),
                        result: durationToMinutesFormatter(responseData.resultOverworkTime),
                    },
                    vacation: {
                        max: durationToMinutesFormatter(responseData.vacationTimeMax),
                        result: durationToMinutesFormatter(responseData.resultVacationTime),
                    },
                });

                setPageState(PAGE_STATE.VIEW);
                break;
            case API_STATE.error:
                navigate("/404");
        }
    }, [scheduleSummaryRequest.state]);

    useEffect(() => {
        switch (scheduleRequest.state) {
            case API_STATE.done:
                getDaysOfWeek(scheduleRequest.response.data);
                break;
            case API_STATE.error:
                navigate("/404");
        }
    }, [scheduleRequest.state]);

    return(
            <div className="my-schedule">
                {
                    scheduleTimes &&
                    <SummaryCard
                        {...{ scheduleTimes, userWorkType, setEditDefaultWorkTimeModal }}
                    />
                }
                <WeeklyCard
                    {...{
                        currentDate,
                        setCurrentDate,
                        setStartOfCurrentWeek,
                        editScheduleModalToggle,
                        getSchedule,
                        weeklySchedule,
                        scheduleType,
                        isDayOff,
                    }}
                />
                {/*스케줄 수정, 등록 모달*/}
                <ModalTemplate
                    modalState={editScheduleModal}
                    setModalToggle={setEditScheduleModal}
                    title={DateWithDayName(selectedDate.initDate)}
                    width={638}
                    className="vacation-setting-modal"
                    closeable={false}
                >
                    <Edit
                        {...{
                            selectedDate,
                            setSelectedDate,
                            userWorkType,
                            scheduleRequest,
                            getSchedule,
                            getScheduleSummary,
                            isDayOff,
                        }}
                    />
                </ModalTemplate>
                {/*기본출근시간 일괄변경 모달*/}
                <ModalTemplate
                    modalState={editDefaultWorkTimeModal}
                    setModalToggle={setEditDefaultWorkTimeModal}
                    title={t("HR.Attendance.MySchedule.weeklyCard.modal-title")}
                    width={268}
                    className="default-workTime-modal"
                    closeable={false}
                >
                    <EditDefaultWorkTime
                        {...{
                            userWorkType,
                            getSchedule,
                            getUserWorkType,
                        }}
                    />
                </ModalTemplate>
            </div>
    )
}

export default MySchedule
