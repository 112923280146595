import React from "react";

import { LightCloseIcon } from "~/assets/svg/CloseIcon";
import Input from "~/common/components/Input";
import Form, { Group } from "~/common/components/Form";
import Textarea from "~/common/components/Textarea";
import SingleImageUploader from "~/common/components/FormImageUploader/SingleImageUploader";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import { useTranslation } from "react-i18next";

const EditCard = ({ name, remove, useForm }) => {
	const { t } = useTranslation();
	return (
		<div className="c__card">
			<span className="btn-close" onClick={remove}>
				<LightCloseIcon />
			</span>
			<div className="data-form">
				<Form useForm={useForm}>
					<SingleImageUploader
						name={name("img")}
						type={UPLOAD_TYPE.COMPANY_MEMBER}
						className={"member-img"}
						height="150px"
						width="150px"
						round
					/>
					<Group>
						<Input
							name={name("department")}
							placeholder={t("Company.Member.Edit.department")}
							required={null}
						/>
						<Input
							name={name("position")}
							placeholder={t("Company.Member.Edit.position")}
							required={null}
						/>
					</Group>
					<Input
						name={name("name")}
						placeholder={t("Company.Member.Edit.name")}
						required={null}
					/>
					<Textarea
						name={name("introduction")}
						placeholder={t("Company.Member.Edit.introduction")}
					/>
				</Form>
			</div>
		</div>
	);
};

export default EditCard;
