import React, { useEffect, useState } from "react";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import { useForm } from "react-hook-form";
import PageHeader from "~/common/components/PageHeader";
import Form, { Group } from "~/common/components/Form";
import Input from "~/common/components/Input";
import FileUploaderAdd from "~/common/components/FileUploader/FileUploaderAdd";

//assets
import "~/assets/sass/pages/community/communityRegister.scss";
import { ALLOW_COMMENT_STATE, API_STATE, PAGE_STATE } from "~/common/constants/state";
import useRequest from "~/common/hooks/useRequest";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import useAlert from "~/common/hooks/useAlert";
import { imgTagSrcExtraction } from "~/utils/formatter";
import Toggle from "~/common/components/Toggle";
import { useAtomValue } from "jotai";
import { CurrentBoardAtom } from "~/atoms/Community";
import Editor from "~/common/components/Editor";
import { useTranslation } from "react-i18next";

const Edit = ({ menu, type, onCancel, defaultData, setPageState }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { idx, postIdx } = useParams();
	const boardEditForm = useForm({
		defaultValues: {
			title: defaultData?.title,
			content: defaultData?.content,
			allowComment: !defaultData
				? true
				: defaultData?.settings.find((s) => s.type === "ALLOW_COMMENT").value ===
				  "ALLOW_COMMENT_ALLOW",
		},
	});
	const [fileList, setFileList] = useState([]);
	const [ImageList, setImageList] = useState([]);
	const [showWarningAlert, WarningAlert] = useAlert();
	const currentBoard = useAtomValue(CurrentBoardAtom);

	useEffect(() => {
		if (defaultData) {
			setFileList(
				defaultData.uploadFiles.map((file) => ({
					name: file.name,
					path: file.path,
					size: Number(file.size),
				}))
			);
			setImageList(
				defaultData.contentFiles.map((file) => ({
					name: file.name,
					path: file.path,
					size: Number(file.size),
				}))
			);
		}
	}, []);

	const boardEditRequest = useRequest(
		defaultData ? "patch" : "post",
		`community/board/${defaultData ? postIdx : idx}`
	);

	useEffect(() => {
		switch (boardEditRequest.state) {
			case API_STATE.done:
				if (boardEditRequest.response.statusCode === 500) {
					console.log("error");
					navigate("/404");
				} else {
					defaultData
						? setPageState(PAGE_STATE.VIEW)
						: navigate(`/community/list/board/${idx}`);
				}

				break;
			case API_STATE.error:
				navigate("/404");
				break;
		}
	}, [boardEditRequest.state]);

	const submit = (data) => {
		const requestData = {
			title: data.title,
			content: data.content,
			settings: [
				{
					type: "ALLOW_COMMENT",
					value: data.allowComment
						? ALLOW_COMMENT_STATE.ALLOW
						: ALLOW_COMMENT_STATE.NOT_ALLOW,
				},
			],
			contentFiles: ImageList.filter((file) =>
				imgTagSrcExtraction(data.content).includes(file.path)
			),
			uploadFiles: fileList,
		};

		boardEditRequest.call(requestData, "body");
	};

	const headerButton = [
		<button
			className="btn__solid"
			color="gray"
			onClick={() => {
				showWarningAlert()
					.then(() => {
						onCancel();
					})
					.catch(() => {});
			}}
		>
			{t("button.cancel")}
		</button>,
		<button className="btn__solid" color="primary" onClick={boardEditForm.handleSubmit(submit)}>
			{t("button.save")}
		</button>,
	];

	return (
		<>
			<PageHeader {...{ menu, type }} buttonList={headerButton} />
			<div className="c__card community-register-card">
				<Form useForm={boardEditForm} className={"Community-PostForm"}>
					<Input
						name="title"
						placeholder={t(
							"Community.BoardPage.BoardPost.Detail.Edit.community-placeholder"
						)}
						required
					/>
					<Editor
						useForm={boardEditForm}
						setImageList={setImageList}
						uploadType={UPLOAD_TYPE.COMMUNITY_CONTENT}
						placeholder={t(
							"Community.BoardPage.BoardPost.Detail.Edit.community-editor-placeholder"
						)}
					/>
					{currentBoard?.allowFile && (
						<FileUploaderAdd
							label={t("Community.FileUploader-label")}
							name="file"
							type={UPLOAD_TYPE.COMMUNITY_UPLOAD}
							className={"community-fileUpload"}
							format=".pdf, .hwp, .docx, .xlsx, .ppt, ,pptx, .jpg, .jpeg, .png, .keynote"
							fileList={fileList}
							setFileList={setFileList}
						/>
					)}
					<Group className="switch-group">
						<label className="switch-label">{t("Community.switch-label")}</label>
						<Toggle name="allowComment" defaultValue={true} />
					</Group>
				</Form>
			</div>
			<WarningAlert
				type="error"
				message={
					<>
						<p>
							{t("Community.BoardPage.BoardPost.Detail.Edit.WarningAlert-massage1")}
						</p>
						<p>
							{t("Community.BoardPage.BoardPost.Detail.Edit.WarningAlert-massage2")}
						</p>
					</>
				}
				reject={t("button.cancel")}
				confirm={t("button.check")}
			/>
		</>
	);
};

export default Edit;
