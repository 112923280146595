import React, { useState } from "react";
import TabMenu from "~/common/components/TabMenu";
import { Case, If, OtherWise } from "~/common/components/If";
import File from "~/pages/mypage/SignaturePage/SignatureUploadModal/components/File";
import Draw from "~/pages/mypage/SignaturePage/SignatureUploadModal/components/Draw";
import translation from "~/translations";

const SignatureUpload = ({ setModalToggle, getSignatureList }) => {
	const [currentTab, setCurrentTab] = useState("file");

	const tabMenuList = [
		{
			id: "file",
			title: translation.t("MyPage.SignaturePage.SignatureUploadModal.content.file"),
		},
		{
			id: "draw",
			title: translation.t("MyPage.SignaturePage.SignatureUploadModal.content.draw"),
		},
	];

	return (
		<>
			<div className="modal-content">
				<div className={`signature-${currentTab}`}>
					<TabMenu
						tabItem={tabMenuList}
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
					<If condition={currentTab} {...{ setModalToggle, getSignatureList }}>
						<Case condition={"file"}>
							<File />
						</Case>
						<OtherWise>
							<Draw />
						</OtherWise>
					</If>
				</div>
			</div>
		</>
	);
};
export default SignatureUpload;
