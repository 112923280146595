import React, { useEffect, useState } from "react";
import TabMenu from "~/common/components/TabMenu";
import { Case, If, OtherWise } from "~/common/components/If";
import translation from "~/translations";

import ProfileView from "../components/ProfileView";
import HrView from "../components/HrView";
import PersonalView from "../components/PersonalView";
import CareerView from "../components/CareerView";
import MemoView from "../components/MemoView";
import ScrollTop from "~/common/components/ScrollTop";
import MemberDelete from "~/pages/hr/TeamInfoPage/modal/MemberDetailModal/components/MemberDelete";

const AdminView = ({ userDetail, setIsDeleteTab }) => {
	const [currentTab, setCurrentTab] = useState("basic");

	const tabMenuList = [
		{ id: "basic", title: translation.t("HR.TeamInfo.modal.MemberDetailModal.EditView.basic") },
		{
			id: "career",
			title: translation.t("HR.TeamInfo.modal.MemberDetailModal.EditView.career"),
		},
		{ id: "memo", title: translation.t("HR.TeamInfo.modal.MemberDetailModal.EditView.memo") },
		{
			id: "delete",
			title: translation.t("HR.TeamInfo.modal.MemberDetailModal.EditView.delete"),
			disabled: true,
		},
	];

	useEffect(() => {
		if (currentTab === "delete") {
			setIsDeleteTab(true);
		} else {
			setIsDeleteTab(false);
		}
	}, [currentTab]);

	return (
		<>
			<div className="modal-content">
				<ProfileView {...{ userDetail }} />
				<div className="tabInfo-area">
					<TabMenu
						tabItem={tabMenuList}
						currentTab={currentTab}
						setCurrentTab={setCurrentTab}
					/>
					<ScrollTop className="scroll-wrap">
						<If condition={currentTab} userDetail={userDetail}>
							<Case condition={"basic"}>
								<HrView />
								<PersonalView />
							</Case>
							<Case condition={"career"}>
								<CareerView />
							</Case>
							<Case condition={"delete"}>
								<MemberDelete />
							</Case>
							<OtherWise>
								<MemoView />
							</OtherWise>
						</If>
					</ScrollTop>
				</div>
			</div>
		</>
	);
};

export default AdminView;
