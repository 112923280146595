import React from "react";
import initImg from "~/assets/img/init-org.png";
import AuthButton from "~/common/components/AuthButton";
import { useTranslation } from "react-i18next";

const Empty = ({ Header, showEditPage }) => {
	const { t } = useTranslation();
	const buttonList = [
		<AuthButton type="button" className="btn__solid" color="primary" onClick={showEditPage}>
			{t("button.registering")}
		</AuthButton>,
	];

	return (
		<>
			<Header buttonList={buttonList} />
			<div className="page-init">
				<p className="main">{t("IR.IrDetail.Shareholder.Empty.main")}</p>
				<p className="sub"> </p>
				<img src={initImg} alt={t("IR.IrDetail.Shareholder.Empty.alt")} />
			</div>
		</>
	);
};

export default Empty;
