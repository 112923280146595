import React, { useEffect } from "react";

import PageHeader from "~/common/components/PageHeader";

import "~/assets/sass/pages/company/product.scss";
import useRequest from "~/common/hooks/useRequest";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { useGlobalStore } from "~/store";
import Form from "~/common/components/Form";
import Tag from "~/common/components/Tag";
import Input from "~/common/components/Input";
import Textarea from "~/common/components/Textarea";
import Select from "~/common/components/Select";
import Toggle from "~/common/components/Toggle";
import { useForm } from "react-hook-form";
import MultiImageUploader from "~/common/components/FormImageUploader/MultiImageUploader";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import useAlert from "~/common/hooks/useAlert";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const CompanyProductEditPage = ({ isEdit }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { idx } = useParams();
	const langCheck = i18next.language;

	const [
		{
			company: { currentProduct },
		},
	] = useGlobalStore();

	useEffect(() => {
		if (isEdit && idx.toString() !== currentProduct.idx?.toString()) {
			navigate(`/company/product/view/${idx}`);
		}
	}, []);

	const productUpdateRequest = useRequest("post", `company/product/${isEdit ? "edit" : "add"}`);

	const productForm = useForm({
		defaultValues: {
			Product: {
				name: currentProduct?.name,
				step: currentProduct?.step,
				description: currentProduct?.description,
				email: currentProduct?.email,
				webSite: currentProduct?.webSite,
				instagram: currentProduct?.instagram,
				facebook: currentProduct?.facebook,
				twitter: currentProduct?.twitter,
				linkedin: currentProduct?.linkedin,
				youtube: currentProduct?.youtube,
				differentiation: currentProduct?.differentiation,
				viewMode: currentProduct?.viewMode,
			},
			KeywordList: currentProduct?.CompanyProductKeyword?.map((i) => i.content),
			ImageList: currentProduct?.CompanyProductImage?.map((i) => i.img),
		},
	});

	const submit = (data) => {
		const requestData = { idx: idx, ...data };
		productUpdateRequest
			.asyncCall(requestData)
			.then(() => {
				navigate(`/company/product${isEdit ? `/view/${idx}` : ""}`);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const [showCancelAlert, CancelAlert] = useAlert();

	const buttonList = [
		<button
			type="button"
			onClick={() => {
				showCancelAlert().then(() =>
					isEdit ? navigate(`/company/product/view/${idx}`) : navigate("/company/product")
				);
			}}
			className="btn__solid"
			color="gray"
		>
			{t("button.cancel")}
		</button>,
		<button
			type="button"
			onClick={productForm.handleSubmit(submit)}
			className="btn__solid"
			color="primary"
		>
			{isEdit ? t("Company.Product.Edit.modify") : t("Company.Product.Edit.register")}
		</button>,
	];

	return (
		<>
			<NavbarAndSidebarLayout menu={["company", "product"]} type="main">
				<PageHeader {...{ buttonList }} />
				<div className="c__card company-product-edit">
					<section className="section-basic">
						<h3 className="section-title">{t("Company.Product.Edit.product")}</h3>
						<Form useForm={productForm}>
							<MultiImageUploader
								label={t("Company.Product.Edit.MultiImageUploader")}
								name="ImageList"
								type={UPLOAD_TYPE.COMPANY_PRODUCT}
								height="104px"
								width="165px"
								className={"product-img"}
								maxCount={5}
							/>
							<Input
								label={t("Company.Product.Edit.service")}
								name="Product.name"
								required
							/>
							<Select
								label={t("Company.Product.Edit.step")}
								name="Product.step"
								required
								options={[
									{
										label: t("Company.Product.Edit.option1"),
										value: t("Company.Product.Edit.option1"),
									},
									{
										label: t("Company.Product.Edit.option2"),
										value: t("Company.Product.Edit.option2"),
									},
									{
										label: t("Company.Product.Edit.option3"),
										value: t("Company.Product.Edit.option3"),
									},
									{
										label: t("Company.Product.Edit.option4"),
										value: t("Company.Product.Edit.option4"),
									},
								]}
							/>
							<Textarea
								label={t("Company.Product.Edit.description")}
								name="Product.description"
							/>
							<Tag
								label={t("Company.Product.Edit.tag")}
								name="KeywordList"
								className="c__tag-wrap"
							/>
						</Form>
					</section>
					<section className="section-info ">
						<h4 className="section-title">{t("Company.Product.Edit.info")}</h4>
						<Form useForm={productForm} className="info-form">
							<Input
								label={t("Company.Product.Edit.email")}
								name="Product.email"
								validation={{
									pattern: {
										value: /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g,
										message: t("Company.Product.Edit.emailMessage"),
									},
								}}
							/>
							<Input
								label={t("Company.Product.Edit.webSite")}
								name="Product.webSite"
								validation={{
									pattern: {
										value: /(http[s]?|ftp):\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}/g,
										message: t("Company.Product.Edit.webSiteMessage"),
									},
								}}
							/>
						</Form>
					</section>
					<section className="section-sns">
						<h4 className="section-title">SNS</h4>
						<Form useForm={productForm} className="sns-form">
							<Input
								label={t("Company.Product.Edit.facebook")}
								name="Product.facebook"
							/>
							<Input
								label={t("Company.Product.Edit.twitter")}
								name="Product.twitter"
							/>
							<Input
								label={t("Company.Product.Edit.instagram")}
								name="Product.instagram"
							/>
							<Input label="LinkedIn" name="Product.linkedin" />
							<Input
								label={t("Company.Product.Edit.youtube")}
								name="Product.youtube"
							/>
						</Form>
					</section>
					<section className="section-desc">
						<Form useForm={productForm}>
							<h5 className="f__label">{t("Company.Product.differentiation")}</h5>
							<Textarea name="Product.differentiation" />
							<h5 className="f__label isPublic">
								{t("Company.Product.Edit.isPublic")}
							</h5>
							<Toggle
								name="Product.viewMode"
								defaultValue={true}
								labels={{
									true: (
										<p className="public-notice public">
											{t("Company.Product.Edit.public")}
										</p>
									),
									false: (
										<p className="public-notice private">
											{t("Company.Product.Edit.private")}{" "}
											<span className="warning">
												{t("Company.Product.Edit.warning")}
											</span>
										</p>
									),
								}}
							/>
						</Form>
					</section>
				</div>
			</NavbarAndSidebarLayout>
			<CancelAlert
				message={
					<>
						{t("Company.Product.Edit.CancelAlert.1")}{" "}
						{langCheck === "ko"
							? isEdit
								? "편집"
								: "등록"
							: isEdit
							? "edit"
							: "registration"}
						{t("Company.Product.Edit.CancelAlert.2")}
						<br />
						{t("Company.Product.Edit.CancelAlert.3")}
					</>
				}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
		</>
	);
};

export default CompanyProductEditPage;
