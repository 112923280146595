import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useGlobalStore } from "~/store";
import useRequest from "~/common/hooks/useRequest";

import RectangleInitialImg from "~/assets/img/defaultImg/logo-rac.png";
import Form, { Group } from "~/common/components/Form";
import Select, { optionsGenerator } from "~/common/components/Select";
import Input from "~/common/components/Input";
import Textarea from "~/common/components/Textarea";
import NumberInput from "~/common/components/NumberInput";
import Address from "~/common/components/Address";
import Radio from "~/common/components/Radio";
import Toggle from "~/common/components/Toggle";

import {companyNumberFormatter, dateFormatter, dateToString} from "~/utils/formatter";
import { range } from "~/utils/array";

import PageHeader from "~/common/components/PageHeader";

import "~/assets/sass/pages/company/info/edit.scss";
// import {
// 	RoundChkIcon,
// 	RoundXIcon
// } from "~/assets/svg/TeamInfoIcon";
import DatePicker from "~/common/components/DatePicker";
import { COUNTRY_TYPE, LISTED_TYPE } from "~/common/constants/form";
import MultiCheckboxSelect from "~/common/components/MultiCheckboxSelect";
import { isDate } from "date-fns";
import useAlert from "~/common/hooks/useAlert";
import axios from "axios";
import * as env from "~/common/constants/env";
import { Trans, useTranslation } from "react-i18next";
import { optionGeneratorByObjectValues } from "~/common/components/CustomSelect";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import { UPLOAD_TYPE } from "~/common/constants/upload";
import SingleImageUploader from "~/common/components/ImageUploader/SingleImageUploader";
import noImg from "~/assets/img/defaultImg/default-img.png";
import {PlusIcon} from "~/assets/svg/MathIcon";
import {useNavigate} from "react-router-dom";
import SignatureUploadModal from "~/pages/mypage/admin/SignaturePage/SignatureUploadModal";
import {PAGE_STATE} from "~/common/constants/state";

export const biznumQuery = (data) => {
	return axios.patch(`${env.EM_COMPANY_API_URL}/company/num`, data);
};

const Edit = ({
  PAGE_STATE,
  setPage,
  menu,
  type,
  companyInfoData,
  certTypeSelect,
  handleGetCompnayInfo
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [
		{
			company: { info },
			vc: { fields },
		},
	] = useGlobalStore();
	const [uploaderConstant] = useState(UPLOAD_TYPE.COMPANY_BUSINESS);
	const [logoRec, setLogoRec] = useState(info.logoRec ? info.logoRec : "");
	const [uploadedImg, setUploadedImg] = useState("");
	const [certImg, setCertImg] = useState(""); // 사업자인증
	const [signImg, setSignImg] = useState(""); // 서명이미지
	const [logoEditWarningAlert, setLogoEditWarningAlert] = useState(false);
	const [signatureUploadModal, setSignatureUploadModal] = useState(false);

	const companyInfoForm = useForm({
		defaultValues: {
			...info,
		},
	});

	const getSignatureList = () => {
		// setSignatureList([]);
		// if (page === 1) {
		// 	setPageState(PAGE_STATE.LOADING);
		// 	adminSignatureRequest.call();
		// } else {
		// 	setPage(1);
		// }
		// setCertImg()
	};

	// const companyInfoApi = useRequest("get", `company/info`);
	const companyInfoMutate = useRequest("post", "company/edit");


	const submit = async (data) => {
		let requestData = {
			...data,
			employeeNum: parseInt(data.employeeNum),
			fields: data.CompanyField.map((d) => d.field),
			certImg: certImg,
			signImg: signImg
		};


		if (!info.comCheck) {
			delete data.idx;
		}

		delete requestData.license;

		companyInfoMutate
			.asyncCall({
				...requestData,
				comCheck: true,
			})
			.then(() => {
				handleGetCompnayInfo()
				// setPage(PAGE_STATE.VIEW);
			})
			.catch((e) => {
				console.log(e);
				alert(t("Company.Info.Edit.alert"));
			});
	};


	const [showAlert, Alert] = useAlert();
	const [showCancelAlert, CancelAlert] = useAlert();

	const recentYearsOption = () => {
		const TODAY = new Date();
		const THIS_YEAR = TODAY.getFullYear();
		return range(THIS_YEAR - 10, THIS_YEAR)
			.reverse()
			.map((i) => ({ label: `${i}`, value: `${i}` }));
	};

	const CountryType = COUNTRY_TYPE();

	const buttonList = [
		<button
			type="button"
			className="btn__solid"
			color="gray"
			onClick={() => {
				showCancelAlert().then(() =>
						setPage(PAGE_STATE.VIEW)
					// setPage(info.comCheck === true ? PAGE_STATE.VIEW : PAGE_STATE.EMPTY)
				);
			}}
		>
			{t("button.cancel")}
		</button>,
		<button
			type="button"
			className="btn__solid"
			color="primary"
			onClick={companyInfoForm.handleSubmit(submit)}
		>
			{t("button.save")}
		</button>,
	];

	const companyName = companyInfoForm.watch("name");
	const documentTitle = companyName?.trim() ? companyName : t("Company.Info.companyName");

	const [uploadImgCompleted, setUploadImgCompleted] = useState(false);
	const uploadImg = () => {
		setUploadImgCompleted(true);
		companyInfoForm.setValue("logoRec", logoRec);
		setLogoEditWarningAlert(false);
	};

	const imageRender = () => {
		// 1. 처음 렌더링할때
		if (info.logoRec && !uploadImgCompleted)
			return (
				<div className="logo-wrap after">
					<img
						src={info.logoRec || RectangleInitialImg}
						alt={t("Company.Info.logo")}
						onError={(e) => {
							e.target.src = RectangleInitialImg;
						}}
					/>
					<span>{t("Company.Info.Edit.logoEdit")}</span>
				</div>
			);
		// 2. 모달창 저장하고 난후
		if (companyInfoForm.watch("logoRec"))
			return (
				<div className="logo-wrap after">
					<img
						src={companyInfoForm.watch("logoRec") || RectangleInitialImg}
						alt={t("Company.Info.logo")}
						onError={(e) => {
							e.target.src = RectangleInitialImg;
						}}
					/>
					<span>{t("Company.Info.Edit.logoEdit")}</span>
				</div>
			);
		// 로고 없을경우
		else
			return (
				<div className="logo-wrap before">
					<span>{t("Company.Info.Edit.logoRegister")}</span>
				</div>
			);
	};



	const handleSetData = () => {
		const companyData = companyInfoData.company;
		const signatureData = companyInfoData.signature;
		const certificateData = companyInfoData.certificate;

		const setData = {
			idx: companyData.idx,
			name: companyData.name,
			regNum: companyData.regNum,
			type: companyData.type,
			certType: companyData.certType,
			introduction: companyData.introduction,
			description: companyData.description,
			bizType: companyData.bizType,
			bizStatus: companyData.bizStatus,
			industryField: companyData.industryField,
			ceoName: companyData.ceoName,
			ceoContact: companyData.ceoContact,
			ceoEmail: companyData.ceoEmail,
			zipCode: companyData.zipCode,
			address: companyData.address,
			addressDetail: companyData.addressDetail,
			logoSqu: companyData.logoSqu,
			logoRec: companyData.logoRec,
			country: companyData.country,
			homepage: companyData.homepage,
			sales: companyData.sales,
			salesYear: companyData.salesYear,
			license: companyData.license,
			employeeNum: companyData.employeeNum,
			payCycle: companyData.payCycle,
			payDay: companyData.payDay,
			estDate: companyData.estDate,
			regDate: companyData.regDate,
			updateDate: companyData.updateDate,
			listed: companyData.listed,
			viewMode: companyData.viewMode,
			comCheck: companyData.comCheck,
			bizCheck: companyData.bizCheck,
			irCheck: companyData.irCheck,
			customerKey: companyData.customerKey,
			CompanyField: companyData.CompanyField,
			certImg: certificateData?.imgUrl ?? "",
			signImg: signatureData?.img ?? ""
		}
		setCertImg(setData.certImg);
		setSignImg(setData.signImg);
		companyInfoForm.reset(setData);
	}

	useEffect(() => {
		companyInfoData && handleSetData()
	}, [companyInfoData]);

	useEffect(() => {
		signImg && console.log("signImg -> ", signImg)
	}, [signImg])

	const values = companyInfoForm.watch();


	return (
		<>
			<PageHeader {...{ menu, type, buttonList }} />
			<div className="c__card company-info-edit">
				<div className="head-wrap">
					<div className="date">
						Updated : {dateToString(info.updateDate, "yyyy. MM. dd.")}
					</div>
					<h2 className="company-name">{documentTitle}</h2>
					<div className="company-logo" >
						<button className={"button_logo"} onClick={() => setLogoEditWarningAlert(true)}>
							{imageRender()}
						</button>
					</div>
				</div>
				<div className="data-form">
					<section className="input-group-section">
						<h3 className="section-title">{t("Company.Info.basicInfo")}</h3>
						<Form useForm={companyInfoForm}>
							<Group
								label={
									<>
										{t("Company.Info.registration-num")}
										{/* <RoundChkIcon /> */}
										{/*<RoundXIcon/>*/}
									</>
								}
								className={"registration-num"}
								required
							>
								<NumberInput
									placeholder="000-00-00000"
									name="regNum"
									type="company"
									required
								/>
							</Group>
							<Input label={t("Company.Info.companyName")} name="name" required />
							<Input label={t("Company.Info.ceo")} name="ceoName" required />
							<Group
								className={"sales-input-group"}
								label={
									<>
										{t("Company.Info.sales")}
										<span>{t("Company.unit")}</span>
									</>
								}
							>
								<NumberInput
									placeholder={t("Company.Info.Edit.salesNum")}
									name="sales"
									max={999999999}
									string
								/>
								<Select
									placeholder={t("Company.Info.Edit.salesYear")}
									name="salesYear"
									options={recentYearsOption()}
								/>
							</Group>
							<Input label={t("Company.Info.bizType")} name="bizType" required />
							<Input label={t("Company.Info.bizStatus")} name="bizStatus" />
							<DatePicker label={t("Company.Info.estDate")} name="estDate" />
							<NumberInput
								label={t("Company.Info.employeeNum")}
								name="employeeNum"
								className="required"
								required
								string
							/>
							{/*기업구분*/}
							<Select
								label={t("Company.Info.type")}
								name="type"
								options={certTypeSelect}
								required
							/>
							<Select
								label={t("Company.Info.Edit.country")}
								name="country"
								options={optionGeneratorByObjectValues(CountryType)}
								required
							/>
							<Radio
								label={t("Company.Info.listed")}
								className="required"
								name="listed"
								options={optionsGenerator(LISTED_TYPE)}
								required
								boolean
							/>
							<Input label={t("Company.Info.homepage")} name="homepage" />
							<Input
								label={t("Company.Info.ceoEmail")}
								type="email"
								name="ceoEmail"
								placeholder="sample@domain.com"
								required
								validation={{
									pattern: {
										value: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{1,10}$/i,
										message: t("Company.Info.Edit.emailMessage"),
									},
								}}
							/>
							<NumberInput
								label={t("Company.Info.ceoContact")}
								name="ceoContact"
								type="phone"
								required
								placeholder="010-0000-0000"
							/>
							<Address
								label={t("Company.Info.address")}
								name={{
									postCode: "zipCode",
									address1: "address",
									address2: "addressDetail",
								}}
							/>
							<Group
								className={"keyword-wrap"}
								label={t("Company.Info.field")}
								required
							>
								<MultiCheckboxSelect
									valueKey={"field"}
									name="CompanyField"
									options={fields}
									selectionLimit={5}
									placeholder={t("Company.Info.Edit.fieldPlaceholder")}
									required={null}
									selectedValues={values.CompanyField}
								/>
								<p className="notice">{t("Company.Info.Edit.notice")}</p>
							</Group>
						</Form>
					</section>
					<section className="company-desc">
						<h3 className="section-title">{t("Company.Info.introduction")}</h3>
						<Form useForm={companyInfoForm}>
							<Textarea
								name="introduction"
								placeholder={t("Company.Info.Edit.placeholder")}
								required
							/>
						</Form>
					</section>

					<section className="private-setting">
						<h3 className="section-title">{t("Company.Info.Edit.whether")}</h3>
						<Form useForm={companyInfoForm}>
							<Toggle
								name="viewMode"
								defaultValue={true}
								labels={{
									false: (
										<p className="public-notice private">
											{t("Company.Info.Edit.private")}
											<span className="warning">
												{t("Company.Info.Edit.warning")}
											</span>
										</p>
									),
									true: (
										<p className="public-notice public">
											{t("Company.Info.Edit.public")}
										</p>
									),
								}}
							/>
						</Form>
					</section>

					{/*사업자인증 start*/}
					<section className="private-setting">
						<h3 className="section-title">사업자인증</h3>
						<div className={"c__form horiz data-view"}>
							{

									<div className={"certification-wrap"}>
										<div className="img_wrap">
											<SingleImageUploader
												uploaderConstant={uploaderConstant}
												width={144}
												height={200}
												img={certImg}
												setImg={setCertImg}
												name={"certImg"}
											/>
										</div>
										{
											certImg ?
												<div className="result_wrap">
													<div className="status-wrap">
														<p className="status">인증됨</p>
														<p className="description">
															사업자 인증이 완료되었습니다. <br/>
															추후 개편되는 서비스 및 기능을 추가 인증 없이 사용 가능합니다.
														</p>
													</div>
													<div className="biz-number">
														<span className={"label"}>
															사업자 등록번호:
														</span> {companyNumberFormatter(companyInfoData?.company?.regNum)}
													</div>
												</div> :
												<div className="result_wrap">
													<div className="status-wrap">
														<p className="status">미인증</p>
														<p className="description">
															사업자 인증을 완료해 주세요. <br/>
															사업자 인증을 완료하시면 개편 중인 서비스의 주요 기능을 편리하게 이용할 수 있습니다.
														</p>
													</div>
												</div>
										}
									</div>
							}
						</div>
					</section>
					{/*사업자인증 end*/}

					{/*인감 start*/}
					{
						// values.signImg ?
						signImg ?
							<section className="private-setting">
								<div className="signature-wrap">
									<div className="signature-part">
										<span className="label">signature</span>
										<div className="img-wrap">
											<SingleImageUploader
												uploaderConstant={uploaderConstant}
												width={236}
												height={122}
												img={signImg}
												setImg={setSignImg}
												name={"signImg"}
											/>
										</div>
									</div>
								</div>
							</section> :
							<section className="private-setting">
								<div className="signature-wrap">
									<div className="signature-part">
										<span className="label">signature</span>
										<button
											className={"register-signature-button"}
											onClick={() => setSignatureUploadModal(true)}>
											<PlusIcon />
											법인인감등록하기
										</button>
									</div>
								</div>
							</section>
					}
					{/*인감 end*/}


				</div>
			</div>

			{/*인감등록 모달*/}
			<ModalTemplate
				title={t("MyPage.admin.Signature.index.modalTitle")}
				modalState={signatureUploadModal}
				setModalToggle={setSignatureUploadModal}
				width={500}
				height={380}
				closeable={false}
				className="modal-signatureUpload"
			>
				<SignatureUploadModal {...{
					getSignatureList,
					uploaderConstant: UPLOAD_TYPE.COMPANY_LOGO,
					setSignImg: setSignImg
				}} />
			</ModalTemplate>

			{/* 로고 등록하기 모달 */}
			<ModalTemplate
				title={t("Company.Info.Edit.logoRegister")}
				modalState={logoEditWarningAlert}
				setModalToggle={setLogoEditWarningAlert}
				width={460}
				height={480}
				className="modal-logo"
			>
				<div className="modal-content">
					<p className="sub-text">
						<Trans
							i18nKey="MyPage.admin.Logo.Edit.logo-rectangle"
							components={[<b />]}
						/>
					</p>
					<div className="logo-uploader">
						<div className={`logo-wrap ${logoRec ? "logo-exist" : ""}`}>
							<SingleImageUploader
								uploaderConstant={UPLOAD_TYPE.COMPANY_LOGO}
								width={"100%"}
								height={"100%"}
								img={logoRec}
								setImg={setLogoRec}
							/>
						</div>
					</div>
					<div className="info-text">
						<p>{t("MyPage.admin.Logo.Edit.alert-info-text1")}</p>
						<p>{t("MyPage.admin.Logo.Edit.alert-info-text2")}</p>
					</div>
				</div>
				<div className="modal-footer">
					<button
						className="btn__solid"
						color="gray"
						onClick={() => setLogoEditWarningAlert(false)}
					>
						{t("button.cancel")}
					</button>
					<button className="btn__solid" color="primary" onClick={uploadImg}>
						{t("button.save")}
					</button>
				</div>
			</ModalTemplate>
			<Alert
				message={t("Company.Info.Edit.Alert")}
				confirm={t("button.check")}
				type={"info"}
			/>
			<CancelAlert
				message={t("Company.Info.Edit.CancelAlert")}
				confirm={t("button.yes")}
				reject={t("button.No")}
				type={"warning"}
			/>
		</>
	);
};

export default Edit;
