import React, { useEffect, useState } from "react";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import ModalTemplate from "~/common/components/ModalPortal/ModalTemplate";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE, PAGE_STATE } from "~/common/constants/state";
import { useTranslation } from "react-i18next";

//Assets
import "~/assets/sass/pages/mypage/signature/signature.scss";
import SignatureUploadModal from "~/pages/mypage/admin/SignaturePage/SignatureUploadModal";
import { dateToString } from "~/utils/formatter";
import View from "./View";
import useInfiniteScroll from "~/common/hooks/useInfiniteScroll";
import useAlert from "~/common/hooks/useAlert";

const SignaturePage = () => {
	const { t } = useTranslation();
	const MENU = ["admin", "signature"];
	const TYPE = "myPage";

	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	const [showErrorAlert, ErrorAlert] = useAlert();

	const [signatureList, setSignatureList] = useState([]);
	const [hasMore, setHasMore] = useState(false);
	const [page, setPage] = useState(1);
	const [deleteSignIdx, setDeleteSignIdx] = useState("");
	const [activeSignIdx, setActiveSignIdx] = useState("");

	const [modalTitle] = useState(t("MyPage.admin.Signature.index.modalTitle"));
	const [signatureUploadModal, setSignatureUploadModal] = useState(false);

	const adminSignatureRequest = useRequest("get", `admin/signature?size=24&page=${page}`);
	const deleteSignatureRequest = useRequest("delete", `admin/signature/delete`);
	const activationSignatureRequest = useRequest("post", `admin/signature/activation`);

	const lastElementRef = useInfiniteScroll(pageState, page, setPage, hasMore);

	const getSignatureList = () => {
		setSignatureList([]);
		if (page === 1) {
			setPageState(PAGE_STATE.LOADING);
			adminSignatureRequest.call();
		} else {
			setPage(1);
		}
	};

	//법인인감 리스트 Request
	useEffect(() => {
		setPageState(PAGE_STATE.LOADING);
		adminSignatureRequest.call();
	}, [page]);

	//법인인감 리스트 Response
	useEffect(() => {
		switch (adminSignatureRequest.state) {
			case API_STATE.done:
				const responseData = adminSignatureRequest.response.data;

				const newData = adminSignatureRequest.response.data.list.map((d) => ({
					...d,
					updateDate: dateToString(d.updateDate, "yyyy-MM-dd"),
				}));

				if (responseData.pageable.totalPage === 0) {
					setPageState(PAGE_STATE.EMPTY);
				} else {
					if (responseData.pageable.pageNumber === 1) {
						setSignatureList(newData);
					} else {
						setSignatureList([...signatureList, ...newData]);
					}
					setPageState(PAGE_STATE.VIEW);
				}
				setHasMore(page !== responseData.pageable.totalPage);
				break;
			case API_STATE.error:
				console.log("error", adminSignatureRequest.error);
				break;
			default:
				break;
		}
	}, [adminSignatureRequest.state, adminSignatureRequest.error, adminSignatureRequest.response]);

	//법인인감 삭제 Request
	useEffect(() => {
		if (deleteSignIdx !== "") {
			deleteSignatureRequest.call(deleteSignIdx, "path");
			setDeleteSignIdx("");
		}
	}, [deleteSignIdx, deleteSignatureRequest]);

	//법인인감 삭제 Response
	useEffect(() => {
		switch (deleteSignatureRequest.state) {
			case API_STATE.done:
				getSignatureList();
				break;
			case API_STATE.error:
				console.log("error", deleteSignatureRequest.error);
				break;
		}
	}, [
		deleteSignatureRequest.state,
		deleteSignatureRequest.error,
		deleteSignatureRequest.response,
	]);

	//법인인감 활성화/비활성화 Request
	useEffect(() => {
		if (activeSignIdx !== "") {
			setActiveSignIdx("");
			activationSignatureRequest.call(activeSignIdx, "path");
		}
	}, [activeSignIdx, activationSignatureRequest]);

	//법인인감 활성화/비활성화 Response
	useEffect(() => {
		switch (activationSignatureRequest.state) {
			case API_STATE.done:
				getSignatureList();
				break;
			case API_STATE.error:
				console.log("error", activationSignatureRequest.error);
				break;
		}
	}, [
		activationSignatureRequest.state,
		activationSignatureRequest.error,
		activationSignatureRequest.response,
	]);

	const headerButtons = [
		<button
			type="button"
			className="btn__solid"
			color="primary"
			onClick={() => {
				if (signatureList?.length >= 6) return showErrorAlert();
				setSignatureUploadModal(true);
			}}
		>
			{t("button.registering")}
		</button>,
	];

	return (
		<>
			<NavbarAndSidebarLayout menu={MENU} type={TYPE} backTop={true}>
				<PageHeader hasBreadCrumb={true} buttonList={headerButtons} />
				<View
					{...{
						signatureList,
						setActiveSignIdx,
						setDeleteSignIdx,
						lastElementRef,
						pageState,
					}}
				/>
				<ModalTemplate
					title={modalTitle}
					modalState={signatureUploadModal}
					setModalToggle={setSignatureUploadModal}
					width={500}
					height={380}
					className="modal-signatureUpload"
				>
					<SignatureUploadModal {...{ getSignatureList }} />
				</ModalTemplate>
				<ErrorAlert
					type="error"
					confirm={t("button.check")}
					message={"법인 인감은 최대 6개까지 등록할 수 있습니다."}
				/>
			</NavbarAndSidebarLayout>
		</>
	);
};
export default SignaturePage;
