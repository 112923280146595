import React from 'react'
import MyInfo from "~/pages/DashboardPage/Contents/UserInfo/MyInfo";
import {ArrowRight, PeopleIcon, PersonActive, PersonNonActive} from "~/assets/svg/InterfacesIcon";
import {useNavigate} from "react-router";

const UserInfo = (props) => {
    const navigate = useNavigate();
    const {auth, setVacationTypeListModal, userInfo, manageInfo} = props;

    return(
        <>
            <section className={"c__card"}>
                {
                    userInfo &&
                    <MyInfo
                        auth={auth}
                        setVacationTypeListModal={setVacationTypeListModal}
                        userInfo={userInfo}
                    />
                }
            </section>
            {
                auth.admin &&
                <section className={"management_section"}>
                    <div className={"c__card approval-request"}>
                        <h3 className="c__title">
                            연차승인요청
                            <button
                                onClick={() => navigate("/vacation/management", {
                                    state: {activeTab: "연차 현황"}
                                })}
                            >
                                <ArrowRight/>
                            </button>
                        </h3>
                        <div className={"content"}>
                            <div className={"inner-content"}>
                                <span className={"number"}>{manageInfo?.upcmReq ?? 0}</span>
                                <span className={"ea"}>건</span>
                            </div>
                        </div>
                    </div>
                    <div className={"c__card team-management"}>
                        <h3 className="c__title">
                            팀원 관리하기
                            <button onClick={() => navigate("/hr/list")}><ArrowRight/></button>
                        </h3>
                        <ul className="content status-list">
                           <li className="status-item">
                               <div className="icon-wrap"><PeopleIcon/></div>
                               <div className="status-content">
                                   <span className={"name"}>팀원</span>
                                   <span className={"number"}>{ manageInfo?.totalMember ?? 0 }</span>
                               </div>
                           </li>
                            <li className="status-item">
                                <div className="icon-wrap">
                                    <PersonActive/>
                                </div>
                                <div className="status-content">
                                    <span className={"name"}>활성</span>
                                    <span className={"number active"}>{ manageInfo?.activeMember ?? 0 }</span>
                                </div>
                            </li>
                            <li className="status-item">
                                <div className="icon-wrap">
                                    <PersonNonActive/>
                                </div>
                                <div className="status-content">
                                    <span className={"name"}>비활성</span>
                                    <span className={"number non-active"}>{ manageInfo?.noactiveMember ?? 0 }</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
            }
        </>
    )
}

export default UserInfo
