import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useGlobalStore } from "~/store";

import { Case, If } from "~/common/components/If";

import useRequest from "~/common/hooks/useRequest";

import "~/assets/sass/pages/hr/modal/teamInfoModal.scss";

import Edit from "./Edit";
import View from "./View";

import { PAGE_STATE } from "~/common/constants/state";
import { API_STATE } from "~/common/constants/state";

import { dateToString } from "~/utils/formatter";
import useAlert from "~/common/hooks/useAlert";
import { useTranslation } from "react-i18next";

const MemberDetailModal = ({ setModalToggle, selectedMember, handleSearch, getInitialState, callbackFunction }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [
		{
			user,
			sidebar: {
				currentPath: { auth },
			},
			organization,
		},
	] = useGlobalStore();

	const [pageState, setPageState] = useState(PAGE_STATE.LOADING);

	const [userDetail, setUserDetail] = useState(null);

	const userRole = auth === "edit" ? "admin" : user.info.idx === selectedMember ? "mine" : "user";

	const queryString = `hr/team/info/${userRole === "admin" ? "admin" : "user"}`;


	const userDetailRequest = useRequest("get", `${queryString}`);
	const deleteRequest = useRequest("post", "hr/team/withdraw");

	const [showDeleteAlert, DeleteAlert] = useAlert();
	const [showInfoAlert, InfoAlert] = useAlert();

	useEffect(() => {
		selectedMember && userDetailRequest.singleCall(selectedMember, "path");
	}, [selectedMember, userDetailRequest]);

	useEffect(() => {
		switch (userDetailRequest.state) {
			case API_STATE.done:
				const responseData = userDetailRequest.response.data;
				const {
					Company,
					UserProfile: { facebook, twitter, instagram, linkedin, youtube, ...userInfo },
					career: career,
					memo: memo,
					idx,
					...others
				} = responseData;


				const workStartDate =
					responseData.UserProfile.workStartDate &&
					dateToString(responseData.UserProfile.workStartDate, "yyyy.MM.dd ");

				const workEndDate = responseData.UserProfile.workEndDate
					? dateToString(responseData.UserProfile.workEndDate, " yyyy.MM.dd")
					: "";

				const resignDate = responseData.UserProfile.resignDate
					? dateToString(responseData.UserProfile.resignDate, " yyyy.MM.dd")
					: "";

				const workPeriod = workStartDate ? workStartDate + "~" + workEndDate : null;
				setUserDetail({
					user: {
						...others,
						...userInfo,
						idx: idx,
						workingTypeTitle: (organization && organization.workTypeList && responseData?.UserProfile?.workingType) ? organization.workTypeList[responseData.UserProfile.workingType] : "",
						workPeriod: workPeriod,
						resignDate: resignDate,
					},
					career: career,
					memo: memo,
					company: Company,
					social: { facebook, twitter, instagram, linkedin, youtube },
				});
				setPageState(PAGE_STATE.VIEW);
				break;
			case API_STATE.error:
				console.error(userDetailRequest.error);
				navigate("/404");
				break;
			default:
				break;
		}
	}, [userDetailRequest.state]);

	const showEditPage = () => {
		setPageState(PAGE_STATE.EDIT);
	};

	const showViewPage = () => {
		userDetailRequest.call(selectedMember, "path");
	};

	const handleClose = () => {
		setModalToggle(false);
	};

	const handleDelete = () => {
		showDeleteAlert().then(() => {
			deleteRequest.call(selectedMember, "path");
		});
	};

	useEffect(() => {
		switch (deleteRequest.state) {
			case API_STATE.done:
				showInfoAlert().then(() => {
					handleClose();
					getInitialState();
				});
				break;
			case API_STATE.error:
				console.log("error: ", deleteRequest.error);
				navigate("/404");
				break;
			default:
				break;
		}
	}, [deleteRequest.state]);

	return (
		<>
			<If condition={pageState} {...{ handleClose, userDetail, userRole }}>
				<Case condition={PAGE_STATE.LOADING} />
				<Case condition={PAGE_STATE.VIEW}>
					<View {...{ showEditPage, handleDelete }} />
				</Case>
				<Case condition={PAGE_STATE.EDIT}>
					<Edit {...{ showViewPage, handleSearch, getInitialState, callbackFunction }} />
				</Case>
			</If>
			<DeleteAlert
				type={"warning"}
				message={t("HR.TeamInfo.modal.MemberDetailModal.DeleteAlert")}
				confirm={t("button.yes")}
				reject={t("button.No")}
			/>
			<InfoAlert
				type="success"
				message={t("HR.TeamInfo.modal.MemberDetailModal.InfoAlert")}
				confirm={t("button.check")}
			/>
		</>
	);
};

export default MemberDetailModal;
