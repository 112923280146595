import React, { useEffect } from "react";
import Form from "~/common/components/Form";
import Tag from "~/common/components/Tag";

const TagType = ({ useForm, form, apply }) => {
	useEffect(() => {
		useForm.setValue(form.order.toString(), form.answer);
	}, []);

	return (
		<>
			{apply ? (
				<div className="c__form horiz data-view">
					<div className="f__group c__tag-wrap">
						<label className="f__label">{form.title}</label>
						<div className="f__control">
							{form.answer?.map((tag, t_idx) => (
								<span className={`c__tag-view`} key={t_idx}>
									{tag}
								</span>
							))}
						</div>
					</div>
				</div>
			) : (
				<Form useForm={useForm}>
					<Tag
						label={form.title}
						name={form.order.toString()}
						required={form.required}
						className="c__tag-wrap"
					/>
				</Form>
			)}
		</>
	);
};

export default TagType;
