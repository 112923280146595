import React, { useEffect, useMemo, useState } from "react";
import * as env from "~/common/constants/env";
import { PAGE_STATE } from "~/common/constants/state";
import { PERIOD_TYPE } from "~/common/constants/form";
import { thousandFormatter } from "~/utils/formatter";
import useRepository from "~/common/hooks/useRepository";
import { Trans, useTranslation } from "react-i18next";

// Store
import { useGlobalStore } from "~/store";
import { OKCEO_PROFIT_DATA } from "~/store/tax/repository";

// Components
import CustomSelect, { optionGeneratorByObjectValues } from "~/common/components/CustomSelect";
import { optionsGenerator } from "~/common/components/Select";
import { Case, If } from "~/common/components/If";
import Loading from "~/common/components/Loading";
import { OkceoNoContent } from "~/pages/Tax/Dashboard/View";

// Assets
// import { ChartIcon } from "~/assets/svg/InterfacesIcon";

const ProfitCard = ({ okceoToken, selectedCompany, profitSelectedYear, setProfitSelectedYear }) => {
	const { t } = useTranslation();
	const [
		{
			tax: { profit },
		},
	] = useGlobalStore();
	const [cardState, setCardState] = useState(PAGE_STATE.LOADING);
	const [getProfitData] = useRepository(OKCEO_PROFIT_DATA);
	const PeriodType = PERIOD_TYPE();

	useEffect(() => {
		getProfitData({ selectedCompany: selectedCompany, selectedYear: profitSelectedYear });
	}, [profitSelectedYear]);

	useEffect(() => {
		if (profit) {
			setCardState(PAGE_STATE.VIEW);
		} else {
			setCardState(PAGE_STATE.EMPTY);
		}
	}, [profit]);

	const receiveAmount = useMemo(
		() => profit?.collectingAmount - profit?.collectedAmount,
		[profit]
	);

	const debtAmount = useMemo(() => profit?.payingAmount - profit?.paidAmount, [profit]);

	return (
		<div className="c__card profit-card">
			<div className="card-head">
				<h2 className="card-title">
					<Trans
						i18nKey="Tax.Dashboard.View.component.ProfitCard.card-title"
						components={[<span />]}
					/>
				</h2>
				<div className="extra-condition">
					<CustomSelect
						value={profitSelectedYear}
						setValue={setProfitSelectedYear}
						options={optionGeneratorByObjectValues(PeriodType)}
					/>
					{/*<button type="button" className="btn__outline angled" color="gray">*/}
					{/*	받을돈 상세보기*/}
					{/*</button>*/}
					{/*<button type="button" className="btn__outline angled" color="gray">*/}
					{/*	나갈돈 상세보기*/}
					{/*</button>*/}
					{/*<div className="divider" />*/}
					{/*<button type="button" className="btn__outline btn-detail" color="gray">*/}
					{/*	<ChartIcon />*/}
					{/*</button>*/}
				</div>
			</div>
			<div className="card-content">
				<div className="total-wrap">
					<h3 className="total-wrap-title">
						{t("Tax.Dashboard.View.component.ProfitCard.total-wrap-title")}
					</h3>
					<div className="amount-wrap">
						<span className="vat">{t("Tax.Dashboard.View.component.vat")}</span>
						<p className="amount">
							{thousandFormatter(profit?.profitAmount)}
							<i>{t("Tax.Dashboard.View.component.won")}</i>
						</p>
					</div>
				</div>
				<If condition={cardState}>
					<Case condition={PAGE_STATE.LOADING}>
						<Loading />
					</Case>
					<Case condition={PAGE_STATE.EMPTY}>
						<OkceoNoContent {...{ okceoToken, selectedCompany }} />
					</Case>
					<Case condition={PAGE_STATE.VIEW}>
						<div className="content-wrap">
							<div className="plus-money">
								<div className="bar-chart-wrap">
									<p className="label">
										{t(
											"Tax.Dashboard.View.component.ProfitCard.plus-money.label"
										)}
									</p>
									<dl className="bar-chart" color="plus">
										<dt className="amount">
											<div className="amount-num">
												{thousandFormatter(profit?.collectingAmount)}
												&nbsp;
												<i>{t("Tax.Dashboard.View.component.won")}</i>
											</div>
											<i className="unit">
												{t("Tax.Dashboard.View.component.vatIncluding")}
											</i>
										</dt>
										<dd className="bar">
											<span
												className="bar-state"
												style={{ width: `${profit?.collectedPercent}%` }}
											/>
										</dd>
										<dd
											className="bar-percent"
											style={{ width: `${profit?.collectedPercent}%` }}
										>
											{profit?.collectedPercent}%
										</dd>
									</dl>
									<dl className="bar-chart-data">
										<div className="data">
											<dt>
												{t(
													"Tax.Dashboard.View.component.ProfitCard.plus-money.dataDt1"
												)}
											</dt>
											<dd>
												{thousandFormatter(profit?.collectedAmount)}{" "}
												{t("Tax.Dashboard.View.component.won")}
											</dd>
										</div>
										<div>
											<dt>
												{t(
													"Tax.Dashboard.View.component.ProfitCard.plus-money.dataDt2"
												)}
											</dt>
											<dd>
												{thousandFormatter(receiveAmount)}{" "}
												{t("Tax.Dashboard.View.component.won")}
											</dd>
										</div>
									</dl>
								</div>

								{/*<div className="profit-list-wrap">*/}
								{/*	<p className="list-title">받을돈 리스트</p>*/}
								{/*	<div className="scroll-wrap">*/}
								{/*		<dl className="profit-list">*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>12,123,123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*		</dl>*/}
								{/*	</div>*/}
								{/*</div>*/}
							</div>
							<div className="minus-money">
								<div className="bar-chart-wrap">
									<p className="label">
										{t(
											"Tax.Dashboard.View.component.ProfitCard.minus-money.label"
										)}
									</p>
									<dl className="bar-chart" color="minus">
										<dt className="amount">
											<div className="amount-num">
												{thousandFormatter(profit?.payingAmount)}
												&nbsp;
												<i>{t("Tax.Dashboard.View.component.won")}</i>
											</div>
											<i className="unit">
												{t("Tax.Dashboard.View.component.vatIncluding")}
											</i>
										</dt>
										<dd className="bar">
											<span
												className="bar-state"
												style={{ width: `${profit?.paidPercent}%` }}
											/>
										</dd>
										<dd
											className="bar-percent"
											style={{ width: `${profit?.paidPercent}%` }}
										>
											{profit?.paidPercent}%
										</dd>
									</dl>
									<dl className="bar-chart-data">
										<div className="data">
											<dt>
												{t(
													"Tax.Dashboard.View.component.ProfitCard.minus-money.dataDt1"
												)}
											</dt>
											<dd>
												{thousandFormatter(profit?.payingAmount)}{" "}
												{t("Tax.Dashboard.View.component.won")}
											</dd>
										</div>
										<div>
											<dt>
												{t(
													"Tax.Dashboard.View.component.ProfitCard.minus-money.dataDt2"
												)}
											</dt>
											<dd>
												{thousandFormatter(debtAmount)}{" "}
												{t("Tax.Dashboard.View.component.won")}
											</dd>
										</div>
									</dl>
								</div>
								{/*<div className="profit-list-wrap">*/}
								{/*	<p className="list-title">나갈돈 리스트</p>*/}
								{/*	<div className="scroll-wrap">*/}
								{/*		<dl className="profit-list">*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*			<div>*/}
								{/*				<dt>*/}
								{/*					<p className="company">헤이드론</p>*/}
								{/*					<span className="business-num">311-10-29890</span>*/}
								{/*				</dt>*/}
								{/*				<dd>123원</dd>*/}
								{/*			</div>*/}
								{/*		</dl>*/}
								{/*	</div>*/}
								{/*</div>*/}
							</div>
						</div>
						<div className="total-wrap">
							<h4 className="total-wrap-sub">
								<Trans
									i18nKey={
										"Tax.Dashboard.View.component.ProfitCard.total-wrap.total-wrap-sub1"
									}
									components={[<span />]}
								/>
							</h4>
							<div className="amount-wrap">
								<p className="amount">
									{thousandFormatter(receiveAmount)}
									<i>{t("Tax.Dashboard.View.component.won")}</i>
								</p>
							</div>
						</div>
						<div
							className="summary-detail"
							onClick={() =>
								window.open(
									`${env.OKCEO_API_URL}/ssolink?token=${okceoToken}&cid=${selectedCompany}`
								)
							}
						>
							&gt;{" "}
							{t("Tax.Dashboard.View.component.ProfitCard.total-wrap.summary-detail")}
						</div>
						<div className="total-wrap">
							<h4 className="total-wrap-sub">
								<Trans
									i18nKey={
										"Tax.Dashboard.View.component.ProfitCard.total-wrap.total-wrap-sub2"
									}
									components={[<span />]}
								/>
							</h4>
							<div className="amount-wrap">
								<p className="amount">
									{thousandFormatter(debtAmount)}
									<i>{t("Tax.Dashboard.View.component.won")}</i>
								</p>
							</div>
						</div>
						<div
							className="summary-detail"
							onClick={() =>
								window.open(
									`${env.OKCEO_API_URL}/ssolink?token=${okceoToken}&cid=${selectedCompany}`
								)
							}
						>
							&gt;{" "}
							{t("Tax.Dashboard.View.component.ProfitCard.total-wrap.summary-detail")}
						</div>
					</Case>
				</If>
			</div>
		</div>
	);
};

export default ProfitCard;
