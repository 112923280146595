import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import english from "./english.json";
import korean from "./korean.json";

const resources = {
	ko: { translation: korean },
	en: { translation: english },
};

i18n.use(initReactI18next).init({
	resources,
	lng: localStorage.getItem("language") || "ko",
	fallbackLng: "ko",
	debug: false,
	keySeparator: ".",
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
