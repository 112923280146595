import React from "react";
import NoData from "~/common/components/NoData";
import { useTranslation } from "react-i18next";

const Empty = () => {
	const { t } = useTranslation();
	return (
		<NoData
			className="c__card"
			main={t("VcInfo.VcList.Empty.main")}
			sub={t("VcInfo.VcList.Empty.sub")}
		/>
	);
};

export default Empty;
