import React, { useEffect, useState } from "react";
import NavbarAndSidebarLayout from "~/layouts/NavbarAndSidebarLayout";
import PageHeader from "~/common/components/PageHeader";
import TabMenu from "~/common/components/TabMenu";
import { Case, If } from "~/common/components/If";
import Management from "~/pages/mypage/admin/PaymentPage/management";

//assets
import "~/assets/sass/pages/mypage/admin/paymentPage/view.scss";
import useRequest from "~/common/hooks/useRequest";
import { API_STATE } from "~/common/constants/state";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { dateToString, thousandFormatter } from "~/utils/formatter";
import { CARD_METADATA } from "~/common/constants/payment";
import { useTranslation } from "react-i18next";
import translation from "~/translations";

const View = () => {
	const { t } = useTranslation();
	const MENU = ["admin", "payment"];
	const TYPE = "myPage";
	const navigate = useNavigate();
	const { idx } = useParams();

	const [currentTab, setCurrentTab] = useState("history");
	const [purchaseHistory, setPurchaseHistory] = useState();

	const purchaseHistoryRequest = useRequest("get", `admin/purchase/history/${idx}`);

	const tabMenuList = [
		{ id: "history", title: translation.t("MyPage.admin.Payment.tabMenuList.history") },
		{ id: "management", title: translation.t("MyPage.admin.Payment.tabMenuList.management") },
	];

	useEffect(() => {
		purchaseHistoryRequest.singleCall();
	}, []);

	useEffect(() => {
		switch (purchaseHistoryRequest.state) {
			case API_STATE.done:
				const response = purchaseHistoryRequest.response.data;
				setPurchaseHistory(response.licensePurchase);
				break;
			case API_STATE.error:
				console.log("error");
				navigate("/404");
				break;
			default:
				break;
		}
	}, [purchaseHistoryRequest.state]);

	const cardNumberFormatter = (cardNumber) => {
		let newCardNumber = cardNumber.replace(/(.{4})/g, "$1-");
		if (newCardNumber[newCardNumber.length - 1] === "-")
			newCardNumber = newCardNumber.slice(0, -1);
		return newCardNumber;
	};

	const getPlanName = (plan) => {
		return plan.replace(translation.t("MyPage.admin.Payment.historyView.license"), "");
	};

	return (
		<NavbarAndSidebarLayout
			menu={MENU}
			type={TYPE}
			// menuName={"paymentView"}
		>
			<PageHeader />
			<TabMenu tabItem={tabMenuList} currentTab={currentTab} setCurrentTab={setCurrentTab} />
			<If condition={currentTab}>
				<Case condition={"history"}>
					{purchaseHistory && (
						<div className="receipt-section">
							<div className="receipt-wrap">
								<div className="receipt-header">
									<p className="date">{`${dateToString(
										purchaseHistory.createdDate,
										"yyyy"
									)}${t("MyPage.admin.Payment.historyView.year")} ${dateToString(
										purchaseHistory.createdDate,
										"MM"
									)}${t("MyPage.admin.Payment.historyView.title-month")}`}</p>
									<div
										className={`state ${
											purchaseHistory.purchaseResult ? "complete" : "fail"
										}`}
									>
										{purchaseHistory.purchaseResult
											? t("MyPage.admin.Payment.historyView.complete")
											: t("MyPage.admin.Payment.historyView.fail")}
									</div>
								</div>
								<div className="receipt-title">
									<p className="title">{purchaseHistory.plan}</p>
									<p
										className={`price ${
											purchaseHistory.purchaseResult ? "" : "fail"
										}`}
									>
										<b>
											{thousandFormatter(
												purchaseHistory.price * purchaseHistory.members
											)}
										</b>
										{t("MyPage.admin.Payment.historyView.won")}
									</p>
								</div>
								<div className="receipt-content">
									<dl>
										<dt>{t("MyPage.admin.Payment.historyView.status")}</dt>
										<dd
											className={
												purchaseHistory.purchaseResult ? "complete" : "fail"
											}
										>
											{purchaseHistory.purchaseResult
												? t("MyPage.admin.Payment.historyView.complete2")
												: t("MyPage.admin.Payment.historyView.fail2")}
										</dd>
									</dl>
									<dl>
										<dt>{t("MyPage.admin.Payment.historyView.time")}</dt>
										<dd className="num">
											{dateToString(
												purchaseHistory.createdDate,
												"yyyy-MM-dd HH:mm:ss"
											)}
										</dd>
									</dl>

									<dl>
										<dt>{t("MyPage.admin.Payment.historyView.method")}</dt>
										<dd>
											{/*신용카드(신한카드 1002-****-****-1234)*/}
											{`${purchaseHistory.cardType}${t(
												"MyPage.admin.Payment.historyView.card"
											)}(${
												CARD_METADATA[purchaseHistory.acquirerCode]?.name ??
												"OO"
											}${t(
												"MyPage.admin.Payment.historyView.card"
											)} ${cardNumberFormatter(purchaseHistory.cardNumber)})`}
										</dd>
									</dl>
									<dl>
										<dt>{t("MyPage.admin.Payment.historyView.price")}</dt>
										<dd>
											{thousandFormatter(
												purchaseHistory.price * purchaseHistory.members
											)}
											{t("MyPage.admin.Payment.historyView.won")}
											<span>{` [${getPlanName(
												purchaseHistory.plan
											)} ${thousandFormatter(purchaseHistory.price)}${t(
												"MyPage.admin.Payment.historyView.price-standard"
											)}(${purchaseHistory.members}${t(
												"MyPage.admin.Payment.historyView.people"
											)})]`}</span>
										</dd>
									</dl>
									{purchaseHistory.expDate && (
										<>
											<dl>
												<dt>
													{t("MyPage.admin.Payment.historyView.expDate")}
												</dt>
												<dd className="num">
													{`${dateToString(
														purchaseHistory.createdDate,
														"yyyy-MM-dd"
													)} ~ ${dateToString(
														purchaseHistory.expDate,
														"yyyy-MM-dd"
													)}`}
												</dd>
											</dl>
											<dl>
												<dt>
													{t(
														"MyPage.admin.Payment.historyView.next-expDate"
													)}
												</dt>
												<dd className="num">
													{dateToString(
														new Date(purchaseHistory.expDate).setDate(
															new Date(
																purchaseHistory.expDate
															).getDate() + 1
														),
														"yyyy-MM-dd"
													)}
												</dd>
											</dl>
										</>
									)}
									{!purchaseHistory.purchaseResult && (
										<dl>
											<dt>
												{t(
													"MyPage.admin.Payment.historyView.purchaseResult"
												)}
											</dt>
											<dd>{purchaseHistory.purchaseResultMessage}</dd>
										</dl>
									)}
								</div>
								<div className="btn-group">
									<button
										className="btn__solid"
										color="gray"
										onClick={() => navigate("/myPage/admin/payment")}
									>
										{t("MyPage.admin.Payment.historyView.list")}
									</button>
									{/*{purchaseHistory.purchaseResult && (*/}
									{/*	<button className="btn__solid" color="red">*/}
									{/*		결제취소*/}
									{/*	</button>*/}
									{/*)}*/}
								</div>
							</div>
						</div>
					)}
				</Case>
				<Case condition={"management"}>
					<Management />
				</Case>
			</If>
		</NavbarAndSidebarLayout>
	);
};

export default View;
