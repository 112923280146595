import { setAlarm } from "~/store/alarm/actions";
import { dateToString } from "~/utils/formatter";

export const ALARM_LIST = {
	method: "get",
	url: "user/alarm",
	async onSuccess({ response, dispatch }) {
		const alarmList = response.map((item) => ({
			...item,
			regDate: dateToString(item.regDate, "yyyy-MM-dd HH:mm:ss"),
		}));
		await dispatch(setAlarm({ alarmList }));
	},
	onError({ error }) {
		console.log("error", error);
	},
};
